import { shape, string, arrayOf } from "source/shared/prop_types"
import { capitalize } from "lodash"
import { AlertMessage } from "source/shared/components"

export function FormErrors({ errors = [] }) {
  if (!errors.length) return null

  const messages = errors.map(({ detail, source: { parameter } }) => {
    if (parameter === "base") {
      return detail
    } else {
      return `${capitalize(parameter)} ${detail}`
    }
  })

  return (
    <AlertMessage className="mb-2">
      Uh oh! We couldn&rsquo;t save the event. Please correct these errors and
      try again.
      <ul className="mt-1">
        {messages.map((m) => (
          <li key={m} className="mt-1">
            {m}
          </li>
        ))}
      </ul>
    </AlertMessage>
  )
}
FormErrors.propTypes = {
  errors: arrayOf(
    shape({
      detail: string,
      source: shape({ parameter: string }),
    }),
  ),
}
