import { css } from "@emotion/react"
import { Helmet } from "react-helmet-async"
import { colors } from "source/shared/colors"
import { Navigate, Routes, Route } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import { useSession } from "source/shared/hooks/useSession"
import { PeopleShow } from "source/directory/people/Show"
import { PeopleIndex } from "source/directory/people/Index"
import { HouseholdsShow } from "source/directory/households/Show"
import { HouseholdsIndex } from "source/directory/households/Index"
import { Edit } from "source/directory/Edit"
import { useDirectoryStatus } from "source/directory/hooks/useDirectoryStatus"
import { Icon } from "source/shared/components"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { useCustomNavigationLabel } from "source/publishing/WebBoot"

const ALLOWED_DIRECTORY_STATUSES = ["viewer", "participant"]
export const LAST_VIEW_STORAGE_KEY = "DirectoryLastView"

export function Directory() {
  useSession(true)
  const directoryStatus = useDirectoryStatus()
  const [directoryLastView, _setDirectoryLastView] = useLocalStorage(
    LAST_VIEW_STORAGE_KEY,
    "people",
  )
  const title = useCustomNavigationLabel("Directory")

  if (!ALLOWED_DIRECTORY_STATUSES.includes(directoryStatus)) {
    return (
      <div className="ta-c">
        <div css={styles.iconCircleBackground}>
          <div css={styles.icon}>
            <Icon symbol="churchCenter#directory-o" />
          </div>
        </div>
        <div className="mb-7">
          <Heading
            level={1}
            color="tint1"
            text="You don't have access to the directory"
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Routes>
        <Route path="/edit" element={<Edit />} />
        <Route path="/people" element={<PeopleIndex />} />
        <Route path="/people/:id" element={<PeopleShow />} />
        <Route path="/households" element={<HouseholdsIndex />} />
        <Route path="/households/:id" element={<HouseholdsShow />} />
        <Route
          path="*"
          element={<Navigate to={`/directory/${directoryLastView}`} replace />}
        />
      </Routes>
      <Helmet title={title} />
    </>
  )
}

const styles = {
  iconCircleBackground: css`
    background-image: linear-gradient(
      135deg,
      ${colors.brandGradientStart} 0%,
      ${colors.brandGradientEnd} 100%
    );
    border-radius: 100%;
    display: inline-block;
    height: 64px;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: center;
    width: 64px;

    &:before {
      background-color: white;
      border-radius: 100%;
      content: "";
      height: 58px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 58px;
    }
  `,
  icon: css`
    color: ${colors.brand};
    font-size: 32px;
    position: relative;
    top: 12px;
    left: -2px;
  `,
}
