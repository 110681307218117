import { string, func } from "prop-types"
import { useMemo } from "react"
import { Select } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"

GenderSelect.propTypes = {
  onChange: func,
  value: string,
}

export function GenderSelect({ value, onChange }) {
  const genderOptions = useGenderOptions()
  const handleChange = (e) => onChange(e.target.value)

  return (
    <>
      <label htmlFor="gender">Gender</label>
      <span className="screen-reader-text">Gender</span>
      <Select
        name="gender"
        value={value || ""}
        onChange={handleChange}
        options={genderOptions}
      />
    </>
  )
}

function useGenderOptions() {
  const genders = useApiRead(`/people/v2/genders`)
  return useMemo(
    () =>
      genders.data.reduce(
        (acc, { attributes: { value } }) => [...acc, { label: value, value }],
        [{ label: "Select...", value: "" }],
      ),
    [genders],
  )
}
