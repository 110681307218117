import { object } from "prop-types"
import Edit from "source/registrations/reservations/Edit"
import { useAddOnsPrefetch } from "source/registrations/hooks/useAddOns"
import { usePeopleHouseholdsPrefetch } from "source/registrations/hooks/usePeopleHouseholds"
import { useQuestionsPrefetch } from "source/registrations/hooks/useQuestions"
import { useParams } from "react-router-dom"

const Reservation = ({ session }) => {
  const { eventId, eventSignupPath, reservationUuid } = useParams()

  useAddOnsPrefetch(eventId)
  usePeopleHouseholdsPrefetch(session.id)
  useQuestionsPrefetch(eventId)

  return (
    <Edit
      basePath={`/registrations/${eventSignupPath}/${eventId}/reservations`}
      eventId={eventId}
      reservationUuid={reservationUuid}
      session={session}
    />
  )
}

Reservation.propTypes = {
  session: object.isRequired,
}

export default Reservation
