export function resourceIconSymbol(iconClass) {
  switch (iconClass) {
    case "image":
      return "general#outlined-image-file"
    case "song":
      return "general#outlined-music-file"
    case "video":
      return "general#outlined-video-file"
    case "pdf":
      return "general#outlined-pdf-file"
    case "link":
      return "general#link"
    default:
      return "general#outlined-text-file"
  }
}
