import { useState } from "react"
import { func } from "prop-types"
import spinner from "source/svg/spinner.svg"

export default function AcceptOrDeclineButtons({
  handleAcceptSchedules,
  handleDeclineSchedules,
  setReasonModalProps,
  setShowReasonModal,
}) {
  const [inFlight, setInFlight] = useState(false)

  const handleDeclineClick = () => {
    setReasonModalProps({
      allDeclined: true,
      declinedCount: 1,
      handleSubmit: handleDeclineSchedules,
    })
    setShowReasonModal(true)
  }

  return (
    <div className="d-f jc-fs pt-3">
      {!inFlight && (
        <button
          onClick={handleDeclineClick}
          className="mr-2 secondary-btn destroy-btn btn"
        >
          Decline&hellip;
        </button>
      )}
      <button
        className="btn"
        onClick={() => {
          setInFlight(true)
          handleAcceptSchedules()
        }}
      >
        {inFlight ? (
          <img
            src={spinner}
            alt="Saving"
            style={{ height: "1em", width: "1em" }}
          />
        ) : (
          <div>Accept</div>
        )}
      </button>
    </div>
  )
}

AcceptOrDeclineButtons.propTypes = {
  handleAcceptSchedules: func,
  handleDeclineSchedules: func,
  setReasonModalProps: func,
  setShowReasonModal: func,
}
