import { useContext } from "react"
import { useLocation } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CurrentEventContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import EventSecondaryHeader from "source/groups/my/groups/events/EventSecondaryHeader"
import { EventNoteForm } from "source/groups/my/groups/events/EventNoteForm"

export default function NotesNew() {
  const currentEvent = useContext(CurrentEventContext)
  const { base_path: eventPath, event, location: eventLocation } = currentEvent
  const routerLocation = useLocation()
  const { returnTo = eventPath } = routerLocation.state || {}

  return (
    <TabView showActions={false}>
      <div>
        <EventSecondaryHeader event={event} location={eventLocation} />
        <EventNoteForm
          returnTo={returnTo}
          save={(payload) => sessionApiClient.post(event.links.notes, payload)}
          textareaLabel="New note"
        />
      </div>
    </TabView>
  )
}
