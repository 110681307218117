import { useState } from "react"
import { arrayOf, bool, func, shape, string } from "prop-types"
import moment from "moment-timezone"

import CustomField from "./CustomField"
import FormHelpers from "../FormHelpers"

DateField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      required: bool.isRequired,
    }),
    id: string.isRequired,
  }),
  onChange: func.isRequired,
}

export default function DateField({ errors, field, onChange }) {
  const [value, setValue] = useState()
  const {
    attributes: { description, label, required },
    id,
  } = field
  const inputId = `field_${id}_value`

  const handleChange = (date) => {
    const newDate = moment(date).toISOString(true)
    setValue(newDate)
    onChange(newDate, id)
  }

  return (
    <CustomField {...{ description, errors, label, required }}>
      <FormHelpers.DateInput
        id={inputId}
        selected={value ? moment(value).toDate() : null}
        onChange={handleChange}
      />
    </CustomField>
  )
}
