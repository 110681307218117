import { useContext } from "react"
import { object } from "prop-types"
import { WebBootContext } from "source/publishing/WebBoot"

const formatMonth = (momentObj) => {
  return momentObj.format("MMM").toUpperCase()
}

const formatDay = (momentObj) => {
  return momentObj.format("D")
}

const PlaceholderDate = ({ date }) => {
  const {
    currentOrganization: {
      attributes: { datetime_fmt_configuration: datetimeFormatConfig },
    },
  } = useContext(WebBootContext)

  return (
    <div className={`d-f ${datetimeFormatConfig.dateFirst ? "fd-cr" : "fd-c"}`}>
      <div className="event-placeholder-month">{formatMonth(date)}</div>
      <div className="event-placeholder-day">{formatDay(date)}</div>
    </div>
  )
}

PlaceholderDate.propTypes = {
  date: object,
}

export default PlaceholderDate
