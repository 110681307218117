import { myRegistrationEventShape } from "source/registrations/hooks/useMyRegistrations"

const EventImage = ({ event }) => {
  return (
    <div className="profile-item__image">
      <img src={event.logoUrl} alt={`Event logo for ${event.name}`} />
    </div>
  )
}

EventImage.propTypes = {
  event: myRegistrationEventShape,
}

export default EventImage
