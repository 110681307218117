import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { getRelationship } from "source/shared/getRelationship"

export function useFirstPageOfTags() {
  const { groupTypeSlug, groupSlug } = useParams()
  return useApiRead(
    `/groups/v2/group_types/${groupTypeSlug}/groups/${groupSlug}/tags?include=tag_group`,
  )
}

export function useTagGroupForTag(tag) {
  const { included } = useFirstPageOfTags()
  return getRelationship({ data: tag, included }, "tag_group")
}
