import { VisualizedDate } from "source/shared/DatetimeFmt"

type MediaThumbnailProps = {
  alt: string
  publishedTime: string
  thumbnailUrl?: string
}

export function MediaThumbnail({
  alt,
  thumbnailUrl,
  publishedTime,
}: MediaThumbnailProps) {
  if (thumbnailUrl)
    return (
      <img
        alt={alt}
        loading="lazy"
        src={thumbnailUrl}
        className="aspect-16-9"
      />
    )

  return <VisualizedDate start={publishedTime} style="abbreviated" />
}
