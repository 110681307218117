import InfiniteScroll from "react-infinite-scroller"
import { Icon, Loading } from "source/shared/components"
import {
  useFirstPageOfMyGroups,
  useInfiniteLoadingMyGroups,
} from "../hooks/useMyGroups"
import { Group } from "./Group"
import { Heading } from "@planningcenter/doxy-web"

export function Memberships() {
  const {
    meta: { total_count: groupsCount },
  } = useFirstPageOfMyGroups()
  const hasGroups = groupsCount > 0

  return hasGroups ? <Groups /> : <BlankState />
}

function BlankState() {
  return (
    <div className="ta-c c-tint2 fs-2">
      <div className="mb-1">
        <Icon symbol="churchCenter#groups-o" />
      </div>
      You are currently not a member of any groups.
    </div>
  )
}

function Groups() {
  const { records: groups, hasMore, loadMore } = useInfiniteLoadingMyGroups()
  return (
    <>
      <div className="mb-2">
        <Heading level={1} size={3} text="My groups" />
      </div>
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        loader={<Loading key="loading" />}
      >
        {groups.map((group) => (
          <Group key={group.id} group={group} />
        ))}
      </InfiniteScroll>
    </>
  )
}
