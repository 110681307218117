import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CurrentGroupContext } from "source/groups/my/groups"
import { ConversationForm } from "source/groups/my/groups/conversations/form"

export function ConversationsNew() {
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)

  function createConversation({ title }) {
    return sessionApiClient
      .post(`/groups/v2/me/groups/${group.id}/chat_conversation_payload`, {
        data: { attributes: { title } },
      })
      .then((response) => response.data.attributes.value)
      .then((payload) =>
        sessionApiClient.post(`/chat/v2/me/conversations`, {
          data: { attributes: { payload } },
        }),
      )
      .then((response) =>
        navigate(`${group.base_path}/conversations/${response.data.id}`),
      )
  }

  return (
    <ConversationForm
      onSubmit={createConversation}
      submitText="Start conversation"
    />
  )
}
