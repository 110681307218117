import { useContext, useCallback } from "react"
import { WebBootContext } from "source/publishing/WebBoot"
import { useJoltWithPrefetchedToken } from "source/shared/hooks/useJolt"
import dig from "lodash/get"
import { JoltContext } from "source/shared/contexts/JoltContext"
import PropTypes from "prop-types"

export function ReactToPublishingJoltEvents() {
  const { publishingJoltToken } = useContext(JoltContext)

  if (!publishingJoltToken) {
    return null
  }

  return (
    <SubscribeToPublishingJoltEvents
      publishingJoltToken={publishingJoltToken}
    />
  )
}

SubscribeToPublishingJoltEvents.propTypes = {
  publishingJoltToken: PropTypes.string.isRequired,
}
function SubscribeToPublishingJoltEvents({ publishingJoltToken }) {
  const {
    currentOrganization: { id: currentOrgId },
    currentPerson,
    reactToCandidateEvents,
    setDraftMode,
  } = useContext(WebBootContext)

  const handleJoltEvent = useCallback(
    (e) => {
      const { data } = JSON.parse(e.data)
      reactToCandidateEvents(data)
    },
    [reactToCandidateEvents],
  )

  useJoltWithPrefetchedToken(
    publishingJoltToken,
    `organization-${currentOrgId}.publishing.church_center.v2.events.theme_settings`,
    "publishing.church_center.v2.events.theme_settings.created",
    handleJoltEvent,
  )

  useJoltWithPrefetchedToken(
    publishingJoltToken,
    `organization-${currentOrgId}.publishing.church_center.v2.events.page`,
    "publishing.church_center.v2.events.page.created",
    handleJoltEvent,
  )

  useJoltWithPrefetchedToken(
    publishingJoltToken,
    `organization-${currentOrgId}.publishing.church_center.v2.events.church_center_app_menu`,
    "publishing.church_center.v2.events.church_center_menu.created",
    handleJoltEvent,
  )

  const maybeChangeDraftMode = useCallback(
    (message) => {
      const data = JSON.parse(message.data)
      const identifier = dig(data, "data.relationships.person.data.id")
      if (identifier === currentPerson.id)
        setDraftMode(
          message.event === SubscribeToPublishingJoltEvents.draftModeStarted,
        )
    },
    [currentPerson.id, setDraftMode],
  )
  useJoltWithPrefetchedToken(
    publishingJoltToken,
    `organization-${currentOrgId}.publishing.church_center.v2.events.draft_mode`,
    SubscribeToPublishingJoltEvents.draftModeEvents,
    maybeChangeDraftMode,
  )

  return null
}
SubscribeToPublishingJoltEvents.draftModeStarted =
  "publishing.church_center.v2.events.draft_mode.started"
SubscribeToPublishingJoltEvents.draftModeFinished =
  "publishing.church_center.v2.events.draft_mode.finished"
SubscribeToPublishingJoltEvents.draftModeEvents = [
  SubscribeToPublishingJoltEvents.draftModeStarted,
  SubscribeToPublishingJoltEvents.draftModeFinished,
]
