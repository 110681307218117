import { get, reject } from "lodash"
import { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import AcceptOrDeclineButtons from "./AcceptOrDeclineButtons"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import AcceptModal from "./AcceptModal"
import ReasonModal from "./ReasonModal"
import HandleSchedulingRequestEmails from "./HandleSchedulingRequestEmails"
import ScheduleHeader from "./ScheduleHeader"
import ScheduleItems from "./ScheduleItems"
import SchedulePlanNotes from "./SchedulePlanNotes"
import ScheduleTimes from "./ScheduleTimes"
import SubmitResponseButton from "./SubmitResponseButton"
import { WideLayout } from "source/Layout"
import { PENDING_STATUS } from "source/services/constants"
import { usePlanDetails } from "source/services/hooks/usePlanDetails"

export default function PlanDetails() {
  useSession(true)
  const location = useLocation()
  const navigate = useNavigate()
  const { planId, serviceTypeId } = useParams()

  const {
    rehearsable,
    schedules,
    serviceTypeName,
    groupedTimes,
    planNotes,
    title,
    url,
  } = usePlanDetails({
    planId,
    serviceTypeId,
  })

  const initialResponseData = schedules.reduce((acc, s) => {
    acc[s.id] = { declinedTimeIds: [] }
    return acc
  }, {})

  const singlePositionOrTime =
    schedules.length === 1 &&
    !(schedules[0].splitTeam && schedules[0].serviceTimes.length > 1)

  const [reasonModalProps, setReasonModalProps] = useState({})
  const [responseData, setResponseData] = useState(initialResponseData)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [toggleableScheduleIds, setToggleableScheduleIds] = useState(
    singlePositionOrTime
      ? []
      : schedules.filter((s) => s.status === PENDING_STATUS).map((s) => s.id),
  )

  const hasAnyPending = schedules.some((s) => s.status === PENDING_STATUS)
  const hasNotes = Object.keys(planNotes).length > 0
  const showSubmitResponseButton = toggleableScheduleIds.length >= 1
  const referrerPath = get(location, "state.prevPath", "/services")
  const referrerPathName = get(location, "state.prevPathName", "My Services")

  const handleAcceptSchedules = () =>
    Promise.all(
      schedules.map((schedule) =>
        sessionApiClient.post(
          `/services/v2/me/unscoped_schedules/${schedule.id}/accept`,
          {},
        ),
      ),
    )

  const handleDeclineSchedules = (declineReason) => {
    Promise.all(
      schedules.map((schedule) =>
        sessionApiClient.post(
          `/services/v2/me/unscoped_schedules/${schedule.id}/decline`,
          { data: { attributes: { reason: declineReason } } },
        ),
      ),
    ).then(() => navigate(referrerPath))
  }

  const handleAcceptToggle = ({ schedule, time }) => {
    setResponseData((prevState) => ({
      ...prevState,
      [schedule.id]: {
        ...prevState[schedule.id],
        declinedTimeIds: schedule.splitTeam
          ? reject(
              prevState[schedule.id]["declinedTimeIds"],
              (id) => id === time.id,
            )
          : [],
      },
    }))
  }

  const handleDeclineToggle = ({ schedule, time }) => {
    setResponseData((prevState) => ({
      ...prevState,
      [schedule.id]: {
        ...prevState[schedule.id],
        declinedTimeIds: schedule.splitTeam
          ? [...prevState[schedule.id]["declinedTimeIds"], time.id]
          : schedule.serviceTimes.map((t) => t.id),
      },
    }))
  }

  return (
    <WideLayout>
      <HandleSchedulingRequestEmails
        handleDeclineSchedules={handleDeclineSchedules}
        schedules={schedules}
        setReasonModalProps={setReasonModalProps}
        setShowAcceptModal={setShowAcceptModal}
        setShowReasonModal={setShowReasonModal}
      />
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl={referrerPath} pageText={referrerPathName} />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl={`/services/service_types/${serviceTypeId}/plans/${planId}`}
          pageText="Plan Details"
          isActive
        />
      </BreadcrumbNavigation>
      <ScheduleHeader
        hasAnyPending={hasAnyPending}
        handleDeclineSchedules={handleDeclineSchedules}
        rehearsable={rehearsable}
        schedules={schedules}
        serviceTypeName={serviceTypeName}
        setReasonModalProps={setReasonModalProps}
        setShowReasonModal={setShowReasonModal}
        setToggleableScheduleIds={setToggleableScheduleIds}
        singlePositionOrTime={singlePositionOrTime}
        title={title}
        url={url}
      />

      <div className="d-f g-3 fd-c fd-r@md mb-3 ai-s">
        <ScheduleItems
          handleAcceptToggle={handleAcceptToggle}
          handleDeclineToggle={handleDeclineToggle}
          hasAnyPending={hasAnyPending}
          responseData={responseData}
          schedules={schedules}
          setToggleableScheduleIds={setToggleableScheduleIds}
          toggleableScheduleIds={toggleableScheduleIds}
        />

        <ScheduleTimes groupedTimes={groupedTimes} />
      </div>

      {hasNotes && <SchedulePlanNotes planNotes={planNotes} />}
      {singlePositionOrTime && hasAnyPending ? (
        <AcceptOrDeclineButtons
          handleAcceptSchedules={() =>
            handleAcceptSchedules().then(() => navigate(referrerPath))
          }
          handleDeclineSchedules={handleDeclineSchedules}
          setReasonModalProps={setReasonModalProps}
          setShowReasonModal={setShowReasonModal}
        />
      ) : (
        <SubmitResponseButton
          referrerPath={referrerPath}
          responseData={responseData}
          schedules={schedules}
          setReasonModalProps={setReasonModalProps}
          setShowReasonModal={setShowReasonModal}
          toggleableScheduleIds={toggleableScheduleIds}
        />
      )}
      {rehearsable && !hasAnyPending && !showSubmitResponseButton && (
        <a
          className="btn secondary-btn minor-btn"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          View in Services
        </a>
      )}
      {showAcceptModal && (
        <AcceptModal
          handleClose={() => setShowAcceptModal(false)}
          handleAccept={() => handleAcceptSchedules().then(() => navigate(0))}
        />
      )}
      {showReasonModal && (
        <ReasonModal
          handleClose={() => setShowReasonModal(false)}
          {...reasonModalProps}
        />
      )}
    </WideLayout>
  )
}
