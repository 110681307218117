import PropTypes from "prop-types"
import { Icon } from "source/shared/components"
import { formatFileSize } from "../utils/formatFileSize"

FilePreview.propTypes = {
  state: PropTypes.object.isRequired,
  handleReplaceFile: PropTypes.func.isRequired,
}
export function FilePreview({ state, handleReplaceFile }) {
  const {
    iconClass,
    fileAttributes: { filename, size },
  } = state

  return (
    <div className="d-f ai-c fs-4 pr-2 mb-1" key={filename}>
      <div
        className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
        style={{
          width: 28,
          height: 28,
          flex: "0 0 28px",
          border: "1px solid",
        }}
      >
        <div className="fs-5 c-tint3 mt-2p">
          <Icon symbol={`general#outlined-${iconClass}-file`} />
        </div>
      </div>
      <div className="d-f fd-c overflow-text lh-1.25">
        <span className="overflow-text">{filename}</span>
        <span className="fs-5 c-tint3">{formatFileSize(size, 1)}</span>
      </div>
      <button
        type="button"
        className="btn minor-compact-btn destroy-btn ml-1 mb-2"
        style={{ marginBottom: 14 }}
        title={`Remove ${filename}`}
        aria-label={`Remove ${filename}`}
        onClick={handleReplaceFile}
      >
        <Icon symbol="general#x" title="Remove" />
      </button>
    </div>
  )
}
