import { isObject, isArray } from "lodash"
import { getRelationship } from "source/shared/getRelationship"

const isEmptyish = (value) => {
  if (!value) return true
  if (isObject(value)) return Object.values(value).every((v) => isEmptyish(v))
  if (isArray(value)) return value.every((v) => isEmptyish(v))
  return false
}

const valueIsBlankDropdownOtherOption = (value) =>
  Object.keys(value).includes("other") && value.other.trim() === ""

const triggerIsCheckboxes = (customFieldValues, conditionTriggerId) =>
  customFieldValues[conditionTriggerId]?.selected?.length > 0 &&
  !Object.keys(customFieldValues[conditionTriggerId].selected[0]).includes(
    "quantity",
  )

const triggerIsResourcesCheckbox = (customFieldValues, conditionTriggerId) =>
  customFieldValues[conditionTriggerId]?.selected?.length > 0 &&
  Object.keys(customFieldValues[conditionTriggerId].selected[0]).includes(
    "quantity",
  )

const triggerIsResourcesRadio = (customFieldValues, conditionTriggerId) =>
  typeof customFieldValues[conditionTriggerId] === "object" &&
  Object.keys(customFieldValues[conditionTriggerId]).includes("quantity")

const otherOptionIsChecked = (conditionOptionTrigger) =>
  parseInt(conditionOptionTrigger.id) === -1

const fieldIsCustomField = ({ attributes }) =>
  attributes.field_type && attributes.field_type.match(/^custom_/)

const optionIsSelectedInCheckboxes = ({
  conditionOptionTrigger,
  conditionTrigger,
  conditionTriggerId,
  formFieldOptions,
  customFieldValues,
}) => {
  if (otherOptionIsChecked(conditionOptionTrigger)) {
    // it's the "other" option so check to see if "other" is selected
    return Object.keys(customFieldValues[conditionTriggerId]).includes("other")
  } else {
    // it's checkboxes so check the selected array
    const fieldType = fieldIsCustomField(conditionTrigger)
      ? "FieldOption"
      : "FormFieldOption"
    return (
      customFieldValues[conditionTriggerId].selected.includes(
        conditionOptionTrigger.id,
      ) &&
      formFieldOptions.find(
        (f) => f.id === conditionOptionTrigger.id && f.type === fieldType,
      )
    )
  }
}

const optionIsSelectedInDropdown = (
  conditionTriggerId,
  conditionOptionTrigger,
) => conditionOptionTrigger && conditionTriggerId === conditionOptionTrigger.id

const optionIsSelectedInResourcesCheckbox = (
  customFieldValues,
  conditionOptionTrigger,
) => {
  return Object.values(customFieldValues).some((fieldValues) =>
    fieldValues.selected?.some(
      (value) => value.optionId === conditionOptionTrigger.id,
    ),
  )
}

const optionIsSelectedInResourcesRadio = (
  customFieldValues,
  conditionOptionTrigger,
) => {
  return Object.values(customFieldValues).some(
    (value) => value.optionId === conditionOptionTrigger.id,
  )
}

export default function fieldHasConditionNotMet(
  field,
  formFields,
  formFieldConditions,
  formFieldOptions,
  customFieldValues,
) {
  const condition = getRelationship(
    { data: field, included: formFieldConditions },
    "form_field_conditions",
  )[0]

  if (!condition) return false

  const conditionTriggerId =
    condition?.relationships?.trigger_form_field.data.id
  const conditionOptionTrigger =
    condition?.relationships?.trigger_form_field_option?.data
  const conditionSelected = !isEmptyish(customFieldValues[conditionTriggerId])

  const conditionTrigger = formFields.find((f) => f.id === conditionTriggerId)

  if (conditionOptionTrigger) {
    // check for whether this is the "other" option for a dropdown,
    // which is not allowed as an option trigger
    if (
      customFieldValues[conditionTriggerId] &&
      !customFieldValues[conditionTriggerId]?.selected &&
      valueIsBlankDropdownOtherOption(customFieldValues[conditionTriggerId])
    ) {
      return true
    }

    const triggerValueFieldType = triggerIsCheckboxes(
      customFieldValues,
      conditionTriggerId,
    )
      ? "checkboxes"
      : triggerIsResourcesRadio(customFieldValues, conditionTriggerId)
        ? "resourcesRadio"
        : triggerIsResourcesCheckbox(customFieldValues, conditionTriggerId)
          ? "resourcesCheckbox"
          : "dropdown"

    const conditionOptionSelected =
      triggerValueFieldType === "checkboxes"
        ? optionIsSelectedInCheckboxes({
            conditionOptionTrigger,
            conditionTrigger,
            conditionTriggerId,
            formFieldOptions,
            customFieldValues,
          })
        : triggerValueFieldType === "resourcesRadio"
          ? optionIsSelectedInResourcesRadio(
              customFieldValues,
              conditionOptionTrigger,
            )
          : triggerValueFieldType === "resourcesCheckbox"
            ? optionIsSelectedInResourcesCheckbox(
                customFieldValues,
                conditionOptionTrigger,
              )
            : optionIsSelectedInDropdown(
                customFieldValues[conditionTriggerId],
                conditionOptionTrigger,
              )

    return !(conditionSelected && conditionOptionSelected)
  } else {
    // this trigger just relies on the form field itself
    return !conditionSelected
  }
}
