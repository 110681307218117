import { number, oneOfType, shape, string } from "prop-types"
import { Component } from "react"
import Circle from "source/shared/components/google_maps/circle"
import Map from "source/shared/components/google_maps/map"
import Marker from "source/shared/components/google_maps/marker"
import { colors } from "source/shared/colors"

export class LocationMap extends Component {
  static propTypes = {
    location: shape({
      approximation: shape({
        center: shape({
          lat: number.isRequired,
          lng: number.isRequired,
        }),
        radius: number.isRequired,
      }),
      latitude: oneOfType([string, number]),
      longitude: oneOfType([string, number]),
      approximateAddress: string,
      formattedAddress: string,
      fullFormattedAddress: string,
    }),
  }

  state = { showMap: false }

  handleShowMapClick = (e) => {
    e.preventDefault()
    this.setState({ showMap: true })
  }

  getExactLocation = () => {
    const { location } = this.props

    if (!location.latitude) {
      return null
    }

    return {
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    }
  }

  render() {
    const { location } = this.props
    const { showMap } = this.state

    if (!location) {
      return null
    }

    const mapStyle = {
      border: `1px solid ${colors.tint5}`,
      borderTop: 0,
      borderRadius: 4,
      marginTop: 12,
      width: "100%",
      height: 200,
    }
    const mapOptions = { mapTypeControl: false, streetViewControl: false }
    const exactLocation = this.getExactLocation()
    const approximateLocation = location.approximation

    return (
      <div>
        <div>
          <div>
            <div>
              <LocationLink
                location={location}
                style={{ whiteSpace: "pre-wrap" }}
                className="c-tint1 fs-4"
              />
            </div>
            {!showMap && (exactLocation || approximateLocation) && (
              <div className="mt-1">
                <button
                  className="mr-1 minor-btn secondary-btn btn"
                  onClick={this.handleShowMapClick}
                  type="button"
                >
                  Show map
                </button>
                {exactLocation && (
                  <LocationLink
                    location={location}
                    text="Get directions"
                    className="minor-btn secondary-btn btn"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {showMap && (
          <div>
            {exactLocation ? (
              <Map
                center={exactLocation}
                style={mapStyle}
                key="exactMap"
                options={mapOptions}
              >
                <Marker position={exactLocation} />
              </Map>
            ) : (
              <Map
                center={approximateLocation.center}
                style={mapStyle}
                key="approximateMap"
                options={{ defaultZoom: 13, ...mapOptions }}
              >
                <Circle
                  center={approximateLocation.center}
                  radius={approximateLocation.radius}
                />
              </Map>
            )}
          </div>
        )}
      </div>
    )
  }
}

function LocationLink({ className, location, style, text }) {
  if (!location) return null

  let url = ""
  let linkText = ""
  className = className || "c-tint1"

  switch (location.locationType) {
    case "coords":
      if ((!location.latitude || !location.longitude) && !text) return null
      url = encodedAddressUrl(`${location.latitude}, ${location.longitude}`)
      linkText = text || `${location.latitude}, ${location.longitude}`
      break
    default:
      if (!location.fullFormattedAddress && !text) return null
      url = encodedAddressUrl(location.formattedAddress)
      linkText = text || location.fullFormattedAddress
      break
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      style={style}
    >
      {linkText}
    </a>
  )
}

const encodedAddressUrl = (address) => {
  const encodedAddress = encodeURIComponent(address).replace(/%20/g, "+")
  return `http://maps.google.com?q=${encodedAddress}`
}
