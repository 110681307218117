import { useState } from "react"
import { chain, isEmpty, xor } from "lodash"
import { useSession } from "source/shared/hooks/useSession"
import { NarrowLayout } from "source/Layout"
import {
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
} from "source/shared/components"
import useAvailableSignup from "source/services/hooks/useAvailableSignup"
import useSignupSheets from "source/services/hooks/useSignupSheets"
import { string } from "prop-types"
import SignupSheet from "./signupSheet"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"
import { useNavigate, useParams } from "react-router-dom"
import ConfirmModal from "./ConfirmModal"

export default function AvailableSignups() {
  useSession(true)
  const navigate = useNavigate()
  const { availableSignupId } = useParams()
  const { service_type_name: serviceTypeName } = useAvailableSignup({
    availableSignupId,
  })
  const signupSheets = useSignupSheets({ availableSignupId })
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [selectedSignupSheetIds, setSelectedSignupSheetIds] = useState([])

  const handleSubmit = () => {
    const selectedSignupSheets = chain(signupSheets)
      .filter(({ id }) => selectedSignupSheetIds.includes(id))
      .groupBy("group_key")
      .values()
      .value()

    Promise.all(selectedSignupSheets.map(serialPost)).then(() =>
      navigate("/me"),
    )
  }

  async function serialPost(signupSheets) {
    for (const signupSheet of signupSheets) {
      await sessionApiClient.post(
        `/services/v2/me/available_signups/${availableSignupId}/signup_sheets/${signupSheet.id}/accept`,
      )
    }

    return Promise.resolve()
  }

  const handleSelectSignupSheet = (signupSheetId) =>
    setSelectedSignupSheetIds(xor(selectedSignupSheetIds, [signupSheetId]))

  return (
    <NarrowLayout>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage
          linkUrl="/services/signup_sheets"
          pageText="Signup Sheet"
          isActive
        />
      </BreadcrumbNavigationWithMe>
      <div className="mb-2">
        <Heading level={1} size={2} text={serviceTypeName} />
      </div>
      {signupSheets.map((signupSheet) => (
        <SignupSheet
          key={signupSheet.id}
          isSelected={selectedSignupSheetIds.includes(signupSheet.id)}
          onHandleSelectSignupSheet={() =>
            handleSelectSignupSheet(signupSheet.id)
          }
          signupSheet={signupSheet}
        />
      ))}
      <button
        className="btn my-2"
        onClick={() => setConfirmModalOpen(true)}
        disabled={isEmpty(selectedSignupSheetIds)}
      >
        Sign up
      </button>

      {confirmModalOpen && (
        <ConfirmModal
          onHandleSubmit={handleSubmit}
          onClose={() => setConfirmModalOpen(false)}
        />
      )}
    </NarrowLayout>
  )
}

AvailableSignups.propTypes = {
  availableSignupId: string,
}
