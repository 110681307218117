import { Suspense, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { string } from "prop-types"
import { Link } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import { WideLayout } from "source/Layout"
import { Icon, Loading } from "source/shared/components"
import { DirectoryAlerts } from "source/directory/DirectoryAlerts"
import { useHouseholds } from "source/directory/households/useHouseholds"
import { HouseholdAvatar } from "source/directory/HouseholdAvatar"
import { useValidSearchTerm } from "source/directory/hooks/useValidSearchTerm"
import { IndexPeople } from "source/directory/people/Index"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { LAST_VIEW_STORAGE_KEY } from "source/directory/index"
import { useQRCode } from "source/shared/QRCode"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"

export function HouseholdsIndex() {
  const { searchTerm, debouncedValidSearchTerm, setSearchTerm } =
    useValidSearchTerm("")
  const [_directoryLastView, setDirectoryLastView] = useLocalStorage(
    LAST_VIEW_STORAGE_KEY,
    "households",
  )

  useQRCode()

  useEffect(() => setDirectoryLastView("households"), [])

  return (
    <WideLayout>
      <div>
        <DirectoryAlerts />
        <div className="action-drawer mb-2 mb-4@md d-f ai-fs">
          <div className="d-b d-f@sm f-2 fw-w mb-1 mb-0@sm mr-2 w-100%">
            <form className="w-100% p-r">
              <input
                type="search"
                style={{ paddingLeft: 32 }}
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
              />
              <Icon
                className="c-tint4 fs-3 p-a zi-100"
                style={{ left: 10, top: 11 }}
                symbol={`general#search`}
              />
            </form>
          </div>
          <div className="d-b d-f@sm f-1 fw-w mb-1 mb-0@sm w-100%">
            <div className="f-1 d-f ai-c">
              <Menu>
                <MenuButton className="ta-l select">View households</MenuButton>

                <MenuList
                  className="dropdown__menu"
                  style={{ "--dropdown-menu--font-size": "1rem" }}
                >
                  <MenuLink href="/directory/people" className="dropdown__item">
                    View people
                  </MenuLink>
                  <MenuLink
                    href="/directory/households"
                    className="dropdown__item"
                  >
                    View households
                  </MenuLink>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>

        <Suspense fallback={<Loading />}>
          <HouseholdListings search={debouncedValidSearchTerm} />
        </Suspense>
      </div>
    </WideLayout>
  )
}

HouseholdListings.propTypes = { search: string }
function HouseholdListings({ search }) {
  const { households, loadMore, hasMore } = useHouseholds()

  if (search) return <IndexPeople search={search} order="last_name" />

  return (
    <>
      {households.length ? (
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          loader={<Loading key="loading" />}
        >
          <section className="card-list">
            {households.map((household) => (
              <article className="avatar-list__item" key={household.id}>
                <Link to={household.attributes.public_listing_path}>
                  <div className="card-list-item__image avatar-list-item__image">
                    <HouseholdAvatar
                      size="square"
                      url={household.attributes.avatar_url}
                      alt={household.attributes.name}
                    />
                  </div>
                  <div className="card-list-item__title ta-c">
                    <Heading
                      level={1}
                      size={4}
                      text={household.attributes.name}
                    />
                  </div>
                </Link>
              </article>
            ))}
          </section>
        </InfiniteScroll>
      ) : (
        <NoEntriesPlaceholder />
      )}
    </>
  )
}

const NoEntriesPlaceholder = () => (
  <div className="mt-2 ta-c c-tint2 fs-2">
    <Icon symbol="churchCenter#directory-o" className="mb-1" aria-hidden />
    <div>Looks like there aren&apos;t any entries in the directory yet</div>
  </div>
)
