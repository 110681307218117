import { array, object } from "prop-types"
import { css } from "@emotion/react"

import EventListing from "source/calendar/list/EventListing"
import { Subheading } from "@planningcenter/doxy-web"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

ListDay.propTypes = { day: object, events: array }
export default function ListDay({ day, events }) {
  const eventList = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
  `
  const shouldUseNewSignupBadgeLogic = useFlipperFeatureEnabled(
    "ROLLOUT_calendar_new_signups_logic",
  )

  return (
    <>
      <div className="my-3" data-date={day.format("YYYY-MM-DD")}>
        <Subheading level={2} text={day.format("dddd, MMM D")} />
      </div>

      <ul css={eventList}>
        {events.map((event) => {
          const legacyLogic =
            event.registrationUrl && !event.registrationUrl.announcementOnly
          const newLogic = event.registrationUrl?.canRegister
          const signupsAvailable = shouldUseNewSignupBadgeLogic
            ? newLogic
            : legacyLogic

          return (
            <EventListing
              key={event.id}
              eventDate={day}
              eventId={event.id}
              eventTitle={event.name}
              eventRecurrence={event.recurrenceDescription}
              eventLocation={
                event.location &&
                (event.location.name || event.location.formattedAddress)
              }
              eventTime={event.eventTime}
              signupsAvailable={signupsAvailable}
            />
          )
        })}
      </ul>
    </>
  )
}
