import { useRef, useState } from "react"
import { func } from "prop-types"
import spinner from "source/svg/spinner.svg"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"

const AcceptModal = ({ handleClose, handleAccept }) => {
  const [inFlight, setInFlight] = useState(false)
  const cancelRef = useRef(null)

  const handleClickSubmit = () => {
    setInFlight(true)
    handleAccept()
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>Accept</AlertDialogLabel>
      <AlertDialogDescription>
        Are you sure you want to accept this position?
        <div className="d-f jc-fe mt-3">
          <button
            className="text-btn mr-2 btn "
            onClick={handleClose}
            ref={cancelRef}
          >
            Cancel
          </button>{" "}
          <button className="btn" onClick={handleClickSubmit}>
            {inFlight ? (
              <img
                src={spinner}
                alt="Saving"
                style={{ height: "1em", width: "1em" }}
              />
            ) : (
              <div>Accept</div>
            )}
          </button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

AcceptModal.propTypes = {
  handleClose: func,
  handleAccept: func,
}

export default AcceptModal
