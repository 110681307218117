import { func, string } from "prop-types"
import { Radio } from "source/registrations/CheckableInput"

const paymentMethods = [
  {
    id: "methodOnline",
    label: "Pay online (credit or debit)",
    value: "creditCard",
  },
  {
    id: "methodOffline",
    label: "Pay in person (cash or check)",
    value: "cashCheck",
  },
]

const ReviewPaymentMethods = ({ onChange, paymentMethod }) => {
  return (
    <div>
      <label htmlFor="method" className="mt-3">
        Payment options
      </label>
      {paymentMethods.map((method) => (
        <div className="mb-4p" key={method.id}>
          <Radio
            name="method"
            id={method.id}
            label={method.label}
            value={method.value}
            onChange={(e) => onChange(e.target.value)}
            checked={paymentMethod === method.value}
          />
        </div>
      ))}
    </div>
  )
}

ReviewPaymentMethods.propTypes = {
  onChange: func,
  paymentMethod: string,
}

export default ReviewPaymentMethods
