import { Suspense, useContext } from "react"
import { Link } from "react-router-dom"
import { object, shape, string } from "prop-types"
import { CurrentEventContext } from "source/groups/my/groups"
import { useSession } from "source/shared/hooks/useSession"
import { useApiRead } from "source/shared/SessionApiResource"
import { Icon, Loading, Avatar } from "source/shared/components"
import { getRelationship } from "source/shared/getRelationship"
import { get as dig } from "lodash"
import { Subheading } from "@planningcenter/doxy-web"

export default function EventNotes() {
  return (
    <section className="mb-4">
      <div className="mb-1">
        <Subheading level={2} text="Notes" />
      </div>
      <Suspense fallback={<Loading />}>
        <NotesList />
      </Suspense>
    </section>
  )
}

function NotesList() {
  const { event, base_path: eventPath } = useContext(CurrentEventContext)
  const { data: currentPerson } = useSession(false)
  const notesResponse = useApiRead(
    `${
      event.links.notes
    }?per_page=100&include=owner&fields[Owner]=first_name,avatar_url`,
  )
  const myNoteResponse = useApiRead(
    `${event.links.my_note}?include=owner&fields[Owner]=avatar_url`,
  )

  const noteApiReadFailed = notesResponse?.errors
  const myNoteApiReadFailed =
    notesResponse.errors && notesResponse.errors.status !== "404"

  if (noteApiReadFailed && myNoteApiReadFailed) return null

  const { data = [], included } = notesResponse
  const { data: myNoteData, included: myNoteIncluded } = myNoteResponse

  const myNoteExists = dig(myNoteResponse, "errors[0].status") !== "404"

  const notes = data.filter(
    (note) => note.relationships.owner.data.id !== currentPerson.id,
  )

  const myNoteOwner =
    myNoteExists &&
    getRelationship({ data: myNoteData, included: myNoteIncluded }, "owner")
  const myNoteUrl = `${eventPath}/notes/${dig(myNoteData, "id")}/edit`

  return (
    <div className="d-f fw-w g-1">
      {!myNoteExists && <AddNoteButton />}
      {myNoteExists && (
        <NoteBadgeLink
          avatarUrl={myNoteOwner.attributes.avatar_url}
          note={myNoteData}
          title="My note"
          to={myNoteUrl}
        />
      )}
      {notes.map((note) => {
        const owner = getRelationship({ data: note, included }, "owner")
        const title = `${owner.attributes.first_name}'s note`
        const noteUrl = `${eventPath}/notes/${note.id}`

        return (
          <NoteBadgeLink
            key={note.id}
            avatarUrl={owner.attributes.avatar_url}
            note={note}
            title={title}
            to={noteUrl}
          />
        )
      })}
    </div>
  )
}

function AddNoteButton() {
  const { base_path: eventPath } = useContext(CurrentEventContext)

  return (
    <Link to={`${eventPath}/notes/new`} className="btn badge-btn fs-4 px-2">
      <Icon symbol="general#plus" className="mr-1 fs-5" />
      Add note
    </Link>
  )
}

function MyNoteButton({ note }) {
  const { base_path: eventPath } = useContext(CurrentEventContext)

  return (
    <Link
      to={`${eventPath}/notes/${dig(note, "data.id")}/edit`}
      className="btn secondary-btn minor-btn"
      state={{ note: note.data, title: "My note" }}
    >
      My note
    </Link>
  )
}
MyNoteButton.propTypes = {
  note: shape({
    data: shape({
      id: string,
    }),
  }),
}

function NoteButton({ author, note }) {
  const { base_path: eventPath } = useContext(CurrentEventContext)
  const title = `${author.attributes.first_name}'s note`

  return (
    <Link
      to={`${eventPath}/notes/${note.id}`}
      className="btn secondary-btn minor-btn"
      state={{ note, title }}
    >
      {title}
    </Link>
  )
}
NoteButton.propTypes = {
  author: shape({
    attributes: shape({
      first_name: string.isRequired,
    }),
  }),
  note: object.isRequired,
}

function NoteBadgeLink({ avatarUrl, note, title, to }) {
  return (
    <Link to={to} className="btn badge-btn avatar-btn" state={{ note, title }}>
      <div className="w-4 h-4">
        <Avatar url={avatarUrl} />
      </div>
      {title}
    </Link>
  )
}
NoteBadgeLink.propTypes = {
  avatarUrl: string.isRequired,
  note: object.isRequired,
  title: string.isRequired,
  to: string.isRequired,
}
