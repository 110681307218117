import cx from "classnames"
import { useEffect, useState, useCallback } from "react"
import { css } from "@emotion/react"
import { bool, func, string } from "prop-types"

const PaymentProcessingProgressBar = ({
  completeRegistrationId,
  onPaymentComplete,
  paymentComplete,
}) => {
  const [progress, setProgress] = useState(0)
  const [percentage, setPercentage] = useState(0)

  const calculateNextProgress = useCallback(
    (nextProgress) => {
      setProgress(completeRegistrationId || paymentComplete ? 1 : nextProgress)
      setPercentage(
        completeRegistrationId || paymentComplete ? 100 : nextProgress * 100,
      )
    },
    [completeRegistrationId, paymentComplete],
  )

  const tick = useCallback(() => {
    const nextProgress = Math.random() * 0.35 + progress
    if (nextProgress > progress) {
      calculateNextProgress(nextProgress > 0.9 ? 0.9 : nextProgress)
    }
  }, [progress, calculateNextProgress])

  useEffect(() => {
    const timeOut = setTimeout(() => tick(), Math.random() * 1000)

    return () => clearTimeout(timeOut)
  }, [tick, progress])

  useEffect(() => {
    if (completeRegistrationId || paymentComplete) {
      calculateNextProgress()

      // let progress bar finish animating before redirecting
      setTimeout(() => onPaymentComplete(completeRegistrationId), 1000)
    }
  }, [
    completeRegistrationId,
    onPaymentComplete,
    paymentComplete,
    calculateNextProgress,
  ])

  return (
    <div
      className={`${cx("progressBarContainer", "progressBar--ccapp", {
        "anim--fadeOutUp": false,
      })} ml-a mr-a mb-4 mt-4`}
      css={css`
        width: 50%;
      `}
    >
      <div className="progressBar">
        {progress > 0 && (
          <div
            className="progressBar--progress"
            style={{ width: `${percentage}%` }}
          />
        )}
      </div>
      <div className="progressBar--label anim--pulse">Processing...</div>
    </div>
  )
}

PaymentProcessingProgressBar.propTypes = {
  completeRegistrationId: string,
  onPaymentComplete: func,
  paymentComplete: bool,
}

export default PaymentProcessingProgressBar
