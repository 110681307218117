import { object, func, bool } from "prop-types"
import { restrictionsToString } from "source/registrations/restrictions"
import { NumberSpinner } from "@planningcenter/doxy-web"

const SelectionsItem = ({
  attendeeType,
  selection,
  onSelectionChange,
  isCurrent,
}) => {
  const restrictions = restrictionsToString(attendeeType)

  const selectionsToString = restrictions.length > 0 ? [restrictions] : []

  if (
    !selection.waitlist &&
    selection.availableCount > 0 &&
    !selection.isWaitlistOnly
  ) {
    selectionsToString.push(`${selection.availableCount} available`)
  }

  if (selection.priceCents > 0) {
    selectionsToString.push(selection.price)
  }

  const showStatus =
    !selection.waitlist &&
    (selection.availableCount === 0 || selection.isWaitlistOnly)

  return (
    <div className="action-drawer d-f ai-c@sm jc-sb">
      <div className="mr-2 f-1">
        <span className={`${isCurrent ? "bw-wb" : ""}`}>{selection.name}</span>
        <div className="fs-4 c-tint2">
          {selectionsToString.join(" \u2022 ")}
        </div>
      </div>
      {showStatus ? (
        <div
          className={`${
            selection.waitlistAvailable ? "success-badge" : "error-badge"
          } badge as-fs my-1`}
        >
          {selection.status}
        </div>
      ) : (
        <NumberSpinner
          max={(!selection.waitlist && selection.availableCount) || 50}
          onChange={(value) =>
            onSelectionChange({ id: selection.id, count: Number(value) })
          }
          value={selection.count}
        />
      )}
    </div>
  )
}

SelectionsItem.propTypes = {
  isCurrent: bool,
  isWaitlist: bool,
  selection: object,
  onSelectionChange: func,
  attendeeType: object,
}

export default SelectionsItem
