import { Fragment } from "react"
import { AlertMessage } from "source/shared/components"
import { buildSubdomain } from "@planningcenter/cc-url"

function alertTextFor(returnUrl) {
  const domain = buildSubdomain().replace("http://", "").replace("https://", "")
  const urlIdsRegex = /\/(\d+)+?/g
  const productUrl = returnUrl
    .replace(urlIdsRegex, "") // Removes all resource ids from url
    .split(domain)[1] // removes the domain from the url

  switch (productUrl) {
    case "/calendar/forms":
      return "To fill out this event request form, you need to log in or create an account."
    case "/registrations/events/registrations/new":
      return "To register, you need to log in or create an account."
    case "/registrations/events/reservations/new":
      return "To register, you need to log in or create an account."
    default:
      return null
  }
}

const LoginAlert = () => {
  const params = new URLSearchParams(window.location.search)
  const returnUrl = params.get("return")
  const alertText = returnUrl ? alertTextFor(returnUrl) : null

  return alertText ? (
    <Fragment>
      <AlertMessage type="info">{alertText}</AlertMessage>
    </Fragment>
  ) : null
}

export default LoginAlert
