import { useContext, useEffect, useReducer } from "react"
import { useNavigate } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { groupBy } from "lodash"
import { bool, node, string } from "source/shared/prop_types"
import { linkify, unlinkify } from "source/shared/linkify"
import { useFocus } from "source/shared/hooks/useFocus"
import { CurrentGroupContext } from "source/groups/my/groups"
import { HideFooter } from "source/Layout"

const SET_TITLE = "SET_TITLE"
const SET_MESSAGE = "SET_MESSAGE"
const TOGGLE_REPLIES_ENABLED = "TOGGLE_REPLIES_ENABLED"
const SET_IN_FLIGHT = "SET_IN_FLIGHT"
const SET_ERRORS = "SET_ERRORS"

function reducer(state, action) {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    case TOGGLE_REPLIES_ENABLED:
      return {
        ...state,
        repliesEnabled: !state.repliesEnabled,
      }
    case SET_IN_FLIGHT:
      return {
        ...state,
        inFlight: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: groupBy(action.payload.errors, "source.parameter"),
      }
    default:
      throw new Error(`Unrecognized action: ${action.type}`)
  }
}

const cancelText = "Are you sure you want to cancel?"

export default function TopicForm({ topicId, ...props }) {
  const navigate = useNavigate()
  const { id: groupId, base_path } = useContext(CurrentGroupContext)
  const isEditing = typeof topicId !== "undefined"

  const initialState = {
    title: isEditing ? props.title : "",
    message: isEditing ? unlinkify(props.message) : "",
    repliesEnabled: isEditing ? props.repliesEnabled : true,
    inFlight: false,
    errors: {},
  }

  const [{ title, message, repliesEnabled, inFlight, errors }, dispatch] =
    useReducer(reducer, initialState)
  const [focusRef, focus] = useFocus()
  useEffect(focus, [focusRef.current])

  function handleCancel(e) {
    e.preventDefault()

    const formBlank = title.length + message.length === 0
    if (!isEditing && formBlank) return navigate(`${base_path}/messages`)

    const formUnchanged =
      message === initialState.message && title === initialState.title
    if (isEditing && formUnchanged)
      return navigate(`${base_path}/messages/${topicId}`)

    if (window.confirm(cancelText)) {
      navigate(
        isEditing
          ? `${base_path}/messages/${topicId}`
          : `${base_path}/messages`,
      )
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    dispatch({ type: SET_IN_FLIGHT, payload: true })

    const topicBasePath = `/groups/v2/me/groups/${groupId}/forum_topics`

    const patchOrPost = isEditing
      ? sessionApiClient.patch(`${topicBasePath}/${topicId}`, {
          data: { attributes: { title, message: linkify(message) } },
        })
      : sessionApiClient.post(topicBasePath, {
          data: {
            attributes: {
              title,
              message: linkify(message),
              replies_enabled: repliesEnabled,
            },
          },
        })

    patchOrPost
      .then(({ data }) => {
        dispatch({ type: SET_IN_FLIGHT, payload: false })
        navigate(`${base_path}/messages/${data.id}`)
      })
      .catch((e) => {
        dispatch({ type: SET_IN_FLIGHT, payload: false })
        dispatch({ type: SET_ERRORS, payload: e })
      })
  }

  return (
    <form className="w-100%" onSubmit={handleSubmit}>
      <HideFooter />
      <div className="mb-2">
        <input
          ref={focusRef}
          type="text"
          className="w-100%"
          value={title}
          onChange={(e) =>
            dispatch({ type: SET_TITLE, payload: e.target.value })
          }
          placeholder="Title"
        />
        {errors.title &&
          errors.title.map((e) => (
            <ValidationError key={e.detail}>{e.detail}</ValidationError>
          ))}
      </div>
      <div className="mb-2">
        <textarea
          value={message}
          onChange={(e) =>
            dispatch({ type: SET_MESSAGE, payload: e.target.value })
          }
          rows={12}
          placeholder="Message"
          className="w-100%"
        />
        {errors.message &&
          errors.message.map((e) => (
            <ValidationError key={e.detail}>{e.detail}</ValidationError>
          ))}
      </div>
      <div className="d-f jc-sb ai-b">
        <div>
          {!isEditing && (
            <>
              <input
                type="checkbox"
                id="repliesEnabled"
                className="checkbox"
                checked={repliesEnabled}
                onChange={() => dispatch({ type: "TOGGLE_REPLIES_ENABLED" })}
              />
              <label htmlFor="repliesEnabled" className="checkbox-label pb-0">
                Allow replies
              </label>
            </>
          )}
        </div>
        <div>
          <button
            onClick={handleCancel}
            className="compact-btn btn text-btn m-1"
          >
            Cancel
          </button>
          <input
            type="submit"
            className="compact-btn btn"
            disabled={inFlight}
            value={isEditing ? "Save" : "Send"}
          />
        </div>
      </div>
    </form>
  )
}
TopicForm.propTypes = {
  topicId: string,
  title: string,
  message: string,
  repliesEnabled: bool,
}

function ValidationError({ children }) {
  return <div className="c-ruby">{children}</div>
}

ValidationError.propTypes = {
  children: node.isRequired,
}
