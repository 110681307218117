import { useContext } from "react"
import { get as dig } from "lodash"
import { Icon } from "source/shared/components"
import { WebBootContext } from "source/publishing/WebBoot"
import { isChurchCenterApp } from "source/Layout"
import { Heading } from "@planningcenter/doxy-web"

export function DraftModeProfilePane() {
  const { currentPerson, features, draftMode, setDraftMode } =
    useContext(WebBootContext)
  const draftModeAvailable =
    features.draft_mode &&
    dig(currentPerson, "attributes.publishing_administrator")

  if (!draftModeAvailable) return null

  return (
    <div>
      <div className="d-f ai-c mt-2 jc-sb mb-2">
        <Heading level={2} size={3} text="Administrator tools" />
      </div>

      <div className="action-drawer mb-1">
        <div className="d-f ai-c">
          <span className="c-tint3 p-r t-1p mr-1">
            <Icon symbol="general#outlined-envelope" />
          </span>
          <Heading
            level={3}
            size={4}
            text={`Draft preview mode: ${draftMode ? "Enabled" : "Disabled"}`}
          />
        </div>
        <div className="mt-2">
          This setting is available because you are a Publishing Administrator
          in Planning Center Publishing. When enabled, you&apos;ll see the draft
          version of content.
        </div>
        <div className="mt-2">
          {draftMode ? (
            <button
              className="minor-btn secondary-btn btn"
              onClick={() => setDraftMode(false)}
            >
              Finish
            </button>
          ) : (
            <button
              className="minor-btn secondary-btn btn"
              onClick={() => setDraftMode(true)}
            >
              Start
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export function DraftModeBanner() {
  if (isChurchCenterApp()) return null

  const { draftMode, setDraftMode } = useContext(WebBootContext)

  if (!draftMode) return null

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "#464C59",
        color: "white",
        padding: "1rem",
        position: "relative",
        transition: "opacity 0.4s ease, margin-top 0.4s ease",
      }}
    >
      <div className="d-f ai-c jc-c">
        <Icon
          symbol="logomark#publishing"
          style={{ width: 24, height: 24, marginRight: "0.5rem" }}
        />
        <p className="fs-2 mb-0 mr-1" style={{ letterSpacing: "0.02em" }}>
          <strong>Draft preview mode</strong>
        </p>
        <div>
          <button
            className="minor-btn secondary-btn btn"
            style={{ color: "#464C59", border: "none" }}
            onClick={() => setDraftMode(false)}
          >
            Finish
          </button>
        </div>
      </div>
    </div>
  )
}
