import { useParams, Navigate } from "react-router-dom"
import { Loading } from "source/shared/components"
import useQueryString from "source/shared/hooks/useQueryString"
import { useCreateReservation } from "source/registrations/hooks/useReservation"
import DisplayError from "./DisplayError"

const New = () => {
  const { eventId, eventSignupPath } = useParams()
  const [params] = useQueryString()
  const {
    isLoading,
    isError,
    data: reservation,
    error,
  } = useCreateReservation(eventId, params)

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <DisplayError error={error} />
  }

  let path = `/registrations/${eventSignupPath}/${eventId}/reservations/${reservation.id}`
  if (params.source) {
    path += `?source=${params.source}`
  }
  return <Navigate to={path} replace={true} />
}

export default New
