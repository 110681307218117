import { useState } from "react"
import { func, bool, string } from "prop-types"
import { noop } from "lodash"
import { Icon } from "source/shared/components"

AddPersonToggle.propTypes = {
  handleClick: func,
  toggleType: string,
}

export default function AddPersonToggle({ handleClick = noop, toggleType }) {
  const [show, setShow] = useState(false)

  const onClickHandler = () => {
    setShow((show) => !show)
    handleClick(!show)
  }

  return toggleType === "button" ? (
    <AddPersonButton showing={show} onClickHandler={onClickHandler} />
  ) : (
    <AddPersonText showing={show} onClickHandler={onClickHandler} />
  )
}

AddPersonButton.propTypes = {
  onClickHandler: func,
  showing: bool,
}

function AddPersonButton({ showing, onClickHandler }) {
  return (
    <button
      type="button"
      className="minor-btn secondary-btn btn"
      onClick={onClickHandler}
    >
      {showing ? (
        <>Close invite</>
      ) : (
        <div className="d-f ai-c" style={{ gap: "4px" }}>
          <Icon symbol="general#plus" className="fs-6" aria-hidden /> Add member
        </div>
      )}
    </button>
  )
}

AddPersonText.propTypes = {
  onClickHandler: func,
  showing: bool,
}

function AddPersonText({ showing, onClickHandler }) {
  return showing ? (
    <button
      className="button--style-reset fs-13 fw-400 lh-1.5 c-brand"
      style={{ margin: "2rem auto 0", display: "block" }}
      onClick={onClickHandler}
    >
      Close invite
    </button>
  ) : (
    <p className="fs-13 lh-1.5 ta-c mt-4">
      Looking to{" "}
      <button
        onClick={onClickHandler}
        className="button--style-reset fs-13 lh-1.5 fw-400 c-brand p-0"
      >
        add a member
      </button>
      ?
    </p>
  )
}
