import { useContext, useState, useEffect } from "react"
import { func, object, array, bool } from "prop-types"
import { Subheading } from "@planningcenter/doxy-web"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { WebBootContext } from "source/publishing/WebBoot"
import classNames from "classnames"
import { Displayable } from "source/directory/edit/Displayables"

DirectoryContactSettings.propTypes = {
  person: object.isRequired,
  directoryPrivacySettings: object.isRequired,
  onPrivacySettingsUpdate: func,
  allowedContactFieldTypes: array,
  contactFields: array,
  disabled: bool,
}

export function DirectoryContactSettings({
  person,
  directoryPrivacySettings,
  onPrivacySettingsUpdate,
  allowedContactFieldTypes,
  contactFields,
  disabled,
}) {
  const { directoryPrivateContactMode, directoryPrivacyModeEnabled } =
    directoryPrivacySettings
  const [checked, setChecked] = useState(
    directoryPrivateContactMode === "enabled",
  )
  const { current_person } = useContext(ServerRenderedProps)
  const {
    abilities: {
      people: { directory_privacy_mode_enabled: globalPrivacyEnabled },
    },
  } = useContext(WebBootContext)

  const displayableFields = contactFields.some(
    (field) => !!field.props.displayables.length,
  )

  const disableGlobalPrivacyAllowContact =
    disabled || !person.data.attributes.has_email_address

  const disableIndividualAllowContact =
    disabled ||
    !directoryPrivacyModeEnabled ||
    !person.data.attributes.has_email_address

  useEffect(() => {
    setChecked(directoryPrivateContactMode === "enabled")
  }, [directoryPrivateContactMode])

  function handleUpdateContactPreference(event) {
    setChecked(!checked)

    onPrivacySettingsUpdate({
      type: "update",
      payload: {
        personId: person.data.id,
        directoryPrivateContactMode: event.target.checked
          ? "enabled"
          : "disabled",
        directoryPrivacyModeEnabled: directoryPrivacyModeEnabled,
      },
    })
  }

  function handleUpdatePrivacyModePreference(event) {
    onPrivacySettingsUpdate({
      type: "update",
      payload: {
        personId: person.data.id,
        directoryPrivateContactMode:
          event.target.value === "disabled"
            ? "disabled"
            : directoryPrivateContactMode,
        directoryPrivacyModeEnabled: event.target.value === "enabled",
      },
    })
  }

  const useFirstPerson = current_person.data.id === person.data.id
  const description = () => {
    if (person.data.attributes.has_email_address) {
      return `Directory users will be able to send ${
        useFirstPerson ? "you" : person.data.attributes.first_name
      } an email through Church Center, but will not see
      ${
        useFirstPerson ? "your" : `${person.data.attributes.first_name}'s`
      } email address until ${
        useFirstPerson
          ? "you reply"
          : `${person.data.attributes.first_name} replies`
      }.`
    } else {
      return `An email address is required to allow other directory users to contact ${
        useFirstPerson ? "you" : person.data.attributes.first_name
      } through Church Center.`
    }
  }

  const missingFields = allowedContactFieldTypes.map((field) =>
    field === "email" ? `${field} address` : field,
  )
  const missingFieldsMessage =
    missingFields.length > 0
      ? `${
          missingFields[0].match(/^[aeiou]/) ? "an" : "a"
        } ${missingFields.join(" or ")}`
      : ""

  const noContactInfoMessage = useFirstPerson
    ? `You don't have ${missingFieldsMessage} associated with your profile`
    : `This person doesn’t have ${missingFieldsMessage} associated with their profile`

  return !globalPrivacyEnabled && contactFields.length === 0 ? null : (
    <div className="mt-2">
      <div className="mb-4p">
        <Subheading level={3} color="tint2" text="Contact information" />
      </div>
      {globalPrivacyEnabled ? (
        <div>
          <Displayable
            id={`allow_contact_${person.data.id}`}
            checked={checked}
            onChange={handleUpdateContactPreference}
            disabled={disableGlobalPrivacyAllowContact}
          >
            <span>
              Allow others to contact{" "}
              {useFirstPerson ? "me" : person.data.attributes.first_name}
            </span>
            <p
              className={classNames("fs-5", {
                "c-tint2": !disableGlobalPrivacyAllowContact,
              })}
            >
              {description()}
            </p>
          </Displayable>
        </div>
      ) : (
        <>
          {displayableFields ? (
            <div className="d-f fd-c">
              <input
                type="radio"
                id={`${person.data.id}_privacy_mode_enabled`}
                className="radio"
                checked={directoryPrivacyModeEnabled}
                onChange={handleUpdatePrivacyModePreference}
                value={"enabled"}
                disabled={disabled}
              />
              <label
                className="radio-label mb-1"
                htmlFor={`${person.data.id}_privacy_mode_enabled`}
                css={{ cursor: "pointer", fontWeight: 400 }}
              >
                Hide {`${useFirstPerson ? "my" : ""}`} contact info
              </label>
              <div className="ml-3">
                <Displayable
                  id={`allow_contact_${person.data.id}`}
                  checked={checked}
                  onChange={handleUpdateContactPreference}
                  disabled={disableIndividualAllowContact}
                >
                  <span>Allow contact through app</span>
                  <p
                    className={classNames("fs-5 pr-5 mb-0", {
                      "c-tint2": !disableIndividualAllowContact,
                    })}
                  >
                    {description()}
                  </p>
                </Displayable>
              </div>
              <input
                type="radio"
                id={`${person.data.id}_privacy_mode_disabled`}
                className="radio"
                checked={!directoryPrivacyModeEnabled}
                onChange={handleUpdatePrivacyModePreference}
                value={"disabled"}
                disabled={disabled}
              />
              <label
                className="radio-label mb-1"
                htmlFor={`${person.data.id}_privacy_mode_disabled`}
                css={{ cursor: "pointer", fontWeight: 400 }}
              >
                Show selected contact info
              </label>
              <div className="ml-3">{contactFields}</div>
            </div>
          ) : (
            <div>
              <p className="c-tint2 fs-5 pr-5">{noContactInfoMessage}</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}
