export function getRelationship({ data, included = [] }, key) {
  const relationship = data?.relationships[key]?.data

  if (!relationship) return null

  if (Array.isArray(relationship)) {
    return relationship.map(({ type, id }) =>
      included.find(
        ({ type: includedType, id: includedId }) =>
          includedType === type && includedId === id,
      ),
    )
  } else {
    return included.find(
      ({ type, id }) => type === relationship.type && id === relationship.id,
    )
  }
}
