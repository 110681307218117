import { useEffect, useState } from "react"
import { string, func, bool, node, object } from "prop-types"
import { range, rangeRight } from "lodash"
import moment from "moment"
import { Select } from "./Select"

const months = [
  { label: "Month", value: "" },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
]

const currentYear = new Date().getFullYear()
const startYear = currentYear - 99
const exclusiveRangeEndYear = currentYear + 1

const initDate = { day: "", month: "", year: "" }

const DateSelector = ({
  name,
  onChange,
  disabled = false,
  initialValue = null,
  isDayFirst = false,
  label = null,
  labelStyle,
  selectStyle,
  readOnly,
}) => {
  const [date, setDate] = useState(initDate)
  const [availableDays, setAvailableDays] = useState(31)
  const [disabledFields, setDisabledFields] = useState(disabled)

  useEffect(() => {
    if (disabled) setDisabledFields(true)
  }, [])

  useEffect(() => {
    if (initialValue === "") {
      resetDateSelector()
      return
    }
    if (!initialValue) return

    const momentDate = moment(initialValue)
    setDate({
      day: momentDate.date().toString(),
      month: (momentDate.month() + 1).toString(),
      year: momentDate.year().toString(),
    })
    setAvailableDays(momentDate.daysInMonth())
  }, [initialValue])

  const resetDateSelector = () => {
    setDate(initDate)
  }

  const handleChange = (value) => {
    setDate((prevDate) => {
      const nextDate = { ...prevDate, ...value }
      const dateAttributes = formatDateAttributesForMoment(nextDate)
      const date = recalculateDate(dateAttributes)

      if (date.isValid()) {
        onChange(date.format("YYYY-MM-DD"))
      }
      return nextDate
    })
  }

  const years = [
    { label: "Year", value: "" },
    // 1885 is the magic number for "no year set" in Planning Center People.
    // It's also the year Marty and Doc travel to in Back to the Future III.
    ...(date.year ? [{ label: "---", value: "1885" }] : []),
    ...rangeRight(startYear, exclusiveRangeEndYear).map((year) => ({
      label: year.toString(),
      value: year.toString(),
    })),
  ]

  const formatDateAttributesForMoment = (nextDate) =>
    Object.fromEntries(
      Object.entries(nextDate).map(([k, v]) => [k, v ? Number(v) : null]),
    )

  const recalculateDate = ({ year, month, day }) => {
    const offsetMonthForMoment = month - 1
    const momentDate = moment([
      year || new Date().getFullYear(),
      offsetMonthForMoment,
    ])
    const daysInMonth = momentDate.daysInMonth()
    setAvailableDays(daysInMonth)

    if (!year || !day) return moment(null)

    if (day > 0 && day <= daysInMonth) {
      return momentDate.set("date", day)
    } else {
      return moment(null)
    }
  }

  const availableDaysWithExclusiveEndRange = availableDays + 1
  const days = [
    { label: "Day", value: "" },
    ...range(1, availableDaysWithExclusiveEndRange).map((day) => ({
      label: day.toString(),
      value: day.toString(),
    })),
  ]

  return (
    <div>
      {label && (
        <label
          className="label"
          style={labelStyle}
          htmlFor={`${name}_${isDayFirst ? "day" : "month"}`}
        >
          {label}
        </label>
      )}
      <div className="d-f" style={{ gap: "0.5rem" }}>
        {isDayFirst && (
          <Select
            disabled={disabledFields}
            style={selectStyle}
            name={`${name}_day`}
            ariaLabel="select day"
            value={date.day}
            onChange={({ target: { value } }) => handleChange({ day: value })}
            options={days}
            readOnly={readOnly}
          />
        )}

        <Select
          disabled={disabledFields}
          style={selectStyle}
          name={`${name}_month`}
          ariaLabel="select month"
          value={date.month}
          onChange={({ target: { value } }) => handleChange({ month: value })}
          options={months}
          readOnly={readOnly}
        />

        {!isDayFirst && (
          <Select
            disabled={disabledFields}
            style={selectStyle}
            name={`${name}_day`}
            ariaLabel="select day"
            value={date.day}
            onChange={({ target: { value } }) => handleChange({ day: value })}
            options={days}
            readOnly={readOnly}
          />
        )}

        <Select
          disabled={disabledFields}
          style={selectStyle}
          name={`${name}_year`}
          ariaLabel="select year"
          value={date.year}
          onChange={({ target: { value } }) => handleChange({ year: value })}
          options={years}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

DateSelector.propTypes = {
  disabled: bool,
  initialValue: string,
  isDayFirst: bool,
  label: node,
  labelStyle: object,
  selectStyle: object,
  name: string.isRequired,
  onChange: func.isRequired,
  readOnly: bool,
}

export { DateSelector }
