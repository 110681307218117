import { arrayOf, shape, bool, number, string, object } from "prop-types"
import Attendee from "./Attendee"
import NamedAttendee from "./NamedAttendees/NamedAttendee"
import WaitlistedAttendee from "./NamedAttendees/WaitlistedAttendee"

const Attendees = ({
  attendeeSummaries,
  registerForSeparateTimes,
  hasAttendeesModule,
  registration,
}) => {
  const { date } = attendeeSummaries[0] ?? {}

  // canceled attendees should be at the bottom of the list
  const sortedAttendeeSummaries = attendeeSummaries.sort((a, b) => {
    return a.canceled === b.canceled ? 0 : a.canceled ? 1 : -1
  })

  return (
    <div className="mb-3">
      {date && registerForSeparateTimes && <div className="mb-2">{date}</div>}
      {hasAttendeesModule
        ? sortedAttendeeSummaries.map((attendeeSummary) =>
            attendeeSummary.waitlist ? (
              <WaitlistedAttendee
                key={attendeeSummary.id}
                registration={registration}
                attendeeSummary={attendeeSummary}
              />
            ) : (
              <NamedAttendee
                key={attendeeSummary.id}
                registration={registration}
                attendeeSummary={attendeeSummary}
              />
            ),
          )
        : sortedAttendeeSummaries.map((attendeeSummary) => (
            <Attendee
              key={attendeeSummary.id}
              attendeeSummary={attendeeSummary}
            />
          ))}
    </div>
  )
}

Attendees.propTypes = {
  registerForSeparateTimes: bool,
  hasAttendeesModule: bool,
  attendeeSummaries: arrayOf(
    shape({
      id: number,
      date: string,
    }),
  ),
  registration: object,
}

export default Attendees
