import { time as formatTime } from "@churchcenter/datetime-fmt"
import { Icon } from "source/shared/components"
import AcceptDeclineControl from "./AcceptDeclineControl"
import { array, bool, func, object } from "prop-types"
import { SCHEDULE_STATUSES } from "source/services/constants"
import moment from "moment"
import classNames from "classnames"

export default function ScheduleItem({
  handleAcceptToggle,
  handleDeclineToggle,
  schedule,
  scheduleResponseData: { declinedTimeIds },
  time,
  toggleableScheduleIds,
}) {
  const isAccepted = schedule.splitTeam
    ? !declinedTimeIds.includes(time.id)
    : declinedTimeIds.length === 0

  const displayName = time
    ? `${schedule.displayName} ${moment(time.starts_at).format(
        "ddd",
      )} ${formatTime(time.starts_at, {
        showTimeZone: false,
      })}`
    : schedule.displayName

  const handleAccept = () => {
    if (isAccepted) return

    handleAcceptToggle({ schedule, time })
  }

  const handleDecline = () => {
    if (!isAccepted) return

    handleDeclineToggle({ schedule, time })
  }

  return (
    <div key={schedule.id} className="d-f jc-sb">
      <div
        key={schedule.id}
        className={classNames("d-f ai-c", {
          "mb-1": toggleableScheduleIds.length > 0,
        })}
      >
        <Icon
          className="mr-4p"
          symbol={SCHEDULE_STATUSES[schedule.status].symbol}
          css={{ fill: SCHEDULE_STATUSES[schedule.status].color }}
        />
        <div>{displayName}</div>
      </div>
      {toggleableScheduleIds.includes(schedule.id) && (
        <AcceptDeclineControl
          isAccepted={isAccepted}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />
      )}
    </div>
  )
}

ScheduleItem.propTypes = {
  handleAcceptToggle: func,
  handleDeclineToggle: func,
  schedule: object,
  scheduleResponseData: object,
  showAcceptDeclineControl: bool,
  time: object,
  toggleableScheduleIds: array,
}
