import { useRef, useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"
import { Icon } from "source/shared/components"

import CustomField from "./CustomField"

FileField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      required: bool.isRequired,
      settings: shape({
        multiple_files: bool.isRequired,
      }),
    }),
    id: string.isRequired,
  }),
  maxTotalFileSize: number,
  onAddFile: func,
  onRemoveFile: func,
  totalFileSizeExceedsLimit: bool,
}

export default function FileField({
  errors = [],
  field,
  maxTotalFileSize,
  onAddFile,
  onRemoveFile,
  totalFileSizeExceedsLimit,
}) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadErrors, setUploadErrors] = useState([])

  const {
    attributes: {
      description,
      label,
      required,
      settings: { multiple_files },
    },
    id,
  } = field

  const fileInputRef = useRef()
  const maxNumberOfFiles = multiple_files ? 5 : 1
  const initialBtnMessage = `Choose file${multiple_files ? "s" : ""}…`
  const updateBtnMessage = multiple_files ? "Add files…" : initialBtnMessage
  const buttonText =
    selectedFiles.length > 0 ? updateBtnMessage : initialBtnMessage
  const noSelectionMsg = `No file${
    multiple_files ? "s" : ""
  } currently selected`
  const selectionMsg = `${selectedFiles.length} file${
    selectedFiles.length > maxNumberOfFiles ? "s" : ""
  } currently selected`
  const selectedFilesMessage =
    selectedFiles.length > 0 ? selectionMsg : noSelectionMsg
  const blockList = [
    "ade",
    "adp",
    "apk",
    "appx",
    "appxbundle",
    "bat",
    "cab",
    "chm",
    "cmd",
    "com",
    "cpl",
    "dll",
    "dmg",
    "ex",
    "ex_",
    "exe",
    "hta",
    "ins",
    "isp",
    "iso",
    "jar",
    "js",
    "jse",
    "lib",
    "lnk",
    "mde",
    "msc",
    "msi",
    "msix",
    "msixbundle",
    "msp",
    "mst",
    "nsh",
    "pif",
    "ps1",
    "scr",
    "sct",
    "shb",
    "sys",
    "vb",
    "vbe",
    "vbs",
    "vxd",
    "wsc",
    "wsf",
    "wsh",
    "zip",
  ]

  const atFileCapacity = () => selectedFiles.length === maxNumberOfFiles

  const exceedsNumberLimit = (files) =>
    [...selectedFiles, ...files].length > maxNumberOfFiles

  const formatFileSize = (bytes, precision = 0) => {
    if (bytes < 1048576) {
      return (bytes / 1024).toFixed(precision) + " KB"
    } else {
      return (bytes / 1048576).toFixed(precision) + " MB"
    }
  }

  const handleFileChange = ({ id }) => {
    const files = Object.values(fileInputRef.current.files).filter(
      (file) => !selectedFiles.find((att) => att.name === file.name),
    )

    if (files.length === 0 || atFileCapacity()) {
      return
    }

    if (files.find((file) => isOversized(file))) {
      setUploadErrors([
        {
          source: {
            parameter: "attachment",
          },
          detail: "size cannot exceed 10 MB.",
        },
      ])
      return
    }

    if (files.find((file) => isUnsupportedExtension(file))) {
      setUploadErrors([
        {
          source: {
            parameter: "attachment",
          },
          detail: "type is not supported.",
        },
      ])
      return
    }

    if (exceedsNumberLimit(files)) {
      setUploadErrors([
        {
          source: {
            parameter: label,
          },
          detail: `can only include ${maxNumberOfFiles} attachment${
            files.length > 1 ? "s" : ""
          }.`,
        },
      ])
      return
    }

    const newSelectedFiles = multiple_files
      ? [...selectedFiles, ...files]
      : [files[0]]

    setSelectedFiles(newSelectedFiles)

    onAddFile({ form_field_id: id, files: newSelectedFiles })
  }

  const handleFileInputClick = () => fileInputRef.current?.click()

  const handleFileRemove = ({ id, name }) => {
    fileInputRef.current.value = null
    const newSelectedFiles = selectedFiles.filter(
      (fileSelected) => fileSelected.name !== name,
    )
    setSelectedFiles(newSelectedFiles)
    onRemoveFile(id, name)
  }

  const isOversized = (file) => file.size > 1024 * 1024 * 10

  const isUnsupportedExtension = (file) => {
    const extension = file.name.split(".").pop().toLowerCase()

    return blockList.indexOf(extension) !== -1
  }

  const isDisabled = atFileCapacity() || totalFileSizeExceedsLimit

  return (
    <CustomField
      errors={[...errors, ...uploadErrors]}
      {...{ description, label, required }}
    >
      <div className="d-f fw-w">
        <div className="mr-2 mb-1">
          <button
            type="button"
            className={`btn minor-btn secondary-btn ${
              isDisabled ? "disabled-btn" : null
            }`}
            style={{ minWidth: "7rem" }}
            htmlFor={`field${id}`}
            onClick={handleFileInputClick}
            disabled={isDisabled}
          >
            {buttonText}
          </button>
          <input
            className="screen-reader-text"
            disabled={isDisabled}
            id={`field${id}`}
            multiple={multiple_files}
            onChange={() => handleFileChange({ id })}
            ref={fileInputRef}
            tabIndex={-1}
            type="file"
          />
        </div>
        <div className="fs-4 pt-2p c-tint2">
          <p className="mb-0">{selectedFilesMessage}</p>
        </div>
      </div>
      <div className="fs-5 c-tint3 mb-1 lh-1.25">
        {totalFileSizeExceedsLimit ? (
          <div className="d-f">
            <span className="fs-6">
              <Icon
                className="c-citrine mr-4p ml-4p mt-2p"
                symbol="general#exclamation-triangle"
              />
            </span>
            <span>
              {`You have exceeded the maximum total file size for attachments on this
          form (${formatFileSize(
            maxTotalFileSize,
          )}). To upload additional files, please remove some first.`}
            </span>
          </div>
        ) : (
          <>
            {multiple_files ? (
              <span>Add up to {maxNumberOfFiles} files.</span>
            ) : (
              <span>Choose 1 file.</span>
            )}{" "}
            <span>Maximum file size 10 MB.</span>
          </>
        )}
      </div>
      <ul className="unstyled mb-1">
        {selectedFiles.map((fileSelected) => (
          <li className="d-f ai-c fs-4 pr-2 mb-1" key={fileSelected.name}>
            <div
              className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
              style={{
                width: 28,
                height: 28,
                flex: "0 0 28px",
                border: "1px solid",
              }}
            >
              {["jpg", "jpeg", "gif", "png"].includes(
                fileSelected.name.split(".").pop().toLowerCase(),
              ) ? (
                <img
                  alt={fileSelected.name}
                  width={28}
                  height={28}
                  style={{ objectFit: "cover" }}
                  src={window.URL.createObjectURL(fileSelected)}
                />
              ) : (
                <div className="fs-5 c-tint3 mt-2p">
                  <Icon symbol="general#outlined-generic-file" />
                </div>
              )}
            </div>
            <div className="d-f fd-c overflow-text lh-1.25">
              <span className="overflow-text">{fileSelected.name}</span>
              <span className="fs-5 c-tint3">
                {formatFileSize(fileSelected.size, 1)}
              </span>
            </div>
            <button
              type="button"
              className="btn minor-compact-btn destroy-btn ml-1 mb-2"
              style={{ marginBottom: 14 }}
              title={`Remove ${fileSelected.name}`}
              aria-label={`Remove ${fileSelected.name}`}
              onClick={() => handleFileRemove({ id, name: fileSelected.name })}
            >
              <Icon symbol="general#x" title="Remove" />
            </button>
          </li>
        ))}
      </ul>
    </CustomField>
  )
}
