import { string, bool } from "prop-types"
import { AlertMessage } from "source/shared/components"
import { WideLayout } from "source/Layout"
import { useRegistration } from "source/registrations/hooks/useRegistration"
import ConfirmationNotify from "./Notification"
import Header from "./Header"
import RegistrationCompletionAlerts from "./RegistrationCompletionAlerts"
import AttendeeSelections from "./AttendeeSelections"
import RegistrantQuestions from "./RegistrantQuestions"
import RegistrationInvoice from "./RegistrationInvoice"
import Help from "./Help"
import useQueryString from "source/shared/hooks/useQueryString"
import { Grid, Heading } from "@planningcenter/doxy-web"

const AuthenticatedConfirmation = ({
  registrationId,
  isNotificationShowing = false,
}) => {
  const { errors, data: registration } = useRegistration(registrationId)

  const { hasPaymentsModule, hasRegistrantQuestionsModule } = registration.event

  const [params] = useQueryString()
  const newRegistrationUrl = `${registration.event.newRegistrationUrl}${
    params.source ? `?source=${params.source}` : ""
  }`

  if (errors) {
    return <AlertMessage>{errors[0].detail}</AlertMessage>
  }

  return (
    <WideLayout>
      {isNotificationShowing && (
        <ConfirmationNotify
          email={registration.responsiblePerson.emailAddress}
        />
      )}
      {registration.canceledOn ? (
        <AlertMessage type="info" icon="general#info-circle">
          This registration has been cancelled. Do you need to{" "}
          <a href={newRegistrationUrl}>register again</a>?
        </AlertMessage>
      ) : (
        <RegistrationCompletionAlerts registration={registration} />
      )}
      <Header registration={registration} />

      <Grid columns={[1, { md: 2 }]} gap={2}>
        {/* Left column */}
        <div>
          <AttendeeSelections registration={registration} />

          {hasRegistrantQuestionsModule &&
            registration.event.questions.length > 0 && (
              <RegistrantQuestions registration={registration} />
            )}
        </div>

        {/* Right column */}
        <div>
          {hasPaymentsModule && registration.hasCost && (
            <div className="mb-3">
              <Heading level={2} size={3} text="Payment summary" />
              <RegistrationInvoice registration={registration} />
            </div>
          )}

          <Help registration={registration} />
        </div>
      </Grid>
    </WideLayout>
  )
}

AuthenticatedConfirmation.propTypes = {
  registrationId: string,
  isNotificationShowing: bool,
}

export default AuthenticatedConfirmation
