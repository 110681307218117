import { css } from "@emotion/react"
import { useContext, useCallback, useMemo, Fragment } from "react"
import PropTypes from "prop-types"
import { get as dig } from "lodash"
import { Navigate, useParams } from "react-router-dom"
import { WebBootContext } from "source/publishing/WebBoot"
import { FullscreenLayout } from "source/Layout"
import {
  Error403,
  Error404,
  RoutingAwareDangerousHTML,
} from "source/shared/components"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import { CalendarEvents } from "./blocks/CalendarEvents"
import { Registrations } from "./blocks/Registrations"
import { useApiSuspenseQuery } from "source/shared/hooks/useApiSuspenseQuery"
import { useJolt } from "source/shared/hooks/useJolt"

export function CustomPage() {
  const { slug } = useParams()
  const { draftMode, currentOrganization } = useContext(WebBootContext)
  const version = draftMode ? "draft" : "published"

  const { data: page, refetch: reloadPage } = useApiSuspenseQuery({
    path: `/publishing/v2/pages/${slug}@${version}`,
    queryClientOptions: {
      refetchOnWindowFocus: false,
    },
  })

  const conditionallyReloadPage = useCallback(
    (event) => {
      const data = JSON.parse(event.data)
      const prevPage = { slug, version }
      const nextPage = {
        slug: dig(data, "data.relationships.slug.data.id"),
        version: dig(data, "data.relationships.version.data.id"),
      }
      if (willBeOnTopOfVersionStack({ prevPage, nextPage })) reloadPage()
    },
    [reloadPage, slug, version],
  )

  useJolt(
    `/publishing/v2/jolt_subscribe`,
    `organization-${currentOrganization.id}.publishing.church_center.v2.events.page`,
    "publishing.church_center.v2.events.page.created",
    conditionallyReloadPage,
  )

  return <PageRendering page={page} />
}

WebBootedHomePage.propTypes = {
  page: PropTypes.shape({
    attributes: PropTypes.shape({
      redirect_uri: PropTypes.string,
    }),
  }),
  notFound: PropTypes.node.isRequired,
}

export function WebBootedHomePage({ page, notFound }) {
  page = useMemo(
    function coereceOurPreloadedHomePageIntoTheStructureTheApiReturns() {
      return page ? { data: page } : { errors: [{ status: "404" }] }
    },
    [page],
  )

  return <PageRendering page={page} notFound={notFound} />
}

PageLayout.propTypes = {
  page: PropTypes.object.isRequired,
}
function PageLayout({ page }) {
  const { attributes } = page
  const { title, content, blocks, header_image: headerImage, slug } = attributes

  useQRCode(slug)

  return (
    <div css={styles.pageLayout}>
      <OpenGraphTags
        title={title}
        imageUrl={headerImage?.attributes?.variants?.small}
      />
      {headerImage && (
        <div css={styles.headerImageWrapper}>
          <img
            alt="Page header"
            css={styles.headerImage}
            src={headerImage.attributes.variants.small}
            srcSet={`${headerImage.attributes.variants.small} 640w, ${headerImage.attributes.variants.medium} 960w, ${headerImage.attributes.variants.large} 2000w`}
          />
        </div>
      )}
      {blocks?.length > 0 ? (
        <FullscreenLayout>
          <PageBlocks blocks={blocks} />
        </FullscreenLayout>
      ) : (
        <RoutingAwareDangerousHTML html={content} />
      )}
    </div>
  )
}

PageBlocks.propTypes = {
  blocks: PropTypes.array.isRequired,
}
function PageBlocks({ blocks }) {
  const reactiveBlockMap = {
    CalendarEvents: CalendarEvents,
    Registrations: Registrations,
  }

  function block(block) {
    const blockFn = reactiveBlockMap[block.type] || rawHtml
    return blockFn(block)
  }

  function rawHtml(block) {
    return (
      <RoutingAwareDangerousHTML
        html={block.attributes.to_html}
        className="page-block"
      />
    )
  }

  return blocks.map((b) => <Fragment key={b.id}>{block(b)}</Fragment>)
}

function PageRendering({ page, notFound = <Error404 /> }) {
  if (page.errors) {
    return page.errors[0].status === "403" ? <Error403 /> : notFound
  } else if (page.data.attributes.redirect_uri) {
    return <Navigate to={page.data.attributes.redirect_uri} replace />
  } else {
    return <PageLayout page={page.data} />
  }
}
PageRendering.propTypes = {
  page: PropTypes.oneOfType([
    PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.shape({ status: PropTypes.string })),
    }),
    PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          redirect_uri: PropTypes.string,
        }),
      }),
    }),
  ]),
  notFound: PropTypes.node,
}

function willBeOnTopOfVersionStack({ prevPage, nextPage }) {
  if (prevPage.slug === nextPage.slug) {
    switch (prevPage.version) {
      case "draft":
        return ["draft", "published"].includes(nextPage.version)
      case "published":
        return nextPage.version === "published"
    }
  }

  return false
}

const styles = {
  pageLayout: css`
    .page-block:not(:has([data-section-header]))
      + .page-block:has([data-section-header]) {
      padding-top: 1.5em;

      @media screen and (min-width: 720px) {
        padding-top: 2em;
      }
    }
  `,
  headerImageWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 24px;
    max-width: 640px;

    @media screen and (max-width: 639px) {
      margin: -24px -24px 24px;
    }
  `,
  headerImage: css`
    display: block;
    width: 100%;
  `,
}
