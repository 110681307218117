import { Suspense, createContext, useContext } from "react"
import { node, shape, string } from "prop-types"
import { Date } from "source/shared/DatetimeFmt"
import { Modal } from "source/shared/components/Modal"
import { LeaveGroupModalContent } from "source/groups/components/LeaveGroupModalContent"
import { useReloadMyGroups } from "../hooks/useMyGroups"
import { useFirstPageOfLeaders } from "../hooks/useMemberships"
import { useNextNotCanceledEvent } from "../hooks/useNextNotCanceledEvent"
import { Loading } from "source/shared/components"

const GroupContext = createContext()

export function Group({ group }) {
  return (
    <Suspense fallback={<Loading />}>
      <GroupContext.Provider value={group}>
        <GroupArticle>
          <ImageLink />
          <Row>
            <Column>
              <Header />
              <Leaders />
              <ScheduleInfo />
            </Column>
            <LeaveThisGroup />
          </Row>
        </GroupArticle>
      </GroupContext.Provider>
    </Suspense>
  )
}
Group.propTypes = {
  group: shape({
    id: string.isRequired,
    attributes: shape({
      church_center_web_my_group_url: string.isRequired,
      enrollment_status: string.isRequired,
      header_image: shape({
        medium: string.isRequired,
      }).isRequired,
      name: string.isRequired,
      schedule: string,
    }).isRequired,
  }).isRequired,
}

function GroupArticle({ children }) {
  return <article className="d-f mb-2">{children}</article>
}
GroupArticle.propTypes = { children: node }

function ImageLink() {
  const {
    attributes: { church_center_web_my_group_url, name, header_image },
  } = useContext(GroupContext)

  return (
    <div className="mr-2">
      <a href={church_center_web_my_group_url}>
        <div className="profile-item__image">
          <img src={header_image.medium} alt={`Logo for ${name}`} />
        </div>
      </a>
    </div>
  )
}

function Row({ children }) {
  return <div className="f-1 d-f@sm jc-sb g-1">{children}</div>
}
Row.propTypes = { children: node }

function Column({ children }) {
  return <div className="f-1">{children}</div>
}
Column.propTypes = { children: node }

function Header() {
  const {
    attributes: { name, church_center_web_my_group_url, enrollment_status },
  } = useContext(GroupContext)

  return (
    <div className="lh-1.25">
      <NameLink href={church_center_web_my_group_url}>{name}</NameLink>
      <EnrollmentStatus>{enrollment_status}</EnrollmentStatus>
    </div>
  )
}

function NameLink({ href, children }) {
  return (
    <a href={href} style={{ color: "inherit", wordBreak: "break-all" }}>
      {children}
    </a>
  )
}
NameLink.propTypes = {
  href: string.isRequired,
  children: node,
}

function EnrollmentStatus({ children }) {
  return (
    <span className={`badge ml-4p ${children}-badge tt-c`}>{children}</span>
  )
}
EnrollmentStatus.propTypes = { children: node }

function Leaders() {
  return (
    <div className="fs-4 lh-1.5 c-tint2">
      <Suspense fallback={null}>
        <LoadedLeaders />
      </Suspense>
    </div>
  )
}

function LoadedLeaders() {
  const group = useContext(GroupContext)
  const {
    data: leaders,
    meta: { total_count: leadersCount },
  } = useFirstPageOfLeaders(group)
  const leaderLabel = leadersCount === 1 ? "Leader" : "Leaders"
  const leaderNames = leaders
    .map(
      ({ attributes: { first_name, last_name } }) =>
        `${first_name} ${last_name}`,
    )
    .join(", ")

  return (
    <>
      {leadersCount > 0 && (
        <div>
          <span className="c-secondary">{leaderLabel}:&nbsp;</span>
          {leaderNames}
        </div>
      )}
    </>
  )
}

function ScheduleInfo() {
  return (
    <div className="fs-4 lh-1.5 c-tint2">
      <Suspense fallback={null}>
        <LoadedScheduleInfo />
      </Suspense>
    </div>
  )
}

function LoadedScheduleInfo() {
  const group = useContext(GroupContext)
  const {
    attributes: { schedule },
  } = group
  const nextEvent = useNextNotCanceledEvent(group)

  return (
    <>
      {schedule && <div>Schedule: {schedule}</div>}
      {nextEvent?.attributes.starts_at && (
        <div>
          Next event:&nbsp;
          <Date start={nextEvent.attributes.starts_at} year style="short" />
        </div>
      )}
    </>
  )
}

function LeaveThisGroup() {
  const {
    id: groupId,
    attributes: { name },
  } = useContext(GroupContext)
  const reloadMyGroups = useReloadMyGroups()

  return (
    <Modal>
      <Modal.Trigger className="mt-1 mt-0@sm destroy-btn secondary-btn minor-btn btn">
        Leave this group
      </Modal.Trigger>
      <LeaveGroupModalContent
        groupId={groupId}
        groupName={name}
        afterSubmit={reloadMyGroups}
      />
    </Modal>
  )
}
