import { func, string } from "prop-types"
import { capitalize } from "lodash"

import { useViewPreferences } from "source/calendar/hooks/useViewPreferences"

const ViewButtons = ({ onSelectView, selectedValue }) => {
  const handleClick = (view) => {
    onSelectView(view)
  }
  const { availableViews: views } = useViewPreferences()

  return (
    <div className="d-f ai-c d-f@sm fw-w jc-c">
      {views.map((view, idx) => (
        <div key={idx} className="d-b d-f@sm fw-w">
          <button
            className={
              selectedValue === view
                ? "btn compact-btn"
                : "px-2 fs-4 fw-600 compact-btn text-btn"
            }
            onClick={() => handleClick(view)}
          >
            {capitalize(view)}
          </button>
        </div>
      ))}
    </div>
  )
}

ViewButtons.propTypes = {
  onSelectView: func.isRequired,
  selectedValue: string.isRequired,
}

export default ViewButtons
