import { useSession } from "source/shared/hooks/useSession"
import { apiResource, arrayOf, func, string } from "source/shared/prop_types"
import Topic, { BOT_AUTHOR_ID } from "source/groups/messaging/Topic"
import { CurrentGroupProvider } from "source/groups/my/groups"
import { TopicsDataProvider } from "source/groups/messaging/TopicsDataContext"
import Replies from "source/groups/messaging/Topic/Replies"
import NewReply from "source/groups/messaging/Topic/NewReply"
import { Reply } from "source/publishing/sermons/Episode/Reply"
import { AuthorName } from "source/groups/messaging/Topic/AuthorName"
import { Icon } from "source/shared/components"

function notABot(reply) {
  return reply?.relationships?.author?.data?.id !== BOT_AUTHOR_ID
}

export default function GroupTopic({ group, topic, setGroupId, leaderLabel }) {
  return (
    <CurrentGroupProvider group={group}>
      <TopicsDataProvider>
        <Topic group={group} topic={topic}>
          <Replies
            RepliesChunk={RepliesChunk}
            repliesFilter={{ func: notABot, queryParamFilter: "human_authors" }}
            leaderLabel={leaderLabel}
          />
          <NewReply>
            <NewReplyHeader group={group} setGroupId={setGroupId} />
            <NewReply.Editor>
              <NewReply.SingleLineInput />
            </NewReply.Editor>
          </NewReply>
        </Topic>
      </TopicsDataProvider>
    </CurrentGroupProvider>
  )
}

GroupTopic.propTypes = {
  group: apiResource.isRequired,
  topic: apiResource.isRequired,
  setGroupId: func,
  leaderLabel: string,
}

function RepliesChunk({ chunk, author, leaderIds, leaderLabel }) {
  const [first, ...rest] = chunk
  return (
    <>
      {first && (
        <Reply
          reply={first}
          author={author}
          leaderIds={leaderIds}
          heading={
            <AuthorName badge={leaderLabel}>
              {author.attributes.name}
            </AuthorName>
          }
        />
      )}
      {rest.map((reply) => (
        <Reply
          key={reply.id}
          reply={reply}
          author={author}
          leaderIds={leaderIds}
        />
      ))}
    </>
  )
}

RepliesChunk.propTypes = {
  chunk: arrayOf(apiResource).isRequired,
  author: apiResource,
  leaderIds: arrayOf(string),
  leaderLabel: string,
}

function NewReplyHeader({ group, setGroupId }) {
  const { data: currentPerson } = useSession(false)
  return (
    <div className="p-4p d-f jc-sb ai-c">
      <span
        className="fs-5 ai-c c-tint3"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingRight: "1rem",
          maxWidth: "50%",
        }}
      >
        {currentPerson && currentPerson.attributes.name}
      </span>
      <span
        onClick={() => setGroupId(null)}
        className="fs-5 f-1 ta-r c-tint3"
        style={{
          position: "relative",
          paddingRight: "14px",
          cursor: "pointer",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Chatting with {group.attributes.name}
        <Icon
          symbol="general#down-chevron"
          style={{
            fontSize: "10px",
            position: "absolute",
            right: "0",
            top: "3px",
          }}
        />
      </span>
    </div>
  )
}

NewReplyHeader.propTypes = {
  group: apiResource,
  setGroupId: func.isRequired,
}
