import { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import { ConversationForm } from "source/groups/my/groups/conversations/form"

export function ConversationsEdit() {
  const params = useParams()
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)

  const { data: conversation } = useApiRead(
    `/chat/v2/me/conversations/${params.conversationId}`,
  )

  function updateConversation({ title }) {
    return sessionApiClient
      .patch(`/chat/v2/me/conversations/${conversation.id}`, {
        data: { attributes: { title } },
      })
      .then(() =>
        navigate(`${group.base_path}/conversations/${conversation.id}`),
      )
  }

  return (
    <ConversationForm
      conversation={conversation}
      onSubmit={updateConversation}
      submitText="Update conversation"
    />
  )
}
