import { bool, func, string, node, object } from "prop-types"
import StepStatusIcon from "source/registrations/reservations/StepStatusIcon"
import SectionHeading from "source/registrations/reservations/SectionHeading"
import SectionFooter from "source/registrations/reservations/SectionFooter"

const SectionLayout = ({
  className,
  heading,
  subHeading,
  step,
  error,
  isButtonShowing,
  isNextDisabled,
  children,
  onSave,
  onCancel,
  onButtonClick,
  buttonText = "Edit",
  isProcessing,
}) => {
  return (
    <div className={className}>
      <StepStatusIcon step={step} />

      <SectionHeading
        text={heading}
        subText={step.isCurrent ? subHeading : null}
        isTinted={!step.isCurrent}
        buttonText={buttonText}
        isButtonShowing={isButtonShowing}
        onClick={onButtonClick}
      />

      {children}

      {step.isCurrent && step.next !== null && (
        <SectionFooter
          isCancelShowing={step.isComplete}
          disabled={isNextDisabled}
          onSave={onSave}
          onCancel={onCancel}
          error={step.isCurrent ? error : null}
          isProcessing={isProcessing}
        />
      )}
    </div>
  )
}

SectionLayout.propTypes = {
  children: node,
  className: string,
  heading: string,
  subHeading: string,
  isButtonShowing: bool,
  error: node,
  step: object,
  buttonText: string,
  onButtonClick: func,
  onSave: func,
  onCancel: func,
  isNextDisabled: bool,
  isProcessing: bool,
}

export default SectionLayout
