import { arrayOf, number, oneOfType, shape, string } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import getRecurrenceDescription from "../utils/recurrence"

function PrefilledData({ prefilledData }) {
  return (
    <div className="my-4">
      <div className="mb-1">
        <Heading level={2} size={4} text="Event details" />
      </div>
      <div className="d-f ai-c jc-sb action-drawer">
        <div className="d-f fd-c">
          <div className="mb-1">
            <strong>Name:</strong> {prefilledData.event_name}
          </div>
          <div className="mb-1">
            <strong>Date:</strong>{" "}
            <EventDatetimeDisplay
              startsAt={prefilledData.starts_at}
              endsAt={prefilledData.ends_at}
            />
          </div>
          {prefilledData.location && (
            <div className="mb-1">
              <strong>Location:</strong>{" "}
              {prefilledData.location.location_type_preference === "virtual"
                ? `Online - ${prefilledData.location.virtual_location_url}`
                : prefilledData.location.name}
            </div>
          )}
          {prefilledData.recurrence && (
            <div className="mb-1">
              <strong>Recurrence:</strong>{" "}
              {getRecurrenceDescription(
                prefilledData.recurrence.frequency_name,
                prefilledData.recurrence.starts_at,
                prefilledData.recurrence.frequency_expression?.elements,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const prefilledDataPropType = shape({
  event_name: string.isRequired,
  starts_at: string.isRequired,
  ends_at: string.isRequired,
  event_connection: shape({
    product_name: string,
    connected_to_type: string,
    connected_to_id: number,
    connected_to_name: string,
  }),
  location: oneOfType([
    shape({
      location_type_preference: string,
      name: string,
      full_formatted_address: string,
      display_preference: string,
      longitude: string,
      latitude: string,
    }),
    shape({
      location_type_preference: string,
      virtual_location_url: string,
    }),
  ]),
  recurrence: shape({
    starts_at: string,
    ends_at: string,
    last_occurrence_date: string,
    frequency_name: string,
    frequency_expression: shape({
      day: number,
      count: number,
      elements: arrayOf(shape({ weekday: number, count: number, day: number })),
    }),
  }),
})

PrefilledData.propTypes = { prefilledData: prefilledDataPropType }

export { prefilledDataPropType }
export default PrefilledData
