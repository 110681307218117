import { Suspense, useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { get as dig } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Button, Heading } from "@planningcenter/doxy-web"
import { Loading } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"

export default function ResourcesDelete() {
  return (
    <TabView>
      <Suspense fallback={<Loading />}>
        <Loaded />
      </Suspense>
    </TabView>
  )
}

function Loaded() {
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const [inFlight, setInFlight] = useState(false)
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(
    `/groups/v2/me/groups/${group.id}/resources/${resourceId}`,
  )

  const canManageResource = dig(
    response.data.attributes,
    "abilities.can_manage",
    false,
  )
  if (!canManageResource) {
    navigate(`${group.base_path}/resources`)
  }

  const { name: resourceName } = response.data.attributes

  const handleDestroy = (e) => {
    e.preventDefault()
    setInFlight(true)

    sessionApiClient
      .del(`/groups/v2/me/groups/${group.id}/resources/${resourceId}`)
      .then(() => navigate(`${group.base_path}/resources`))
      .catch((response) => {
        if (
          response.errors &&
          response.errors.some(({ status }) => status === "404")
        ) {
          navigate(`${group.base_path}/resources`)
        }
        setInFlight(false)
      })
  }

  return (
    <>
      <Heading level={1} size={3} text="Delete resource" />

      <article className="my-2 action-drawer">
        Are you sure you want to delete <strong>{resourceName}</strong>? This
        will delete the resource for everyone in {group.name}.
      </article>

      <div className="d-f jc-c jc-fe@sm mb-2">
        <button
          className="compact-btn btn text-btn m-1 mr-2"
          onClick={() => navigate(`${group.base_path}/resources/${resourceId}`)}
        >
          Cancel
        </button>
        <Button
          size="lg"
          text="Yes, delete"
          theme="destroy"
          type="submit"
          variant="outline"
          onClick={handleDestroy}
          isLoading={inFlight}
        />
      </div>
    </>
  )
}
