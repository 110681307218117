import { bool, number, string } from "prop-types"
import { date as formatDate } from "@churchcenter/datetime-fmt"
import { Heading } from "@planningcenter/doxy-web"
import { Link } from "react-router-dom"

SignupSheet.propTypes = {
  ends_at: string,
  id: number,
  new_signups_available: bool,
  service_type_name: string,
  starts_at: string,
  time_zone: string,
}

function displayName(starts_at, ends_at, time_zone) {
  return formatDate(starts_at, ends_at, {
    timeZone: time_zone,
    year: new Date(ends_at).getFullYear() !== new Date().getFullYear(),
  })
}

export default function SignupSheet({
  ends_at,
  id: availableSignupId,
  new_signups_available,
  service_type_name,
  starts_at,
  time_zone,
}) {
  return (
    <div className="action-drawer d-f fd-c">
      <div className="f-1">
        <div className="d-f fd-r jc-sb">
          <div className="mb-1">
            <Heading
              level={4}
              color="brand"
              text={displayName(starts_at, ends_at, time_zone)}
            />
          </div>
          {new_signups_available && <span className="c-brand">New</span>}
        </div>
        <div>{service_type_name}</div>
        <div className="c-tint2">Signups available</div>
      </div>
      <div className="action-drawer__footer ta-r">
        <Link
          to={`/services/available_signups/${availableSignupId}`}
          className="btn secondary-btn minor-btn"
        >
          Sign up
        </Link>
      </div>
    </div>
  )
}
