import { array, bool } from "prop-types"
import { questionShape } from "source/registrations/propTypes"
import { Icon } from "source/shared/components"
import classNames from "classnames"

const QuestionText = ({ question, answers, removeContainer }) => {
  return (
    <div className={classNames("mb-1", { "action-drawer": !removeContainer })}>
      <div className="label">
        <div className="d-f ai-c g-1">
          {question.question}
          {(question.required || question.publiclyRequired) &&
            answers.length === 0 && (
              <Icon
                symbol="general#exclamation-triangle"
                className="fs-8 symbol"
                style={{ color: "var(--color-citrine)" }}
              />
            )}
        </div>
        {question.description && (
          <div className="fs-4 fw-400 c-tint2">{question.description}</div>
        )}
      </div>
      {answers.length ? <div>{answers.join(", ")}</div> : <>—</>}
    </div>
  )
}

QuestionText.propTypes = {
  question: questionShape,
  answers: array,
  removeContainer: bool,
}

export default QuestionText
