import spinner from "source/svg/spinner.svg"
import { string, number } from "prop-types"

export function Spinner({ alt, opacity = 1 }) {
  return (
    <img
      src={spinner}
      alt={alt}
      style={{ height: "1em", width: "1em", position: "absolute", opacity }}
    />
  )
}
Spinner.propTypes = {
  alt: string.isRequired,
  opacity: number,
}
