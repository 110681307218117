import { useState } from "react"
import { Select } from "source/shared/components"

const cardOptions = [
  { label: "Select a test card:", value: "" },
  { label: "Visa", value: "visa" },
  { label: "Visa (debit)", value: "visa-debit" },
  { label: "Mastercard", value: "mastercard" },
  { label: "Amex", value: "amex" },
  { label: "Discover", value: "discover" },
]

const getTestCardNumber = (card) => {
  switch (card) {
    case "visa":
      return "4242 4242 4242 4242"
    case "visa-debit":
      return "4000 0566 5566 5556"
    case "mastercard":
      return "5555 5555 5555 4444"
    case "amex":
      return "3782 822463 10005"
    case "discover":
      return "6011 1111 1111 1117"
    default:
      return null
  }
}

const StripeTestCards = () => {
  const [testCard, setTestCard] = useState("")
  const [cardNumber, setCardNumber] = useState("")

  const handleTestCardChange = ({ target: { value } }) => {
    const number = getTestCardNumber(value)
    setTestCard(value)
    setCardNumber(number)
  }

  const currentYear = new Date().getFullYear().toString().substring(2)

  return (
    <div css={{ marginTop: 20 }}>
      <div className="alert info-alert">
        <div className="d-f fd-cr fd-r@sm ai-fs ai-c@sm">
          <div className="w-100% w-a@sm">
            <Select
              ariaLabel="select test card"
              name="stripe-test-card"
              id="stripe-test-card"
              value={testCard}
              options={cardOptions}
              onChange={handleTestCardChange}
            />
          </div>

          <div className="d-ib ml-1">
            (This account is in test mode,{" "}
            <a
              href="https://stripe.com/docs/testing#cards"
              target="_blank"
              rel="noopener noreferrer"
            >
              view docs
            </a>
            )
          </div>
        </div>

        {testCard !== "" && (
          <div className="px-4p pt-2 d-f jc-sb">
            <div className="d-if ai-c jc-fs">
              <span className="fw-600">{cardNumber}</span>
            </div>
            <div className="fw-600">
              <span>01/{Number(currentYear) + 5}</span>

              <span
                css={{
                  marginRight: testCard === "amex" ? "0.5em" : ".75em",
                  marginLeft: testCard === "amex" ? "1.25em" : "1.5em",
                }}
              >
                {testCard === "amex" ? "1234" : "222"}
              </span>

              <span css={{ marginLeft: "0.75" }}>92009</span>
            </div>
          </div>
        )}

        <div className="pt-1">
          For testing ApplePay, make sure you are using Safari and have a valid
          card setup in your wallet.{" "}
          <a
            href="https://support.stripe.com/questions/testing-apple-pay-with-stripe"
            target="_blank"
            rel="noopener noreferrer"
          >
            view docs
          </a>
        </div>
      </div>
    </div>
  )
}

export default StripeTestCards
