import { bool, node } from "prop-types"

FieldLabel.propTypes = { children: node, required: bool }

export default function FieldLabel({ children, required, ...props }) {
  return (
    <label {...props}>
      {children}
      {required && <span className="c-ruby"> *</span>}
    </label>
  )
}
