import PropTypes from "prop-types"
import { useState, useContext, useCallback, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { chain } from "lodash"
import { Helmet } from "react-helmet-async"
import { Error404 } from "source/shared/components/Error404"
import { isChurchCenterApp } from "source/Layout"
import { channelPath } from "source/publishing/sermons/shared/routes"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { JoltContext } from "source/shared/contexts/JoltContext"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  Loading,
} from "source/shared/components"
import LiveEpisode from "./Episode/LiveEpisode"
import LibraryEpisode from "./Episode/LibraryEpisode"
import { useEpisode } from "./Episode/queries"
import { useQRCode } from "source/shared/QRCode"
import "./Episode/styles.css"
import { Bugsnag } from "@bugsnag/js"
import NativeHeader from "./shared/NativeHeader"

export function Episode() {
  const { episodeId } = useParams()
  const { data: json } = useEpisode(episodeId)

  if (json.errors) return <Error404 />

  return <EpisodeWithData episodeId={episodeId} episodeJson={json} />
}

EpisodeWithData.propTypes = {
  episodeId: PropTypes.string,
  episodeJson: PropTypes.object,
}
function EpisodeWithData({ episodeId, episodeJson: json }) {
  const navigate = useNavigate()

  const episode = json.data
  const episodeTimes = json.included.filter((r) => r.type == "EpisodeTime")
  const channel = json.included.find((i) => i.type === "Channel")
  const personEpisodeProgress = json.included.find(
    (i) => i.type === "PersonEpisodeProgress",
  )
  const series = json.included.find((i) => i.type === "Series")
  const speaker = json.included.find((r) => r.type === "Speaker")
  const episodeTimeIdMatch = location.search.match(/episode_time_id=(\d+)/)
  const episodeTime =
    episodeTimeIdMatch &&
    json.included.find(
      (r) => r.type === "EpisodeTime" && r.id === episodeTimeIdMatch[1],
    )
  const isWatchingLive = !!episodeTime
  const [[featuredResource], episodeResources] = chain(json.included)
    .filter(["type", "EpisodeResource"])
    .partition(["attributes.featured", true])
    .value()
  let joltEpisodeChannelName = `church_center.publishing.sermons.episodes.${episodeId}`
  let joltSubscribeUrl = `/publishing/v2/episodes/${episodeId}/jolt_subscribe`
  if (episodeTime) {
    joltEpisodeChannelName = `${joltEpisodeChannelName}.episode_times.${episodeTime.id}`
    joltSubscribeUrl = `/publishing/v2/episodes/${episodeId}/episode_times/${episodeTime.id}/jolt_subscribe`
  }

  useNotifyNativeLoaded()
  useQRCode()

  const { jolt } = useContext(JoltContext)
  const [joltChannel, setJoltChannel] = useState(null)
  const [audioDownloadLink, setAudioDownloadLink] = useState("")

  const fetchSubscribeTokenFn = useCallback(
    async (joltChannel, connection_id) => {
      try {
        if (!connection_id)
          Bugsnag.notify(new Error("Jolt connection_id is missing"))

        const response = await sessionApiClient.post(joltSubscribeUrl, {
          data: {
            attributes: {
              channel: joltChannel,
              connection_id,
            },
          },
        })
        return response.data.id
      } catch (response) {
        if (response.errors[0]?.status === "404") {
          alert("This episode cannot be found. It may have been removed.")
          navigate(channelPath(channel))
        } else {
          console.error("failed to subscribe to Jolt channel", response)
        }
      }
    },
    [channel, joltSubscribeUrl, navigate],
  )

  useEffect(() => {
    setAudioDownloadLink("")
  }, [episodeId])

  useEffect(() => {
    if (jolt) {
      setJoltChannel(
        jolt.subscribe(joltEpisodeChannelName, {
          fetchSubscribeTokenFn,
        }),
      )
      return () => jolt.unsubscribe(joltEpisodeChannelName)
    }
  }, [fetchSubscribeTokenFn, jolt, joltEpisodeChannelName])

  if (!joltChannel) {
    return <Loading />
  }

  return (
    <>
      <OpenGraphTags
        title={episode.attributes.title}
        imageUrl={
          episode.attributes.art.attributes.variants.small ||
          episode?.attributes?.video_thumbnail_url
        }
        description={episode.attributes.description}
      />
      <Helmet>
        <script src="https://js.churchcenter.com/modal/v1" />
        {channel.attributes.podcast_feed_url && (
          <link
            rel="alternate"
            type="application/rss+xml"
            href={channel.attributes.podcast_feed_url}
            title={channel.attributes.podcast_settings.attributes.title}
          ></link>
        )}
      </Helmet>
      <NativeHeader
        title={episode.attributes.title}
        rightType={"share"}
        shareUrl={episode.attributes.church_center_url}
        hideBottomDrawer={true}
      />
      <div className={isWatchingLive ? "full-height-col-to-xl" : undefined}>
        {!isChurchCenterApp() && (
          <BreadcrumbNavigation>
            <BreadcrumbPage
              linkUrl={channelPath(channel)}
              pageText={channel.attributes.name}
            />
            <BreadcrumbDividerIcon />
            <BreadcrumbPage pageText={episode.attributes.title} isActive />
          </BreadcrumbNavigation>
        )}
        {isWatchingLive ? (
          <LiveEpisode
            key={episodeId}
            {...{
              channel,
              episode,
              episodeResources,
              episodeTimes,
              episodeTime,
              featuredResource,
              joltChannel,
            }}
          />
        ) : (
          <LibraryEpisode
            key={episodeId}
            {...{
              audioDownloadLink,
              channel,
              episode,
              episodeResources,
              featuredResource,
              joltChannel,
              personEpisodeProgress,
              series,
              speaker,
              setAudioDownloadLink,
            }}
          />
        )}
      </div>
    </>
  )
}
