import { useState, useCallback, useContext } from "react"
import { useSession } from "source/shared/hooks/useSession"
import { RepliesDataContext } from "source/groups/messaging/Topic/RepliesDataContext"
import { Files } from "source/shared/components/messaging/Files"
import { css } from "@emotion/react"
import { apiResource, arrayOf, node, string } from "source/shared/prop_types"
import { colors } from "source/shared/colors"
import { Icon } from "source/shared/components"
import { unlinkify } from "source/shared/linkify"
import { sanitize } from "source/groups/messaging/utils"
import { sessionApiClient } from "@planningcenter/cc-api-client"

Reply.propTypes = {
  reply: apiResource.isRequired,
  heading: node,
  leaderIds: arrayOf(string),
}

export function Reply({ reply, heading, leaderIds }) {
  const { data: currentPerson } = useSession(false)
  const { del } = useContext(RepliesDataContext)
  const [hovering, setHovering] = useState(false)
  const myReply = currentPerson.id === reply.relationships.author.data.id
  const currentPersonIsLeader = leaderIds.includes(currentPerson.id)
  const showControls =
    hovering && (myReply || currentPersonIsLeader) && !reply.pending

  const handleDelete = useCallback(
    function () {
      if (confirm("Are you sure you want to remove this message?")) {
        del(reply.id)
        sessionApiClient.del(reply.links.self)
      }
    },
    [reply],
  )

  return (
    // TODO have a different element for author vs. others so we can style it differently
    <div
      style={{
        opacity: reply.pending ? "0.5" : "1",
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: hovering ? "rgba(0, 0, 0, 0.04)" : null,
        position: "relative",
        borderRadius: "4px",
      }}
      css={styles.reply}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {heading}
      {showControls && (
        <Controls>
          <button className="reply-action fs-3" onClick={handleDelete}>
            <Icon symbol="publishing#trash" />
          </button>
        </Controls>
      )}
      <Message>{reply.attributes.message}</Message>
      <Files reply={reply} />
      <Errors>{reply.errors}</Errors>
    </div>
  )
}

function Controls({ children }) {
  return <div className="reply-controls d-f ai-c jc-c">{children}</div>
}

Controls.propTypes = {
  children: node.isRequired,
}

function Message({ children }) {
  if (unlinkify(children).trim().length === 0) return null

  return <span dangerouslySetInnerHTML={{ __html: sanitize(children) }} />
}

Message.propTypes = {
  children: string.isRequired,
}

function Errors({ children }) {
  if (!children) return null
  if (children.length === 0) return null

  return (
    <div className="reply-error d-f ai-c jc-c">
      {tradeApiErrorsForHardcodedError(children)}
    </div>
  )
}

Errors.propTypes = {
  children: node,
}

// We should handle the errors returned by the API. Until we do, though,
// let's return the hard-coded error we've been displaying.
function tradeApiErrorsForHardcodedError(errors) {
  if (errors.length > 0) {
    return "This message couldn't be posted because you sent too many messages in a row, please try again in 10 seconds."
  } else {
    return ""
  }
}

const styles = {
  reply: css`
    padding: 8px;

    .reply-controls {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px;
      height: 28px;
      width: 28px;
      border-radius: 3px;
      background: ${colors.tint1};
    }

    .reply-error {
      background-color: #ff9999;
      border: solid 1px red;
      border-radius: 5px;
      font-size: 0.7em;
      padding-left: 3px;
    }

    .reply-action {
      border-radius: 2px;
      border: 0;
      background: rgba(255, 255, 255, 0);
      margin: 0;
      padding: 0.2rem 0.25rem;
      color: ${colors.tint6};

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    p {
      overflow: hidden;
      margin-bottom: 0;
      font-size: 0.865rem;
      line-height: 1.4;
      word-wrap: break-word;
      @media (min-width: 1200px) {
        line-height: 1.5;
        font-size: 0.9rem;
      }

      &[data-uuid] {
        margin-bottom: 0;
      }
    }
  `,
}
