import { shape, string, arrayOf, number } from "prop-types"

const NamedAttendeeCost = ({ attendeeSummary }) => {
  const { baseCost, discounts, discountsTotal, addOns } = attendeeSummary

  return (
    <>
      {baseCost !== "$0" && (
        <div className={`d-f ai-c jc-sb mb-1`}>
          <span>Base cost</span>
          <div className="ta-r">{baseCost}</div>
        </div>
      )}
      {addOns.map((addOn) => (
        <div className={`d-f ai-c jc-sb mb-1`} key={addOn.id}>
          <span>{addOn.name}</span>
          {addOn.total !== "$0" && <div className="ta-r">{addOn.total}</div>}
        </div>
      ))}
      {discountsTotal &&
        discountsTotal !== "$0" &&
        discounts.map((discount) => (
          <div className={`d-f ai-c jc-sb mb-1`} key={discount.name}>
            <div>Discount: {discount.name}</div>
            <div>-{discount.amount}</div>
          </div>
        ))}
    </>
  )
}

NamedAttendeeCost.propTypes = {
  attendeeSummary: shape({
    discountsTotal: string,
    baseCost: string,
    addOns: arrayOf(
      shape({
        id: number,
        name: string,
        total: string,
      }),
    ),
    discounts: arrayOf(
      shape({
        name: string,
        amount: string,
      }),
    ),
  }),
}

export default NamedAttendeeCost
