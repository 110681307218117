import { arrayOf, bool, func, oneOf, node, number } from "prop-types"
import { css } from "@emotion/react"
import { colors } from "source/shared/colors"
import { emojis } from "source/groups/my/groups/messages/ReactionPicker"

ReactionsSummary.propTypes = {
  reactions: arrayOf(oneOf(Object.keys(emojis))),
  total_count: number,
  isHighlighted: bool,
  onClick: func,
}

export function ReactionsSummary({
  reactions = [],
  total_count = 0,
  isHighlighted = false,
  onClick,
}) {
  if (!reactions.length) return null

  const showCount = total_count > reactions.length

  return (
    <button
      css={[
        styles.summaryContainer,
        isHighlighted
          ? { borderColor: colors.turquoise }
          : { borderColor: "var(--color-tint7)" },
      ]}
      onClick={onClick}
    >
      {reactions.map((r) => (
        <Emoji key={r} name={r} />
      ))}
      {showCount && <Count>{total_count}</Count>}
    </button>
  )
}

Emoji.propTypes = {
  name: oneOf(Object.keys(emojis)).isRequired,
}

function Emoji({ name }) {
  return <p css={styles.emoji}>{emojis[name]}</p>
}

Count.propTypes = {
  children: node,
}

function Count({ children }) {
  return <span css={styles.count}>{children}</span>
}

const styles = {
  summaryContainer: css`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 0.75rem;
    background: var(--color-tint8);
    border: 1px solid var(--color-tint7);
    border-radius: 50px;
    margin: 0px 4px;
    font-size: 0.75rem;
    &:hover {
      background: var(--color-tint6);
    }
  `,
  emoji: css`
    line-height: initial;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  `,
  count: css`
    color: var(--color-tint2);
  `,
}
