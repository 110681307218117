import { object } from "prop-types"
import Attendees from "./Attendees"
import { Heading } from "@planningcenter/doxy-web"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"

const AttendeeSelections = ({ registration }) => {
  const {
    attendeeSummaries,
    event: { registerForSeparateTimes, eventTimes, hasAttendeesModule },
  } = registration
  const nullEventTime = { id: 0 }
  const signupTimes = registerForSeparateTimes ? eventTimes : [nullEventTime]
  const regularAttendees = attendeeSummaries.filter(
    (attendee) => !attendee.waitlist,
  )
  const waitlistedAttendees = attendeeSummaries.filter(
    (attendee) => attendee.waitlist,
  )
  const anyRegularAttendees = regularAttendees.length > 0
  const anyWaitlistedAttendees = waitlistedAttendees.length > 0

  const attendeesForEventTimeId = (attendees, eventTimeId) => {
    return attendees.filter((attendee) =>
      [0, eventTimeId, null].includes(attendee.signupTimeId),
    )
  }

  const detailedWaitlistEnabled = useOrganizationFlag("detailedWaitlist")

  return (
    <div>
      {anyRegularAttendees && (
        <>
          {!registration.isDetailed && (
            <div className="mb-2">
              <Heading level={2} size={3} text="You are registered for:" />
            </div>
          )}

          {signupTimes.map((eventTime) => (
            <Attendees
              key={eventTime.id}
              hasAttendeesModule={hasAttendeesModule}
              registerForSeparateTimes={registerForSeparateTimes}
              attendeeSummaries={attendeesForEventTimeId(
                regularAttendees,
                eventTime.id,
              )}
              registration={registration}
            />
          ))}
        </>
      )}

      {anyWaitlistedAttendees && (
        <>
          {registration.isDetailed && detailedWaitlistEnabled && (
            <div className="mb-2">
              <Heading level={2} size={3} text="Waitlist" />
            </div>
          )}
          {!registration.isDetailed && (
            <div className="mb-2">
              <Heading level={2} size={3} text="You are on the waitlist for:" />
            </div>
          )}

          {(!registration.isDetailed || detailedWaitlistEnabled) &&
            signupTimes.map((eventTime) => (
              <Attendees
                key={eventTime.id}
                hasAttendeesModule={hasAttendeesModule}
                registerForSeparateTimes={registerForSeparateTimes}
                attendeeSummaries={attendeesForEventTimeId(
                  waitlistedAttendees,
                  eventTime.id,
                )}
                registration={registration}
              />
            ))}
        </>
      )}
    </div>
  )
}

AttendeeSelections.propTypes = {
  registration: object,
}

export default AttendeeSelections
