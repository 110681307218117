import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useEffect, useMemo, useState } from "react"
import { difference, uniq } from "lodash"
import { buildUrl } from "source/shared/buildUrl"

/**
 * This hook assumes eventIds is stable (it doesn't change over time). If that assumption changes
 * in the future this hook will need to be reworked.
 *
 * It supports personIds being added over time (as the group attendance screen is paginated).
 * As new personIds are added, it keeps track of which people it has already loaded attendances
 * for and only loads attendances for new person_ids. Then it updates it's list so the data it
 * returns is a combination of all attendances for all people requested.
 */
export const useGroupAttendances = ({ groupId, eventIds, personIds }) => {
  const [loadedPersonIds, setLoadedPersonIds] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const neededPersonIds = useMemo(
    () => difference(personIds, loadedPersonIds),
    [personIds, loadedPersonIds],
  )

  useEffect(() => {
    if (!loading && neededPersonIds.length && eventIds.length) {
      setLoading(true)
      const url = buildUrl(`/groups/v2/me/groups/${groupId}/attendances`, {
        filter: "completed,by_event,by_person",
        event_ids: eventIds.join(","),
        person_ids: neededPersonIds.join(","),
        per_page: (eventIds.length * neededPersonIds.length).toString(),
      })

      sessionApiClient
        .get(url, {})
        .then((json) => {
          setData([...data, ...(json.data || [])])
        })
        .finally(() => {
          setLoadedPersonIds(uniq([...loadedPersonIds, ...neededPersonIds]))
          setLoading(false)
        })
    }
  }, [neededPersonIds, loading, data, loadedPersonIds, groupId, eventIds])

  return { data, loading }
}
