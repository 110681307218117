import { string, bool, func } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"

const SectionHeading = ({
  text,
  subText = null,
  isTinted = true,
  buttonText = "Edit",
  isButtonShowing = false,
  onClick = () => null,
}) => {
  return (
    <>
      <div className="d-f ai-c jc-sb mb-2">
        <div>
          <Heading
            level={2}
            size={3}
            color={isTinted ? "tint2" : "tint0"}
            text={text}
          />
          {subText && <p className="c-tint2 mb-0 mt-4p">{subText}</p>}
        </div>

        {isButtonShowing && (
          <button className="minor-btn secondary-btn btn" onClick={onClick}>
            {buttonText}
          </button>
        )}
      </div>
    </>
  )
}

SectionHeading.propTypes = {
  text: string.isRequired,
  subText: string,
  onClick: func,
  isTinted: bool,
  isButtonShowing: bool,
  buttonText: string,
  error: string,
}

export default SectionHeading
