import { useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"
import { TextInput } from "@planningcenter/doxy-web"

import CustomField from "./CustomField"

CheckboxesField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      required: bool.isRequired,
      settings: shape({
        other: bool,
      }),
    }),
    id: string.isRequired,
    options: arrayOf(
      shape({
        attributes: shape({
          label: string.isRequired,
          sequence: number.isRequired,
        }),
        id: string.isRequired,
      }),
    ),
  }),
  onChange: func.isRequired,
}

export default function CheckboxesField({ errors, field, onChange }) {
  const [showOther, setShowOther] = useState(false)
  const [selected, setSelected] = useState([])

  const {
    attributes: { description, label, required, settings },
    id,
    options,
  } = field
  const sortedOptions = options.sort((a, b) => a.sequence - b.sequence)
  const hasOtherOption = settings && settings.other

  const handleChange = ({ target: { value, checked } }) => {
    let values = checked
      ? [...selected, value]
      : selected.filter((a) => a !== value)

    setSelected(values)

    if (values.length > 0) {
      onChange({ selected: values }, id)
    } else {
      onChange({}, id)
    }
  }

  const handleOtherChange = ({ target: { value } }) => {
    onChange({ selected, other: value }, id)
  }

  return (
    <CustomField {...{ description, errors, label, required }}>
      <div>
        {sortedOptions.map((option) => (
          <Checkbox
            key={option.id}
            label={option.attributes.label}
            checked={selected.includes(option.id) || false}
            id={`checkbox_${option.id}`}
            value={option.id}
            onChange={(e) => {
              handleChange(e)
            }}
          />
        ))}
        {hasOtherOption && (
          <Checkbox
            id={`other_${id}`}
            label="Other"
            checked={showOther}
            onChange={() => setShowOther(!showOther)}
          />
        )}
        {showOther && (
          <div className="form-field__input--full">
            <TextInput
              placeholder="Other"
              onChange={(e) => handleOtherChange(e)}
            />
          </div>
        )}
      </div>
    </CustomField>
  )
}

const Checkbox = ({ id, label, ...props }) => {
  return (
    <div>
      <input type="checkbox" className="checkbox" id={id} {...props} />
      <label htmlFor={id} className="checkbox-label mb-4p">
        {label}
      </label>
    </div>
  )
}
Checkbox.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
}
