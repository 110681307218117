import { Suspense, useContext, useState, useReducer } from "react"
import { object } from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useApiRead } from "source/shared/SessionApiResource"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Link, Navigate, useNavigate } from "react-router-dom"
import TabView from "source/groups/my/groups/TabView"
import { Loading, Avatar } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

export default function MembershipEdit() {
  return (
    <TabView showActions={false}>
      <Heading level={2} text="Edit your visible information" />
      <p className="mt-1">
        Select the information you would like to be visible to the other members
        of this group.{" "}
        <span className="c-tint2">
          (Leaders will always have access to this information. Groups uses your
          primary contact information, which can be
          <Link to="/me/profile"> edited in your profile</Link>.)
        </span>
      </p>
      <Suspense fallback={<Loading />}>
        <Form />
      </Suspense>
    </TabView>
  )
}

const defaultFormState = {
  name_visible: false,
  avatar_visible: false,
  email_visible: false,
  phone_visible: false,
  apply_to_current_groups: false,
}

function Form() {
  const { id: groupId } = useContext(CurrentGroupContext)
  const response = useApiRead(
    `/groups/v2/me/groups/${groupId}/my_shared_attributes`,
  )

  return response.errors &&
    response.errors.some(({ status }) => status === "404") ? (
    <Navigate to="/groups" replace />
  ) : (
    <FormContent attributes={response.data.attributes} />
  )
}

FormContent.propTypes = { attributes: object.isRequired }
function FormContent({ attributes }) {
  const { id: groupId, base_path } = useContext(CurrentGroupContext)
  const [isBusy, setIsBusy] = useState(false)
  const { name, avatar, email_addresses, phone_numbers } = attributes
  const [formValues, dispatch] = useReducer(formReducer, {
    ...defaultFormState,
    name_visible: name.shared,
    avatar_visible: avatar.shared,
    email_visible: email_addresses[0]?.shared || false,
    phone_visible: phone_numbers[0]?.shared || false,
  })
  const navigate = useNavigate()
  const {
    name_visible,
    avatar_visible,
    email_visible,
    phone_visible,
    apply_to_current_groups,
  } = formValues

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsBusy(true)
    sessionApiClient
      .post(`/groups/v2/me/groups/${groupId}/set_shared_person_attributes`, {
        data: { attributes: formValues },
      })
      .then(() => navigate(`${base_path}/members`))
      .catch((response) => {
        if (
          response.errors &&
          response.errors.some(({ status }) => status === "404")
        ) {
          return navigate("/groups")
        }
        alert(
          "Uh oh, something went wrong. We could not set your shared information preferences. Please try again.",
        )
      })
      .finally(() => setIsBusy(false))
  }

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <fieldset disabled={isBusy}>
        <div className="d-f ai-c mb-1">
          <input
            type="checkbox"
            id="nameVisible"
            className="checkbox large-checkbox"
            checked={name_visible}
            onChange={() => dispatch({ type: "TOGGLE_NAME" })}
            disabled={name.must_share_reason}
          />
          <label
            htmlFor="nameVisible"
            className="checkbox-label large-checkbox-label pb-0"
          >
            <div className="d-f ai-c" style={{ marginTop: -8 }}>
              <div className="w-5 h-5 mx-1" style={{ marginTop: -8 }}>
                <Avatar
                  url={avatar.value}
                  alt={`Profile image for ${name.value}`}
                />
              </div>
              <div className="ws-nw">
                <Heading level={3} text={name.value} />
                {name.must_share_reason && (
                  <p className="fs-13 mb-0">{name.must_share_reason}</p>
                )}
              </div>
            </div>
          </label>
        </div>

        <div className="mb-1" style={{ paddingLeft: 80 }}>
          <input
            type="checkbox"
            id="avatarVisible"
            className="checkbox"
            checked={avatar_visible}
            onChange={() => dispatch({ type: "TOGGLE_AVATAR" })}
          />
          <label htmlFor="avatarVisible" className="checkbox-label pb-0">
            Include profile photo
          </label>
        </div>

        {email_addresses.map((e) => {
          const id = `emailAddressVisible-${e.value}`
          return (
            <div key={id} className="mb-1" style={{ paddingLeft: 80 }}>
              <input
                type="checkbox"
                id={id}
                className="checkbox"
                checked={email_visible}
                onChange={() => dispatch({ type: "TOGGLE_EMAIL" })}
              />
              <label htmlFor={id} className="checkbox-label pb-0">
                {e.value}
              </label>
            </div>
          )
        })}

        {phone_numbers.map((p) => {
          const id = `phoneNumberVisible-${p.value}`
          return (
            <div key={id} className="mb-1" style={{ paddingLeft: 80 }}>
              <input
                type="checkbox"
                id={id}
                className="checkbox"
                checked={phone_visible}
                onChange={() => dispatch({ type: "TOGGLE_PHONE" })}
              />
              <label htmlFor={id} className="checkbox-label pb-0">
                {p.value}
              </label>
            </div>
          )
        })}

        <div className="mt-4 mb-2 fs-4">
          <input
            type="checkbox"
            id="applyToCurrentGroups"
            className="checkbox"
            checked={apply_to_current_groups}
            onChange={() =>
              dispatch({ type: "TOGGLE_APPLY_TO_CURRENT_GROUPS" })
            }
          />
          <label htmlFor="applyToCurrentGroups" className="checkbox-label">
            Apply to existing groups
          </label>
        </div>

        <button type="submit" className="btn">
          Save changes
        </button>
      </fieldset>
    </form>
  )
}

function formReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_NAME":
      return {
        ...state,
        name_visible: !state.name_visible,
        avatar_visible: false,
        email_visible: false,
        phone_visible: false,
      }
    case "TOGGLE_AVATAR":
      return {
        ...state,
        avatar_visible: !state.avatar_visible,
      }
    case "TOGGLE_EMAIL":
      return {
        ...state,
        email_visible: !state.email_visible,
      }
    case "TOGGLE_PHONE":
      return {
        ...state,
        phone_visible: !state.phone_visible,
      }
    case "TOGGLE_APPLY_TO_CURRENT_GROUPS":
      return {
        ...state,
        apply_to_current_groups: !state.apply_to_current_groups,
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}
