import PropTypes from "prop-types"
import { useEffect } from "react"
import { Link as ReactRouterLink, Navigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Icon } from "source/shared/components"
import { datetime } from "@churchcenter/datetime-fmt"
import { useApiRead } from "source/shared/SessionApiResource"
import { useSession } from "source/shared/hooks/useSession"
import { Heading } from "@planningcenter/doxy-web"

NotificationsShow.propTypes = {
  id: PropTypes.string,
}

export default function NotificationsShow() {
  const { id } = useParams()
  useSession()
  const response = useApiRead(
    `/notifications/v2/me/notifications/${id}?include=native_payloads`,
  )

  let notification, content

  try {
    notification = response.data
    content = JSON.parse(response.included[0].attributes.content)
  } catch (_error) {
    content = { kind: "PARSE_ERROR" }
  }

  if (content.kind === "CHURCH_CENTER_ANNOUNCEMENT")
    return (
      <ChurchCenterAnnouncement notification={notification} content={content} />
    )
  else return <Navigate to="/notifications" replace />
}

ChurchCenterAnnouncement.propTypes = {
  notification: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
}

function ChurchCenterAnnouncement({ notification, content }) {
  const { button_1_link, button_1_text } = content
  const announcement = JSON.parse(content.alert)

  // Mark the announcement read
  useEffect(() => {
    if (notification.attributes.read_at) return
    sessionApiClient.post(`${notification.links.self}/mark_read`)
  }, [])

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Icon symbol="general#megaphone" />
        <Heading level={1} text={announcement.title} />
        <div>
          {datetime(notification.attributes.created_at, {
            style: "relative",
          })}
        </div>
      </div>
      <div className="mt-2 mb-2 d-f jc-c">
        <span className="ta-l">{announcement.body}</span>
      </div>
      {button_1_text && (
        <div style={{ textAlign: "center" }}>
          <Link to={button_1_link} className="btn secondary-btn">
            {button_1_text}
          </Link>
        </div>
      )}
    </>
  )
}

// User generated links can come in various shapes and sizes
//
// - https://example.com/ (Anywhere on the internet, secure)
// - http://example.com/ (Anywhere on the internet, insecure)
// - https://some.churchcenter.com/some/path (A full Church Center url)
// - /some/path (An absolute path on Church Center)
// - some/path (An absolute path on Church Center, missing the leading slash because of Church Center App react-navigation decisions)
// - sms://8595554444 (Some other protocol because they want to send text messages)
// - mailto://you@yours.com (Some other protocol because they want to send emails)
//
// This function will make the most usable URL possible, prefering relative paths on the current domain for react-router
const minimalURL = (string) => {
  const url = new URL(string, window.origin)
  if (url.origin === window.origin)
    return url.toString().replace(window.origin, "")
  else return url.toString()
}

export function Link({ to, children, ...rest }) {
  const sanitizedTo = minimalURL(to)
  const localLink = sanitizedTo.startsWith("/")
  const target = localLink ? "_self" : "_blank"
  const rel = localLink ? undefined : "noopener noreferrer"

  return (
    <ReactRouterLink to={sanitizedTo} target={target} rel={rel} {...rest}>
      {children}
    </ReactRouterLink>
  )
}
Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
