import { useContext, useState } from "react"
import { object, array, bool, func, number } from "prop-types"
import AttendeePersonFormHousehold from "./AttendeePersonFormHousehold"
import PersonInfoField from "source/registrations/reservations/personInfo/PersonInfoField"
import { Select } from "source/shared/components"
import AttendeesPersonRegistered from "source/registrations/reservations/AttendeesPersonRegistered"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"

const AVAILABLE_FIELDS = [
  "name",
  "gender",
  "birthdate",
  "grade",
  "address",
  "phoneNumber",
  "emailAddress",
  "emergencyContact",
  "medical",
]

const AttendeesPersonForm = ({
  isNewPerson,
  attendee,
  onChange,
  onApplyEmergencyContactToAll,
  people,
  households,
  session,
  maxHouseholdSize,
  onAttendeePersonChange,
  peopleOptions,
  errors = [],
  reservation,
}) => {
  const [addToHousehold, setAddToHousehold] = useState(false)

  const person = attendee.person || {
    id: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    address: {},
    medicalNotes: "",
    gender: "",
    grade: "",
    birthdate: "",
    householdId: "",
  }

  const { generic_avatar_url } = useContext(ServerRenderedProps)

  const {
    collectionOptions: {
      email: emailAddress,
      phone: phoneNumber,
      ...attendeeTypeCollectionOptions
    },
  } = attendee.attendeeType

  const collectionOptions = {
    emailAddress,
    phoneNumber,
    ...attendeeTypeCollectionOptions,
    name: "required",
  }

  const existingPerson = people.find((p) => p.id === person.id) || {}
  const isNewOrExistingPerson = !!person.id || isNewPerson

  const handleAddToHouseholdChange = (checked, value) => {
    setAddToHousehold(checked)
    onChange(attendee, "householdId", checked ? value : "")
  }

  const handleHouseholdChange = ({ target: { value } }) =>
    onChange(attendee, "householdId", value)

  const handleChange = (field, value, useContactForAllAttendees) =>
    onChange(attendee, field, value, useContactForAllAttendees)

  const handleCancel = (field) =>
    onChange(attendee, field, existingPerson[field])

  const availableFieldsForPerson = AVAILABLE_FIELDS.filter((field) => {
    if (!isNewPerson && person.id) return field !== "name"
    if (isNewPerson) return true
    return false
  })

  const personSelectProps = {
    className: "select mb-2",
    ariaLabel: "select attendee",
    name: `attendee-${attendee.id}`,
    id: `attendee-${attendee.id}`,
    onChange: onAttendeePersonChange,
    options: peopleOptions,
    value: isNewPerson ? "new" : attendee.person?.id?.toString() || "",
  }

  return (
    <div>
      <div className="d-f g-2 action-drawer fd-c">
        <div className="d-f g-2 fsh-0">
          <img
            src={`${
              existingPerson.avatarUrl ||
              existingPerson.demographicAvatarUrl ||
              generic_avatar_url
            }?g=200x200`}
            className="w-6 h-6 circle d-b"
            alt={` Profile of ${existingPerson.name}`}
          />
          <Select {...personSelectProps} />
        </div>
        <div className="w-100% d-f fd-c g-2">
          <AttendeesPersonRegistered attendee={attendee} />

          {isNewOrExistingPerson &&
            availableFieldsForPerson.map((field) => (
              <PersonInfoField
                key={field}
                id={attendee.id}
                field={field}
                attendee={attendee}
                reservation={reservation}
                person={person}
                existingPerson={existingPerson}
                onChange={handleChange}
                onApplyEmergencyContactToAll={onApplyEmergencyContactToAll}
                onCancel={handleCancel}
                requirement={collectionOptions[field]}
                error={errors.find((e) => e.type === field)?.message}
              />
            ))}

          {session.isAuthenticated && isNewPerson && (
            <AttendeePersonFormHousehold
              attendeeId={attendee.id}
              addToHouseholdValue={addToHousehold}
              onAddToHouseholdChange={handleAddToHouseholdChange}
              householdValue={person.householdId}
              onHouseholdChange={handleHouseholdChange}
              households={households}
              maxHouseholdSize={maxHouseholdSize}
            />
          )}
        </div>
      </div>
    </div>
  )
}

AttendeesPersonForm.propTypes = {
  isNewPerson: bool,
  attendee: object,
  onChange: func,
  onApplyEmergencyContactToAll: func,
  people: array,
  households: array,
  session: object,
  maxHouseholdSize: number,
  onAttendeePersonChange: func,
  peopleOptions: array,
  errors: array,
  reservation: object,
}

export default AttendeesPersonForm
