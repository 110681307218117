import { useState, useEffect } from "react"
import { string, shape, func, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const EmailAddressField = ({ attendee, value, onChange, error, ...rest }) => {
  const { id: attendeeId } = attendee

  const [emailAddress, setEmailAddress] = useState(value)

  const validateEmail = (email) => {
    return (
      email && email.length > 0 && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    )
  }

  const handleChange = (value) => {
    setEmailAddress(value)
  }

  useEffect(() => {
    if (validateEmail(emailAddress)) {
      onChange(emailAddress)
    }
  }, [emailAddress, onChange])

  return (
    <Field label={"Email Address"} error={error} value={value} {...rest}>
      <TextInput
        id={`emailAddress-${attendeeId}`}
        value={emailAddress || ""}
        onChange={({ target: { value } }) => handleChange(value)}
        placeholder="Email address"
        size="sm"
      />
    </Field>
  )
}

EmailAddressField.propTypes = {
  attendee: shape({
    id: oneOfType([string, number]),
  }),
  onChange: func,
  error: string,
  value: string,
}

export default EmailAddressField
