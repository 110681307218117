import { string, func, bool } from "prop-types"
import { TextInput } from "@planningcenter/doxy-web"

const ReviewPaymentDiscountForm = ({
  error,
  discountCode,
  onChange,
  onSubmit,
  isProcessing,
}) => {
  return (
    <div className="mb-2">
      {error && <div className="sm-alert error-alert alert mb-1">{error}</div>}

      <div className="action-drawer p-1 d-f ai-c">
        <TextInput
          id="discountCode"
          placeholder="Enter discount code"
          value={discountCode}
          onChange={onChange}
        />
        <button
          className="minor-btn secondary-btn btn ml-1"
          disabled={isProcessing || discountCode.trim().length <= 0}
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </div>
  )
}

ReviewPaymentDiscountForm.propTypes = {
  error: string,
  discountCode: string,
  onChange: func,
  onSubmit: func,
  isProcessing: bool,
}

export default ReviewPaymentDiscountForm
