import { useContext, useEffect, useReducer, useRef } from "react"
import PropTypes from "prop-types"
import { CurrentEventContext } from "source/groups/my/groups"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { get as dig } from "lodash"
import { css } from "@emotion/react"

const propTypes = {
  personId: PropTypes.string.isRequired,
  initialValue: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  isApplicant: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

function useEffectExceptForInitialRender(callback, dependencies) {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) callback()
    else didMount.current = true
  }, dependencies)
}

export default function AttendanceCheckbox({
  personId,
  initialValue,
  role,
  isApplicant,
  children,
}) {
  const { event } = useContext(CurrentEventContext)
  const [state, dispatch] = useReducer(reducer, {
    attended: initialValue,
    isBusy: false,
  })

  useEffectExceptForInitialRender(() => {
    if (isApplicant) updateAttendance()
  }, [role])

  const setAttendance = (newAttended) => {
    const apiUrl = `${event.links.self}/attendance_recording/set_attendance`
    const attributes = {
      person_id: personId,
      attended: newAttended,
      role: role,
    }

    dispatch({ type: "TOGGLE_INIT", attended: newAttended })
    sessionApiClient
      .post(apiUrl, { data: { attributes } })
      .then((response) => {
        const attended = dig(response, "data.attributes.attended")
        dispatch({ type: "TOGGLE_SUCCESS", attended })
      })
      .catch((_err) => {
        dispatch({ type: "TOGGLE_ERROR" })
        alert("Oops, something went wrong. Please try again.")
      })
      .finally(() => dispatch({ type: "TOGGLE_COMPLETE" }))
  }

  const toggleAttendance = () => {
    setAttendance(!state.attended)
  }

  const updateAttendance = () => {
    setAttendance(state.attended)
  }

  const inputId = `${personId}-attended`
  const styles = {
    checkboxLabel: css`
      &:before {
        top: calc(50% - 12px);
      }
      &:after {
        top: calc(50% - 9px);
      }
    `,
  }

  return (
    <div className="d-f f-1 ai-c">
      <input
        id={inputId}
        type="checkbox"
        className="checkbox"
        checked={state.attended}
        disabled={state.isBusy}
        onChange={toggleAttendance}
      />
      <label
        htmlFor={inputId}
        className="checkbox-label large-checkbox-label"
        css={styles.checkboxLabel}
      >
        {children}
      </label>
    </div>
  )
}
AttendanceCheckbox.propTypes = propTypes

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_INIT":
      return {
        ...state,
        attended: action.attended,
        isBusy: true,
      }
    case "TOGGLE_SUCCESS":
      return {
        ...state,
        attended: action.attended,
      }
    case "TOGGLE_ERROR":
      return {
        ...state,
        attended: !state.attended,
      }
    case "TOGGLE_COMPLETE":
      return {
        ...state,
        isBusy: false,
      }
    default:
      throw new Error("Unknown action type")
  }
}
