import { time as formatTime } from "@churchcenter/datetime-fmt"
import { array, number, string } from "prop-types"
import ScheduleItem from "source/myChurchCenter/Schedule/ScheduleItem"

UpcomingSchedules.propTypes = {
  ends_at: string,
  plan_id: number,
  schedules: array,
  service_type_id: number,
  service_type_name: string,
  starts_at: string,
  times_label: string,
  time_zone: string,
}

export default function UpcomingSchedules({
  ends_at,
  plan_id,
  schedules,
  service_type_id,
  service_type_name,
  starts_at,
  times_label,
  time_zone,
}) {
  const scheduleItemLinkTitle = `${formatTime(starts_at, ends_at, {
    timeZone: time_zone,
  })} ${service_type_name}`

  const planDetailsPath = `/services/service_types/${service_type_id}/plans/${plan_id}`

  const scheduleItemContext = schedules.map((schedule) => {
    return {
      icon: "general#check",
      iconClass: "c-emerald mr-4p mt-4p fs-4",
      text: schedule.display_name,
    }
  })

  return (
    <ScheduleItem
      linkTitle={scheduleItemLinkTitle}
      linkUrl={planDetailsPath}
      linkState={{ prevPath: location.pathname, prevPathName: "Me" }}
      itemDescription={times_label}
      itemContext={scheduleItemContext}
    />
  )
}
