import { useContext } from "react"
import { CurrentGroupContext } from "source/groups/my/groups"
import { Navigate } from "react-router-dom"
import TabView from "source/groups/my/groups/TabView"
import EventForm from "source/groups/my/groups/events/form"
import { Heading } from "@planningcenter/doxy-web"

export default function EventsNew() {
  const { abilities, base_path: groupPath } = useContext(CurrentGroupContext)

  if (!abilities.events.can_manage)
    return <Navigate to={`${groupPath}/events`} replace />

  return (
    <TabView showActions={false}>
      <div className="mt-1 mb-3">
        <Heading level={1} size={3} text="New event" />
      </div>
      <EventForm />
    </TabView>
  )
}
