import { object } from "prop-types"
import {
  useRegistrationInvoice,
  useInvalidateRegistrationInvoice,
} from "source/registrations/hooks/useRegistrationInvoice"
import { Loading } from "source/shared/components"
import PaymentFormWrapper from "./Payment/PaymentFormWrapper"

const RegistrationInvoice = ({ registration }) => {
  const invalidateInvoice = useInvalidateRegistrationInvoice(registration.id)
  const { isLoading, data: registrationInvoice = {} } = useRegistrationInvoice(
    registration.id,
    {
      retry: false,
    },
  )

  const {
    total,
    totalRefunded,
    totalPaid,
    hasBalanceDue,
    hasRefundDue,
    balanceDue,
    refundDue,
  } = registrationInvoice

  const { allowCreditCardPayments } = registration.event

  const handlePaymentSave = () => {
    invalidateInvoice()
    registration.invalidate()
  }

  return (
    <div>
      {isLoading && <Loading />}
      {registrationInvoice && (
        <div className="mt-2 d-f fd-c g-2">
          <div className="d-f ai-c jc-sb fw-500">
            <span>Total</span>
            <span>{total}</span>
          </div>
          <div className="d-f ai-c jc-sb fw-500">
            <span>Paid</span>
            <span>{totalPaid}</span>
          </div>
          {totalRefunded && (
            <div className={"d-f ai-c jc-sb fw-500"}>
              <span>Refunded</span>
              <span>{totalRefunded}</span>
            </div>
          )}
          <div className="d-f ai-c jc-sb fw-500">
            <span>{hasRefundDue ? "Refund" : "Balance"} Due</span>
            <span>{hasRefundDue ? refundDue : balanceDue}</span>
          </div>
        </div>
      )}
      {allowCreditCardPayments && hasBalanceDue && (
        <PaymentFormWrapper
          registrationInvoice={registrationInvoice}
          registration={registration}
          onPaymentSave={handlePaymentSave}
        />
      )}
    </div>
  )
}

RegistrationInvoice.propTypes = {
  registration: object.isRequired,
}

export default RegistrationInvoice
