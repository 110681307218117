import PropTypes from "prop-types"
import { Heading } from "@planningcenter/doxy-web"

TabViewHeadingRow.propTypes = {
  children: PropTypes.node,
  ingoreWrap: PropTypes.bool,
  text: PropTypes.string.isRequired,
}
export function TabViewHeadingRow({ children, ingoreWrap = false, text }) {
  const wrapStyles = ingoreWrap
    ? "fd-r fw-w jc-sb"
    : "fd-c fd-r@sm fw-w jc-c jc-sb@sm"

  return (
    <div className={`d-f gap-2 mb-2 ${wrapStyles}`}>
      <Heading level={1} size={3} text={text} />
      {children && <div className="d-f ai-c jc-c gap-2 fw-w">{children}</div>}
    </div>
  )
}
