import {
  useQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"

export const usePeopleGenders = (options = {}) => {
  const path = "/people/v2/genders"
  const { params, queryOptions } = splitQueryParams(options)

  return useQuery({
    queryFn,
    queryKey: [path, params],
    ...queryOptions,
  })
}

export const usePeopleGenderOptions = (options = {}) => {
  const genders = usePeopleGenders(options)

  return {
    ...genders,
    data: genders?.data?.reduce(
      (acc, { value }) => [...acc, { label: value, value }],
      [{ label: "Select gender", value: "" }],
    ),
  }
}
