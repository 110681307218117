import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  apiResource,
  arrayOf,
  node,
  oneOfType,
  string,
} from "source/shared/prop_types"
import { useSession } from "source/shared/hooks/useSession"
import { useApiRead } from "source/shared/SessionApiResource"
import { getRelationship } from "source/shared/getRelationship"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  Link,
  Navigate,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom"
import { WideLayout } from "source/Layout"
import ConversationsRouter from "source/groups/my/groups/conversations/router"
import MessagesIndex from "source/groups/my/groups/messages"
import MessagesNew from "source/groups/my/groups/messages/new"
import MessagesShow from "source/groups/my/groups/messages/show"
import MessagesEdit from "source/groups/my/groups/messages/edit"
import ReplyShow from "source/groups/my/groups/messages/reply/show"
import EventsIndex from "source/groups/my/groups/events"
import EventsNew from "source/groups/my/groups/events/new"
import EventsShow from "source/groups/my/groups/events/show"
import EventsEdit from "source/groups/my/groups/events/edit"
import EventsCancel from "source/groups/my/groups/events/cancel"
import AttendanceEdit from "source/groups/my/groups/events/attendance/edit"
import AttendanceIndex from "source/groups/my/groups/attendances/index"
import MyNote from "source/groups/my/groups/events/MyNote"
import NotesNew from "source/groups/my/groups/events/notes/new"
import NotesShow from "source/groups/my/groups/events/notes/show"
import NotesEdit from "source/groups/my/groups/events/notes/edit"
import ResourcesIndex from "source/groups/my/groups/resources"
import ResourcesNew from "source/groups/my/groups/resources/new"
import ResourcesShow from "source/groups/my/groups/resources/show"
import ResourcesEdit from "source/groups/my/groups/resources/edit"
import ResourcesDelete from "source/groups/my/groups/resources/delete"
import MembersIndex from "source/groups/my/groups/members"
import MemberShow from "source/groups/my/groups/members/show"
import MemberRemove from "source/groups/my/groups/members/remove"
import EmailNew from "source/groups/my/groups/members/email/new"
import MembershipEdit from "source/groups/my/groups/membership/edit"
import ApplicationsIndex from "source/groups/my/groups/applications"
import ApplicationShow from "source/groups/my/groups/applications/show"
import { get as dig } from "lodash"
import { RecordNotFound } from "source/shared/components"

import { TopicsDataProvider } from "source/groups/messaging/TopicsDataContext"

export const CurrentGroupContext = createContext()
export const CurrentEventContext = createContext()

export function CurrentGroupProvider({
  group,
  slug,
  location,
  rsvpResponseAttributes,
  children,
}) {
  const infiniteLoadingMemberships = useInfiniteLoadingResource(
    `/groups/v2/me/groups/${group.id}/memberships?order=role,first_name`,
  )
  const slugAttrs = slug ? { slug, base_path: `/my/groups/${slug}` } : {}

  return (
    <CurrentGroupContext.Provider
      value={{
        id: group.id,
        infiniteLoadingMemberships,
        links: group.links,
        location,
        rsvpResponseAttributes,
        ...group.attributes,
        ...slugAttrs,
      }}
    >
      {children}
    </CurrentGroupContext.Provider>
  )
}
CurrentGroupProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  group: apiResource.isRequired,
  location: apiResource,
  rsvpResponseAttributes: arrayOf(apiResource).isRequired,
  slug: string,
}

export function MyGroupRouter() {
  const { slug } = useParams()
  useSession(true)
  const json = useApiRead(
    `/groups/v2/me/groups/${slug}?include=location,rsvp_responses`,
  )

  if (json.errors) return <GroupNotFound />

  const group = json.data
  const location = getRelationship(json, "location")
  const chatEnabled = group.attributes.abilities.conversations.can_read
  const messagingEnabled =
    !chatEnabled && group.attributes.abilities.forum_topics.can_read
  const myGroupRootPath = chatEnabled
    ? "conversations"
    : messagingEnabled
      ? "messages"
      : "events"
  const rsvpResponseAttributes = getRelationship(json, "rsvp_responses")

  return (
    <CurrentGroupProvider
      group={group}
      slug={slug}
      location={location}
      rsvpResponseAttributes={rsvpResponseAttributes}
    >
      <WideLayout>
        <Routes>
          <Route path="/" element={<Navigate to={myGroupRootPath} replace />} />

          <Route path="conversations/*" element={<ConversationsRouter />} />
          <Route path="messages/*" element={<MessagesRouter />} />
          <Route path="events/*" element={<EventsRouter />} />
          <Route path="resources/*" element={<ResourcesRouter />} />
          <Route path="members" element={<MembersIndex />} />
          <Route path="members/:memberId" element={<MemberShow />} />
          <Route path="members/:memberId/remove" element={<MemberRemove />} />
          <Route path="members/email/new" element={<EmailNew />} />
          <Route path="membership/edit" element={<MembershipEdit />} />
          <Route path="applications" element={<ApplicationsIndex />} />
          <Route
            path="applications/:applicationId"
            element={<ApplicationShow />}
          />
          <Route path="attendances" element={<AttendanceIndex />} />
        </Routes>
      </WideLayout>
    </CurrentGroupProvider>
  )
}

function RedirectFromReplyReactions() {
  const { slug, topicId, replyId } = useParams()
  const targetPath = `/my/groups/${slug}/messages/${topicId}/replies/${replyId}`

  return <Navigate to={targetPath} replace />
}

function RedirectFromTopicReactions() {
  const { slug, topicId } = useParams()
  const targetPath = `/my/groups/${slug}/messages/${topicId}?scroll=top`

  return <Navigate to={targetPath} replace />
}

function MessagesRouter() {
  return (
    <TopicsDataProvider>
      <Routes>
        <Route
          path=":topicId/replies/:replyId/reactions"
          element={<RedirectFromReplyReactions />}
        />
        <Route
          path="/:topicId/reactions"
          element={<RedirectFromTopicReactions />}
        />
        <Route path="/" element={<MessagesIndex />} />
        <Route path="new" element={<MessagesNew />} />
        <Route path=":topicId" element={<MessagesShow />} />
        <Route path=":topicId/edit" element={<MessagesEdit />} />
        <Route path=":topicId/replies/:replyId" element={<ReplyShow />} />
      </Routes>
    </TopicsDataProvider>
  )
}

function EventsRouter() {
  return (
    <Routes>
      <Route path="/" element={<EventsIndex />} />
      <Route path="new" element={<EventsNew />} />
      <Route path=":eventId/*" element={<EventRouter />} />
    </Routes>
  )
}

function EventRouter() {
  const { eventId } = useParams()
  const group = useContext(CurrentGroupContext)
  const fetchEventUrl = `${group.links.self}/events/${eventId}?include=location,my_rsvp,attendance_recording,repeating_event,next_reminder,jolt_channel`
  const initialState = useApiRead(fetchEventUrl)
  const [json, setJson] = useState(initialState)
  const event = dig(json, "data", {})

  const refetchEvent = useCallback(
    () => sessionApiClient.get(fetchEventUrl).then((res) => setJson(res)),
    [fetchEventUrl],
  )
  const routeLocation = useLocation()

  useEffect(() => {
    refetchEvent()
  }, [routeLocation, refetchEvent])

  if (json.errors) return <EventNotFound />

  const location = getRelationship(json, "location")
  const my_rsvp = getRelationship(json, "my_rsvp")
  const attendance_recording = getRelationship(json, "attendance_recording")
  const repeating_event = getRelationship(json, "repeating_event")
  const next_reminder = getRelationship(json, "next_reminder")
  const jolt_channel = getRelationship(json, "jolt_channel")

  return (
    <CurrentEventContext.Provider
      value={{
        base_path: `${group.base_path}/events/${eventId}`,
        event,
        location,
        attendance_recording,
        jolt_channel,
        my_rsvp,
        next_reminder,
        repeating_event,
        refetchEvent,
      }}
    >
      <Routes>
        <Route path="/" element={<EventsShow />} />
        <Route path="edit" element={<EventsEdit />} />
        <Route path="cancel" element={<EventsCancel />} />
        <Route path="attendance" element={<AttendanceEdit />} />
        <Route path="my_note" element={<MyNote />} />
        <Route path="notes/new" element={<NotesNew />} />
        <Route path="notes/:noteId" element={<NotesShow />} />
        <Route path="notes/:noteId/edit" element={<NotesEdit />} />
      </Routes>
    </CurrentEventContext.Provider>
  )
}

function ResourcesRouter() {
  return (
    <Routes>
      <Route path="/" element={<ResourcesIndex />} />
      <Route path="new" element={<ResourcesNew />} />
      <Route path=":resourceId" element={<ResourcesShow />} />
      <Route path=":resourceId/edit" element={<ResourcesEdit />} />
      <Route path=":resourceId/delete" element={<ResourcesDelete />} />
    </Routes>
  )
}

function GroupNotFound() {
  const { data: currentPerson } = useSession()
  const logoutAndReturnUrl = `/logout?return=${encodeURIComponent(
    window.location.href,
  )}`

  return (
    <RecordNotFound
      message={
        <>
          You are logged in as {currentPerson.attributes.name} and do not have
          access to this group. Check out all available groups or log into a
          different profile.
        </>
      }
      actions={
        <>
          <Link to="/groups" className="btn">
            All Groups
          </Link>
          <Link to={logoutAndReturnUrl} className="btn secondary-btn ml-1">
            Log Out
          </Link>
        </>
      }
    />
  )
}

function EventNotFound() {
  const group = useContext(CurrentGroupContext)

  return (
    <RecordNotFound
      message={
        <>
          We can&rsquo;t find that event! That event may have been removed from
          the group. Check out all group events to find more.
        </>
      }
      actions={
        <Link to={`${group.base_path}/events`} className="btn">
          All Events
        </Link>
      }
    />
  )
}
