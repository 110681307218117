import { createContext, useContext, useReducer, useRef } from "react"
import {
  RepliesDataContext,
  prepareMessageForSubmitting,
} from "source/groups/messaging/Topic/RepliesDataContext"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { node, string, func, shape } from "prop-types"
import { useSession } from "source/shared/hooks/useSession"
import { Icon } from "source/shared/components"

const SET_TEXT = "SET_TEXT"
const ADD_FILE = "ADD_FILE"
const REMOVE_FILE = "REMOVE_FILE"
const RESET_FILES = "RESET_FILES"
const SET_IN_FLIGHT = "SET_IN_FLIGHT"

const initialState = {
  text: "",
  files: [],
  inFlight: false,
}

function reducer(state, action) {
  switch (action.type) {
    case SET_TEXT:
      return {
        ...state,
        text: action.payload,
      }
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.payload],
      }
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((f) => f !== action.payload.file),
      }
    case RESET_FILES:
      return {
        ...state,
        files: initialState.files,
      }
    case SET_IN_FLIGHT:
      return {
        ...state,
        inFlight: action.payload,
      }
    default:
      throw new Error(`Unrecognized action: ${action.type}`)
  }
}

const NewReplyContext = createContext()

NewReply.propTypes = {
  children: node.isRequired,
}

export default function NewReply({ children }) {
  const [{ text, files, inFlight }, dispatch] = useReducer(
    reducer,
    initialState,
  )
  const { data: currentPerson } = useSession(false)
  let {
    ingest,
    injected: { group, topic },
  } = useContext(RepliesDataContext)
  let inputRef = useRef()

  function setText(text) {
    return dispatch({ type: SET_TEXT, payload: text })
  }

  function addFiles(e) {
    const rawFiles = [...e.target.files]

    rawFiles.map((file) => {
      const reader = new FileReader()
      reader.onload = function (e) {
        dispatch({
          type: ADD_FILE,
          payload: { file: file, img: e.target.result },
        })
      }
      reader.readAsDataURL(file)
    })
  }

  function removeFile(file) {
    if (confirm("Are you sure you want to remove this attachment?")) {
      dispatch({ type: REMOVE_FILE, payload: file })
    }
  }

  function uploadFiles(files) {
    return Promise.all(
      files.map((file) => {
        return new Promise((resolve, reject) => {
          sessionApiClient
            .uploadFile(file.file)
            .then(({ data }) => {
              resolve(data[0].id)
            })
            .catch((e) => reject(e))
        })
      }),
    )
  }

  const canSend = !inFlight && (text.trim() !== "" || files.length !== 0)

  let handleSubmit = function () {
    event.preventDefault()

    if (!canSend) return

    let message = prepareMessageForSubmitting(text)

    const path = `/groups/v2/me/groups/${group.id}/forum_topics/${
      topic.id
    }/replies?include=author`

    ingest(
      generateFakeReply(message, currentPerson, {
        pending: true,
        hasFiles: files.length > 0 ? true : false,
      }),
    )

    inputRef.current.style.height = "48px"

    dispatch({ type: SET_IN_FLIGHT, payload: true })

    uploadFiles(files)
      .then((fileIDs) =>
        sessionApiClient.post(path, {
          data: { attributes: { message, files: fileIDs } },
        }),
      )
      .then((json) => ingest(json))
      .then(() => dispatch({ type: RESET_FILES }))
      .catch(() => {
        ingest(generateFakeReply(message, currentPerson, { error: true }))
      })
      .finally(() => dispatch({ type: SET_IN_FLIGHT, payload: false }))

    setTimeout((_) => dispatch({ type: SET_TEXT, payload: "" }), 100)
    inputRef.current.focus()
  }

  return (
    <NewReplyContext.Provider
      value={{
        addFiles,
        canSend,
        files,
        handleSubmit,
        inFlight,
        inputRef,
        removeFile,
        setText,
        text,
      }}
    >
      <div className="action-drawer messaging__new-reply">{children}</div>
    </NewReplyContext.Provider>
  )
}

NewReply.Editor = Editor
NewReply.AddFilesButton = AddFilesButton
NewReply.SingleLineInput = SingleLineInput
NewReply.MultiLineInput = MultiLineInput
NewReply.FilePreviews = FilePreviews

function Editor({ children }) {
  const { handleSubmit } = useContext(NewReplyContext)

  return (
    <form onSubmit={handleSubmit} className="messaging__new-reply-form">
      <div className="messaging__new-reply-form-wrapper">{children}</div>
    </form>
  )
}

Editor.propTypes = {
  children: node.isRequired,
}

function SingleLineInput() {
  const { inputRef, text, setText, canSend } = useContext(NewReplyContext)

  return (
    <>
      <input
        ref={inputRef}
        value={text}
        onChange={(event) => setText(event.target.value)}
        className="messaging__new-reply-input"
        style={{
          borderRadius: 30,
          backgroundColor: "var(--color-tint10)",
          borderColor: "var(--color-tint6)",
          boxShadow: "inset 0 1px 2px var(--color-tint9)",
        }}
        type="text"
        placeholder="Message this group"
      />
      <button
        type="submit"
        className="stripped-btn messaging__submit-new-reply messaging__form-btn"
        aria-label="Submit message"
        disabled={!canSend}
      >
        <Icon
          symbol={
            canSend ? "churchCenter#paper-plane" : "churchCenter#paper-plane-o"
          }
          className={canSend ? "has-text" : "no-text"}
          style={{
            fill: canSend ? "var(--color-brand)" : "var(--color-tint3)",
          }}
        />
      </button>
    </>
  )
}

function MultiLineInput() {
  const { inputRef, text, setText, canSend, handleSubmit } =
    useContext(NewReplyContext)

  function handleChange(event) {
    setText(event.target.value)

    event.target.style.height = "auto"
    event.target.style.height = event.target.scrollHeight + "px"
  }

  return (
    <>
      <textarea
        ref={inputRef}
        value={text}
        onChange={handleChange}
        onKeyPress={(e) => {
          if (e.which === 13 && !e.shiftKey) handleSubmit()
        }}
        className="messaging__new-reply-textarea"
        rows={1}
        style={{
          backgroundColor: "var(--color-tint10)",
          borderColor: "var(--color-tint6)",
          boxShadow: "inset 0 1px 2px var(--color-tint9)",
          minHeight: "50px",
          maxHeight: "30vh",
        }}
        placeholder="Message this group"
      />
      <button
        type="submit"
        className="stripped-btn messaging__submit-new-reply messaging__form-btn"
        aria-label="Submit message"
        disabled={!canSend}
      >
        <Icon
          symbol={
            canSend ? "churchCenter#paper-plane" : "churchCenter#paper-plane-o"
          }
          className={canSend ? "has-text" : "no-text"}
          style={{
            fill: canSend ? "var(--color-brand)" : "var(--color-tint4)",
          }}
        />
      </button>
    </>
  )
}

function FilePreviews() {
  const { files, removeFile, inFlight } = useContext(NewReplyContext)

  if (inFlight || files.length === 0) return null

  return (
    <div className="d-f messaging__file-preview-bar">
      {files.map((f, idx) => (
        <FilePreview key={idx} file={f} removeFile={removeFile} />
      ))}
    </div>
  )
}

function FilePreview({ file, removeFile }) {
  return (
    <div className="p-r mr-2">
      <span className="messaging__preview-image-container">
        {file.img ? (
          <img
            src={file.img}
            className="messaging__preview-image"
            alt={file.file.name}
          />
        ) : (
          <Icon symbol="churchCenter#photos-o" />
        )}
      </span>
      <button
        className="messaging__preview-remove"
        onClick={() => removeFile({ file })}
        aria-label={`Remove ${file.file.name}`}
      >
        <Icon symbol="general#x" />
      </button>
    </div>
  )
}
FilePreview.propTypes = {
  file: shape({
    id: string,
    img: string,
  }).isRequired,
  removeFile: func.isRequired,
}

function AddFilesButton() {
  const { addFiles } = useContext(NewReplyContext)

  return (
    <button
      className="messaging__upload-btn messaging__form-btn stripped-btn"
      aria-label="Upload a file to this messaging thread."
      type="button"
    >
      <label
        htmlFor="testUpload"
        css={{
          color: "var(--color-tint3)",
          cursor: "pointer",
          paddingBottom: 0,
          "&:hover": { color: "var(--color-brand)" },
        }}
      >
        <Icon
          symbol="churchCenter#photos-o"
          style={{ verticalAlign: "middle", fontSize: "20px" }}
        />
      </label>
      <input
        id="testUpload"
        type="file"
        accept="image/gif, image/jpeg, image/png"
        multiple="multiple"
        onChange={addFiles}
        style={{ display: "none" }}
      />
    </button>
  )
}

function generateFakeReply(message, currentPerson, properties) {
  const now = new Date()
  return {
    data: {
      type: "Reply",
      id: Math.random().toString(36),
      attributes: {
        abilities: {
          can_update: false,
          can_delete: false,
        },
        message,
        created_at: now,
        updated_at: now,
        expanded_links: {},
        reactions_summary: { reactions: [], total_count: 0 },
      },
      relationships: {
        author: {
          data: {
            type: "Author",
            id: currentPerson.id,
          },
        },
        topic: {
          data: {
            type: "Topic",
            id: "1",
          },
        },
      },
      links: {},
      ...properties,
    },
    included: [
      {
        type: "Author",
        id: currentPerson.id,
        attributes: {
          avatar_url: currentPerson.attributes.avatar_url,
          name: currentPerson.attributes.name,
          status: "active",
        },
      },
    ],
  }
}
