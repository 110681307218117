import { bool, func } from "prop-types"

export default function AcceptDeclineControl({
  handleAccept,
  handleDecline,
  isAccepted,
}) {
  return (
    <div className="d-f ai-c">
      <button
        className={
          isAccepted
            ? "minor-compact-btn btn mr-1"
            : "minor-btn destroy-btn btn mr-1"
        }
        style={{
          paddingLeft: 12,
          paddingRight: 12,
        }}
        onClick={handleDecline}
      >
        Decline
      </button>
      <button
        className={isAccepted ? "minor-btn btn" : "minor-compact-btn btn"}
        style={{
          paddingLeft: 12,
          paddingRight: 12,
        }}
        onClick={handleAccept}
      >
        Accept
      </button>
    </div>
  )
}

AcceptDeclineControl.propTypes = {
  handleAccept: func,
  handleDecline: func,
  isAccepted: bool,
}
