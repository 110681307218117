import { Suspense, useEffect } from "react"
import { array, object, string } from "prop-types"
import { WideLayout } from "source/Layout"
import { Link } from "react-router-dom"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import { Icon, Avatar, Loading } from "source/shared/components"
import InfiniteScroll from "react-infinite-scroller"
import { groupBy } from "lodash"
import { usePeople } from "source/directory/people/usePeople"
import { getRelationship } from "source/shared/getRelationship"
import { useQRCode } from "source/shared/QRCode"
import { useValidSearchTerm } from "source/directory/hooks/useValidSearchTerm"
import { DirectoryAlerts } from "source/directory/DirectoryAlerts"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { LAST_VIEW_STORAGE_KEY } from "source/directory/index"
import {
  Menu,
  MenuButton,
  MenuList,
  MenuLink,
  MenuItem,
} from "@reach/menu-button"

const ORDER_STRINGS = {
  last_name: "last_name,first_name",
  first_name: "first_name,last_name",
}

export function PeopleIndex() {
  const [order, setOrder] = useLocalStorage("DirectorySorting", "first_name")
  const [_directoryLastView, setDirectoryLastView] = useLocalStorage(
    LAST_VIEW_STORAGE_KEY,
    "people",
  )
  const { searchTerm, debouncedValidSearchTerm, setSearchTerm } =
    useValidSearchTerm("")
  const orderDisplayString = {
    last_name: "By last name",
    first_name: "By first name",
  }

  useQRCode()

  useEffect(() => setDirectoryLastView("people"), [])

  return (
    <WideLayout>
      <DirectoryAlerts />
      <div className="action-drawer mb-2 d-f fd-c fd-r@sm ai-c">
        <div className="d-b d-f@sm f-2 fw-w mb-1 mb-0@sm mr-1@sm w-100%">
          <form className="w-100% p-r" onSubmit={(e) => e.preventDefault()}>
            <input
              type="search"
              style={{ paddingLeft: 32 }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
            />
            <Icon
              className="c-tint4 fs-3 p-a zi-100"
              style={{ left: 10, top: 11 }}
              symbol={`general#search`}
              aria-hidden
            />
          </form>
        </div>

        <div className="f-1 d-f fd-c fd-r@sm ai-c@sm mb-1 mb-0@sm w-100%">
          <Menu>
            <MenuButton className="ta-l select">View people</MenuButton>
            <MenuList
              className="dropdown__menu"
              style={{ "--dropdown-menu--font-size": "1rem" }}
            >
              <MenuLink href="/directory/people" className="dropdown__item">
                View people
              </MenuLink>
              <MenuLink href="/directory/households" className="dropdown__item">
                View households
              </MenuLink>
            </MenuList>
          </Menu>
        </div>
        <div className="f-1 d-f fd-c fd-r@sm ai-c@sm mb-1 mb-0@sm ml-1@sm w-100%">
          <Menu>
            <MenuButton className="ta-l select">
              {orderDisplayString[order]}
            </MenuButton>
            <MenuList
              className="dropdown__menu"
              style={{ "--dropdown-menu--font-size": "1rem" }}
            >
              <MenuItem
                onSelect={() => setOrder("first_name")}
                className="dropdown__item"
              >
                By first name
              </MenuItem>
              <MenuItem
                onSelect={() => setOrder("last_name")}
                className="dropdown__item"
              >
                By last name
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>

      <Suspense fallback={<Loading />}>
        <IndexPeople order={order} search={debouncedValidSearchTerm} />
      </Suspense>
    </WideLayout>
  )
}

IndexPeople.propTypes = {
  order: string.isRequired,
  search: string,
}

export function IndexPeople({ order, search }) {
  const { people, included, hasMore, loadMore } = usePeople({
    order: ORDER_STRINGS[order],
    where: { name: search },
    include: "directory_households,directory_households.directory_people",
    "fields[DirectoryPerson]":
      "avatar_url,first_name,last_name,name,directory_households",
    "fields[DirectoryHousehold]": "name,directory_people",
  })
  const groupedPeople = groupBy(people, ({ attributes }) =>
    attributes[order][0].toUpperCase(),
  )

  return (
    <>
      {Object.entries(groupedPeople).length ? (
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          loader={<Loading key="loading" />}
          className="container container--narrow"
        >
          {Object.entries(groupedPeople).map(([letter, people]) => (
            <div key={letter || "nonLetterName"}>
              {letter && (
                <div className="action-drawer mb-2 py-1">
                  <Subheading level={5} text={letter} />
                </div>
              )}
              <ul className="unstyled">
                {people.map((person) => (
                  <Person key={person.id} person={person} included={included} />
                ))}
              </ul>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <NoEntriesPlaceholder />
      )}
    </>
  )
}

const NoEntriesPlaceholder = () => (
  <div className="mt-2 ta-c c-tint2 fs-2">
    <Icon symbol="churchCenter#directory-o" className="mb-1" aria-hidden />
    <div>Looks like there aren&apos;t any entries in the directory yet</div>
  </div>
)

Person.propTypes = {
  person: object.isRequired,
  included: array.isRequired,
}

function Person({ person, included }) {
  const households = person.relationships.directory_households
    ? getRelationship({ data: person, included }, "directory_households")
    : []

  return (
    <li className="mb-2" key={person.id}>
      <Link to={`/directory/people/${person.id}`} className="d-f f-2">
        <div className="avatar mr-2 w-7 h-7 o-h">
          <Avatar
            url={person.attributes.avatar_url}
            alt={`profile image for ${name}`}
          />
        </div>
        <div className="d-f fd-c f-6 mt-2">
          <div className="pr-1 mb-2p lh-1.25">
            <Heading level={3} size={4} text={person.attributes.name} />
          </div>
          <div className="mb-4p">
            {households.map(
              (h) =>
                h.relationships.directory_people.data.length > 1 && (
                  <Household key={h.id} household={h} included={included} />
                ),
            )}
          </div>
        </div>
      </Link>
    </li>
  )
}

Household.propTypes = {
  household: object.isRequired,
  included: array.isRequired,
}

function Household({ household, included }) {
  const people = household.relationships.directory_people
    ? getRelationship({ data: household, included }, "directory_people")
    : []

  return (
    <div className="d-f fw-400 ta-l" style={{ fontSize: "13px" }}>
      <Icon
        className="c-tint4 fs-5 p-r t-2p mr-4p pr-2p"
        symbol={`churchCenter#home`}
        alt={household.attributes.name}
      />
      <Heading
        level={6}
        color="tint2"
        text={people.map((p) => p.attributes.first_name).join(", ")}
      />
    </div>
  )
}
