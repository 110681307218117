import {
  isStringBlank,
  isStringEqual,
} from "source/registrations/utils/helpers"
import { calculateAge } from "source/registrations/utils/dates"

const gradeToString = (type) => {
  const { minGrade, maxGrade, minGradeToS, maxGradeToS } = type

  const isOnlyMinGrade = minGradeToS && !maxGradeToS

  const isOnlyMaxGrade = maxGradeToS && !minGradeToS

  const isMinAndMaxGradeEqual =
    minGradeToS && maxGradeToS && isStringEqual(minGradeToS, maxGradeToS)

  const isMinAndMaxGradeNotEqual =
    minGradeToS && maxGradeToS && !isStringEqual(minGradeToS, maxGradeToS)

  if (isMinAndMaxGradeEqual) {
    return `${minGradeToS} ${minGrade > 0 ? "grade" : ""}`
  } else if (isMinAndMaxGradeNotEqual) {
    return `${minGradeToS}–${maxGradeToS} ${maxGrade > 0 ? "grade" : ""}`
  } else if (isOnlyMinGrade) {
    return `${minGradeToS} ${minGrade > 0 ? "grade" : ""} and up`
  } else if (isOnlyMaxGrade) {
    return `Up to ${maxGradeToS} ${maxGrade > 0 ? "grade" : ""}`
  }
}

const ageToString = (type) => {
  const {
    minAge,
    maxAge,
    minAgeInMonths,
    maxAgeInMonths,
    ageRestrictionUnitToS,
    evaluateAgeAtString,
  } = type

  const isInYears = isStringEqual(ageRestrictionUnitToS, "years")
  const minimumAge = isInYears ? minAge : minAgeInMonths
  const maximumAge = isInYears ? maxAge : maxAgeInMonths

  const isOnlyMinAge = minimumAge && !maximumAge

  const isOnlyMaxAge = maximumAge && !minimumAge

  const isMinAndMaxAgeEqual =
    minimumAge && maximumAge && isStringEqual(minimumAge, maximumAge)

  const isMinAndMaxAgeNotEqual =
    minimumAge && maximumAge && !isStringEqual(minimumAge, maximumAge)

  const byDate = !isStringBlank(evaluateAgeAtString)
    ? `by ${evaluateAgeAtString}`
    : ""

  if (isMinAndMaxAgeEqual) {
    return `${minimumAge} ${ageRestrictionUnitToS} ${byDate}`
  } else if (isMinAndMaxAgeNotEqual) {
    return `${minimumAge}–${maximumAge} ${ageRestrictionUnitToS} ${byDate}`
  } else if (isOnlyMinAge) {
    return `${minimumAge} ${ageRestrictionUnitToS} and up ${byDate}`
  } else if (isOnlyMaxAge) {
    return `Up to ${maximumAge} ${ageRestrictionUnitToS} ${byDate}`
  }
}

export const isFull = (type) => type.atMaximumCapacity

export const isGenderRestricted = (type, person) => {
  let status = false
  const { hasGenderRestrictions, genderRestrictionUnit } = type

  if (!hasGenderRestrictions || !person.gender) {
    return status
  }

  if (isCustomGender(person.gender)) return false
  if (!gendersMatch(genderRestrictionUnit, person.gender)) {
    status = true
  }

  return status
}

function isCustomGender(gender) {
  return !!gender && !isMaleGender(gender) && !isFemaleGender(gender)
}

function gendersMatch(genderA, genderB) {
  return (
    genderA === genderB ||
    (isMaleGender(genderA) && isMaleGender(genderB)) ||
    (isFemaleGender(genderA) && isFemaleGender(genderB))
  )
}

const MALE_GENDERS = ["m", "male"]
function isMaleGender(gender) {
  return MALE_GENDERS.includes(gender.toLowerCase())
}

const FEMALE_GENDERS = ["f", "female"]
function isFemaleGender(gender) {
  return FEMALE_GENDERS.includes(gender.toLowerCase())
}

export const isGradeRestricted = (type, person) => {
  const {
    collectionOptions = {},
    hasGradeRestrictions,
    minGrade,
    maxGrade,
  } = type

  const isAdult = !person.child
  const isGradeRequired = collectionOptions["grade"] === "required"
  const isPersonGradeBlank = isStringBlank(person.grade)

  if (!isGradeRequired && !hasGradeRestrictions) return false
  if (isAdult && isPersonGradeBlank) return true

  if (!isPersonGradeBlank && hasGradeRestrictions) {
    return (
      (minGrade !== null && person.grade < minGrade) ||
      (maxGrade !== null && person.grade > maxGrade)
    )
  }

  return false
}

export const isAgeRestricted = (type, person) => {
  let status = false
  const {
    hasAgeRestrictions,
    ageRestrictionUnitToS,
    evaluateAgeAt,
    minAge,
    maxAge,
  } = type

  if (!hasAgeRestrictions || !person.birthdate) {
    return status
  }

  const personAge = calculateAge(
    person.birthdate,
    evaluateAgeAt,
    isStringEqual(ageRestrictionUnitToS, "years"),
  )

  if ((minAge && personAge < minAge) || (maxAge && personAge > maxAge)) {
    status = true
  }

  return status
}

export const isSelectionDisabled = (type, person, attendeeWaitlist) => {
  let isDisabled = false
  let disabledReason = ""

  if (isFull(type) && !type.waitlist && !attendeeWaitlist) {
    isDisabled = true
    disabledReason = "(does not meet restrictions)"
  } else if (isGenderRestricted(type, person)) {
    isDisabled = true
    disabledReason = "(does not meet gender restrictions)"
  } else if (isGradeRestricted(type, person)) {
    isDisabled = true
    disabledReason = "(does not meet grade restrictions)"
  } else if (isAgeRestricted(type, person)) {
    isDisabled = true
    disabledReason = "(does not meet age restrictions)"
  }

  return { isDisabled: isDisabled, disabledReason: disabledReason }
}

export const restrictionsToString = (type) => {
  if (!type) return ""

  const restrictions = []

  if (type.hasGenderRestrictions) {
    restrictions.push(type.genderRestrictionUnitToS)
  }

  if (type.hasGradeRestrictions) {
    restrictions.push(gradeToString(type))
  }

  if (type.hasAgeRestrictions) {
    restrictions.push(ageToString(type))
  }

  return restrictions.join(", ")
}
