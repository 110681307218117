import { useEffect } from "react"
import { string, func, bool, number, array } from "prop-types"
import { Select } from "source/shared/components"
import { useParams } from "react-router-dom"
import { Checkbox } from "source/registrations/CheckableInput"
import { useEvent } from "source/registrations/hooks/useEvent"

const AttendeePersonFormHousehold = ({
  attendeeId,
  addToHouseholdValue,
  onAddToHouseholdChange,
  householdValue,
  onHouseholdChange,
  households,
  maxHouseholdSize,
}) => {
  const { eventId } = useParams()

  const {
    data: { canRegisterHouseholdMembers, canRegisterOutsideMembers },
  } = useEvent(eventId)

  useEffect(() => {
    if (canRegisterHouseholdMembers) {
      handleAddToHousehold({
        checked: canRegisterOutsideMembers ? false : true,
      })
    }
  }, [])

  const handleAddToHousehold = ({ checked }) => {
    const householdId =
      households.length === 0
        ? "new"
        : households
            .find((household) => household.peoples.length < maxHouseholdSize)
            ?.id?.toString()

    onAddToHouseholdChange(checked, householdId)
  }

  const allHouseholdsFull =
    households.length === 0
      ? false
      : households.every(
          (household) => household.peoples.length >= maxHouseholdSize,
        )

  const householdSelectOptions = households.map((household) => {
    const isFull = household.peoples.length >= maxHouseholdSize

    return {
      label: isFull ? `${household.name} (Full)` : household.name,
      value: household.id,
      disabled: isFull,
    }
  })

  return (
    <>
      <div className="mb-2">
        {canRegisterHouseholdMembers && (
          <Checkbox
            name="addToHousehold"
            id={`addToHousehold-${attendeeId}`}
            checked={addToHouseholdValue}
            onChange={(e) => handleAddToHousehold(e.target)}
            label="Add this person to my household"
            disabled={!canRegisterOutsideMembers || allHouseholdsFull}
          />
        )}

        {canRegisterHouseholdMembers &&
          householdValue &&
          households.length > 1 && (
            <div className="mt-1">
              <Select
                name="setHousehold"
                ariaLabel="select household"
                value={householdValue}
                onChange={onHouseholdChange}
                options={householdSelectOptions}
              />
            </div>
          )}
      </div>
    </>
  )
}

AttendeePersonFormHousehold.propTypes = {
  attendeeId: number,
  addToHouseholdValue: bool,
  onAddToHouseholdChange: func,
  householdValue: string,
  onHouseholdChange: func,
  households: array,
  maxHouseholdSize: number,
}

export default AttendeePersonFormHousehold
