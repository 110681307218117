import { useReducer } from "react"
import { get as dig } from "lodash"

export const SET_ATTRIBUTES = "SET_ATTRIBUTES"
export const SET_BUSY = "SET_BUSY"
export const SET_ERRORS = "SET_ERRORS"
export const FILE_RESOURCE = "FileResource"
export const LINK_RESOURCE = "LinkResource"
export const ADD_FILE = "ADD_FILE"
export const REMOVE_FILE = "REMOVE_FILE"

function reducer(state, action) {
  switch (action.type) {
    case SET_ATTRIBUTES:
      return {
        ...state,
        ...action.payload,
      }
    case ADD_FILE:
      return {
        ...state,
        file: action.payload,
      }
    case REMOVE_FILE:
      return {
        ...state,
        file: null,
      }
    case SET_BUSY:
      return {
        ...state,
        isBusy: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
  }
}

export function useResourceFormReducer(existingResource) {
  const initialState = {
    resourceId: dig(existingResource, "id", null),
    name: dig(existingResource, "attributes.name", ""),
    description: dig(existingResource, "attributes.description", ""),
    visibility: dig(existingResource, "attributes.visibility", "members"),
    type: dig(existingResource, "attributes.type", FILE_RESOURCE),
    file: null,
    fileAttributes: dig(existingResource, "attributes.file_attributes", {}),
    iconClass: dig(existingResource, "attributes.icon_class", ""),
    link: dig(existingResource, "attributes.link_url", ""),
    isBusy: false,
    errors: [],
  }

  return useReducer(reducer, initialState)
}
