import { Link } from "react-router-dom"
import { Icon } from "source/shared/components"
import { string, number, object, array, bool, oneOfType } from "prop-types"

const ContextItem = ({ icon, iconClass, text, index }) => {
  return (
    <div className="d-f ai-c" key={index || null}>
      {icon && <Icon symbol={icon} className={`${iconClass}`} aria-hidden />}

      {text && <div className="fs-4 c-tint2">{text}</div>}
    </div>
  )
}

ContextItem.propTypes = {
  icon: oneOfType([string, bool]),
  iconClass: string,
  text: string.isRequired,
  index: number,
}

const ScheduleItem = ({
  linkTitle,
  linkUrl,
  linkState,
  itemDescription,
  itemContext,
}) => {
  return (
    <div className="mb-2">
      <Link className="c-brand fw-500" state={linkState} to={linkUrl}>
        {linkTitle}
      </Link>

      {itemDescription && <div>{itemDescription}</div>}

      {itemContext &&
        (itemContext.length ? (
          itemContext.map((context, index) => (
            <ContextItem
              key={index}
              icon={context.icon}
              iconClass={context.iconClass}
              text={context.text}
            />
          ))
        ) : (
          <ContextItem
            icon={itemContext.icon}
            iconClass={itemContext.iconClass}
            text={itemContext.text}
          />
        ))}
    </div>
  )
}

ScheduleItem.propTypes = {
  linkTitle: oneOfType[(string, object)],
  linkUrl: string,
  linkState: object,
  itemDescription: string,
  itemContext: oneOfType[(object, array)],
}

export default ScheduleItem
