import PropTypes from "prop-types"
import { cloneElement, Component } from "react"
const { shape, number, instanceOf, node } = PropTypes

const latLngProp = shape({ lat: number.isRequired, lng: number.isRequired })

export default class extends Component {
  static propTypes = {
    position: latLngProp.isRequired,
    map: instanceOf(google.maps.Map),
    infoWindow: node,
  }

  state = { marker: null, isOpen: false }

  componentDidMount() {
    this.clickListener = null
    this.doImperativeWork()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.doImperativeWork(nextProps)
  }

  componentWillUnmount() {
    if (this.state.marker) {
      this.state.marker.setMap(null)
    }
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  // eslint-disable-next-line consistent-return
  doImperativeWork = (props = this.props) => {
    const { marker } = this.state

    if (!marker) {
      return this.createMarker().then(this.doImperativeWork)
    }

    marker.setPosition(props.position)
    this.clickListener && this.clickListener.remove()
    this.clickListener = marker.addListener("click", this.handleClick)
  }

  createMarker = () => {
    const marker = new google.maps.Marker({ map: this.props.map })
    return new Promise((resolve) => this.setState({ marker }, resolve))
  }

  render() {
    const { marker, isOpen } = this.state

    if (!marker || !this.props.infoWindow) return null

    return isOpen
      ? cloneElement(this.props.infoWindow, {
          anchor: marker,
          map: this.props.map,
          onClose: () => this.setState({ isOpen: false }),
        })
      : null
  }
}
