import { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"
import { useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { merge } from "lodash"
import { CurrentGroupContext } from "source/groups/my/groups"
import {
  useResourceFormReducer,
  FILE_RESOURCE,
  LINK_RESOURCE,
  SET_ATTRIBUTES,
  SET_BUSY,
  SET_ERRORS,
} from "../reducers/useResourceFormReducer"
import { FilePreview } from "./FilePreview"
import { FileResource } from "./FileResource"
import { LinkResource } from "./LinkResource"
import { FormErrors } from "./FormErrors"
import { Buttons } from "./Buttons"
import { Select } from "@planningcenter/doxy-web"

const FormContext = createContext()

Form.propTypes = {
  existingResource: PropTypes.object,
  children: PropTypes.node.isRequired,
}
export function Form({ existingResource = {}, children }) {
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const [state, dispatch] = useResourceFormReducer(existingResource)
  const group = useContext(CurrentGroupContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch({ type: SET_BUSY, payload: true })

    const baseApiPath = `/groups/v2/me/groups/${group.id}/resources`
    const { name, link, description, visibility, type } = state

    const basePayload = {
      data: {
        attributes: {
          name,
          description,
          visibility,
        },
      },
    }

    const linkPayload = merge(basePayload, {
      data: {
        attributes: {
          type: LINK_RESOURCE,
          link,
        },
      },
    })

    const filePayload = (file_uuid) =>
      merge(basePayload, {
        data: {
          attributes: {
            type: FILE_RESOURCE,
            file_uuid,
          },
        },
      })

    function uploadFiles() {
      if (!state.file) return Promise.resolve()

      return new Promise((resolve, reject) => {
        sessionApiClient
          .uploadFile(state.file.file)
          .then(({ data }) => {
            resolve(data[0].id)
          })
          .catch((e) => reject(e))
      })
    }

    function patchOrPost(fileID) {
      const payload = type === FILE_RESOURCE ? filePayload(fileID) : linkPayload

      return resourceId
        ? sessionApiClient.patch(`${baseApiPath}/${resourceId}`, payload)
        : sessionApiClient.post(baseApiPath, payload)
    }

    uploadFiles()
      .then((fileID) => patchOrPost(fileID))
      .then((response) => {
        dispatch({ type: SET_BUSY, payload: false })
        const rid = resourceId || response.data.id
        navigate(`${group.base_path}/resources/${rid}`)
      })
      .catch((errors) => {
        const errorsPayload = errors?.errors || [
          {
            source: { parameter: "Something" },
            detail: "went wrong. Please try again.",
          },
        ]
        dispatch({ type: SET_BUSY, payload: false })
        dispatch({ type: SET_ERRORS, payload: errorsPayload })
        window.scrollTo({ top: 0, behavior: "smooth" })
      })
  }
  return (
    <FormContext.Provider value={{ state, dispatch }}>
      <form onSubmit={handleSubmit}>
        <FormErrors
          message="There was a problem submitting the form. Please review these errors and try again."
          errors={state.errors}
        />
        {children}
        <Buttons state={state} />
      </form>
    </FormContext.Provider>
  )
}
Form.Name = Name
Form.Description = Description
Form.ResourceTypeSelector = ResourceTypeSelector
Form.EditResourceType = EditResourceType
Form.Visibility = Visibility

function Name() {
  const { state, dispatch } = useContext(FormContext)

  return (
    <div>
      <label className="label" htmlFor="resource_name">
        Name
        <span className="c-ruby"> *</span>
      </label>
      <input
        type="text"
        id="resource_name"
        className="w-100%"
        required
        value={state.name}
        onChange={(e) =>
          dispatch({
            type: SET_ATTRIBUTES,
            payload: { name: e.target.value },
          })
        }
      />
    </div>
  )
}

function Description() {
  const { state, dispatch } = useContext(FormContext)

  return (
    <div className="mt-2">
      <label className="label" htmlFor="resource_description">
        Description
      </label>
      <textarea
        id="resource_description"
        className="w-100%"
        rows={4}
        value={state.description}
        onChange={(e) =>
          dispatch({
            type: SET_ATTRIBUTES,
            payload: { description: e.target.value },
          })
        }
      />
    </div>
  )
}

ResourceTypeSelector.propTypes = {
  meta: PropTypes.object.isRequired,
}
function ResourceTypeSelector({ meta }) {
  const { state, dispatch } = useContext(FormContext)

  const fileResource = state.type === FILE_RESOURCE
  const linkResource = state.type === LINK_RESOURCE

  return (
    <fieldset className="mt-2">
      <span className="label">
        Type of resource
        <span className="c-ruby"> *</span>
      </span>
      <div className="d-f ai-c">
        <input
          type="radio"
          id="resource_file_type"
          value="file"
          className="radio"
          checked={fileResource}
          onChange={() =>
            dispatch({
              type: SET_ATTRIBUTES,
              payload: { type: FILE_RESOURCE },
            })
          }
        />
        <label className="radio-label" htmlFor="resource_file_type">
          File
          {fileResource && (
            <HintText>
              <span>Choose 1 file.&nbsp;</span>
              <span>
                Maximum file size {meta.max_file_size_in_megabytes} MB.
              </span>
            </HintText>
          )}
        </label>
      </div>
      {fileResource && (
        <div className="pl-3 mt-1">
          <FileResource state={state} dispatch={dispatch} meta={meta} />
        </div>
      )}
      <div className="d-f ai-c mt-2">
        <input
          type="radio"
          id="resource_link_type"
          value="link"
          className="radio"
          checked={linkResource}
          onChange={() =>
            dispatch({
              type: SET_ATTRIBUTES,
              payload: { type: LINK_RESOURCE },
            })
          }
        />
        <label className="radio-label" htmlFor="resource_link_type">
          Link
        </label>
      </div>
      {linkResource && (
        <div className="pl-3 mt-1">
          <LinkResource state={state} dispatch={dispatch} />
        </div>
      )}
    </fieldset>
  )
}

function Visibility() {
  const { state, dispatch } = useContext(FormContext)

  const handleVisibilityChange = (e) => {
    dispatch({
      type: SET_ATTRIBUTES,
      payload: { visibility: e.target.value },
    })
  }

  return (
    <div className="mt-2">
      <Select
        id="resource_visibility"
        label="Access"
        onBlur={handleVisibilityChange}
        onChange={handleVisibilityChange}
        required={true}
        value={state.visibility}
      >
        <Select.Option label="All members" value="members" />
        <Select.Option label="Leaders only" value="leaders" />
      </Select>
    </div>
  )
}

EditResourceType.propTypes = {
  meta: PropTypes.object.isRequired,
}
function EditResourceType({ meta }) {
  const { state, dispatch } = useContext(FormContext)

  const fileResource = state.type === FILE_RESOURCE
  const linkResource = state.type === LINK_RESOURCE
  const [replaceFile, setReplaceFile] = useState(false)

  return (
    <div className="mt-1">
      {fileResource && (
        <>
          <label htmlFor="file_resource">File</label>
          {replaceFile ? (
            <FileResource state={state} dispatch={dispatch} meta={meta} />
          ) : (
            <FilePreview
              state={state}
              handleReplaceFile={() => setReplaceFile(true)}
            />
          )}
        </>
      )}
      {linkResource && (
        <>
          <label htmlFor="link_resource">Link</label>
          <LinkResource state={state} dispatch={dispatch} />
        </>
      )}
    </div>
  )
}

HintText.propTypes = {
  children: PropTypes.node.isRequired,
}
function HintText({ children }) {
  return <div className="c-tint2 fs-4">{children}</div>
}
