import * as PropTypes from "source/shared/prop_types"
import { weeklyOptions } from "../utils/frequency"
import { useEffect, useMemo } from "react"
import { Select } from "@planningcenter/doxy-web"

export const MonthlyWeekCountSelect = ({ date, value, onChange }) => {
  const setValue = onChange
  const options = useMemo(() => weeklyOptions(date, value), [date, value])

  ensureValueIsIncludedInOptions({ options, value, setValue })

  return (
    <div className="f-1 pl-1">
      <label className="label" htmlFor="event_repeating_week_count">
        On the:
      </label>
      <Select
        id="event_repeating_week_count"
        onBlur={(e) => setValue(e.target.value)}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </Select>
    </div>
  )
}
MonthlyWeekCountSelect.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

/**
 * @param {Object} Args
 * @param {{value: string, label: string}} Args.options
 * @param {string} Args.value
 * @param {(newValue: string) => void} Args.setValue
 */
const ensureValueIsIncludedInOptions = ({ options, value, setValue }) => {
  useEffect(() => {
    const valueIncludedInOptions = options.find((opt) => opt.value === value)

    if (!valueIncludedInOptions && options.length > 0) {
      setValue(options[0].value)
    }
  }, [options, value, setValue])
}
