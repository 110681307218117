import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import RegistrationSummaryList from "./RegistrationSummaryList"

const MyRegistrations = () => {
  return (
    <>
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl="/me" pageText="Me" />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="My registrations" isActive />
      </BreadcrumbNavigation>
      <div className="container container--narrow">
        <RegistrationSummaryList timeframe="upcoming" />
        <RegistrationSummaryList timeframe="ongoing" mt />
        <RegistrationSummaryList timeframe="past" mt />
      </div>
    </>
  )
}

export default MyRegistrations
