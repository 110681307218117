import { useState, useCallback } from "react"

// Adapted from https://usehooks.com/useLocalStorage
export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item
        ? JSON.parse(item)
        : initialValue instanceof Function
          ? initialValue()
          : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = useCallback(
    (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        console.log(error)
      }
    },
    [storedValue, setStoredValue],
  )

  return [storedValue, setValue]
}

export const removeLocalStorageValue = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    console.log(error)
  }
}
