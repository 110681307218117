import { datadogRum } from "@datadog/browser-rum"

export function startDatadogRealUserMonitoring(env) {
  if (window._datadog_running) return
  window._datadog_running = true

  datadogRum.init({
    applicationId: "c8c743c1-2ddc-4e1b-b4ff-953912349b4c",
    clientToken: "pubcc93308b971ba05fd1fbc313deada12c",
    site: "datadoghq.com",
    service: "church-center",
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 2,
    sessionReplaySampleRate: 50,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  })

  datadogRum.startSessionReplayRecording()
}
