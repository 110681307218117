import { shape, number, string, arrayOf, bool } from "prop-types"
import {
  queryFn,
  queryFnInfinite,
  useQuery,
  useQueryClient,
  useSuspenseInfiniteQuery,
} from "source/registrations/utils/queryCache"

export const MY_REGISTRATIONS_SUMMARY_COUNT = 5
export const MY_REGISTRATIONS_PER_PAGE = 10

const MY_REGISTRATIONS_INCLUDES = ["event", "responsible_person"]

const MY_REGISTRATIONS_FIELDS = {
  Event: ["ends_at", "event_time", "logo_url", "name", "starts_at"],
  Person: ["name"],
  Registration: [
    "completion_detail",
    "completion_status",
    "event",
    "participant_names",
    "waitlisted_names",
    "responsible_person",
    "total_due_string",
  ],
}

export const myRegistrationEventShape = shape({
  endsAt: string,
  eventTime: string,
  id: number.isRequired,
  logoUrl: string,
  name: string.isRequired,
  startsAt: string,
})

export const myRegistrationShape = shape({
  id: number.isRequired,
  event: myRegistrationEventShape,
  responsiblePerson: shape({
    name: string,
  }),
  completionDetail: shape({
    hasMissingPersonalInfo: bool.isRequired,
    hasMissingAnswersToAttendeeQuestions: bool.isRequired,
    hasMissingAnswersToRegistrantQuestions: bool.isRequired,
    hasMissingForms: bool.isRequired,
    hasMissingSelectionsForRequiredAddOns: bool.isRequired,
    hasBalanceDue: bool.isRequired,
    hasRefundDue: bool.isRequired,
  }).isRequired,
  completionStatus: string.isRequired,
  participantNames: arrayOf(string).isRequired,
  waitlistedNames: arrayOf(string).isRequired,
  totalDueString: string,
})

export const getConfirmationLink = (registration) => {
  return `/registrations/registrations/${registration.id}/confirmation`
}

const getQueryOptions = ({ timeframe, per_page }) => ({
  queryKey: getQueryKey({ timeframe, per_page }),
  queryFn: (args) => queryFn({ ...args, includeMeta: true }),
})

const getQueryKey = ({ timeframe, per_page }) => [
  "/registrations/v2/registrations",
  {
    include: MY_REGISTRATIONS_INCLUDES,
    fields: MY_REGISTRATIONS_FIELDS,
    where: {
      timeframe,
    },
    per_page,
  },
]

export const useMyRegistrations = ({ timeframe }) => {
  const { data, ...rest } = useQuery(
    getQueryOptions({ timeframe, per_page: MY_REGISTRATIONS_SUMMARY_COUNT }),
  )

  return {
    data: data?.data,
    ...rest,
  }
}

export const useInfiniteMyRegistrations = ({ timeframe }) => {
  return useSuspenseInfiniteQuery({
    queryKey: getQueryKey({ timeframe, per_page: MY_REGISTRATIONS_PER_PAGE }),
    queryFn: queryFnInfinite,
    getNextPageParam: (lastPage) => lastPage.meta?.next?.offset,
    initialPageParam: 0,
  })
}

export const useMyRegistrationsTotalCount = ({ timeframe }) => {
  const { data } = useQuery(
    getQueryOptions({ timeframe, per_page: MY_REGISTRATIONS_SUMMARY_COUNT }),
  )

  return data?.meta?.totalCount || 0
}

export const useUncacheMyRegistrations = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.removeQueries({
      queryKey: ["/registrations/v2/registrations"],
    })
  }
}
