import moment from "moment"

/**
 * This function updates the date portion of `datetime` to match `newDate`
 * while leaving the time potion as-is.
 *
 * @param {string} datetime ISO formatted date/time string
 * @param {string} newDate ISO formatted date/date string
 * @returns string
 */
export function updateDateInISODatetime(datetime, newDate) {
  const date = moment(newDate)
  return moment(datetime)
    .set({
      year: date.year(),
      month: date.month(),
      date: date.date(),
    })
    .toISOString(true)
}

/**
 * Returns the number of days in the month of the given date
 *
 * @param {Date} date
 * @returns number
 */
export function daysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}
