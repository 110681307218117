import { noop } from "lodash"
import { Link } from "react-router-dom"
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuLink,
} from "@reach/menu-button"
import { bool, func } from "prop-types"
import { Icon } from "source/shared/components"

ServicesOptionsDropdown.propTypes = {
  handleAcceptAllScheduleRequests: func,
  hasServicesAbilities: bool,
  showAcceptAllSchedules: bool,
}

export default function ServicesOptionsDropdown({
  handleAcceptAllScheduleRequests = noop,
  hasServicesAbilities,
  showAcceptAllSchedules = false,
}) {
  return (
    hasServicesAbilities && (
      <Menu>
        <MenuButton className="minor-btn secondary-btn btn">
          Actions
          <span className="fs-6 pl-4p dropdown-trigger__icon">
            <Icon symbol="general#down-chevron" aria-hidden />
          </span>
        </MenuButton>
        <MenuList>
          {showAcceptAllSchedules && (
            <MenuItem onSelect={handleAcceptAllScheduleRequests}>
              Accept all scheduling requests
            </MenuItem>
          )}
          <MenuLink
            as={Link}
            to={"/services/blockouts/new"}
            state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
          >
            Add blockout
          </MenuLink>
          <MenuLink
            as={Link}
            to={"/services/email_team_leaders"}
            state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
          >
            Email team leaders
          </MenuLink>
        </MenuList>
      </Menu>
    )
  )
}
