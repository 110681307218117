import moment from "moment"
import { Icon } from "source/shared/components"
import { string, shape } from "prop-types"
import { buildUrl } from "@planningcenter/cc-url"

const NoLogoPlaceholder = ({ event, name }) => {
  const startsAt = moment(event.attributes.starts_at)
  const endsAt = moment(event.attributes.ends_at)

  const sameDayEvent = endsAt.diff(startsAt, "days") === 0

  return (
    <div className="event-placeholder">
      {startsAt.isValid() ? (
        <span className="event-placeholder__dates">
          {sameDayEvent ? (
            <div>
              <div className="event-placeholder-month">
                {startsAt.format("MMM").toUpperCase()}
              </div>
              <div className="event-placeholder-day">
                {startsAt.format("D")}
              </div>
            </div>
          ) : (
            <div className="d-f ai-c jc-c">
              <div>
                <div className="event-placeholder-month">
                  {startsAt.format("MMM").toUpperCase()}
                </div>
                <div className="event-placeholder-day">
                  {startsAt.format("D")}
                </div>
              </div>
              <div className="event-placeholder-arrow">
                <Icon
                  symbol="general#right-arrow"
                  aria-label="arrow icon pointing from start date to end date"
                />
              </div>
              <div>
                <div className="event-placeholder-month">
                  {endsAt.format("MMM").toUpperCase()}
                </div>
                <div className="event-placeholder-day">
                  {endsAt.format("D")}
                </div>
              </div>
            </div>
          )}
        </span>
      ) : (
        <div className="d-f ai-c jc-c p-4 ta-c p-a">{name}</div>
      )}
      <img
        src={buildUrl("/static/no-event-image.png")}
        alt="event logo with dates"
      />
    </div>
  )
}

NoLogoPlaceholder.propTypes = {
  event: shape({
    attributes: shape({
      starts_at: string.isRequired,
      ends_at: string.isRequired,
    }),
  }).isRequired,
  name: string,
}

export default NoLogoPlaceholder
