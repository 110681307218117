import { stringToCase } from "source/registrations/utils/helpers"

export const formatEmergencyContact = (name = "", phoneNumber = "") => {
  return {
    id: stringToCase(`${name}_${phoneNumber}`),
    name,
    phoneNumber,
    lockName: !!name,
    lockPhoneNumber: !!phoneNumber,
  }
}
