import { useContext } from "react"
import PropTypes from "prop-types"
import { WebBootContext } from "source/publishing/WebBoot"
import { Helmet } from "react-helmet-async"

OpenGraphTags.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
}
export default function OpenGraphTags({ title, imageUrl, description }) {
  const {
    currentOrganization: {
      attributes: { name: organizationName, avatar_url: organizationAvatarUrl },
    },
  } = useContext(WebBootContext)
  const openGraphTitle = title
    ? `${title} - ${organizationName}`
    : organizationName
  const openGraphDescription =
    description ||
    (title
      ? `${organizationName}'s ${title} on Church Center.`
      : `${organizationName} on Church Center.`)
  const openGraphImage = imageUrl || organizationAvatarUrl

  return (
    <Helmet title={title}>
      {/* Primary Meta Tags */}
      <meta name="description" content={openGraphDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={openGraphTitle} />
      <meta property="og:description" content={openGraphDescription} />
      <meta property="og:image" content={openGraphImage} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={openGraphTitle} />
      <meta property="twitter:description" content={openGraphDescription} />
      <meta property="twitter:image" content={openGraphImage} />
    </Helmet>
  )
}
