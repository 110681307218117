import { shape, string, number, object, func, oneOfType } from "prop-types"
import { Select } from "source/shared/components"
import Field from "./Field"

const GradeField = ({ attendee, value, onChange, error, ...rest }) => {
  const {
    id: attendeeId,
    attendeeType: { gradeLabel = "Grade", gradeOptions },
  } = attendee

  const options = [
    { label: `Select ${gradeLabel.toLowerCase()}`, value: "" },
    ...Object.keys(gradeOptions).map((key) => ({
      key: key,
      label: key,
      value: gradeOptions[key],
    })),
  ]

  const gradeRender = (grade) => {
    if (grade || grade == 0) {
      return Object.fromEntries(
        Object.entries(gradeOptions).map(([key, value]) => [value, key]),
      )[grade]
    }
  }

  const handleGradeChange = (value) => {
    value == "" ? onChange(null) : onChange(value)
  }

  return (
    <Field
      label={gradeLabel}
      value={gradeRender(value)}
      error={error}
      {...rest}
    >
      <Select
        name={`grade-${attendeeId}`}
        onChange={({ target: { value } }) => handleGradeChange(value)}
        value={value == 0 || value ? value : ""}
        style={{ height: "32px" }}
        options={options}
      />
    </Field>
  )
}

GradeField.propTypes = {
  value: oneOfType([string, number]),
  attendee: shape({
    id: number,
    attendeeType: shape({
      gradeLabel: string,
      gradeOptions: object,
    }),
  }),
  onChange: func,
  error: string,
}

export default GradeField
