import { useEffect, useContext } from "react"
import { LoginForm } from "source/login/LoginForm"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { HardRefresh } from "source/shared/routingUtils"
import { resetCache as resetNavitarCache } from "@planningcenter/cc-nav"
import { resetCache as resetWebBootCache } from "source/publishing/WebBoot"

export function Login() {
  const { login } = useContext(ServerRenderedProps)
  return <LoginForm emailOnly={login.email_only} />
}

export function Logout() {
  useEffect(() => {
    resetNavitarCache()
    resetWebBootCache()
  }, [])
  return <HardRefresh />
}
