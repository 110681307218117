import { useState } from "react"
import { arrayOf, string, shape, node, oneOf, bool } from "prop-types"
import { css } from "@emotion/react"
import classNames from "classnames"

Accordion.propTypes = {
  panels: arrayOf(
    shape({
      name: string.isRequired,
      heading: node.isRequired,
      content: node.isRequired,
    }),
  ).isRequired,
  listIcon: oneOf(["none", "arrow", "radio"]),
  exclusiveOpen: bool,
  className: string,
}

export function Accordion({
  panels,
  listIcon = "radio",
  exclusiveOpen = true,
  className = "",
}) {
  const [currentPanel, setCurrentPanel] = useState(panels[0].name)

  const handlePanelToggle = (panelName) => {
    if (panels.length === 1) return
    if (currentPanel === panelName) {
      setCurrentPanel(null)
      return
    }
    setCurrentPanel(panelName)
  }

  return (
    <div css={accordionStyles}>
      <div className={classNames("accordion", className)}>
        {panels.map((panel, index) => (
          <div
            key={index}
            className={classNames("accordion--panel", {
              open: exclusiveOpen && currentPanel === panel.name,
              hasIcon: listIcon !== "none",
            })}
          >
            <div
              role="button"
              className={classNames("accordion--titleContainer", {
                inert: panels.length === 1,
              })}
              tabIndex={0}
              onKeyDown={(e) =>
                e.key === "Enter" && handlePanelToggle(panel.name)
              }
              onClick={() => handlePanelToggle(panel.name)}
            >
              {panels.length > 1 && (
                <div className={classNames("accordion--listIcon", listIcon)}>
                  <div></div>
                </div>
              )}
              <div className="accordion--title">{panel.heading}</div>
            </div>
            <div role="definition" className="accordion--contentDrawer">
              <div className="accordion--content">{panel.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const accordionStyles = css`
  .accordion {
    box-shadow: inset 0 1px 2px var(--color-tint9);
    border: 1px solid var(--color-tint5);
    border-radius: 4px;
  }

  .accordion--panel {
    border-bottom: 1px solid var(--color-tint5);
    overflow: hidden;
  }

  .accordion--panel:last-child {
    border-bottom: none;
  }

  .accordion--titleContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 1rem;
    color: var(--color-tint2);
    transition: color 200ms ease-in;
  }

  .accordion--titleContainer.inert {
    cursor: default;
  }

  .accordion--titleContainer:hover {
    color: var(--color-tint1);
    transition: color 200ms ease-in;
  }

  .accordion--panel.open .accordion--titleContainer {
    color: var(--color-topaz);
    transition: color 200ms ease-in;
  }

  .accordion--listIcon.none {
    display: none;
  }

  .accordion--listIcon.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid var(--color-tint2);
    transition:
      border-color 200ms ease-in,
      background-color 200ms ease-in;
  }

  .accordion--listIcon.radio div {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 200ms ease-in;
  }

  .accordion--titleContainer:hover .accordion--listIcon.radio {
    border-color: var(--color-tint1);
    transition: border-color 200ms ease-in;
  }

  .accordion--panel.open .accordion--listIcon.radio {
    border-color: var(--color-topaz);
    background-color: var(--color-topaz);
    transition:
      border-color 200ms ease-in,
      background-color 200ms ease-in;
  }

  .accordion--panel.open .accordion--listIcon.radio div {
    background-color: var(--color-tint10);
    transition: background-color 200ms ease-in;
  }

  .accordion--title {
    font-weight: 600;
    line-height: 2rem;
  }

  .accordion--contentDrawer {
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms ease-in;
  }

  .accordion--panel.open .accordion--contentDrawer {
    max-height: 300px;
    transition: max-height 400ms ease-in;
  }

  .accordion--content {
    padding: 0 1rem 1rem 1rem;
  }
`
