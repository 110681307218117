import { useCallback } from "react"
import { useBeforeUnload } from "react-router-dom"

const useUnsavedChangesAlert = (isDirty = false, _message) => {
  useBeforeUnload(
    useCallback(
      (event) => {
        if (isDirty) {
          event.preventDefault()
          event.returnValue = ""
        }
      },
      [isDirty],
    ),
    { capture: true },
  )
}

export default useUnsavedChangesAlert
