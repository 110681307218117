import moment from "moment-timezone"

export const FIRST_MINUTE_OF_DAY = 0
export const LAST_MINUTE_OF_DAY = 1439

export class Blockout {
  constructor({
    id,
    isNew,
    isOwner,
    group_identifier,
    reason,
    repeat_frequency,
    repeat_interval,
    repeat_period,
    repeat_until,
    share,
    timeZone,
    starts_at,
    ends_at,
    organization_name,
    personId,
  }) {
    this.id = id
    this.starts_at = starts_at
    this.ends_at = ends_at
    this.reason = reason
    this.isNew = isNew
    this.isOwner = isOwner
    this.group_identifier = group_identifier
    this.frequency = repeat_frequency
    this.interval = repeat_interval
    this.period = repeat_period
    this.until = this.buildUntilDate(repeat_until)
    this.share = share
    this.timeZone = timeZone
    this.organization_name = organization_name
    this.personId = personId
  }

  get startsAt() {
    return moment(this.starts_at).tz(this.timeZone)
  }

  get endsAt() {
    return moment(this.ends_at).tz(this.timeZone)
  }

  get recurrence() {
    return {
      frequency: this.frequency,
      interval: this.interval,
      period: this.period,
      until: this.until,
    }
  }

  get startsAtDate() {
    return toLocalDate(this.startsAt)
  }

  get endsAtDate() {
    return toLocalDate(this.endsAt)
  }

  get startsAtMinuteOfDay() {
    return toLocalMinuteOfDay(this.startsAt)
  }

  get endsAtMinuteOfDay() {
    return toLocalMinuteOfDay(this.endsAt)
  }

  get isAllDay() {
    return (
      this.startsAtMinuteOfDay === FIRST_MINUTE_OF_DAY &&
      this.endsAtMinuteOfDay === LAST_MINUTE_OF_DAY
    )
  }

  get isRecurring() {
    return this.frequency !== "no_repeat"
  }

  get hasLinkedAccounts() {
    return this._hasLinkedAccounts
  }

  set hasLinkedAccounts(val) {
    this._hasLinkedAccounts = val
  }

  get canShareWithLinkedAccounts() {
    return this.isNew && this.isOwner && this.hasLinkedAccounts
  }

  get manageableHouseholdMemberIds() {
    return this._manageableHouseholdMemberIds
  }

  set manageableHouseholdMemberIds(ids) {
    this._manageableHouseholdMemberIds = ids
  }

  get isSharedWithHouseholdMembers() {
    return this._isSharedWithHouseholdMembers
  }

  set isSharedWithHouseholdMembers(val) {
    this._isSharedWithHouseholdMembers = val
  }

  get canApplyToHousehold() {
    // You cannot apply blockouts shared from a linked account to a household
    if (!this.isOwner) return false

    return this.isNew
      ? !!this.manageableHouseholdMemberIds.length
      : this.isSharedWithHouseholdMembers
  }

  buildUntilDate(repeat_until) {
    return repeat_until ? toLocalDate(moment.utc(repeat_until)) : repeat_until
  }
}

// native Date for a given moment, in the moment's timezone (rather than the browser's)
function toLocalDate(aMoment) {
  const date = new Date()
  date.setYear(aMoment.year())
  date.setMonth(aMoment.month())
  date.setDate(aMoment.date())
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)

  return date
}

function toLocalMinuteOfDay(aMoment) {
  const midnight = aMoment.clone().startOf("day")
  return aMoment.diff(midnight, "minutes")
}
