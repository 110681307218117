import PropTypes from "prop-types"
import { Icon } from "source/shared/components"

export default function RsvpIcon({ iconColor, iconSymbol }) {
  const color = `c-${iconColor}`

  return (
    <span className={`${color} p-r t-1p mr-4p fs-5`}>
      <Icon symbol={iconSymbol} />
    </span>
  )
}
RsvpIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
  iconSymbol: PropTypes.string.isRequired,
}
