import { bool, func, node } from "prop-types"
import { AlertMessage } from "source/shared/components"
import { Button } from "@planningcenter/doxy-web"

const SectionFooter = ({
  onSave,
  onCancel,
  isCancelShowing = false,
  disabled = true,
  error = null,
  isProcessing,
}) => {
  return (
    <div className="my-2">
      {error && (
        <AlertMessage>
          <div style={{ color: "var(--color-ruby)" }}>{error}</div>
        </AlertMessage>
      )}

      <Button
        text="Next"
        onClick={onSave}
        disabled={disabled}
        isLoading={isProcessing}
      />

      {isCancelShowing && (
        <button className="text-btn ml-2" onClick={onCancel}>
          Cancel
        </button>
      )}
    </div>
  )
}

SectionFooter.propTypes = {
  onSave: func,
  onCancel: func,
  isCancelShowing: bool,
  disabled: bool,
  error: node,
  isProcessing: bool,
}

export default SectionFooter
