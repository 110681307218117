import { node, shape, string } from "prop-types"

SectionHeading.propTypes = {
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
    }),
  }),
}

export default function SectionHeading({ field }) {
  const {
    attributes: { description, label },
  } = field

  return (
    <div className="section-header pt-4 pb-1">
      <h2 className="h2">{label}</h2>
      {description && (
        <span className="fs-4 c-tint0" style={{ whiteSpace: "pre-line" }}>
          {description}
        </span>
      )}
    </div>
  )
}
