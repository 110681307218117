import type { ResourceFieldValue } from "../types"
import ResourceQuantity from "./ResourceQuantity"

type ResourceOptionRadioProps = {
  handleChangeSelected: (e: React.ChangeEvent<HTMLInputElement>) => void
  label: string
  maxQuantity: number
  onQuantityChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  optionId: string
  selected: ResourceFieldValue
}

export default function ResourceOptionRadio({
  handleChangeSelected,
  label,
  maxQuantity,
  onQuantityChange,
  optionId,
  selected,
}: ResourceOptionRadioProps) {
  const isTheSelectedOption = selected.optionId === optionId
  const quantity = selected.quantity

  return (
    <div>
      <input
        type="radio"
        id={`resource-${optionId}`}
        className="radio"
        checked={isTheSelectedOption}
        onChange={handleChangeSelected}
        value={optionId}
      />
      <label
        className="radio-label"
        css={{ cursor: "pointer", fontWeight: 400 }}
        htmlFor={`resource-${optionId}`}
      >
        {label}
      </label>
      {isTheSelectedOption && (
        <div className="m-1 ml-3">
          <ResourceQuantity
            id={optionId}
            onChange={onQuantityChange}
            {...{ maxQuantity, quantity }}
          />
        </div>
      )}
    </div>
  )
}
