import { useState } from "react"

import type { FormField, ResourceFieldValue } from "../types"
import ResourceOptionRadio from "./ResourceOptionRadio"

type ResourceRadioFieldProps = {
  onChange: (value: ResourceFieldValue, id: string) => void
  id: FormField["id"]
  options: FormField["options"]
}

export default function ResourceRadioField({
  onChange,
  id,
  options,
}: ResourceRadioFieldProps) {
  const [selected, setSelected] = useState<ResourceFieldValue>({
    optionId: "",
    quantity: "",
  })

  const handleChangeSelected = ({
    target: { value },
  }: {
    target: { value: string }
  }) => {
    const newSelected = { optionId: value, quantity: "1" }

    updateState(newSelected)
  }

  const handleQuantityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const newSelected = { ...selected, quantity: value }

    updateState(newSelected)
  }

  const updateState = (newSelected: ResourceFieldValue) => {
    setSelected(newSelected)
    onChange(newSelected, id)
  }

  return options.map(
    ({
      attributes: { label },
      id: optionId,
      optionable: {
        attributes: { quantity: maxQuantity },
      },
    }) => (
      <ResourceOptionRadio
        key={optionId}
        onQuantityChange={handleQuantityChange}
        {...{ handleChangeSelected, label, maxQuantity, optionId, selected }}
      />
    ),
  )
}
