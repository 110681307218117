import { useApiRead } from "source/shared/SessionApiResource"

const AVAILABLE_SIGNUP_FIELDS = ["service_type_name"].join(",")

export default function useAvailableSignup({ availableSignupId }) {
  const { data } = useApiRead(
    `/services/v2/me/available_signups/${availableSignupId}?fields[AvailableSignup]=${AVAILABLE_SIGNUP_FIELDS}`,
  )

  return data ? { ...data.attributes, id: data.id } : {}
}
