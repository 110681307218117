import { useContext, useEffect, useMemo } from "react"
import { shape, string } from "prop-types"
import { RepliesDataContext } from "./RepliesDataContext"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context.jsx"
import {
  SessionApiResource,
  useApiRead,
} from "source/shared/SessionApiResource"

RepliesInitialDataLoader.propTypes = {
  repliesFilter: shape({ queryParamFilter: string }),
}
export default function RepliesInitialDataLoader({
  repliesFilter = { queryParamFilter: "all_people" },
}) {
  let {
    ingest,
    setLeaders,
    injected: { group, topic },
  } = useContext(RepliesDataContext)

  let [replies, invalidateCachedReplies] = (function useReplies() {
    const path = `/groups/v2/me/groups/${group.id}/forum_topics/${topic.id}/replies?filter=${repliesFilter.queryParamFilter}&include=author,my_reaction&per_page=50`
    return [useApiRead(path), useCacheHydrate(SessionApiResource, path, {})]
  })()

  let leaders = (function useLeaders() {
    let l = useApiRead(
      `/groups/v2/me/groups/${group.id}/memberships?filter=phantom&where[role]=leader`,
    )
    return useMemo(
      () => l.data.map((membership) => membership.relationships.person.data.id),
      [l],
    )
  })()

  useEffect(() => {
    ingest(replies)
    setLeaders(leaders)
  }, [ingest, replies, setLeaders, leaders])

  useEffect(() => () => invalidateCachedReplies(), [invalidateCachedReplies])

  return null
}
