import { AlertMessage, Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"
import { Link } from "react-router-dom"

const GuestConfirmation = () => {
  return (
    <div className="f-1 container container--narrow">
      <section>
        <AlertMessage type="success" className="pt-3 pb-2 mb-3">
          <div className="ta-c">
            <Icon
              symbol="general#check"
              style={{ color: "var(--color-emerald)" }}
              className="fs-1"
              aria-hidden
            />
            <div className="pt-4p mb-1">
              <Heading level={1} size={4} text="Thanks for registering!" />
            </div>
            <p>
              A confirmation has been sent to the email address provided. <br />
              You can also view registration details from your{" "}
              <Link to="/me/profile">profile</Link>.
            </p>
          </div>
        </AlertMessage>
      </section>
    </div>
  )
}

export default GuestConfirmation
