import { createContext, useContext } from "react"
import { node, string } from "prop-types"
import { Avatar as SharedAvatar } from "source/shared/components"
import { Badge, Heading } from "@planningcenter/doxy-web"
import { Date } from "source/shared/DatetimeFmt"

const PersonInfoContext = createContext()

PersonInfo.propTypes = {
  children: node.isRequired,
  emailAddress: string,
  joinedAt: string,
  name: string.isRequired,
  phoneNumber: string,
  role: string,
}
export default function PersonInfo({
  children,
  emailAddress,
  joinedAt,
  name,
  phoneNumber,
  role,
}) {
  return (
    <PersonInfoContext.Provider
      value={{ emailAddress, joinedAt, name, phoneNumber, role }}
    >
      {children}
    </PersonInfoContext.Provider>
  )
}

HeaderRow.propTypes = {
  children: node.isRequired,
}
function HeaderRow({ children }) {
  return <section className="d-f g-2">{children}</section>
}
PersonInfo.HeaderRow = HeaderRow

HeaderColumn.propTypes = {
  children: node.isRequired,
}
function HeaderColumn({ children }) {
  return <div>{children}</div>
}
PersonInfo.HeaderColumn = HeaderColumn

Avatar.propTypes = {
  url: string.isRequired,
}
function Avatar({ url }) {
  return (
    <div className="w-8 h-8">
      <SharedAvatar url={url} />
    </div>
  )
}
PersonInfo.Avatar = Avatar

function Name() {
  const { name } = useContext(PersonInfoContext)
  return <Heading level={1} size={2} text={name} />
}
PersonInfo.Name = Name

Subtitle.propTypes = {
  children: node.isRequired,
}
function Subtitle({ children }) {
  return <p className="m-0 d-f g-1 fw-w">{children}</p>
}
PersonInfo.Subtitle = Subtitle

function JoinedAt() {
  const { joinedAt } = useContext(PersonInfoContext)

  if (!joinedAt) return null

  return (
    <span>
      Joined <Date start={joinedAt} style="standard" year={true} />
    </span>
  )
}
PersonInfo.JoinedAt = JoinedAt

function Leader() {
  const { role } = useContext(PersonInfoContext)

  if (role !== "leader") return null

  return <Badge text="Leader" />
}
PersonInfo.Leader = Leader

Contact.propTypes = {
  children: node.isRequired,
}
function Contact({ children }) {
  const { emailAddress, phoneNumber, name } = useContext(PersonInfoContext)

  const showContactInfo = Boolean(emailAddress || phoneNumber)

  return (
    <section className="d-f fd-c g-1">
      <Heading level={2} size={3} text="Contact" />
      <div className="action-drawer d-f fd-c g-1 ta-c ta-l@sm">
        {showContactInfo ? (
          children
        ) : (
          <p className="fs-4 m-0 c-tint2">
            There is currently no contact information for {name}.
          </p>
        )}
      </div>
    </section>
  )
}
PersonInfo.Contact = Contact

function EmailAddress() {
  const { emailAddress } = useContext(PersonInfoContext)

  const hasEmail = Boolean(emailAddress)

  if (!hasEmail) return null

  return (
    <ContactLink href={`mailto:${emailAddress}`}>{emailAddress}</ContactLink>
  )
}
PersonInfo.EmailAddress = EmailAddress

function PhoneNumber() {
  const { phoneNumber } = useContext(PersonInfoContext)

  const hasPhoneNumber = Boolean(phoneNumber)

  if (!hasPhoneNumber) return null

  return <ContactLink href={`tel:${phoneNumber}`}>{phoneNumber}</ContactLink>
}
PersonInfo.PhoneNumber = PhoneNumber

ContactLink.propTypes = {
  href: string,
  children: node,
}
function ContactLink({ href, children }) {
  return (
    <a href={href} className="c-tint2 d-b w-fc@sm">
      {children}
    </a>
  )
}
