import { reservationStepPropTypes } from "source/registrations/propTypes"
import { AlertMessage } from "source/shared/components"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import AttendeesBreakdown from "./AttendeesBreakdown"

const ReviewSummary = ({
  reservation,
  attendees,
  addOns,
  isProcessing,
  isAddOnsEnabled,
  onReservationSave,
  onIsProcessing,
  className,
  step,
  error,
}) => {
  const handleSave = async () => {
    onIsProcessing(true)
    await onReservationSave({
      data: {
        attributes: {
          send_confirmation: true,
        },
      },
      included: [{ type: "Payment", attributes: {} }],
    })
    onIsProcessing(false)
  }

  return (
    <div>
      <SectionLayout
        heading="Review"
        className={className}
        step={step}
        error={error}
        expiresAt={reservation.expiresAt}
        isProcessing={isProcessing}
      >
        {step.isCurrent && (
          <>
            <AttendeesBreakdown
              attendees={attendees}
              addOns={addOns}
              isAddOnsEnabled={isAddOnsEnabled}
            />

            <AttendeesBreakdown attendees={attendees} forWaitlist />

            {error && (
              <AlertMessage className="mt-2">
                <div style={{ color: "var(--color-ruby)" }}>{error}</div>
              </AlertMessage>
            )}
          </>
        )}
      </SectionLayout>

      {step.isCurrent && (
        <div className="ta-c mt-4">
          <button className="btn" disabled={isProcessing} onClick={handleSave}>
            {isProcessing ? "Saving..." : "Register"}
          </button>
        </div>
      )}
    </div>
  )
}

ReviewSummary.propTypes = reservationStepPropTypes

export default ReviewSummary
