import linkifyStr from "linkifyjs/string"

export function linkify(string) {
  return linkifyStr(string)
    .split("\n")
    .map((text) => `<p>${text.trim().length == 0 ? "&nbsp;" : text}</p>`)
    .join("\n")
}

export function unlinkify(string) {
  return string
    .replace(/<a.*?>/g, "")
    .replace(/<\/a>/g, "")
    .replace(/<p.*?>/g, "")
    .replace(/<\/p>/g, "")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&nbsp;/g, "")
}
