import { useState, useEffect } from "react"

const MOBILE_UA_STRINGS = [
  "Android",
  "BlackBerry",
  "IEMobile",
  "Opera Mini",
  "WPDesktop",
  "iPad",
  "iPhone",
  "iPod",
  "webOS",
]

export function useMobileDetect() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mobileDetected = MOBILE_UA_STRINGS.some((ua) =>
      navigator.userAgent.includes(ua),
    )
    setIsMobile(mobileDetected)
  }, [])

  return { isMobile }
}
