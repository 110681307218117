import { useContext } from "react"
import { Routes, Route } from "react-router-dom"
import CalendarEvent from "./event/Event"
import CalendarForm from "./form/Form"
import CalendarList from "./list/List"
import { CalendarDataProvider } from "./CalendarDataContext"
import { WebBootContext } from "source/publishing/WebBoot"
import { Error404 } from "source/shared/components/Error404"

export function Calendar() {
  const { builtInPages } = useContext(WebBootContext)
  const isPublished = builtInPages["calendar"] === "published"

  return (
    <CalendarDataProvider>
      <Routes>
        {isPublished && (
          <>
            <Route path="/" element={<CalendarList />} />
            <Route path="/event/:eventId" element={<CalendarEvent />} />
          </>
        )}
        <Route path="/forms/:formId" element={<CalendarForm />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </CalendarDataProvider>
  )
}
