import { useState, useCallback, useContext } from "react"
import { id } from "source/shared/prop_types"
import { Loading } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { RepliesDataContext } from "./RepliesDataContext"
import { colors } from "source/shared/colors"

export default function LoadMore({ groupId, topicId }) {
  const [loading, setLoading] = useState(false)
  const { replies, ingest, hasMore } = useContext(RepliesDataContext)

  const loadMore = useCallback(() => {
    setLoading(true)
    let url = `/groups/v2/me/groups/${groupId}/forum_topics/${topicId}/replies?include=author,my_reaction&per_page=25&offset=${
      replies.length
    }`
    sessionApiClient
      .get(url)
      .then((json) => ingest(json))
      .finally(() => setLoading(false))
  }, [replies, groupId, topicId])

  if (!hasMore) return null
  if (loading) return <Loading />
  return (
    <button
      className="btn minor-compact-btn"
      onClick={loadMore}
      style={{
        background: "none",
        padding: "8px",
        color: `${colors.brand}`,
      }}
    >
      View older replies...
    </button>
  )
}

LoadMore.propTypes = {
  groupId: id.isRequired,
  topicId: id.isRequired,
}
