import { api } from "source/registrations/api/client"

export const postPaymentIntent = (eventId, reservationUuid, options = {}) => {
  const path = `/registrations/v2/events/${eventId}/reservations/${reservationUuid}/payment_intents`
  return api.post(path, options)
}

export const patchPaymentIntent = (eventId, reservationUuid, options = {}) => {
  const path = `/registrations/v2/events/${eventId}/reservations/${reservationUuid}/payment_intent`
  return api.patch(path, options)
}
