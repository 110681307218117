import _ from "lodash"
import { useEffect, useState } from "react"
import {
  time as formatTime,
  dates as formatDates,
} from "@churchcenter/datetime-fmt"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Link } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import { array, bool, func, number, string } from "prop-types"
import { Icon } from "source/shared/components"
import spinner from "source/svg/spinner.svg"

ScheduleRequest.propTypes = {
  acceptingAll: func,
  schedules: array,
  plan_id: number,
  refreshSchedule: func,
  service_type_id: number,
  service_type_name: string,
  title_dates: array, // of iso8601
  time_zone: string,
}

PendingSchedule.propTypes = {
  display_name: string,
  service_times: array,
  is_split_team: bool,
  timeZone: string,
}

export default function ScheduleRequest({
  acceptingAll,
  schedules,
  plan_id,
  refreshSchedule,
  service_type_id,
  service_type_name,
  time_zone,
  title_dates,
}) {
  const [inFlight, setInFlight] = useState(false)

  useEffect(() => {
    setInFlight(acceptingAll)
  }, [acceptingAll])

  const planDetailsPath = `/services/service_types/${service_type_id}/plans/${plan_id}`

  const inCurrentYear =
    new Date().getFullYear() ===
    new Date(title_dates[title_dates.length - 1]).getFullYear()

  const formattedTimes = formatDates(title_dates, {
    style: inCurrentYear ? "abbreviated" : "standard",
    year: !inCurrentYear,
  })

  const handleAccept = () => {
    setInFlight(true)
    Promise.all(
      schedules.map((schedule) =>
        sessionApiClient.post(
          `/services/v2/me/unscoped_schedules/${schedule.id}/accept`,
          {},
        ),
      ),
    )
      .then(refreshSchedule)
      .catch(() => {
        setInFlight(false)
        alert("Oops, something went wrong. Please try again.")
      })
  }

  return (
    <div className="action-drawer d-f fd-c">
      <div className="f-1">
        <Link
          to={planDetailsPath}
          state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
        >
          <div className="mb-1">
            <Heading level={4} color="brand" text={formattedTimes} />
          </div>
        </Link>
        <div>{service_type_name}</div>
        {schedules.map((schedule) => (
          <PendingSchedule
            key={schedule.id}
            timeZone={time_zone}
            {...schedule}
          />
        ))}
      </div>

      <div className="action-drawer__footer d-f fd-r jc-fe">
        <div className="pr-1">
          <Link
            to={planDetailsPath}
            state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
            className="minor-compact-btn btn"
          >
            More&hellip;
          </Link>
        </div>
        <button className="btn secondary-btn minor-btn" onClick={handleAccept}>
          {inFlight ? (
            <img
              src={spinner}
              alt="Saving"
              style={{ height: "1em", width: "1em" }}
            />
          ) : (
            <div>Accept</div>
          )}
        </button>
      </div>
    </div>
  )
}

function PendingSchedule({
  display_name,
  service_times,
  is_split_team,
  timeZone,
}) {
  const PENDING_ORANGE = "var(--color-citrine)"

  const times = is_split_team
    ? _(service_times)
        .map((d) => formatTime(d, { timeZone }))
        .uniq()
        .value()
    : []

  return (
    <div className="d-f ai-fs mt-2">
      <Icon
        symbol="general#question-circle"
        aria-label="Pending service request icon"
        className="mr-4p p-r t-4p"
        css={{ fill: PENDING_ORANGE }}
      />
      <div>
        <div>{display_name}</div>
        <div className="fs-4 c-tint2">{times.join(", ")}</div>
      </div>
    </div>
  )
}
