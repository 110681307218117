export function buildUrl(url, params) {
  return `${url}?${buildQuery(params)}`
}

function buildQuery(queryObj, nesting = "") {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    // Handle the nested, recursive case, where the value to encode is an object itself
    if (typeof val === "object") {
      return buildQuery(val, nesting + `${key}`)
    } else {
      // Handle base case, where the value to encode is simply a string.
      const nestedKey = nesting ? `${nesting}[${key}]` : key
      return [nestedKey, escape(val)].join("=")
    }
  })
  return pairs.join("&")
}
