import { createContext, useEffect, useState } from "react"
import { node } from "prop-types"
import { useDebounce } from "use-debounce"
import { omit, uniq } from "lodash"
import {
  useTranslatedQueryParamsState,
  OPEN_GROUPS_ENROLLMENT_STATUS,
  ALL_GROUPS_ENROLLMENT_STATUS,
} from "../hooks/useTranslatedQueryParamsState"
import { useGroupType } from "../hooks/useGroupType"
export const FiltersContext = createContext()

export function FiltersContextProvider({ children }) {
  const { data: groupType } = useGroupType()
  const closedAndFullGroupsVisible =
    groupType.attributes.closed_and_full_groups_visible
  const [params, updateParams] = useTranslatedQueryParamsState()
  const [searchTerm, setSearchTerm] = useState(params["where[name]"] || "")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)
  const [tagIds, setTagIds] = useState(params.tag_id?.split(",") || [])
  const [includeClosedGroups, setIncludeClosedGroups] = useState(
    closedAndFullGroupsVisible || params.enrollment?.includes("closed"),
  )

  useEffect(() => {
    updateParams((prev) => ({
      ...prev,
      enrollment: includeClosedGroups
        ? ALL_GROUPS_ENROLLMENT_STATUS
        : OPEN_GROUPS_ENROLLMENT_STATUS,
    }))
  }, [includeClosedGroups])

  useEffect(() => {
    updateParams((prev) => {
      if (tagIds.length > 0) {
        const filter = uniq([...prev.filter.split(","), "tag"]).join(",")
        return { ...prev, filter, tag_id: tagIds.join(",") }
      } else {
        const filter = prev.filter
          .split(",")
          .filter((value) => value !== "tag")
          .join(",")
        return omit({ ...prev, filter }, "tag_id")
      }
    })
  }, [tagIds])

  useEffect(() => {
    updateParams((prev) => ({ ...prev, "where[name]": debouncedSearchTerm }))
  }, [debouncedSearchTerm])

  return (
    <FiltersContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        params,
        updateParams,
        includeClosedGroups,
        setIncludeClosedGroups,
        tagIds,
        setTagIds,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
FiltersContextProvider.propTypes = {
  children: node.isRequired,
}
