import { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"

export function Confirmation({ individuals }) {
  const { name } = useContext(CurrentGroupContext)

  return (
    <TabView>
      <div className="success-alert alert d-f fd-c ai-c p-3">
        <div className="fs-1">
          <Icon color="var(--color-emerald)" symbol="general#check" />
        </div>
        <div className="my-1">
          <Heading level={1} size={3} text="Email sent" />
        </div>
        <p className="ta-c">
          Your message will be delivered to
          {individuals
            ? ` the selected members of ${name} `
            : ` all ${name} members `}
          who have email addresses.
        </p>
        <Link
          to={location.pathname.replace("/email/new", "")}
          className="c-turquoise text-btn"
        >
          View all members
        </Link>
      </div>
    </TabView>
  )
}
Confirmation.propTypes = {
  individuals: PropTypes.bool.isRequired,
}
