import {
  useSuspenseQuery,
  usePrefetchQuery,
  splitQueryParams,
  queryFnAll,
} from "source/registrations/utils/queryCache"

const FIELDS = {
  AttendeeType: [
    "age_restriction_unit_to_s",
    "age_restriction_unit",
    "any_active_attendees_on_waitlist",
    "at_maximum_capacity",
    "attendee_type_capacity_to_string",
    "available_add_ons",
    "available_capacity",
    "collection_options",
    "constrained_capacity",
    "different_price_later",
    "evaluate_age_at_string",
    "evaluate_age_at",
    "event",
    "gender_restriction_unit_to_s",
    "gender_restriction_unit",
    "grade_label",
    "has_age_restrictions",
    "has_gender_restrictions",
    "has_grade_restrictions",
    "has_restrictions",
    "max_age_in_months",
    "max_age",
    "max_grade_to_s",
    "max_grade",
    "maximum_capacity",
    "min_age_in_months",
    "min_age",
    "min_grade_to_s",
    "min_grade",
    "minimum_deposit_cents",
    "name",
    "optional_add_ons",
    "position",
    "price_cents",
    "price_later_string",
    "price_later",
    "price_now_expires_at",
    "price_string",
    "required_add_ons",
    "signup_time",
    "template_attendee_type",
    "waitlist",
  ],
}

const queryOptions = (eventId, options = {}) => {
  const path = `/registrations/v2/events/${eventId}/attendee_types`
  const { params, queryOptions } = splitQueryParams(options)

  return {
    queryFn: queryFnAll,
    queryKey: [
      path,
      {
        order: "position",
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  }
}

export const useAttendeeTypesPrefetch = (eventId, options) =>
  usePrefetchQuery(queryOptions(eventId, options))

export const useAttendeeTypes = (eventId, options) =>
  useSuspenseQuery(queryOptions(eventId, options))
