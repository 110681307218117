import { object } from "prop-types"
import { AlertMessage } from "source/shared/components"
import NoCapacityError from "./NoCapacityError"
import { RecordNotFound } from "source/shared/components"
import { Link } from "react-router-dom"

const DisplayError = ({ error }) => {
  if (error.title === "No capacity available") {
    return <NoCapacityError error={error} />
  }

  if (error.title === "Waitlisted registration not found") {
    return (
      <RecordNotFound
        message="Someone may have already fulfilled the waitlisted registration you are trying to access. If you believe this message is incorrect, verify that the URL is correct and try again."
        actions={
          <Link to="/registrations" className="btn">
            See all signups
          </Link>
        }
      />
    )
  }

  return (
    <AlertMessage>
      {error.detail || "Something unexpected happened. Please try again."}
    </AlertMessage>
  )
}

DisplayError.propTypes = {
  error: object,
}

export default DisplayError
