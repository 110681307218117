import { Suspense, useContext } from "react"
import { useParams } from "react-router-dom"
import { string } from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { useSession } from "source/shared/hooks/useSession"
import { CurrentEventContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import EventSecondaryHeader from "source/groups/my/groups/events/EventSecondaryHeader"
import { Heading } from "@planningcenter/doxy-web"

export default function NotesShow() {
  const { noteId } = useParams()
  const currentEvent = useContext(CurrentEventContext)
  const { event, location: eventLocation } = currentEvent
  const url = `${
    event.links.notes
  }/${noteId}?include=owner&fields[Owner]=first_name`

  return (
    <TabView showActions={false}>
      <div>
        <EventSecondaryHeader event={event} location={eventLocation} />
        <Suspense fallback={null}>
          <Fetch url={url} />
        </Suspense>
      </div>
    </TabView>
  )
}

function Fetch({ url }) {
  const { data: currentPerson } = useSession(true)
  const { data, included, errors } = useApiRead(url)
  const notFound = errors?.some((e) => e.status === "404")

  if (notFound) return null

  const owner = data?.relationships?.owner?.data
  const author = included.find(
    ({ type, id }) => owner?.type === type && owner?.id === id,
  )
  const title =
    author.id === currentPerson.id
      ? "My note"
      : `${author.attributes.first_name}\u2019s note`

  return (
    <div className="mt-2">
      <Heading size={3} text={title} />
      <p style={{ whiteSpace: "pre-line" }}>{data.attributes.body}</p>
    </div>
  )
}
Fetch.propTypes = {
  url: string.isRequired,
}
