import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { Textarea } from "@planningcenter/doxy-web"

const MedicalNotesField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
}) => {
  const { medicalNotes } = person

  return (
    <Field
      label="Medical Notes"
      value={medicalNotes}
      persistedValue={existingPerson.medicalNotes}
      onCancel={() => onCancel("medicalNotes")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
    >
      <Textarea
        id={`medicalNotes-${id}`}
        name={`medicalNotes-${id}`}
        value={medicalNotes || ""}
        onChange={({ target: { value } }) => onChange("medicalNotes", value)}
        placeholder="Medical notes"
      />
    </Field>
  )
}

MedicalNotesField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    phoneNumber: string,
  }),
  existingPerson: shape({
    phoneNumber: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
}

export default MedicalNotesField
