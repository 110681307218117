import { useState, useEffect, useContext, useCallback } from "react"
import { bool, number, shape, array, func } from "prop-types"
import { ReservationContext } from "source/registrations/reservations/ReservationContext"
import { TextInput, Button } from "@planningcenter/doxy-web"
import { Select } from "source/shared/components"
import Field from "./Field"
import spinner from "source/svg/spinner.svg"
import { Icon } from "source/shared/components"

const newEmergencyContact = { id: "new", name: "", phoneNumber: "" }

const DoneIcon = () => (
  <Icon symbol="general#check-circle" className="c-emerald" />
)

const SavingSpinner = () => (
  <img src={spinner} alt="Saving" style={{ height: "1em", width: "1em" }} />
)

const INDICATOR_STATES = {
  off: null,
  saving: <SavingSpinner />,
  done: <DoneIcon />,
}

const EmergencyContactField = ({
  attendee,
  onChange,
  reservation,
  isRequired,
  onApplyEmergencyContactToAll,
}) => {
  const { emergencyContacts } = useContext(ReservationContext)
  const [selectedContact, setSelectedContact] = useState(null)

  const [applyIndicator, setApplyIndicator] = useState("off")

  const bestAvailableEmergencyContact = useCallback(() => {
    const attendeeEmergencyContacts = emergencyContacts.filter(
      (contact) =>
        contact.name !== attendee.person?.name &&
        !!contact.name &&
        !!contact.phoneNumber,
    )

    const responsiblePersonEmergencyContact = attendeeEmergencyContacts.find(
      (contact) => contact.name === reservation.responsiblePerson.name,
    )

    const existingEmergencyContact =
      attendee.emergencyContact?.id == "new"
        ? null
        : attendee.person?.name == attendee.emergencyContact?.name
          ? null
          : attendee.emergencyContact

    return (
      existingEmergencyContact ||
      responsiblePersonEmergencyContact ||
      attendeeEmergencyContacts[0] ||
      newEmergencyContact
    )
  }, [
    emergencyContacts,
    reservation.responsiblePerson,
    attendee.person,
    attendee.emergencyContact,
  ])

  const onEmergencyContactSelect = useCallback(
    (id) => {
      const emergencyContact =
        id === "new"
          ? newEmergencyContact
          : emergencyContacts.find((ec) => ec.id === id)

      setSelectedContact({
        ...emergencyContact,
        lockName: !!emergencyContact?.name,
        lockPhoneNumber: !!emergencyContact?.phoneNumber,
      })

      if (emergencyContact.id !== attendee.emergencyContact?.id) {
        onChange("emergencyContact", {
          ...emergencyContact,
          lockName: !!emergencyContact?.name,
          lockPhoneNumber: !!emergencyContact?.phoneNumber,
        })
      }
    },
    [emergencyContacts, onChange],
  )

  const handleChange = (field, value) => {
    const ec = { ...attendee.emergencyContact, [field]: value }
    onChange("emergencyContact", ec)
  }

  useEffect(() => {
    if (!selectedContact) {
      onEmergencyContactSelect(bestAvailableEmergencyContact().id)
    } else {
      onEmergencyContactSelect(attendee.emergencyContact.id)
    }
  }, [])

  useEffect(() => {
    return onEmergencyContactSelect(bestAvailableEmergencyContact().id)
  }, [attendee.person?.id])

  const showInputRow =
    !selectedContact?.lockName || !selectedContact?.lockPhoneNumber

  const handleApplyToAllClick = () => {
    setApplyIndicator("saving")
    setTimeout(() => setApplyIndicator("done"), 300)
    setTimeout(() => setApplyIndicator("off"), 3300)
    onApplyEmergencyContactToAll(attendee.emergencyContact)
  }

  const selectOptions = () => {
    return [
      ...emergencyContacts
        .filter((ec) => !!ec.name)
        .map((ec) => ({
          label: ec.phoneNumber ? `${ec.name} - ${ec.phoneNumber}` : ec.name,
          value: ec.id,
        })),
      { label: "Someone else", value: "new" },
    ]
  }

  const renderApplyIndicator = () => INDICATOR_STATES[applyIndicator]

  return (
    <Field label="Emergency Contact" isRequired={isRequired}>
      <div className="d-f fd-c g-1">
        <Select
          name={`emergencyContact-${attendee.id}`}
          value={selectedContact?.id || ""}
          options={selectOptions()}
          onChange={({ target: { value } }) => onEmergencyContactSelect(value)}
          style={{ height: "32px" }}
        />

        {showInputRow && (
          <div className="d-f ai-c g-1">
            <TextInput
              placeholder="Name"
              readOnly={attendee.emergencyContact?.lockName}
              size="sm"
              value={attendee.emergencyContact?.name || ""}
              onChange={({ target: { value } }) => handleChange("name", value)}
            />

            <TextInput
              placeholder="Phone number"
              readOnly={attendee.emergencyContact?.lockPhoneNumber}
              size="sm"
              value={attendee.emergencyContact?.phoneNumber || ""}
              onChange={({ target: { value } }) =>
                handleChange("phoneNumber", value)
              }
            />
          </div>
        )}

        {reservation.attendees.length > 1 && (
          <div className="d-f ai-c jc-fe">
            {renderApplyIndicator()}
            <Button
              text="Apply this contact to all attendees"
              disabled={
                !attendee.emergencyContact?.name ||
                !attendee.emergencyContact?.phoneNumber
              }
              onClick={handleApplyToAllClick}
              variant="naked"
              size="sm"
            />
          </div>
        )}
      </div>
    </Field>
  )
}

EmergencyContactField.propTypes = {
  attendee: shape({}),
  reservation: shape({
    responsiblePerson: shape({
      id: number,
    }),
    attendees: array,
  }),
  onChange: func,
  onApplyEmergencyContactToAll: func,
  totalAttendees: number,
  isRequired: bool,
  isOptional: bool,
}

export default EmergencyContactField
