import { Skeleton as SkeletonComponent, Circle, Paragraph } from "./Skeleton"

interface SkeletonComposition {
  Circle: typeof Circle
  Paragraph: typeof Paragraph
}

const Skeleton: typeof SkeletonComponent & SkeletonComposition = Object.assign(
  SkeletonComponent,
  {
    Circle: Object.assign(Circle, { displayName: "Skeleton.Circle" }),
    Paragraph: Object.assign(Paragraph, { displayName: "Skeleton.Paragraph" }),
  },
)

export { Skeleton }
