import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { node } from "prop-types"
import _ from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { getRelationship } from "source/shared/getRelationship"
import { linkify } from "source/shared/linkify"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"
import {
  mergeAuthors,
  isCustomAvatarUrl,
} from "source/groups/messaging/Topic/utils"

export const RepliesDataContext = createContext()

const INGEST = "INGEST"
const REMOVE = "REMOVE"
const SET_LEADERS = "SET_LEADERS"
const SET_MEMBER_ATTRS = "SET_MEMBER_ATTRS"

function refetchReply(groupId, topicId, replyId) {
  const uri = `/groups/v2/me/groups/${groupId}/forum_topics/${topicId}/replies/${replyId}?include=author,my_reaction`
  return sessionApiClient.get(uri)
}

function isPendingReplyThatShouldBeRemoved(reply, incomingReplies) {
  if (!reply.pending) return false
  return incomingReplies.find(
    (r) =>
      r.attributes.message === reply.attributes.message && reply.id != r.id,
  )
}

export function prepareMessageForSubmitting(rawMessage) {
  // We want a unique message, so we are hiding a UUID inside
  return linkify(rawMessage).replace(
    /^<p>/,
    `<p data-uuid="${Math.random().toString(36)}">`,
  )
}

const initialState = {
  replies: [],
  authors: {},
  leaderIds: [],
  hasMore: false,
  totalCount: undefined,
}

function reducer(state, action) {
  switch (action.type) {
    case INGEST: {
      const { json } = action.payload
      const includedAuthors = json.included
        .filter(({ type }) => type === "Author")
        .reduce((acc, author) => ({ ...acc, [author.id]: author }), {})
      const includedMyReactions = json.included.filter(
        ({ type }) => type === "Reaction",
      )
      const data = [json.data].flat()

      const replies = _(data.concat(state.replies))
        .map((r) => {
          return {
            ...r,
            myReaction:
              r.myReaction ||
              getRelationship(
                { data: r, included: includedMyReactions },
                "my_reaction",
              ),
          }
        })
        .uniqBy((r) => r.id)
        .sortBy((r) => [r.pending, r.attributes.created_at])
        .reject((r) => isPendingReplyThatShouldBeRemoved(r, data))
        .value()

      const total_count =
        json.meta?.human_authored_replies?.count || state.totalCount

      return {
        ...state,
        replies,
        authors: mergeAuthors(state.authors, includedAuthors),
        hasMore: Array.isArray(json.data) ? !!json.links.next : state.hasMore,
        totalCount: total_count,
      }
    }
    case REMOVE: {
      const { id } = action.payload
      return {
        ...state,
        replies: state.replies.filter((r) => r.id !== id),
        totalCount: state.replies.find((r) => r.id === id)
          ? state.totalCount - 1
          : state.totalCount,
      }
    }
    case SET_LEADERS: {
      const { leaderIds } = action.payload
      return {
        ...state,
        leaderIds,
      }
    }
    case SET_MEMBER_ATTRS: {
      const { personIdsToAttributes } = action.payload
      const updatedAuthors = _.mergeWith(
        state.authors,
        personIdsToAttributes,
        (author, newAttributes) => ({
          ...author,
          attributes: {
            ...author.attributes,
            ...newAttributes,
          },
        }),
      )

      return {
        ...state,
        authors: updatedAuthors,
      }
    }
    default: {
      throw new Error(`Unrecognized action: ${action.type}`)
    }
  }
}

const ADD_AUTHOR_IDS = "ADD_AUTHOR_IDS"
const SET_FETCHING_IDS = "SET_FETCHING_IDS"
const SET_FETCHED_IDS = "SET_FETCHED_IDS"

function fetchAuthorAvatarReducer(state, action) {
  switch (action.type) {
    case ADD_AUTHOR_IDS: {
      const { ids, value } = action.payload
      return ids.reduce((acc, id) => ({ ...acc, [id]: value }), { ...state })
    }
    case SET_FETCHING_IDS: {
      const { ids } = action.payload
      return ids.reduce((acc, id) => ({ ...acc, [id]: "fetching" }), {
        ...state,
      })
    }
    case SET_FETCHED_IDS: {
      const { ids } = action.payload
      return ids.reduce((acc, id) => ({ ...acc, [id]: "fetched" }), {
        ...state,
      })
    }
    default: {
      throw new Error(`Unrecognized action: ${action.type}`)
    }
  }
}

function useFetchAuthorAvatars(group, authors, setAvatarUrls) {
  const [state, dispatch] = useReducer(fetchAuthorAvatarReducer, {})
  const allIds = Object.keys(authors)
  const knownIds = Object.keys(state)

  // these are the things we actually care about. allIds and knownIds
  // only provide context to build these variables.
  const unknownIds = allIds.filter((id) => !knownIds.includes(id))
  const unfetchedIds = knownIds.reduce(
    (acc, id) => (state[id] === "unfetched" ? [...acc, id] : acc),
    [],
  )

  if (unknownIds.length > 0) {
    const unknownAuthorHasCustomAvatar = unknownIds.some((id) =>
      isCustomAvatarUrl(authors[id]?.attributes?.avatar_url),
    )
    dispatch({
      type: ADD_AUTHOR_IDS,
      payload: {
        ids: unknownIds,
        value: unknownAuthorHasCustomAvatar ? "fetched" : "unfetched",
      },
    })
  }

  useEffect(() => {
    if (unfetchedIds.length > 0) {
      const ids = [...unfetchedIds]
      dispatch({ type: SET_FETCHING_IDS, payload: { ids } })
      sessionApiClient
        .get(
          `/groups/v2/me/groups/${
            group.id
          }/memberships?where[person_id]=${ids.join(",")}&filter=phantom`,
        )
        .then((resp) =>
          resp.data.reduce(
            (acc, d) => ({
              ...acc,
              [d.relationships.person.data.id]: {
                name: `${d.attributes.first_name} ${d.attributes.last_name}`,
                avatar_url: d.attributes.avatar_url,
              },
            }),
            {},
          ),
        )
        .then((personIdsToAttributes) => {
          dispatch({
            type: SET_FETCHED_IDS,
            payload: { ids },
          })
          setAvatarUrls(personIdsToAttributes)
        })
    }
  })

  return state
}

export const RepliesDataProvider = ({ children, ...rest }) => {
  const { currentTopic, group } = useContext(TopicsDataContext)
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    totalCount: currentTopic?.attributes?.human_authored_replies?.count,
  })
  const avatarsFetchingState = useFetchAuthorAvatars(
    group,
    state.authors,
    (personIdsToAttributes) =>
      dispatch({ type: SET_MEMBER_ATTRS, payload: { personIdsToAttributes } }),
  )

  const ingest = useCallback((json) => {
    dispatch({ type: INGEST, payload: { json } })
  }, [])
  const del = (id) => dispatch({ type: REMOVE, payload: { id } })
  const setLeaders = useCallback(
    (leaderIds) => dispatch({ type: SET_LEADERS, payload: { leaderIds } }),
    [],
  )
  const react = (reaction) => {
    const reply = reaction.data.relationships.message.data

    refetchReply(group.id, currentTopic.id, reply.id).then((reply) => {
      dispatch({ type: INGEST, payload: { json: reply } })
    })
  }

  const combinedState = {
    ...state,
    authors: Object.keys(state.authors).reduce(
      (acc, authorId) => ({
        ...acc,
        [authorId]: {
          ...state.authors[authorId],
          knownBestAvatarUrl: avatarsFetchingState[authorId] === "fetched",
        },
      }),
      {},
    ),
  }

  return (
    <RepliesDataContext.Provider
      value={{
        injected: rest,
        ingest,
        del,
        react,
        setLeaders,
        ...combinedState,
      }}
    >
      {children}
    </RepliesDataContext.Provider>
  )
}

RepliesDataProvider.propTypes = {
  children: node.isRequired,
}
