import { createContext, useEffect, useState } from "react"
import { node } from "prop-types"
import { useMobileDetect } from "source/shared/hooks/useMobileDetect"
import { useGroupType } from "../hooks/useGroupType"

export const MapContext = createContext()

export function MapContextProvider({ children }) {
  const { data: groupType } = useGroupType()
  const mapIsVisibleForGroupType =
    groupType.attributes.church_center_map_visible
  const { isMobile } = useMobileDetect()
  const [hasLocations, setHasLocations] = useState(false)
  const [mapIsPossible, setMapIsPossible] = useState(false)
  const [mapIsRevealed, setMapIsRevealed] = useState(false)

  useEffect(() => {
    setMapIsPossible(hasLocations && !isMobile && mapIsVisibleForGroupType)
  }, [hasLocations, isMobile, mapIsVisibleForGroupType])

  return (
    <MapContext.Provider
      value={{
        setHasLocations,
        mapIsPossible,
        mapIsRevealed,
        setMapIsRevealed,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
MapContextProvider.propTypes = {
  children: node,
}
