import { useReducer } from "react"
import { getRelationship } from "source/shared/getRelationship"
import { uniqBy } from "lodash"

export function usePersonDirectoryPrivacySettings({ person, households }) {
  const {
    data: {
      id: personId,
      attributes: {
        directory_privacy_mode_enabled:
          initialIndividualDirectoryPrivacyModeEnabled,
        directory_private_contact_mode: initialDirectoryPrivateContactMode,
      },
    },
  } = person
  const initialPersonDirectoryPrivacySettings = {
    personId,
    directoryPrivacyModeEnabled: initialIndividualDirectoryPrivacyModeEnabled,
    directoryPrivateContactMode: initialDirectoryPrivateContactMode,
  }
  const initialHouseholdDirectoryPrivacySettings =
    serializeHouseholdPrivacySettings(households)

  const initialDirectoryPrivacySettings = uniqBy(
    [
      initialPersonDirectoryPrivacySettings,
      ...initialHouseholdDirectoryPrivacySettings,
    ],
    "personId",
  )
  const [{ directoryPrivacySettings }, dispatch] = useReducer(
    managePrivacySettingsReducer,
    { directoryPrivacySettings: initialDirectoryPrivacySettings },
  )

  return {
    directoryPrivacySettings,
    dispatch,
  }
}

function managePrivacySettingsReducer({ directoryPrivacySettings }, action) {
  const newState = buildNewPrivacySettingsState(
    directoryPrivacySettings,
    action,
  )

  return { directoryPrivacySettings: newState }
}

function buildNewPrivacySettingsState(state, action) {
  const otherHouseholdMemberSettings = state.filter(
    ({ personId }) => personId !== action.payload.personId,
  )
  switch (action.type) {
    case "update":
      return [...otherHouseholdMemberSettings, action.payload]
    case "resetForPerson":
      return [
        ...otherHouseholdMemberSettings,
        {
          personId: action.payload.personId,
          directoryPrivacyModeEnabled: false,
          directoryPrivateContactMode: "disabled",
        },
      ]
    default:
      throw new Error("Unrecognized action in managePrivacySettingsReducer")
  }
}

function serializeHouseholdPrivacySettings({ data, included }) {
  return data.reduce((acc, household) => {
    const people = getRelationship({ data: household, included }, "people")
    return people.reduce(
      (innerAcc, person) => [
        ...innerAcc,
        {
          personId: person.id,
          directoryPrivacyModeEnabled:
            person.attributes.directory_privacy_mode_enabled,
          directoryPrivateContactMode:
            person.attributes.directory_private_contact_mode,
        },
      ],
      acc,
    )
  }, [])
}
