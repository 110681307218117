import { Suspense, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { get as dig } from "lodash"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import TabView from "source/groups/my/groups/TabView"
import InfiniteScroll from "react-infinite-scroller"
import { Loading, Icon } from "source/shared/components"
import BlankState from "source/groups/BlankState"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { resourceIconSymbol } from "./utils/resourceIconSymbol"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

const SORT_OPTIONS = [
  { label: "Name", value: "name" },
  { label: "Last Updated", value: "-updated_at" },
]

export default function ResourcesIndex() {
  const group = useContext(CurrentGroupContext)
  const [selectedSort, setSelectedSort] = useLocalStorage(
    "groups_sort_resources_by",
    SORT_OPTIONS[0],
  )
  const canCreateResources = dig(group, "abilities.resources.can_create", false)

  return (
    <TabView>
      <TabViewHeadingRow text="Resources">
        <>
          <label
            htmlFor="sort-resources-menu-button"
            className="screen-reader-text"
          >
            Sort by:
          </label>
          <Menu>
            <MenuButton
              id="sort-resources-menu-button"
              className="ta-l select small-select w-a"
            >
              {selectedSort.label}
            </MenuButton>
            <MenuList
              className="dropdown__menu"
              style={{ "--dropdown-menu--font-size": "1rem" }}
            >
              {SORT_OPTIONS.map((option, index) => (
                <MenuItem
                  key={index}
                  onSelect={() => setSelectedSort(SORT_OPTIONS[index])}
                  className="dropdown__item"
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
        {canCreateResources && (
          <Link to="new" className="mr-1 minor-btn secondary-btn btn">
            &#43; Add resource
          </Link>
        )}
      </TabViewHeadingRow>
      <Suspense fallback={<Loading />}>
        <ResourcesList
          sortValue={selectedSort.value}
          canCreateResources={canCreateResources}
        />
      </Suspense>
    </TabView>
  )
}

function ResourcesList({ sortValue, canCreateResources }) {
  const group = useContext(CurrentGroupContext)
  const {
    records: resources,
    hasMore,
    loadMore,
  } = useInfiniteLoadingResource(
    `/groups/v2/me/groups/${group.id}/resources?sort=${sortValue}`,
  )

  const canManageGroup = dig(group, "abilities.can_manage", false)

  const blankStateMessage = canCreateResources
    ? "Add a URL, image, or file that either all group members or only fellow leaders can view."
    : "There are currently no resources for this group."

  return resources.length ? (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      <ul className="unstyled">
        {resources.map((resource) => (
          <ResourceListItem
            key={resource.id}
            json={resource}
            canManageGroup={canManageGroup}
          />
        ))}
      </ul>
    </InfiniteScroll>
  ) : (
    <BlankState icon="general#outlined-generic-file">
      <p css={{ maxWidth: "22rem", margin: "0 auto" }}>{blankStateMessage}</p>
    </BlankState>
  )
}
ResourcesList.propTypes = {
  canCreateResources: PropTypes.bool.isRequired,
  sortValue: PropTypes.string.isRequired,
}

function ResourceListItem({ json, canManageGroup }) {
  const { icon_class, name, description, shared, visibility } = json.attributes

  return (
    <li className="mb-2">
      <div className={`d-f ${description ? "ai-fs" : "ai-c"}`}>
        <ResourceIconBlock iconClass={icon_class} />
        <div className="f-1 d-f fd-c ai-fs">
          <Link to={json.id} className="fw-500">
            {name}
          </Link>
          {description && (
            <div className="d-g">
              <div className="fs-4 c-tint2 truncate">{description}</div>
            </div>
          )}
          <div className="d-f g-1 mt-4p">
            {shared && canManageGroup && <span className="badge">Shared</span>}
            {visibility === "leaders" && (
              <span className="badge">Leaders only</span>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}
ResourceListItem.propTypes = {
  json: PropTypes.object.isRequired,
  canManageGroup: PropTypes.bool.isRequired,
}

function ResourceIconBlock({ iconClass, ...restProps }) {
  const symbol = resourceIconSymbol(iconClass)

  return (
    <div className="w-6 h-6 mr-2 media-tile">
      <Icon symbol={symbol} {...restProps} className="c-tint3" aria-hidden />
    </div>
  )
}
ResourceIconBlock.propTypes = { iconClass: PropTypes.string }
