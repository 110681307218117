import _ from "lodash"

export function mergeAuthors(one, two) {
  const oneKeys = Object.keys(one)
  const twoKeys = Object.keys(two)
  const allKeys = _.uniq([...oneKeys, ...twoKeys])
  const [sharedKeys, uniqueKeys] = _.partition(
    allKeys,
    (k) => oneKeys.includes(k) && twoKeys.includes(k),
  )

  const uniques = uniqueKeys.reduce(
    (acc, k) => ({ ...acc, [k]: one[k] || two[k] }),
    {},
  )
  const deduped = sharedKeys.reduce(
    (acc, k) => ({ ...acc, [k]: preferBestAttributes(one[k], two[k]) }),
    {},
  )

  return {
    ...uniques,
    ...deduped,
  }
}

export function preferBestAttributes(one, two) {
  return {
    ...one,
    ...two,
    attributes: {
      ...one.attributes,
      ...two.attributes,
      name: one.attributes.name || two.attributes.name,
      avatar_url: preferCustomAvatarUrl(
        one.attributes.avatar_url,
        two.attributes.avatar_url,
      ),
    },
  }
}

function preferCustomAvatarUrl(one, two) {
  if (isCustomAvatarUrl(one)) {
    return one
  } else {
    return two
  }
}

export function isCustomAvatarUrl(url) {
  try {
    const { pathname } = new URL(url)
    return !isInitials(pathname) && !isGeneric(pathname)
  } catch {
    return false
  }
}

function isInitials(pathname) {
  const regex = /\/uploads\/initials.*/
  return regex.test(pathname)
}

function isGeneric(pathname) {
  const regex = /\/static\/avatar-generic.*/
  return regex.test(pathname)
}
