import PropTypes from "prop-types"
import { capitalize } from "lodash"
import { AlertMessage } from "source/shared/components"

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      detail: PropTypes.string,
      source: PropTypes.shape({ parameter: PropTypes.string }),
    }),
  ),
  message: PropTypes.string,
}
export function FormErrors({ message, errors = [] }) {
  if (!errors.length) return null

  const messages = errors.map(({ detail, source: { parameter } }) => {
    if (parameter === "base" || parameter === "destination") {
      return detail
    } else {
      return `${capitalize(parameter.replace("_", " "))} ${detail}`
    }
  })

  return (
    <AlertMessage className="mb-2">
      {message}
      <ul className="mt-1">
        {messages.map((m) => (
          <li key={m} className="mt-1">
            {m}
          </li>
        ))}
      </ul>
    </AlertMessage>
  )
}
