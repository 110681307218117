import { useEffect, useState, useContext } from "react"
import { reservationStepPropTypes } from "source/registrations/propTypes"
import { find as _find } from "lodash"
import { Link } from "react-router-dom"
import { ReservationContext } from "source/registrations/reservations/ReservationContext"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import AttendeesPersonSelect from "source/registrations/reservations/AttendeesPersonSelect"
import {
  isGenderRestricted,
  isGradeRestricted,
  isAgeRestricted,
} from "source/registrations/restrictions"
import { isStringBlank } from "source/registrations/utils/helpers"
import { Heading } from "@planningcenter/doxy-web"
import { formatEmergencyContact } from "source/registrations/reservations/personInfo/emergencyContact"

const Attendees = ({
  people: reservationPeople,
  attendees: reservationAttendees,
  session,
  households,
  error,
  isProcessing,
  onIsProcessing,
  onReservationSave,
  onEdit,
  onCancel,
  step,
  className,
  reservation,
  maxHouseholdSize,
}) => {
  const { emergencyContacts, setEmergencyContacts } =
    useContext(ReservationContext)

  const [attendees, setAttendees] = useState([])
  const [people, setPeople] = useState([])
  const [attendeeErrors, setAttendeeErrors] = useState([])
  const [possibleEmergencyContacts, setPossibleEmergencyContacts] = useState([])

  useEffect(() => {
    setPossibleEmergencyContacts(emergencyContacts)
  }, [emergencyContacts])

  useEffect(() => {
    const availablePeople = reservation.responsiblePerson?.child
      ? reservationPeople.filter(
          (person) => person.id === reservation.responsiblePerson.id,
        )
      : reservationPeople

    const attendees = reservationAttendees.map((attendee) => {
      if (attendee.person || availablePeople.length !== 1) return attendee

      const attendeeWithSelectedPerson = {
        ...attendee,
        person: availablePeople[0],
        isPersonModified: true,
      }

      return checkRestrictions(attendeeWithSelectedPerson).length === 0
        ? attendeeWithSelectedPerson
        : attendee
    })

    setAttendees(attendees)
    setPeople(availablePeople)
  }, [reservationAttendees, reservationPeople, reservation.responsiblePerson])

  useEffect(() => {
    if (reservationPeople.length === 0) return

    const possible = [
      ...reservationPeople
        .filter((person) => !person.child)
        .map((person) => {
          const phoneNumber =
            person.phoneNumbers?.find((phoneNumber) => phoneNumber.primary)
              ?.number ||
            person.phoneNumber ||
            ""

          return formatEmergencyContact(person.name, phoneNumber)
        }),
      ...attendees
        .filter((attendee) => !!attendee.emergencyContact)
        .map((attendee) => attendee.emergencyContact),
      ...possibleEmergencyContacts,
    ]

    const dedupedEmergencyContacts = Array.from(
      new Set(possible.map((ec) => ec.id)),
    ).map((id) =>
      possible
        .filter((ec) => ec.id == id)
        .reduce((acc, current) => ({ ...acc, ...current })),
    )

    if (dedupedEmergencyContacts.length == emergencyContacts.length) return

    setPossibleEmergencyContacts(dedupedEmergencyContacts)
    setEmergencyContacts(dedupedEmergencyContacts)
  }, [reservationPeople, setEmergencyContacts, emergencyContacts, attendees])

  const validateFields = () =>
    attendees.every((a) => {
      const { collectionOptions = {} } = a.attendeeType

      const isNameRequired = true
      const isGenderRequired = collectionOptions["gender"] === "required"
      const isGradeRequired = collectionOptions["grade"] === "required"
      const isAgeRequired = collectionOptions["birthdate"] === "required"
      const isEmailRequired = collectionOptions["email"] === "required"
      const isPhoneRequired = collectionOptions["phone"] === "required"
      const isAddressRequired = collectionOptions["address"] === "required"
      const isEmergencyContactRequired =
        collectionOptions["emergencyContact"] === "required"

      const person = a.person || {}

      const isNameValid = isNameRequired
        ? !isStringBlank(person.firstName) && !isStringBlank(person.lastName)
        : true

      const isGenderValid = isGenderRequired
        ? !isStringBlank(person.gender)
        : true

      const isGradeValid = isGradeRequired ? !isStringBlank(person.grade) : true

      const isAgeValid = isAgeRequired ? !isStringBlank(person.birthdate) : true

      const isEmailValid = isEmailRequired
        ? !isStringBlank(person.emailAddress)
        : true

      const isPhoneValid = isPhoneRequired
        ? !isStringBlank(person.phoneNumber)
        : true

      const isAddressValid = isAddressRequired
        ? !isStringBlank(person.address?.street) ||
          !isStringBlank(person.address?.city) ||
          !isStringBlank(person.address?.state) ||
          !isStringBlank(person.address?.zip)
        : true

      const emergencyContact = a.emergencyContact || {}

      let isEmergencyContactValid = true

      if (
        isEmergencyContactRequired ||
        !!emergencyContact?.name ||
        !!emergencyContact?.phoneNumber
      ) {
        isEmergencyContactValid =
          !isStringBlank(emergencyContact?.name) &&
          !isStringBlank(emergencyContact?.phoneNumber)
      }

      const isPersonValid =
        isGenderValid &&
        isGradeValid &&
        isAgeValid &&
        isEmailValid &&
        isPhoneValid &&
        isAddressValid &&
        isEmergencyContactValid

      return (person.id && isPersonValid) || (isNameValid && isPersonValid)
    })

  const checkRestrictions = (attendee) => {
    const { id, person, attendeeType } = attendee
    const requirements = []

    if (isGenderRestricted(attendeeType, person)) {
      requirements.push({
        id,
        type: "gender",
        message: "Attendee does not meet gender requirements",
      })
    }

    if (isGradeRestricted(attendeeType, person)) {
      requirements.push({
        id,
        type: "grade",
        message: "Attendee does not meet grade requirements",
      })
    }

    if (isAgeRestricted(attendeeType, person)) {
      requirements.push({
        id,
        type: "birthdate",
        message: "Attendee does not meet age restrictions",
      })
    }

    return requirements
  }

  const validateRestrictions = () => {
    const validations = attendees.map((a) => checkRestrictions(a)).flat(1)
    setAttendeeErrors(validations)

    return validations
  }

  const handleSave = async (_event) => {
    onIsProcessing(true)
    const validations = validateRestrictions()

    if (validations.length > 0) {
      return onIsProcessing(false)
    }

    const personContactData = {
      location: "Home",
      primary: true,
    }

    const included = attendees
      .filter((a) => a.isPersonModified)
      .map((a) => {
        const existingPerson = people.find((p) => p.id === a.person.id)

        return {
          type: "AttendeePerson",
          attributes: {
            attendeeId: a.id,
            ...(a.emergencyContact?.name &&
              a.emergencyContact?.phoneNumber && {
                emergencyContactAttributes: {
                  name: a.emergencyContact.name,
                  phoneNumber: a.emergencyContact.phoneNumber,
                },
              }),
            personAttributes: {
              ...(a.person?.id && {
                accountCenterId: a.person.id,
              }),
              ...(!a.person?.id && {
                firstName: a.person?.firstName,
                lastName: a.person?.lastName,
                ...(a.person?.householdId && {
                  householdId: a.person.householdId,
                }),
              }),
              ...(a.person?.gender && { gender: a.person.gender }),
              ...(a.person?.grade && { grade: a.person.grade }),
              ...(a.person?.birthdate && { birthdate: a.person.birthdate }),
              ...(a.person?.medicalNotes && {
                medicalNotes: a.person.medicalNotes,
              }),
              ...(!existingPerson?.phoneNumber &&
                a.person?.phoneNumber && {
                  phoneNumbersAttributes: [
                    {
                      ...personContactData,
                      number: a.person.phoneNumber,
                    },
                  ],
                }),
              ...(!existingPerson?.emailAddress &&
                a.person?.emailAddress && {
                  emailAddressesAttributes: [
                    {
                      ...personContactData,
                      address: a.person.emailAddress,
                    },
                  ],
                }),
              ...(!existingPerson?.address &&
                a.person?.address && {
                  addressesAttributes: [
                    {
                      ...personContactData,
                      street: a.person.address.street,
                      city: a.person.address.city,
                      state: a.person.address.state,
                      zip: a.person.address.zip,
                    },
                  ],
                }),
            },
          },
        }
      })

    const mergedAndDedupedAttributes = mergePersonAttributes(included)

    await onReservationSave({
      data: { attributes: {} },
      included: mergedAndDedupedAttributes,
    })
    onIsProcessing(false)
  }

  // This function will take attendees with the same person id, and merge their phoneNumbersAttributes, addressesAttributes
  // and emailAddressAttributes objects onto the first attendee with that person id, and delete those keys from
  // subsequent attendess with the same person.

  const mergePersonAttributes = (attributes) => {
    let mergedAttributes = []
    Array.from(
      new Set(
        attributes.map(
          (att) => att.attributes.personAttributes.accountCenterId,
        ),
      ),
    ).map((pid) => {
      //loop through the attendees by uniq accountCenterId
      const personAttendees = attributes.filter(
        (att) => att.attributes?.personAttributes?.accountCenterId == pid,
      )

      if (pid === undefined) {
        // these are new people being created
        personAttendees.map((attendee) => {
          mergedAttributes.push(attendee)
        })
        return
      }

      const firstInstanceOfPerson = personAttendees[0]

      const mergedPersonAttributes = Object.assign(
        {},
        ...personAttendees.map((att) => att.attributes.personAttributes),
      )

      firstInstanceOfPerson.attributes.personAttributes = mergedPersonAttributes

      mergedAttributes.push(firstInstanceOfPerson)

      personAttendees
        .filter(
          (att) =>
            att.attributes.attendeeId !==
            firstInstanceOfPerson.attributes.attendeeId,
        )
        .map((attendee) => {
          // delete the keys from the subsequent attendees with the same accountCenterId
          delete attendee?.attributes?.personAttributes?.phoneNumbersAttributes
          delete attendee?.attributes?.personAttributes?.addressesAttributes
          delete attendee?.attributes?.personAttributes
            ?.emailAddressesAttributes
          mergedAttributes.push(attendee)
        })
    })

    return mergedAttributes
  }

  const handlePersonChange = (personId, changedAttendee) => {
    const person = _find(people, { id: Number(personId) }) || null

    const newAttendees = attendees.map((attendee) =>
      attendee.id === changedAttendee.id
        ? {
            ...attendee,
            person,
            isPersonModified: true,
          }
        : attendee,
    )

    if (!person) return setAttendees(newAttendees)

    const restrictionErrors = checkRestrictions({ ...changedAttendee, person })

    if (restrictionErrors.length > 0) {
      return setAttendeeErrors([...attendeeErrors, ...restrictionErrors])
    }

    setAttendeeErrors(attendeeErrors.filter((e) => e.id !== changedAttendee.id))
    setAttendees(newAttendees)
  }

  const handleEmergencyContactChange = (
    changedAttendee,
    emergencyContact,
    useContactForAllAttendees = false,
  ) => {
    let updatedAttendees = []

    if (useContactForAllAttendees) {
      updatedAttendees = attendees.map((attendee) => ({
        ...attendee,
        emergencyContact,
        isPersonModified: true,
      }))
    } else {
      updatedAttendees = attendees.map((attendee) =>
        attendee.id === changedAttendee.id
          ? {
              ...attendee,
              emergencyContact,
              isPersonModified: true,
            }
          : attendee,
      )
    }
    setTimeout((_) => setAttendees((_) => updatedAttendees), 15)
  }

  const handleApplyEmergencyContactToAll = (emergencyContact) => {
    const updatedAttendees = attendees.map((attendee) => {
      const collectEC = attendee.attendeeType.collectionOptions.emergencyContact
      return collectEC === "required" || collectEC === "optional"
        ? {
            ...attendee,
            emergencyContact,
            isPersonModified: true,
          }
        : attendee
    })
    setAttendees(updatedAttendees)
  }

  const handlePersonFieldChange = (changedAttendee, fieldName, fieldValue) => {
    setAttendeeErrors(
      attendeeErrors.filter((ae) =>
        ae.id === changedAttendee.id && ae.type === fieldName ? false : true,
      ),
    )

    setAttendees(
      attendees.map((attendee) =>
        attendee.id === changedAttendee.id
          ? {
              ...attendee,
              person: {
                ...attendee.person,
                [fieldName]: fieldValue,
              },
              isPersonModified: true,
            }
          : attendee,
      ),
    )
  }

  const standardAttendees = attendees.filter((a) => !a.waitlist)
  const waitlistAttendees = attendees.filter((a) => a.waitlist)

  return (
    <SectionLayout
      heading="Attendees"
      className={className}
      step={step}
      isButtonShowing={step.isEditable}
      onButtonClick={onEdit}
      error={error}
      isNextDisabled={isProcessing || !validateFields()}
      onSave={handleSave}
      onCancel={onCancel}
      expiresAt={reservation.expiresAt}
      isProcessing={isProcessing}
    >
      {step.isCurrent && session.isGuest && (
        <div className="info-alert alert mb-2">
          To more easily register family members who are already in your
          household,{" "}
          <Link to={`/login?return=${window.location.href}`}>log in</Link>
        </div>
      )}

      {standardAttendees.map((attendee) => (
        <AttendeesPersonSelect
          key={attendee.id}
          attendee={attendee}
          people={people}
          session={session}
          reservation={reservation}
          step={step}
          onPersonChange={handlePersonChange}
          onPersonFieldChange={handlePersonFieldChange}
          onEmergencyContactChange={handleEmergencyContactChange}
          onApplyEmergencyContactToAll={handleApplyEmergencyContactToAll}
          households={households}
          maxHouseholdSize={maxHouseholdSize}
          attendeeErrors={attendeeErrors}
        />
      ))}

      {waitlistAttendees.length > 0 && <Heading level={4} text="Waitlist" />}

      {waitlistAttendees.map((attendee) => (
        <AttendeesPersonSelect
          key={attendee.id}
          attendee={attendee}
          people={people}
          session={session}
          reservation={reservation}
          step={step}
          onPersonChange={handlePersonChange}
          onPersonFieldChange={handlePersonFieldChange}
          onEmergencyContactChange={handleEmergencyContactChange}
          households={households}
          maxHouseholdSize={maxHouseholdSize}
          attendeeErrors={attendeeErrors}
        />
      ))}
    </SectionLayout>
  )
}

Attendees.propTypes = reservationStepPropTypes

export default Attendees
