import { array, object } from "prop-types"

const QuestionCompletion = ({ requiredQuestions, questions, answers }) => {
  // filter answers down to only those pertaining the questions passed
  const questionIds = questions.map((question) => question.id)
  const answersToQuestions = Object.values(answers).filter((answer) =>
    questionIds.includes(answer.questionId),
  )

  return (
    <div className="c-tint2 fs-4">
      {requiredQuestions.length === 0
        ? `${answersToQuestions.length} / ${questions.length} questions answered`
        : "All required questions answered"}
    </div>
  )
}

QuestionCompletion.propTypes = {
  requiredQuestions: array,
  questions: array,
  answers: object,
}

export default QuestionCompletion
