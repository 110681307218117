import { questionShape } from "source/registrations/propTypes"
import { bool } from "prop-types"

const QuestionHeader = ({ question, removeContainer }) => {
  return (
    <div className={removeContainer ? "" : "mb-2"}>
      <strong>{question.question}</strong>
      <p className="fs-4 c-tint2 mb-1">{question.description}</p>
    </div>
  )
}

QuestionHeader.propTypes = {
  question: questionShape.isRequired,
  removeContainer: bool,
}

export default QuestionHeader
