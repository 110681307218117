import { Button } from "@planningcenter/doxy-web"
import { bool, func } from "prop-types"

const BUTTON_LABELS = {
  save: "Save",
  edit: "Edit",
  cancel: "Cancel",
  noEditPermission: "You do not have permission to edit this attendee.",
}

export const FormButtons = ({
  handleCancel,
  handleSave,
  handleEdit,
  canSave,
  canEdit,
  isEditing,
  isLoading,
}) => {
  const getButtonTitle = () => {
    if (canEdit) {
      return ""
    }

    return BUTTON_LABELS.noEditPermission
  }

  if (isEditing) {
    return (
      <div className={`d-f ai-c`}>
        <Button
          onClick={handleCancel}
          text={BUTTON_LABELS.cancel}
          size="sm"
          variant="naked"
          disabled={false}
        />
        <Button
          onClick={handleSave}
          text={BUTTON_LABELS.save}
          size="sm"
          disabled={!canSave}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <div title={getButtonTitle()} data-testid="edit-button">
      <Button
        onClick={handleEdit}
        text={BUTTON_LABELS.edit}
        size="sm"
        variant="outline"
        disabled={!canEdit}
      />
    </div>
  )
}

FormButtons.propTypes = {
  handleCancel: func,
  handleSave: func,
  handleEdit: func,
  canSave: bool,
  canEdit: bool,
  isEditing: bool,
  isLoading: bool,
}
