import PropTypes from "prop-types"
import { SET_ATTRIBUTES } from "../reducers/useResourceFormReducer"

LinkResource.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
}
export function LinkResource({ state, dispatch }) {
  return (
    <input
      id="link_resource"
      type="text"
      placeholder="URL to resource…"
      value={state.link}
      onChange={(e) =>
        dispatch({
          type: SET_ATTRIBUTES,
          payload: { link: e.target.value },
        })
      }
    />
  )
}
