import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, string, number } from "prop-types"
import ScheduleItem from "source/myChurchCenter/Schedule/ScheduleItem"

CalendarBookmark.propTypes = {
  all_day: bool,
  church_center_event_id: number,
  day: number,
  ends_at: string,
  event_name: string,
  starts_at: string,
  title: string,
  total_days: number,
}

const calenderScheduleItemContext = {
  icon: "churchCenter#bookmark",
  iconClass: "mr-4p fs-4 c-tint3",
  text: "Bookmarked",
}

function activityTitle({
  all_day,
  day,
  ends_at,
  event_name,
  starts_at,
  total_days,
}) {
  const isLastDay = total_days > 1 && day === total_days
  const isSingleDay = total_days === 1

  return all_day
    ? `${event_name} (all day)`
    : isLastDay
      ? `${event_name} (until ${formatTime(ends_at, { showTimeZone: false })})`
      : isSingleDay
        ? `${formatTime(starts_at, ends_at, { showTimeZone: false })} ${event_name}`
        : `${formatTime(starts_at, { showTimeZone: false })} ${event_name}`
}

export default function CalendarBookmark({
  church_center_event_id,
  ...activity
}) {
  return (
    <ScheduleItem
      linkTitle={activityTitle(activity)}
      linkUrl={`/calendar/event/${church_center_event_id}`}
      itemContext={calenderScheduleItemContext}
    />
  )
}
