import { Link } from "react-router-dom"
import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, string, number } from "prop-types"
import { Icon } from "source/shared/components"

CalendarBookmark.propTypes = {
  all_day: bool,
  church_center_event_id: number,
  day: number,
  ends_at: string,
  event_name: string,
  starts_at: string,
  title: string,
  total_days: number,
}

function CalendarBookmark({ church_center_event_id, ...activity }) {
  return (
    <div className="mb-2">
      <Link
        to={`/calendar/event/${church_center_event_id}`}
        className="c-brand fw-500"
      >
        {activityTitle(activity)}
      </Link>
      <div className="d-f ai-c fs-4 c-tint2">
        <Icon symbol="churchCenter#bookmark" className="c-tint3 mr-4p" />
        Bookmarked
      </div>
    </div>
  )
}

function activityTitle({
  all_day,
  day,
  ends_at,
  event_name,
  starts_at,
  total_days,
}) {
  const isLastDay = total_days > 1 && day === total_days
  const isSingleDay = total_days === 1

  return all_day
    ? `${event_name} (all day)`
    : isLastDay
      ? `${event_name} (until ${formatTime(ends_at, { showTimeZone: false })})`
      : isSingleDay
        ? `${formatTime(starts_at, ends_at, { showTimeZone: false })} ${event_name}`
        : `${formatTime(starts_at, { showTimeZone: false })} ${event_name}`
}

export default CalendarBookmark
