import { useMemo } from "react"
import { useParams } from "react-router-dom"
import {
  SessionApiResource,
  useApiRead,
} from "source/shared/SessionApiResource"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"

export function useFirstPageOfLeaders() {
  const { groupTypeSlug, groupSlug } = useParams()

  return useApiRead(
    `/groups/v2/group_types/${groupTypeSlug}/groups/${groupSlug}/memberships?where[role]=leader`,
  )
}

export function useMyMembership() {
  const myMembershipUrl = useMyMembershipUrl()

  return useApiRead(myMembershipUrl)
}

export function useReloadMyMembership() {
  const myMembershipUrl = useMyMembershipUrl()

  return useCacheHydrate(SessionApiResource, myMembershipUrl)
}

function useMyMembershipUrl() {
  const { groupTypeSlug, groupSlug } = useParams()

  return useMemo(
    () =>
      `/groups/v2/group_types/${groupTypeSlug}/groups/${groupSlug}/my_membership`,
    [groupTypeSlug, groupSlug],
  )
}
