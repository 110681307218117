import type { Error, FormField, ResourceFieldValue } from "./types"

import CustomField from "./CustomField"
import ResourceCheckboxesField from "./resource/ResourceCheckboxesField"
import ResourceRadioField from "./resource/ResourceRadioField"

type MultipleOptions = {
  selected?: ResourceFieldValue[]
}

type SingleOption = ResourceFieldValue

type ResourceFieldProps = {
  errors: Error[]
  field: FormField
  onChange: (value: MultipleOptions | SingleOption, id: string) => void
}

export default function ResourceField({
  errors,
  field,
  onChange,
}: ResourceFieldProps) {
  const {
    attributes: {
      description,
      label,
      required,
      settings: { multiple_selections },
    },
    id,
    options,
  } = field

  return (
    <CustomField {...{ description, errors, label, required }}>
      {multiple_selections ? (
        <ResourceCheckboxesField {...{ id, onChange, options }} />
      ) : (
        <ResourceRadioField {...{ id, onChange, options }} />
      )}
    </CustomField>
  )
}
