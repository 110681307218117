import { Link } from "react-router-dom"
import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, number, string } from "prop-types"
import { Icon } from "source/shared/components"

Blockout.propTypes = {
  blockout_id: number,
  blockout_date_id: number,
  reason: string,
  is_all_day: bool,
  starts_at: string,
  ends_at: string,
  day: number,
  total_days: number,
}

export default function Blockout({
  is_all_day,
  blockout_id,
  blockout_date_id,
  day,
  total_days,
  reason,
  starts_at,
  ends_at,
}) {
  const isLastDay = total_days > 1 && day === total_days
  const title = is_all_day
    ? "Blockout (all day)"
    : isLastDay
      ? `Blockout (until ${formatTime(ends_at, { showTimeZone: false })})`
      : `${formatTime(starts_at, ends_at, { showTimeZone: false })} Blockout`

  return (
    <div className="mb-2">
      <Link
        to={`/services/blockouts/${blockout_id}/dates/${blockout_date_id}`}
        state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
        className="d-if c-brand fw-500"
      >
        <Icon
          symbol="general#thick-x"
          className="mr-4p mt-4p c-ruby p-r t-1p fs-4"
        />
        <div>{title}</div>
      </Link>
      <div className="c-tint2">{reason}</div>
    </div>
  )
}
