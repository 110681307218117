import PropTypes from "prop-types"
import { useSession } from "source/shared/hooks/useSession"
import { Link, useLocation } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import MyRsvpForm from "source/groups/my/groups/events/MyRsvpForm"
import { Heading } from "@planningcenter/doxy-web"

export default function EncryptedRsvpsShow() {
  const location = useLocation()

  const { data: currentPerson } = useSession()
  const { rsvp } = location.state || {}

  return (
    <>
      <div className="mb-2">
        <Heading level={1} text="Thanks for your RSVP!" />
      </div>

      {currentPerson.id ? (
        <LoggedInDisplay rsvp={rsvp} />
      ) : (
        <LoggedOutDisplay rsvp={rsvp} />
      )}
    </>
  )
}

function LoggedOutDisplay({ rsvp }) {
  if (!rsvp) {
    return (
      <Link to={"/login"} className="btn">
        Log In
      </Link>
    )
  }

  return (
    <Link
      to={`/login?return=${window.location.origin}${rsvp.attributes.church_center_event_path}`}
      className="btn"
    >
      Go To Event
    </Link>
  )
}
LoggedOutDisplay.propTypes = {
  rsvp: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      church_center_event_path: PropTypes.string,
    }),
  }),
}

function LoggedInDisplay({ rsvp }) {
  return (
    <>
      {rsvp ? (
        <CurrentUser rsvp={rsvp} />
      ) : (
        <Link to={"/groups"} className="btn">
          Go To Groups
        </Link>
      )}
    </>
  )
}
LoggedInDisplay.propTypes = {
  rsvp: PropTypes.object,
}

function CurrentUser({ rsvp }) {
  const eventId = rsvp.relationships.event.data.id
  const eventPath = rsvp.attributes.church_center_event_path
  const groupId = rsvp.relationships.group.data.id
  const eventApiPath = `/groups/v2/me/groups/${groupId}/events/${eventId}`

  const { data: rsvpResponseAttributes } = useApiRead(
    `/groups/v2/me/groups/${groupId}/rsvp_responses`,
  )

  const massagedData = {
    attributes: {
      id: rsvp.id,
      ...rsvp.attributes,
    },
    links: {
      self: `${eventApiPath}/rsvps/${rsvp.id}`,
    },
  }

  return (
    <>
      <MyRsvpForm
        rsvp={massagedData}
        rsvpResponseAttributes={rsvpResponseAttributes}
        eventApiPath={eventApiPath}
      />
      <Link to={eventPath} className="btn">
        Go To Event
      </Link>
    </>
  )
}
CurrentUser.propTypes = {
  rsvp: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      church_center_event_path: PropTypes.string,
    }),
    relationships: PropTypes.shape({
      event: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
  }),
}
