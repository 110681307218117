import moment from "moment"
import { buildUrl } from "@planningcenter/cc-url"
import { myRegistrationEventShape } from "source/registrations/hooks/useMyRegistrations"

import { Icon } from "source/shared/components"
import PlaceholderDate from "./PlaceholderDate"

const EventImagePlaceholder = ({ event }) => {
  const startsAt = event.startsAt ? moment(event.startsAt) : null
  const endsAt = event.endsAt ? moment(event.endsAt) : null
  const hasDates = startsAt && endsAt

  return (
    <div className="profile-item__placeholder">
      <div className="event-placeholder">
        <span className="event-placeholder__dates">
          {hasDates ? (
            startsAt.date() === endsAt.date() ? (
              <PlaceholderDate date={startsAt} />
            ) : (
              <div className="d-f ai-c jc-c">
                <PlaceholderDate date={startsAt} />
                <div className="event-placeholder-arrow">
                  <Icon symbol="general#right-arrow" />
                </div>
                <PlaceholderDate date={endsAt} />
              </div>
            )
          ) : null}
        </span>
        <img
          alt="Event logo with dates"
          src={buildUrl("/static/no-event-image.png")}
        />
      </div>
    </div>
  )
}

EventImagePlaceholder.propTypes = {
  event: myRegistrationEventShape,
}

export default EventImagePlaceholder
