import { string } from "prop-types"

Avatar.propTypes = {
  url: string.isRequired,
  alt: string,
}

export function Avatar({ url, alt = "avatar" }) {
  return (
    <img
      src={`${url}?background_color=EEE&color=9E9E9E`}
      alt={alt}
      loading="lazy"
      style={{
        borderRadius: "50%",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
  )
}
