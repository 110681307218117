import { node, string } from "prop-types"
import { css } from "@emotion/react"

CardGrid.propTypes = {
  as: string,
  children: node.isRequired,
}

export function CardGrid({ as = "section", children }) {
  const Wrapper = as
  return <Wrapper css={styles.wrapper}>{children}</Wrapper>
}

const styles = {
  wrapper: css`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
    gap: 24px;
  `,
}
