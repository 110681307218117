import { useContext, useState } from "react"
import { Link, Navigate, useParams } from "react-router-dom"
import { bool, id } from "source/shared/prop_types"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useApiRead } from "source/shared/SessionApiResource"
import TabView from "source/groups/my/groups/TabView"
import { Heading } from "@planningcenter/doxy-web"
import { ActionBar } from "source/shared/components"
import PersonInfo from "source/groups/my/groups/PersonInfo"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { MemberAttendances } from "source/groups/my/groups/members/MemberAttendances"

function memberApiUrl(group_id, member_id) {
  return `/groups/v2/me/groups/${group_id}/memberships/${member_id}`
}

export default function MemberShow() {
  const { memberId } = useParams()

  return (
    <TabView>
      <LoadedMember id={memberId} />
    </TabView>
  )
}

function LoadedMember({ id }) {
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(memberApiUrl(group.id, id))

  if (
    response.errors &&
    response.errors.some(({ status }) => status === "404")
  ) {
    return <Navigate to={`${group.base_path}/members`} replace />
  }

  const member = response.data
  const {
    avatar_url,
    email_address,
    first_name,
    group_permissions: { attendance_taker },
    last_name,
    joined_at,
    phone_number,
    role,
    abilities,
  } = member.attributes
  const name = `${first_name} ${last_name}`

  return (
    <article className="d-f fd-c g-3">
      <PersonInfo
        name={name}
        role={role}
        emailAddress={email_address}
        phoneNumber={phone_number}
        joinedAt={joined_at}
      >
        <PersonInfo.HeaderRow>
          <PersonInfo.Avatar url={avatar_url} />
          <PersonInfo.HeaderColumn>
            <PersonInfo.Name />
            <PersonInfo.Subtitle>
              <PersonInfo.JoinedAt />
              <PersonInfo.Leader />
            </PersonInfo.Subtitle>
          </PersonInfo.HeaderColumn>
        </PersonInfo.HeaderRow>
        <PersonInfo.Contact>
          <PersonInfo.EmailAddress />
          <PersonInfo.PhoneNumber />
        </PersonInfo.Contact>
      </PersonInfo>
      {abilities?.attendances?.can_index && (
        <MemberAttendances
          groupId={group.id}
          personId={member.relationships.person.data.id}
          joinedAt={joined_at}
        />
      )}
      {abilities.can_delete && (
        <Permissions
          canManageAttendanceTaker={
            abilities.permissions.attendance_taker.can_manage
          }
          isAttendanceTaker={attendance_taker}
        />
      )}
    </article>
  )
}
LoadedMember.propTypes = {
  id: id.isRequired,
}

function Permissions({ canManageAttendanceTaker, isAttendanceTaker }) {
  return (
    <section className="d-f fd-c g-1">
      <Heading level={2} size={3} text="Permissions" />
      {canManageAttendanceTaker && (
        <AttendanceTakerPermission isAttendanceTaker={isAttendanceTaker} />
      )}
      <RemoveMemberPermission />
    </section>
  )
}
Permissions.propTypes = {
  canManageAttendanceTaker: bool.isRequired,
  isAttendanceTaker: bool.isRequired,
}

function RemoveMemberPermission() {
  return (
    <ActionBar.Pane>
      <ActionBar.Column>
        <ActionBar.Description>Remove member from group</ActionBar.Description>
      </ActionBar.Column>
      <ActionBar.Action>
        <Link to="remove" className="btn secondary-btn minor-btn destroy-btn">
          Remove...
        </Link>
      </ActionBar.Action>
    </ActionBar.Pane>
  )
}

function AttendanceTakerPermission({ isAttendanceTaker }) {
  const [canTakeAttendance, setCanTakeAttendance] = useState(isAttendanceTaker)
  const [inFlight, setInFlight] = useState(false)
  const { memberId } = useParams()
  const group = useContext(CurrentGroupContext)

  return (
    <ActionBar.Pane>
      <ActionBar.Column>
        <div className="d-f as-c as-fs@xs">
          <ActionBar.Title>Event attendance</ActionBar.Title>
        </div>
        <ActionBar.Description>
          This setting grants access to the attendance form where they can take
          attendance, track visitors, and add a note. They will not be able to
          add members or manage pending membership requests.
        </ActionBar.Description>
        <div className="d-f p-r mt-2 as-c as-fs@xs">
          <input
            type="checkbox"
            id="takeAttendenceCheckbox"
            className="checkbox"
            checked={canTakeAttendance}
            disabled={inFlight}
            onChange={(e) => {
              const newValue = e.target.checked
              setCanTakeAttendance(newValue)
              setInFlight(true)

              return sessionApiClient
                .post(`${memberApiUrl(group.id, memberId)}/set_permission`, {
                  data: { attributes: { attendance_taker: newValue } },
                })
                .then((response) => {
                  const newAttendanceTaker =
                    response.data.attributes.group_permissions.attendance_taker
                  setCanTakeAttendance(newAttendanceTaker)
                })
                .catch(() => setCanTakeAttendance(!canTakeAttendance))
                .finally(() => setInFlight(false))
            }}
          />
          <label
            htmlFor="takeAttendenceCheckbox"
            className="checkbox-label c-tint2"
          >
            Can take attendance
          </label>
        </div>
      </ActionBar.Column>
    </ActionBar.Pane>
  )
}
AttendanceTakerPermission.propTypes = {
  isAttendanceTaker: bool.isRequired,
}
