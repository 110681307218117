import { createContext, Suspense, useContext, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { node } from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useApiRead } from "source/shared/SessionApiResource"
import TabView from "source/groups/my/groups/TabView"
import { Loading, Spinner } from "source/shared/components"
import { css } from "@emotion/react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"

function memberApiUrl(group_id, member_id) {
  return `/groups/v2/me/groups/${group_id}/memberships/${member_id}`
}

export default function MemberRemove() {
  return (
    <TabView>
      <div className="d-f jc-sb mt-1 mb-2">
        <Heading level={1} size={3} text="Remove member" />
      </div>
      <Suspense fallback={<Loading />}>
        <FormProvider>
          <Confirmation />
          <Buttons />
        </FormProvider>
      </Suspense>
    </TabView>
  )
}

const FormContext = createContext()

function FormProvider({ children }) {
  const { memberId } = useParams()
  const [notify, setNotify] = useState(false)
  const toggleNotify = () => setNotify((prevState) => !prevState)
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(memberApiUrl(group.id, memberId))

  if (
    response.errors &&
    response.errors.some(({ status }) => status === "404")
  ) {
    return <Navigate to={`${group.base_path}/members/${memberId}`} replace />
  }

  const member = response.data
  const { abilities, email_address, first_name, last_name } = member.attributes
  const name = `${first_name} ${last_name}`

  return abilities.can_delete ? (
    <FormContext.Provider
      value={{ notify, toggleNotify, emailAddress: email_address, name }}
    >
      {children}
    </FormContext.Provider>
  ) : (
    <Navigate to={`${group.base_path}/members/${memberId}`} replace />
  )
}
FormProvider.propTypes = {
  children: node.isRequired,
}

function Confirmation() {
  const group = useContext(CurrentGroupContext)
  const { name, emailAddress } = useContext(FormContext)
  return (
    <article className="mb-2 action-drawer">
      <div className="mb-2">
        Are you sure you want to remove {name} from {group.name}?
      </div>
      {emailAddress && <NotifyByEmail />}
    </article>
  )
}

function NotifyByEmail() {
  const { emailAddress, name, notify, toggleNotify } = useContext(FormContext)
  const inputId = "notify-by-email"
  const styles = {
    checkboxLabel: css`
      &:before {
        top: calc(50% - 12px);
      }
      &:after {
        top: calc(50% - 9px);
      }
    `,
  }
  return (
    <div>
      <input
        type="checkbox"
        id={inputId}
        className="checkbox"
        checked={notify}
        onChange={toggleNotify}
      />
      <label
        htmlFor={inputId}
        className="checkbox-label"
        css={styles.checkboxLabel}
      >
        Notify {name} by email
        <div>({emailAddress})</div>
      </label>
    </div>
  )
}

function Buttons() {
  const { memberId } = useParams()
  const { notify } = useContext(FormContext)
  const group = useContext(CurrentGroupContext)
  const [inProgress, setInProgress] = useState(false)
  const navigate = useNavigate()

  return (
    <div className="d-f jc-c jc-fe@sm mb-2">
      <button
        className="btn compact-btn text-btn mr-2"
        onClick={() => navigate(`${group.base_path}/members/${memberId}`)}
      >
        Cancel
      </button>
      <button
        className={
          inProgress
            ? "btn compact-btn destroy-outline-btn--loading"
            : "btn compact-btn destroy-outline-btn"
        }
        disabled={inProgress}
        onClick={() => {
          setInProgress(true)
          return sessionApiClient
            .del(memberApiUrl(group.id, memberId), {
              notify_member: notify,
            })
            .then(() => navigate(`${group.base_path}/members`))
            .catch((response) => {
              if (
                response.errors &&
                response.errors.some(({ status }) => status === "404")
              ) {
                navigate(`${group.base_path}/members`)
              }
              setInProgress(false)
            })
        }}
      >
        <Spinner alt="Removing" opacity={inProgress ? 1 : 0} />
        <span style={{ opacity: inProgress ? 0 : 1 }}>Yes, remove</span>
      </button>
    </div>
  )
}
