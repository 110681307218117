import { useMemo } from "react"
import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, string, arrayOf, number } from "prop-types"
import ScheduleItem from "source/myChurchCenter/Schedule/ScheduleItem"

Attendee.propTypes = {
  ends_at: string,
  all_day: bool,
  registration_completion_status: string,
  registration_id: number,
  selection_name: string,
  selections: arrayOf({
    selection_name: string,
  }),
  signup_name: string,
  starts_at: string,
  day: number,
  total_days: number,
}

export default function Attendee({
  ends_at,
  all_day,
  registration_completion_status,
  registration_id,
  selections,
  signup_name,
  starts_at,
  day,
  total_days,
}) {
  const isLastDay = total_days > 1 && day === total_days
  const isFirstDay = total_days > 1 && day === 1

  const activityRecordTitle = useMemo(() => {
    if (all_day) {
      return `${signup_name} (all day)`
    }
    if (isFirstDay) {
      return `${formatTime(starts_at, { showTimeZone: false })} ${signup_name}`
    }
    if (isLastDay) {
      return `${signup_name} (until ${formatTime(ends_at, {
        showTimeZone: false,
      })})`
    }
    return `${formatTime(starts_at, ends_at, {
      showTimeZone: false,
    })} ${signup_name}`
  }, [signup_name, all_day, isFirstDay, isLastDay, ends_at, starts_at])

  const scheduleItemContext = selections.map((selection) => {
    return {
      icon:
        registration_completion_status === "incomplete" &&
        "general#exclamation-triangle",
      iconClass: "c-citrine mr-4p",
      text: selection.selection_name,
    }
  })

  return (
    <ScheduleItem
      linkTitle={activityRecordTitle}
      linkUrl={`/registrations/registrations/${registration_id}`}
      itemDescription="Signup"
      itemContext={scheduleItemContext}
    />
  )
}
