import { css } from "@emotion/react"
import {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  objectOf,
  shape,
  string,
} from "prop-types"
import { useNavigate, useParams } from "react-router-dom"
import { buildSubdomain } from "@planningcenter/cc-url"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Icon, DateSelector } from "source/shared/components"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { Heading } from "@planningcenter/doxy-web"
import { GenderSelect } from "./GenderSelect"
import { colors } from "source/shared/colors"
import { WebBootContext } from "source/publishing/WebBoot"
import { Address } from "./FormElements/Address"
import { GradeAndSchool } from "./FormElements/GradeAndSchool"
import { LocationSelect } from "./FormElements/LocationSelect"

const NEW_RECORD_PREFIX = "new_record_"

function isNewRecord(record) {
  return record.id.startsWith(NEW_RECORD_PREFIX)
}

function sameRecord(a, b) {
  return a.type === b.type && a.id === b.id
}

function typeFilter(type) {
  return (record) => record.type === type
}

function editContactAndInfoReducer(state, action) {
  switch (action.type) {
    case "UPDATE_ATTRIBUTES":
      return state.map((record) => {
        if (sameRecord(record, action.record)) {
          return {
            ...record,
            attributes: { ...record.attributes, ...action.attributes },
          }
        } else {
          return record
        }
      })
    case "NEW_RECORD":
      return [...state, action.data]
    case "MARK_FOR_DELETE": {
      if (isNewRecord(action.record))
        return state.filter((record) => record !== action.record)
      return state.map((record) => {
        if (sameRecord(action.record, record)) {
          return { ...record, deleting: true }
        } else {
          return record
        }
      })
    }
    case "UNMARK_FOR_DELETE":
      return state.map((record) => {
        if (sameRecord(record, action.record)) {
          const { deleting, ...nextRecord } = record
          return nextRecord
        } else {
          return record
        }
      })
    case "MARK_PRIMARY":
      return state.map((record) => {
        if (record.type === action.record.type) {
          const isPrimary = sameRecord(record, action.record)
          return {
            ...record,
            attributes: { ...record.attributes, ...{ primary: isPrimary } },
          }
        } else {
          return record
        }
      })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const apiDataPropType = shape({
  attributes: object.isRequired,
  id: string.isRequired,
  type: string.isRequired,
})

const editableOptionsCollection = shape({
  schoolOptions: arrayOf(apiDataPropType).isRequired,
  maritalStatusOptions: arrayOf(apiDataPropType).isRequired,
})

EditContactAndInfo.propTypes = {
  addressCountries: array,
  canEditContactInfo: bool.isRequired,
  currentPersonId: string,
  editableOptions: objectOf(editableOptionsCollection).isRequired,
  isChild: bool.isRequired,
  onDiscard: func.isRequired,
  onSave: func.isRequired,
  personId: string.isRequired,
  records: arrayOf(apiDataPropType).isRequired,
}

export function EditContactAndInfo({
  editableOptions,
  isChild,
  canEditContactInfo,
  onDiscard,
  onSave,
  personId,
  records,
  addressCountries = [],
  currentPersonId = "",
}) {
  const [state, dispatch] = useReducer(editContactAndInfoReducer, [...records])
  const [saving, setSaving] = useState(false)
  const newRecordIdentifier = useRef(0)
  const { layout } = useContext(ServerRenderedProps)
  const { organization_contact_email: email } = layout
  const {
    abilities: {
      people: {
        add_or_remove_household_members: addOrRemoveHouseholdMembersEnabled,
      },
    },
    currentOrganization: {
      attributes: { country_code },
    },
  } = useContext(WebBootContext)

  const navigate = useNavigate()
  const { householdId, householdMemberId } = useParams()

  function generateNewRecordIdentifier() {
    return `${NEW_RECORD_PREFIX}${newRecordIdentifier.current++}`
  }

  function removeHouseholdMember() {
    sessionApiClient
      .del(
        `/people/v2/people/${currentPersonId}/households/${householdId}/household_members/${householdMemberId}`,
      )
      .then(() => navigate(`/households/${householdId}`))
      .catch(() =>
        alert("An error occured. Please reload the page and try again"),
      )
  }

  MaritalStatusSelect.propTypes = {
    additionalOptions: arrayOf(apiDataPropType),
    onChange: func.isRequired,
    value: string.isRequired,
  }

  function MaritalStatusSelect({ value, onChange, additionalOptions }) {
    return (
      <>
        <label htmlFor="marital_status">Marital status</label>
        <select
          className="select"
          id="marital_status"
          name="marital_status"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <option key="0" value="">
            Select...
          </option>
          {additionalOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.attributes.value}
            </option>
          ))}
        </select>
      </>
    )
  }

  PrimarySelect.propTypes = {
    additionalOptions: array,
    disabled: bool,
    id: string.isRequired,
    label: string.isRequired,
    onChange: func.isRequired,
    options: array,
    value: string.isRequired,
  }

  function PrimarySelect({
    id,
    label,
    value,
    onChange,
    disabled = false,
    options = [],
  }) {
    id = `primary_select_${id}`

    return (
      <>
        <label className="f-1 mt-1 mb-1 pb-0 mb-0@sm" htmlFor={id}>
          <span className="d-b mb-1">Primary {label}</span>
        </label>
        <select
          id={id}
          disabled={disabled}
          className="select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onChange(e.target.value)}
        >
          {options.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </>
    )
  }

  function updateAttribute(record, attribute, value) {
    dispatch({
      type: "UPDATE_ATTRIBUTES",
      record,
      attributes: { [attribute]: value },
    })
  }

  function updateAttributes(record, attributes) {
    dispatch({
      type: "UPDATE_ATTRIBUTES",
      record,
      attributes,
    })
  }

  function createRecord(record) {
    const url = record.links.self
    const pathname = url.replace(buildSubdomain("api"), "")
    const { type, attributes } = record
    return sessionApiClient.post(pathname, { data: { type, attributes } })
  }

  function deleteRecord(record) {
    const url = record.links.self
    const pathname = url.replace(buildSubdomain("api"), "")
    return sessionApiClient.del(pathname)
  }

  function updateRecord(record) {
    const nextAttributes = record.attributes
    const prevAttributes = records.find((r) => sameRecord(r, record)).attributes

    let changeSet = Object.keys(nextAttributes).reduce(
      (changes, key) =>
        nextAttributes[key] !== prevAttributes[key]
          ? { ...changes, [key]: nextAttributes[key] }
          : changes,
      {},
    )

    if (record.deleteValueAttributeForPatch) delete changeSet.value

    if (record.type === "SchoolOption") {
      changeSet = { school_id: changeSet.school_id }
    }

    const url = record.links.self
    const pathname = url.replace(buildSubdomain("api"), "")
    return sessionApiClient.patch(pathname, {
      data: { attributes: changeSet },
    })
  }

  function saveChanges() {
    setSaving(true)
    const recordsToCreate = state.filter(isNewRecord)
    const recordsToDelete = state.filter((record) => record.deleting)
    const recordsToUpdate = state.filter((recordInState) => {
      if (recordsToCreate.includes(recordInState)) return false
      if (recordsToDelete.includes(recordInState)) return false
      return records.some(
        (recordInProps) =>
          sameRecord(recordInState, recordInProps) &&
          recordInProps.attributes !== recordInState.attributes,
      )
    })
    const creating = recordsToCreate.map(createRecord)
    const deleting = recordsToDelete.map(deleteRecord)
    const updating = recordsToUpdate.map(updateRecord)
    Promise.all([...creating, ...deleting, ...updating]).then(() => onSave())
  }

  function addEmail() {
    dispatch({
      type: "NEW_RECORD",
      data: {
        id: generateNewRecordIdentifier(),
        type: "Email",
        attributes: {
          location: "Home",
          address: "",
        },
        links: {
          self: `/people/v2/people/${personId}/emails`,
        },
      },
    })
  }

  function addPhoneNumber() {
    dispatch({
      type: "NEW_RECORD",
      data: {
        id: generateNewRecordIdentifier(),
        type: "PhoneNumber",
        attributes: {
          location: "Mobile",
          number: "",
        },
        links: {
          self: `/people/v2/people/${personId}/phone_numbers`,
        },
      },
    })
  }

  function addAddress() {
    dispatch({
      type: "NEW_RECORD",
      data: {
        id: generateNewRecordIdentifier(),
        type: "Address",
        attributes: {
          location: "Home",
          street: "",
          city: "",
          state: "",
          zip: "",
          country_code,
        },
        links: {
          self: `/people/v2/people/${personId}/addresses`,
        },
      },
    })
  }

  function markPrimary(record) {
    dispatch({ type: "MARK_PRIMARY", record })
  }

  function unmarkForDelete(record) {
    dispatch({ type: "UNMARK_FOR_DELETE", record })
  }

  function markForDelete(record) {
    dispatch({ type: "MARK_FOR_DELETE", record })
  }

  function domId(record, suffix = "") {
    return `${record.type}_${record.id}_${suffix}`
  }

  const getPrimaryRecord = (list) =>
    list.find(
      (record) =>
        record.attributes.primary === true && record.deleting !== true,
    ) || list.find((record) => !record.deleting)

  const addresses = state.filter(typeFilter("Address"))
  const anniversary = state.find(typeFilter("Anniversary"))
  const birthdate = state.find(typeFilter("Birthdate"))
  const emails = state.filter(typeFilter("Email"))
  const gender = state.find(typeFilter("Gender"))
  const grade = state.find(typeFilter("Grade"))
  const graduationYear = state.find(typeFilter("Graduation_Year"))
  const maritalStatus = state.find(typeFilter("MaritalStatus"))
  const medicalNotes = state.find(typeFilter("Medical_Notes"))
  const phoneNumbers = state.filter(typeFilter("PhoneNumber"))
  const school = state.find(typeFilter("SchoolOption"))
  const { maritalStatusOptions, schoolOptions } = editableOptions

  const [disableGraduationYear, setDisableGraduationYear] = useState(false)
  const [disableGrade, setDisableGrade] = useState(false)

  useEffect(() => {
    if (graduationYear.attributes.graduation_year)
      setDisableGraduationYear(true)
    if (Number.isInteger(grade.attributes.grade)) setDisableGrade(true)
  }, [])

  const primaryEmail = getPrimaryRecord(emails)?.attributes.address || ""
  const primaryPhoneNumber =
    getPrimaryRecord(phoneNumbers)?.attributes.number || ""

  return (
    <Fragment>
      <Heading level={2} size={3} text="Contact Information" />
      {!canEditContactInfo && (
        <div style={{ backgroundColor: colors.tint8, padding: "10px" }}>
          <Icon symbol="general#exclamation-triangle" />{" "}
          <span>
            This person has administrative rights in Planning Center. Because of
            this, only they can edit their contact info.
          </span>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          saveChanges()
        }}
      >
        <div className="action-drawer mb-1 mt-2">
          <div>
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-1">
                <Icon symbol="general#outlined-envelope" />
              </span>
              <Heading level={3} size={4} text="Email" />
            </div>
            {emails.map((email) => (
              <div css={styles.editingFieldContainer} key={email.id}>
                {email.deleting ? (
                  <Fragment>
                    <div className="c-tint3 fs-5">
                      {email.attributes.location}
                    </div>
                    <div>
                      <strike
                        aria-label={`email address to be deleted: ${email.attributes.address}`}
                      >
                        {email.attributes.address}
                      </strike>
                      <button
                        type="button"
                        disabled={saving}
                        className="fw-500 ml-1 c-topaz stripped-btn p-0"
                        aria-label={`restore email address: ${email.attributes.address}`}
                        onClick={() => unmarkForDelete(email)}
                      >
                        Restore
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="d-f@sm ai-c">
                      <LocationSelect
                        disabled={!canEditContactInfo || saving}
                        id={domId(email)}
                        value={email.attributes.location}
                        onChange={(value) =>
                          updateAttribute(email, "location", value)
                        }
                      />
                      <div className="f-1 pl-1@sm">
                        <label htmlFor={domId(email, "address")}>
                          <span className="screen-reader-text">Email</span>
                          <input
                            id={domId(email, "address")}
                            autoFocus={isNewRecord(email)}
                            disabled={!canEditContactInfo || saving}
                            inputMode="email"
                            onChange={(e) =>
                              updateAttribute(email, "address", e.target.value)
                            }
                            pattern="^\S+@\S+\.\S+$"
                            placeholder="name@email.com"
                            type="text"
                            value={email.attributes.address}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-f ai-fb jc-fe">
                      <button
                        type="button"
                        disabled={!canEditContactInfo || saving}
                        className="destroy-btn minor-compact-btn btn"
                        aria-label={`mark email for deletion: ${email.attributes.address}`}
                        onClick={() => markForDelete(email)}
                      >
                        <span className="mr-4p fs-6" aria-hidden="true">
                          <Icon symbol="general#x" />
                        </span>
                        Delete
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            ))}
          </div>
          <div className="ta-r pt-2">
            <button
              disabled={!canEditContactInfo || saving}
              className="btn secondary-btn minor-btn"
              type="button"
              onClick={addEmail}
            >
              <span
                className="mr-4p"
                style={{ fontSize: "9px" }}
                aria-hidden="true"
              >
                <Icon symbol="general#plus" />
              </span>
              Add email address
            </button>
          </div>
          {emails.filter((address) => !address.deleting).length > 0 && (
            <PrimarySelect
              id="email"
              disabled={!canEditContactInfo || saving}
              label="Email"
              value={primaryEmail}
              options={emails.map((email) => email.attributes.address)}
              onChange={(value) =>
                markPrimary(
                  emails.find((email) => email.attributes.address === value),
                )
              }
            />
          )}
        </div>

        <div className="action-drawer mb-1">
          <div>
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-1">
                <Icon symbol="general#mobile-phone" />
              </span>
              <Heading level={3} size={4} text="Phone" />
            </div>
            {phoneNumbers.map((phoneNumber) => (
              <div css={styles.editingFieldContainer} key={phoneNumber.id}>
                {phoneNumber.deleting ? (
                  <Fragment>
                    <div className="c-tint3 fs-5">
                      {phoneNumber.attributes.location}
                    </div>
                    <div>
                      <strike
                        aria-label={`phone number to be deleted: ${phoneNumber.attributes.number}`}
                      >
                        {phoneNumber.attributes.number}
                      </strike>
                      <button
                        type="button"
                        disabled={!canEditContactInfo || saving}
                        className="fw-500 ml-1 c-topaz stripped-btn p-0"
                        aria-label={`restore phone number: ${phoneNumber.attributes.number}`}
                        onClick={() => unmarkForDelete(phoneNumber)}
                      >
                        Restore
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="d-f@sm ai-c">
                      <LocationSelect
                        disabled={!canEditContactInfo || saving}
                        id={domId(phoneNumber)}
                        value={phoneNumber.attributes.location}
                        additionalOptions={[
                          "Mobile",
                          phoneNumber.attributes.location,
                        ]}
                        onChange={(value) =>
                          updateAttribute(phoneNumber, "location", value)
                        }
                      />
                      <div className="f-1 pl-1@sm">
                        <label htmlFor={domId(phoneNumber, "number")}>
                          <span className="screen-reader-text">
                            Phone number
                          </span>
                          <input
                            id={domId(phoneNumber, "number")}
                            autoFocus={isNewRecord(phoneNumber)}
                            disabled={!canEditContactInfo || saving}
                            inputMode="number"
                            onChange={(e) =>
                              updateAttribute(
                                phoneNumber,
                                "number",
                                e.target.value,
                              )
                            }
                            placeholder="888-555-1212"
                            required
                            type="text"
                            value={phoneNumber.attributes.number}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-f ai-fb jc-fe">
                      <button
                        type="button"
                        disabled={!canEditContactInfo || saving}
                        className="destroy-btn minor-compact-btn btn"
                        aria-label={`mark phone number for deletion: ${phoneNumber.attributes.number}`}
                        onClick={() => markForDelete(phoneNumber)}
                      >
                        <span className="mr-4p fs-6" aria-hidden="true">
                          <Icon symbol="general#x" />
                        </span>
                        Delete
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            ))}
          </div>
          <div className="ta-r pt-2">
            <button
              className="btn secondary-btn minor-btn"
              disabled={!canEditContactInfo || saving}
              type="button"
              onClick={addPhoneNumber}
            >
              <span
                className="mr-4p"
                style={{ fontSize: "9px" }}
                aria-hidden="true"
              >
                <Icon symbol="general#plus" />
              </span>
              Add phone number
            </button>
          </div>
          {phoneNumbers.filter((number) => !number.deleting).length > 0 && (
            <PrimarySelect
              disabled={!canEditContactInfo || saving}
              id="phone_numbers"
              label="Phone Number"
              value={primaryPhoneNumber}
              options={phoneNumbers.map((number) => number.attributes.number)}
              onChange={(value) =>
                markPrimary(
                  phoneNumbers.find(
                    (number) => number.attributes.number === value,
                  ),
                )
              }
            />
          )}
        </div>

        <div className="action-drawer mb-4">
          <div>
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-4p">
                <Icon symbol="general#outlined-location-pin" />
              </span>
              <Heading level={3} size={4} text="Address" />
            </div>
            {addresses.map((address) => (
              <Address
                key={address.id}
                address={address}
                canEditContactInfo={canEditContactInfo}
                isNewRecord={isNewRecord}
                saving={saving}
                markForDelete={markForDelete}
                unmarkForDelete={unmarkForDelete}
                updateAttribute={updateAttribute}
                domId={domId}
                initialCountry={addressCountries.find(
                  (country) => country.id === address.attributes.country_code,
                )}
              />
            ))}
          </div>
          <div className="ta-r pt-2">
            <button
              className="btn secondary-btn minor-btn"
              disabled={!canEditContactInfo || saving}
              type="button"
              onClick={addAddress}
            >
              <span
                className="mr-4p"
                style={{ fontSize: "9px" }}
                aria-hidden="true"
              >
                <Icon symbol="general#plus" />
              </span>
              Add mailing address
            </button>
          </div>
        </div>

        <Heading level={2} size={3} text="Personal Information" />
        <div className="action-drawer mt-2">
          <div className="d-f@sm f_1 mb-2">
            <div className="mb-1 pr-2@sm">
              <GenderSelect
                label="Gender"
                onChange={(value) => updateAttribute(gender, "gender", value)}
                value={gender.attributes.gender || ""}
              />
            </div>
            <div className="mb-1 pl-2@sm">
              <DateSelector
                disabled={!!birthdate.attributes.birthdate}
                initialValue={birthdate.attributes.birthdate}
                label={
                  <div className="label pb-0">
                    Birthdate
                    {birthdate.attributes.birthdate && (
                      <Icon symbol="general#lock" className="fs-2 pl-1" />
                    )}
                  </div>
                }
                name="birthdate"
                onChange={(newBirthdate) =>
                  updateAttribute(birthdate, "birthdate", newBirthdate)
                }
              />
            </div>
          </div>
          <GradeAndSchool
            {...{
              disableGrade,
              disableGraduationYear,
              grade,
              graduationYear,
              isChild,
              school,
              schoolOptions,
              updateAttribute,
              updateAttributes,
            }}
          />
          {!isChild && (
            <div className="d-f@sm f_1 mb-2">
              <div className="mb-1">
                <MaritalStatusSelect
                  additionalOptions={maritalStatusOptions}
                  onChange={(id) => {
                    const value = maritalStatusOptions.find(
                      (option) => option.id === id,
                    )?.attributes?.value
                    updateAttributes(maritalStatus, {
                      marital_status_id: id,
                      value,
                    })
                  }}
                  value={maritalStatus.attributes.marital_status_id}
                />
              </div>
              {maritalStatus.attributes.value === "Married" && (
                <div className="mb-1 pl-2@sm">
                  <DateSelector
                    initialValue={anniversary.attributes.anniversary}
                    label="Anniversary"
                    name="anniversary"
                    onChange={(newAnniversary) =>
                      updateAttribute(
                        anniversary,
                        "anniversary",
                        newAnniversary,
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
          <div>
            <label htmlFor="medical_notes">Medical notes</label>
            <textarea
              id="medical_notes"
              className="text-input"
              placeholder="Please use a comma-separated list for brevity. Ex: Pollen, nuts, and dairy."
              onChange={(e) =>
                updateAttribute(medicalNotes, "medical_notes", e.target.value)
              }
              value={medicalNotes.attributes.medical_notes}
            />
          </div>
        </div>
        <div className="d-f mt-1 fs-4 c-tint3">
          <span className="p-r t-2p">
            <Icon symbol="general#lock" className="mr-4p" />
          </span>
          <p>
            Locked fields can only be edited by a church administrator.{" "}
            <a href={`mailto:${email}`}>Contact us</a> for help.
          </p>
        </div>

        {currentPersonId && !!addOrRemoveHouseholdMembersEnabled ? (
          <div className="mt-4 d-f" css={styles.wrapper}>
            <button
              className="btn secondary-btn destroy-btn"
              onClick={removeHouseholdMember}
            >
              Remove from household
            </button>
            <div className="d-f g-2" css={styles.innerWrapper}>
              <button
                type="button"
                onClick={() => onDiscard()}
                className="c-tint3 text-btn"
                disabled={saving}
              >
                Discard changes
              </button>
              <button type="submit" className="btn" disabled={saving}>
                Save changes
              </button>
            </div>
          </div>
        ) : (
          <div className="ta-c mt-4 d-f jc-c g-2">
            <button
              type="button"
              onClick={() => onDiscard()}
              className="c-tint3 text-btn"
              disabled={saving}
            >
              Discard changes
            </button>
            <button type="submit" className="btn" disabled={saving}>
              Save changes
            </button>
          </div>
        )}
      </form>
    </Fragment>
  )
}

const styles = {
  editingFieldContainer: {
    paddingTop: "16px",

    "&:first-of-type": { paddingTop: "8px" },
  },

  wrapper: css`
    justify-content: space-between;
    text-align: "center";

    @media (max-width: 600px) {
      align-items: center;
      flex-direction: column-reverse;
    }
  `,

  innerWrapper: css`
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      margin-bottom: 15px;
    }
  `,
}
