import { Suspense, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import { xor } from "lodash"
import { css } from "@emotion/react"
import { Heading } from "@planningcenter/doxy-web"
import {
  ActionBar,
  AlertMessage,
  Avatar,
  Loading,
} from "source/shared/components"
import { Modal, useCloseModal } from "source/shared/components/Modal"
import { CurrentGroupContext } from "source/groups/my/groups"

const MAX_BEFORE_ROLLUP = 4
const MAX_RECIPIENT_COUNT = 500

export function EmailRecipients({ peopleIds, setPeopleIds }) {
  const { groupMembershipsCountOverMax, maxRecipientCount } =
    useMaxRecipientCount()

  return (
    <div className="d-f fd-c g-1 mb-2">
      {groupMembershipsCountOverMax && (
        <AlertMessage icon="general#exclamation-triangle" type="warning">
          <p className="mb-4p">
            Your group is too large to email everyone. You can select up through{" "}
            {maxRecipientCount} members.
          </p>
          <p className="mb-0">
            <a href="https://pcopeople.zendesk.com/hc/en-us/articles/10862339696667">
              Use People Lists
            </a>{" "}
            to send larger emails.
          </p>
        </AlertMessage>
      )}
      <Heading level={3} size={4} text="Send to" />
      <ActionBar.Pane css={{ minHeight: 0 }}>
        <ActionBar.Column>
          <ActionBar.Title level={4}>
            <TitleText peopleIds={peopleIds} />
          </ActionBar.Title>
        </ActionBar.Column>
        <ActionBar.Action>
          <Modal>
            <Modal.Trigger className="btn minor-btn secondary-btn d-b mt-4p">
              Select
            </Modal.Trigger>
            <Modal.Show>
              <ModalContent peopleIds={peopleIds} setPeopleIds={setPeopleIds} />
            </Modal.Show>
          </Modal>
        </ActionBar.Action>
      </ActionBar.Pane>
    </div>
  )
}
EmailRecipients.propTypes = {
  peopleIds: PropTypes.array.isRequired,
  setPeopleIds: PropTypes.func.isRequired,
}

function TitleText({ peopleIds }) {
  const {
    infiniteLoadingMemberships: { records: members },
    name: groupName,
  } = useContext(CurrentGroupContext)
  const { groupMembershipsCountOverMax } = useMaxRecipientCount()

  const [nameRollup, setNameRollup] = useState("")

  useEffect(() => {
    const names = peopleIds
      .slice(0, MAX_BEFORE_ROLLUP)
      .map((pid) => members.find((m) => m.relationships.person.data.id === pid))
      .map(
        (person) =>
          `${person.attributes.first_name} ${person.attributes.last_name}`,
      )

    if (peopleIds.length > MAX_BEFORE_ROLLUP) {
      names[MAX_BEFORE_ROLLUP - 1] = `${
        peopleIds.length - (MAX_BEFORE_ROLLUP - 1)
      } more...`
    }

    const lf = new Intl.ListFormat("en")

    setNameRollup(lf.format(names))
  }, [peopleIds])

  return (
    <>
      {groupMembershipsCountOverMax && peopleIds.length === 0
        ? "Select members..."
        : peopleIds.length > 0
          ? nameRollup
          : groupName}
    </>
  )
}
TitleText.propTypes = {
  peopleIds: PropTypes.array.isRequired,
}

function ModalContent({ peopleIds, setPeopleIds }) {
  const closeModal = useCloseModal()
  const { groupMembershipsCountOverMax, maxRecipientCount } =
    useMaxRecipientCount()
  const [showMemberList, setShowMemberList] = useState(
    groupMembershipsCountOverMax || peopleIds.length !== 0,
  )
  const [draftPeopleIds, setDraftPeopleIds] = useState(peopleIds)

  function onSubmit(e) {
    e.preventDefault()

    if (!showMemberList) {
      setPeopleIds([])
    } else {
      setPeopleIds(draftPeopleIds)
    }

    closeModal()
  }

  return (
    <>
      <Modal.Header>Select members</Modal.Header>
      <Modal.Body>
        {groupMembershipsCountOverMax && (
          <AlertMessage icon="general#outlined-info-circle" type="info">
            Select up through {maxRecipientCount} members below.
          </AlertMessage>
        )}
        <fieldset>
          <legend className="mb-1 p-0">
            <Heading level={3} size={4} text="Send to" />
          </legend>
          {!groupMembershipsCountOverMax && (
            <>
              <div className="d-f ai-c mb-1">
                <input
                  type="radio"
                  id="all_members"
                  className="radio"
                  checked={!showMemberList}
                  onChange={() => setShowMemberList(false)}
                />
                <label
                  className="radio-label"
                  htmlFor="all_members"
                  css={{ cursor: "pointer", fontWeight: 400 }}
                >
                  All members
                </label>
              </div>
              <div className="d-f ai-c mb-1">
                <input
                  type="radio"
                  id="select_members"
                  className="radio"
                  checked={showMemberList}
                  onChange={() => setShowMemberList(true)}
                />
                <label
                  className="radio-label"
                  htmlFor="select_members"
                  css={{ cursor: "pointer", fontWeight: 400 }}
                >
                  Select members
                </label>
              </div>
            </>
          )}
          {showMemberList && (
            <div css={{ marginLeft: "1.25rem" }}>
              <MemberList
                peopleIds={draftPeopleIds}
                setPeopleIds={setDraftPeopleIds}
              />
            </div>
          )}
        </fieldset>
        <Modal.Footer>
          <Modal.Close>Cancel</Modal.Close>
          <button type="submit" className="btn" onClick={onSubmit}>
            Select
          </button>
        </Modal.Footer>
      </Modal.Body>
    </>
  )
}
ModalContent.propTypes = {
  peopleIds: PropTypes.array.isRequired,
  setPeopleIds: PropTypes.func.isRequired,
}

function MemberList({ peopleIds, setPeopleIds }) {
  const { infiniteLoadingMemberships } = useContext(CurrentGroupContext)
  const { records: members, hasMore, loadMore } = infiniteLoadingMemberships

  function onSelect(personId) {
    setPeopleIds((prevPeopleIds) => xor(prevPeopleIds, [personId]))
  }

  const scrollStyles = css`
    max-height: 22rem;
    overflow-y: auto;
    padding-left: 0.25rem;

    background:
      linear-gradient(white 10%, rgba(255, 255, 255, 0)) center top,
      linear-gradient(rgba(255, 255, 255, 0), white 10%) center bottom,
      radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0)
        )
        center top,
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0)
        )
        center bottom;

    background-repeat: no-repeat;
    background-size:
      100% 40px,
      100% 40px,
      180% 8px,
      180% 8px;
    background-attachment: local, local, scroll, scroll;
  `

  return (
    <Suspense fallback={<Loading />}>
      <div css={scrollStyles}>
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          loader={<Loading key="loading" />}
          useWindow={false}
        >
          {members.map((member) => (
            <MemberItem
              key={member.id}
              member={member}
              onSelect={onSelect}
              peopleIds={peopleIds}
            />
          ))}
        </InfiniteScroll>
      </div>
    </Suspense>
  )
}
MemberList.propTypes = {
  peopleIds: PropTypes.array.isRequired,
  setPeopleIds: PropTypes.func.isRequired,
}

function MemberItem({ member, onSelect, peopleIds }) {
  const { maxRecipientCount } = useMaxRecipientCount()

  const styles = {
    row: css`
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 0.75rem;
    `,
    checkboxLabel: css`
      &:before {
        top: calc(50% - 8px);
      }
      &:after {
        top: calc(50% - 5px);
      }
    `,
    labelText: css`
      flex-shrink: 2;
    `,
  }
  const personId = member?.relationships?.person?.data?.id
  const isChecked = peopleIds.includes(personId)
  const email = member.attributes.email_address
  const isDisabled =
    !email || (peopleIds.length >= maxRecipientCount && !isChecked)

  return (
    <div css={styles.row}>
      <input
        id={personId}
        type="checkbox"
        className="checkbox"
        checked={isChecked}
        onChange={() => onSelect(personId)}
        disabled={isDisabled}
      />

      <label
        htmlFor={personId}
        className="checkbox-label d-if ai-c wb-bw"
        css={styles.checkboxLabel}
      >
        <div className="w-5 h-5 mr-1">
          <Avatar url={member.attributes.avatar_url} />
        </div>
        <div css={styles.labelText}>
          <span className="d-b fw-500">
            {member.attributes.first_name} {member.attributes.last_name}
          </span>
          {email ? (
            <Subtitle className="c-tint2">{email}</Subtitle>
          ) : (
            <Subtitle className="c-ruby">Missing email</Subtitle>
          )}
        </div>
      </label>
    </div>
  )
}
MemberItem.propTypes = {
  member: PropTypes.shape({
    attributes: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.shape.isRequired,
    }).isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  peopleIds: PropTypes.array.isRequired,
}

function Subtitle({ className, ...restProps }) {
  return <span className={`d-b fs-13 lh-1.5 ${className}`} {...restProps} />
}
Subtitle.propTypes = {
  className: PropTypes.string,
}

export function useMaxRecipientCount() {
  const {
    infiniteLoadingMemberships: { totalCount },
  } = useContext(CurrentGroupContext)
  const groupMembershipsCountOverMax = totalCount >= MAX_RECIPIENT_COUNT

  return {
    groupMembershipsCountOverMax,
    maxRecipientCount: MAX_RECIPIENT_COUNT,
  }
}
