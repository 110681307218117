import { string, shape, func, number, oneOfType } from "prop-types"
import Field from "./Field"
import { usePeopleGenderOptions } from "source/registrations/hooks/usePeopleGenders"
import { Select } from "source/shared/components"

const GenderField = ({ attendee, value, onChange, error, ...rest }) => {
  const { id: attendeeId } = attendee
  const genderOptions = usePeopleGenderOptions()

  const handleGenderChange = (value) => {
    onChange(value || null)
  }

  return (
    <Field label={"Gender"} value={value} error={error} {...rest}>
      <Select
        name={`gender-${attendeeId}`}
        onChange={({ target: { value } }) => handleGenderChange(value)}
        style={{ height: "32px" }}
        disabled={genderOptions.isLoading}
        options={genderOptions.data || []}
        value={value || ""}
      />
    </Field>
  )
}

GenderField.propTypes = {
  attendee: shape({
    id: oneOfType([string, number]),
  }),
  onChange: func,
  error: string,
  value: string,
}

export default GenderField
