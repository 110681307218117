import { useParams } from "react-router-dom"
import {
  SessionApiResource,
  useApiRead,
} from "source/shared/SessionApiResource"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import { getRelationship } from "source/shared/getRelationship"

export function useCurrentGroup() {
  const { groupTypeSlug, groupSlug } = useParams()
  return useApiRead(
    `/groups/v2/group_types/${groupTypeSlug}/groups/${groupSlug}?include=location`,
  )
}

export function useLocationForCurrentGroup() {
  const response = useCurrentGroup()
  return getRelationship(response, "location")
}

export function useMyApplicationForCurrentGroup() {
  const { data: group } = useCurrentGroup()
  const { data: applications } = useMyApplications()

  return (
    applications?.find(
      (application) => group.id === application.relationships.group.data.id,
    ) || null
  )
}

// Let's just grab one page of 100,
// it feels reasonable to say a person should not have more than that
// Alternatively, we could allow querying by group...
const MY_APPLICATIONS_URL = "/groups/v2/me/group_applications?per_page=100"

export function useReloadMyApplications() {
  return useCacheHydrate(SessionApiResource, MY_APPLICATIONS_URL)
}

function useMyApplications() {
  return useApiRead(MY_APPLICATIONS_URL)
}
