/**
 * Returns an ordinalized string for the given number.
 *
 * Example: `1` turns into "1st"
 *
 * @param {number} n
 * @returns string
 */
export function ordinalize(n) {
  if (n > 10 && n < 20) return `${n}th`

  switch (n % 10) {
    case 1:
      return `${n}st`
    case 2:
      return `${n}nd`
    case 3:
      return `${n}rd`
    default:
      return `${n}th`
  }
}
