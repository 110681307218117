import { useState, useCallback, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"
import { groupBy } from "lodash"
import { NarrowLayout } from "source/Layout"
import { useApiRead } from "source/shared/SessionApiResource"
import { HouseholdsOptIn } from "source/directory/edit/HouseholdsOptIn"
import { SelectableIndividualOptIn } from "source/directory/edit/IndividualOptIn"
import { usePersonAndHouseholdDirectoryFieldData } from "source/directory/hooks/usePersonAndHouseholdDirectoryFieldData"
import { usePersonDirectoryPrivacySettings } from "./hooks/usePersonDirectoryPrivacySettings"
import { useReloadDirectoryStatus } from "source/directory/hooks/useDirectoryStatus"
import {
  AlertMessage,
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { Footer } from "source/directory/edit/Footer"
import { WebBootContext } from "source/publishing/WebBoot"

export function Edit() {
  const navigate = useNavigate()
  const person = useApiRead(
    "/people/v2/me?include=emails,addresses,phone_numbers,directory_field_data",
  )
  const households = useApiRead(
    `/people/v2/people/${person.data.id}/households?include=people.emails,people.addresses,people.phone_numbers,people.directory_field_data`,
  )
  const directoryFields = useApiRead("/people/v2/directory_fields")
  const { directoryFieldData, dispatch, isCurrentlyInDirectory } =
    usePersonAndHouseholdDirectoryFieldData({ person, households })
  const { directoryPrivacySettings, dispatch: updatePrivacySettings } =
    usePersonDirectoryPrivacySettings({ person, households })

  const [isSaving, setIsSaving] = useState(false)
  const [savingError, setSavingError] = useState()
  const reloadDirectoryStatus = useReloadDirectoryStatus()

  const {
    abilities: {
      people: { directory_privacy_mode_enabled: globalPrivacyEnabled },
    },
  } = useContext(WebBootContext)

  const redirectAfterSave = useCallback(
    (isSharing) => {
      const navigateToHousehold = isSharing && households.data.length === 1
      const navigateToPerson = isSharing && households.data.length !== 1
      if (navigateToHousehold) {
        navigate(`/directory/households/${households.data[0].id}`)
      } else if (navigateToPerson) {
        navigate(`/directory/people/${person.data.id}`)
      } else if (!isSharing) {
        navigate("/directory/people?status=removed_profile")
      } else {
        navigate("/directory/people")
      }
      reloadDirectoryStatus()
    },
    [households, person],
  )

  async function handleSubmit(directoryFieldData, directoryPrivacySettings) {
    const peopleToUpdate = isInHousehold
      ? households.included.filter(({ type }) => type === "Person")
      : [person.data]
    const payload = {
      people: peopleToUpdate,
      directoryFieldData,
      directoryPrivacySettings,
    }

    setSavingError()
    setIsSaving(true)
    try {
      await sessionApiClient.patch(
        "/people/v2/me",
        deserializePayload({
          ...payload,
          globalPrivacyEnabled,
        }),
      )
      dispatch({
        type: "set",
        payload: payload.directoryFieldData,
        persisted: true,
      })
      redirectAfterSave(payload.directoryFieldData.length > 0)
      setIsSaving(false)
    } catch (e) {
      setSavingError(e)
      setIsSaving(false)
    }
  }

  const isInHousehold = households.data.length > 0

  return (
    <NarrowLayout>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="My directory profile" isActive />
      </BreadcrumbNavigationWithMe>

      <div className="mb-1">
        <Heading level={1} size={2} text="My information" />
      </div>
      <p className="mb-3">
        Select the information you want to share with other members of the
        directory.{" "}
        <span className="c-tint2">
          (If any of your information is incorrect, you can{" "}
          <Link to="/me/profile">edit your profile</Link>.)
        </span>
      </p>

      <SelectableIndividualOptIn
        directoryFieldData={directoryFieldData}
        directoryFields={directoryFields}
        directoryPrivacySettings={directoryPrivacySettings}
        person={person}
        onUpdate={dispatch}
        onPrivacySettingsUpdate={updatePrivacySettings}
      />
      {isInHousehold && (
        <HouseholdsOptIn
          directoryFieldData={directoryFieldData}
          directoryFields={directoryFields}
          households={households}
          onUpdate={dispatch}
          directoryPrivacySettings={directoryPrivacySettings}
          onPrivacySettingsUpdate={updatePrivacySettings}
        />
      )}
      {savingError && (
        <AlertMessage className="mt-2">
          Uh oh! Something went wrong. Please try saving again.
        </AlertMessage>
      )}
      <Footer
        isCurrentlyInDirectory={isCurrentlyInDirectory}
        directoryFieldData={directoryFieldData}
        directoryPrivacySettings={directoryPrivacySettings}
        disabled={isSaving}
        onClick={handleSubmit}
        onPrivacySettingsUpdate={updatePrivacySettings}
      />
    </NarrowLayout>
  )
}

const DISPLAYABLE_TYPES = {
  phone_number: "PhoneNumber",
  email: "Email",
  address: "Address",
  birthday: "Birthday",
  anniversary: "Anniversary",
}

function deserializePayload({
  people,
  directoryFieldData,
  directoryPrivacySettings,
  globalPrivacyEnabled,
}) {
  const groupedDirectoryFieldData = groupBy(
    directoryFieldData,
    ({ personId }) => personId,
  )

  return {
    data: {
      attributes: {
        bulk_directory_privacy_preferences: people.map((person) => {
          const directoryPrefs =
            directoryPrivacySettings.find(
              (pref) => pref.personId == person.id,
            ) || {}

          return {
            person_id: person.id,
            directory_privacy_preferences: {
              directory_privacy_mode_enabled:
                directoryPrefs.directoryPrivacyModeEnabled,
              directory_private_contact_mode:
                !!directoryPrefs.directoryPrivacyModeEnabled ||
                globalPrivacyEnabled
                  ? directoryPrefs.directoryPrivateContactMode
                  : null,
            },
          }
        }),
        bulk_directory_field_data_attributes: people.map((person) => ({
          person_id: person.id,
          directory_field_data: (
            groupedDirectoryFieldData[person.id] || []
          ).map(({ fieldType, displayableId }) => ({
            field_type: fieldType,
            displayable_type: DISPLAYABLE_TYPES[fieldType],
            displayable_id: displayableId,
          })),
        })),
      },
    },
  }
}
