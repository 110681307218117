import { Heading } from "@planningcenter/doxy-web"
import { string, node } from "prop-types"

export const RecordNotFound = ({ heading = "Uh oh!", message, actions }) => {
  return (
    <div className="container container--narrow ta-c">
      <img
        className="error-img pt-2 pt-0@md"
        style={{ maxWidth: "240px" }}
        src="/static/errors/cco-error.png"
        alt="Error graphic"
      />
      <div className="mt-3 mb-3">
        <Heading level={1} text={heading} />
      </div>
      <div className="mt-3 mb-3">{message}</div>
      {actions}
    </div>
  )
}

RecordNotFound.propTypes = {
  heading: string,
  message: node.isRequired,
  actions: node.isRequired,
}
