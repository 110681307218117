import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import { useGroupAttendanceSummary } from "source/groups/hooks/useGroupAttendanceSummary"
import { Loading } from "source/shared/components"
import { MemberAttendanceCard, LIST_SPACE } from "./MemberAttendanceCard"
import moment from "moment"
import BlankState from "source/groups/BlankState"

export const MemberAttendanceCards = ({
  basePath,
  groupId,
  filterBy,
  orderBy,
}) => {
  const { memberships, hasMore, loadMore } = useGroupAttendanceSummary({
    groupId,
    filterBy,
    orderBy,
  })

  if (memberships.length === 0) return <FiltersBlankState />

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      <ul className="unstyled d-f fd-c" style={{ gap: LIST_SPACE }}>
        {memberships.map((membership) => {
          const lastEvent =
            membership.attendances[membership.attendances.length - 1]?.event
          const lastEventStartsAt = lastEvent?.attributes?.starts_at
          const showJoinedAt =
            !!lastEventStartsAt &&
            membership.attributes.joined_at >= lastEventStartsAt

          return (
            <MemberAttendanceCard
              key={membership.id}
              memberId={membership.id}
              basePath={basePath}
              name={membership.person?.attributes.name || "Unknown"}
              joinedAt={
                showJoinedAt ? membership.attributes.joined_at : undefined
              }
              avatarUrl={membership.person?.attributes.avatar_url || ""}
              attendanceStates={membership.attendances.map((a) => ({
                state: attendanceState({
                  recordingStatus:
                    a.event.attributes.attendance_recording_status,
                  memberJoinedAt: membership.attributes.joined_at,
                  eventStartsAt: a.event.attributes.starts_at,
                  attended: a.attendance?.attributes?.attended,
                }),
                label: formatEventDate(a.event.attributes.starts_at),
              }))}
              attendanceRate={membership.attendanceRate}
            />
          )
        })}
      </ul>
    </InfiniteScroll>
  )
}

MemberAttendanceCards.propTypes = {
  basePath: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  filterBy: PropTypes.string,
  orderBy: PropTypes.string,
}

const FiltersBlankState = () => (
  <BlankState icon="churchCenter#calendar-cancel">
    <p className="mb-0">
      No attendances found, please try adjusting your filters.
    </p>
  </BlankState>
)

const formatEventDate = (date) => {
  return moment(date).format("MM/DD")
}

const attendanceState = ({
  recordingStatus,
  memberJoinedAt,
  eventStartsAt,
  attended,
}) => {
  if (memberJoinedAt > eventStartsAt) return "not_member"
  if (recordingStatus === "no_recording") return "unrecorded_event"
  if (recordingStatus === "draft") return "unrecorded_event"
  if (recordingStatus === "processing") return "unrecorded_event"
  if (attended) return "attended"
  if (attended === false) return "missed"

  return "cancelled"
}
