import { useState, useEffect, useCallback } from "react"

export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = useCallback(() => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }, [setScrollPosition])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return scrollPosition
}

export function useScrollThreshold(threshold) {
  const [pastThreshold, setPastThreshold] = useState(false)
  const handleScrollThreshold = useCallback(() => {
    const flag = window.pageYOffset >= threshold
    if (flag !== pastThreshold) {
      setPastThreshold(flag)
    }
  }, [setPastThreshold, pastThreshold, threshold])

  useEffect(() => {
    window.addEventListener("scroll", handleScrollThreshold, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScrollThreshold)
    }
  }, [handleScrollThreshold])

  return pastThreshold
}
