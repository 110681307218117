import { Link } from "react-router-dom"
import { time as formatTime } from "@churchcenter/datetime-fmt"
import { array, number, string } from "prop-types"
import { Icon } from "source/shared/components"

UpcomingSchedules.propTypes = {
  ends_at: string,
  plan_id: number,
  schedules: array,
  service_type_id: number,
  service_type_name: string,
  starts_at: string,
  times_label: string,
  time_zone: string,
}

export default function UpcomingSchedules({
  ends_at,
  plan_id,
  schedules,
  service_type_id,
  service_type_name,
  starts_at,
  times_label,
  time_zone,
}) {
  const title = `${formatTime(starts_at, ends_at, {
    timeZone: time_zone,
  })} ${service_type_name}`

  const planDetailsPath = `/services/service_types/${service_type_id}/plans/${plan_id}`

  return (
    <div className="mb-2">
      <Link
        to={planDetailsPath}
        state={{ prevPath: location.pathname, prevPathName: "Schedule" }}
        className="c-brand fw-500"
      >
        {title}
      </Link>
      <div>{times_label}</div>
      {schedules.map((schedule) => (
        <div key={schedule.id} className="d-f ai-c c-tint2 fs-4">
          <div className="mr-4p p-r t-2p">
            <Icon symbol="general#check" className="c-emerald" />
          </div>
          <div>{schedule.display_name}</div>
        </div>
      ))}
    </div>
  )
}
