import { useApiPost } from "source/shared/SessionApiResource"
import GroupTopic from "./GroupTopic"
import { object, func } from "prop-types"

GeneralChat.propTypes = {
  episode: object,
  episodeTime: object,
  setGroupId: func,
}
export default function GeneralChat({ episode, episodeTime, setGroupId }) {
  const token = useApiPost(
    `/publishing/v2/episodes/${episode.id}/episode_times/${
      episodeTime.id
    }/generate_topic_token`,
  ).data.id
  const payload = useApiPost(`/groups/v2/topic_for_episode?include=group`, {
    data: { attributes: { token } },
  })
  const topic = payload.data
  const group = payload.included[0]
  group.attributes.name = "General Chat"
  return (
    <GroupTopic
      group={group}
      topic={topic}
      setGroupId={setGroupId}
      leaderLabel="Host"
    />
  )
}
