import { useContext, useEffect } from "react"
import { setGlobalConfiguration } from "@churchcenter/datetime-fmt"

import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"

export function SetOrganizationTimeZone({ children }) {
  const {
    current_organization: { time_zone: timeZone },
  } = useContext(ServerRenderedProps)

  useEffect(() => {
    setGlobalConfiguration({ timeZone })
  }, [timeZone])

  return children
}
