import { Suspense, useContext } from "react"
import PropTypes from "prop-types"
import { Link, Navigate, useParams } from "react-router-dom"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import { get as dig } from "lodash"
import { Icon, ActionBar, Loading } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import { resourceIconSymbol } from "./utils/resourceIconSymbol"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { WebBootContext } from "source/publishing/WebBoot"

export default function ResourcesShow() {
  return (
    <TabView>
      <Suspense fallback={<Loading />}>
        <Loaded />
      </Suspense>
    </TabView>
  )
}

function Loaded() {
  const { resourceId } = useParams()
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(
    `/groups/v2/me/groups/${group.id}/resources/${resourceId}`,
  )

  return response.errors &&
    response.errors.some(({ status }) => status === "404") ? (
    <Navigate to={`/my/groups/${group.slug}/resources`} replace />
  ) : (
    <Resource data={response.data} />
  )
}

function Resource({ data }) {
  const {
    name,
    description,
    icon_class,
    visibility,
    type,
    file_download_url,
    link_url,
    shared,
  } = data.attributes
  const group = useContext(CurrentGroupContext)
  const {
    layout: { organization_contact_email },
  } = useContext(ServerRenderedProps)
  const features = useContext(WebBootContext).features

  const canManageGroup = dig(group, "abilities.can_manage", false)
  const canManageResource = dig(data, "attributes.abilities.can_manage", false)

  const alignItems = canManageGroup ? "ai-c ai-fs@xs" : "ai-c"

  return (
    <div>
      <header className={`d-f fd-c fd-r@xs mb-3 pt-1 g-2 ${alignItems}`}>
        <ResourceIcon iconClass={icon_class} />

        <div className="f-1 ta-c ta-l@xs">
          <Heading level={1} size={2} text={name} />
          <div className="d-f jc-c jc-fs@xs ai-c g-1 mt-1">
            {features.ROLLOUT_groups_manage_resources && canManageGroup && (
              <Menu>
                <MenuButton className="minor-btn secondary-btn btn">
                  Options
                  <span className="fs-6 pl-4p dropdown-trigger__icon">
                    <Icon symbol="general#down-chevron" aria-hidden />
                  </span>
                </MenuButton>
                {canManageResource ? (
                  <MenuList>
                    <MenuLink as={Link} to="edit">
                      Edit
                    </MenuLink>
                    <MenuLink as={Link} to="delete">
                      <span className="c-ruby">Delete...</span>
                    </MenuLink>
                  </MenuList>
                ) : (
                  <MenuList>
                    <div className="px-2 py-1 c-tint1 d-f">
                      <Icon
                        className="c-tint3 mr-1 mt-4p"
                        symbol="general#lock"
                        aria-hidden
                      />
                      <span className="c-tint2" css={{ maxWidth: "14rem" }}>
                        <a href={`mailto:${organization_contact_email}`}>
                          Contact your church administrator
                        </a>{" "}
                        to request changes for resources shared between groups.
                      </span>
                    </div>
                  </MenuList>
                )}
              </Menu>
            )}
            {shared && canManageGroup && <span className="badge">Shared</span>}
            {visibility === "leaders" && (
              <span className="badge">Leaders only</span>
            )}
          </div>
        </div>
      </header>

      <ResourceBar
        type={type}
        href={type === "FileResource" ? file_download_url : link_url}
        selfLink={data.links.self}
      />

      {description && (
        <div className="my-3">
          <div className="d-f ai-c jc-sb mb-1">
            <Subheading level={2} text="Description" />
          </div>
          <div className="fs-4 c-tint1">
            {description.split("\n").map((i, key) => {
              return (
                <p key={key} className="mb-1">
                  {i}
                </p>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
Resource.propTypes = {
  data: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      icon_class: PropTypes.string.isRequired,
      visibility: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      file_download_url: PropTypes.string.isRequired,
      link_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

function ResourceIcon({ iconClass }) {
  const symbol = resourceIconSymbol(iconClass)
  return (
    <div className="event-detail__cal">
      <div className="ta-c event-cal">
        <div className="w-6 h-6 media-tile">
          <Icon symbol={symbol} className="c-tint4 w-6 h-6" aria-hidden />
        </div>
      </div>
    </div>
  )
}
ResourceIcon.propTypes = {
  iconClass: PropTypes.string.isRequired,
}

function ResourceBar({ type, href, selfLink }) {
  return (
    <ActionBar>
      <ActionBar.Column>
        <ActionBar.Description>
          This {type === "LinkResource" ? "link" : "file"} will open in a new
          tab.
        </ActionBar.Description>
      </ActionBar.Column>
      <ActionBar.Action>
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            if (type === "LinkResource") return

            e.preventDefault()
            sessionApiClient
              .get(selfLink)
              .then((res) => res.data.attributes.file_download_url)
              .then((url) => window.open(url, "_blank"))
          }}
          className="btn"
        >
          View resource
          <div className="w-2 h-2 ml-1">
            <Icon symbol="general#new-window" className="w-2 h-2" aria-hidden />
          </div>
        </a>
      </ActionBar.Action>
    </ActionBar>
  )
}
ResourceBar.propTypes = {
  type: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selfLink: PropTypes.string.isRequired,
}
