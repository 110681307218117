import { object, array, func } from "prop-types"
import Question from "source/registrations/questions/Question"

const AttendeeQuestions = ({ attendee, questions, onChange, step }) => {
  const attendeeQuestions = questions.filter(
    (question) =>
      !question.excludedAttendeeTypeIds.includes(attendee.attendeeTypeId),
  )

  const handleChange = (questionId, answer) => {
    onChange(attendee, "answers", {
      ...attendee.answers,
      [questionId]: {
        ...attendee.answers[questionId],
        attendeeId: attendee.id,
        answers: answer,
        questionId,
        _destroy: attendee.answers[questionId]?.id && answer.length === 0,
      },
    })
  }

  return (
    <>
      {step.isCurrent &&
        attendeeQuestions.map((question) => (
          <Question
            key={question.id}
            fieldId={`attendee${attendee.id}_question${question.id}`}
            question={question}
            answers={attendee.answers}
            onChange={handleChange}
            isEditing
          />
        ))}
    </>
  )
}

AttendeeQuestions.propTypes = {
  attendee: object,
  questions: array,
  onChange: func,
  step: object,
}

export default AttendeeQuestions
