import { useState } from "react"
import { get } from "lodash"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import moment from "moment-timezone"
import ReactDatePicker from "react-datepicker"
import spinner from "source/svg/spinner.svg"
import { NarrowLayout } from "source/Layout"
import { bool, func, string, number } from "prop-types"
import {
  Icon,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { sessionApiClient as API } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { useBlockout } from "source/services/hooks/useBlockout"
import {
  FIRST_MINUTE_OF_DAY,
  LAST_MINUTE_OF_DAY,
} from "source/services/classes/Blockout"

import { useCurrentOrganizationTimeZone } from "source/services/hooks/useCurrentOrganizationTimeZone"
import { RecurrenceSelectGroup } from "./RecurrenceSelectGroup"
import { DestroyModal } from "./DestroyModal"
import { TextInput } from "@planningcenter/doxy-web"

export default function Blockout() {
  useSession(true)
  const location = useLocation()
  const navigate = useNavigate()
  const { blockoutId, blockoutDateId } = useParams()

  const blockout = useBlockout({ blockoutId, blockoutDateId })
  const timeZone = useCurrentOrganizationTimeZone()

  const [inFlight, setInFlight] = useState(false)
  const [isSharedWithLinkedAccounts, setIsSharedWithLinkedAccounts] = useState(
    blockout.canShareWithLinkedAccounts,
  )
  const [applyTo, setApplyTo] = useState("one")
  const [applyToHousehold, setApplyToHousehold] = useState(
    blockout.isNew
      ? blockout.canApplyToHousehold
      : blockout.isSharedWithHouseholdMembers,
  )
  const [isAllDay, setIsAllDay] = useState(blockout.isAllDay)
  const [recurrence, setRecurrence] = useState(blockout.recurrence)
  const [reason, setReason] = useState(blockout.reason)
  const [startsAtDate, setStartsAtDate] = useState(blockout.startsAtDate)
  const [endsAtDate, setEndsAtDate] = useState(blockout.endsAtDate)
  const [startsAtMinuteOfDay, setStartsAtMinuteOfDay] = useState(
    blockout.startsAtMinuteOfDay,
  )
  const [endsAtMinuteOfDay, setEndsAtMinuteOfDay] = useState(
    blockout.endsAtMinuteOfDay,
  )
  const [showDestroyModal, setShowDestroyModal] = useState(false)
  const referrerPath = get(location, "state.prevPath", "/services")
  const referrerPathName = get(location, "state.prevPathName", "My Services")

  const handleStartsAtDateChange = (date) => {
    if (date > endsAtDate) {
      setEndsAtDate(date)
    }
    setStartsAtDate(date)
  }
  const handleEndsAtDateChange = (date) => setEndsAtDate(date)
  const handleStartsAtTimeChange = (e) => setStartsAtMinuteOfDay(e.target.value)
  const handleEndsAtTimeChange = (e) => setEndsAtMinuteOfDay(e.target.value)
  const handleIsAllDayChange = () => setIsAllDay((prev) => !prev)

  const handleChangeRecurrence = (recurrence) =>
    setRecurrence((currentRecurrence) => ({
      ...currentRecurrence,
      ...recurrence,
    }))

  const handleSetReason = (e) => setReason(e.target.value)

  const handleSubmit = () => {
    setInFlight(true)

    const handler = blockout.isNew ? handleCreate : handleUpdate

    handler()
      .then(() => navigate(referrerPath))
      .catch(() => {
        setInFlight(false)
        alert("Something went wrong. Please try again.")
      })
  }

  const handleCreate = () => {
    const attributes = {
      decline_conflicts: true,
      starts_at: buildISOStringFromDateAndMinuteOfDay({
        date: startsAtDate,
        minuteOfDay: isAllDay ? FIRST_MINUTE_OF_DAY : startsAtMinuteOfDay,
        timeZone,
      }),
      ends_at: buildISOStringFromDateAndMinuteOfDay({
        date: endsAtDate,
        minuteOfDay: isAllDay ? LAST_MINUTE_OF_DAY : endsAtMinuteOfDay,
        seconds: isAllDay ? 59 : 0,
        timeZone,
      }),
      reason,
      repeat_frequency: recurrence.frequency,
      repeat_interval: recurrence.interval,
      repeat_period: recurrence.period,
      repeat_until: recurrence.until
        ? moment(recurrence.until).format("YYYY-MM-DD")
        : null,
      share: isSharedWithLinkedAccounts,
    }

    const householdMemberPromises = applyToHousehold
      ? blockout.manageableHouseholdMemberIds.map((id) =>
          API.post(`/services/v2/me/household_members/${id}/blockouts`, {
            data: { attributes },
          }),
        )
      : []

    return Promise.all([
      API.post(`/services/v2/me/blockouts`, { data: { attributes } }),
      ...householdMemberPromises,
    ])
  }

  const handleUpdate = () => {
    const attributes = {
      apply_to: applyTo,
      apply_to_group: applyToHousehold,
      blockout_date_id: blockoutDateId,
      starts_at: buildISOStringFromDateAndMinuteOfDay({
        date: startsAtDate,
        minuteOfDay: isAllDay ? FIRST_MINUTE_OF_DAY : startsAtMinuteOfDay,
        timeZone,
      }),
      ends_at: buildISOStringFromDateAndMinuteOfDay({
        date: endsAtDate,
        minuteOfDay: isAllDay ? LAST_MINUTE_OF_DAY : endsAtMinuteOfDay,
        seconds: isAllDay ? 59 : 0,
        timeZone,
      }),
      reason,
      share: isSharedWithLinkedAccounts,
    }

    return API.post(`/services/v2/me/blockouts/${blockoutId}/update`, {
      data: { attributes },
    })
  }

  return (
    <NarrowLayout>
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl={referrerPath} pageText={referrerPathName} />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl={
            blockout.isNew
              ? "/services/blockouts/new"
              : `/services/blockouts/${blockoutId}/dates/${blockoutDateId}`
          }
          pageText={blockout.isNew ? "Add blockout" : "Edit blockout"}
          isActive
        />
      </BreadcrumbNavigation>

      {blockout.share && blockout.hasLinkedAccounts && (
        <div className="alert info-alert d-f ai-c">
          <span className="mr-1 p-r t-2p c-topaz">
            <Icon symbol="general#outlined-info-circle" />
          </span>
          <p className="unstyled">
            {blockout.isOwner
              ? "This blockout is shared with your linked accounts."
              : `Shared from ${
                  blockout.organization_name || "a linked account"
                }`}
          </p>
        </div>
      )}

      <div>
        <div className="d-f@md mb-1">
          <div className="f-1 d-f mb-2 mb-0@md">
            <DateField
              name="blockout_starts_at_date"
              value={startsAtDate}
              onChange={handleStartsAtDateChange}
              minDate={moment().add(-3, "months").toDate()}
              label="Start date"
              wrapperClassname="f-1"
            />

            {!isAllDay && (
              <MinuteOfDaySelect
                id="blockout_starts_at_time"
                label="Start time"
                value={startsAtMinuteOfDay}
                onChange={handleStartsAtTimeChange}
                wrapperClassname="f-1 pl-2 px-1@md"
              />
            )}
          </div>

          <div className="d-n d-b@md as-c px-2 pt-4">to</div>

          <div className="f-1 d-f">
            {!isAllDay && (
              <MinuteOfDaySelect
                id="blockout_ends_at_time"
                label="End time"
                required
                value={endsAtMinuteOfDay}
                onChange={handleEndsAtTimeChange}
                wrapperClassname="f-1 pr-2 px-1@md"
              />
            )}
            <DateField
              name="blockout_ends_at_date"
              value={endsAtDate}
              onChange={handleEndsAtDateChange}
              minDate={startsAtDate}
              label="End date"
              wrapperClassname="f-1"
            />
          </div>
        </div>
        <CheckboxField
          name="isAllDay"
          checked={isAllDay}
          onChange={handleIsAllDayChange}
          label="All day"
        />

        {(blockout.isNew || blockout.isRecurring) && (
          <div className="pt-2">
            <div className="label">Recurrence</div>
            <RecurrenceSelectGroup
              disabled={!blockout.isNew}
              recurrence={recurrence}
              onChange={handleChangeRecurrence}
              startDate={startsAtDate}
              endDate={endsAtDate}
            />

            {blockout.isRecurring && (
              <select
                className="select"
                value={applyTo}
                onChange={(e) => setApplyTo(e.target.value)}
              >
                <option value="one">Apply to this date only</option>
                <option value="future">Apply to this and future dates</option>
                <option value="all">Apply to all dates</option>
              </select>
            )}
          </div>
        )}

        <div>
          <div className="pt-1">
            <label className="label" htmlFor="reason">
              Reason
            </label>
            <TextInput id="reason" value={reason} onChange={handleSetReason} />
          </div>
        </div>

        <div className="d-f fd-c">
          {blockout.canShareWithLinkedAccounts && (
            <div className="pt-1">
              <CheckboxField
                name="isSharedWithLinkedAccounts"
                checked={isSharedWithLinkedAccounts}
                onChange={() => setIsSharedWithLinkedAccounts((val) => !val)}
                label="Share with linked accounts"
              />
            </div>
          )}

          {blockout.canApplyToHousehold && (
            <div className="pt-1">
              <CheckboxField
                name="applyToHousehold"
                checked={applyToHousehold}
                onChange={() => setApplyToHousehold((val) => !val)}
                label={
                  blockout.isNew
                    ? "Share with my household"
                    : "Edit household blockout"
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-f fd-c fd-r@xs jc-sb mt-2">
        <div className="d-f ai-c jc-c js-fe@xs o-1@xs">
          <Link to={referrerPath} className="c-tint3 text-btn">
            Cancel
          </Link>
          {inFlight ? (
            <img
              src={spinner}
              alt="Saving"
              style={{ height: "1em", width: "1em" }}
            />
          ) : (
            <button className="ml-2 btn" onClick={handleSubmit}>
              Save
            </button>
          )}
        </div>
        <div className="ta-c ta-l@xs pt-2 pt-0@xs">
          {!blockout.isNew && (
            <button
              className="btn destroy-btn"
              onClick={() => setShowDestroyModal(true)}
            >
              {blockout.isRecurring
                ? `Delete ${applyTo} ${
                    applyToHousehold ? "household " : ""
                  }blockout${applyTo === "one" ? "" : "s"}`
                : `Delete${applyToHousehold ? " household blockout" : ""}`}
            </button>
          )}
        </div>
      </div>

      {showDestroyModal && (
        <DestroyModal
          onComplete={() => navigate(referrerPath)}
          onCancel={() => setShowDestroyModal(false)}
          applyTo={applyTo}
          applyToHousehold={applyToHousehold}
          blockoutDateId={blockoutDateId}
          blockoutId={blockoutId}
        />
      )}
    </NarrowLayout>
  )
}

CheckboxField.propTypes = {
  checked: bool,
  disabled: bool,
  label: string,
  name: string,
  onChange: func,
}

function CheckboxField({ checked, disabled, label, name, onChange }) {
  return (
    <div>
      <input
        type="checkbox"
        className="checkbox"
        id={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="checkbox-label pb-0" htmlFor={name}>
        <span>{label}</span>
      </label>
    </div>
  )
}

DateField.propTypes = {
  wrapperClassname: string,
  name: string,
  label: string,
  value: string,
  onChange: func,
  minDate: string,
}

function DateField({
  wrapperClassname,
  name,
  label,
  value,
  onChange,
  minDate,
}) {
  return (
    <div className={`${wrapperClassname ? wrapperClassname : ""}`}>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <ReactDatePicker
        dateFormat="M/d/yyyy"
        id={name}
        minDate={minDate}
        onChange={onChange}
        placeholderText="Select a date"
        required
        selected={value}
        showPopperArrow={false}
        wrapperClassName="w-100%"
      />
    </div>
  )
}

MinuteOfDaySelect.propTypes = {
  wrapperClassname: string,
  id: number,
  label: string,
  value: string,
  onChange: func,
}

function MinuteOfDaySelect({
  wrapperClassname,
  id,
  label,
  value,
  onChange,
  ...props
}) {
  return (
    <div className={`${wrapperClassname ? wrapperClassname : ""}`}>
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <select className="select" value={value} onChange={onChange} {...props}>
        <option value="0">Start of day</option>
        <option value="390">06:30 AM</option>
        <option value="420">07:00 AM</option>
        <option value="450">07:30 AM</option>
        <option value="480">08:00 AM</option>
        <option value="510">08:30 AM</option>
        <option value="540">09:00 AM</option>
        <option value="570">09:30 AM</option>
        <option value="600">10:00 AM</option>
        <option value="630">10:30 AM</option>
        <option value="660">11:00 AM</option>
        <option value="690">11:30 AM</option>
        <option value="720">Noon</option>
        <option value="750">12:30 PM</option>
        <option value="780">01:00 PM</option>
        <option value="810">01:30 PM</option>
        <option value="840">02:00 PM</option>
        <option value="870">02:30 PM</option>
        <option value="900">03:00 PM</option>
        <option value="930">03:30 PM</option>
        <option value="960">04:00 PM</option>
        <option value="990">04:30 PM</option>
        <option value="1020">05:00 PM</option>
        <option value="1050">05:30 PM</option>
        <option value="1080">06:00 PM</option>
        <option value="1110">06:30 PM</option>
        <option value="1140">07:00 PM</option>
        <option value="1170">07:30 PM</option>
        <option value="1200">08:00 PM</option>
        <option value="1230">08:30 PM</option>
        <option value="1260">09:00 PM</option>
        <option value="1290">09:30 PM</option>
        <option value="1320">10:00 PM</option>
        <option value="1350">10:30 PM</option>
        <option value="1380">11:00 PM</option>
        <option value="1410">11:30 PM</option>
        <option value="1439">End of day</option>
      </select>
    </div>
  )
}

// builds an ISO 8601 string from a native Date & minute of day offset
function buildISOStringFromDateAndMinuteOfDay({
  date,
  minuteOfDay,
  seconds = 0,
  timeZone,
}) {
  return moment
    .tz(simpleDateString(date), timeZone)
    .add(minuteOfDay, "minutes")
    .add(seconds, "seconds")
    .toISOString()
}

// formats a native Date (in browser's timezone) as zone-less date string
function simpleDateString(date) {
  return moment(date).format("YYYY-MM-DD")
}
