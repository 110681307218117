import { css } from "@emotion/react"
import { useContext } from "react"
import { fontSize, size } from "@planningcenter/system"
import { WebBootedHomePage } from "source/publishing/Page"
import { colors } from "source/shared/colors"
import { useHiddenHeader, mapReachMenuLinkProps } from "source/Layout"
import { useQRCode } from "source/shared/QRCode"
import { extractMenuItems } from "source/shared/extractMenuItems"
import { WebBootContext } from "source/publishing/WebBoot"
import reject from "lodash/reject"

export function Home() {
  const { draftMode, publishedHomePage, draftHomePage } =
    useContext(WebBootContext)
  const page = draftMode ? draftHomePage : publishedHomePage

  useQRCode()

  return <WebBootedHomePage page={page} notFound={<FallbackHomePage />} />
}

function FallbackHomePage() {
  useHiddenHeader()
  const {
    currentOrganization,
    currentPerson,
    draftMenu,
    draftMode,
    publishedMenu,
  } = useContext(WebBootContext)

  const organizationDisplayName = currentOrganization.attributes.name
  const organizationImageUrl = currentOrganization.attributes.avatar_url
  const menu = draftMode
    ? extractMenuItems(draftMenu)
    : extractMenuItems(publishedMenu)
  let extraLinks = [
    currentPerson.id === "anonymous"
      ? { href: "/login", children: "Login" }
      : { href: "/me", children: "Me" },
  ]
  extraLinks = reject(extraLinks, (link) =>
    menu.find((l) => l.href === link.href),
  )
  const links = [...menu, ...extraLinks].map(mapReachMenuLinkProps)

  return (
    <div css={styles.layout}>
      <div css={styles.wrapper}>
        <div css={styles.logo}>
          {organizationImageUrl ? (
            <img
              src={organizationImageUrl}
              alt={organizationDisplayName}
              css={styles.logoImg}
            />
          ) : (
            organizationDisplayName
          )}
        </div>
        <nav css={styles.nav}>
          {links.map(({ as: Component, ...rest }, index) => (
            <div key={index}>
              <Component {...rest} css={styles.navItem} />
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}

const styles = {
  layout: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  wrapper: css`
    align-items: center;

    @media (min-width: 600px) {
      display: flex;
    }
  `,
  logo: css`
    flex: 1;
    margin-bottom: ${size(3)}px;
    text-align: center;

    @media (min-width: 600px) {
      margin-bottom: 0;
      padding-right: ${size(3)}px;
      text-align: left;
    }
  `,
  logoImg: css`
    max-height: 140px;
  `,
  nav: css`
    flex: 1;
    padding: ${size(4)}px 0;
    text-align: center;

    @media (min-width: 600px) {
      border-left: 1px solid ${colors.tint5};
      padding-left: ${size(4)}px;
      min-height: 140px;
    }
  `,
  navItem: css`
    color: ${colors.tint2};
    display: inline-block;
    font-size: ${fontSize(4)}px;
    font-weight: 500;
    margin: 0 0 ${size(2)}px 0;
  `,
}
