import { css } from "@emotion/react"
import { string, shape, bool, func } from "source/shared/prop_types"
import { Date, Datetime } from "source/shared/DatetimeFmt"
import { Avatar } from "source/shared/components"
import TopicMenu from "source/groups/messaging/Topic/TopicMenu"
import { Heading } from "@planningcenter/doxy-web"

MessageProfileHeader.propTypes = {
  title: string.isRequired,
  author: shape({
    attributes: shape({
      avatar_url: string.isRequired,
      name: string.isRequired,
    }).isRequired,
  }),
  createdAt: string.isRequired,
  updatedAt: string,
  showMenu: bool,
  toggleRepliesEnabled: func,
}

export default function MessageProfileHeader({
  title,
  author,
  createdAt,
  updatedAt,
  showMenu,
  toggleRepliesEnabled,
}) {
  const { avatar_url, name } = author.attributes

  return (
    <div className="d-f">
      <div className="avatar w-7 h-7 mr-2">
        <Avatar url={avatar_url} alt={`profile image for ${name}`} />
      </div>
      <div className="d-f fd-c f-1">
        <div className="d-f jc-sb">
          <div className="wb-bw mr-1">
            <Heading level={1} size={2} text={title} />
          </div>
          {showMenu && (
            <TopicMenu toggleRepliesEnabled={toggleRepliesEnabled} />
          )}
        </div>
        <div css={styles.subText}>{name}</div>
        <div css={styles.subText}>
          <Date start={createdAt} style="relative" />
          {updatedAt && (
            <>
              {" "}
              <span>
                (updated <Datetime start={updatedAt} style="relative-short" />)
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  subText: css`
    font-size: 14px;
    color: var(--color-tint2);
  `,
}
