import {
  Suspense,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import { css } from "@emotion/react"
import { useNavigate, useParams } from "react-router-dom"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { useSession } from "source/shared/hooks/useSession"
import { useJolt } from "source/shared/hooks/useJolt"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"
import { RepliesDataContext } from "source/groups/messaging/Topic/RepliesDataContext"
import {
  apiResource,
  arrayOf,
  bool,
  func,
  id,
  object,
  string,
} from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { getRelationship } from "source/shared/getRelationship"
import TabView from "source/groups/my/groups/TabView"
import Topic, { BOT_AUTHOR_ID } from "source/groups/messaging/Topic"
import Replies from "source/groups/messaging/Topic/Replies"
import NewReply from "source/groups/messaging/Topic/NewReply"
import { Reply } from "source/groups/my/groups/messages/Reply"
import MessageProfileHeader from "source/groups/my/groups/messages/MessageProfileHeader"
import { Files } from "source/shared/components/messaging/Files"
import { Avatar, Icon, Loading } from "source/shared/components"
import spinner from "source/svg/spinner.svg"
import { unlinkify } from "source/shared/linkify"
import { sanitize } from "source/groups/messaging/utils"
import { ReactionPicker } from "source/groups/my/groups/messages/ReactionPicker"
import { ReactionsSummary } from "source/groups/my/groups/messages/ReactionsSummary"
import InfiniteScroll from "react-infinite-scroller"
import { ReactionRow } from "source/groups/my/groups/messages/ReactionRow"
import { Heading } from "@planningcenter/doxy-web"

import "./styles.css"

export default function MessagesShow() {
  const { topicId } = useParams()
  const { group, setCurrentTopicId } = useContext(TopicsDataContext)

  useEffect(() => {
    setCurrentTopicId(topicId)
    return () => setCurrentTopicId(undefined)
  }, [topicId])

  const topicUrl = `/groups/v2/me/groups/${group.id}/forum_topics/${topicId}?include=author,most_recent_reply,most_recent_reply.author`

  return (
    <TabView showActions={false}>
      <Message fetchTopicUrl={topicUrl} />
    </TabView>
  )
}

function Message({ fetchTopicUrl }) {
  const { group, included, currentTopic, ingest } =
    useContext(TopicsDataContext)
  const topicJson = useApiRead(fetchTopicUrl)

  useEffect(() => {
    ingest(topicJson)
  }, [topicJson])

  if (!currentTopic) return null

  const author = getRelationship({ data: currentTopic, included }, "author")

  const {
    abilities: { can_update, can_delete },
    title,
    replies_enabled,
    created_at,
    content_updated_at,
  } = currentTopic.attributes
  const showMenu = can_update || can_delete

  function toggleRepliesEnabled() {
    const confirmationMessage = `Are you sure you want to ${
      replies_enabled ? "disable" : "enable"
    } replies?`

    if (confirm(confirmationMessage)) {
      const payload = {
        data: {
          attributes: { replies_enabled: !replies_enabled },
        },
      }
      sessionApiClient
        .patch(currentTopic.links.self, payload)
        .then((json) => ingest(json))
    }
  }

  return (
    <section
      className="mygroups__messaging"
      css={messageStyles.messagingContainer}
    >
      <div className="d-f fd-c jc-fe p-a t-0 b-0 l-0 r-0">
        <div>
          <MessageProfileHeader
            title={title}
            author={author}
            createdAt={created_at}
            updatedAt={content_updated_at}
            showMenu={showMenu}
            toggleRepliesEnabled={toggleRepliesEnabled}
          />
          <hr css={messageStyles.hr} />
        </div>
        <Topic group={group} topic={currentTopic}>
          <div css={messageStyles.topicContainer}>
            <TopicMessage />
            <Replies RepliesChunk={RepliesChunk} />
            <KeepReadReceiptUpToDate topic={currentTopic} />
          </div>
          {replies_enabled && (
            <NewReply>
              <NewReply.FilePreviews />
              <NewReply.Editor>
                <NewReply.AddFilesButton />
                <NewReply.MultiLineInput />
              </NewReply.Editor>
            </NewReply>
          )}
        </Topic>
      </div>
    </section>
  )
}
Message.propTypes = {
  fetchTopicUrl: string,
}
Message.joltEvents = [
  "groups.church_center.v2.events.forum_topic_reaction.created",
  "groups.church_center.v2.events.forum_topic_reaction.updated",
  "groups.church_center.v2.events.forum_topic_reaction.destroyed",
]
const messageStyles = {
  messagingContainer: css`
    position: relative;
    /* our best guess at the height we need to not scroll... */
    height: calc(100vh - (136px + 57px + 32px + 31px + 17px));

    @media (min-width: 720px) : {
      height: calc(100vh - (136px + 57px + 32px + 31px + 32px));
    }
  `,
  hr: css`
    background: none;
    border-top: 0;
    border-bottom: 1px solid var(--color-tint5);
    height: 1px;
    margin-bottom: 0;
    width: 100%;
  `,
  topicContainer: css`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-grow: 1;
    overflow: auto;
    padding: 1.5rem 0.5rem;
  `,
}

function KeepTopicReactionsUpToDate({ topic }) {
  const { ingest } = useContext(TopicsDataContext)

  const refetchTopic = useCallback(() => {
    sessionApiClient.get(topic.links.self).then((json) => ingest(json))
  }, [ingest, topic])

  useJolt(
    `${topic.links.self}/jolt_subscribe`,
    `church_center.groups.messaging.topics.${topic.id}`,
    Message.joltEvents,
    refetchTopic,
  )

  return null
}

function useDialog(initiallyVisible = false) {
  const [isVisible, setIsVisible] = useState(initiallyVisible)
  const cancelRef = useRef(null)

  const open = () => setIsVisible(true)
  const close = () => setIsVisible(false)

  return { isVisible, cancelRef, open, close }
}

function ReactionsDialog({ cancelRef, onClose }) {
  const { currentTopic: topic } = useContext(TopicsDataContext)
  const reactionsTotalCount = topic.attributes.reactions_summary.total_count

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel css={reactionsDialogStyles.label}>
        Reactions
      </AlertDialogLabel>
      <AlertDialogDescription>
        <button
          className="modal__close-button"
          aria-label="Close modal"
          css={reactionsDialogStyles.button}
          onClick={onClose}
          ref={cancelRef}
        >
          <Icon symbol="general#x" />
        </button>
        <div
          css={[
            reactionsDialogStyles.contentContainer,
            { paddingRight: reactionsTotalCount > 7 ? "1rem" : "0.5rem" },
          ]}
        >
          <Suspense fallback={<Loading />}>
            <ReactionsList reactionsUrl={`${topic.links.self}/reactions`} />
          </Suspense>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}
ReactionsDialog.propTypes = {
  cancelRef: object,
  onClose: func,
}
const reactionsDialogStyles = {
  label: css`
    width: calc(100% - 50px);
  `,
  button: css`
    top: 1.5rem;
  `,
  contentContainer: css`
    overflow-y: auto;
    maxheight: 400;
    margintop: 0.5rem;
  `,
}

function ReactionsList({ reactionsUrl }) {
  const {
    records: reactions,
    included,
    hasMore,
    loadMore,
  } = useInfiniteLoadingResource(
    `${reactionsUrl}?include=actor&sort=reacted_at`,
  )

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
      css={reactionListStyles.scroll}
    >
      {reactions.map((reaction) => {
        const actor = getRelationship({ data: reaction, included }, "actor")
        return (
          <ReactionRow
            key={reaction.id}
            avatarUrl={actor.attributes.avatar_url}
            actorName={actor.attributes.name}
            reactionName={reaction.attributes.name}
          />
        )
      })}
    </InfiniteScroll>
  )
}
ReactionsList.propTypes = {
  reactionsUrl: string,
}
const reactionListStyles = {
  scroll: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    overflow: hidden;
  `,
}

function KeepReadReceiptUpToDate({ topic }) {
  const { totalCount } = useContext(RepliesDataContext)
  useEffect(() => {
    if (topic) {
      sessionApiClient.post(`${topic.links.self}/read`)
    }
  }, [totalCount])
  return null
}

function TopicMessage() {
  const dialog = useDialog()
  const { included, currentTopic } = useContext(TopicsDataContext)
  const { message } = currentTopic.attributes

  if (sanitize(message) === "<p>&nbsp;</p>") return null

  const myReaction = getRelationship(
    { data: currentTopic, included },
    "my_reaction",
  )

  function setMyReaction(name) {
    sessionApiClient
      .post(`${currentTopic.links.self}/react`, {
        data: { attributes: { name } },
      })
      .catch(() => alert("Oops, something went wrong. Please try again."))
  }

  function deleteMyReaction() {
    sessionApiClient
      .del(myReaction.links.self)
      .catch(() => alert("Oops, something went wrong. Please try again."))
  }

  return (
    <div>
      <article
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
        css={topicMessageStyles.article}
      />
      <div className="d-f mt-4p">
        <ReactionPicker
          value={myReaction?.attributes?.name}
          onSelect={setMyReaction}
          onClear={deleteMyReaction}
        />
        <ReactionsSummary
          {...currentTopic.attributes.reactions_summary}
          isHighlighted={Boolean(myReaction)}
          onClick={dialog.open}
        />
        <KeepTopicReactionsUpToDate topic={currentTopic} />
        {dialog.isVisible && (
          <ReactionsDialog
            cancelRef={dialog.cancelRef}
            onClose={dialog.close}
          />
        )}
      </div>
    </div>
  )
}
const topicMessageStyles = {
  article: css`
    wordbreak: break-word;
    p {
      margin-bottom: 0;
    }
  `,
}

export function AuthorAvatar({ author }) {
  const { avatar_url, name } = author.attributes

  return (
    <div className="avatar w-5 h-5">
      {author.knownBestAvatarUrl ? (
        <Avatar url={avatar_url} alt={`profile image for ${name}`} />
      ) : (
        <img src={spinner} alt="Loading" />
      )}
    </div>
  )
}
AuthorAvatar.propTypes = {
  author: apiResource,
}

function AuthorRow({ author, isLeader }) {
  return (
    <div className="d-f ai-c mb-1 g-1">
      <AuthorAvatar author={author} />
      <div className="d-f fd-c jc-c">
        <div className="lh-1">
          <Heading
            level={4}
            size={6}
            color="tint2"
            text={author.attributes.name}
          />
        </div>
        {isLeader && <span className="fs-5 c-tint2 mb-2p">leader</span>}
      </div>
    </div>
  )
}
AuthorRow.propTypes = {
  author: apiResource,
  isLeader: bool,
}

function RepliesChunk({ chunk, author, leaderIds, leaderLabel }) {
  const { data: currentPerson } = useSession(true)
  const navigate = useNavigate()
  const { group, currentTopic: topic } = useContext(TopicsDataContext)

  if (author.id === BOT_AUTHOR_ID) {
    return chunk.map((reply) => <BotReply key={reply.id} reply={reply} />)
  }

  const myReply = currentPerson.id === author.id

  return (
    <div className="px-4p p-r">
      {!myReply && (
        <AuthorRow author={author} isLeader={leaderLabel === "Leader"} />
      )}
      <div className="d-f fd-c f-1 pl-6 g-2">
        {chunk.map((reply) => {
          const { id, myReaction } = reply
          const { reactions_summary } = reply.attributes

          function goToReply() {
            navigate(`${group.base_path}/messages/${topic.id}/replies/${id}`)
          }

          function setMyReaction(name) {
            sessionApiClient
              .post(`${reply.links.self}/react`, {
                data: { attributes: { name } },
              })
              .catch(() =>
                alert("Oops, something went wrong. Please try again."),
              )
          }

          function deleteMyReaction() {
            sessionApiClient
              .del(myReaction.links.self)
              .catch(() =>
                alert("Oops, something went wrong. Please try again."),
              )
          }

          return (
            <div key={reply.id}>
              <ReplyChunk
                reply={reply}
                myReply={myReply}
                author={author}
                leaderIds={leaderIds}
              />
              <div className={`d-f ai-c mt-4p ${myReply ? "fd-rr" : "fd-r"}`}>
                <ReactionPicker
                  value={myReaction?.attributes?.name}
                  onSelect={setMyReaction}
                  onClear={deleteMyReaction}
                />
                <ReactionsSummary
                  {...reactions_summary}
                  isHighlighted={Boolean(myReaction)}
                  onClick={goToReply}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
RepliesChunk.propTypes = {
  chunk: arrayOf(apiResource).isRequired,
  author: apiResource,
  leaderIds: arrayOf(id),
  leaderLabel: string,
}

function ReplyChunk({ reply, myReply, author, leaderIds }) {
  const { data: currentPerson } = useSession(true)
  const currentPersonIsLeader = leaderIds.includes(currentPerson.id)

  const { id, pending } = reply
  const { message, files } = reply.attributes

  const imageOnly = unlinkify(message) === "" && files?.length > 0
  const canEdit = !pending && myReply && !imageOnly
  const canDelete = !pending && (myReply || currentPersonIsLeader)

  return (
    <Reply
      key={id}
      reply={reply}
      author={author}
      canEdit={canEdit}
      canDelete={canDelete}
      myReply={myReply}
    >
      <Files reply={reply} />
      <Reply.Message />
    </Reply>
  )
}
ReplyChunk.propTypes = {
  author: apiResource,
  leaderIds: arrayOf(id),
  myReply: bool.isRequired,
  reply: apiResource.isRequired,
}

function BotReply({ reply }) {
  return <p className="c-tint3 fs-4 m-0 ta-c">{reply.attributes.message}</p>
}
BotReply.propTypes = {
  reply: apiResource.isRequired,
}
