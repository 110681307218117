import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { get as dig } from "lodash"
import { CurrentEventContext } from "source/groups/my/groups"
import { useApiRead } from "source/shared/SessionApiResource"
import { Loading } from "source/shared/components"

export default function MyNote() {
  const routerLocation = useLocation()
  const navigate = useNavigate()
  const { event, base_path: eventPath } = useContext(CurrentEventContext)

  const myNoteResponse = useApiRead(event.links.my_note)
  const myNoteExists = dig(myNoteResponse, "errors[0].status") !== "404"

  const link = myNoteExists
    ? `${eventPath}/notes/${dig(myNoteResponse, "data.id")}/edit`
    : `${eventPath}/notes/new`

  const { returnTo } = routerLocation.state || {}
  navigate(link, { state: { returnTo }, replace: true })

  return <Loading message="Loading" />
}
