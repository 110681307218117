import { object } from "prop-types"
import { Route, Routes, useLocation } from "react-router-dom"
import Confirmation from "./Confirmation"
import AttendeeInformationForm from "./AttendeeInformationForm"

const Registrations = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route
        path="/confirmation"
        element={
          <Confirmation
            isNotificationShowing={location.state?.showNotification}
          />
        }
      />
      <Route
        path="/attendee_info/:attendeeId"
        element={<AttendeeInformationForm />}
      />
    </Routes>
  )
}

Registrations.propTypes = {
  location: object,
}

export default Registrations
