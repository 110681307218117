import { useEffect } from "react"
import { array, func } from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"

const SCHEDULE_ID_QUERY_PARAM = "schedule_id"
const RESPONSE_QUERY_PARAM = "response"
const RESPONSE_ACCEPT = "accept"
const RESPONSE_DECLINE = "decline"
const STATUS_CONFIRMED = "C"
const STATUS_UNCONFIRMED = "U"

const replaceUrl = (location, navigate) => {
  navigate(location.pathname, { replace: true })
}

const parseQueryParam = (queryParamName) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(queryParamName)
}

const parseQueryParamId = (queryParamName) => {
  const value = parseQueryParam(queryParamName)
  return value ? parseInt(value, 10) : undefined
}

const shouldAccept = (schedules) => {
  return (
    parseQueryParam(RESPONSE_QUERY_PARAM) === RESPONSE_ACCEPT &&
    schedules.length === 1 &&
    schedules[0].status === STATUS_UNCONFIRMED &&
    parseInt(schedules[0].id, 10) === parseQueryParamId(SCHEDULE_ID_QUERY_PARAM)
  )
}

const shouldDecline = (schedules) => {
  return (
    parseQueryParam(RESPONSE_QUERY_PARAM) === RESPONSE_DECLINE &&
    schedules.length === 1 &&
    (schedules[0].status === STATUS_CONFIRMED ||
      schedules[0].status === STATUS_UNCONFIRMED) &&
    parseInt(schedules[0].id, 10) === parseQueryParamId(SCHEDULE_ID_QUERY_PARAM)
  )
}

export default function HandleSchedulingRequestEmails({
  handleDeclineSchedules,
  schedules,
  setReasonModalProps,
  setShowAcceptModal,
  setShowReasonModal,
}) {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (shouldAccept(schedules)) {
      replaceUrl(location, navigate)
      setShowAcceptModal(true)
    } else if (shouldDecline(schedules)) {
      replaceUrl(location, navigate)
      setShowReasonModal(true)
      setReasonModalProps({
        allDeclined: true,
        declinedCount: 1,
        handleSubmit: handleDeclineSchedules,
      })
    }
  }, [])

  return null
}

HandleSchedulingRequestEmails.propTypes = {
  handleDeclineSchedules: func,
  schedules: array,
  setReasonModalProps: func,
  setShowAcceptModal: func,
  setShowReasonModal: func,
}
