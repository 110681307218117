import { useState, useEffect, useRef } from "react"
import { object } from "prop-types"
import NamedAttendeeCost from "./NamedAttendeeCost"
import NamedAttendeeForms from "./NamedAttendeeForms"
import NamedAttendeeQuestions from "./NamedAttendeeQuestions"
import NamedAttendeePersonalInformation from "./NamedAttendeePersonalInformation"
import { Avatar } from "source/shared/components"
import { AlertMessage } from "source/shared/components"
import { FormButtons } from "./FormButtons"

const NamedAttendee = ({ attendeeSummary, registration }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [changes, setChanges] = useState({})
  const [saves, setSaves] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(null)

  const { id: attendeeId } = attendeeSummary
  const {
    person: { id: accountCenterId },
  } = registration.attendees.find((attendee) => {
    return attendee.id == attendeeId
  })

  const { allowedAccountCenterIds } = registration
  const canEditAttendee = allowedAccountCenterIds.includes(accountCenterId)

  const handleChange = (changed) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      ...changed,
    }))
  }

  const thingsThatChanged = Object.keys(changes).filter((key) => changes[key])
  const hasAnythingChanged = thingsThatChanged.length > 0

  const handleSaveClick = () => {
    if (Object.values(changes).some((change) => change)) {
      setSaves({})
      setIsSaving(true)
      attendeeQuestionsRef.current.save()
      attendeePersonalInfoRef.current.save()
    }
  }

  const handleEditClick = () => {
    setChanges({})
    setIsSaving(false)
    setIsEditing(true)
  }

  const handleSave = (saved) => {
    setSaves((prevSaves) => ({
      ...prevSaves,
      ...saved,
    }))
  }

  useEffect(() => {
    if (isEditing && isSaving && thingsThatChanged) {
      if (thingsThatChanged.every((key) => saves[key])) {
        setChanges({})
        setSaves({})
        setIsSaving(false)
        setIsEditing(false)
        setError(null)
        registration.invalidate()
      }
    }
  }, [saves, changes, isEditing, isSaving, thingsThatChanged, registration])

  const {
    photoThumbnailUrl,
    personName,
    profileInfoStatus,
    attendeeInfoQuestionsStatus,
    attendeeQuestionsStatus,
    selectionName,
    canceled,
  } = attendeeSummary

  const incomplete = [
    profileInfoStatus,
    attendeeQuestionsStatus,
    attendeeInfoQuestionsStatus,
  ].some((status) => status == "incomplete")

  const attendeeSelectionEdit = {
    changes,
    onChange: handleChange,
    onSave: handleSave,
    onError: setError,
    isEditing,
  }

  const attendeeQuestionsRef = useRef(null)
  const attendeePersonalInfoRef = useRef(null)

  return (
    <div
      className="p-2 g-3 mb-3"
      css={{ backgroundColor: "var(--color-tint9)" }}
    >
      <div className={`d-f ai-c jc-sb mb-3`}>
        <div className={`d-f ai-c g-1`}>
          <div className={`d-f ai-c`}>
            <div className="avatar mr-1 w-4 h-4 t-3p">
              <Avatar
                url={photoThumbnailUrl}
                alt={`profile image for ${personName}`}
              />
            </div>
            <h2 className={`h4`}>{personName}</h2>
          </div>
          {incomplete && (
            <div>
              <AlertMessage
                className="sm-alert mb-0"
                type="warning"
                icon="general#exclamation-triangle"
              >
                Incomplete
              </AlertMessage>
            </div>
          )}
        </div>

        <FormButtons
          handleCancel={() => setIsEditing(false)}
          handleSave={handleSaveClick}
          handleEdit={handleEditClick}
          canSave={hasAnythingChanged}
          canEdit={canEditAttendee}
          isEditing={isEditing}
          isLoading={isSaving}
        />
      </div>
      <div className={`mb-1`}>
        <span style={{ color: "initial" }}>{selectionName}</span>
        {canceled && (
          <span className={`ml-1 danger-badge badge`}>Canceled</span>
        )}
      </div>
      <NamedAttendeeCost attendeeSummary={attendeeSummary} />
      <NamedAttendeePersonalInformation
        attendeeSummary={attendeeSummary}
        attendeeSelectionEdit={attendeeSelectionEdit}
        ref={attendeePersonalInfoRef}
      />
      <NamedAttendeeQuestions
        attendeeSummary={attendeeSummary}
        registration={registration}
        isEditing={isEditing}
        isSaving={isSaving}
        onChange={handleChange}
        onSave={handleSave}
        ref={attendeeQuestionsRef}
      />
      <NamedAttendeeForms attendeeSummary={attendeeSummary} />
      {error}
    </div>
  )
}

NamedAttendee.propTypes = {
  attendeeSummary: object,
  registration: object,
}

export default NamedAttendee
