import { string } from "source/shared/prop_types"
import { Avatar } from "source/shared/components"
import { emojis } from "source/groups/my/groups/messages/ReactionPicker"
import { Heading } from "@planningcenter/doxy-web"

ReactionRow.propTypes = {
  avatarUrl: string.isRequired,
  actorName: string.isRequired,
  reactionName: string.isRequired,
}

export function ReactionRow({ avatarUrl, actorName, reactionName }) {
  return (
    <div className="d-f">
      {/* d-f on the div below fixes an overflow scrolling bug */}
      <div className="d-f w-5 h-5 mr-2">
        <Avatar url={avatarUrl} alt={`profile image for ${actorName}`} />
      </div>
      <div className="d-f fd-c jc-c f-1">
        <div className="d-f jc-sb ai-c wb-bw">
          <Heading level={4} color="tint1" text={actorName} />
          <span css={{ fontSize: "1.5rem" }}>{emojis[reactionName]}</span>
        </div>
      </div>
    </div>
  )
}
