import { array, bool, string, shape, number } from "prop-types"
import { Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

const NamedAttendeeForms = ({ attendeeSummary }) => {
  const {
    requiredForms,
    hasPubliclyAvailableAttendeeInfoQuestions,
    missingFormIds,
    canEditAttendee,
    id: attendeeId,
    registrationId,
    formsCount,
    attendeeInfoQuestionsStatus,
  } = attendeeSummary

  const hasForms = formsCount && formsCount > 0
  const hasAttendeeInfoForm = hasPubliclyAvailableAttendeeInfoQuestions

  if (!hasForms && !hasAttendeeInfoForm) {
    return null
  }

  return (
    <div>
      <div className="mt-3 mb-1">
        <Heading level={3} size={4} text="Additional Forms" />
      </div>
      {formsCount > 0 && (
        <>
          {requiredForms.map((form) => (
            <div key={form.id} className={`d-f`}>
              {form.attendeeRemoteUrl || form.remoteUrl ? (
                <>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={form.attendeeRemoteUrl || form.remoteUrl}
                  >
                    {form.name}
                  </a>
                  <span className={`ml-4p`}>(Online)</span>
                </>
              ) : (
                <>
                  {form?.attachment?.url && (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={form.attachment.url}
                      >
                        {form.name}
                      </a>
                      <span className={`ml-4p`}>(PDF)</span>
                    </>
                  )}
                </>
              )}
              {missingFormIds.includes(form.id) ? (
                <span className={`mr-1 c-marigold`}>
                  <span
                    className={`pl-6p pr-1p ml-1`}
                    style={{ fontSize: "12px", verticalAlign: "middle" }}
                  >
                    <Icon
                      symbol="general#exclamation-triangle"
                      className={`fs-8 symbol`}
                      aria-hidden
                    />
                  </span>
                </span>
              ) : (
                <span className={`mr-1 ml-1 c-emerald`}>
                  <Icon
                    symbol="general#check"
                    className={`fs-8`}
                    style={{ verticalAlign: "middle" }}
                    aria-hidden
                  />
                </span>
              )}
            </div>
          ))}
        </>
      )}
      {hasAttendeeInfoForm && (
        <div className={`d-f`}>
          {canEditAttendee ? (
            <a
              href={`/registrations/registrations/${registrationId}/attendee_info/${attendeeId}`}
            >
              Attendee Information
            </a>
          ) : (
            <>Attendee Information</>
          )}
          {attendeeInfoQuestionsStatus == "incomplete" ? (
            <span className={`mr-1 c-marigold ml-1`}>
              <span
                className={`pl-6p pr-1p`}
                style={{ fontSize: "12px", verticalAlign: "middle" }}
              >
                <Icon
                  symbol="general#exclamation-triangle"
                  className={`fs-8 symbol`}
                  aria-hidden
                />
              </span>
            </span>
          ) : (
            <span className={`mr-1 ml-1 c-emerald`}>
              <Icon
                symbol="general#check"
                className={`fs-5`}
                style={{ verticalAlign: "middle" }}
                aria-hidden
              />
            </span>
          )}
        </div>
      )}
    </div>
  )
}

NamedAttendeeForms.propTypes = {
  attendeeSummary: shape({
    requiredForms: array,
    hasPubliclyAvailableQuestions: bool,
    hasPubliclyAvailableAttendeeInfoQuestions: bool,
    questionsStatus: string,
    attendeeInfoQuestionsStatus: string,
    profileInfoStatus: string,
    missingFormIds: array,
    canEditAttendee: bool,
    id: number,
    registrationId: number,
    formsCount: number,
  }),
}

export default NamedAttendeeForms
