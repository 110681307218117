import { Link } from "react-router-dom"
import { Avatar } from "source/shared/components"
import { useSession } from "source/shared/hooks/useSession"
import { Heading } from "@planningcenter/doxy-web"

export function MyInformation() {
  const currentPerson = useSession()
  const { avatar_url, name, email_address } = currentPerson.data.attributes

  return (
    <div className="mb-2">
      <Heading level={2} size={4} text="My information" />
      <div className="d-f ai-c jc-sb action-drawer mt-1">
        <div className="d-f">
          <div className="w-6 h-6 mr-2">
            <Avatar url={avatar_url} alt={name} size={6} />
          </div>
          <div className="d-f fd-c">
            <div>{name}</div>
            <div className="fs-13 c-tint2">{email_address}</div>
          </div>
        </div>
        <Link to="/logout" className="minor-compact-btn btn">
          Log Out
        </Link>
      </div>
    </div>
  )
}
