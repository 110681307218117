import { useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import CustomField from "./CustomField"

NumberField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      placeholder: string,
      required: bool.isRequired,
      settings: shape({
        max: number,
        min: number,
      }),
    }),
    id: string.isRequired,
  }),
  onChange: func.isRequired,
}

export default function NumberField({ errors, field, onChange }) {
  const {
    attributes: { description, label, placeholder, required, settings },
    id,
  } = field
  const { max, min } = settings
  const [value, setValue] = useState("")
  const inputId = `field_${id}_value`

  const handleChange = ({ target: { value } }) => {
    setValue(value)
    onChange(value, id)
  }

  const minMaxLabel = [min && `min: ${min}`, max && `max: ${max}`]
    .filter(Boolean)
    .join(" / ")

  const numberFieldLabel = (
    <>
      {min || max ? (
        <div className="d-f ai-c">
          <div className="f-1">{label}</div>
          <div className="f-0 fs-13 c-tint2">{minMaxLabel}</div>
        </div>
      ) : (
        label
      )}
    </>
  )

  return (
    <CustomField
      label={numberFieldLabel}
      {...{ description, errors, required }}
    >
      <input
        id={inputId}
        max={max ? max.toString() : null}
        min={min ? min.toString() : null}
        onChange={handleChange}
        onWheel={(e) => e.target.blur()}
        placeholder={placeholder}
        type="number"
        value={value.toString()}
      />
    </CustomField>
  )
}
