import { Suspense, useContext } from "react"
import { useParams } from "react-router-dom"
import { useSession } from "source/shared/hooks/useSession"
import { css } from "@emotion/react"
import InfiniteScroll from "react-infinite-scroller"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"
import { string, array, number, shape } from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { getRelationship } from "source/shared/getRelationship"
import TabView from "source/groups/my/groups/TabView"
import { Reply } from "source/groups/my/groups/messages/Reply"
import MessageProfileHeader from "source/groups/my/groups/messages/MessageProfileHeader"
import { Files } from "source/shared/components/messaging/Files"
import { Loading } from "source/shared/components"
import { ReactionRow } from "source/groups/my/groups/messages/ReactionRow"
import { Heading } from "@planningcenter/doxy-web"

import BlankState from "source/groups/BlankState"
import "source/groups/my/groups/messages/styles.css"

export default function ReplyShow() {
  const { topicId, replyId } = useParams()
  return (
    <Suspense fallback={<Loading />}>
      <TabView showActions={false}>
        <Container topicId={topicId} replyId={replyId} />
      </TabView>
    </Suspense>
  )
}

Container.propTypes = {
  topicId: string.isRequired,
  replyId: string.isRequired,
}
function Container({ topicId, replyId }) {
  const { data: currentPerson } = useSession()
  const { group } = useContext(TopicsDataContext)
  const reply = useApiRead(
    `/groups/v2/me/groups/${group.id}/forum_topics/${topicId}/replies/${replyId}?include=author,my_reaction,topic`,
  )
  const topic = getRelationship(
    { data: reply.data, included: reply.included },
    "topic",
  )
  const author = getRelationship(
    { data: reply.data, included: reply.included },
    "author",
  )

  const { created_at, content_updated_at } = reply.data.attributes

  return (
    <section className="mygroups__messaging reply-detail__container">
      <MessageProfileHeader
        title={topic.attributes.title}
        author={author}
        createdAt={created_at}
        updatedAt={content_updated_at}
      />

      <Reply
        key={reply.data.id}
        reply={reply.data}
        author={author}
        canEdit={false}
        canDelete={false}
        myReply={author.id === currentPerson.id}
      >
        <Files reply={reply.data} />
        <Reply.Message />
      </Reply>

      <hr css={styles.lineBreak} />

      <ReactionsList reply={reply} groupId={group.id} topicId={topic.id} />
    </section>
  )
}

function reactionsWithActors(reply, reactions, included, currentPerson) {
  const myReaction = getRelationship(
    {
      data: reply.data,
      included: reply.included.filter(({ type }) => type === "Reaction"),
    },
    "my_reaction",
  )

  const reactionsWithActors = [myReaction]
    .concat(reactions)
    .filter((r) => r?.id !== undefined)
    .map((r) => {
      r.actor =
        r.id === myReaction?.id
          ? currentPerson
          : getRelationship({ data: r, included }, "actor")
      return r
    })

  return reactionsWithActors
}

ReactionsList.propTypes = {
  reply: shape({
    included: array.isRequired,
    data: shape({
      attributes: shape({
        reactions_summary: shape({
          total_count: number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  groupId: string.isRequired,
  topicId: string.isRequired,
}
function ReactionsList({ reply, groupId, topicId }) {
  const {
    records: currentReactions,
    included,
    hasMore,
    loadMore,
  } = useInfiniteLoadingResource(
    `/groups/v2/me/groups/${groupId}/forum_topics/${topicId}/replies/${reply.data.id}/reactions?include=actor&order=-reacted_at`,
  )
  const { data: currentPerson } = useSession()
  const { total_count } = reply.data.attributes.reactions_summary

  const reactions = reactionsWithActors(
    reply,
    currentReactions.filter(
      (r) => r.relationships.actor.data.id !== currentPerson.id,
    ),
    included,
    currentPerson,
  )

  if (reactions.length) {
    return (
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        loader={<Loading key="loading" />}
        className="container container--narrow"
      >
        <div className="d-f fd-c f-1 g-2">
          <div className="d-f">
            <Heading level={3} text="Reactions" />
            <span css={styles.count}>{total_count}</span>
          </div>
          {reactions.map((reaction) => (
            <ReactionRow
              key={reaction.id}
              avatarUrl={reaction.actor.attributes.avatar_url}
              actorName={reaction.actor.attributes.name}
              reactionName={reaction.attributes.name}
            />
          ))}
        </div>
      </InfiniteScroll>
    )
  } else {
    return <ReactionsListBlankState />
  }
}

function ReactionsListBlankState() {
  return (
    <BlankState icon="general#heart">
      <p>There are currently no reactions for this reply.</p>
    </BlankState>
  )
}

const styles = {
  count: css`
    display: flex;
    align-items: center;
    background: var(--color-tint6);
    color: var(--color-topaz);
    margin-left: 0.5rem;
    padding: 0 0.5rem;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  `,
  lineBreak: css`
    background: none;
    border-top: 0;
    border-bottom: 1px solid var(--color-tint5);
    height: 1px;
    margin-bottom: 0;
    width: 100%;
  `,
}
