import { Component, Fragment } from "react"
import { bool } from "prop-types"
import { AlertMessage } from "source/shared/components"
import {
  FindVerifiedPerson,
  communicateErrorAsHelpfulAsPossible,
} from "source/login/FindVerifiedPerson"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { resetCache as resetNavitarCache } from "@planningcenter/cc-nav"
import { resetCache as resetWebBootCache } from "source/publishing/WebBoot"

export class LoginForm extends Component {
  static propTypes = { emailOnly: bool.isRequired }
  constructor(props) {
    super(props)
    this.state = { error: false, key: 0 }
    this.loginAndRedirect = this.loginAndRedirect.bind(this)
    this.restart = this.restart.bind(this)
  }

  componentDidCatch(error) {
    this.setState({ error: communicateErrorAsHelpfulAsPossible(error) })
  }

  async loginAndRedirect(person) {
    await sessionApiClient.login(person.data.attributes.token)
    resetNavitarCache()
    resetWebBootCache()
    window.location.reload()
  }

  restart() {
    this.setState((prev) => ({
      error: false,
      key: prev.key + 1,
    }))
  }

  render() {
    const { error, key } = this.state
    const params = new URLSearchParams(window.location.search)
    const defaultValue = params.has("value") ? params.get("value") : ""
    const autoSubmitDefaultValue = false // params.has("autosubmit") // NOTE: Disbabled temporarily. See Pull Request #287 for details.

    return error ? (
      <Fragment>
        <AlertMessage>{error}</AlertMessage>
        <div className="ta-c">
          <button
            className="secondary-btn btn mb-3 mt-3"
            onClick={this.restart}
          >
            Start over
          </button>
        </div>
      </Fragment>
    ) : (
      <FindVerifiedPerson
        key={`FindVerifiedPerson-${key}`}
        onChange={this.loginAndRedirect}
        defaultValue={defaultValue}
        autoSubmitDefaultValue={key === 0 && autoSubmitDefaultValue}
        emailOnly={this.props.emailOnly}
      />
    )
  }
}
