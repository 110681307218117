import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"

import type { UseSuspenseQueryOptions } from "@tanstack/react-query"

export function useApiSuspenseQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>({
  apiClientOptions = {},
  path,
  queryParams = {},
  queryClientOptions = {},
}: {
  apiClientOptions?: {
    authorizationHeader?: string | null
    headers?: Record<string, string>
    origin?: string
    version?: string
  }
  path: string
  queryParams?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
  queryClientOptions?: Omit<
    UseSuspenseQueryOptions<TQueryFnData, TError, TData>,
    "queryFn" | "queryKey"
  >
}) {
  return useSuspenseQuery<TQueryFnData, TError, TData>({
    ...queryClientOptions,

    queryFn: (): TQueryFnData =>
      sessionApiClient
        .get(path, queryParams, apiClientOptions)
        .catch((json: unknown) => json),
    queryKey: [...path.replace("/", "").split("/"), queryParams],
  })
}
