import React from "react"
import { object, shape, number, string } from "prop-types"
import { Avatar } from "source/shared/components"
import { Button } from "@planningcenter/doxy-web"
import {
  Modal,
  useCloseModal,
  useOpenModal,
} from "source/shared/components/Modal"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useInvalidateRegistration } from "source/registrations/hooks/useRegistration"

const WaitlistedAttendee = ({ attendeeSummary, registration }) => {
  const { photoThumbnailUrl, personName, selectionName, canceled } =
    attendeeSummary

  const attendee = registration.attendees.find(
    (attendee) => attendee.id === attendeeSummary.id,
  )

  const selectionType = attendee?.attendeeType

  return (
    <div
      className="p-2 g-3 mb-3"
      css={{ backgroundColor: "var(--color-tint9)" }}
    >
      <div className={`d-f ai-c jc-sb mb-3`}>
        <div className={`d-f ai-c g-1`}>
          <div className={`d-f ai-c`}>
            <div className="avatar mr-1 w-4 h-4 t-3p">
              <Avatar
                url={photoThumbnailUrl}
                alt={`profile image for ${personName}`}
              />
            </div>
            <h2 className={`h4`}>{personName}</h2>
          </div>
        </div>
      </div>
      <div className={`mb-1`}>
        <span style={{ color: "initial" }}>{selectionName}</span>
        {selectionType.atMaximumCapacity && (
          <span> {selectionType.priceCents > 0 ? "(Sold Out)" : "(Full)"}</span>
        )}
        {canceled && (
          <span className={`ml-1 danger-badge badge`}>Canceled</span>
        )}
      </div>
      {!canceled && (
        <>
          <div className={`mb-1 h4`}>
            You will be notified if spots become available
          </div>
          <CancelWaitlistedAttendeeModal attendeeSummary={attendeeSummary} />
        </>
      )}
    </div>
  )
}

WaitlistedAttendee.propTypes = {
  attendeeSummary: object,
  registration: object,
}

const CancelWaitlistedAttendeeModal = ({
  attendeeSummary,
  attendeeSummary: { personName },
}) => {
  return (
    <Modal>
      <CancelWaitlistedAttendeeTrigger personName={personName} />
      <Modal.Show>
        <Modal.Header>{`Remove from waitlist`}</Modal.Header>
        <Modal.Body>
          <div>
            Are you sure you want to remove {personName} from the waitlist?
          </div>
          <Modal.Footer>
            <Modal.Close className="btn text-btn md-btn mr-2 btn-loading">
              Cancel
            </Modal.Close>
            <CancelWaitlistedAttendeeAction attendeeSummary={attendeeSummary} />
          </Modal.Footer>
        </Modal.Body>
      </Modal.Show>
    </Modal>
  )
}

CancelWaitlistedAttendeeModal.propTypes = {
  attendeeSummary: object,
}

const CancelWaitlistedAttendeeAction = ({
  attendeeSummary: { id: attendeeId, registrationId },
}) => {
  const closeModal = useCloseModal()
  const [submitting, setSubmitting] = React.useState(false)
  const invalidateRegistrationCache = useInvalidateRegistration(registrationId)
  const handleWaitlistRemoval = async () => {
    setSubmitting(true)

    await sessionApiClient.patch(
      `/registrations/v2/registrations/${registrationId}/attendees/${attendeeId}`,
      {
        data: { attributes: { canceled: true } },
      },
    )
    setSubmitting(false)
    invalidateRegistrationCache()
    closeModal()
  }
  return (
    <Button
      isLoading={submitting}
      text="Remove"
      size="md"
      variant="danger"
      type="submit"
      theme="destroy"
      onClick={handleWaitlistRemoval}
    />
  )
}

CancelWaitlistedAttendeeAction.propTypes = {
  attendeeSummary: shape({
    id: number,
    registrationId: string,
  }),
}

const CancelWaitlistedAttendeeTrigger = ({ personName }) => {
  const openModal = useOpenModal()

  return (
    <div className={`mb-1 d-f ai-c jc-sb `}>
      <span>
        No longer interested in this selection type?{" "}
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a onClick={() => openModal()} href="javascript:void(0)">
          Remove {personName} from the waitlist.
        </a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </span>
    </div>
  )
}

CancelWaitlistedAttendeeTrigger.propTypes = {
  personName: string,
}

export default WaitlistedAttendee
