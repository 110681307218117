import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { Loading } from "source/shared/components"
import { get as dig } from "lodash"

export default function JoinCreate() {
  const navigate = useNavigate()
  const { token } = useParams()
  useSession(true)

  const createApplicationViaToken = (token) => {
    sessionApiClient
      .post(
        `/groups/v2/expirable_tokens/${token}/apply?include=group&fields[Group]=enrollment_status`,
      )
      .then(({ data, included }) => {
        if (data.type == "Membership") {
          const groupId = data.relationships.group.data.id

          navigate(`/my/groups/${groupId}`)
        } else {
          const group = included.find((i) => i.type === "Group")
          const isGroupPrivate =
            dig(group, "attributes.enrollment_status", "private") === "private"
          const publicGroupPath = new URL(
            data.attributes.public_church_center_url,
          ).pathname

          navigate(isGroupPrivate ? "/groups" : publicGroupPath, {
            replace: true,
            state: {
              type: "success",
              message: `Thank you for applying to ${dig(
                data,
                "attributes.group_name",
                "the group",
              )}, your membership request is being reviewed.`,
            },
          })
        }
      })
      .catch((error) => {
        const defaultErrorMessage =
          "We couldn't save your application. Please try scanning the code or clicking the link again, or contact your Group Leader."

        if (dig(error, "errors.0.status") === "422") {
          navigate("/groups", {
            replace: true,
            state: {
              type: "error",
              message: `Oops! ${dig(error, "errors.0.detail", [
                defaultErrorMessage,
              ]).join()}`,
            },
          })
        } else {
          navigate("/groups", {
            replace: true,
            state: {
              type: "error",
              message: defaultErrorMessage,
            },
          })
        }
      })
  }

  useEffect(() => {
    createApplicationViaToken(token)
  }, [])

  return <Loading message="Saving your application" />
}
