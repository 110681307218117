import { useParams } from "react-router-dom"
import { uniqBy } from "lodash"
import { useApiReadEntireCollection } from "source/shared/SessionApiResource"

export function useAllTags() {
  const { groupTypeSlug } = useParams()
  const responses = useApiReadEntireCollection(
    `/groups/v2/group_types/${groupTypeSlug}/tags?include=tag_group`,
  )
  return responses.reduce(
    (acc, { data, included }) => {
      return {
        data: [...acc.data, ...data],
        included: uniqBy([...acc.included, ...included], "id"),
      }
    },
    { data: [], included: [] },
  )
}
