import { createContext, Fragment, useContext, useEffect, useState } from "react"
import { func, number, string } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { Modal } from "source/shared/components/Modal"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import { MyInformation } from "./MyInformation"
import { css } from "@emotion/react"
import { Heading, Subheading } from "@planningcenter/doxy-web"

const ContactContext = createContext()

export function Contact() {
  return (
    <Modal>
      <Modal.Trigger className="btn secondary-btn">Contact</Modal.Trigger>
      <Modal.Show>
        <ModalContent />
      </Modal.Show>
    </Modal>
  )
}

function ModalContent() {
  const currentPerson = useSession()
  const [displayForm, setDisplayForm] = useState(true)
  const [name, setName] = useState(currentPerson?.data.attributes.name || "")
  const [email, setEmail] = useState(
    currentPerson?.data.attributes.email_address || "",
  )
  const [message, setMessage] = useState("")

  return (
    <ContactContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        message,
        setMessage,
        setDisplayForm,
      }}
    >
      {displayForm ? <FormWrapper /> : <ConfirmationWrapper />}
    </ContactContext.Provider>
  )
}

function FormWrapper() {
  return (
    <>
      <Modal.Header>Send message</Modal.Header>
      <Modal.Body>
        <Form />
      </Modal.Body>
    </>
  )
}

function ConfirmationWrapper() {
  return (
    <>
      <Modal.Header>Message sent</Modal.Header>
      <Modal.Body>
        <Confirmation />
      </Modal.Body>
    </>
  )
}

function Form() {
  const {
    data: {
      links: { contact: contactUrl },
    },
  } = useCurrentGroup()
  const currentPerson = useSession()
  const {
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    setDisplayForm,
  } = useContext(ContactContext)
  const [disabled, setDisabled] = useState(true)

  const saveForm = (e) => {
    e.preventDefault()

    const payload = {
      data: {
        attributes: {
          from_email_address: email,
          from_name: name,
          body: message,
        },
      },
    }
    sessionApiClient
      .post(contactUrl, payload)
      .then(() => {
        setDisplayForm(false)
      })
      .catch(() => alert("Uh oh, something went wrong. Please try again."))
  }

  useEffect(() => {
    setDisabled(name.length === 0 || email.length === 0 || message.length === 0)
  }, [name, email, message])

  return (
    <form onSubmit={saveForm}>
      {currentPerson.meta.authenticated ? (
        <MyInformation />
      ) : (
        <>
          <div className="field-group mb-2">
            <label className="label" htmlFor="from_name">
              Name
              <span className="c-ruby"> *</span>
            </label>
            <input
              type="text"
              name="from_name"
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="field-group mb-2">
            <label className="label" htmlFor="from_email_address">
              Email Address
              <span className="c-ruby"> *</span>
            </label>
            <input
              type="email"
              name="from_email_address"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </>
      )}
      <div className="field-group mb-2">
        <label className="label" htmlFor="body">
          Message
          <span className="c-ruby"> *</span>
        </label>
        <Textarea
          characterLimit={500}
          value={message}
          setValue={setMessage}
          name="body"
          id="body"
          placeholder="Have questions before joining? Ask here and someone will get back to you soon!"
          className="textarea"
        />
      </div>
      <Modal.Footer>
        <Modal.Close>Cancel</Modal.Close>
        <button type="submit" className="btn" disabled={disabled}>
          Send message
        </button>
      </Modal.Footer>
    </form>
  )
}

function Confirmation() {
  const { name, email, message } = useContext(ContactContext)

  return (
    <>
      <div className="d-f fd-c g-2">
        <p className="mb-0">We will get back to you as soon as we can.</p>
        <hr css={stylesheet.lineBreak} />
        <Heading level={3} size={4} text="Submission details" />
        <div>
          <Subheading level={4} color="tint2" text="Name" />
          <p className="mt-4p mb-0">{name}</p>
        </div>
        <div>
          <Subheading level={4} color="tint2" text="Email" />
          <p className="mt-4p mb-0">{email}</p>
        </div>
        <div>
          <Subheading level={4} color="tint2" text="Message" />
          <p className="mt-4p mb-0">{message}</p>
        </div>
      </div>
      <Modal.Footer>
        <Modal.Close className="btn compact-btn secondary-btn">
          Close
        </Modal.Close>
      </Modal.Footer>
    </>
  )
}

function Textarea({
  characterLimit = undefined,
  rows = 4,
  name,
  value = "",
  setValue,
  ...props
}) {
  const [charCount, setCharCount] = useState(0)

  const handleChange = (e) => {
    setValue(e.target.value)
    setCharCount(e.target.value.length)
  }

  const remainingChars = characterLimit - charCount

  return (
    <Fragment>
      <textarea
        rows={rows}
        name={name}
        defaultValue={value}
        maxLength={characterLimit}
        onChange={handleChange}
        {...props}
      />
      {characterLimit && (
        <div className="ta-r">
          <span className="c-tint3 fs-5">
            {remainingChars} characters remaining
          </span>
        </div>
      )}
    </Fragment>
  )
}

Textarea.propTypes = {
  characterLimit: number,
  rows: number,
  name: string.isRequired,
  value: string,
  setValue: func.isRequired,
}

const stylesheet = {
  lineBreak: css`
    background: none;
    border-top: 0;
    border-bottom: 1px solid var(--color-tint5);
    height: 1px;
    margin: 0;
    width: 100%;
  `,
}
