import { Suspense, useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { uniqBy, get as dig, sortBy } from "lodash"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useApiRead } from "source/shared/SessionApiResource"
import TabView from "source/groups/my/groups/TabView"
import { Loading, Avatar, ActionBar } from "source/shared/components"
import pluralize from "source/shared/pluralize"
import BlankState from "source/groups/BlankState"
import QRContainer from "source/groups/my/groups/add_person/QRContainer"
import AddPersonToggle from "source/groups/my/groups/add_person/AddPersonToggle"
import { pendingGroupApplicationsUrl } from "source/groups/my/groups/applications"
import { Heading } from "@planningcenter/doxy-web"
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

const SORT_OPTIONS = [
  { label: "Role", value: "role,first_name" },
  { label: "Last Name", value: "last_name" },
]

export default function MembersIndex() {
  return (
    <TabView>
      <Loaded />
    </TabView>
  )
}

function Loaded() {
  const { infiniteLoadingMemberships, ...group } =
    useContext(CurrentGroupContext)

  const {
    records: members,
    hasMore,
    loadMore,
    totalCount,
  } = infiniteLoadingMemberships

  const sortMembers = (sort, members) => {
    if (!members) return []
    const sortKeys = sort.value
      .split(",")
      .map((key) => (member) => dig(member, `attributes.${key}`, ""))
    return sortBy(members, sortKeys)
  }

  const canCreateMembers = dig(group, "abilities.memberships.can_create")
  const canSendEmails = dig(group, "abilities.email_messages.can_create", false)
  const showActions = canCreateMembers || canSendEmails

  const [qrContainerVisible, setQrContainerVisible] = useState(false)
  const [selectedSort, setSelectedSort] = useLocalStorage(
    "groups_sort_members_by",
    SORT_OPTIONS[0],
  )
  const [sortedMembers, setSortedMembers] = useState(
    sortMembers(selectedSort, members),
  )

  useEffect(() => {
    setSortedMembers(sortMembers(selectedSort, members))
  }, [members, selectedSort])

  const { meta } = useApiRead(
    `/groups/v2/me/groups/${group.id}/memberships?where[role]=leader&per_page=0`,
  )
  const leaderCount = meta.total_count
  const myMembershipResponse = useApiRead(
    `/groups/v2/me/groups/${group.id}/my_membership`,
  )
  const myMembership = myMembershipResponse.data
  const allMemberships = uniqBy([myMembership, ...sortedMembers], "id")

  const hasOnlyLeaders = leaderCount > 0 && leaderCount === totalCount

  const headerLabel = pluralize(
    totalCount,
    hasOnlyLeaders ? "Leader" : "Member",
  )
  const handleSortChange = (sort) => {
    setSelectedSort(sort)
  }

  return (
    <>
      <Suspense fallback={null}>
        <PendingApplicationsActionBar />
      </Suspense>
      <TabViewHeadingRow text={headerLabel}>
        <>
          <label
            htmlFor="sort-members-menu-button"
            className="screen-reader-text"
          >
            Sort by:
          </label>
          <Menu>
            <MenuButton
              id="sort-members-menu-button"
              className="ta-l select small-select w-a"
            >
              {selectedSort.label}
            </MenuButton>
            <MenuList
              className="dropdown__menu"
              style={{ "--dropdown-menu--font-size": "1rem" }}
            >
              {SORT_OPTIONS.map((option, index) => (
                <MenuItem
                  key={index}
                  onSelect={() => handleSortChange(SORT_OPTIONS[index])}
                  className="dropdown__item"
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
        {showActions && (
          <div className="d-f gap-2">
            {canSendEmails && (
              <Link to="email/new" className="minor-btn secondary-btn btn">
                Email group
              </Link>
            )}
            {canCreateMembers && (
              <AddPersonToggle
                handleClick={(clicked) => setQrContainerVisible(clicked)}
                toggleType="button"
              />
            )}
          </div>
        )}
      </TabViewHeadingRow>

      <Suspense fallback={<Loading />}>
        <QRContainer visible={qrContainerVisible} group_id={group.id} />
        <MembersList
          members={members}
          hasMore={hasMore}
          loadMore={loadMore}
          allMemberships={allMemberships}
          myMembership={myMembership}
        />
      </Suspense>
    </>
  )
}

function MembersList({
  members,
  hasMore,
  loadMore,
  allMemberships,
  myMembership,
}) {
  return members.length ? (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      {allMemberships.map((member) => {
        if (member.id === myMembership.id) {
          return <MyMembershipListItem key={member.id} json={member} />
        } else {
          return <MemberListItem key={member.id} json={member} />
        }
      })}
    </InfiniteScroll>
  ) : (
    <BlankState icon="churchCenter#people">
      There are no visible members in this group.
    </BlankState>
  )
}
MembersList.propTypes = {
  members: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  allMemberships: PropTypes.array.isRequired,
  myMembership: PropTypes.object.isRequired,
}

function MyMembershipListItem({ json }) {
  const { base_path } = useContext(CurrentGroupContext)
  const { avatar_url, first_name, last_name, role } = json.attributes

  const name = `${first_name} ${last_name}`

  return (
    <Link to={`${base_path}/membership/edit`}>
      <article className="d-f ai-c mb-2 pb-2 my-member__row">
        <div className="w-8 h-8 mr-2">
          <Avatar url={avatar_url} />
        </div>
        <div className="f-1 d-f jc-sb ai-c">
          <div className="d-f fd-c ai-fs">
            <Heading level={3} size={4} text={name} />
            <span className="fs-13 lh-1.5 c-brand">Edit visible info</span>
          </div>
          {role === "leader" && (
            <div>
              <span className="badge">Leader</span>
            </div>
          )}
        </div>
      </article>
    </Link>
  )
}
MyMembershipListItem.propTypes = {
  json: PropTypes.shape({
    attributes: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

function MemberListItem({ json }) {
  const { avatar_url, first_name, last_name, role } = json.attributes

  const name = `${first_name} ${last_name}`

  return (
    <Link to={json.id}>
      <article className="d-f ai-c mb-2">
        <div className="w-8 h-8 mr-2">
          <Avatar url={avatar_url} />
        </div>
        <div className="f-1 d-f jc-sb ai-c">
          <div className="d-f fd-c ai-fs">
            <Heading level={3} size={4} text={name} />
            <span className="fs-13 lh-1.5 c-brand">Member info</span>
          </div>
          {role === "leader" && (
            <div>
              <span className="badge">Leader</span>
            </div>
          )}
        </div>
      </article>
    </Link>
  )
}
MemberListItem.propTypes = {
  json: PropTypes.shape({
    attributes: PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
}

function PendingApplicationsActionBar() {
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(pendingGroupApplicationsUrl(group.id))
  const { total_count } = response.meta

  if (total_count === 0) return null

  return (
    <ActionBar.Link to={`${group.base_path}/applications`} className="mb-3@sm">
      <ActionBar.Title>Review applicants</ActionBar.Title>
      <ActionBar.Description>
        <PendingRequestText totalCount={total_count} name={group.name} />
      </ActionBar.Description>
    </ActionBar.Link>
  )
}

function PendingRequestText({ totalCount, name }) {
  if (totalCount === 1) {
    return (
      <>
        You have <strong>1 pending request</strong> to join {name}.
      </>
    )
  }

  return (
    <>
      You have <strong>{totalCount} pending requests</strong> to join {name}.
    </>
  )
}
PendingRequestText.propTypes = {
  name: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
}
