import { array, bool, func, string } from "prop-types"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"

export default function ScheduleHeader({
  hasAnyPending,
  rehearsable,
  schedules,
  serviceTypeName,
  setToggleableScheduleIds,
  title,
  url,
}) {
  return (
    <div className="mb-3">
      <div className="d-f jc-sb">
        <Heading level={1} size={2} text={title} />
        {(rehearsable || !hasAnyPending) && (
          <Menu>
            <MenuButton className="minor-btn secondary-btn btn">
              Actions
              <span className="fs-6 pl-4p dropdown-trigger__icon">
                <Icon symbol="general#down-chevron" />
              </span>
            </MenuButton>
            <MenuList position="bottom-left">
              {rehearsable && (
                <MenuItem onSelect={() => window.open(url, "_blank")}>
                  View in Services
                </MenuItem>
              )}
              {!hasAnyPending && (
                <MenuItem
                  onSelect={() =>
                    setToggleableScheduleIds(schedules.map((s) => s.id))
                  }
                >
                  Change response
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </div>
      <p className="c-tint2">{serviceTypeName}</p>
    </div>
  )
}

ScheduleHeader.propTypes = {
  hasAnyPending: bool,
  rehearsable: bool,
  schedules: array,
  serviceTypeName: string,
  setToggleableScheduleIds: func,
  title: string,
  url: string,
}
