import { forwardRef, useState } from "react"
import { isEmpty } from "lodash/lang"
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types"
import { useComponentVisible } from "source/calendar/hooks/useComponentVisible"

const Dropdown = ({ disabled, label, onToggle }) => {
  return (
    <button className="select ta-l" onClick={onToggle} disabled={disabled}>
      <span>{label}</span>
      <span className="caret c-primary" />
    </button>
  )
}
Dropdown.propTypes = {
  disabled: bool,
  label: string.isRequired,
  onToggle: func.isRequired,
}

const DropdownOption = ({ label, onSelect, selectedValue }) => {
  return (
    <button
      style={{
        display: "block",
        color: "#424242",
        lineHeight: "1.25",
        padding: "0.5rem 0.75rem",
        backgroundColor: "transparent",
        border: "none",
        fontWeight: 400,
        textAlign: "left",
        width: "100%",
      }}
      onClick={() => {
        onSelect(selectedValue)
      }}
    >
      {label}
    </button>
  )
}
DropdownOption.propTypes = {
  label: string.isRequired,
  onSelect: func.isRequired,
  selectedValue: oneOfType([string, object]).isRequired,
}

const Filter = forwardRef(
  (
    {
      defaultLabel,
      isLoading,
      isOpen,
      isVisible,
      items,
      onSelect,
      onToggle,
      selectedValue,
    },
    ref,
  ) => {
    return (
      <div {...{ ref }}>
        <div className={`dropdown ${isOpen && isVisible ? "is-open" : ""}`}>
          <Dropdown
            disabled={isLoading}
            label={selectedValue}
            onToggle={onToggle}
          />
          <ul className="dropdown__menu">
            <li className="dropdown__item p-0">
              <DropdownOption
                onSelect={onSelect}
                label={defaultLabel}
                selectedValue={{}}
              />
            </li>
            {items.map((item, index) => {
              return (
                <li key={index} className="dropdown__item p-0">
                  <DropdownOption
                    onSelect={onSelect}
                    label={item.attributes.name}
                    selectedValue={item}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  },
)
Filter.displayName = "Filter"
Filter.propTypes = {
  defaultLabel: string.isRequired,
  isLoading: bool,
  isOpen: bool.isRequired,
  isVisible: bool.isRequired,
  items: arrayOf(shape({ id: string, attributes: shape({ name: string }) }))
    .isRequired,
  onSelect: func.isRequired,
  onToggle: func.isRequired,
  selectedValue: string.isRequired,
}

const CampusAndCategoryFilters = ({
  campuses,
  categories,
  defaultCampusFilter,
  defaultCategoryFilter,
  eventsLoading,
  onSelectedCampusChange = () => {},
  onSelectedCategoryChange = () => {},
  selectedCampus,
  selectedCategory,
}) => {
  const {
    ref: campusRef,
    isComponentVisible: campusIsComponentVisible,
    setIsComponentVisible: setCampusIsComponentVisible,
  } = useComponentVisible(false)
  const {
    ref: categoryRef,
    isComponentVisible: categoryIsComponentVisible,
    setIsComponentVisible: setCategoryIsComponentVisible,
  } = useComponentVisible(false)

  const toggleCategories = () => {
    setCategoryIsComponentVisible((s) => !s)
    setCampusIsComponentVisible(false)
  }

  const toggleCampuses = () => {
    setCampusIsComponentVisible((s) => !s)
    setCategoryIsComponentVisible(false)
  }

  const handleSelectCategory = (clicked) => {
    const selectingAllCategories = selectedCategory == "All Categories"
    const allCategoriesToItself = selectingAllCategories && isEmpty(clicked)
    const selectionToItself =
      clicked.attributes && clicked.attributes.name === selectedCategory

    if (!allCategoriesToItself && !selectionToItself) {
      onSelectedCategoryChange(clicked)
    }
    setCategoryIsComponentVisible(false)
  }

  const handleSelectCampus = (clicked) => {
    const selectingAllCampuses = selectedCampus == "All Campuses"
    const allCampusesToItself = selectingAllCampuses && isEmpty(clicked)
    const selectionToItself =
      clicked.attributes && clicked.attributes.name === selectedCampus

    if (!allCampusesToItself && !selectionToItself) {
      onSelectedCampusChange(clicked)
    }
    setCampusIsComponentVisible(false)
  }

  return (
    <div className="d-f fd-c fd-r@sm fw-w" style={{ gap: "8px" }}>
      {campuses.length > 0 && (
        <Filter
          defaultLabel={defaultCampusFilter}
          isOpen={campusIsComponentVisible}
          isVisible={campusIsComponentVisible}
          items={campuses}
          onSelect={handleSelectCampus}
          onToggle={toggleCampuses}
          ref={campusRef}
          selectedValue={selectedCampus}
        />
      )}
      {categories.length > 0 && (
        <Filter
          defaultLabel={defaultCategoryFilter}
          isOpen={categoryIsComponentVisible}
          isVisible={categoryIsComponentVisible}
          items={categories}
          onSelect={handleSelectCategory}
          onToggle={toggleCategories}
          ref={categoryRef}
          selectedValue={selectedCategory}
        />
      )}
    </div>
  )
}

CampusAndCategoryFilters.propTypes = {
  categories: arrayOf(
    shape({ id: string, attributes: shape({ name: string }) }),
  ),
  campuses: arrayOf(shape({ id: string, attributes: shape({ name: string }) })),
  defaultCampusFilter: string,
  defaultCategoryFilter: string,
  onSelectedCampusChange: func.isRequired,
  onSelectedCategoryChange: func.isRequired,
  selectedCampus: string,
  selectedCategory: string,
}

export default CampusAndCategoryFilters
