import { useState, useMemo, useEffect } from "react"
import { object, string } from "prop-types"
import { useNavigate } from "react-router-dom"
import { groupBy, find as _find, flatMap, keyBy, omit, chain } from "lodash"
import { FullscreenLayout } from "source/Layout"
import { Loading } from "source/shared/components"
import Selections from "source/registrations/reservations/Selections"
import Attendees from "source/registrations/reservations/Attendees"
import { useJolt } from "source/shared/hooks/useJolt"
import AddOns from "source/registrations/reservations/AddOns"
import RegistrantQuestions from "source/registrations/reservations/RegistrantQuestions"
import ReviewSummary from "source/registrations/reservations/ReviewSummary"
import ReviewPayment from "source/registrations/reservations/ReviewPayment"
import {
  useReservation,
  useReservationMutation,
} from "source/registrations/hooks/useReservation"
import moment from "moment-timezone"
import CountdownTimer from "source/registrations/reservations/CountdownTimer"
import { useScrollThreshold } from "source/registrations/hooks/useScrollPosition"
import { formatEmergencyContact } from "source/registrations/reservations/personInfo/emergencyContact"
import useQueryString from "source/shared/hooks/useQueryString"
import useStripe from "source/registrations/hooks/useStripe"
import { useOrganization } from "source/registrations/hooks/useOrganization"
import { useEvent } from "source/registrations/hooks/useEvent"
import { useAddOns } from "source/registrations/hooks/useAddOns"
import { useAttendeeTypes } from "source/registrations/hooks/useAttendeeTypes"
import {
  usePeopleHouseholds,
  useMaxHouseholdSize,
} from "source/registrations/hooks/usePeopleHouseholds"
import { useQuestions } from "source/registrations/hooks/useQuestions"

const RESERVATION_STEPS = [
  "selections",
  "attendees",
  "addons",
  "registrantQuestions",
  "reviewSummary",
  "reviewPayment",
]

const Edit = ({ basePath, eventId, reservationUuid, session }) => {
  const navigate = useNavigate()
  const { data: organization } = useOrganization()
  const { data: attendeeTypes = [] } = useAttendeeTypes(eventId)
  const { data: addOns = [] } = useAddOns(eventId)
  const { data: questions = [] } = useQuestions(eventId)
  const { data: households = [] } = usePeopleHouseholds(session.id)
  const maxHouseholdSize = useMaxHouseholdSize(session.id)

  const {
    data: {
      name,
      hasAddonsModule,
      hasAttendeeQuestionsModule,
      hasRegistrantQuestionsModule,
      hasAttendeesModule,
      hasPaymentsModule,
      free,
      registrationType,
      recentRegistrationUrls,
      recentRegistrationDetails,
      eventTimes,
      allowCreditCardPayments,
      contact,
    },
  } = useEvent(eventId)

  const { data: reservation, error: reservationError } = useReservation(
    eventId,
    reservationUuid,
  )

  const mutation = useReservationMutation(eventId, reservationUuid)

  useStripe({
    stripeKey: organization.paymentIntegration.publishableKey,
    stripeAccount: organization.paymentIntegration.accountIdentifier,
    allowsOnlinePayments: hasPaymentsModule && allowCreditCardPayments,
  })

  const { registrant, attendee } = useMemo(
    () => groupBy(questions, ({ questionGroup }) => questionGroup),
    [questions],
  )

  const registrantQuestions = registrant || []
  const attendeeQuestions = attendee || []

  const reservationNotFound = reservationError?.status === "404"
  const isAttendeeQuestionsEnabled =
    hasAttendeeQuestionsModule && attendeeQuestions.length > 0
  const isAddOnsEnabled = hasAddonsModule && addOns.length > 0
  const isRegistrantQuestionEnabled =
    hasRegistrantQuestionsModule && registrantQuestions.length > 0

  const availableSteps = RESERVATION_STEPS.filter(
    (step) =>
      step === "selections" ||
      (step === "attendees" && hasAttendeesModule) ||
      (step === "addons" && (isAddOnsEnabled || isAttendeeQuestionsEnabled)) ||
      (step === "registrantQuestions" && isRegistrantQuestionEnabled) ||
      (step === "reviewSummary" && (!hasPaymentsModule || free)) ||
      (step === "reviewPayment" && hasPaymentsModule && !free),
  )

  const [isProcessing, setIsProcessing] = useState(false)
  const [completeRegistrationId, setCompleteRegistrationId] = useState(null)
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState("selections")
  const [stepStatus, setStepStatus] = useState(
    availableSteps.map((rs) => ({ step: rs, status: "incomplete" })),
  )

  const headerHeight = useMemo(
    () => document.getElementsByTagName("header")[0]?.offsetHeight || null,
    [],
  )

  const pastThreshold = useScrollThreshold(headerHeight)

  useEffect(() => {
    if (!reservation?.expiresAt) return

    const expirationTimer = setTimeout(
      () => {
        navigate(`${basePath}/expired`, { replace: true })
      },
      moment(reservation.expiresAt) - moment(),
    )

    return () => clearTimeout(expirationTimer)
  }, [reservation?.expiresAt])

  useJolt(
    reservation ? `${reservation.links?.joltChannel}/subscribe` : null,
    reservation?.joltChannel?.id,
    "reservation.completed",

    (joltMessage) => {
      const data = JSON.parse(joltMessage.data)
      const registrationId = data.relationships?.registration?.data?.id
      if (registrationId) {
        setCompleteRegistrationId(registrationId)
      }
    },
  )

  useJolt(
    reservation ? `${reservation.links?.joltChannel}/subscribe` : null,
    reservation?.joltChannel?.id,
    "reservation.error",

    (joltMessage) => {
      setIsProcessing(false)
      setError(joltMessage.data.error_message)
    },
  )

  const [params] = useQueryString()

  const handleEdit = (step) => {
    setCurrentStep(step)
  }

  const handlePaymentComplete = (registrationId) => {
    const path =
      registrationType === "simple"
        ? `/registrations/registrations/${registrationId}/confirmation`
        : `/registrations/registrations/${registrationId}?new_registration=true${
            session.isGuest ? `&_e=${reservation.guestSessionParam}` : ""
          }${params.source ? `&source=${params.source}` : ""}`

    navigate(path, { state: { showNotification: true } })
    return <Loading />
  }

  const handleCancel = () => {
    const lastStep = stepStatus.find((ss) => ss.status !== "completed")
    setCurrentStep(lastStep ? lastStep.step : null)
  }

  const handleStepStatus = (step) => {
    const unconfirmedSteps = stepStatus
      .slice(stepStatus.findIndex((cs) => cs.step === step))
      .filter((cs) => cs.status === "completed")
      .map((cs) => cs.step)

    const updatedSteps = stepStatus.map((ss) => {
      if (ss.step === currentStep) {
        return { ...ss, status: "completed" }
      } else if (unconfirmedSteps.includes(ss.step)) {
        return { ...ss, status: "unconfirmed" }
      }

      return ss
    })

    setStepStatus(updatedSteps)
  }

  const handleReservationSave = async (params, nextStep) => {
    mutation.reset()

    try {
      const response = await mutation.mutateAsync(params)

      if (nextStep && nextStep !== currentStep) {
        handleStepStatus(currentStep)
        setCurrentStep(nextStep)
      }

      setError(null)
      return { data: response, error: null }
    } catch (error) {
      const errorHelp = (
        <>
          {error?.detail}. If you have questions, please contact{" "}
          {contact?.primaryEmailAddress ? (
            <a href={`mailto:${contact.primaryEmailAddress}`}>
              {contact?.name || "our office"}
            </a>
          ) : (
            "our office"
          )}
        </>
      )

      setError(errorHelp)
      return { error, data: null }
    }
  }

  const availablePeople = useMemo(() => {
    const reservationPeople = reservation?.peoples || []

    const householdPeople = flatMap(
      households,
      (household) => household.peoples,
    )
      .filter(
        (person) =>
          !reservationPeople.map((person) => person.id).includes(person.id),
      )
      .map((person) => {
        const address =
          person.addresses.find((a) => a.primary) || person.addresses[0]

        return {
          ...person,
          emailAddress:
            person.emails.find((e) => e.primary)?.address ||
            person.emails[0]?.address ||
            null,
          phoneNumber:
            person.phoneNumbers.find((p) => p.primary)?.number ||
            person.phoneNumbers[0]?.number ||
            null,
          address: address
            ? {
                street: address.street,
                city: address.city,
                state: address.state,
                zip: address.zip,
              }
            : null,
        }
      })

    let combinedPeople = [...householdPeople, ...reservationPeople]

    if (reservation?.responsiblePerson) {
      combinedPeople = [
        reservation.responsiblePerson,
        ...combinedPeople.filter(
          (person) => person.id !== reservation.responsiblePerson.id,
        ),
      ]
    }

    return combinedPeople.map((person) => ({
      ...person,
      existingRegistrationUrl:
        (recentRegistrationUrls && recentRegistrationUrls[person.id]) || null,
      existingRegistrationDetails: recentRegistrationDetails[person.id] || [],
    }))
  }, [
    households,
    reservation,
    recentRegistrationUrls,
    recentRegistrationDetails,
  ])

  const attendees = useMemo(() => {
    const reservationAttendees = reservation?.attendees || []

    return chain(reservationAttendees)
      .map((attendee) => {
        const attendeeType = _find(attendeeTypes, {
          id: Number(attendee.attendeeTypeId),
        })

        const attendeeAnswers = attendee.answers.map((answer) => ({
          answers: answer.answers,
          questionId: answer.questionId,
          id: answer.id,
        }))

        const reservationAddOnSelections = reservation?.addOnSelections || []
        const addOnSelections = reservationAddOnSelections
          .filter((as) => as.attendeeId === attendee.id)
          .map((as) => omit(as, "attendee"))

        const requiredAddOns = attendeeType?.requiredAddOns || []
        const requiredAddOnSelections = requiredAddOns.map((addOnId) => {
          const selected = addOnSelections.find(
            (aos) => aos.addOnId === addOnId,
          )

          if (selected) return selected

          const addOn = addOns.find((a) => a.id === Number(addOnId))
          const hasVariations = addOn?.addOnVariations?.length > 0

          return {
            addOnId: addOnId,
            addOnableType: hasVariations ? "AddOnVariation" : "AddOn",
            quantity: 1,
            _destroy: false,
            ...(hasVariations && { addOnVariationId: null }),
          }
        })

        const emergencyContact = _find(reservation?.attendeePeoples, {
          attendeeId: attendee.id,
        })?.emergencyContact

        return {
          ...attendee,
          emergencyContact: emergencyContact
            ? {
                ...formatEmergencyContact(
                  emergencyContact.name,
                  emergencyContact.phoneNumber,
                ),
                isValid: true,
              }
            : null,
          attendeeType,
          person: _find(availablePeople, { id: attendee.personId }) || null,
          eventTime:
            _find(eventTimes, { id: attendeeType?.signupTimeId }) || null,
          addOnSelections: keyBy(
            [...addOnSelections, ...requiredAddOnSelections],
            "addOnId",
          ),
          answers: keyBy(attendeeAnswers, "questionId"),
          isInfoModified: requiredAddOnSelections.length > 0,
        }
      })
      .orderBy(["waitlist", "attendeeType.position"], "asc")
      .value()
  }, [reservation])

  if (reservation?.registrationId) {
    const path =
      registrationType === "simple"
        ? `/registrations/registrations/${reservation.registrationId}/confirmation`
        : `/registrations/registrations/${
            reservation.registrationId
          }?new_registration=true${
            session.isGuest ? `&_e=${reservation.guestSessionParam}` : ""
          }${params.source ? `&source=${params.source}` : ""}`

    navigate(path, { state: { showNotification: true } })
    return <Loading />
  }

  if (reservationNotFound || reservation?.expired) {
    navigate(`${basePath}/expired`, { replace: true })
    return <Loading />
  }

  const steps = availableSteps.map((reservationStep, stepIndex) => {
    const StepComponent = {
      selections: Selections,
      attendees: Attendees,
      addons: AddOns,
      registrantQuestions: RegistrantQuestions,
      reviewSummary: ReviewSummary,
      reviewPayment: ReviewPayment,
    }

    const nextStep =
      stepIndex + 1 === availableSteps.length
        ? null
        : availableSteps[stepIndex + 1]

    const prevStep = stepIndex - 1 <= 0 ? null : availableSteps[stepIndex - 1]

    const status = stepStatus.find((cs) => cs.step === reservationStep)?.status

    const step = {
      name: reservationStep,
      next: nextStep,
      prev: prevStep,
      isCurrent: reservationStep === currentStep,
      isComplete: status === "completed",
      isIncomplete: status === "incomplete",
      isUnconfirmed: status === "unconfirmed",
      isEditable: currentStep !== reservationStep && status === "completed",
    }

    const className = `reservation-step mb-4 ${
      step.isCurrent ? "reservation-step--is-active" : ""
    } ${
      step.isComplete && step.isCurrent ? "reservation-step--is-editing " : ""
    }`

    return {
      StepComponent: StepComponent[reservationStep],
      className,
      session,
      organization,
      reservation,
      attendeeTypes,
      addOns,
      attendeeQuestions,
      registrantQuestions,
      households,
      maxHouseholdSize,
      people: availablePeople,
      attendees,
      isProcessing,
      completeRegistrationId,
      isAddOnsEnabled,
      onPaymentComplete: (registrationId) =>
        handlePaymentComplete(registrationId),
      onIsProcessing: (value) => setIsProcessing(value),
      onCancel: handleCancel,
      onEdit: () => handleEdit(reservationStep),
      onReservationSave: (params, step = nextStep) =>
        handleReservationSave(params, step),
      error,
      step,
    }
  })

  return (
    <>
      <FullscreenLayout>
        <div
          className="status-bar action-drawer"
          css={{
            margin: "-1.5rem -1.5rem 1.5rem -1.5rem",
            "@media (min-width: 720px)": { margin: "-2rem -2rem 2rem -2rem" },
            top: pastThreshold ? headerHeight / 2 : "0",
            transition: "top 0.25s ease",
          }}
        >
          <div className="container container--wide d-f jc-sb ai-c">
            <div className="fs-4 c-tint2 fw-500">{name}</div>
            {reservation?.expiresAt && (
              <CountdownTimer expiresAt={reservation.expiresAt} />
            )}
          </div>
        </div>
      </FullscreenLayout>

      <div className="container container--narrow">
        {steps.map(({ StepComponent, ...stepProps }) => (
          <div key={stepProps.step.name}>
            <StepComponent {...stepProps} />
          </div>
        ))}
      </div>
    </>
  )
}

Edit.propTypes = {
  basePath: string,
  eventId: string,
  reservationUuid: string,
  session: object,
}

export default Edit
