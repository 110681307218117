import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const PhoneField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
  rowLayout,
}) => {
  const { phoneNumber } = person

  return (
    <Field
      label="Phone"
      value={phoneNumber}
      persistedValue={existingPerson.phoneNumber}
      onCancel={() => onCancel("phoneNumber")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
      rowLayout={rowLayout}
    >
      <TextInput
        id={`phoneNumber-${id}`}
        value={phoneNumber || ""}
        readOnly={!!existingPerson.phoneNumber}
        onChange={({ target: { value } }) => onChange("phoneNumber", value)}
        size="sm"
        placeholder="Phone number"
      />
    </Field>
  )
}

PhoneField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    phoneNumber: string,
  }),
  existingPerson: shape({
    phoneNumber: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
  rowLayout: bool,
}

export default PhoneField
