import { useContext } from "react"
import { array, bool, func, object, node } from "prop-types"
import { getRelationship } from "source/shared/getRelationship"
import { Loading } from "source/shared/components"
import InfiniteScroll from "react-infinite-scroller"
import { EventsListContext } from "../contexts/EventsListContext"
import { EventDetails } from "./EventDetails"
import { TakeAttendanceButton } from "./TakeAttendanceButton"

const propTypes = {
  children: node,
  events: array.isRequired,
  getEventUrl: func.isRequired,
  hasMore: bool,
  included: array.isRequired,
  loadMore: func,
  loadingMore: bool,
}

export function EventsList({
  children,
  events,
  getEventUrl,
  hasMore = false,
  included,
  loadMore = () => {},
  loadingMore = false,
}) {
  return (
    <EventsListContext.Provider
      value={{
        events,
        getEventUrl,
        hasMore,
        included,
        loadMore,
        loadingMore,
      }}
    >
      <div className="mb-3">{children}</div>
    </EventsListContext.Provider>
  )
}
EventsList.propTypes = propTypes

EventsList.Content = Content
EventsList.Empty = Empty
EventsList.Header = Header
EventsList.List = List
EventsList.ListItem = ListItem

function Content({ children }) {
  const { events = [] } = useContext(EventsListContext)

  return events.length ? children : null
}
Content.propTypes = {
  children: node.isRequired,
}

function Empty({ children }) {
  const { events = [] } = useContext(EventsListContext)

  return !events.length ? children : null
}
Empty.propTypes = {
  children: node.isRequired,
}

function Header({ children }) {
  return <h2 className="h3">{children}</h2>
}
Header.propTypes = {
  children: node.isRequired,
}

function List({ allowAttendance = false }) {
  const { events, hasMore, loadMore, included } = useContext(EventsListContext)

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      {events.map((event, index) => (
        <EventsList.ListItem
          allowAttendance={allowAttendance}
          event={event}
          key={index}
          location={getRelationship({ data: event, included }, "location")}
          rsvp={getRelationship({ data: event, included }, "my_rsvp")}
        />
      ))}
    </InfiniteScroll>
  )
}
List.propTypes = {
  allowAttendance: bool,
}

function ListItem({ allowAttendance, event, location, rsvp }) {
  const {
    attributes: {
      attendance_recording_status,
      abilities,
      church_center_my_event_url,
    },
  } = event
  const isRepeating = !!event.relationships.repeating_event.data?.id

  return (
    <div className="d-f mb-1 pt-1">
      <EventDetails
        event={event}
        location={location}
        rsvp={rsvp}
        isRepeating={isRepeating}
      />
      {allowAttendance && abilities.attendances.can_manage && (
        <TakeAttendanceButton
          myEventUrl={church_center_my_event_url}
          status={attendance_recording_status}
        />
      )}
    </div>
  )
}
ListItem.propTypes = {
  allowAttendance: bool.isRequired,
  event: object.isRequired,
  location: object,
  rsvp: object,
}
