import PropTypes from "prop-types"
import { Component } from "react"
import ReactDOMServer from "react-dom/server"
import { Icon } from "source/shared/components"
const { instanceOf, node, func, string } = PropTypes

class InfoWindow extends google.maps.OverlayView {
  constructor({ component }) {
    super()

    this.component = component
  }

  open({ map, anchor }) {
    this.setMap(map)
    this.anchor = anchor

    this.bindTo("anchorPoint", anchor)

    setTimeout(() => this.panToView(), 200)
  }

  dontPropagateEvents() {
    const events = [
      "mousedown",
      "mousemove",
      "mouseover",
      "mouseout",
      "mouseup",
      "mousewheel",
      "DOMMouseScroll",
      "touchstart",
      "touchend",
      "touchmove",
      "dblclick",
      "contextmenu",
      "click",
    ]

    this.listeners = events.map((event) =>
      google.maps.event.addDomListener(this.DOMNode, event, (e) => {
        e.cancelBubble = true
        e.stopPropagation && e.stopPropagation()
      }),
    )
  }

  getAnchorHeight() {
    const anchorPoint = this.get("anchorPoint")

    if (anchorPoint) {
      return -1 * anchorPoint.y
    } else {
      return 0
    }
  }

  getDrawPosition() {
    const projection = this.getProjection()

    if (!projection) return null

    const contentSize = this.getContentSize()
    const anchorPosition = projection.fromLatLngToDivPixel(
      this.anchor.getPosition
        ? this.anchor.getPosition()
        : this.anchor.getCenter(),
    )

    return {
      left: anchorPosition.x - contentSize.width / 2,
      top: anchorPosition.y - this.getAnchorHeight() - contentSize.height,
    }
  }

  getContent() {
    return ReactDOMServer.renderToStaticMarkup(
      this.component.renderInfoWindowContent(),
    )
  }

  getContentSize() {
    const panes = this.getPanes()

    if (!panes) {
      return { width: 0, height: 0 }
    }

    const sizer = document.createElement("div")
    sizer.style.visibility = "hidden"
    sizer.style.position = "absolute"

    panes.floatPane.appendChild(sizer)

    sizer.innerHTML = this.getContent()

    const size = {
      width: sizer.offsetWidth,
      height: sizer.offsetHeight,
    }

    panes.floatPane.removeChild(sizer)

    return size
  }

  panToView() {
    const projection = this.getProjection()

    if (!projection || !this.DOMNode) {
      return
    }

    const drawPosition = this.getDrawPosition()
    const contentSize = this.getContentSize()
    const contentSouthwest = projection.fromDivPixelToLatLng({
      x: drawPosition.left,
      y: drawPosition.top + contentSize.height,
    })
    const contentNortheast = projection.fromDivPixelToLatLng({
      x: drawPosition.left + contentSize.width,
      y: drawPosition.top,
    })
    const contentBounds = new google.maps.LatLngBounds(
      contentSouthwest,
      contentNortheast,
    )

    this.map.panToBounds(contentBounds)
  }

  // OverlayView required functions

  onAdd() {
    this.DOMNode = document.createElement("div")
    this.getPanes().floatPane.appendChild(this.DOMNode)
    this.DOMNode.innerHTML = this.getContent()

    this.dontPropagateEvents()
    google.maps.event.addDomListener(
      this.DOMNode.querySelector("[data-react-hook='InfoWindow.CloseButton']"),
      "click",
      (e) => {
        e.cancelBubble = true
        e.stopPropagation && e.stopPropagation()
        this.component.props.onClose()
      },
    )
  }

  draw() {
    if (!this.DOMNode) return

    const drawPosition = this.getDrawPosition()

    if (!drawPosition) return

    this.DOMNode.style.position = "absolute"
    this.DOMNode.style.left = `${drawPosition.left}px`
    this.DOMNode.style.top = `${drawPosition.top}px`
  }

  onRemove() {
    this.listeners.forEach((l) => l.remove())
    this.removeDOMNode()
  }

  removeDOMNode() {
    this.DOMNode &&
      this.DOMNode.parentNode &&
      this.DOMNode.parentNode.removeChild(this.DOMNode)
    this.DOMNode = null
  }

  // bindings

  anchorPoint_changed() {
    this.draw()
  }
}

export default class extends Component {
  static displayName = "GoogleMaps.InfoWindow"

  static propTypes = {
    map: instanceOf(google.maps.Map),
    anchor: instanceOf(google.maps.MVCObject),
    className: string,
    children: node,
    onClose: func,
  }

  UNSAFE_componentWillMount() {
    const { map, anchor } = this.props

    this.infoWindow = new InfoWindow({ component: this })
    this.infoWindow.open({ map, anchor })
  }

  componentWillUnmount() {
    this.infoWindow.setMap(null)
    this.infoWindow = null
  }

  renderInfoWindowContent = () => {
    const {
      children,
      className,
      map: _map,
      anchor: _anchor,
      onClose: _onClose,
      ...props
    } = this.props

    return (
      <div
        {...props}
        className={`map-info-window ${className}`}
        style={{
          backgroundColor: "white",
          borderRadius: "3px",
          border: "1px solid #B5B5B4",
          marginTop: "-12px",
          position: "relative",
        }}
      >
        <div
          className="map-info-window-close"
          data-react-hook="InfoWindow.CloseButton"
          style={{
            color: "#ACACAC",
            cursor: "pointer",
            fontSize: "10px",
            padding: "4px",
            position: "absolute",
            right: "2px",
            top: "2px",
            zIndex: "1",
          }}
        >
          <Icon symbol="general#x" />
        </div>
        <div>{children}</div>
      </div>
    )
  }

  render() {
    return null
  }
}
