import { Suspense, useEffect, useMemo } from "react"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { useSession } from "source/shared/hooks/useSession"
import { useMobileDetect } from "source/shared/hooks/useMobileDetect"
import { useApiRead } from "source/shared/SessionApiResource"
import { bool, func, object } from "prop-types"
import { Loading } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

import { SelectedGroup, MessagingNotEnabled } from "./SelectedGroup"
import GeneralChat from "./GeneralChat"

Messaging.propTypes = {
  episode: object,
  episodeTime: object,
  groupChatEnabled: bool,
  generalChatEnabled: bool,
}

export default function Messaging({
  episode,
  episodeTime,
  groupChatEnabled,
  generalChatEnabled,
}) {
  let jsonApiShape = useSession(false)
  const { isMobile } = useMobileDetect()
  if (isMobile && episodeTime.attributes.streaming_service === "facebook")
    return <FacebookChat />

  if (jsonApiShape.data.type !== "VerifiedInSessionPerson")
    return <NotLoggedIn generalChatEnabled={generalChatEnabled} />

  return (
    <div
      id="panel-1"
      role="tabpanel"
      tabIndex="0"
      aria-labelledby="tab-1"
      className="set-focus-invisible tab-widget-wrapper"
    >
      <Suspense fallback={<Loading />}>
        <div className="tab-widget-content f-1">
          <Groups
            episode={episode}
            episodeTime={episodeTime}
            groupChatEnabled={groupChatEnabled}
            generalChatEnabled={generalChatEnabled}
          />
        </div>
      </Suspense>
    </div>
  )
}

Groups.propTypes = {
  episode: object,
  episodeTime: object,
  groupChatEnabled: bool,
  generalChatEnabled: bool,
}

function Groups({
  episode,
  episodeTime,
  groupChatEnabled,
  generalChatEnabled,
}) {
  const groups = useApiRead("/groups/v2/me/groups")
  const channelId = episode.relationships.channel.data.id
  const LOCAL_STORAGE_KEY = `Sermons.Channel.${channelId}.SelectedGroupId`
  const [groupId, setGroupId] = useLocalStorage(LOCAL_STORAGE_KEY)
  const group = useMemo(() => {
    if (groups) return groups.data.find((g) => g.id === groupId)
  }, [groupId, groups])

  useEffect(() => {
    if (generalChatEnabled && !groupChatEnabled) setGroupId("general")
    if (!generalChatEnabled && groupChatEnabled && groupId === "general")
      setGroupId(null)
  }, [groupChatEnabled, generalChatEnabled])

  if (group && groupChatEnabled) {
    let groupMessagingEnabled = group.attributes.abilities.forum_topics.can_read
    if (!groupMessagingEnabled)
      return <MessagingNotEnabled group={group} setGroupId={setGroupId} />

    return (
      <SelectedGroup
        group={group}
        episode={episode}
        episodeTime={episodeTime}
        setGroupId={setGroupId}
      />
    )
  }

  if (groupId === "general" && generalChatEnabled)
    return (
      <GeneralChat
        episode={episode}
        episodeTime={episodeTime}
        setGroupId={setGroupId}
      />
    )

  return (
    <SelectGroup
      groups={groups.data}
      setGroupId={setGroupId}
      groupChatEnabled={groupChatEnabled}
      generalChatEnabled={generalChatEnabled}
    />
  )
}

SelectGroup.propTypes = {
  groups: object,
  setGroupId: func,
  groupChatEnabled: bool,
  generalChatEnabled: bool,
}

function SelectGroup({
  groups,
  setGroupId,
  groupChatEnabled,
  generalChatEnabled,
}) {
  return (
    <div className="p-2 set-focus-invisible">
      {generalChatEnabled && (
        <div className="mb-1">
          <button
            onClick={() => setGroupId("general")}
            className="d-if ai-c"
            style={{ border: "0", padding: "0", background: "transparent" }}
          >
            <img
              src="/images/general-chat-placeholder-graphic.png"
              alt="group"
              className="d-f ai-c jc-c mr-2 fs-1"
              css={{
                width: "99px",
                height: "56px",
                borderRadius: "4px",
                backgroundColor: "var(--color-tint10)",
                color: "var(--color-tint5)",
              }}
            />
            <span className="fw-400 c-tint1">General Chat</span>
          </button>
        </div>
      )}
      {groupChatEnabled &&
        groups.map((group) => (
          <div className="mb-1" key={group.id}>
            <button
              onClick={() => setGroupId(group.id)}
              className="d-if ai-c ta-l"
              style={{ border: "0", padding: "0", background: "transparent" }}
            >
              <img
                src={group.attributes.header_image.medium}
                alt="group"
                className="d-f ai-c jc-c mr-2 fs-1"
                css={{
                  width: "99px",
                  height: "56px",
                  borderRadius: "4px",
                  backgroundColor: "var(--color-tint10)",
                  color: "var(--color-tint5)",
                }}
              />
              <span className="fw-400" css={{ color: "var(--color-tint1)" }}>
                {group.attributes.name}
              </span>
            </button>
          </div>
        ))}
    </div>
  )
}

NotLoggedIn.propTypes = {
  generalChatEnabled: bool.isRequired,
}
function NotLoggedIn({ generalChatEnabled }) {
  return (
    <section
      className="action-drawer ta-c d-f ai-c jc-c fd-c g-1"
      css={{
        /* subtract tab action bar height */
        height: "calc(100% - 32px)",
        width: "100%",
      }}
    >
      {generalChatEnabled ? (
        <Heading level={2} size={3} text="Log in to chat live." />
      ) : (
        <>
          <Heading
            level={2}
            size={3}
            text="Log in to chat with any group you are in."
          />
          <Heading
            level={3}
            size={6}
            color="tint3"
            text="You must already be a member of a group"
          />
        </>
      )}

      <a
        href={`/login?return=${window.location.href}`}
        className="btn compact-btn secondary-btn mt-1"
      >
        Log in
      </a>
    </section>
  )
}

function FacebookChat() {
  return (
    <section
      className="action-drawer ta-c d-f ai-c jc-c fd-c mb-1"
      css={{
        /* subtract tab action bar height */
        height: "calc(100% - 32px)",
        width: "100%",
      }}
    >
      <Heading level={2} size={4} text="Chat is unavailable on mobile." />
    </section>
  )
}
