import { string, shape, func, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const AddressField = ({
  attendee,
  onChange,
  error,
  value: address,
  ...rest
}) => {
  const { id: attendeeId } = attendee

  const handleChange = (field, value) =>
    onChange({ ...address, [field]: value })

  const addressToString = (personAddress) =>
    [
      ...(personAddress?.street ? [personAddress.street] : []),
      ...(personAddress?.city ? [personAddress.city] : []),
      ...(personAddress?.state ? [personAddress.state] : []),
      ...(personAddress?.zip ? [personAddress.zip] : []),
    ].join(", ")

  return (
    <Field
      label="Address"
      value={addressToString(address)}
      error={error}
      {...rest}
    >
      <>
        <TextInput
          id={`street-${attendeeId}`}
          value={address?.street || ""}
          onChange={({ target: { value } }) => handleChange("street", value)}
          size="sm"
          placeholder="Street address"
        />
        <div className="mt-1 d-f ai-c g-1">
          <div css={{ width: 0, flexGrow: 3 }}>
            <TextInput
              id={`city-${attendeeId}`}
              value={address?.city || ""}
              onChange={({ target: { value } }) => handleChange("city", value)}
              size="sm"
              placeholder="City"
            />
          </div>
          <div css={{ width: 0, flexGrow: 1 }}>
            <TextInput
              id={`state-${attendeeId}`}
              value={address?.state || ""}
              onChange={({ target: { value } }) => handleChange("state", value)}
              size="sm"
              placeholder="State"
            />
          </div>
          <div css={{ width: 0, flexGrow: 2 }}>
            <TextInput
              id={`zip-${attendeeId}`}
              value={address?.zip || ""}
              onChange={({ target: { value } }) => handleChange("zip", value)}
              size="sm"
              placeholder="Zip"
            />
          </div>
        </div>
      </>
    </Field>
  )
}

AddressField.propTypes = {
  value: shape({
    street: oneOfType([string, number]),
    city: oneOfType([string, number]),
    state: oneOfType([string, number]),
    zip: oneOfType([string, number]),
  }),
  attendee: shape({
    id: oneOfType([string, number]),
  }),
  onChange: func,
  error: string,
}

export default AddressField
