/*global YT,Vimeo*/
import { useEffect, useMemo, useRef, useState } from "react"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  streamingService: PropTypes.string.isRequired,
  showControls: PropTypes.bool,
  enforceSync: PropTypes.bool,
  episodeTime: PropTypes.shape({
    links: PropTypes.shape({
      self: PropTypes.string.isRequired,
    }),
  }),
  joltChannel: PropTypes.object,
}

const MAX_DRIFT_IN_SECONDS = 10
const EXCESSIVE_DRIFT_IN_SECONDS = 3600
const MAX_RAPID_DRIFT_CORRECTIONS = 2
const MAX_OVERALL_DRIFT_CORRECTIONS = 15

export default function VideoPlayer({
  url,
  streamingService,
  showControls,
  enforceSync,
  episodeTime,
  joltChannel,
}) {
  if (url) {
    let PlayerComponent = null
    if (streamingService === "youtube") {
      PlayerComponent = YouTubeVideoPlayer
    } else if (streamingService === "vimeo") {
      PlayerComponent = VimeoVideoPlayer
    } else if (streamingService === "livestream_com") {
      PlayerComponent = LivestreamComVideoPlayer
    } else if (streamingService === "boxcast") {
      PlayerComponent = BoxcastVideoPlayer
    }
    return (
      <div css={styles.videoContainer}>
        {PlayerComponent ? (
          <PlayerComponent
            showControls={showControls}
            enforceSync={enforceSync}
            isPlayingFromLivestream={!!episodeTime}
            timestamp={episodeTime && episodeTime.attributes.current_timestamp}
            url={url}
            joltChannel={joltChannel}
          />
        ) : (
          <div>unsupported video URL</div>
        )}
      </div>
    )
  } else {
    return (
      <div
        css={styles.videoContainer}
        style={{ backgroundColor: "var(--color-gray25)" }}
      >
        <div className="no-video-url">no video url</div>
      </div>
    )
  }
}

VimeoVideoPlayer.propTypes = {
  showControls: PropTypes.bool,
  enforceSync: PropTypes.bool,
  timestamp: PropTypes.number,
  url: PropTypes.string.isRequired,
  joltChannel: PropTypes.object,
  isPlayingFromLivestream: PropTypes.bool.isRequired,
}

function VimeoVideoPlayer({
  enforceSync,
  timestamp,
  url,
  joltChannel,
  isPlayingFromLivestream,
}) {
  const playerRef = useRef()

  const setCurrentTime = (timestamp) => {
    return new Promise((resolve) => {
      playerRef.current
        .getDuration()
        .then((duration) => {
          if (duration === 0 || timestamp < 0 || timestamp > duration) return
          playerRef.current
            .setCurrentTime(timestamp)
            .then(resolve)
            .catch((error) => console.log(`Error seeking: ${error}`)) // eslint-disable-line no-console
        })
        .catch((e) => console.log(e)) // eslint-disable-line no-console
    })
  }

  const driftCorrector = useMemo(
    () =>
      new DriftCorrector(
        () => playerRef.current.getCurrentTime(),
        setCurrentTime,
      ),
    [url, enforceSync],
  )

  const publishStats = ({ playerState, playerCurrentTime }) => {
    let statsType = "client.libraryWatchStats"
    if (isPlayingFromLivestream) {
      statsType = "client.liveWatchStats"
    }

    joltChannel.trigger(
      statsType,
      JSON.stringify({
        playerState,
        playerCurrentTime,
      }),
    )
  }

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    if (playing && enforceSync) driftCorrector.checkDrift(timestamp)
  }, [playing, enforceSync, timestamp])

  const [apiReady, setApiReady] = useState(!!window.Vimeo)

  const urlMatch = url.match(/^https:\/\/vimeo\.com\/(event\/)?\d+/i)

  const checkScriptReady = () => {
    if (window.Vimeo) {
      setApiReady(true)
    } else {
      setTimeout(checkScriptReady, 100)
    }
  }

  useEffect(() => {
    if (!urlMatch) return
    if (!apiReady) {
      setTimeout(checkScriptReady, 100)
      return
    }
    document.querySelector("#player").innerHTML = ""
    playerRef.current = new Vimeo.Player("player", {
      url: url,
      height: "390",
      width: "640",
      autoplay: false,
      byline: false,
      controls: true,
      title: false,
    })
    playerRef.current.on("loaded", () => {
      playerRef.current.setVolume(1.0)
      if (enforceSync) {
        setCurrentTime(timestamp)
          .then(() => playerRef.current.play().catch(console.log)) // eslint-disable-line no-console
          .catch(console.log) // eslint-disable-line no-console
      } else {
        playerRef.current.play()
      }
    })
    playerRef.current.on("pause", () => setPlaying(false))
    playerRef.current.on("play", () => setPlaying(true))

    playerRef.current.on("play", ({ seconds }) =>
      publishStats({
        playerState: "PLAYING",
        playerCurrentTime: seconds,
      }),
    )
    playerRef.current.on("pause", ({ seconds }) =>
      publishStats({
        playerState: "PAUSED",
        playerCurrentTime: seconds,
      }),
    )
    playerRef.current.on("ended", ({ seconds }) =>
      publishStats({
        playerState: "ENDED",
        playerCurrentTime: seconds,
      }),
    )
    return () => {
      playerRef.current?.destroy()
    }
  }, [url, apiReady])

  if (!url) return <div>problem loading player</div>

  return (
    <>
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js" />
      </Helmet>
      <div id="player" />
    </>
  )
}

YouTubeVideoPlayer.propTypes = {
  showControls: PropTypes.bool,
  enforceSync: PropTypes.bool,
  timestamp: PropTypes.number,
  url: PropTypes.string.isRequired,
  joltChannel: PropTypes.object,
  isPlayingFromLivestream: PropTypes.bool.isRequired,
}

function YouTubeVideoPlayer({
  showControls,
  enforceSync,
  timestamp: upstreamTimestamp,
  url,
  joltChannel,
  isPlayingFromLivestream,
}) {
  const urlMatch = url.match(/(watch\?v=|youtu\.be\/|live\/)([a-z0-9-_]+)/i)
  const videoId = urlMatch && urlMatch[2]
  const timeFromUrl = new URL(url).searchParams.get("t")
  const timestamp = upstreamTimestamp || timeFromUrl

  const playerRef = useRef()

  const driftCorrector = useMemo(
    () =>
      new DriftCorrector(
        () => {
          return new Promise((resolve) =>
            resolve(playerRef.current.getCurrentTime()),
          )
        },
        (timestamp) => playerRef.current.seekTo(timestamp),
      ),
    [],
  )
  const publishStats = ({ playerState, playerCurrentTime }) => {
    let statsType = "client.libraryWatchStats"
    if (isPlayingFromLivestream) {
      statsType = "client.liveWatchStats"
    }

    joltChannel.trigger(
      statsType,
      JSON.stringify({
        playerState,
        playerCurrentTime,
      }),
    )
  }

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    if (playing && enforceSync) driftCorrector.checkDrift(timestamp)
  }, [playing, enforceSync, timestamp, driftCorrector])

  const [apiReady, setApiReady] = useState(!!(window.YT && window.YT.Player))
  window.onYouTubeIframeAPIReady = () => setApiReady(true)

  useEffect(() => {
    if (!videoId) return
    if (!apiReady) return
    playerRef.current = new YT.Player("player", {
      videoId,
      height: "390",
      width: "640",
      playerVars: {
        autoplay: 0,
        controls: showControls ? 1 : 0,
        playsinline: 1, // allow playing inside web page without forcing full screen (works on some mobile devices)
        rel: 0, // don't show related videos from other channels (prefer related videos from the same channel)
      },
      events: {
        onReady: () => {
          playerRef.current.seekTo(timestamp, true)
          playerRef.current.playVideo()
        },
        onStateChange: () => {
          const playerState = playerRef.current.getPlayerState()
          const isPlaying = playerState === YT.PlayerState.PLAYING
          const isEnded = playerState === YT.PlayerState.ENDED
          const isPaused = playerState === YT.PlayerState.PAUSED
          setPlaying(isPlaying)

          if (!isPlaying && !isEnded && !isPaused) {
            return // there are other player states we don't want to report on
          }

          let friendlyPlayerState = "PLAYING"
          if (isEnded) {
            friendlyPlayerState = "ENDED"
          } else if (isPaused) {
            friendlyPlayerState = "PAUSED"
          }

          publishStats({
            playerState: friendlyPlayerState,
            playerCurrentTime: playerRef.current.getCurrentTime(),
          })
        },
      },
    })
    return () => {
      if (
        playerRef.current &&
        playerRef.current.hasOwnProperty("stopVideo") &&
        playerRef.current.hasOwnProperty("destroy")
      ) {
        playerRef.current.stopVideo()
        playerRef.current.destroy()
      }
    }
  }, [videoId, apiReady])

  if (!videoId) return <div>problem loading player</div>

  return (
    <>
      <Helmet>
        <script src="https://www.youtube.com/iframe_api" />
      </Helmet>
      <div id="player" />
    </>
  )
}

class DriftCorrector {
  constructor(getPlayerTimestamp, setPlayerTimestamp) {
    this.getPlayerTimestamp = getPlayerTimestamp
    this.setPlayerTimestamp = setPlayerTimestamp
    this.enabled = true
    this.corrections = 0
    this.rapidCorrections = 0
  }

  checkDrift(desiredTimestamp) {
    if (!this.enabled) return
    this.getDrift(desiredTimestamp).then((drift) => {
      if (drift > EXCESSIVE_DRIFT_IN_SECONDS) {
        console.log("drift is excessively high; disabling drift correction") // eslint-disable-line no-console
        this.enabled = false
        return
      }
      if (drift > MAX_DRIFT_IN_SECONDS) {
        this.corrections++
        this.rapidCorrections++
        if (this.corrections > MAX_OVERALL_DRIFT_CORRECTIONS) {
          // eslint-disable-next-line no-console
          console.log(
            `we have made ${this.corrections} drift corrections overall; disabling drift correction`,
          )
          this.enabled = false
          return
        }
        if (this.rapidCorrections > MAX_RAPID_DRIFT_CORRECTIONS) {
          // eslint-disable-next-line no-console
          console.log(
            `we have made ${this.rapidCorrections} rapid drift corrections in a row; disabling drift correction`,
          )
          this.enabled = false
          return
        }
        console.log(`seeking to ${desiredTimestamp}`) // eslint-disable-line no-console
        this.setPlayerTimestamp(desiredTimestamp)
        setTimeout(() => {
          this.rapidCorrections--
        }, 1000)
      }
    })
  }

  getDrift(desiredTimestamp) {
    return new Promise((resolve) => {
      this.getPlayerTimestamp().then((actualTimestamp) => {
        resolve(Math.abs(actualTimestamp - desiredTimestamp))
      })
    })
  }
}

LivestreamComVideoPlayer.propTypes = {
  episodeTime: PropTypes.object,
  joltChannel: PropTypes.object,
  url: PropTypes.string.isRequired,
}

function LivestreamComVideoPlayer({ url, episodeTime, joltChannel }) {
  const [started, setStarted] = useState(false)

  useEffect(() => {
    if (joltChannel && !started) {
      setStarted(true)
      joltChannel.trigger(
        episodeTime ? "client.liveWatchStats" : "client.libraryWatchStats",
        JSON.stringify({
          playerState: "PLAYING",
          playerCurrentTime: 0,
        }),
      )
    }
  }, [episodeTime, joltChannel, started])

  return (
    <iframe
      title="Livestream VOD"
      src={`${url}/player`}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
    />
  )
}

BoxcastVideoPlayer.propTypes = {
  episodeTime: PropTypes.object,
  joltChannel: PropTypes.object,
  url: PropTypes.string.isRequired,
}

function BoxcastVideoPlayer({ url, episodeTime, joltChannel }) {
  const [started, setStarted] = useState(false)

  const viewEmbedUrl = url.replace("/view/", "/view-embed/")

  useEffect(() => {
    if (joltChannel && !started) {
      setStarted(true)
      joltChannel.trigger(
        episodeTime ? "client.liveWatchStats" : "client.libraryWatchStats",
        JSON.stringify({
          playerState: "PLAYING",
          playerCurrentTime: 0,
        }),
      )
    }
  }, [episodeTime, joltChannel, started])

  return (
    <iframe
      title="Boxcast Video Player"
      src={viewEmbedUrl}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
    />
  )
}

const styles = {
  videoContainer: css`
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 50px;
      height: 35px;
      @media (min-width: 960px) {
        width: 58px;
        height: 40px;
      }
      display: inline-flex;
    }

    .no-video-url {
      background: #ef5350;
      color: var(--color-tint10);
      padding: 8px 16px;
      border-radius: 3px;
    }

    header h1 {
      opacity: 0.8;
      font-weight: bold;
      font-size: 44px;
      @media (min-width: 960px) {
        font-size: 52px;
      }
      text-transform: uppercase;
    }

    header h2 {
      font-size: 16px;
      @media (min-width: 960px) {
        font-size: 20px;
      }
      opacity: 0.6;
    }

    header h3.timer {
      font-size: 40px;
      @media (min-width: 960px) {
        font-size: 44px;
      }
      display: inline;
      background-image: linear-gradient(to right, #41a5f5, #66bb6a);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  `,
}
