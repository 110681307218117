import { shape, func, bool, string, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const NameField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
  rowLayout,
}) => {
  const { firstName, lastName } = person

  const personName =
    existingPerson.name === "Unknown" ? "" : existingPerson.name

  if (personName) {
    return (
      <div
        css={{
          fontWeight: 600,
          fontSize: "18px",
          height: "48px",
          lineHeight: "48px",
        }}
      >
        {person.name}
      </div>
    )
  }

  return (
    <Field
      label="Name"
      persistedValue={personName}
      onCancel={() => onCancel("name")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
      rowLayout={rowLayout}
    >
      <div className="d-f ai-c g-1 fg-1">
        <TextInput
          id={`firstName-${id}`}
          placeholder="First name"
          readOnly={!!personName}
          value={firstName || ""}
          size="sm"
          onChange={({ target: { value } }) => onChange("firstName", value)}
        />
        <TextInput
          id={`lastName-${id}`}
          placeholder="Last name"
          readOnly={!!personName}
          value={lastName || ""}
          size="sm"
          onChange={({ target: { value } }) => onChange("lastName", value)}
        />
      </div>
    </Field>
  )
}

NameField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    name: string,
    firstName: string,
    lastName: string,
  }),
  existingPerson: shape({
    name: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
  rowLayout: bool,
}

export default NameField
