import { array, bool, func, string } from "prop-types"

LocationSelect.propTypes = {
  id: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  additionalOptions: array,
  disabled: bool,
}

export function LocationSelect({
  id,
  value,
  onChange,
  disabled = false,
  additionalOptions = [],
}) {
  id = `location_select_${id}`

  return (
    <label htmlFor={id}>
      <span className="screen-reader-text">Location</span>
      <select
        id={id}
        disabled={disabled}
        className="select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
      >
        <option value="Home">Home</option>
        <option value="Work">Work</option>
        {additionalOptions.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
        <option value="Other">Other</option>
      </select>
    </label>
  )
}
