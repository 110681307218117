import { arrayOf, bool, func, node, shape, string } from "prop-types"

Displayables.propTypes = {
  children: func.isRequired,
  directoryFieldData: arrayOf(
    shape({
      fieldType: string.isRequired,
      displayableType: string,
      displayableId: string,
      personId: string.isRequired,
    }),
  ).isRequired,
  disabled: bool,
  displayables: arrayOf(shape({ id: string.isRequired })).isRequired,
  fieldType: string.isRequired,
  householdId: string,
  person: shape({ data: shape({ id: string.isRequired }).isRequired })
    .isRequired,
  onUpdate: func.isRequired,
}

export function Displayables({
  children,
  directoryFieldData,
  disabled,
  displayables,
  fieldType,
  householdId,
  person,
  onUpdate,
}) {
  return (
    <>
      {displayables.map((displayable) => (
        <Displayable
          key={displayable.id}
          checked={directoryFieldData.some(
            ({ fieldType: directoryFieldDatumType, displayableId, personId }) =>
              directoryFieldDatumType === fieldType &&
              displayableId === displayable.id &&
              person.data.id === personId,
          )}
          disabled={disabled}
          id={`displayable-${fieldType}-${displayable.id}-${householdId}`}
          onChange={(e) => {
            const type = e.target.checked ? "append" : "remove"
            onUpdate({
              type,
              payload: {
                fieldType,
                displayableId: displayable.id,
                personId: person.data.id,
              },
            })
          }}
        >
          {children(displayable)}
        </Displayable>
      ))}
    </>
  )
}

PersonDisplayable.propTypes = {
  directoryFieldData: arrayOf(
    shape({
      fieldType: string.isRequired,
      personId: string.isRequired,
    }),
  ).isRequired,
  disabled: bool,
  householdId: string,
  person: shape({ data: shape({ id: string.isRequired }).isRequired })
    .isRequired,
  onUpdate: func.isRequired,
  fieldTypeName: string.isRequired,
}

export function PersonDisplayable({
  children,
  directoryFieldData,
  disabled,
  fieldTypeName,
  householdId,
  person,
  onUpdate,
}) {
  return (
    <Displayable
      checked={directoryFieldData.some(
        ({ fieldType, personId }) =>
          fieldType === fieldTypeName && person.data.id === personId,
      )}
      disabled={disabled}
      id={`displayable-${fieldTypeName}-${person.data.id}-${householdId}`}
      onChange={(e) => {
        const type = e.target.checked ? "append" : "remove"
        onUpdate({
          type,
          payload: { fieldType: fieldTypeName, personId: person.data.id },
        })
      }}
      children={children}
    />
  )
}

Displayable.propTypes = {
  checked: bool.isRequired,
  children: node.isRequired,
  disabled: bool,
  id: string.isRequired,
  onChange: func.isRequired,
}

export function Displayable({ checked, disabled, id, onChange, children }) {
  return (
    <div className="d-f p-r mb-1">
      <input
        type="checkbox"
        id={id}
        className="large-checkbox checkbox directory-checkbox"
        checked={checked}
        disabled={disabled}
        readOnly
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="large-checkbox-label checkbox-label directory-nested-checkbox-label p-s w-100%"
      >
        <span className="d-b ml-1">{children}</span>
      </label>
    </div>
  )
}
