import { arrayOf, node, number, shape, string } from "prop-types"
import { useApiRead } from "source/shared/SessionApiResource"
import pluralize from "source/shared/pluralize"
import { simplifyGroupTypeDescription } from "source/groups/utils/simplifyGroupTypeDescription"
import { truncated } from "source/shared/truncated"

const CHARACTER_LIMIT = 275

export function GroupTypeRow({ groupType }) {
  const { data: groups, meta } = useApiRead(
    `/groups/v2/group_types/${groupType.id}/groups?per_page=3&where[enrollment_status]=open&fields[Group]=header_image,name`,
  )

  return (
    <Row linkTo={groupType.attributes.church_center_web_url}>
      <Info>
        <InfoHeader>
          <InfoName>{groupType.attributes.name}</InfoName>
          <InfoCount count={meta.total_count} />
        </InfoHeader>
        <InfoDescription
          description={groupType.attributes.description_as_plain_text}
        />
      </Info>
      <GroupHeaderImages groups={groups} />
    </Row>
  )
}
GroupTypeRow.propTypes = {
  groupType: shape({
    id: string.isRequired,
    attributes: shape({
      name: string.isRequired,
      description: string.isRequired,
      church_center_web_url: string.isRequired,
    }).isRequired,
  }).isRequired,
}

export function GroupTypeWithoutGroupsRow({ groupType }) {
  return (
    <Row linkTo={groupType.attributes.church_center_web_url}>
      <Info>
        <InfoHeader>
          <InfoName>{groupType.attributes.name}</InfoName>
        </InfoHeader>
        <InfoDescription
          description={groupType.attributes.description_as_plain_text}
        />
      </Info>
    </Row>
  )
}
GroupTypeWithoutGroupsRow.propTypes = {
  groupType: shape({
    attributes: shape({
      church_center_web_url: string.isRequired,
    }).isRequired,
  }).isRequired,
}

function Row({ linkTo, children }) {
  return (
    <a
      href={linkTo}
      className="card-list-item__wrapper-link group-types-collection"
    >
      <div className="d-f@md jc-sb ai-c">{children}</div>
    </a>
  )
}
Row.propTypes = {
  linkTo: string.isRequired,
  children: node,
}

function Info({ children }) {
  return (
    <div className="f-1">
      <div>{children}</div>
    </div>
  )
}
Info.propTypes = { children: node }

function InfoHeader({ children }) {
  return <div className="d-f ai-c mb-1">{children}</div>
}
InfoHeader.propTypes = { children: node }

function InfoName({ children }) {
  return <h2 className="mb-0">{children}</h2>
}
InfoName.propTypes = { children: node }

function InfoCount({ count }) {
  const openGroupCount = pluralize(count, "open group")

  return <div className="ml-1 badge as-fs p-r t-2p">{openGroupCount}</div>
}
InfoCount.propTypes = { count: number.isRequired }

function InfoDescription({ description }) {
  const simpleDescription = simplifyGroupTypeDescription(description)
  const descriptionLength = simpleDescription?.length
  const truncate = descriptionLength > CHARACTER_LIMIT

  const groupTypeDescription = truncate
    ? truncated(simpleDescription, CHARACTER_LIMIT)
    : simpleDescription

  return <div className="fs-4">{groupTypeDescription}</div>
}
InfoDescription.propTypes = { description: string }

function GroupHeaderImages({ groups }) {
  return (
    <div className="w-30%@md mt-3 mt-0@md d-f ai-c jc-c">
      {groups.map((group) => (
        <GroupHeaderAvatar
          key={group.id}
          src={group.attributes.header_image.thumbnail}
          alt={`Logo for ${group.attributes.name}`}
        />
      ))}
    </div>
  )
}
GroupHeaderImages.propTypes = {
  groups: arrayOf(
    shape({
      id: string.isRequired,
      attributes: shape({
        header_image: shape({
          name: string.isRequired,
          thumbnail: string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
}

function GroupHeaderAvatar({ src, alt }) {
  return (
    <div className="w-8 h-8 ml-1">
      <img loading="lazy" src={src} alt={alt} className="d-b circle" />
    </div>
  )
}
GroupHeaderAvatar.propTypes = {
  src: string.isRequired,
  alt: string.isRequired,
}
