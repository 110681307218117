import { useState } from "react"
import { get, isEmpty, filter, xor } from "lodash"
import { func, object } from "prop-types"
import { sessionApiClient as API } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { NarrowLayout } from "source/Layout"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { useCurrentPersonAssignedTeamLeaders } from "source/services/hooks/useCurrentPersonAssignedTeamLeaders"
import TeamLeaderMultiSelect from "./TeamLeaderMultiSelect"
import spinner from "source/svg/spinner.svg"
import { TextInput } from "@planningcenter/doxy-web"

function sendTeamLeaderEmail(recipients, subject, message) {
  const people = recipients.map((recipient) => ({
    type: "Person",
    id: recipient.personId,
  }))

  return API.post("/services/v2/send_people_emails", {
    data: {
      attributes: {
        subject,
        message,
        sent_externally: false,
      },
      relationships: { people: { data: people } },
    },
  })
}

export default function EmailTeamLeaders() {
  useSession(true)
  const location = useLocation()
  const navigate = useNavigate()
  const assignedTeamLeaders = useCurrentPersonAssignedTeamLeaders()
  const [inFlight, setInFlight] = useState(false)
  const [selectedTeamLeaderIds, setSelectedTeamLeaderIds] = useState([])
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const referrerPath = get(location, "state.prevPath") || "/services"
  const referrerPathName = get(location, "state.prevPathName") || "My Services"

  const handleSendEmail = () => {
    setInFlight(true)

    sendTeamLeaderEmail(selectedTeamLeaders(), subject, message).then(() => {
      setInFlight(false)
      navigate(referrerPath)
    })
  }

  const validEmail = !isEmpty(selectedTeamLeaderIds) && subject && message

  const handleSelectTeamLeader = (personId) =>
    setSelectedTeamLeaderIds((prevTeamLeaders) =>
      xor(prevTeamLeaders, [personId]),
    )

  const selectedTeamLeaders = () =>
    filter(assignedTeamLeaders, ({ personId }) =>
      selectedTeamLeaderIds.includes(personId),
    )

  return (
    <NarrowLayout>
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl={referrerPath} pageText={referrerPathName} />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl={"/services/email_team_leaders"}
          pageText={"Email Team Leaders"}
          isActive
        />
      </BreadcrumbNavigation>
      <div className="mb-2">
        <TeamLeaderMultiSelect
          disabled={false}
          handleSelectTeamLeader={handleSelectTeamLeader}
          teamLeaders={assignedTeamLeaders}
          placeholder={"Select Team Leaders"}
          selectedTeamLeaderIds={selectedTeamLeaderIds}
        />
      </div>
      <div className="mb-2">
        <label className="label" htmlFor="reason">
          Subject
        </label>
        <TextInput
          id="reason"
          value={subject}
          onChange={({ target: { value } }) => setSubject(value)}
        />
      </div>
      <div>
        <label className="label" htmlFor="message">
          Message
        </label>
        <textarea
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          rows={8}
          placeholder="Message"
          className="w-100%"
          id="message"
        />
      </div>
      <div className="d-f jc-fe ai-c mt-3">
        <Link to={referrerPath} className="c-tint3 text-btn pr-2">
          Cancel
        </Link>
        <button
          className="btn"
          onClick={handleSendEmail}
          disabled={!validEmail}
        >
          {inFlight ? (
            <img
              src={spinner}
              alt="Sending"
              style={{ height: "1em", width: "1em" }}
            />
          ) : (
            <div>Send email</div>
          )}
        </button>
      </div>
    </NarrowLayout>
  )
}

EmailTeamLeaders.propTypes = {
  location: object,
  onClose: func,
}
