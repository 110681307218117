import { buildUrl } from "source/shared/buildUrl"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"

const DEFAULT_PARAMS = { order: "orderable_name", per_page: 50 }

export function useHouseholds(params = {}) {
  const url = buildHouseholdsUrl(params)
  const { records: households, ...rest } = useInfiniteLoadingResource(url)
  return { households, ...rest }
}

function buildHouseholdsUrl(params) {
  const paramsWithDefaults = { ...DEFAULT_PARAMS, ...params }
  return buildUrl(`/people/v2/directory_listings`, paramsWithDefaults)
}
