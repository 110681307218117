import { createContext, useContext, useReducer } from "react"
import { arrayOf, node, oneOfType } from "prop-types"
import _ from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CurrentGroupContext } from "source/groups/my/groups"

export const TopicsDataContext = createContext()

const UNKNOWN = "UNKNOWN"
const INGEST_ELEMENT = "INGEST_ELEMENT"
const INGEST_ELEMENTS = "INGEST_ELEMENTS"
const REMOVE_ELEMENT = "REMOVE_ELEMENT"
const SET_CURRENT_TOPIC_ID = "SET_CURRENT_TOPIC_ID"

const initialState = {
  data: [],
  currentTopicId: undefined,
  included: [],
  nextLink: undefined,
}

function dedupe(data) {
  return _(data).uniqBy(({ type, id }) => `${type}-${id}`)
}

function dedupeAndSort(data) {
  return dedupe(data).sortBy((d) => -Date.parse(d.attributes.last_replied_at))
}

function reducer(state, action) {
  switch (action.type) {
    case INGEST_ELEMENTS: {
      const json = action.payload
      let data = dedupeAndSort([...json.data, ...state.data]).value()

      return {
        ...state,
        data,
        included: [...json.included, ...state.included],
        nextLink: json.links.next,
      }
    }
    case INGEST_ELEMENT: {
      const json = action.payload
      // purposefully prepend the new element to the array. This is preferred
      // over appending for 2 reasons:
      // 1. `_.uniqBy` keeps the newest one since it finds it first. Otherwise
      //    we would need a custom comparator. And,
      // 2. It pre-sorts the first element to the front, which we probably want
      //    since it's the most recently updated element.
      let data = dedupeAndSort([json.data, ...state.data]).value()
      let included = dedupe([...json.included, ...state.included]).value()

      return {
        ...state,
        data,
        included,
      }
    }
    case REMOVE_ELEMENT: {
      return {
        ...state,
        data: state.data.filter((d) => d.id !== action.payload.id),
      }
    }
    case SET_CURRENT_TOPIC_ID: {
      return {
        ...state,
        currentTopicId: action.payload.id,
      }
    }
    default:
      throw new Error(`Unrecognized action: ${action.type}`)
  }
}

export function TopicsDataProvider({ children }) {
  const group = useContext(CurrentGroupContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const ingestMany = (json) =>
    dispatch({ type: INGEST_ELEMENTS, payload: json })
  const ingest = async (json) => {
    const { groupId, topicId } = extractIds(json)
    if (groupId !== group.id) return

    const resp = await sessionApiClient.get(
      `/groups/v2/me/groups/${groupId}/forum_topics?filter=not_for_sermon&where[id]=${topicId}&include=author,my_reaction,most_recent_reply,most_recent_reply.author`,
    )
    if (resp.meta.total_count === 0) return

    const payload = { ...resp, data: resp.data[0] }
    return dispatch({ type: INGEST_ELEMENT, payload })
  }
  const del = (id) => dispatch({ type: REMOVE_ELEMENT, payload: { id } })
  const setCurrentTopicId = (id) =>
    dispatch({ type: SET_CURRENT_TOPIC_ID, payload: { id } })

  return (
    <TopicsDataContext.Provider
      value={{
        ingestMany,
        ingest,
        del,
        currentTopic: state.data.find(({ id }) => id === state.currentTopicId),
        setCurrentTopicId,
        group,
        groupId: group.id,
        ...state,
      }}
    >
      {children}
    </TopicsDataContext.Provider>
  )
}

TopicsDataProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}

function extractIds(json) {
  switch (json.data.type) {
    case "Topic":
      return {
        groupId: json.data.relationships.group.data.id,
        topicId: json.data.id,
      }
    case "Reply":
      return {
        groupId: json.data.relationships.group.data.id,
        topicId: json.data.relationships.topic.data.id,
      }
    default:
      return { topicId: UNKNOWN, groupId: UNKNOWN }
  }
}
