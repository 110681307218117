import _ from "lodash"
import { Suspense, useContext, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, Route, Routes } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { NarrowLayout } from "source/Layout"
import Schedule from "./Schedule"
import ActivityAndInvolvement from "./ActivityAndInvolvement"
import useMyChuchCenter from "./hooks/useMyChurchCenter"
import { Avatar, Loading } from "source/shared/components"
import { WebBootContext } from "source/publishing/WebBoot"
import { RespondsToBlockoutNotification } from "source/services/shared"
import { Heading } from "@planningcenter/doxy-web"
import { Profile } from "./Profile"

export function MyChurchCenter() {
  return (
    <Routes>
      <Route path="/profile/*" element={<Profile />} />
      <Route path="*" element={<MyChurchCenterLayout />} />
    </Routes>
  )
}

export function MyChurchCenterLayout() {
  useSession(true)
  const {
    currentPerson: {
      attributes: { first_name, avatar_url },
    },
  } = useContext(WebBootContext)

  const {
    builtInPages: { calendar, groups, registrations, giving },
    abilities: { services: servicesAbilities } = {},
  } = useContext(WebBootContext)

  const initialState = useMyChuchCenter()
  const [{ activityDates, pendingItems }, setMyChurchCenterData] =
    useState(initialState)

  const pauseToAllowReplicationBetweenServicesAndDynamoDB = setTimeout

  const refreshMyChurchCenter = () =>
    pauseToAllowReplicationBetweenServicesAndDynamoDB(() => {
      sessionApiClient
        .get("/publishing/v2/me/my_church_center")
        .then((resp) => {
          const { upcoming: activityDates, pending: pendingItems } =
            resp.data.attributes

          setMyChurchCenterData({ activityDates, pendingItems })
        })
        .catch((json) => json)
    }, 1000)

  const hasServices = _.some(servicesAbilities)
  const hasGroups = groups === "published"
  const hasRegistrations = registrations === "published"
  const hasCalendar = calendar === "published"
  const hasGiving = giving === "published"

  return (
    <NarrowLayout>
      <Helmet title="Me" />
      <RespondsToBlockoutNotification />
      <div className="d-f jc-sb ai-c mb-3">
        <div className="d-f ai-c">
          <div className="avatar mr-2 w-8 h-8 o-h">
            <Avatar url={avatar_url} alt={`profile image for ${first_name}`} />
          </div>
          <div className="df-c">
            <Heading level={1} size={2} text={`Hello, ${first_name}!`} />
            <Link to="/me/profile" className="f-2">
              My profile &amp; settings
            </Link>
          </div>
        </div>
        <div>
          <Link to="/logout" className="f-2">
            Logout
          </Link>
        </div>
      </div>
      <Schedule
        hasCalendar={hasCalendar}
        hasGroups={hasGroups}
        hasRegistrations={hasRegistrations}
        hasServices={hasServices}
        refreshMyChurchCenter={refreshMyChurchCenter}
        scheduleItems={{ activityDates, pendingItems }}
      />
      <Suspense fallback={<Loading />}>
        <ActivityAndInvolvement
          hasGiving={hasGiving}
          hasGroups={hasGroups}
          hasRegistrations={hasRegistrations}
        />
      </Suspense>
    </NarrowLayout>
  )
}
