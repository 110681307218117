import { useEffect, useState } from "react"
import { reservationStepPropTypes } from "source/registrations/propTypes"
import { useParams } from "react-router-dom"
import PersonInfoForm from "source/registrations/reservations/personInfo/PersonInfoForm"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import SelectionsItems from "source/registrations/reservations/SelectionsItems"
import SelectionsSeparateTimes from "source/registrations/reservations/SelectionsSeparateTimes"
import { isStringBlank } from "source/registrations/utils/helpers"
import { postPeopleGuest } from "source/registrations/api/people"
import { useEvent } from "source/registrations/hooks/useEvent"

const DEFAULT_ADDRESS = { street: "", city: "", state: "", zip: "" }

const Selections = ({
  reservation,
  attendeeTypes,
  session,
  onReservationSave,
  isProcessing,
  error,
  onIsProcessing,
  onEdit,
  onCancel,
  step,
  className,
}) => {
  const { eventId } = useParams()

  const {
    data: {
      registrantCollectionOptions,
      registerForSeparateTimes,
      maximumRegistrationAttendees,
    },
  } = useEvent(eventId)

  const responsiblePerson = reservation?.responsiblePerson || session

  const collectionOptions = {
    ...registrantCollectionOptions,
    ...(!responsiblePerson.emailAddress &&
      session.isAuthenticated && { emailAddress: "hidden" }),
  }

  const [isValid, setIsValid] = useState(false)
  const [peopleError, setPeopleError] = useState(null)
  const [person, setPerson] = useState({
    name: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    address: DEFAULT_ADDRESS,
  })
  const [selections, setSelections] = useState([])

  useEffect(() => {
    const { name, firstName, lastName, emailAddress, phoneNumber, address } =
      responsiblePerson

    setPerson({
      name: name === "Unknown" ? "" : name,
      firstName: firstName === "Unknown" ? "" : firstName,
      lastName: lastName === "Unknown" ? "" : lastName,
      address: address || DEFAULT_ADDRESS,
      emailAddress,
      phoneNumber,
    })
    setSelections(reservation.attendeeTypeSelections)
  }, [reservation.updatedAt])

  useEffect(() => {
    const requiredOptions = Object.keys(collectionOptions).filter(
      (key) => collectionOptions[key] === "required",
    )

    const isValidPerson = requiredOptions.every((key) => {
      if (key === "name") {
        return isNameValid()
      } else if (key === "address") {
        return isAddressValid()
      }

      return !isStringBlank(person[key])
    })

    const counts = selections.filter((s) => s.count > 0)

    setIsValid(isValidPerson && counts.length > 0)
  }, [selections, person])

  const isNameValid = () =>
    !isStringBlank(person.firstName) && !isStringBlank(person.lastName)

  const isPhoneNumberValid = () => !isStringBlank(person.phoneNumber)

  const isAddressValid = () => {
    const {
      address: { street, city, state, zip },
    } = person

    return (
      !isStringBlank(street) ||
      !isStringBlank(city) ||
      !isStringBlank(state) ||
      !isStringBlank(zip)
    )
  }

  const handlePersonChange = (field, value) => {
    setPerson({ ...person, [field]: value })
  }

  const handlePersonCancel = (field) => {
    if (field === "name") {
      setPerson({
        ...person,
        firstName: responsiblePerson ? responsiblePerson.firstName : "",
        lastName: responsiblePerson ? responsiblePerson.lastName : "",
      })
    } else if (field === "address") {
      setPerson({
        ...person,
        address: responsiblePerson?.address
          ? responsiblePerson.address
          : DEFAULT_ADDRESS,
      })
    } else {
      setPerson({
        ...person,
        [field]: responsiblePerson ? responsiblePerson[field] : "",
      })
    }
  }

  const saveSelectionsToReservation = async (personId) => {
    const attributes = {
      ...{
        responsible_person_attributes: {
          id: personId,
          ...(isPhoneNumberValid() && {
            phone_number: person.phoneNumber,
          }),
          ...(isAddressValid() && {
            address: person.address,
          }),
        },
      },
      attendee_type_selections_attributes: selections
        .filter(({ isModified }) => isModified)
        .map(({ id, count }) => ({ id, count })),
    }

    await onReservationSave({ data: { attributes } })
    onIsProcessing(false)
  }

  const handleSave = async (_event) => {
    onIsProcessing(true)
    let persistedPerson = responsiblePerson
    let personError = null

    if (session.isGuest && !reservation.responsiblePersonId) {
      const response = await postPeopleGuest({
        first_name: person.firstName,
        last_name: person.lastName,
        email_address: person.emailAddress,
      })

      personError = response?.errors && response.errors[0]
      persistedPerson = response.data
    }

    if (personError) {
      setPeopleError(
        personError.code === "invalid_data"
          ? "Request could not be processed, please check the email address."
          : personError.detail,
      )

      return onIsProcessing(false)
    }

    setPeopleError(null)
    saveSelectionsToReservation(persistedPerson.id)
  }

  const handleSelectionsReset = (eventTimeId) => {
    setSelections((currentSelections) =>
      currentSelections.map((selection) =>
        selection.signupTimeId === eventTimeId
          ? { ...selection, count: 0, isModified: true }
          : selection,
      ),
    )
  }

  const updateSingleSelection = (id, count) =>
    selections.map((selection) => {
      return {
        ...selection,
        count: selection.id === id ? count : 0,
        isModified: true,
      }
    })

  const updateMultipleSelections = (id, count) => {
    let currentSelection = selections.find((s) => s.id === id)
    currentSelection = { ...currentSelection, count, isModified: true }

    return selections.map((selection) => {
      if (selection.id === currentSelection.id) return currentSelection

      if (
        currentSelection.count < currentSelection.availableCount &&
        selection.id === Number(`${currentSelection.attendeeTypeId}1`) &&
        selection.isModified
      ) {
        return { ...selection, count: 0, isModified: true }
      }

      return selection
    })
  }

  const handleSelectionChange = ({ id, count }) => {
    const newSelections =
      maximumRegistrationAttendees === 1
        ? updateSingleSelection(id, count)
        : updateMultipleSelections(id, count)

    setSelections(newSelections)
  }

  const selectionError = error || peopleError

  const isSingleSelection = maximumRegistrationAttendees === 1

  const subHeadingText = isSingleSelection
    ? ""
    : maximumRegistrationAttendees > 1
      ? `Choose up to ${maximumRegistrationAttendees}`
      : "How many are you registering for?"

  return (
    <SectionLayout
      heading={isSingleSelection ? "Selection" : "Selections"}
      subHeading={subHeadingText}
      className={className}
      step={step}
      isButtonShowing={step.isEditable}
      onButtonClick={onEdit}
      error={selectionError}
      onSave={handleSave}
      onCancel={onCancel}
      isNextDisabled={isProcessing || !isValid}
      expiresAt={reservation.expiresAt}
      isProcessing={isProcessing}
    >
      {!isSingleSelection && registerForSeparateTimes ? (
        <SelectionsSeparateTimes
          step={step}
          selections={selections}
          attendeeTypes={attendeeTypes}
          onSelectionChange={handleSelectionChange}
          onSelectionsReset={handleSelectionsReset}
        />
      ) : (
        <SelectionsItems
          step={step}
          selections={selections}
          attendeeTypes={attendeeTypes}
          onSelectionChange={handleSelectionChange}
          isSingleSelection={isSingleSelection}
        />
      )}

      {step.isCurrent && (
        <>
          <PersonInfoForm
            heading="Contact information"
            subheading={
              <>
                All correspondence for this registration will be sent to this
                person. If any of this information is incorrect, you can{" "}
                <a
                  href={`${window.location.origin}/me/profile/contact-and-info`}
                >
                  update your profile
                </a>{" "}
                before or after you register.
              </>
            }
            options={collectionOptions}
            person={person}
            existingPerson={responsiblePerson}
            onChange={handlePersonChange}
            onCancel={handlePersonCancel}
          />
        </>
      )}
    </SectionLayout>
  )
}

Selections.propTypes = reservationStepPropTypes

export default Selections
