import { Button } from "@planningcenter/doxy-web"
import { usePaymentIntentMutation } from "source/registrations/hooks/usePaymentIntent"
import { number, object, func } from "prop-types"

const MakePaymentButton = ({ balanceDueCents, registration, onOpenForm }) => {
  const { id, paymentIntent } = registration
  const mutation = usePaymentIntentMutation(id, paymentIntent)

  const handleMakePayment = async () => {
    if (balanceDueCents <= 0) return
    if (paymentIntent && paymentIntent.amount === balanceDueCents)
      return onOpenForm()

    try {
      await mutation.mutateAsync({ amount: balanceDueCents })
      onOpenForm(true)
    } catch (error) {
      onOpenForm(false)
    }
  }

  return (
    <div>
      <div className={"ta-c my-3"}>
        <Button onClick={handleMakePayment} text={"Make payment"} />
      </div>
    </div>
  )
}

MakePaymentButton.propTypes = {
  balanceDueCents: number,
  registration: object,
  onOpenForm: func,
}

export default MakePaymentButton
