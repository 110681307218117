import {
  Suspense,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react"
import { apiResource } from "source/shared/prop_types"
import { bool, func, number, object, shape, string } from "prop-types"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { css } from "@emotion/react"
import { get as dig } from "lodash"
import {
  CurrentEventContext,
  CurrentGroupContext,
} from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import {
  Icon,
  Loading,
  Spinner,
  Avatar,
  ActionBar,
} from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import EventSecondaryHeader from "source/groups/my/groups/events/EventSecondaryHeader"
import InfiniteScroll from "react-infinite-scroller"
import AttendanceCheckbox from "source/groups/my/groups/events/attendance/AttendanceCheckbox"
import QRContainer from "source/groups/my/groups/add_person/QRContainer"
import AddPersonToggle from "source/groups/my/groups/add_person/AddPersonToggle"
import { useJolt } from "source/shared/hooks/useJolt"
import { Heading } from "@planningcenter/doxy-web"
import { RecordNotFound } from "source/shared/components"

export default function AttendanceEdit() {
  const group = useContext(CurrentGroupContext)
  const {
    event,
    location,
    attendance_recording,
    base_path: eventPath,
    refetchEvent,
  } = useContext(CurrentEventContext)

  const [sortOrder, setSortOrder] = useState(SORT_OPTIONS[0])

  const { attendance_recording_status } = event.attributes

  useEffect(() => {
    if (!attendance_recording) {
      sessionApiClient
        .post(`${event.links.self}/attendance_recording`, {
          data: { attributes: {} },
        })
        .then(() => refetchEvent())
    }
  }, [])

  if (!event.attributes.abilities.attendances.can_manage)
    return <AttendanceNotAllowed />

  return (
    <TabView showActions={false}>
      <div className="d-f fd-c my-0 mx-a">
        <form className="p-r">
          <div
            className="gc-tint10 d-f ai-c py-2 mb-3"
            style={{
              position: "sticky",
              top: 68, // main condensed header height
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <EventSecondaryHeader event={event} location={location} />
            {attendance_recording_status === "complete" ? (
              <Link to={eventPath} className="btn">
                Done
              </Link>
            ) : (
              <SubmitButton />
            )}
          </div>
          <SortBar sortOrder={sortOrder} setSortOrder={setSortOrder} />
          <Suspense fallback={<Loading />}>
            <EventPeopleList sortOrder={sortOrder} />
          </Suspense>

          <NewPersonSection group={group} />
        </form>
      </div>
    </TabView>
  )
}

function SubmitButton() {
  const navigate = useNavigate()
  const { event, base_path: eventPath } = useContext(CurrentEventContext)
  const [isBusy, setIsBusy] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    setIsBusy(true)
    sessionApiClient
      .post(`${event.links.self}/attendance_recording/commit`)
      .then(() => {
        setIsBusy(false)
        navigate(eventPath)
      })
      .catch((_err) => {
        setIsBusy(false)
        alert("Oops, something went wrong. Please try again.")
      })
  }

  return (
    <button className="btn" disabled={isBusy} onClick={handleClick}>
      Submit
    </button>
  )
}

const SORT_OPTIONS = [
  { label: "First name", value: "first_name,last_name" },
  { label: "Last name", value: "last_name,first_name" },
]

function SortBar({ sortOrder, setSortOrder }) {
  return (
    <div className="d-f jc-sb ai-c mb-1">
      <Heading level={2} size={3} text="Attendees" />
      <label
        htmlFor="sort-attendance-menu-button"
        className="screen-reader-text"
      >
        Sort by:
      </label>
      <Menu>
        <MenuButton
          id="sort-attendance-menu-button"
          className="ta-l select small-select w-a"
        >
          {sortOrder.label}
        </MenuButton>
        <MenuList
          className="dropdown__menu"
          style={{ "--dropdown-menu--font-size": "1rem" }}
        >
          {SORT_OPTIONS.map((option, index) => (
            <MenuItem
              key={index}
              onSelect={() => setSortOrder(SORT_OPTIONS[index])}
              className="dropdown__item"
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  )
}
SortBar.propTypes = {
  sortOrder: shape({
    label: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  setSortOrder: func.isRequired,
}

function EventPeopleList({ sortOrder }) {
  const { event } = useContext(CurrentEventContext)
  const {
    records: people,
    hasMore,
    loadMore,
  } = useInfiniteLoadingResource(
    `${event.links.self}/people?sort=${sortOrder.value}`,
  )

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      <ul className="unstyled pl-1 mb-4">
        {people.map((person) => (
          <EventPersonListItem key={person.id} person={person} />
        ))}
      </ul>
    </InfiniteScroll>
  )
}
EventPeopleList.propTypes = {
  sortOrder: shape({
    label: string.isRequired,
    value: string.isRequired,
  }).isRequired,
}

function EventPersonListItem({ person }) {
  const {
    id,
    attributes: {
      abilities,
      attended,
      avatar_url,
      first_name,
      last_name,
      role: origRole,
    },
  } = person
  const [role, setRole] = useState(origRole)
  const [applicantDescription, setApplicantDescription] =
    useState("Membership pending")
  const groupApplicationId = person.relationships.group_application?.data?.id

  return (
    <li className="mb-1">
      <div className="d-f ai-c">
        <AttendanceCheckbox
          key={id}
          personId={id}
          initialValue={attended}
          role={role}
          isApplicant={!!groupApplicationId}
        >
          <div className="d-f ai-c">
            <div className="w-8 h-8 px-2">
              <Avatar url={avatar_url} />
            </div>
            <div>
              <span className="d-b fw-500 c-tint1">
                {first_name} {last_name}
              </span>
              {groupApplicationId ? (
                <ApplicantRole description={applicantDescription} />
              ) : (
                <span className="d-b fs-13 lh-1.5 c-tint2 tt-c">{role}</span>
              )}
            </div>
          </div>
        </AttendanceCheckbox>
        {groupApplicationId && (
          <ManageApplicant
            groupApplicationId={groupApplicationId}
            firstName={first_name}
            applicationIsPending={applicantDescription.includes("pending")}
            canManageMembership={abilities.memberships.can_manage}
            setDescription={setApplicantDescription}
            setRole={setRole}
          />
        )}
      </div>
    </li>
  )
}
EventPersonListItem.propTypes = {
  person: apiResource.isRequired,
}

function AttendanceNotAllowed() {
  const { base_path: eventPath } = useContext(CurrentEventContext)

  return (
    <RecordNotFound
      message="You must be a group leader to take attendance for this event."
      actions={
        <Link to={eventPath} className="btn">
          View Event
        </Link>
      }
    />
  )
}

function ApplicantRole({ description }) {
  let icon = "general#outlined-info-circle"

  if (description.includes("deleted")) {
    icon = "general#outlined-x-circle"
  } else if (description === "Member") {
    icon = ""
  }

  return (
    <div className="d-f fs-13 lh-1.5 ai-c c-tint2">
      {icon && <Icon symbol={icon} style={{ marginRight: 4 }} />}
      <span>{description}</span>
    </div>
  )
}
ApplicantRole.propTypes = {
  description: string.isRequired,
}

const NOPE = "NOPE"
const REJECTING = "REJECTING"
const APPROVING = "APPROVING"

function ManageApplicant({
  groupApplicationId,
  firstName,
  applicationIsPending,
  canManageMembership,
  setDescription,
  setRole,
}) {
  const [showDialog, setShowDialog] = useState(false)
  const [inFlight, setInFlight] = useState(NOPE)
  const cancelRef = useRef()
  const requestInProgress = inFlight === APPROVING || inFlight === REJECTING

  const {
    links: { applications: baseApplicationUrl },
  } = useContext(CurrentGroupContext)

  const open = (e) => {
    e.preventDefault()
    setShowDialog(true)
  }
  const close = (e) => {
    e.preventDefault()
    setShowDialog(false)
  }

  const markApplication = (action) => {
    return sessionApiClient
      .post(`${baseApplicationUrl}/${groupApplicationId}/${action}`)
      .then(() => {
        setInFlight(NOPE)
        setShowDialog(false)
      })
      .catch(() => {
        setInFlight(NOPE)
        alert(
          `Uh oh, something went wrong. We could not ${action} the membership request. Please try again.`,
        )
      })
  }

  const approve = (e) => {
    e.preventDefault()
    setInFlight(APPROVING)
    markApplication("approve").then(() => {
      setDescription("Member")
      setRole("member")
    })
  }
  const reject = (e) => {
    e.preventDefault()
    setInFlight(REJECTING)
    markApplication("reject").then(() => {
      setDescription("Application deleted")
      setRole("visitor")
    })
  }

  return (
    <div>
      {canManageMembership && applicationIsPending && (
        <button className="btn secondary-btn minor-btn" onClick={open}>
          Manage
        </button>
      )}

      {showDialog && (
        <AlertDialog leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ width: "calc(100% - 50px)" }}>
            {firstName} wants to join your group!
          </AlertDialogLabel>
          <AlertDialogDescription>
            <button
              className="modal__close-button"
              aria-label="Close modal"
              style={{ top: "1.5rem" }}
              onClick={close}
              ref={cancelRef}
            >
              <Icon symbol="general#x" />
            </button>
            <p>
              They will be added as a <strong>Member</strong> with access to
              events, resources, and messaging (if enabled).
            </p>
            <div className="d-f jc-fe mt-3">
              <button
                className={
                  inFlight === REJECTING
                    ? "btn compact-btn mr-1 destroy-outline-btn--loading"
                    : "btn compact-btn mr-1 destroy-outline-btn"
                }
                disabled={requestInProgress}
                onClick={reject}
              >
                <Spinner
                  alt="Rejecting"
                  opacity={inFlight === REJECTING ? 1 : 0}
                />
                <span style={{ opacity: inFlight === REJECTING ? 0 : 1 }}>
                  Delete request
                </span>
              </button>
              <button
                className={
                  inFlight === APPROVING
                    ? "btn compact-btn compact-btn--loading"
                    : "btn compact-btn"
                }
                disabled={requestInProgress}
                onClick={approve}
              >
                <Spinner
                  alt="Approving"
                  opacity={inFlight === APPROVING ? 1 : 0}
                />
                <span style={{ opacity: inFlight === APPROVING ? 0 : 1 }}>
                  Add to group
                </span>
              </button>
            </div>
          </AlertDialogDescription>
        </AlertDialog>
      )}
    </div>
  )
}
ManageApplicant.propTypes = {
  groupApplicationId: string.isRequired,
  firstName: string.isRequired,
  applicationIsPending: bool.isRequired,
  setDescription: func.isRequired,
  canManageMembership: bool.isRequired,
  setRole: func.isRequired,
}

function NewPersonSection({ group }) {
  const canShowApplicationURL = dig(group, "abilities.application_url.can_read")

  return (
    <section>
      <div className="mb-1">
        <Heading level={2} size={3} text="New people" />
      </div>
      {canShowApplicationURL && <AddPeople groupId={group.id} />}
      <VisitorsCount />
    </section>
  )
}
NewPersonSection.propTypes = {
  group: object.isRequired,
}

function AddPeople({ groupId }) {
  const [qrContainerVisible, setQrContainerVisible] = useState(false)
  return (
    <div className="mb-2">
      <ActionBar.Pane>
        <ActionBar.Column>
          <ActionBar.Title>Joining this group?</ActionBar.Title>
          <ActionBar.Description>Add them as a member.</ActionBar.Description>
        </ActionBar.Column>
        <ActionBar.Action>
          <AddPersonToggle
            handleClick={(clicked) => setQrContainerVisible(clicked)}
            toggleType="button"
          />
        </ActionBar.Action>
      </ActionBar.Pane>
      <QRContainer visible={qrContainerVisible} group_id={groupId} />
    </div>
  )
}
AddPeople.propTypes = {
  groupId: string.isRequired,
}

function VisitorsCount() {
  const { event, jolt_channel } = useContext(CurrentEventContext)
  const [state, setState] = useState({
    count: event.attributes.visitors_count || 0,
    version: event.attributes.visitors_count_version || -1,
  })

  const updateCount = useCallback((joltMessage) => {
    const data = JSON.parse(joltMessage.data)
    const { visitors_count, visitors_count_version } = data.attributes

    setState((prev) => {
      if (prev.version >= visitors_count_version) return prev

      return { count: visitors_count, version: visitors_count_version }
    })
  }, [])

  useJolt(
    `${event.links.jolt_channel}/subscribe`,
    jolt_channel.id,
    "event.updated",
    updateCount,
  )

  function decrement(e) {
    e.preventDefault()
    return sessionApiClient.post(event.links.decrement_visitors_count)
  }

  function increment(e) {
    e.preventDefault()
    return sessionApiClient.post(event.links.increment_visitors_count)
  }

  return (
    <div className="mb-2">
      <ActionBar.Pane>
        <ActionBar.Column>
          <ActionBar.Title>Just visiting?</ActionBar.Title>
          <ActionBar.Description>
            <VisitorDescription />
          </ActionBar.Description>
        </ActionBar.Column>
        <ActionBar.Action>
          <div className="d-f ai-c">
            <ChangeVisitorCount
              onClick={decrement}
              icon="general#minus"
              ariaLabel="Decrease visitor count by 1"
              disabled={state.count === 0}
            />
            <Count count={state.count} />
            <ChangeVisitorCount
              onClick={increment}
              icon="general#plus"
              ariaLabel="Increase visitor count by 1"
            />
          </div>
        </ActionBar.Action>
      </ActionBar.Pane>
    </div>
  )
}

function VisitorDescription() {
  const routerLocation = useLocation()
  const { event, base_path: eventPath } = useContext(CurrentEventContext)

  const showNote = event.attributes.abilities.notes.can_read

  return (
    <span>
      Count them present and add their details{" "}
      {showNote && (
        <Link
          to={`${eventPath}/my_note`}
          state={{ returnTo: routerLocation.pathname }}
        >
          to a note
        </Link>
      )}
      .
    </span>
  )
}

function ChangeVisitorCount({ onClick, icon, ariaLabel, disabled = false }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      css={styles.changeCountButton}
    >
      <Icon symbol={icon} />
    </button>
  )
}
ChangeVisitorCount.propTypes = {
  onClick: func.isRequired,
  icon: string.isRequired,
  ariaLabel: string.isRequired,
  disabled: bool,
}

function Count({ count }) {
  return <span css={styles.count}>{count}</span>
}
Count.propTypes = {
  count: number.isRequired,
}

const styles = {
  changeCountButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border: none;
    border-radius: 40px;
    background: var(--color-tint7);
    color: var(--color-brand);
    font-size: 14px;

    &:disabled {
      background: var(--color-tint8);
      color: var(--color-tint5);
    }
  `,
  count: css`
    width: 48px;
    font-size: 1.75rem;
    font-weight: 500;
    text-align: center;
    color: var(--color-tint1);
  `,
}
