import { useMemo } from "react"
import { object } from "prop-types"
import { AlertMessage } from "source/shared/components"

const RegistrationCompletionAlerts = ({ registration }) => {
  const hasMissingForms = useMemo(() => {
    return registration.attendeeSummaries.some(
      (summary) => summary.missingFormIds?.length > 0,
    )
  }, [registration])

  const hasMissingQuestions = registration.missingRequiredQuestions

  const hasMissingPayments = useMemo(() => {
    return registration.totalDue > 0
  }, [registration])

  const alerts = useMemo(() => {
    return [
      hasMissingForms && "forms",
      hasMissingQuestions && "information",
    ].filter(Boolean)
  }, [hasMissingForms, hasMissingQuestions])

  const alertSentence = useMemo(() => {
    let sentence = []
    if (alerts.length > 0) {
      sentence.push(
        `Additional ${alerts.join(" and ")} ${
          alerts.includes("forms") ? "are" : "is"
        } required to complete your registration`,
      )
    }
    if (hasMissingPayments) {
      sentence.push(
        `you have a balance due of ${registration.event.currency}${
          registration.totalDue / 100
        }`,
      )
    }
    sentence = sentence.join(" and ")
    return sentence.charAt(0).toUpperCase() + sentence.slice(1) + "."
  }, [alerts, registration, hasMissingPayments])

  return (
    <>
      {(hasMissingPayments || hasMissingForms || hasMissingQuestions) && (
        <AlertMessage type="warning" icon="general#exclamation-triangle">
          {alertSentence}
        </AlertMessage>
      )}
    </>
  )
}

RegistrationCompletionAlerts.propTypes = {
  registration: object,
}

export default RegistrationCompletionAlerts
