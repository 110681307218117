import moment from "moment"
import { ordinalize } from "source/shared/number"

function getRecurrenceDescription(frequency_name, starts_at, elements = []) {
  const weekdayName = moment(starts_at).format("dddd")
  const ordinal = elements
    .map((element) => ordinalize(element.count))
    .join(" & ")

  switch (frequency_name) {
    case "biweekly":
      return `Every other week on ${weekdayName}`
    case "monthly_on_day":
      return `Monthly on the ${moment(starts_at).format("Do")}`
    case "monthly_on_weekday":
      return `Monthly on the ${ordinal} ${weekdayName}`
    case "weekly":
      return `Weekly on ${weekdayName}`
    case "yearly":
      return `Yearly on ${moment(starts_at).format("MMMM Do")}`
    default:
      return "Does not repeat"
  }
}

export default getRecurrenceDescription
