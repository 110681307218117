import { useContext, useState, useEffect, createContext } from "react"
import { node, oneOfType, arrayOf } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import Jolt from "@planningcenter/jolt-client"
import { useSession } from "source/shared/hooks/useSession"
import { WebBootContext } from "source/publishing/WebBoot"

export const JoltContext = createContext()
const fetchAuthTokenFn = async () => {
  const response = await sessionApiClient.post("/global/v2/jolt_connect")
  return response.data.id
}

const fetchSubscribeTokenFn = async (channel, connection_id) => {
  const response = await sessionApiClient.post(
    `/notifications/v2/me/jolt_subscribe`,
    {
      data: {
        attributes: {
          channel,
          connection_id,
        },
      },
    },
  )
  return response.data.id
}

class JoltProxyThatHandlesRepeatedChannelSubscriptions {
  constructor(...args) {
    this.jolt = new Jolt(...args)
    this.subscriptions = {}
  }

  get connection() {
    return this.jolt.connection
  }

  get connectionId() {
    return this.jolt.connectionId
  }

  disconnect() {
    this.jolt.disconnect()
  }

  subscribe(channel, { fetchSubscribeTokenFn }) {
    if (!this.subscriptions[channel]) {
      this.subscriptions[channel] = {
        subscription: this.jolt.subscribe(channel, { fetchSubscribeTokenFn }),
        subscribers: 0,
      }
    }

    this.subscriptions[channel].subscribers += 1
    return this.subscriptions[channel].subscription
  }

  unsubscribe(channel) {
    if (!this.subscriptions[channel]) {
      return
    }

    this.subscriptions[channel].subscribers -= 1

    if (this.subscriptions[channel].subscribers === 0) {
      this.jolt.unsubscribe(channel)
      delete this.subscriptions[channel]
    }
  }
}

export const JoltProvider = ({ children }) => {
  const [jolt, setJolt] = useState()
  const [connectionId, setConnectionId] = useState()
  const [publishingJoltToken, setPublishingJoltToken] = useState()
  const [notificationsChannel, setNotificationsChannel] = useState()
  const {
    joltWebsocketUrl,
    currentOrganization: { id: currentOrgId },
  } = useContext(WebBootContext)
  const {
    data: { id: currentPersonId },
  } = useSession(false)

  // Establish jolt connection
  useEffect(() => {
    let ignore = false
    setJolt(null)
    setConnectionId(null)

    const nextJolt = joltWebsocketUrl
      ? new JoltProxyThatHandlesRepeatedChannelSubscriptions(
          joltWebsocketUrl,
          { fetchAuthTokenFn },
          { logToConsole: true },
        )
      : null

    if (nextJolt) {
      nextJolt.connection.then(() => {
        if (!ignore) {
          setJolt(nextJolt)
          setConnectionId(nextJolt.connectionId)
        }
      })
    }

    return () => {
      ignore = true
      nextJolt?.disconnect()
    }
  }, [joltWebsocketUrl])

  // Subscribe to notifications channel
  useEffect(() => {
    let nextChannel = null
    const name = currentPersonId
      ? `church_center.notifications.people.${currentPersonId}`
      : null

    if (jolt && name) {
      nextChannel = jolt.subscribe(name, { fetchSubscribeTokenFn })
    }

    setNotificationsChannel(nextChannel)

    return () => {
      if (nextChannel) jolt.unsubscribe(name)
    }
  }, [jolt, currentPersonId])

  // Prefetch a publishingJoltToken that can be used for later subscriptions
  useEffect(() => {
    let ignore = false
    setPublishingJoltToken(null)

    if (!jolt) return
    if (!connectionId) return

    sessionApiClient
      .post("/publishing/v2/jolt_subscribe", {
        data: {
          attributes: {
            channel: [
              `organization-${currentOrgId}.publishing.church_center.v2.events.theme_settings`,
              `organization-${currentOrgId}.publishing.church_center.v2.events.page`,
              `organization-${currentOrgId}.publishing.church_center.v2.events.church_center_app_menu`,
              `organization-${currentOrgId}.publishing.church_center.v2.events.draft_mode`,
            ].join(","),
            connection_id: connectionId,
          },
        },
      })
      .then((res) => {
        if (!ignore) {
          setPublishingJoltToken(res.data.id)
        }
      })
      .catch((res) => console.error("failed to subscribe to Jolt channel", res))

    return () => {
      ignore = true
    }
  }, [connectionId, currentOrgId, jolt])

  return (
    <JoltContext.Provider
      value={{ jolt, notificationsChannel, publishingJoltToken }}
    >
      {children}
    </JoltContext.Provider>
  )
}

JoltProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]),
}
