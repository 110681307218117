import { string, shape, func, number, oneOfType } from "prop-types"
import { DateSelector } from "source/shared/components"
import { Date } from "source/shared/DatetimeFmt"
import Field from "./Field"

const BirthdateField = ({ attendee, value, onChange, error, ...rest }) => {
  const { id: attendeeId } = attendee

  const handleBirthdateChange = (value) => {
    const newBirtdate = value && value.length > 0 ? value : null
    onChange(newBirtdate)
  }

  return (
    <Field
      label={"Birthdate"}
      value={value ? <Date start={value} year /> : ""}
      error={error}
      {...rest}
    >
      <DateSelector
        name={`birthdate-${attendeeId}`}
        onChange={(value) => handleBirthdateChange(value)}
        initialValue={value || ""}
        selectStyle={{ height: "32px" }}
      />
    </Field>
  )
}

BirthdateField.propTypes = {
  attendee: shape({
    id: oneOfType([string, number]),
  }),
  onChange: func,
  error: string,
  value: string,
}

export default BirthdateField
