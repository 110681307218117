import {
  useQuery,
  usePrefetchQuery,
  splitQueryParams,
  queryFnAll,
} from "source/registrations/utils/queryCache"

const DEFAULT_PEOPLE_MAX_HOUSEHOLD_SIZE = 20

const FIELDS = {
  Household: [
    "avatar_url",
    "name",
    "non_pending_and_not_deceased_people",
    "people",
    "phone_numbers",
    "emails",
    "addresses",
    "primary_contact",
  ],
  PhoneNumber: ["number", "primary", "location"],
  Address: [
    "city",
    "location",
    "primary",
    "state",
    "street",
    "zip",
    "country_code",
    "country_name",
  ],
  Email: ["address", "primary", "location"],
  Person: [
    "avatar_url",
    "birthdate",
    "birthday",
    "child",
    "contact_info_editable_by_household",
    "first_name",
    "given_name",
    "gender",
    "grade",
    "last_name",
    "medical_notes",
    "name",
    "status",
    "phone_numbers",
    "emails",
    "addresses",
  ],
}

const queryOptions = (personId, options = {}) => {
  const path = `/people/v2/people/${personId}/households`
  const { params, queryOptions } = splitQueryParams(options)

  return {
    enabled: !!personId,
    queryFn: (args) => queryFnAll({ ...args, includeMeta: true }),
    queryKey: [
      path,
      {
        include: [
          "non_pending_and_not_deceased_people",
          "non_pending_and_not_deceased_people.phone_numbers",
          "non_pending_and_not_deceased_people.emails",
          "non_pending_and_not_deceased_people.addresses",
        ],
        fields: FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  }
}

export const usePeopleHouseholdsPrefetch = (personId, options) =>
  usePrefetchQuery(queryOptions(personId, options))

export const usePeopleHouseholds = (personId, options) => {
  const households = useQuery(queryOptions(personId, options))

  return {
    ...households,
    data: households.data?.data,
  }
}

export const useMaxHouseholdSize = (personId, options) => {
  const households = useQuery(queryOptions(personId, options))

  return (
    households.data?.meta?.maxHouseholdSize || DEFAULT_PEOPLE_MAX_HOUSEHOLD_SIZE
  )
}
