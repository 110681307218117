import {
  getConfirmationLink,
  myRegistrationShape,
} from "source/registrations/hooks/useMyRegistrations"

import { Link } from "react-router-dom"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"
import { Icon } from "source/shared/components"

const Status = ({ registration }) => {
  const isCanceled = registration.completionStatus === "canceled"
  const isIncomplete = registration.completionStatus === "incomplete"

  const hasBalanceDue = registration.completionDetail.hasBalanceDue
  const hasMissingDetail = Object.keys(registration.completionDetail).some(
    (key) => key !== "hasBalanceDue" && registration.completionDetail[key],
  )

  const confirmationLink = getConfirmationLink(registration)

  return (
    <div>
      {isCanceled && (
        <div className="c-tint3 fs-13 mb-1 mt-1">
          <span className="fs-6 p-r t-1p" aria-hidden="true">
            <Icon symbol="general#x" className="fs-6" aria-hidden />
          </span>{" "}
          Canceled
        </div>
      )}
      {isIncomplete && (
        <Menu>
          <MenuButton className="btn destroy-btn secondary-btn minor-btn mt-1">
            <span className="mr-4p p-r t-1p">
              <Icon symbol="general#exclamation-triangle" aria-hidden />
            </span>
            Needs attention
            <span className="dropdown-trigger__icon ml-1 p-r t-1p">
              <Icon symbol="general#down-chevron" aria-hidden />
            </span>
          </MenuButton>

          <MenuList
            className="dropdown__menu fs-4 mt-4p"
            style={{ "--dropdown-menu--font-size": "1rem" }}
          >
            {hasMissingDetail && (
              <MenuLink
                as={Link}
                to={confirmationLink}
                className="dropdown__item"
              >
                Complete missing forms
              </MenuLink>
            )}
            {hasBalanceDue && (
              <MenuLink
                as={Link}
                to={confirmationLink}
                className="dropdown__item"
              >
                Pay balance of {registration.totalDueString}
              </MenuLink>
            )}
          </MenuList>
        </Menu>
      )}
    </div>
  )
}

Status.propTypes = {
  registration: myRegistrationShape,
}

export default Status
