import PropTypes from "prop-types"
import parse from "html-react-parser"
import { Link, useLocation, useParams } from "react-router-dom"
import { episodePath } from "../shared/routes"
import {
  useWindowDimensions,
  BREAKPOINTS,
} from "source/shared/hooks/useWindowDimensions"
import { useApiSuspenseQuery } from "source/shared/hooks/useApiSuspenseQuery"
import { NotesContext } from "./NotesContext"
import {
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMutation } from "@tanstack/react-query"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useDebounce } from "source/shared/hooks/useDebounce"
import { useUpdateEffect } from "source/shared/hooks/useUpdateEffect"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { Alert, Button, Heading } from "@planningcenter/doxy-web"
import { Icon, Spinner } from "source/shared/components"
import spinner from "source/svg/spinner.svg"
import { Loading } from "source/shared/components"
import { useEpisode } from "./queries"
import moment from "moment"
import {
  PENDO_EVENTS,
  trackPendoEvent,
} from "source/publishing/trackPendoEvent"
import { isEmpty } from "lodash/lang"
import useQueryString from "source/shared/hooks/useQueryString"
import { isChurchCenterApp } from "source/Layout"
import { useSession } from "source/shared/hooks/useSession"
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button"
import { unlinkify } from "source/shared/linkify"
import useReactNativeBridgeComponent from "source/shared/hooks/useReactNativeBridgeComponent"

export function SuspenseNotes(props) {
  return (
    <Suspense fallback={<Loading />}>
      <Notes {...props} />
    </Suspense>
  )
}

Notes.propTypes = {
  notesWrapperCss: PropTypes.object,
}
export function Notes({ notesWrapperCss = {} }) {
  const {
    meta: { authenticated },
  } = useSession()

  const { episodeId } = useParams()
  const { templateHtml, draftMode } = Notes.useNoteState({ episodeId })

  const { reactifiedTemplate, blanks } = useMemo(
    () => Notes.reactifyTemplate(templateHtml),
    [templateHtml],
  )

  if (!authenticated) return <NotLoggedIn />

  return (
    <div css={notesWrapperCss}>
      {draftMode && (
        <Alert
          text="Draft Mode: Your notes will not be saved."
          theme="warning"
        />
      )}
      <Notes.ContextProvider
        episodeId={episodeId}
        reactifiedTemplate={reactifiedTemplate}
        blanks={blanks}
      >
        <NotesHeader />
        <NotesSubheader />
        <div css={styles.notesRoot}>{reactifiedTemplate}</div>
      </Notes.ContextProvider>
    </div>
  )
}
Notes.ContextProvider = function ContextProvider({
  blanks,
  children,
  episodeId,
}) {
  const { initialNotes, updateNotes } = Notes.useNoteState({ episodeId })
  const [notes, setNotes] = useState(initialNotes)
  const debouncedNotes = useDebounce(notes, 300)
  const onChange = (id, value) => setNotes((prev) => ({ ...prev, [id]: value }))

  useUpdateEffect(() => {
    updateNotes(debouncedNotes)
  }, [debouncedNotes, updateNotes])

  return (
    <NotesContext.Provider
      value={{
        blanks,
        onChange,
        values: notes,
        episodeId,
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}
Notes.ContextProvider.displayName = "Notes.ContextProvider"
Notes.ContextProvider.propTypes = {
  blanks: PropTypes.object,
  children: PropTypes.node.isRequired,
  episodeId: PropTypes.string.isRequired,
  reactifiedTemplate: PropTypes.array,
}
Notes.reactifyTemplate = function (templateHtml) {
  const blanks = {}
  const options = {
    transform(node, dom, index) {
      if (
        node.type === "span" &&
        node.props?.className === "fill-in-the-blank"
      ) {
        const id = node.props.id
        const templateValue = node.props.content || node.props.children
        blanks[id] = templateValue
        return (
          <NoteInput
            key={id}
            id={id}
            annotationId={`root-${index}`}
            templateValue={templateValue}
          />
        )
      }

      if (node.type === "textarea") {
        const id = node.props.id
        return <NoteAnnotation key={id} id={id} />
      }

      return node
    },
  }

  return { reactifiedTemplate: parse(templateHtml, options), blanks }
}
Notes.useNoteState = function ({ episodeId }) {
  const { data: episodeData } = useEpisode(episodeId)
  const publishedAtInPast =
    episodeData.data.attributes.published_to_library_at &&
    moment(episodeData.data.attributes.published_to_library_at).isBefore(
      moment(),
    )
  const liveAtInPast =
    episodeData.data.attributes.published_live_at &&
    moment(episodeData.data.attributes.published_live_at).isBefore(moment())
  const draftMode = !publishedAtInPast && !liveAtInPast

  const { data: noteData } = useApiSuspenseQuery({
    path: `/publishing/v2/episodes/${episodeId}/note`,
    queryClientOptions: {
      select: ({ data }) => data?.attributes,
      staleTime: Infinity,
    },
  })
  const { data: noteTemplateHtml } = useApiSuspenseQuery({
    path: `/publishing/v2/episodes/${episodeId}/note_template`,
    queryClientOptions: {
      select: ({ data }) => data.attributes.template,
      staleTime: Infinity,
    },
  })
  const { mutate: noteMutate } = useMutation({
    mutationFn: (attributes) => {
      sessionApiClient
        .patch(`/publishing/v2/episodes/${episodeId}/note`, {
          data: { attributes },
        })
        .catch(console.error)
    },
  })

  const [localNotes, setLocalNotes] = useLocalStorage(`notes-${episodeId}`, {})
  const templateHtml =
    noteData?.template || localNotes?.template || noteTemplateHtml || ""
  const initialNotes = (noteData ? noteData.content : localNotes.content) || {}
  const trackPendoEventCallback = useCallback(
    () => trackPendoEvent(PENDO_EVENTS.sermonNoteCreated),
    [],
  )
  const trackPendoEventOnce = useRunOnce(
    trackPendoEventCallback,
    !isEmpty(initialNotes),
  )

  const updateNotes = useCallback(
    (content) => {
      if (draftMode) return

      const data = { content, template: templateHtml }
      noteData ? noteMutate(data) : setLocalNotes(data)
      trackPendoEventOnce()
    },
    [
      draftMode,
      noteData,
      noteMutate,
      setLocalNotes,
      templateHtml,
      trackPendoEventOnce,
    ],
  )

  return { draftMode, initialNotes, templateHtml, updateNotes }
}

function useRunOnce(func, alreadyDone = false) {
  const doneRef = useRef(alreadyDone)

  return useCallback(() => {
    if (doneRef.current) return

    doneRef.current = true
    func()
  }, [func])
}

function NoteAnnotation({ id }) {
  const { onChange: contextOnChange, values } = useContext(NotesContext)
  const textareaRef = useRef(null)
  const onChange = (e) => {
    contextOnChange(id, e.target.value)
  }
  const value = values[id] || ""

  return (
    <>
      <div
        data-note-id={id}
        data-replicated-value={value}
        css={[
          styles.annotationWrapper,
          {
            ":after": [
              styles.growTextarea,
              {
                content: 'attr(data-replicated-value) " "',
                whiteSpace: "pre-wrap",
                visibility: "hidden",
              },
            ],
          },
        ]}
      >
        {value.length === 0 && (
          <span
            className="annotation-icon c-tint3 f-0 d-f jc-c ai-c"
            css={styles.annotationIcon}
          >
            <Icon symbol="general#plus" aria-hidden />
          </span>
        )}
        <textarea
          ref={textareaRef}
          placeholder="Add notes"
          value={value}
          onChange={onChange}
          css={[styles.growTextarea, styles.annotationTextarea]}
          rows={1}
        />
      </div>
    </>
  )
}
NoteAnnotation.displayName = "NoteAnnotation"
NoteAnnotation.propTypes = {
  id: PropTypes.string.isRequired,
}

function NoteInput({ id, templateValue = "" }) {
  const { blanks, onChange, values } = useContext(NotesContext)
  const value = values[id] || ""
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)

  const blankIds = Object.keys(blanks)
  const revealedBlanks = blankIds.filter((id) => values[id] === blanks[id])

  return (
    <span
      className="fill-in-the-blank"
      data-current-value={value}
      data-template-value={templateValue}
      css={[
        styles.inputWrapper,
        {
          padding: isFocused || !value ? "0 1.5rem 0 0" : "0",
          borderBottom: isFocused
            ? "1px solid var(--color-topaz)"
            : "1px solid var(--color-tint3)",
          ":after": [
            styles.growInput,
            {
              content:
                isFocused || !value ? 'attr(data-template-value) " "' : '""',
              visibility: "hidden",
            },
          ],
          ":before": [
            styles.growInput,
            {
              content: 'attr(data-current-value) " "',
              visibility: "hidden",
            },
          ],
        },
      ]}
    >
      <input
        ref={inputRef}
        onFocus={(_e) => {
          setIsFocused(true)
        }}
        onClick={(_e) => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        onChange={(e) => {
          onChange(id, e.target.value)
        }}
        value={value}
        css={styles.input}
      />
      <button
        className="btn naked-btn"
        css={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          height: "auto",
          padding: "0 0.25rem",
          color: "var(--color-topaz)",
          zIndex: 50,
          opacity: isFocused ? 1 : 0,
          visibility: isFocused ? "visible" : "hidden",
          transition: "opacity 0.2s ease-in-out",
          pointerEvents: isFocused ? "all" : "none",
        }}
        onTouchStart={(e) => {
          e.preventDefault()
        }}
        onMouseDown={(e) => {
          e.preventDefault()
        }}
        onClick={() => {
          if (revealedBlanks.includes(id)) {
            onChange(id, "")
          } else {
            onChange(id, blanks[id])
          }
        }}
      >
        <Icon
          symbol={
            revealedBlanks.includes(id)
              ? "general#hide-eye"
              : "general#show-eye"
          }
          aria-hidden
        />
      </button>
    </span>
  )
}
NoteInput.displayName = "NoteInput"
NoteInput.propTypes = {
  annotationId: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  templateValue: PropTypes.string,
}

function useIsLoggedIn() {
  const { data: currentPerson } = useSession()

  return Boolean(currentPerson.id)
}

SendNotesButton.propTypes = {
  buttonSize: PropTypes.string.isRequired,
}
function SendNotesButton({ buttonSize }) {
  const { values: notes, episodeId } = useContext(NotesContext)
  const { templateHtml } = Notes.useNoteState({ episodeId })
  const { data: episode } = useEpisode(episodeId)
  const [searchParams, _] = useQueryString()
  const canRNShare = isChurchCenterApp() && searchParams.supports_share

  const { postMessage, supported: canBridgeShare } =
    useReactNativeBridgeComponent({
      component: "ShareSheet",
    })

  const canShare = canBridgeShare || canRNShare || navigator.canShare
  const canEmail = useIsLoggedIn()
  const [emailSent, setEmailSent] = useState(false)

  const fetchNoteAsText = useMutation({
    mutationFn: () =>
      sessionApiClient
        .post(`/publishing/v2/note_to_text`, {
          data: {
            attributes: {
              template: templateHtml,
              content: notes,
            },
          },
        })
        .catch(console.error),
  })

  const share = () => {
    fetchNoteAsText.mutateAsync().then((data) => {
      const noteAsText = unlinkify(data.data.attributes.value)

      if (canBridgeShare) {
        postMessage("share", {
          message: noteAsText,
          title: `My Notes for ${episode.data.attributes.title}`,
        })
      } else if (canRNShare) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "share",
            message: noteAsText,
            title: `My Notes for ${episode.data.attributes.title}`,
          }),
        )
      } else {
        try {
          navigator.share({
            title: `My Notes for ${episode.data.attributes.title}`,
            text: noteAsText,
          })
        } catch {
          alert("Error: unable to share notes.")
        }
      }
    })
  }

  const { mutate: sendViaEmail, isPending: isSendingEmail } = useMutation({
    mutationFn: () =>
      sessionApiClient.post(
        `/publishing/v2/episodes/${episodeId}/note/export_to_email`,
      ),
    onSuccess: () => {
      setEmailSent(true)
      setTimeout(() => setEmailSent(false), 1000)
    },
  })

  function getShareLabel(defaultLabel = "Send to my email") {
    switch (true) {
      case isSendingEmail:
        return (
          <div className="d-f g-1 ai-c">
            Sending <img src={spinner} alt="Sending" height={16} width={16} />
          </div>
        )
      case emailSent:
        return "Sent!"
      default:
        return defaultLabel
    }
  }

  if (episode && canShare && canEmail) {
    return (
      <Menu>
        <MenuButton className={`btn ${buttonSize}-btn`}>
          {getShareLabel("Send notes")}
          <span className="fs-6 pl-4p dropdown-trigger__icon">
            <Icon symbol="general#down-chevron" />
          </span>
        </MenuButton>

        <MenuList>
          <MenuItem
            onSelect={sendViaEmail}
            disabled={isSendingEmail || emailSent}
          >
            {getShareLabel()}
          </MenuItem>
          <MenuItem onSelect={share}>Share via...</MenuItem>
        </MenuList>
      </Menu>
    )
  }

  if (canEmail) {
    return (
      <Button
        disabled={isSendingEmail || emailSent}
        onClick={sendViaEmail}
        size={buttonSize}
        text={getShareLabel()}
      />
    )
  }

  return (
    canShare && (
      <Button
        size={buttonSize}
        text={fetchNoteAsText.isPending ? <Spinner /> : "Send notes"}
        onClick={share}
      />
    )
  )
}

function NotesHeader() {
  const location = useLocation()
  const { episodeId } = useContext(NotesContext)
  const { width: winWidth } = useWindowDimensions()
  const buttonSize = winWidth < BREAKPOINTS.SM ? "md" : "sm"

  const { data: episodeData } = useEpisode(episodeId)

  const activeEpisodeTime = episodeData.included.find(
    (included) =>
      included.type === "EpisodeTime" &&
      included.attributes.current_state === "active",
  )

  return (
    <div
      className="notes-header"
      css={[styles.notesHeader, styles.notesHeaderSticky]}
    >
      <h2>Notes</h2>
      <div className="d-f g-1 ai-c jc-fe f-1">
        <SendNotesButton buttonSize={buttonSize} />
        {location.pathname.includes("/notes") && (
          <Link
            to={
              activeEpisodeTime
                ? `${episodePath(episodeId)}?episode_time_id=${activeEpisodeTime.id}`
                : episodePath(episodeId)
            }
            className={`btn ${buttonSize}-btn primary-btn primary-btn`}
          >
            Go to episode
          </Link>
        )}
      </div>
    </div>
  )
}

function NotesSubheader() {
  const { blanks, onChange, values } = useContext(NotesContext)
  const blankIds = Object.keys(blanks)
  const revealedBlanks = blankIds.filter((id) => values[id] === blanks[id])
  const { width: winWidth } = useWindowDimensions()
  const buttonSize = winWidth < BREAKPOINTS.SM ? "md" : "sm"

  if (blankIds?.length === 0) return null

  return (
    <div css={[styles.notesHeader, styles.notesHeaderBelow]}>
      <Button
        text={
          blankIds.length > revealedBlanks.length
            ? "Reveal all blanks"
            : "Hide all blanks"
        }
        size={buttonSize}
        variant="primary"
        onClick={() => {
          if (blankIds.length > revealedBlanks.length) {
            blankIds.forEach((id) => {
              onChange(id, blanks[id])
            })
          } else {
            blankIds.forEach((id) => {
              onChange(id, "")
            })
          }
        }}
      />
    </div>
  )
}

function NotLoggedIn() {
  return (
    <section
      className="p-2 ta-c d-f ai-c jc-c fd-c g-1 b-t"
      css={{
        /* subtract tab action bar height */
        height: "calc(100% - 32px)",
        width: "100%",
      }}
    >
      <Heading level={2} size={3} text="Log in to take notes." />
      <a
        href={`/login?return=${window.location.href}`}
        className="btn compact-btn secondary-btn mt-1"
      >
        Log in
      </a>
    </section>
  )
}

const styles = {
  notesRoot: {
    padding: "1rem",
    paddingBottom: "64px",
    lineHeight: 1.5,
    "ul,ol": {
      paddingLeft: "1rem",
      li: {
        paddingLeft: 0,
      },
    },
    "li > div": {
      marginLeft: "0.25rem",
    },
    fontSize: "18px",
    blockquote: {
      borderLeft: "1px solid var(--color-tint4)",
      paddingLeft: "1rem",
      margin: 0,
      fontSize: "16px",
      fontStyle: "italic",
      color: "var(--color-tint2)",
    },
    "h1,h2,p,blockquote": {
      marginBottom: 0,
    },
  },
  annotationIcon: {
    fontSize: 12,
    position: "absolute",
    top: 0,
    left: 0,
    width: "12px",
    height: "32px",
  },
  annotationWrapper: {
    marginTop: "4px",
    marginBottom: "16px",
    minHeight: 0,
    borderRadius: 4,
    position: "relative",
    overflow: "hidden",
    display: "grid",
    flex: "1",
    ":has(textarea:focus)": {
      background: "transparent",
    },
    ":has(textarea:focus) .annotation-icon": {
      display: "none",
    },
  },
  annotationTextarea: {
    resize: "none",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "transparent",
    border: "1px solid transparent",
    "&::placeholder": {
      color: "var(--color-tint3)",
      lineHeight: "1",
      paddingLeft: "12px",
    },
    "&:focus::placeholder": {
      visibility: "hidden",
    },
    "&:not(:empty)": {
      borderColor: "var(--color-tint5)",
    },
    "&:focus": {
      borderColor: "var(--color-brand)",
    },
  },
  inputWrapper: {
    paddingBottom: "0.25rem",
    fontSize: "inherit",
    fontWeight: "inherit",
    position: "relative",
    lineHeight: "1",
    overflow: "hidden",
    display: "inline-grid",
    flex: "1",
    gridArea: "1 / 1 / 2 / 2",
    whiteSpace: "pre-wrap",
    transition: "padding 0.2s ease-in-out",
  },
  input: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 1,
    border: "none",
    backgroundColor: "transparent",
    fontSize: "inherit",
    fontWeight: "inherit",
    padding: "0",
    transition: "opacity 0.3s ease-in-out 0.1s",
    outline: "none",
    color: "inherit",
    paddingBottom: "0.25rem",
  },
  growInput: {
    paddingBottom: "0.25rem",
    border: "none",
    font: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    color: "var(--color-tint1)",
    gridArea: "1 / 1 / 2 / 2",
    outlineColor: "transparent !important",
    boxShadow: "none !important",
    whiteSpace: "pre-wrap",
  },
  growTextarea: {
    border: "none",
    padding: "0.5rem",
    font: "inherit",
    fontSize: "16px",
    color: "var(--color-tint1)",
    gridArea: "1 / 1 / 2 / 2",
    outlineColor: "transparent !important",
    boxShadow: "none !important",
    lineHeight: 1.5,
    whiteSpace: "pre-wrap",
  },
  notesHeader: {
    ".btn": {
      background: "var(--color-tint6)",
      color: "var(--color-tint1) !important",
    },
  },
  notesHeaderSticky: {
    background: "var(--color-tint10)",
    borderBottom: "1px solid var(--color-tint6)",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    gap: "0.5rem",
    paddingInline: "1rem",
    paddingBlock: "0.5rem",
    position: "sticky",
    top: "0",
    zIndex: 900,
    ":not(.live-episode-notes-panel) &": {
      [`@media (min-width: ${BREAKPOINTS.LG}px)`]: {
        top: "68px",
      },
    },
    ".live-episode-notes-panel &": {
      borderBottom: "1px solid var(--color-tint8)",
      h2: {
        display: "none",
      },
    },
  },
  notesHeaderBelow: {
    display: "flex",
    gap: "0.5rem",
    paddingInline: "1rem",
    paddingBlock: "1rem",
  },
}
