import { useContext, useEffect, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { CardGrid, Icon } from "source/shared/components"
import { FiltersContext } from "../contexts/FiltersContext"
import { MapContext } from "../contexts/MapContext"
import { useInfiniteLoadingGroups } from "../hooks/useInfiniteLoadingGroups"
import { Group } from "./Group"
import { MapDisplay } from "./Map"

export function GroupList() {
  const { setHasLocations } = useContext(MapContext)
  const { setIncludeClosedGroups } = useContext(FiltersContext)
  const {
    records: groups,
    included,
    hasMore,
    loadMore,
  } = useInfiniteLoadingGroups()

  useEffect(() => {
    if (groups.length === 0) {
      setIncludeClosedGroups(true)
    }
  }, [])

  const locations = useMemo(
    () => included.filter((inc) => inc.type === "Location"),
    [included],
  )

  useEffect(() => {
    setHasLocations(locations.length > 0)
  }, [locations])

  if (groups.length === 0) return <BlankState />

  return (
    <>
      <MapDisplay groups={groups} locations={locations} />
      <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
        <CardGrid as="section">
          {groups.map((group) => (
            <Group key={group.id} group={group} />
          ))}
        </CardGrid>
      </InfiniteScroll>
    </>
  )
}

function BlankState() {
  return (
    <div className="ta-c c-tint2 fs-2">
      <Icon symbol="churchCenter#groups-o" className="mb-1" />
      <div>We can’t find any groups to match your criteria.</div>
    </div>
  )
}
