import { bool, func, node, string } from "prop-types"

import InfiniteScroll from "react-infinite-scroller"
import { Loading } from "source/shared/components"

LimitedInfiniteScroll.propTypes = {
  hasMore: bool,
  loadMore: func,
  loadMoreButtonText: string,
  loadingMore: bool,
  keepLoadingOnScroll: bool,
  children: node,
}

function LimitedInfiniteScroll({
  hasMore,
  loadMore,
  loadMoreButtonText,
  loadingMore,
  keepLoadingOnScroll,
  children,
}) {
  return (
    <>
      <InfiniteScroll
        hasMore={keepLoadingOnScroll && hasMore}
        loadMore={loadMore}
        loader={<Loading key="loading" />}
      >
        {children}
      </InfiniteScroll>

      {!keepLoadingOnScroll && (
        <div className="d-f jc-c">
          <LoadingIndicator
            loadingMore={loadingMore}
            hasMore={hasMore}
            loadMoreButtonText={loadMoreButtonText}
            loadMore={loadMore}
          />
        </div>
      )}
    </>
  )
}

LoadingIndicator.propTypes = {
  loadingMore: bool,
  hasMore: bool,
  loadMoreButtonText: string,
  loadMore: func,
}

function LoadingIndicator({
  loadingMore,
  hasMore,
  loadMoreButtonText,
  loadMore,
}) {
  if (loadingMore) return <Loading key="loading" />

  if (hasMore)
    return (
      <button className="btn" onClick={loadMore}>
        {loadMoreButtonText}
      </button>
    )

  return null
}

LimitedInfiniteScroll.LoadingIndicator = LoadingIndicator

export { LimitedInfiniteScroll }
