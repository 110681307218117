import { object, bool, array, number, string } from "prop-types"
import AttendeeTypeString from "./AttendeeTypeString"
import ReviewAddOnSelection from "./ReviewAddOnSelection"

const ReviewAttendee = ({
  addOns,
  attendee,
  count,
  isAddOnsEnabled,
  hasTotalDue = false,
  hasNamedAttendees = false,
  forWaitlist = false,
  totalDiscountString,
}) => {
  const {
    addOnSelections,
    totalCostCents,
    baseCostString,
    totalCostMinusDiscountsString,
  } = attendee

  const totalCostString = [
    ...(!hasNamedAttendees ? [count] : []),
    ...(!forWaitlist && !hasNamedAttendees && hasTotalDue ? ["x"] : []),
    ...(!forWaitlist && hasTotalDue ? [totalCostMinusDiscountsString] : []),
  ]

  const hasMultipleLines =
    hasTotalDue || Object.values(addOnSelections).length > 0

  const discounts = attendee.effectiveDiscountSelections

  return (
    <div className="mt-4p">
      <div className={hasMultipleLines ? "fw-500 mt-2 mb-4p" : ""}>
        <AttendeeTypeString attendee={attendee}>
          {totalCostString.join(" ")}
        </AttendeeTypeString>
      </div>

      {!forWaitlist && hasTotalDue && (
        <div className="d-f ai-c jc-sb mb-4p c-tint2">
          <div>Base cost</div>
          <div>{baseCostString}</div>
        </div>
      )}

      {!forWaitlist &&
        isAddOnsEnabled &&
        Object.values(addOnSelections).map((aos) => (
          <ReviewAddOnSelection
            key={aos.id}
            addOnSelection={aos}
            addOns={addOns}
            hasTotalDue={hasTotalDue}
          />
        ))}

      {!forWaitlist && totalCostCents > 0 && discounts.length > 0 && (
        <>
          <div className="d-f ai-c jc-sb mb-4p c-tint2">
            <div className="fw-500" style={{ color: "initial" }}>
              Discounts
            </div>
            <div>-{totalDiscountString}</div>
          </div>

          {discounts.map((discount) => (
            <div
              key={discount.discountId}
              className="d-f ai-c jc-sb mb-4p c-tint2"
            >
              <div>{discount.name}</div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

ReviewAttendee.propTypes = {
  attendee: object,
  addOns: array,
  hasTotalDue: bool,
  isAddOnsEnabled: bool,
  count: number,
  hasNamedAttendees: bool,
  forWaitlist: bool,
  totalDiscountString: string,
}

export default ReviewAttendee
