import { useEffect, useState } from "react"
import { useInterval } from "source/shared/hooks/useInterval"
import PropTypes from "prop-types"
import moment from "moment"
import { css } from "@emotion/react"
import canAutoplay from "can-autoplay"
import { Heading } from "@planningcenter/doxy-web"

Countdown.propTypes = {
  startTime: PropTypes.object.isRequired,
  onFinished: PropTypes.func,
}

export default function Countdown({ startTime, onFinished }) {
  const [, setState] = useState()

  const totalSeconds = startTime.diff(moment(), "seconds")
  const [finished, setFinished] = useState(totalSeconds <= 0)
  const [autoplayEnabled, setAutoplayEnabled] = useState(null)

  useInterval(() => {
    setState({}) // force render by setting empty state
  }, 1000)

  useEffect(() => {
    if (!finished && totalSeconds <= 0) {
      setFinished(true)
      if (onFinished) onFinished()
    }
  }, [finished, totalSeconds, onFinished])

  let formatted
  if (finished) {
    formatted = "0:00"
  } else {
    const hours = Math.floor(totalSeconds / 60 / 60)
    const minutes = Math.floor((totalSeconds / 60) % 60)
    const minutesPadded = minutes < 10 ? `0${minutes}` : minutes
    const seconds = totalSeconds % 60
    const secondsPadded = seconds < 10 ? `0${seconds}` : seconds
    formatted =
      hours > 0
        ? `${hours}:${minutesPadded}:${secondsPadded}`
        : `${minutes}:${secondsPadded}`
  }

  useEffect(() => {
    canAutoplay.video({ inline: true }).then(({ result }) => {
      setAutoplayEnabled(result)
    })
  }, [])

  return (
    <div
      css={styles.videoContainer}
      className="video-countdown d-f fd-c ai-c jc-c ta-c"
    >
      <div className="action-drawer countdown">
        <header>
          {autoplayEnabled ? (
            <>
              <img src="/assets/gfx-video-start.svg" alt="" />
              <Heading level={1} text="Welcome" />
              <Heading level={2} text="Our live stream will begin in" />
              <h3 className="timer">{formatted}</h3>
            </>
          ) : (
            <figure className="play-btn-container d-f">
              <button
                className="primary-btn btn"
                name="play"
                onClick={() => setAutoplayEnabled(true)}
              />
            </figure>
          )}
        </header>
      </div>
    </div>
  )
}

const styles = {
  videoContainer: css`
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    height: 0;
    overflow: hidden;

    .countdown {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 50px;
      height: 35px;
      @media (min-width: 960px) {
        width: 58px;
        height: 40px;
      }
      display: inline-flex;
    }

    .no-video-url {
      background: #ef5350;
      color: var(--color-gray0);
      padding: 8px 16px;
      border-radius: 3px;
    }

    header h1 {
      opacity: 0.8;
      font-weight: bold;
      font-size: 44px;
      @media (min-width: 960px) {
        font-size: 52px;
      }
      text-transform: uppercase;
    }

    header h2 {
      font-size: 16px;
      @media (min-width: 960px) {
        font-size: 20px;
      }
      opacity: 0.6;
    }

    header h3.timer {
      font-size: 40px;
      @media (min-width: 960px) {
        font-size: 44px;
      }
      display: inline;
      background-image: linear-gradient(to right, #41a5f5, #66bb6a);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }

    .play-btn-container button[name="play"] {
      width: 50px;
      height: 50px;
      margin: auto;
    }
    .play-btn-container button[name="play"]:focus {
      outline: 0;
      border: 1px solid hsl(0deg 0% 60%);
      box-shadow: 0 0 0 3px hsl(0deg 0% 73% / 50%);
    }

    .play-btn-container button[name="play"]::after {
      /* This is the caret inside the button */
      content: "";
      display: inline-block;
      position: relative;
      top: 1px;
      left: 3px;
      border-style: solid;
      border-width: 10px 0 10px 20px;
      border-color: transparent transparent transparent white;
    }
  `,
}
