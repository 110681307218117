import { Suspense, useMemo, useState } from "react"
import { useApiPost } from "source/shared/SessionApiResource"
import { apiResource, bool, object } from "source/shared/prop_types"
import constrainDimensions from "source/shared/constrainDimensions"
import { Icon } from "source/shared/components"
import "@planningcenter/experimental/css/crispy-interfaces-modal/modal.css"

export function Files({ reply }) {
  let { files } = reply.attributes
  if (!files) return null

  return (
    <>
      {files.map((file) => (
        <div key={file.identifier}>
          <ApiImg reply={reply} file={file} />
        </div>
      ))}
    </>
  )
}

Files.propTypes = {
  reply: apiResource.isRequired,
}

const MAX_IMAGE_SIZE = 200
function ApiImg({ reply, file }) {
  let { width, height } = useMemo(
    () => constrainDimensions(file, MAX_IMAGE_SIZE),
    [file],
  )

  let style = {
    borderRadius: 12,
    margin: "0 -8px 8px",
    display: "block",
  }

  return (
    <Suspense fallback={<div style={{ width, height, ...style }} />}>
      <ApiLoadedImg
        file={file}
        reply={reply}
        style={{ width, height, ...style }}
      />
    </Suspense>
  )
}

ApiImg.propTypes = {
  reply: apiResource.isRequired,
  file: object.isRequired,
}

function ApiLoadedImg({ reply, file, style }) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  let imageSrc = useApiPost(`${reply.links.self}/generate_file_link`, {
    data: {
      attributes: {
        identifier: file.identifier,
        thumb: `${MAX_IMAGE_SIZE}x${MAX_IMAGE_SIZE}`,
      },
    },
  }).data?.attributes?.link

  return imageSrc ? (
    <button
      onClick={() => setIsModalVisible(!isModalVisible)}
      style={{
        cursor: "pointer",
        border: 0,
        padding: 0,
        background: "transparent",
      }}
    >
      <img src={imageSrc} alt={file.identifier} style={style} />
      {isModalVisible && <LargeImageModal reply={reply} file={file} />}
    </button>
  ) : null
}

ApiLoadedImg.propTypes = {
  reply: apiResource.isRequired,
  file: object.isRequired,
  style: object.isRequired,
}

function LargeImageModal({ reply, file }) {
  let fullImageSrc = useApiPost(`${reply.links.self}/generate_file_link`, {
    data: {
      attributes: {
        identifier: file.identifier,
      },
    },
  }).data?.attributes?.link

  return (
    <div className="modal--open">
      <div className="modal-layer">
        <div
          className="modal"
          style={{
            width: "auto",
            maxWidth: "800px",
            paddingTop: 0,
          }}
        >
          <div className="modal__body d-f jc-c">
            <button
              className="modal__close-button"
              aria-label="Close modal"
              style={{ top: "1.5rem" }}
            >
              <Icon symbol="general#x" />
            </button>
            <img
              src={fullImageSrc}
              alt={file.identifier}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LargeImageModal.propTypes = {
  reply: object.isRequired,
  file: object.isRequired,
}
