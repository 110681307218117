import { Fragment, useEffect, useState, useContext } from "react"
import { node } from "prop-types"
import { redirectTo } from "source/shared/routingUtils"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"
import { useLocalStorage } from "./useLocalStorage"
import {
  VERIFIED_IN_SESSION_STRATEGY,
  VERIFIED_IN_MEMORY_STRATEGY,
} from "source/login/FindPersonConstants"

export function useSession(requireLogin = false) {
  const { current_person } = useContext(ServerRenderedProps)

  const meta = {
    authenticated: [
      VERIFIED_IN_SESSION_STRATEGY,
      VERIFIED_IN_MEMORY_STRATEGY,
    ].includes(current_person.data.type),
  }

  if (requireLogin && !meta.authenticated)
    redirectTo(`/login?return=${window.location.href}`)

  return { ...current_person, meta }
}

ProtectAgainstChangingSessions.propTypes = {
  children: node.isRequired,
}
export function ProtectAgainstChangingSessions({ children }) {
  const frequency = 60000 // 60000 milliseconds = 1 minute
  const localStorageKey = "ProtectAgainstChangingSessions-id"
  const [_, setProtectAgainstChangingSessionId] = useLocalStorage(
    localStorageKey,
    null,
  )

  const { data: currentPerson } = useSession(false)
  const currentPersonIdString = `${currentPerson.id}`

  const [sessionChanged, setSessionChanged] = useState(false)
  useEffect(broadcastSession, [currentPersonIdString])
  useEffect(() => {
    window.addEventListener("storage", receiveSession)
    return () => {
      window.removeEventListener("storage", receiveSession)
    }
  })
  useEffect(() => {
    const interval = window.setInterval(manualCheckSession, frequency)
    return () => window.clearInterval(interval)
  })

  function broadcastSession() {
    setProtectAgainstChangingSessionId(currentPersonIdString)
  }

  function receiveSession(event) {
    if (event.key !== localStorageKey) return
    const nextPersonIdString = event.newValue
    setSessionChanged(currentPersonIdString !== nextPersonIdString)
  }

  async function manualCheckSession() {
    const session = await sessionApiClient.check()
    const nextPersonIdString = `${session.data.id}`
    setSessionChanged(currentPersonIdString !== nextPersonIdString)
  }

  return sessionChanged ? (
    <Fragment>
      <div className="d-f fs-0 g-1">
        <span aria-label="waving hand emoji" role="img">
          👋
        </span>
        <Heading level={1} text="Hey!" />
      </div>
      <p className="my-2">
        Logging in and out of Church Center changed in another browser window.
        Sometimes this will happen on a shared computer. To make sure
        you&apos;re logged in as who you expect to be, we&apos;ll need to
        refresh the page.
      </p>
      <div className="d-f py-2">
        <button className="btn" onClick={() => window.location.reload()}>
          Refresh
        </button>
      </div>
    </Fragment>
  ) : (
    children
  )
}
