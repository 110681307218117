import { useState } from "react"
import {
  arrayOf,
  shape,
  string,
  number,
  func,
  oneOfType,
  bool,
} from "prop-types"
import { Icon } from "source/shared/components"
import { css } from "@emotion/react"
import classNames from "classnames"

export function SavedPaymentMethods({ paymentMethods, onPaymentMethodChange }) {
  const [selectedCard, setSelectedCard] = useState(null)

  const handlePaymentMethodChange = (paymentMethod) => {
    setSelectedCard(paymentMethod.id)
    onPaymentMethodChange(paymentMethod)
  }

  return (
    <div className="d-f fd-c g-1" css={paymentMethodStyles}>
      {paymentMethods.map((paymentMethod, index) => (
        <PaymentMethodRow
          key={index}
          data={paymentMethod.card || paymentMethod.us_bank_account}
          isCard={!!paymentMethod.card}
          onClick={() => handlePaymentMethodChange(paymentMethod)}
          selected={selectedCard === paymentMethod.id}
        />
      ))}
    </div>
  )
}

const cardProps = shape({
  brand: string.isRequired,
  last4: string.isRequired,
  exp_month: number.isRequired,
  exp_year: number.isRequired,
})

const bankProps = shape({
  account_type: string.isRequired,
  bank_name: string.isRequired,
  last4: string.isRequired,
})

SavedPaymentMethods.propTypes = {
  paymentMethods: arrayOf(
    shape({
      id: string.isRequired,
      type: string.isRequired,
      card: cardProps,
      us_bank_account: bankProps,
    }),
  ),
  onPaymentMethodChange: func,
}

const PaymentMethodRow = ({ data, onClick, selected, isCard }) => {
  return (
    <div
      role="button"
      className={classNames("paymentMethod--row", { selected: selected })}
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      {/* TODO: render a glyph for the credit card provider - Visa, Mastercard, etc. */}
      <Icon symbol={isCard ? "general#credit-card" : "churchCenter#bank"} />
      <span className="fw-500">{data.brand || data.bank_name}</span>
      <span className="fs-4 c-tint2">****{data.last4}</span>
      {isCard && (
        <span className="fs-4 c-tint2">
          {data.exp_month}/{data.exp_year.toString().substring(2)}
        </span>
      )}
    </div>
  )
}
PaymentMethodRow.propTypes = {
  data: oneOfType([cardProps, bankProps]).isRequired,
  onClick: func,
  selected: bool,
  isCard: bool,
}

const paymentMethodStyles = css`
  .paymentMethod--row {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid var(--color-tint9);
    background-color: var(--color-tint9);
    transition:
      background-color 100ms ease-in,
      border-color 100ms ease-in;
  }

  .paymentMethod--row:hover {
    cursor: pointer;
    background-color: var(--color-tint7);
    border-color: var(--color-tint7);
    transition:
      background-color 100ms ease-in,
      border-color 100ms ease-in;
  }

  .paymentMethod--row.selected {
    border-color: var(--color-topaz);
    background-color: hsl(207, 90%, 93%);
  }
`

export const fakePaymentMethodResponse = {
  object: "list",
  url: "/v1/customers/cus_9s6XKzkNRiz8i3/payment_methods",
  has_more: false,
  data: [
    {
      id: "pm_1Q0PsIJvEtkwdCNYMSaVuRz6",
      object: "payment_method",
      allow_redisplay: "unspecified",
      billing_details: {
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
        email: null,
        name: "John Doe",
        phone: null,
      },
      created: 1726673582,
      customer: null,
      livemode: false,
      metadata: {},
      type: "us_bank_account",
      us_bank_account: {
        account_holder_type: "individual",
        account_type: "checking",
        bank_name: "STRIPE TEST BANK",
        financial_connections_account: null,
        fingerprint: "LstWJFsCK7P349Bg",
        last4: "6789",
        networks: {
          preferred: "ach",
          supported: ["ach"],
        },
        routing_number: "110000000",
        status_details: {},
      },
    },
    {
      id: "pm_1NVChw2eZvKYlo2CHxiM5E2E",
      object: "payment_method",
      billing_details: {
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
        email: null,
        name: null,
        phone: null,
      },
      card: {
        brand: "Visa",
        checks: {
          address_line1_check: null,
          address_postal_code_check: null,
          cvc_check: "pass",
        },
        country: "US",
        exp_month: 12,
        exp_year: 2034,
        fingerprint: "Xt5EWLLDS7FJjR1c",
        funding: "credit",
        generated_from: null,
        last4: "4242",
        networks: {
          available: ["visa"],
          preferred: null,
        },
        three_d_secure_usage: {
          supported: true,
        },
        wallet: null,
      },
      created: 1689682128,
      customer: "cus_9s6XKzkNRiz8i3",
      livemode: false,
      metadata: {},
      redaction: null,
      type: "card",
    },
    {
      id: "pm_1NVChw2eZvKYlo2CHxiM5B2B",
      object: "payment_method",
      billing_details: {
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
        email: null,
        name: null,
        phone: null,
      },
      card: {
        brand: "Mastercard",
        checks: {
          address_line1_check: null,
          address_postal_code_check: null,
          cvc_check: "pass",
        },
        country: "US",
        exp_month: 12,
        exp_year: 2034,
        fingerprint: "Xx5XXXXXX7FJjR1c",
        funding: "credit",
        generated_from: null,
        last4: "1234",
        networks: {
          available: ["mastercard"],
          preferred: null,
        },
        three_d_secure_usage: {
          supported: true,
        },
        wallet: null,
      },
      created: 1689682128,
      customer: "cus_9s6XKzkNRiz8i3",
      livemode: false,
      metadata: {},
      redaction: null,
      type: "card",
    },
    {
      id: "pm_1NVChw2eZvKYlo2CHxiM5C2C",
      object: "payment_method",
      billing_details: {
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
        email: null,
        name: null,
        phone: null,
      },
      card: {
        brand: "American Express",
        checks: {
          address_line1_check: null,
          address_postal_code_check: null,
          cvc_check: "pass",
        },
        country: "US",
        exp_month: 12,
        exp_year: 2034,
        fingerprint: "Xx5XXXXXX7FJjR2c",
        funding: "credit",
        generated_from: null,
        last4: "5678",
        networks: {
          available: ["amex"],
          preferred: null,
        },
        three_d_secure_usage: {
          supported: true,
        },
        wallet: null,
      },
      created: 1689682128,
      customer: "cus_9s6XKzkNRiz8i3",
      livemode: false,
      metadata: {},
      redaction: null,
      type: "card",
    },
  ],
}
