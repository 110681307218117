import { buildUrl } from "source/shared/buildUrl"
import { getRelationship } from "source/shared/getRelationship"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"

const DEFAULT_PARAMS = {
  order: "sequence",
  include: "options,form_field_conditions,options.optionable",
  per_page: 25,
  fields: { FormFieldOptionable: "quantity" },
}

export function useFormFields({ formId }) {
  const url = buildFormFieldsUrl(formId)
  const {
    records: formFields,
    included: includedFormFields,
    ...rest
  } = useInfiniteLoadingResource(url)

  const formFieldOptionables = includedFormFields?.filter(
    (record) => record.type === "FormFieldOptionable",
  )

  const formFieldOptions = includedFormFields
    ?.filter((record) => record.type === "FormFieldOption")
    .map((option) => {
      return {
        ...option,
        optionable: getRelationship(
          { data: option, included: formFieldOptionables },
          "optionable",
        ),
      }
    })

  const formFieldConditions = includedFormFields?.filter(
    (record) => record.type === "FormFieldCondition",
  )

  return { formFields, formFieldConditions, formFieldOptions, ...rest }
}

function buildFormFieldsUrl(formId) {
  return buildUrl(`/calendar/v2/forms/${formId}/fields`, DEFAULT_PARAMS)
}
