type ResourceQuantityProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  quantity: string | number
  maxQuantity: number
}

export default function ResourceQuantity({
  onChange,
  id,
  quantity,
  maxQuantity,
}: ResourceQuantityProps) {
  return (
    <div className="d-f ai-c">
      <div className="f-1" style={{ maxWidth: "15%" }}>
        <input
          id={id}
          onChange={onChange}
          type="number"
          value={quantity.toString()}
        />
      </div>
      <div className="m-1">
        <span className="c-tint3 fs-5">of {maxQuantity}</span>
      </div>
    </div>
  )
}
