import { Suspense } from "react"
import PropTypes from "prop-types"
import { useApiRead } from "source/shared/SessionApiResource"
import { buildUrl } from "source/shared/buildUrl"
import { maxEventCount } from "./config"

export const GroupAttendanceAverageSummary = ({ groupId }) => {
  return (
    <Suspense fallback="Finding attendance...">
      <GroupAttendanceAverage groupId={groupId} />
    </Suspense>
  )
}

GroupAttendanceAverageSummary.propTypes = {
  groupId: PropTypes.number.isRequired,
}

const GroupAttendanceAverage = ({ groupId }) => {
  const url = buildUrl(`/groups/v2/me/groups/${groupId}/attendance_average`, {
    filter: "by_event_count",
    event_count: maxEventCount.toString(),
    "fields[AttendanceAverage]": "attended_percentage,event_count",
  })
  const response = useApiRead(url)

  if (!response.data) return "Review past event attendance."

  const percentage = response.data?.attributes?.attended_percentage || 0
  const eventCount = response.data?.attributes?.event_count || 0

  if (eventCount === 0) return "Take attendance to see trends."

  const eventCountMessage =
    eventCount > 1
      ? `through the last ${eventCount} events`
      : "on the last event"

  return (
    <span>
      <strong>{percentage}% attended</strong> {eventCountMessage}.
    </span>
  )
}

GroupAttendanceAverage.propTypes = {
  groupId: PropTypes.number.isRequired,
}
