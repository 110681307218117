import {
  shape,
  oneOf,
  string,
  arrayOf,
  number,
  func,
} from "source/shared/prop_types"
import moment from "moment"
import { DateInput } from "./DateInput"
import {
  frequencies,
  frequencyForDate,
  resolveDeprecatedFrequency,
} from "../utils/frequency"
import { MonthlyWeekCountSelect } from "./MonthlyWeekCountSelect"
import { Select } from "@planningcenter/doxy-web"

export function RepeatingDateFields({ startDate, attributes, onChange }) {
  const { last_occurrence_date, week_count } = attributes
  const startDateDate = new Date(startDate)

  const frequency_name = resolveDeprecatedFrequency(
    attributes.frequency_name,
    week_count,
  )

  const onFrequencyChange = (e) => {
    const frequency_name = e.target.value
    let attrs = {}
    switch (frequency_name) {
      case frequencies.NEVER:
        attrs = {
          frequency_name,
          last_occurrence_date: undefined,
          week_count: undefined,
        }
        break
      default:
        attrs = {
          frequency_name,
          last_occurrence_date,
          week_count: undefined,
        }
    }
    onChange(attrs)
  }

  const onLastOccurrenceChange = (date) => {
    const last_occurrence_date = moment(date).toISOString(true)
    onChange({ ...attributes, last_occurrence_date })
  }

  /**
   * @param {string} value
   */
  const onWeekCountChange = (value) => {
    const week_count = value
      ? value.split(",").map((v) => Number(v))
      : undefined
    onChange({ ...attributes, week_count })
  }

  const isRepeating = frequency_name !== frequencies.NEVER
  const isMonthlyOnWeekday = frequency_name === frequencies.MONTHLY_ON_WEEKDAY

  const frequencyOptions = frequencyForDate({
    date: startDateDate,
    includeNever: true,
  })

  return (
    <div className="d-f fw-w">
      <div className={!isRepeating ? "f-1" : "f-1 pr-1"}>
        <label htmlFor="event_repeating_frequency_name" className="label">
          Repeat
        </label>
        <Select
          id="event_repeating_frequency_name"
          key={frequency_name}
          onBlur={onFrequencyChange}
          onChange={onFrequencyChange}
          value={frequency_name}
        >
          {frequencyOptions.map((option) => (
            <Select.Option
              key={option.value}
              label={option.label}
              value={option.value}
            />
          ))}
        </Select>
      </div>
      {isRepeating && (
        <>
          {isMonthlyOnWeekday && (
            <MonthlyWeekCountSelect
              date={startDateDate}
              value={week_count ? week_count.join(",") : null}
              onChange={onWeekCountChange}
            />
          )}
          <div className={isMonthlyOnWeekday ? "w-100% mt-2" : "f-1 pl-1"}>
            <label
              htmlFor="event_repeating_last_occurrence_date"
              className="label"
            >
              Repeat until <span className="c-ruby"> *</span>
            </label>
            <DateInput
              id="event_repeating_last_occurrence_date"
              required
              minDate={moment(startDate).toDate()}
              selected={
                last_occurrence_date
                  ? moment(last_occurrence_date).toDate()
                  : null
              }
              onChange={onLastOccurrenceChange}
            />
          </div>
        </>
      )}
    </div>
  )
}
RepeatingDateFields.propTypes = {
  startDate: string.isRequired, // ISO formatted date/time string
  attributes: shape({
    frequency_name: oneOf([
      "never",
      "weekly",
      "biweekly",
      "monthly", // deprecated
      "monthly_on_day",
      "monthly_on_weekday",
      "yearly",
    ]).isRequired,
    last_occurrence_date: string,
    week_count: arrayOf(number),
  }).isRequired,
  onChange: func.isRequired,
}
