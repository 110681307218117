import { useContext } from "react"
import { node } from "prop-types"
import { colors } from "source/shared/colors"
import { Icon } from "source/shared/components"
import { FiltersContext } from "../contexts/FiltersContext"
import { MapContext } from "../contexts/MapContext"
import { TagFilter } from "./TagFilter"
import { MapButton } from "./Map"
import { Grid } from "@planningcenter/doxy-web"

export function Filters() {
  const { mapIsPossible } = useContext(MapContext)
  return (
    <Grid columns={1} gap={2}>
      <SearchRowWrapper>
        <SearchBar />
        {mapIsPossible && <MapButton />}
      </SearchRowWrapper>
      <TagFilter />
      <EnrollmentStatus />
    </Grid>
  )
}

function SearchRowWrapper({ children }) {
  return (
    <div>
      <div className="d-f jc-sb ai-c">{children}</div>
    </div>
  )
}
SearchRowWrapper.propTypes = {
  children: node.isRequired,
}

function SearchBar() {
  const { searchTerm, setSearchTerm } = useContext(FiltersContext)
  return (
    <div style={{ flex: 1, position: "relative" }}>
      <input
        type="text"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        style={{ paddingLeft: 32 }}
        aria-label="Search for groups"
      />
      <span
        style={{
          color: colors.tint4,
          fontSize: "14px",
          left: "10px",
          top: "11px",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <Icon symbol="general#search" aria-hidden />
      </span>
    </div>
  )
}

function EnrollmentStatus() {
  const { includeClosedGroups, setIncludeClosedGroups } =
    useContext(FiltersContext)
  return (
    <div className="mb-3">
      <input
        type="checkbox"
        id="enrollment-status"
        className="checkbox"
        checked={includeClosedGroups}
        onChange={(e) => setIncludeClosedGroups(e.target.checked)}
      />
      <label htmlFor="enrollment-status" className="checkbox-label">
        Include closed & full groups
      </label>
    </div>
  )
}
