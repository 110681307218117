import { useState } from "react"
import { array, func, object } from "prop-types"
import { groupBy } from "lodash"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import { getRelationship } from "source/shared/getRelationship"
import { SelectableIndividualOptIn } from "source/directory/edit/IndividualOptIn"
import { DIRECTORY_STATUS_PATH } from "source/directory/hooks/useDirectoryStatus"
import { useApiRead } from "source/shared/SessionApiResource"
import { HouseholdAvatarSelect } from "source/directory/HouseholdAvatar"
import { Icon } from "source/shared/components"

import { css } from "@emotion/react"

HouseholdsOptIn.propTypes = {
  directoryFieldData: array.isRequired,
  directoryPrivacySettings: array.isRequired,
  directoryFields: object.isRequired,
  households: object.isRequired,
  onUpdate: func.isRequired,
  onPrivacySettingsUpdate: func.isRequired,
}

export function HouseholdsOptIn({
  directoryFieldData,
  directoryFields,
  directoryPrivacySettings,
  households,
  onUpdate,
  onPrivacySettingsUpdate,
}) {
  const hasMultipleHouseholds = households.data.length > 1
  const householdsHeading = hasMultipleHouseholds
    ? "My households"
    : households.data[0].attributes.name
  const householdsMessage = hasMultipleHouseholds
    ? "You will appear in the directory in these households. For each household, you can set a household photo and select the details you want to share about other household members."
    : "You will appear in the directory in this household. You can set a household photo and select the details you want to share about others in your household."

  return (
    <div className="mt-4">
      <div className="mb-1">
        <Heading level={1} size={2} text={householdsHeading} />
      </div>
      <p className="mb-3">{householdsMessage}</p>
      {hasMultipleHouseholds ? (
        households.data.map((household) => (
          <ExpandableHouseholdOptIn
            directoryFields={directoryFields}
            directoryFieldData={directoryFieldData}
            directoryPrivacySettings={directoryPrivacySettings}
            household={household}
            included={households.included}
            key={household.id}
            onUpdate={onUpdate}
            onPrivacySettingsUpdate={onPrivacySettingsUpdate}
          />
        ))
      ) : (
        <HouseholdOptIn
          directoryFields={directoryFields}
          directoryFieldData={directoryFieldData}
          directoryPrivacySettings={directoryPrivacySettings}
          household={households.data[0]}
          included={households.included}
          onUpdate={onUpdate}
          onPrivacySettingsUpdate={onPrivacySettingsUpdate}
        />
      )}
    </div>
  )
}

HouseholdOptIn.propTypes = {
  directoryFields: object.isRequired,
  directoryFieldData: array.isRequired,
  directoryPrivacySettings: array.isRequired,
  household: object.isRequired,
  included: array.isRequired,
  onUpdate: func.isRequired,
  onPrivacySettingsUpdate: func.isRequired,
}

function HouseholdOptIn({
  household,
  included,
  directoryFields,
  directoryFieldData,
  directoryPrivacySettings,
  onPrivacySettingsUpdate,
  onUpdate,
}) {
  const {
    data: { id: meId },
  } = useApiRead(DIRECTORY_STATUS_PATH)
  const people = getRelationship({ data: household, included }, "people")
  const {
    adults = [],
    children = [],
    self: _filteredOut,
  } = groupBy(people, (person) => {
    if (person.id === meId) return "self"
    return person.attributes.child ? "children" : "adults"
  })
  return (
    <div className="mt-3">
      <HouseholdAvatarSelect
        household={household}
        src="http://first.churchcenter.test/static/no-event-image.png"
      />
      {adults.length > 0 && (
        <>
          <div className="mb-1">
            <Subheading level={3} text="Adults" />
          </div>
          <div className="mb-1">
            {adults.map((person) => (
              <div className="d-f p-r" key={person.id}>
                <SelectableIndividualOptIn
                  person={{ data: person, included }}
                  directoryFields={directoryFields}
                  directoryFieldData={directoryFieldData}
                  directoryPrivacySettings={directoryPrivacySettings}
                  householdId={household.id}
                  onUpdate={onUpdate}
                  onPrivacySettingsUpdate={onPrivacySettingsUpdate}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {children.length > 0 && (
        <>
          <div className="mb-1">
            <Subheading level={3} text="Children" />
          </div>
          <div className="mb-1">
            {children.map((person) => (
              <div className="d-f p-r" key={person.id}>
                <SelectableIndividualOptIn
                  person={{ data: person, included }}
                  directoryFields={directoryFields}
                  directoryFieldData={directoryFieldData}
                  directoryPrivacySettings={directoryPrivacySettings}
                  onUpdate={onUpdate}
                  onPrivacySettingsUpdate={onPrivacySettingsUpdate}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

ExpandableHouseholdOptIn.propTypes = { household: object.isRequired }

function ExpandableHouseholdOptIn(props) {
  const [open, setOpen] = useState(false)
  const { household } = props
  const chevronSymbol = open ? "general#down-chevron" : "general#right-chevron"
  const householdButton = css`
    border: none;
  `

  return (
    <>
      <button
        className="action-drawer mb-1 d-f fd-rr ai-fs jc-sb w-100%"
        css={householdButton}
        onClick={() => setOpen((open) => !open)}
      >
        <Icon
          className="c-tint4 ar"
          symbol={chevronSymbol}
          aria-hidden="true"
        />
        {household.attributes.name}
      </button>
      {open && <HouseholdOptIn {...props} />}
    </>
  )
}
