import { useState } from "react"
import { find, isEmpty } from "lodash"
import { array, bool, func, string } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"
import TeamLeaderCheckbox from "./TeamLeaderCheckbox"

const NUMBER_OF_LEADERS_TO_SHOW = 5

export default function TeamLeaderMultiSelect({
  handleSelectTeamLeader,
  teamLeaders,
  selectedTeamLeaderIds,
}) {
  const [showAll, setShowAll] = useState(false)
  const shouldHaveShowAllButton = teamLeaders.length > NUMBER_OF_LEADERS_TO_SHOW

  const selectedListItems =
    !isEmpty(selectedTeamLeaderIds) &&
    selectedTeamLeaderIds
      .map((teamLeaderId) => {
        const selectedTeamLeader = find(teamLeaders, {
          personId: teamLeaderId,
        })
        return selectedTeamLeader.personName
      })
      .join(", ")

  const leadersToShow = showAll
    ? teamLeaders
    : teamLeaders.slice(0, NUMBER_OF_LEADERS_TO_SHOW)

  return (
    <>
      <div className="mb-2">
        <Heading level={1} size={3} text="Send to" />
      </div>
      {leadersToShow.map((teamLeader) => (
        <TeamLeaderCheckbox
          key={teamLeader.personId}
          teamLeader={teamLeader}
          selectedTeamLeaderIds={selectedTeamLeaderIds}
          onSelectTeamLeader={() => handleSelectTeamLeader(teamLeader.personId)}
        />
      ))}
      {shouldHaveShowAllButton && (
        <button className="btn link-btn" onClick={() => setShowAll(!showAll)}>
          {showAll ? "Show less" : "Show all"}
        </button>
      )}
    </>
  )
}

TeamLeaderMultiSelect.propTypes = {
  disabled: bool,
  handleSelectTeamLeader: func,
  teamLeaders: array,
  placeholder: string,
  selectedTeamLeaderIds: array,
}
