import { useParams } from "react-router-dom"
import { capitalize } from "lodash"
import {
  BreadcrumbNavigation,
  BreadcrumbContent,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import BlankState from "source/groups/BlankState"
import { EventsList } from "source/groups/components/EventsList"

export default function EventsIndex() {
  return (
    <>
      <BreadcrumbNav />
      <List />
    </>
  )
}

function BreadcrumbNav() {
  const { groupSlug, filter } = useParams()
  const {
    data: {
      attributes: { church_center_web_url, name },
    },
  } = useApiRead(
    `/groups/v2/groups/${groupSlug}?fields[Group]=church_center_web_url,name`,
  )
  const relativeGroupLink = new URL(church_center_web_url).pathname

  return (
    <BreadcrumbNavigation>
      <BreadcrumbPage linkUrl={relativeGroupLink} pageText={name} />
      <BreadcrumbDividerIcon />
      <BreadcrumbContent isActive>
        {capitalize(filter)} events
      </BreadcrumbContent>
    </BreadcrumbNavigation>
  )
}

function List() {
  const { groupSlug, filter } = useParams()
  const query = new URLSearchParams({
    filter,
    order: filter === "upcoming" ? "starts_at" : "-starts_at",
  })
  const { records: events = [], ...rest } = useInfiniteLoadingResource(
    `/groups/v2/groups/${groupSlug}/events?${query.toString()}`,
  )
  const getEventUrl = (event) => event.attributes.church_center_web_url

  return (
    <EventsList events={events} getEventUrl={getEventUrl} {...rest}>
      <div className="d-f ai-c mb-2">
        <EventsList.Header>{capitalize(filter)} events</EventsList.Header>
      </div>
      <EventsList.Content>
        <EventsList.List />
      </EventsList.Content>
      <EventsList.Empty>
        <BlankState icon="churchCenter#calendar-events">
          <p>There are no upcoming events</p>
        </BlankState>
      </EventsList.Empty>
    </EventsList>
  )
}
