import ReactDatePicker from "react-datepicker"

export function DateInput(props) {
  return (
    <ReactDatePicker
      placeholder="Select a date"
      wrapperClassName="w-100%"
      showPopperArrow={false}
      dateFormat="M/d/yyyy"
      {...props}
    />
  )
}
