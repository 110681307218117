import { buildUrl } from "source/shared/buildUrl"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"

const DEFAULT_PARAMS = { order: "last_name,first_name", per_page: 50 }

export function usePeople(params = {}) {
  const url = buildPeopleUrl(params)
  const { records: people, ...rest } = useInfiniteLoadingResource(url)
  return { people, ...rest }
}

function buildPeopleUrl(params) {
  const paramsWithDefaults = { ...DEFAULT_PARAMS, ...params }
  return buildUrl(`/people/v2/directory_people`, paramsWithDefaults)
}
