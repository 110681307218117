import { shape, string, number, oneOf, oneOfType } from "prop-types"
import { LocationMap } from "source/shared/components"
import { Subheading } from "@planningcenter/doxy-web"

const virtualLocationPropTypes = {
  url: string,
}

const physicalLocationPropTypes = {
  name: string,
  strategy: string,
  latitude: oneOfType([string, number]),
  longitude: oneOfType([string, number]),
  radius: number,
}

const propTypes = {
  preference: oneOf(["virtual", "physical"]).isRequired,
  virtual: shape(virtualLocationPropTypes),
  physical: shape(physicalLocationPropTypes),
}

export function LocationDetails({ preference, virtual, physical }) {
  if (preference === "virtual") {
    return <VirtualLocationDetails {...virtual} />
  } else if (preference === "physical" && physical) {
    return <PhysicalLocationDetails {...physical} />
  } else {
    return null
  }
}
Location.propTypes = propTypes

function VirtualLocationDetails({ url }) {
  return (
    <section className="mb-4">
      <div className="mb-1">
        <Subheading level={2} text="Location" />
      </div>
      <p className="mb-0 fs-4 c-tint1">Online</p>
      {url && (
        <a
          className="mt-1 btn minor-btn secondary-btn"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Attend online
        </a>
      )}
    </section>
  )
}
VirtualLocationDetails.propTypes = virtualLocationPropTypes

function PhysicalLocationDetails(location) {
  if (
    !location ||
    location.strategy === "hidden" ||
    !location.latitude ||
    !location.longitude
  )
    return null

  const mapProps = locationMapPropsFromGroupsLocation(location)

  return (
    <section className="mb-4">
      <div className="mb-1">
        <Subheading level={2} text="Location" />
      </div>
      {location.name && <p className="mb-0 fs-4 c-tint1">{location.name}</p>}
      <LocationMap {...mapProps} />
    </section>
  )
}
PhysicalLocationDetails.propTypes = physicalLocationPropTypes

function locationMapPropsFromGroupsLocation(location) {
  switch (location.strategy) {
    case "exact":
      return {
        location: {
          latitude: location.latitude,
          longitude: location.longitude,
          formattedAddress: location.full_formatted_address,
          fullFormattedAddress: location.full_formatted_address,
        },
      }
    case "approximate":
      return {
        location: {
          approximation: {
            center: {
              lat: location.latitude,
              lng: location.longitude,
            },
            radius: location.radius,
          },
          formattedAddress: location.full_formatted_address,
          fullFormattedAddress: location.full_formatted_address,
        },
      }
    default:
      return {
        location: null,
      }
  }
}
