import { useContext } from "react"
import { CurrentEventContext } from "source/groups/my/groups"
import { Navigate } from "react-router-dom"
import TabView from "source/groups/my/groups/TabView"
import EventForm from "source/groups/my/groups/events/form"
import { Heading } from "@planningcenter/doxy-web"

export default function EventsEdit() {
  const currentEvent = useContext(CurrentEventContext)
  const { event, base_path: eventPath } = currentEvent
  const { abilities, name } = event.attributes

  if (!abilities.can_manage) return <Navigate to={eventPath} replace />

  return (
    <TabView showActions={false}>
      <div className="mt-1 mb-3">
        <Heading level={1} size={3} text={`Edit ${name}`} />
      </div>
      <EventForm currentEvent={currentEvent} />
    </TabView>
  )
}
