import { useApiRead } from "source/shared/SessionApiResource"

export function useBlockoutIsSharedWithAnyHouseholdMembers({
  group_identifier,
  isNew,
  isOwner,
  manageableHouseholdMemberIds,
}) {
  if (isNew) return false
  if (!isOwner) return false

  return manageableHouseholdMemberIds.some(
    (id) =>
      useApiRead(
        `/services/v2/me/household_members/${id}/blockouts?where[group_identifier]=${group_identifier}`,
      ).data.length,
  )
}
