import { Suspense, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { get as dig } from "lodash"
import { Heading } from "@planningcenter/doxy-web"
import { Loading } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import { Form } from "./components/Form"

export default function ResourcesNew() {
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)
  const canCreateResources = dig(group, "abilities.resources.can_create", false)

  if (!canCreateResources) {
    navigate(`${group.base_path}/resources`)
  }

  return (
    <TabView>
      <Suspense fallback={<Loading />}>
        <Loaded />
      </Suspense>
    </TabView>
  )
}

function Loaded() {
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(
    `/groups/v2/me/groups/${group.id}/resources?per_page=0`,
  )

  return (
    <>
      <div className="mt-1 mb-3">
        <Heading level={1} size={3} text="New resource" />
      </div>
      <Form>
        <Form.Name />
        <Form.Description />
        <Form.ResourceTypeSelector meta={response.meta} />
        <Form.Visibility />
      </Form>
    </>
  )
}
