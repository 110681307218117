import classNames from "classnames"
import { node, string } from "prop-types"
import { Icon } from "source/shared/components"

AlertMessage.propTypes = {
  children: node,
  className: string,
  type: string,
  icon: string,
}

AlertMessage.defaultProps = {
  className: "",
  type: "error",
}

export function AlertMessage(props) {
  const { children, type, icon, ...rest } = props

  const className = classNames(props.className, `${props.type}-alert alert`)

  const getIconColor = () => {
    let color = ""
    if (type === "error") {
      color = "var(--color-ruby)"
    }
    if (type === "warning") {
      color = "var(--color-citrine)"
    }
    if (type === "info") {
      color = "var(--color-topaz)"
    }
    if (type === "success") {
      color = "var(--color-emerald)"
    }
    return color
  }

  return (
    <div
      {...rest}
      className={className}
      style={icon && { display: "flex", gap: "0.5rem" }}
    >
      {icon && (
        <Icon
          symbol={icon}
          style={{ color: getIconColor(), marginTop: "2px" }}
        />
      )}
      <div className="f-1">{children}</div>
    </div>
  )
}
