import type { Error, FormField } from "./types"

import CheckboxesField from "./CheckboxesField"
import DropdownField from "./DropdownField"

type MultipleOptions = {
  selected?: string[]
}

type SingleOption = string

type RoomFieldProps = {
  errors: Error[]
  field: FormField
  onChange: (value: MultipleOptions | SingleOption, id: string) => void
}

export default function RoomField({ errors, field, onChange }: RoomFieldProps) {
  const {
    attributes: {
      settings: { multiple_selections },
    },
  } = field

  return multiple_selections ? (
    <CheckboxesField {...{ errors, field, onChange }} />
  ) : (
    <DropdownField {...{ errors, field, onChange }} />
  )
}
