import { useEffect, useRef, useCallback } from "react"
import { useApiRead, useApiPost } from "source/shared/SessionApiResource"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Error404 } from "source/shared/components"
import { useParams } from "react-router-dom"

export default function MessageShow() {
  const { id } = useParams()
  const message = useApiRead(`/people/v2/me/messages/${id}`)

  // Messages are deleted after a few months.
  if (message.errors) return <Error404 />

  const messageHtml = useApiPost(`/people/v2/me/messages/${id}/view_html`)
  const messageIframe = useRef(null)

  const { subject, from_name } = message.data.attributes
  const { html } = messageHtml.data.attributes

  useEffect(() => {
    sessionApiClient.post(`/people/v2/me/messages/${id}/mark_read`)
  }, [])

  const transformIframe = useCallback(() => {
    // resize iframe to content size
    messageIframe.current.style.height =
      messageIframe.current.contentWindow.document.body.scrollHeight + "px"

    // update links to open in new tab
    messageIframe.current.contentWindow.document.body
      .querySelectorAll("a")
      .forEach((a) => {
        if (a.href) a.target = "_blank"
      })
  })

  return (
    <div>
      <div className="mb-2">
        <p className="mb-1">
          <strong>From:</strong> {from_name}
        </p>
        <hr
          className="bc-tint9 mb-1 bw-0"
          style={{ borderBottomWidth: "1px" }}
        />
        <p>
          <strong>Subject:</strong> {subject}
        </p>
      </div>
      <div className="gc-tint9 p-6 br-4p">
        <iframe
          ref={messageIframe}
          title={subject}
          srcDoc={html}
          style={{ border: "none", width: "100%" }}
          onLoad={transformIframe}
        />
      </div>
    </div>
  )
}
