import { Suspense, useState, useEffect } from "react"
import { get as dig } from "lodash"
import { bool, string } from "prop-types"
import { Loading, AlertMessage } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import LinkToJoin from "source/groups/my/groups/add_person/LinkToJoin"
import QRCodeWithImage from "source/shared/QRCodeWithImage"
import { Heading } from "@planningcenter/doxy-web"

QRContainer.propTypes = {
  visible: bool.isRequired,
  group_id: string.isRequired,
}

export default function QRContainer({ visible, group_id }) {
  if (visible) {
    return (
      <Suspense fallback={<Loading />}>
        <Loaded group_id={group_id} />
      </Suspense>
    )
  } else {
    return null
  }
}

Loaded.propTypes = {
  group_id: string.isRequired,
}

function Loaded({ group_id }) {
  const [groupApplicationUrl, setGroupApplicationUrl] = useState(undefined)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    sessionApiClient
      .get(`/groups/v2/me/groups/${group_id}/application_url`)
      .then((payload) => {
        setGroupApplicationUrl(dig(payload, "data.attributes.url", {}))
      })
      .catch((response) => {
        setError(dig(response.errors[0], "detail", {}))
      })
  }, [group_id])

  const Error = () => {
    return (
      <AlertMessage>
        Your unique invite link could not be generated. {error} - Please try
        again.
      </AlertMessage>
    )
  }

  if (error) {
    return <Error />
  } else if (groupApplicationUrl) {
    return (
      <div className="action-drawer mb-2">
        <div className="d-f ai-c ai-fs@sm fd-c fd-r@sm">
          <div
            style={{ maxWidth: 260 }}
            className="d-f fd-c ai-c ai-fs@sm ta-c ta-l@sm"
          >
            <Heading level={2} size={4} color="tint2" text="Scan to join" />
            <p className="mb-1 fs-14 c-tint2 mt-4p">
              Have your new member scan the QR code, or you can send them the
              URL.
            </p>
            <LinkToJoin url={groupApplicationUrl} />
          </div>
          <div className="mt-3 mt-0@sm ml-2@sm d-f f-1 jc-fe@sm">
            <QRCodeWithImage
              url={groupApplicationUrl}
              imageSrc="/assets/groups-logo-bordered-x2.jpg"
            />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}
