import { number, object, oneOfType, shape, string } from "prop-types"

export {
  any,
  array,
  arrayOf,
  bigint,
  bool,
  element,
  elementType,
  exact,
  func,
  instanceOf,
  node,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
  symbol,
} from "prop-types"

export const id = oneOfType([number, string])

export const apiResource = shape({
  type: string.isRequired,
  id: id.isRequired,
  attributes: object.isRequired,
  relationships: object,
  links: object.isRequired,
})
