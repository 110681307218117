import { useCallback, useMemo } from "react"
import moment from "moment"
import { useEvents } from "./useEvents"
import { useMembershipAttendances } from "./useMembershipAttendances"
import { useGroupAttendances } from "./useGroupAttendances"
import {
  maxEventCount,
  membersPerPage,
} from "source/groups/my/groups/attendances/config"

export const useGroupAttendanceSummary = ({ groupId, filterBy, orderBy }) => {
  const events = useEvents({
    groupId,
    order: "-starts_at",
    filter: "past",
    limit: maxEventCount,
  })
  const eventIds = useMemo(() => events.map((event) => event.id), [events])
  const filter = useMemo(() => convertFilterToDate(filterBy), [filterBy])
  const {
    membershipAttendances,
    included: includedPeople,
    hasMore,
    loadingMore,
    loadMore,
  } = useMembershipAttendances({
    groupId,
    filter,
    order: orderBy,
    limit: membersPerPage,
  })
  const personIds = useMemo(
    () => personIdsFromMembershipAttendances(membershipAttendances),
    [membershipAttendances],
  )
  const { data: attendancesData } = useGroupAttendances({
    groupId,
    eventIds,
    personIds,
  })

  const filteredEvents = useMemo(() => {
    switch (filter?.type) {
      case "date":
        return events.filter(
          (event) => event.attributes.starts_at >= filter.after,
        )
      case "event":
        return events.slice(0, filter.count)
      default:
        return events
    }
  }, [events, filter])

  const findAttendances = useCallback(
    ({ personId }) => {
      return filteredEvents.map((event) => {
        const attendance = attendancesData.find(
          (att) =>
            att.type === "Attendance" &&
            att.relationships.event.data.id === event.id &&
            att.relationships.person.data.id === personId,
        )
        const pair = { event, attendance }
        return pair
      })
    },
    [filteredEvents, attendancesData],
  )

  const findPerson = useCallback(
    ({ personId }) => {
      return includedPeople.find(
        (person) => person.type === "Person" && person.id === personId,
      )
    },
    [includedPeople],
  )

  const hydratedMembershipAttendances = useMemo(() => {
    return membershipAttendances.map((ar) => {
      const personId = ar.relationships.person.data.id
      const personAttendances = findAttendances({ personId })
      const person = findPerson({ personId })

      return {
        ...ar,
        person,
        attendances: personAttendances,
        attendanceRate: {
          attended: ar.attributes.attended_count,
          outOf: ar.attributes.attendable_count,
          percentage: ar.attributes.attended_percentage,
        },
      }
    })
  }, [membershipAttendances, findAttendances, findPerson])

  return {
    memberships: hydratedMembershipAttendances,
    hasMore,
    loadingMore,
    loadMore,
  }
}

const convertFilterToDate = (filterBy) => {
  switch (filterBy) {
    case "latest_events":
      return { type: "event", count: maxEventCount }
    case "last_1_month":
      return {
        type: "date",
        after: moment().subtract(1, "months").format("YYYY-MM-DD"),
      }
    case "last_3_months":
      return {
        type: "date",
        after: moment().subtract(3, "months").format("YYYY-MM-DD"),
      }
    case "last_6_months":
      return {
        type: "date",
        after: moment().subtract(6, "months").format("YYYY-MM-DD"),
      }
    case "all":
      return undefined
  }
}

const personIdsFromMembershipAttendances = (membershipAttendances) =>
  membershipAttendances.map(
    (membership) => membership.relationships.person.data.id,
  )
