import Question from "source/registrations/questions/Question"
import { object, func, bool } from "prop-types"
import { useAnswers } from "source/registrations/hooks/useAnswers"
import { Heading } from "@planningcenter/doxy-web"
import { AlertMessage } from "source/shared/components"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import useUnsavedChangesAlert from "source/shared/hooks/useUnsavedChangesAlert"

const NamedAttendeeQuestions = forwardRef(
  ({ attendeeSummary, registration, isEditing, onChange, onSave }, ref) => {
    const attendeeAnswers = registration.attendees.filter((at) => {
      return at.id == attendeeSummary.id
    })[0].answers

    const { answers, questions, handleChange, handleSave, handleReset, error } =
      useAnswers({
        ...registration,
        questions: attendeeSummary.questions,
        attendeeId: attendeeSummary.id,
        answers: attendeeAnswers,
      })
    const [changes, setChanges] = useState(false)

    // Because the handleChange from the useAnswers hook automatically filters
    // out empty answers that are not saved, we need to check if we have changes
    // after the answers have been updated.
    useEffect(() => {
      const hasChanges = Object.values(answers).some(
        (answer) => answer.isModified,
      )
      setChanges(hasChanges)
    }, [answers])

    useUnsavedChangesAlert(changes)

    const attendeeQuestions = questions.filter(
      (q) => q.questionGroup == "attendee",
    )

    const handleSaveClick = useCallback(() => {
      if (!changes) {
        Object.keys(answers).map((k) => {
          !answers[k].isModified && onSave({ [k]: true })
        })
      } else {
        const response = handleSave()
        !response.errors && setChanges(false)
        Object.keys(answers).map((k) => {
          answers[k].isModified && onSave({ [k]: true })
        })
      }
    }, [answers, changes, onSave, handleSave])

    useImperativeHandle(ref, () => {
      return {
        save() {
          handleSaveClick()
        },
      }
    }, [handleSaveClick])

    const handleChangeMade = (questionId, value) => {
      // Given that someone can set and then delete an unsaved answer to a
      // question, and the handleChange funtion from the useAnswers hook
      // automatically filters out those empty answers, we need to check if we
      // have anything to save with each change.
      const hasChanged = !(
        typeof answers[questionId]?.id === "undefined" && value.length === 0
      )
      handleChange(questionId, value, attendeeSummary.id)
      onChange({ [questionId]: hasChanged })
    }

    useEffect(() => {
      if (changes && !isEditing) {
        setChanges(false)
        handleReset()
      }
    }, [isEditing, changes, handleReset])

    return attendeeQuestions.length == 0 ? null : (
      <>
        <div className="mt-3 mb-1">
          <Heading level={3} size={4} text="Attendee Questions" />
        </div>
        {error && (
          <AlertMessage type="error" icon="general#exclamation-triangle">
            {error}
          </AlertMessage>
        )}
        {questions.map((question) => (
          <Question
            key={question.id}
            question={question}
            answers={answers}
            onChange={handleChangeMade}
            isEditing={isEditing}
            removeContainer={true}
          />
        ))}
      </>
    )
  },
)

NamedAttendeeQuestions.propTypes = {
  attendeeSummary: object,
  registration: object,
  isEditing: bool,
  isSaving: bool,
  onChange: func,
  onSave: func,
}

NamedAttendeeQuestions.displayName = "NamedAttendeeQuestions"

export default NamedAttendeeQuestions
