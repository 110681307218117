import { number, string } from "prop-types"
import { datetime } from "@churchcenter/datetime-fmt"
import { Link } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"

PendingGroupsEvent.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  starts_at: string.isRequired,
  ends_at: string.isRequired,
  group_slug: string.isRequired,
  group_name: string.isRequired,
}

export default function PendingGroupsEvent({
  id,
  name,
  starts_at,
  ends_at,
  group_slug,
  group_name,
}) {
  const myGroupEventPath = `/my/groups/${group_slug}/events/${id}`
  const formattedTimes = datetime(starts_at, ends_at, { showTimeZone: false })

  return (
    <div className="action-drawer d-f fd-c">
      <div className="f-1">
        <Link to={myGroupEventPath}>
          <div className="mb-1">
            <Heading level={4} color="brand" text={formattedTimes} />
          </div>
        </Link>
        <div>{group_name}</div>
        <div>{name}</div>
      </div>

      <div className="action-drawer__footer d-f fd-r jc-fe">
        <Link to={myGroupEventPath} className="btn minor-btn">
          RSVP
        </Link>
      </div>
    </div>
  )
}
