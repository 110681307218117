import { string, shape, func, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const PhoneField = ({ attendee, onChange, error, value, ...rest }) => {
  const { id: attendeeId } = attendee

  return (
    <Field label="Phone" value={value} error={error} {...rest}>
      <TextInput
        id={`phoneNumber-${attendeeId}`}
        value={value || ""}
        onChange={({ target: { value } }) => onChange(value)}
        size="sm"
        placeholder="Phone number"
      />
    </Field>
  )
}

PhoneField.propTypes = {
  attendee: shape({
    id: oneOfType([string, number]),
  }),
  onChange: func,
  error: string,
  value: string,
}

export default PhoneField
