import { object } from "prop-types"

const Help = ({ registration }) => {
  const {
    event: { contact, allowRegistrantsToCancel },
    cancelRegistrationUrl,
    canceledOn,
  } = registration

  if (!contact) {
    return null
  }

  return (
    <div className="action-drawer">
      <strong>Need Help? </strong>
      {allowRegistrantsToCancel && !canceledOn ? (
        <>
          You can <a href={cancelRegistrationUrl}>cancel online</a> or contact{" "}
        </>
      ) : (
        "Contact "
      )}

      <a href={`mailto:${contact.primaryEmailAddress}`}>{contact.name}</a>
    </div>
  )
}

Help.propTypes = {
  registration: object,
}

export default Help
