import PropTypes from "prop-types"
import { Fragment } from "react"
import { Icon } from "source/shared/components"
import { maxEventCount } from "source/groups/my/groups/attendances/config"
import type { CSSProperties } from "react"

type AttendanceState =
  | "attended"
  | "missed"
  | "cancelled"
  | "not_member"
  | "unrecorded_event"
  | "loading"
export interface AttendanceReportPillState {
  state: AttendanceState
  label: string
}

/**
 * In this view, we show 8 pills (the last 8 event attendances) for each member.
 * If the member has joined more recently, we show a continuous line instead of pills
 * taking up the space of the events when they were not yet members.
 */
export const Pills = ({ states }: { states: AttendanceReportPillState[] }) => {
  const paddingStatesNeeded =
    maxEventCount - Math.min(maxEventCount, states.length)
  const paddingStates: AttendanceReportPillState[] = new Array(
    paddingStatesNeeded,
  ).fill({
    state: "not_member",
    label: "0",
  })
  const statesWithPadding = [...states, ...paddingStates]

  return (
    <div className="d-f fd-rr ai-c o-a">
      {statesWithPadding.map((state, index) => (
        <Fragment key={index}>
          <PillGap
            state={state.state}
            previousState={statesWithPadding[index - 1]?.state}
            index={index}
          />
          <Pill state={state.state} label={state.label} />
        </Fragment>
      ))}
    </div>
  )
}

interface AttendanceReportPillProps extends AttendanceReportPillState {
  style?: CSSProperties
}

export const Pill = ({
  state,
  label,
  style = {},
}: AttendanceReportPillProps) => {
  const noDate = label === "0" || label === ""
  const display = noDate ? "d-n" : "d-b"

  return (
    <div
      className="d-f fd-c ai-c g-1"
      style={{ ...styles.pillContainer, ...style }}
    >
      {state === "not_member" && <div style={styles.line} />}
      <div
        className="d-f h-3 w-3 br-4 o-h ai-c jc-c"
        style={{
          ...(state === "attended" ? styles.attended : {}),
          ...(state === "missed" ? styles.missed : {}),
          ...(state === "unrecorded_event" ? styles.unrecorded : {}),
          ...(state === "loading" ? styles.loading : {}),
        }}
      >
        {state === "unrecorded_event" && (
          <SharedIcon
            symbol="churchCenter#question"
            style={{ color: "var(--color-tint2)" }}
          />
        )}
        {state === "cancelled" && <CancelledEventPattern />}
        {state === "attended" && (
          <SharedIcon symbol="general#check" style={{ color: "#25812B" }} />
        )}
        {state === "missed" && (
          <SharedIcon symbol="general#x" style={{ color: "#C82120" }} />
        )}
      </div>
      <span className={`fs-5 c-tint2 ${display}`}>{label}</span>
    </div>
  )
}

const PillGap = ({
  state,
  previousState,
  index,
}: {
  state: AttendanceState
  previousState: AttendanceState
  index: number
}) => {
  // We don't need a gap before the first pill
  if (index === 0) return null

  // Hides the gap-line between the "non-member" line and the first event pill
  // where the person attended as a group member.
  const hideLineBeforeAttendedEvent =
    state === "not_member" && previousState !== "not_member" ? { width: 0 } : {}

  return (
    <div
      className="d-f f-1"
      style={{ ...styles.pillContainer, minWidth: "1rem" }}
    >
      {state === "not_member" && (
        <div style={{ ...styles.line, ...hideLineBeforeAttendedEvent }} />
      )}
    </div>
  )
}

PillGap.propTypes = {
  state: PropTypes.string.isRequired,
  previousState: PropTypes.string,
  index: PropTypes.number.isRequired,
}

const SharedIcon = ({
  ...restProps
}: {
  symbol: string
  style?: CSSProperties
}) => <Icon className="fs-6" {...restProps} />

const CancelledEventPattern = () => {
  const color = "var(--color-tint3)"

  return (
    <svg height="100%" width="100%">
      <pattern id="stripe" width="5" height="5" patternUnits="userSpaceOnUse">
        <line x1="-1" y1="1" x2="1" y2="-1" stroke={color} strokeWidth="1" />
        <line x1="0" y1="5" x2="5" y2="0" stroke={color} strokeWidth="1" />
        <line x1="4" y1="6" x2="6" y2="4" stroke={color} strokeWidth="1" />
      </pattern>
      <rect width="100%" height="100%" fill="url(#stripe)" />
    </svg>
  )
}

const styles: Record<string, CSSProperties> = {
  pillContainer: {
    height: 50,
    minWidth: 35,
    position: "relative",
  },
  line: {
    position: "absolute",
    top: 10,
    width: "100%",
    height: 2,
    backgroundColor: "var(--color-tint6)",
  },
  attended: {
    backgroundColor: "#F1F8F1",
  },
  missed: {
    backgroundColor: "#FDEDED",
  },
  unrecorded: {
    backgroundColor: "var(--color-tint7)",
  },
  loading: {
    backgroundColor: "var(--color-tint7)",
  },
}

export const attendanceReportStatePropType = PropTypes.shape({
  state: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
})
