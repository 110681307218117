import { Fragment } from "react"
import { string } from "prop-types"
import { capitalize } from "lodash"
import { useInfiniteMyRegistrations } from "../hooks/useMyRegistrations"

import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  LimitedInfiniteScroll,
} from "source/shared/components"
import Registration from "./Registration"

const PaginatedRegistrations = ({ timeframe }) => {
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteMyRegistrations({ timeframe })

  return (
    <>
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl="/me" pageText="Me" />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl="/registrations/profile"
          pageText="My registrations"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText={capitalize(timeframe)} isActive />
      </BreadcrumbNavigation>

      <div className="container container--narrow">
        <section>
          <h2 className="h3 mb-2">{capitalize(timeframe)}</h2>
          <LimitedInfiniteScroll
            loadMoreButtonText="View more"
            keepLoadingOnScroll={false}
            hasMore={hasNextPage}
            loadMore={fetchNextPage}
            loadingMore={isFetching || isFetchingNextPage}
          >
            {data.pages.map((page, page_num) => (
              <Fragment key={page_num}>
                {page.data.map((registration) => (
                  <Registration
                    key={registration.id}
                    registration={registration}
                  />
                ))}
              </Fragment>
            ))}
          </LimitedInfiniteScroll>
        </section>
      </div>
    </>
  )
}

PaginatedRegistrations.propTypes = {
  timeframe: string.isRequired,
}

export default PaginatedRegistrations
