import PropTypes from "prop-types"
import { Date } from "source/shared/DatetimeFmt"
import { Heading } from "@planningcenter/doxy-web"
import { Link } from "react-router-dom"
import { Avatar, Icon } from "source/shared/components"
import { Pills, attendanceReportStatePropType } from "./Pill"
import { css } from "@emotion/react"

export const LIST_SPACE = "1.5rem"

export const MemberAttendanceCard = ({
  name,
  avatarUrl,
  joinedAt,
  attendanceStates,
  attendanceRate,
  memberId,
  basePath,
}) => {
  const nameStyles = css`
    font-size: 1rem;
    margin-right: 0.25rem;

    @media (min-width: 601px) {
      margin-right: 0.5rem;
      font-size: 1.125rem;
    }
  `
  return (
    <li
      className="d-f fd-c g-2"
      style={{
        borderBottom: "solid 1px var(--color-tint7)",
        paddingBottom: LIST_SPACE,
      }}
    >
      <div className="d-f fd-r gap-1">
        <Link
          to={`${basePath}/members/${memberId}`}
          className="d-f fd-r f-1 jc-sb ai-c g-1 g-2@sm"
        >
          <div className="h-5 h-6@sm h-5 w-6@sm">
            <Avatar url={avatarUrl} />
          </div>
          <div className="f-1">
            <h2>
              <span css={nameStyles}>{name}</span>
              <span className="c-tint3 fs-5">
                <Icon symbol="general#right-chevron" aria-hidden />
              </span>
            </h2>
            {joinedAt && (
              <span className="fs-4 c-tint2">
                Joined <Date start={joinedAt} style="standard" year={true} />
              </span>
            )}
          </div>
        </Link>
        <div className="d-f fd-c ai-fe">
          <Heading level={3} size={1} text={`${attendanceRate.percentage}%`} />
          <div className="fs-5 c-tint2">
            {attendanceRate.attended} of {attendanceRate.outOf} events
          </div>
        </div>
      </div>
      <Pills states={attendanceStates} />
    </li>
  )
}

MemberAttendanceCard.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  joinedAt: PropTypes.string,
  attendanceStates: PropTypes.arrayOf(attendanceReportStatePropType),
  attendanceRate: PropTypes.shape({
    attended: PropTypes.number.isRequired,
    outOf: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
  memberId: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
}
