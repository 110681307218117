import { shape, string, array } from "prop-types"
import { Date } from "source/shared/DatetimeFmt"
import { Heading } from "@planningcenter/doxy-web"

AddressField.propTypes = {
  field: shape({
    attributes: shape({
      street: string,
      city: string,
      state: string,
      zip: string,
    }),
  }).isRequired,
}

function AddressField({ field }) {
  return (
    <div className="d-f lh-1.25 g-4p" title="Address">
      <div className="c-tint2 fsh-0 w-8">{field.attributes.location}</div>
      <div className="d-f fd-c fw-400 c-tint0 g-4p">
        <div>{field.attributes.street}</div>
        <div>
          {field.attributes.city}, {field.attributes.state}{" "}
          {field.attributes.zip}
        </div>
        {!!field.attributes.country_name && (
          <div>{field.attributes.country_name}</div>
        )}
      </div>
    </div>
  )
}

AddressFields.propTypes = {
  addresses: array.isRequired,
}
export function AddressFields({ addresses }) {
  if (!addresses.length) return null
  return (
    <>
      <div className="pt-2 pb-1">
        <Heading level={4} size={5} className="fs-4 mb-1 mt-2" text="ADDRESS" />
      </div>
      <div className="d-f fd-c g-1">
        {addresses.map((field) => (
          <AddressField field={field} key={field.id} />
        ))}
      </div>
    </>
  )
}

EmailField.propTypes = {
  field: shape({ attributes: shape({ address: string }) }).isRequired,
}

function EmailField({ field }) {
  return (
    <div className="d-f g-4p" title="Email address">
      <div className="c-tint2 fsh-0 w-8">{field.attributes.location}</div>
      <a
        className="truncate f-1 c-tint0"
        href={`mailto:${field.attributes.address}`}
      >
        {field.attributes.address}
      </a>
    </div>
  )
}

export function EmailFields({ emails }) {
  if (!emails.length) return null
  return (
    <>
      <div className="pt-2 pb-1">
        <Heading level={4} size={5} className="fs-4 mb-1 mt-2" text="EMAIL" />
      </div>
      <div className="d-f fd-c g-2p">
        {emails.map((field) => (
          <EmailField field={field} key={field.id} />
        ))}
      </div>
    </>
  )
}
EmailFields.propTypes = {
  emails: array.isRequired,
}

PhoneNumberField.propTypes = {
  field: shape({ attributes: shape({ number: string }) }).isRequired,
}

function PhoneNumberField({ field }) {
  return (
    <div className="d-f g-4p" title="Phone number">
      <div className="c-tint2 fsh-0 w-8">{field.attributes.location}</div>
      <a className="f-1 c-tint0" href={`tel:${field.attributes.number}`}>
        {field.attributes.number}
      </a>
    </div>
  )
}

export function PhoneNumberFields({ phoneNumbers }) {
  if (!phoneNumbers.length) return null
  return (
    <>
      <div className="pt-2 pb-1">
        <Heading level={4} size={5} className="fs-4 mb-1 mt-2" text="PHONE" />
      </div>
      <div className="d-f fd-c g-2p">
        {phoneNumbers.map((field) => (
          <PhoneNumberField field={field} key={field.id} />
        ))}
      </div>
    </>
  )
}
PhoneNumberFields.propTypes = {
  phoneNumbers: array.isRequired,
}

BirthdayField.propTypes = {
  birthday: string,
}

function BirthdayField({ birthday }) {
  if (birthday && birthday.length) {
    return (
      <div className="d-f g-4p mb-4p">
        <div className="c-tint2 fsh-0 w-8">Birthday</div>
        <div className="c-tint0">
          <Date start={birthday} style="standard" />
        </div>
      </div>
    )
  } else {
    return null
  }
}

AnniversaryField.propTypes = {
  anniversary: string,
}

function AnniversaryField({ anniversary }) {
  if (anniversary && anniversary.length) {
    return (
      <div className="d-f g-4p">
        <div className="c-tint2 fsh-0 w-8">Anniv.</div>
        <div className="c-tint0">
          <Date start={anniversary} style="standard" />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export function PersonalInfoFields({ birthday, anniversary }) {
  if (!birthday && !anniversary) return null
  return (
    <>
      <div className="pt-2 pb-1">
        <Heading
          level={4}
          size={5}
          className="fs-4 mb-1 mt-2"
          text="PERSONAL INFORMATION"
        />
      </div>
      <BirthdayField birthday={birthday} />
      <AnniversaryField anniversary={anniversary} />
    </>
  )
}
PersonalInfoFields.propTypes = {
  birthday: string,
  anniversary: string,
}
