import ReactDatePicker from "react-datepicker"

export function TimeInput(props) {
  return (
    <ReactDatePicker
      placeholder="Select a time"
      wrapperClassName="w-100%"
      showPopperArrow={false}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="h:mmaaa"
      {...props}
    />
  )
}
