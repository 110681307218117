import { useContext } from "react"
import { bool, func, node } from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import {
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  Icon,
} from "source/shared/components"
import {
  Menu,
  MenuButton,
  MenuList,
  MenuLink,
  MenuItem,
} from "@reach/menu-button"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "source/shared/components/Modal"
import { LeaveGroupModalContent } from "source/groups/components/LeaveGroupModalContent"
import { emailNewPath } from "source/groups/my/groups/members/email/new"

export default function MyGroupBreadcrumbs({ children, showActions }) {
  const navigate = useNavigate()
  const group = useContext(CurrentGroupContext)
  const published = group.enrollment_status !== "private"

  return (
    <BreadcrumbNavigationWithMe>
      <BreadcrumbPage linkUrl="/groups/profile" pageText="My groups" />
      <BreadcrumbDividerIcon />
      <BreadcrumbPage
        linkUrl={group.base_path}
        pageText={group.name}
        isActive
      />
      {children}
      {showActions && (
        <Modal>
          <Menu>
            <MenuButton
              style={{ marginLeft: "auto" }}
              className="minor-btn secondary-btn btn"
            >
              Actions
              <span className="fs-6 pl-4p dropdown-trigger__icon">
                <Icon symbol="general#down-chevron" aria-hidden />
              </span>
            </MenuButton>
            <MenuList>
              {group.abilities.email_messages.can_create && (
                <MenuLink
                  as={Link}
                  to={emailNewPath()}
                  state={{ prevPath: window.location.pathname }}
                >
                  Email group
                </MenuLink>
              )}
              {published && (
                <MenuLink href={group.church_center_web_url}>
                  View public group page
                </MenuLink>
              )}
              {group.abilities.can_manage && (
                <MenuLink href={group.planning_center_url}>
                  Manage on Planning Center
                </MenuLink>
              )}
              <Modal.Trigger as={MenuItemTrigger} className="dropdown__item">
                Leave group…
              </Modal.Trigger>
            </MenuList>
          </Menu>
          <LeaveGroupModalContent
            groupId={group.id}
            groupName={group.name}
            afterSubmit={() => navigate("/groups/profile")}
          />
        </Modal>
      )}
    </BreadcrumbNavigationWithMe>
  )
}
MyGroupBreadcrumbs.propTypes = {
  children: node,
  showActions: bool.isRequired,
}

function MenuItemTrigger({ onClick, ...rest }) {
  return <MenuItem onSelect={onClick} {...rest} />
}
MenuItemTrigger.propTypes = {
  onClick: func.isRequired,
}
