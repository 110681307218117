import { useState } from "react"
import { object } from "prop-types"
import Question from "source/registrations/questions/Question"
import { useAnswers } from "source/registrations/hooks/useAnswers"
import spinner from "source/svg/spinner.svg"
import { AlertMessage } from "source/shared/components"
import { Heading, Button } from "@planningcenter/doxy-web"
import { useSession } from "source/shared/hooks/useSession"

const RegistrantQuestions = ({ registration }) => {
  const {
    data: { id: currentPersonId },
  } = useSession()
  const { questions: allQuestions, responsiblePersonId } = registration
  const registrantQuestions = allQuestions.filter(
    (question) => question.questionGroup == "registrant",
  )
  const {
    answers,
    questions,
    handleChange,
    handleSave,
    handleReset,
    error,
    isLoading,
  } = useAnswers({ ...registration, questions: registrantQuestions })

  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const canEdit = currentPersonId == responsiblePersonId

  const handleCancel = () => {
    handleReset()
    setIsEditing(false)
  }

  const handleAnswerSave = async () => {
    setIsSaving(true)
    const response = await handleSave()
    setIsSaving(false)
    !response.errors && setIsEditing(false)
  }

  const canSave =
    !isLoading && Object.values(answers).some((answer) => answer.isModified)

  const requiredQuestionIds = registration.questions
    .filter((question) => question.required)
    .map((question) => question.id)

  const answeredQuestionIds = registration.answers.map(
    (answer) => answer.questionId,
  )

  const allQuestionsAnswered = requiredQuestionIds.every((questionId) =>
    answeredQuestionIds.includes(questionId),
  )

  if (questions.length <= 0) {
    return null
  }

  return (
    <div className="mt-3">
      <div className="d-f ai-c jc-sb mb-2 g-2">
        <div className="d-f@sm ai-c g-1">
          <Heading level={2} size={3} text="Registration Questions" />

          {!allQuestionsAnswered && !registration.isDetailed && (
            <AlertMessage
              className="sm-alert mb-0"
              type="warning"
              icon="general#exclamation-triangle"
            >
              Incomplete
            </AlertMessage>
          )}
        </div>
        {isLoading && !isEditing && (
          <img
            src={spinner}
            alt="Additional information loading"
            style={{ width: "1em", height: "1em" }}
            className="d-b mt-1p"
          />
        )}
        {!isLoading && !isEditing && canEdit && (
          <>
            <button
              className="minor-btn secondary-btn btn"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          </>
        )}
        {isEditing && (
          <div className="d-f ai-c g-1">
            <Button
              onClick={handleCancel}
              text="Cancel"
              size="sm"
              variant="naked"
            />
            <Button
              onClick={handleAnswerSave}
              text="Save"
              size="sm"
              disabled={!canSave}
              isLoading={isSaving}
            />
          </div>
        )}
      </div>

      {error && (
        <AlertMessage type="error" icon="general#exclamation-triangle">
          {error}
        </AlertMessage>
      )}

      {questions.map((question) => {
        if (
          registration.isDetailed &&
          question.questionGroup !== "registrant"
        ) {
          return
        }

        return (
          <Question
            key={question.id}
            question={question}
            answers={answers}
            onChange={handleChange}
            isEditing={isEditing}
          />
        )
      })}
    </div>
  )
}

RegistrantQuestions.propTypes = {
  registration: object,
}

export default RegistrantQuestions
