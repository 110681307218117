import { useContext } from "react"
import {
  arrayOf,
  number,
  object,
  oneOfType,
  node,
  shape,
  string,
} from "prop-types"
import { colors } from "source/shared/colors"
import { Icon } from "source/shared/components"
import Map from "source/shared/components/google_maps/map"
import Marker from "source/shared/components/google_maps/marker"
import Circle from "source/shared/components/google_maps/circle"
import InfoWindow from "source/shared/components/google_maps/info_window"
import { MapContext } from "../contexts/MapContext"

export function MapButton() {
  const { mapIsRevealed, setMapIsRevealed } = useContext(MapContext)

  return (
    <div className="ml-1">
      <button
        onClick={() => setMapIsRevealed((prev) => !prev)}
        type="button"
        style={{
          backgroundColor: colors.tint8,
          borderRadius: "4px",
          border: "0px",
          color: colors.tint2,
          fontSize: "12px",
          fontWeight: 400,
          letterSpacing: "0.025em",
          padding: "12px",
          textAlign: "left",
          textTransform: "uppercase",
          width: "128px",

          "&:focus": { outline: "none" },
        }}
      >
        <div className="d-f jc-sb ai-c">
          <div className="pr-1">
            <span className="fs-6 mr-4p">
              <Icon symbol="general#location-pin" />
            </span>
            {mapIsRevealed ? "Hide map" : "Show map"}
          </div>
          <div className="fs-3" style={{ marginTop: "-2px" }}>
            {mapIsRevealed ? "–" : "+"}
          </div>
        </div>
      </button>
    </div>
  )
}

export function MapDisplay({ groups, locations }) {
  const { mapIsRevealed } = useContext(MapContext)

  if (!mapIsRevealed) return null

  const bounds = getBounds(locations)
  const centerFunc = bounds.getCenter()
  const center = { lat: centerFunc.lat(), lng: centerFunc.lng() }

  return (
    <Map
      center={center}
      viewport={bounds}
      style={{
        width: "100%",
        height: "220px",
        borderRadius: 4,
        marginBottom: 32,
      }}
      key="exactMap"
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {locations.map((location) => (
        <Location
          key={location.id}
          location={location}
          infoWindow={
            <Info
              groups={groups.filter(
                (g) => location.id === g.relationships.location.data?.id,
              )}
            />
          }
        />
      ))}
    </Map>
  )
}
MapDisplay.propTypes = {
  groups: arrayOf(
    shape({
      relationships: shape({
        location: shape({
          data: object,
        }),
      }),
    }),
  ).isRequired,
  locations: arrayOf(
    shape({
      attributes: shape({
        strategy: string.isRequired,
        latitude: oneOfType([number, string]).isRequired,
        longitude: oneOfType([number, string]).isRequired,
      }),
    }),
  ).isRequired,
}

function Location({ location, infoWindow, ...googleMapProps }) {
  return location.attributes.strategy === "exact" ? (
    <Marker
      {...googleMapProps}
      infoWindow={infoWindow}
      position={{
        lat: parseFloat(location.attributes.latitude),
        lng: parseFloat(location.attributes.longitude),
      }}
    />
  ) : (
    <Circle
      {...googleMapProps}
      infoWindow={infoWindow}
      center={{
        lat: parseFloat(location.attributes.latitude),
        lng: parseFloat(location.attributes.longitude),
      }}
      radius={location.attributes.radius}
    />
  )
}
Location.propTypes = {
  location: shape({
    attributes: shape({
      strategy: string.isRequired,
      latitude: oneOfType([number, string]).isRequired,
      longitude: oneOfType([number, string]).isRequired,
      radius: number.isRequired,
    }),
  }).isRequired,
  infoWindow: node.isRequired,
}

function Info({ groups, ...googleMapProps }) {
  return (
    <InfoWindow {...googleMapProps}>
      {groups.map((group) => (
        <a
          key={group.id}
          href={group.attributes.church_center_web_url}
          className="map-info-window__item"
          style={{
            borderBottom: "1px solid #DFDFDF",
            display: "block",
            padding: "0.5rem 1.25rem 0.5rem 0.75rem",
            position: "relative",
          }}
        >
          <strong>{group.attributes.name}</strong>
        </a>
      ))}
    </InfoWindow>
  )
}
Info.propTypes = {
  groups: arrayOf(
    shape({
      id: string.isRequired,
      attributes: shape({
        church_center_web_url: string.isRequired,
        name: string.isRequired,
      }),
    }),
  ),
}

function getBounds(locations) {
  const bounds = new google.maps.LatLngBounds()

  locations.map(({ attributes: { latitude, longitude } }) => {
    if (latitude && longitude) {
      bounds.extend(new google.maps.LatLng(latitude, longitude))
    }
  })

  return bounds
}
