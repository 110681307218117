import { Helmet } from "react-helmet-async"
import { useSession } from "source/shared/hooks/useSession"
import { Memberships } from "./components/Memberships"
import {
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
} from "source/shared/components"

export default function Entry() {
  useSession(true)

  return (
    <section id="groups_collection">
      <Helmet>
        <title>My groups</title>
      </Helmet>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage
          linkUrl="/groups/profile"
          pageText="My groups"
          isActive
        />
      </BreadcrumbNavigationWithMe>
      <Memberships />
    </section>
  )
}
