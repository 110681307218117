import { useApiRead } from "source/shared/SessionApiResource"
import _ from "lodash"

const SIGNUP_SHEET_FIELDS = [
  "display_times",
  "group_key",
  "position_name",
  "sort_date",
  "sort_index",
  "team_name",
  "title",
  "signup_sheet_metadata",
].join(",")
const SIGNUP_SHEET_METADATA_FIELDS = [
  "conflicts",
  "starts_at",
  "time_name",
  "time_type",
].join(",")
const PER_PAGE = 100
const SIGNUP_SHEETS_PAGE_LIMIT = 4

export default function useSignupSheets({ availableSignupId }) {
  const allData = []
  const allIncluded = []
  let pagesFetched = 0

  let nextLink = `/services/v2/me/available_signups/${availableSignupId}/signup_sheets?per_page=${PER_PAGE}&fields[SignupSheet]=${SIGNUP_SHEET_FIELDS}&fields[SignupSheetMetadata]=${SIGNUP_SHEET_METADATA_FIELDS}&include=signup_sheet_metadata`
  while (nextLink != null && pagesFetched < SIGNUP_SHEETS_PAGE_LIMIT) {
    const { data, included, links } = useApiRead(nextLink)
    allData.push(...data)
    allIncluded.push(...included)
    nextLink = links?.next
    pagesFetched++
  }

  const signupSheets = allData.map((signupSheet) => {
    const metadataIds =
      signupSheet.relationships.signup_sheet_metadata.data.map((t) => t.id)
    const metadataItems = allIncluded
      .filter(
        (incl) =>
          incl.type === "SignupSheetMetadata" && metadataIds.includes(incl.id),
      )
      .map((t) => ({ id: t.id, ...t.attributes }))
    let uniqueConflicts = {}
    metadataItems.forEach((metadataItem) => {
      Object.keys(metadataItem.conflicts).forEach((conflictKey) => {
        if (!Object.keys(uniqueConflicts).includes(conflictKey)) {
          uniqueConflicts[conflictKey] = metadataItem.conflicts[conflictKey]
        }
      })
    })

    const times = metadataItems
      ? metadataItems.map((metadataItem) => {
          return {
            starts_at: metadataItem.starts_at,
            time_name: metadataItem.time_name,
            time_type: metadataItem.time_type,
            conflicts: Object.values(metadataItem.conflicts),
          }
        })
      : []

    const sortedTimes = _.orderBy(times, ["starts_at"], ["asc"])

    return {
      ...signupSheet.attributes,
      id: signupSheet.id,
      conflicts: Object.values(uniqueConflicts),
      times: sortedTimes,
    }
  })

  const sortedSignupSheets = _.orderBy(
    signupSheets,
    ["sort_date", "group_key", "sort_index"],
    ["asc", "asc", "asc"],
  )

  return sortedSignupSheets
}
