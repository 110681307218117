import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { isChurchCenterApp } from "source/Layout"
import { WebBootContext } from "source/publishing/WebBoot"
import { useEnv } from "source/shared/hooks/useEnv"

declare global {
  interface Window {
    ccaVersion: string
  }
}

// TODO: remove enabled flag once the flipper is removed
export function useChurchCenterPageAnalytics(enabled: boolean = false) {
  const env = useEnv()
  const analyticsBaseUrl = getAnalyticsUrlForEnvironment(env)
  const { pathname } = useLocation()
  const { abilities, currentPerson } = useContext(WebBootContext)
  const track_analytics = abilities.publishing?.track_analytics || "none"
  const currentPersonId = currentPerson.id

  useEffect(() => {
    if (!enabled || track_analytics === "none") return

    const isAppWithTracking = window.ccaVersion

    if (isChurchCenterApp() && isAppWithTracking) return

    const analyticsUrl = new URL("/v1/ingest", analyticsBaseUrl)
    analyticsUrl.searchParams.append("h", window.location.hostname)
    analyticsUrl.searchParams.append("p", pathname)
    analyticsUrl.searchParams.append("s", isChurchCenterApp() ? "app" : "web")

    if (document.referrer) {
      analyticsUrl.searchParams.append("r", document.referrer)
    }

    if (currentPersonId !== "anonymous") {
      analyticsUrl.searchParams.append("v", `acid-${currentPersonId}`)
    }

    if (track_analytics === "all" || pathname === "/home") {
      fetch(analyticsUrl.toString(), { method: "POST" })
    }
  }, [analyticsBaseUrl, currentPersonId, enabled, pathname, track_analytics])

  return null
}

function getAnalyticsUrlForEnvironment(env: string) {
  switch (env) {
    case "production":
      return "https://p.ca.churchcenter.net"
    case "staging":
      return "https://s.ca.churchcenter.net"
    case "development":
      return "http://customer-analytics.pco.test"
    default:
      return ""
  }
}
