import { useCallback, useState } from "react"
import { func, object } from "prop-types"
import { Modal, useCloseModal } from "source/shared/components/Modal"
import { sessionApiClient } from "@planningcenter/cc-api-client"

ContactDirectoryMemberModal.propTypes = {
  recipient: object,
  onSuccess: func,
}

export function ContactDirectoryMemberModal({
  recipient,
  onSuccess = () => {},
}) {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [messageBody, setMessageBody] = useState("")

  const clearState = useCallback(() => {
    setSubmitting(false)
    setMessageBody("")
    setErrorMessage("")
  }, [])

  const closeModal = useCloseModal()
  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)

    sessionApiClient
      .post(`/people/v2/directory_people/${recipient.data.id}/deliver_email`, {
        data: { attributes: { message_body: messageBody } },
      })
      .then(() => {
        onSuccess()
        setSubmitting(false)
        closeModal()
      })
      .catch(({ errors }) => {
        setErrorMessage(
          errors[0]?.detail ||
            "An error occurred while sending this message. Please try again.",
        )
        setSubmitting(false)
      })
  }

  return (
    <Modal.Show onOpen={clearState}>
      <Modal.Header>{`Contact ${recipient.data.attributes.first_name}`}</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            maxLength={65535}
            onChange={(e) => setMessageBody(e.target.value)}
            rows={4}
            value={messageBody}
          />
          {errorMessage && (
            <div className="mt-1" style={{ color: "var(--error-alert--text)" }}>
              {errorMessage}
            </div>
          )}
          <Modal.Footer>
            <Modal.Close className="btn text-btn md-btn mr-2">
              Cancel
            </Modal.Close>
            <button
              type={"submit"}
              className={`btn md-btn filled-btn primary-btn ${
                submitting && "loading-btn"
              }`}
              disabled={submitting || !messageBody.length}
              data-pendo="privacy-mode-contact-me-submit"
            >
              {submitting && <span className="spinner" />}
              Send message
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal.Show>
  )
}
