import React, { useEffect, useState } from "react"
import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useDebounce } from "use-debounce"

const trackEmailSuggestionsClick = (original_address, suggested_address) => {
  sessionApiClient.post("/accounts/v2/track_email_replacement_selected_count", {
    data: {
      attributes: {
        origin: "ccw_registration_signup",
        original_address,
        suggested_address,
      },
    },
  })
}

const EmailField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
  rowLayout,
}) => {
  const { emailAddress } = person
  const [suggestedReplacement, setSuggestedReplacement] = useState(undefined)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [debouncedAddress] = useDebounce(emailAddress, 300)

  useEffect(() => {
    if (debouncedAddress === suggestedReplacement) return

    sessionApiClient
      .post("/accounts/v2/suggest_email_replacement", {
        data: { attributes: { email: debouncedAddress } },
      })
      .then(({ data }) => {
        setSuggestedReplacement(data.attributes.suggested_replacement)
        setShowSuggestion(!!data.attributes.suggested_replacement)
      })
      .catch(() => setShowSuggestion(false))
  }, [debouncedAddress, suggestedReplacement])

  useEffect(() => {
    if (showSuggestion && emailAddress === suggestedReplacement) {
      setShowSuggestion(false)
    }
  }, [emailAddress, suggestedReplacement, showSuggestion])

  return (
    <div>
      <Field
        label="Email"
        value={emailAddress}
        persistedValue={existingPerson.emailAddress}
        onCancel={() => onCancel("emailAddress")}
        isRequired={isRequired}
        isOptional={isOptional}
        error={error}
        rowLayout={rowLayout}
      >
        <input
          type="text"
          id={`emailAddress-${id}`}
          value={emailAddress || ""}
          readOnly={!!existingPerson.emailAddress}
          onChange={({ target: { value } }) => {
            onChange("emailAddress", value)
            setShowSuggestion(false)
          }}
          placeholder="Email address"
          autoComplete="on"
          className={`text-input sm-input ${!!existingPerson.emailAddress && "pe-n"}`}
          {...(showSuggestion && {
            style: {
              boxShadow: "none",
              outline: "none",
              borderColor: "var(--color-citrine)",
            },
          })}
        />
      </Field>
      {showSuggestion && (
        <div className="d-f g-2">
          <div style={{ minWidth: "80px" }} />
          <div
            className="fs-4 mt-2p"
            style={{
              color: "var(--warning-alert--text)",
            }}
          >
            Did you mean <strong>{suggestedReplacement}</strong>?{" "}
            <button
              className="btn naked-btn primary-btn fs-5 h-2 td-u:h"
              onClick={() => {
                trackEmailSuggestionsClick(
                  debouncedAddress,
                  suggestedReplacement,
                )
                setShowSuggestion(false)
                onChange("emailAddress", suggestedReplacement)
              }}
              style={{
                padding: 0,
                borderRadius: 0,
                color: "var(--color-tint2)",
              }}
            >
              Replace
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

EmailField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    emailAddress: string,
  }),
  existingPerson: shape({
    emailAddress: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
  rowLayout: bool,
}

export default EmailField
