import { useContext } from "react"
import PropTypes from "prop-types"

import { WebBootContext } from "source/publishing/WebBoot"

FlipperFeatureEnabled.propTypes = {
  name: PropTypes.string.isRequired,
  fallback: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export function useFlipperFeatures() {
  const { features } = useContext(WebBootContext)
  return features
}

export function useFlipperFeatureEnabled(name) {
  const flipperFeatures = useFlipperFeatures()
  return !!flipperFeatures[`${name}`]
}

export function FlipperFeatureEnabled({ name, fallback = null, children }) {
  return useFlipperFeatureEnabled(name) ? children : fallback
}
