import { useApiRead } from "source/shared/SessionApiResource"

export function useNextNotCanceledEvent(group) {
  const response = useApiRead(
    `/groups/v2/me/groups/${
      group.id
    }/events?order=starts_at&filter=upcoming,not_canceled&per_page=1`,
  )
  return response.data.length > 0 ? response.data[0] : null
}
