import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { JoltContext } from "source/shared/contexts/JoltContext"
import { Bugsnag } from "@bugsnag/js"

function useFetchSubscribeTokenFn({ subscriptionTokenPath, prefetchedToken }) {
  return useCallback(
    async (channel, connection_id) => {
      if (prefetchedToken) return prefetchedToken

      if (!connection_id)
        Bugsnag.notify(new Error("Jolt connection_id is missing"))

      const requestBody = { data: { attributes: { channel, connection_id } } }
      const token = await sessionApiClient
        .post(subscriptionTokenPath, requestBody)
        .then((res) => res.data.id)
        .catch((res) =>
          console.error("failed to subscribe to Jolt channel", res),
        )
      return token
    },
    [subscriptionTokenPath, prefetchedToken],
  )
}

function useJoltChannel(fetchSubscribeTokenFn, channel) {
  const { jolt } = useContext(JoltContext)
  const [subscription, setSubscription] = useState()

  useEffect(() => {
    if (!jolt) return

    const sub = jolt.subscribe(channel, { fetchSubscribeTokenFn })
    setSubscription(sub)

    return () => jolt.unsubscribe(channel)
  }, [jolt, channel, fetchSubscribeTokenFn])

  return subscription
}

function useJoltEffect(subscription, events, callbackFn) {
  useEffect(() => {
    events.forEach((event) => subscription?.bind(event, callbackFn))

    return () => {
      events.forEach((event) => subscription?.unbind(event, callbackFn))
    }
  }, [subscription, events, callbackFn])
}

export function useJolt(subscriptionTokenPath, channel, event, callbackFn) {
  const fetchSubscribeTokenFn = useFetchSubscribeTokenFn({
    subscriptionTokenPath,
  })
  const subscription = useJoltChannel(fetchSubscribeTokenFn, channel)
  const events = useMemo(
    () => (Array.isArray(event) ? event : [event]),
    [event],
  )
  useJoltEffect(subscription, events, callbackFn)
}

export function useJoltWithPrefetchedToken(
  prefetchedToken,
  channel,
  event,
  callbackFn,
) {
  const fetchSubscribeTokenFn = useFetchSubscribeTokenFn({ prefetchedToken })
  const subscription = useJoltChannel(fetchSubscribeTokenFn, channel)
  const events = useMemo(
    () => (Array.isArray(event) ? event : [event]),
    [event],
  )
  useJoltEffect(subscription, events, callbackFn)
}
