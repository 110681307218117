import { Suspense, useContext } from "react"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import {
  AttendanceReportFilterBar,
  filterOptions,
  orderOptions,
} from "./AttendanceReportFilterBar"
import { Loading } from "source/shared/components"
import { MemberAttendanceCards } from "./MemberAttendanceCards"
import { Alert } from "@planningcenter/doxy-web"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { Heading } from "@planningcenter/doxy-web"
import BlankState from "source/groups/BlankState"
import { Link } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"

export default function AttendanceIndex() {
  return (
    <TabView>
      <Loaded />
    </TabView>
  )
}

function Loaded() {
  const group = useContext(CurrentGroupContext)
  const groupId = group.id
  const basePath = group.base_path
  const hasAccess = group.abilities?.membership_attendances?.can_read
  const [filterBy, setFilterBy] = useLocalStorage(
    "GroupsAttendanceReportFilterBy",
    filterOptions[0],
  )
  const [orderBy, setOrderBy] = useLocalStorage(
    "GroupsAttendanceReportOrderBy",
    orderOptions[0],
  )
  const attendances = useApiRead(
    `/groups/v2/me/groups/${groupId}/attendances?filter=completed&per_page=1`,
  )
  const hasAttendance = attendances.meta.total_count > 0

  if (!hasAccess) {
    return (
      <Alert
        theme="error"
        text="Only group leaders have access to attendance reports"
      />
    )
  }

  if (!hasAttendance) {
    return (
      <section>
        <BlankState icon="churchCenter#calendar-cancel">
          <Heading
            level={1}
            size={3}
            color="tint2"
            text="Attendance not found"
          />
          <p className="mb-2">
            This group has not submitted attendance yet for any events.
          </p>
          <Link
            to={`${basePath}/events`}
            className="mr-1 minor-btn secondary-btn btn"
          >
            View events
          </Link>
        </BlankState>
      </section>
    )
  }

  return (
    <section>
      <div className="d-f fd-c g-2 mb-3">
        <Heading level={1} size={3} text="Attendance report" />

        <AttendanceReportFilterBar
          filter={filterBy}
          setFilter={setFilterBy}
          order={orderBy}
          setOrder={setOrderBy}
        />
      </div>

      <Suspense fallback={<Loading />}>
        <MemberAttendanceCards
          groupId={groupId}
          filterBy={filterBy.value}
          orderBy={orderBy.value}
          basePath={basePath}
        />
      </Suspense>
    </section>
  )
}
