import { useCallback } from "react"
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
let genericResourceIdentifier = 0
function buildGenericCacheKeyGenerator() {
  const identifier = genericResourceIdentifier++
  return (...args) =>
    `genericResourceCacheKey-${identifier}-` + JSON.stringify(...args)
}

export function createResource(
  resolver,
  cacheKeyGenerator = buildGenericCacheKeyGenerator(),
) {
  return (...args) => [() => resolver(...args), cacheKeyGenerator(...args)]
}

export function useCacheRead(resource, ...resourceArgs) {
  const [queryFn, queryKey] = resource(...resourceArgs)
  const result = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: queryFn,
    refetchOnWindowFocus: false,
    retry: false,
  })

  return result.data
}

export function useCacheHydrate(resource, ...resourceArgs) {
  const queryClient = useQueryClient()
  const [_queryFn, queryKey] = resource(...resourceArgs)

  return useCallback(
    () => queryClient.invalidateQueries({ queryKey: [queryKey] }),
    [queryClient, queryKey],
  )
}
