import PropTypes from "prop-types"
import { Icon } from "source/shared/components"
import moment from "moment"
import datetimefmt from "@churchcenter/datetime-fmt"
import { Link } from "./NotificationShow"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { Heading } from "@planningcenter/doxy-web"

NotificationListItem.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleToggleReadStatus: PropTypes.func.isRequired,
}

export default function NotificationListItem({
  notifications,
  handleToggleReadStatus,
}) {
  const notification = notifications[0]
  const unread = !notification.attributes.read_at
  const payload = notification.payload
  if (!payload) return null
  if (!payload.content) payload.content = JSON.parse(payload.attributes.content)
  if (!payload.alert && payload.content.alert)
    try {
      payload.alert = JSON.parse(payload.content.alert)
    } catch {
      payload.alert = null
    }
  if (!payload.alert) return null
  const { read_at, created_at } = notification.attributes

  const href = payload.content.link || `/notifications/${notification.id}`

  const title = payload.alert.title
  const subtitle = payload.alert.subtitle
  const preview =
    !read_at && notifications.length > 1
      ? `${notifications.length} new replies`
      : payload.alert.body
  const date = created_at
  const symbol = iconSymbolForKind(payload.content.kind)
  const buttonText = read_at ? "Mark as unread" : "Mark as read"

  return (
    <Presentation
      buttonText={buttonText}
      onButtonClick={() => handleToggleReadStatus(notifications)}
      onVisitHref={() => unread && handleToggleReadStatus(notifications)}
      date={date}
      href={href}
      preview={preview}
      subtitle={subtitle}
      symbol={symbol}
      title={title}
    />
  )
}

Presentation.propTypes = {
  buttonText: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.date]),
  href: PropTypes.string.isRequired,
  preview: PropTypes.string,
  subtitle: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  onVisitHref: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

function Presentation({
  buttonText,
  date,
  href,
  preview,
  subtitle,
  onButtonClick,
  onVisitHref,
  symbol,
  title,
}) {
  return (
    <article className="mb-3 d-f">
      <div className="w-6 h-6 mr-2 media-tile">
        <Icon symbol={symbol} aria-hidden />
      </div>
      <div className="f-1 d-f fd-c ai-fs">
        <Link to={href} rel="noopener noreferrer" onClick={onVisitHref}>
          <Heading level={3} color="brand" text={title} />
        </Link>

        {subtitle && <span className="mt-4p mb-4p c-tint2">{subtitle}</span>}
        {preview && <div className="fs-4 c-tint2">{preview}</div>}
      </div>
      <div className="pl-2 pr-3 fs-4">{relativeDate(date)}</div>
      <Menu>
        <MenuButton className="fs-2 c-tint2 as-fs stripped-btn">
          <Icon symbol="churchCenter#three-dots" title="view item actions" />
        </MenuButton>
        <MenuList className="dropdown__menu">
          <MenuItem className="dropdown__item" onSelect={onButtonClick}>
            {buttonText}
          </MenuItem>
        </MenuList>
      </Menu>
    </article>
  )
}

function iconSymbolForKind(kind) {
  switch (kind) {
    case "CALENDAR_BOOKMARKED_EVENT_RESCHEDULED":
      return "churchCenter#calendar-cancel"
    case "CALENDAR_BOOKMARKED_EVENT_UNAVAILABLE":
      return "churchCenter#calendar-cancel"
    case "CHURCH_CENTER_ANNOUNCEMENT":
      return "general#megaphone"
    case "GROUPS_ATTENDANCE_REQUEST":
      return "general#outlined-circle-check"
    case "GROUPS_EVENT_CANCELED":
      return "churchCenter#calendar-cancel"
    case "GROUPS_EVENT_REMINDER":
      return "general#event"
    case "GROUPS_RESOURCE_CREATED":
      return "general#outlined-generic-file"
    case "GROUPS_MEMBERSHIP_CREATED":
      return "general#plus-person"
    case "PEOPLE_NOTIFICATION":
      return "churchCenter#event-circle"
    default:
      return "churchCenter#message-circle"
  }
}

// TODO: Honor organization time formatting in here
function relativeDate(date) {
  const sameYear = moment(date).year() === moment().year()
  const elseFormat = sameYear
    ? `[${datetimefmt.date(date)}]`
    : `[${datetimefmt.date(date)}], YYYY`

  return moment(date).calendar(null, {
    sameDay: `[${datetimefmt.time(date)}]`,
    lastDay: "[Yesterday]",
    lastWeek: "ddd",
    sameElse: elseFormat,
  })
}
