import { bool, number, string, func, object, array, element } from "prop-types"
import { css } from "@emotion/react"
import spinner from "source/svg/spinner.svg"

import { fontSize, size } from "@planningcenter/system"
import { colors } from "source/shared/colors"
import { Icon } from "source/shared/components"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import { Heading } from "@planningcenter/doxy-web"

MiniCalendar.propTypes = {
  date: string.isRequired,
  events: object,
  lastDateLoaded: string,
  loadingEvents: bool,
  selectedDay: object,
  onChangeMonth: func.isRequired,
  onMonthLabelClick: func,
  onSelectDay: func,
  showMiniCal: bool,
}
export default function MiniCalendar({
  date,
  events,
  lastDateLoaded,
  loadingEvents,
  selectedDay,
  onChangeMonth,
  onMonthLabelClick,
  onSelectDay,
  showMiniCal,
}) {
  const momentInTZ = useMomentInTimeZone()
  const currentDate = momentInTZ().startOf("month")
  const viewingDate = momentInTZ(date).startOf("month")

  const canNavigateToPreviousMonth = viewingDate.isAfter(currentDate)
  const canNavigateToNextMonth =
    momentInTZ(viewingDate).diff(currentDate, "years") < 2

  const loadedEventsForThisMonth = momentInTZ(lastDateLoaded).isAfter(
    viewingDate,
    "month",
  )

  const firstDayOfMonth = momentInTZ(viewingDate).startOf("month")
  const lastDayOfMonth = momentInTZ(viewingDate).endOf("month")

  const makeWeeksArray = () => {
    const firstVisibleDay = momentInTZ(firstDayOfMonth).startOf("week")
    const lastVisibleDay = momentInTZ(lastDayOfMonth).endOf("week")
    let weeks = []
    let days = []
    let currentDay = firstVisibleDay.clone()
    let currentWeekNumber = currentDay.week()

    while (currentDay.isSameOrBefore(lastVisibleDay)) {
      days.push(currentDay.clone())
      currentDay.add(1, "d")

      if (currentDay.week() !== currentWeekNumber) {
        // We've reached the end of the week! Add this one to the array and start another one.
        weeks.push(days)
        days = []
        currentWeekNumber = currentDay.week()
      }
    }

    return weeks
  }

  const goToPreviousMonth = () => {
    onChangeMonth(viewingDate.subtract(1, "month"))
  }

  const goToNextMonth = () => {
    onChangeMonth(viewingDate.add(1, "month"))
  }

  const handleMonthLabelClick = () => {
    onMonthLabelClick(viewingDate)
  }

  const showLoadingIndicator = loadingEvents && !loadedEventsForThisMonth
  const weeks = makeWeeksArray()

  return (
    <div
      className="action-drawer"
      css={
        showMiniCal
          ? miniCalendarStyles.container
          : miniCalendarStyles.hideContainer
      }
    >
      <header css={miniCalendarStyles.header}>
        <ChangeMonthButton
          disabled={!canNavigateToPreviousMonth}
          onClick={goToPreviousMonth}
          label="previous month"
        >
          <Icon symbol="general#left-chevron" aria-hidden />
        </ChangeMonthButton>

        <button className="stripped-btn" onClick={handleMonthLabelClick}>
          <Heading level={1} size={3} text={viewingDate.format("MMMM YYYY")} />
        </button>

        <ChangeMonthButton
          disabled={!canNavigateToNextMonth}
          onClick={goToNextMonth}
          label="next month"
        >
          <Icon symbol="general#right-chevron" aria-hidden />
        </ChangeMonthButton>
      </header>
      <div className="p-r" css={miniCalendarStyles.calendarContainer}>
        <LoadingEvents show={showLoadingIndicator} />
        <table
          css={[
            miniCalendarStyles.calendar,
            showLoadingIndicator && { filter: "blur(2px)" },
          ]}
        >
          <thead>
            <tr>
              <th className="c-tint3 fs-4 fw-600 lh-20p">S</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">M</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">T</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">W</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">T</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">F</th>
              <th className="c-tint0 fs-4 fw-600 lh-20p">S</th>
            </tr>
          </thead>
          <tbody valign="top">
            {weeks.map((week, ix) => {
              return (
                <CalendarWeek
                  key={ix}
                  {...{
                    week,
                    events,
                    firstDayOfMonth,
                    lastDayOfMonth,
                    selectedDay,
                    onSelectDay,
                  }}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

ChangeMonthButton.propTypes = {
  onClick: func,
  disabled: bool,
  children: element,
  label: string,
}
function ChangeMonthButton({ onClick, disabled, children, label }) {
  return (
    <button
      className="text-btn"
      css={[
        miniCalendarStyles.monthPrevNext,
        disabled && miniCalendarStyles.disabledMonthPrevNext,
      ]}
      aria-label={label}
      {...{ disabled, onClick }}
    >
      {children}
    </button>
  )
}

CalendarWeek.propTypes = {
  week: array,
  events: object,
  firstDayOfMonth: object,
  lastDayOfMonth: object,
  onSelectDay: func,
  selectedDay: object,
}
function CalendarWeek({
  week,
  events,
  firstDayOfMonth,
  lastDayOfMonth,
  onSelectDay,
  selectedDay,
}) {
  const momentInTZ = useMomentInTimeZone()
  const today = momentInTZ()

  return (
    <tr>
      {week.map((day) => {
        const dayNumber = day.date()
        const isToday = today.isSame(day, "day")
        const dayHasEvent = events.hasOwnProperty(day.toISOString())
        const daySelected = selectedDay && selectedDay.isSame(day, "day")
        const inPastOrFuture =
          firstDayOfMonth.isAfter(day) || lastDayOfMonth.isBefore(day)

        const handleSelectDay = () =>
          onSelectDay && dayHasEvent && onSelectDay(day)

        return (
          <CalendarDay
            key={day.toString()}
            onClick={handleSelectDay}
            {...{
              inPastOrFuture,
              dayHasEvent,
              dayNumber,
              daySelected,
              isToday,
            }}
          />
        )
      })}
    </tr>
  )
}

CalendarDay.propTypes = {
  dayNumber: number.isRequired,
  dayHasEvent: bool,
  isToday: bool,
  inPastOrFuture: bool,
  daySelected: bool,
  onClick: func,
}
function CalendarDay({
  dayNumber,
  dayHasEvent,
  isToday,
  inPastOrFuture,
  daySelected,
  onClick,
}) {
  const styles = {
    todayCell: css`
      color: ${colors.tint0};
      font-weight: 600;
    `,
    selectedCell: css`
      background: ${colors.tint6};
      border-radius: 4px;
    `,
    pastOrFutureCell: css`
      color: ${colors.tint4};
    `,
    eventIndicator: css`
      background: ${colors.emerald};
      border-radius: 100%;
      display: inline-block;
      height: 4px;
      margin-top: 4px;
      width: 4px;
    `,
    linkCell: css`
      cursor: pointer;
    `,
  }

  return (
    <td
      css={[
        daySelected && styles.selectedCell,
        isToday && styles.todayCell,
        inPastOrFuture && styles.pastOrFutureCell,
      ]}
    >
      <div role="button" css={dayHasEvent && styles.linkCell} {...{ onClick }}>
        {dayNumber}
        {dayHasEvent && <span css={styles.eventIndicator} />}
      </div>
    </td>
  )
}

LoadingEvents.propTypes = { show: bool }
function LoadingEvents({ show }) {
  return (
    <div
      className="p-a l-0 r-0 b-0 t-0 w-100% ai-c jc-c"
      css={{ display: show ? "flex" : "none" }}
    >
      <img
        alt="Loading..."
        src={spinner}
        css={{ width: 80, height: 80, opacity: 0.6 }}
      />
    </div>
  )
}

const miniCalendarStyles = {
  hideContainer: css`
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
    padding: ${size(2)}px 0 0;

    @media (min-width: 768px) {
      overflow: visible;
      max-height: none;
    }

    @media (min-width: 960px) {
      padding: ${size(2)}px 0 0;
    }
  `,
  container: css`
    padding: ${size(2)}px 0 0;
    overflow: hidden;
    transition: 0.5s;

    @media (min-width: 960px) {
      padding: ${size(2)}px 0 0;
    }
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${size(1)}px;
    transition: 2s;
  `,
  monthPrevNext: css`
    color: ${colors.tint3};
    display: flex;
    font-size: 12px;
    padding: ${size(1)}px;
  `,
  disabledMonthPrevNext: css`
    color: ${colors.tint4};
    cursor: not-allowed;
  `,
  calendarContainer: css`
    padding: ${size(1)}px;

    @media (min-width: 960px) {
      padding: ${size(2)}px;
    }
  `,
  calendar: css`
    color: ${colors.tint2};
    border-collapse: collapse;
    cellpadding: 0;
    cellspacing: 0;
    table-layout: fixed;
    width: 100%;

    th {
      padding: ${size(1)}px;
      text-align: center;

      @media (min-width: 720px) and (max-width: 959px) {
        font-size: ${fontSize(1)}px;
        padding: 6px;
      }
    }
    td {
      line-height: 1;
      text-align: center;
    }
    td > div {
      height: 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;
      padding: 12px 8px 0 8px;

      @media (min-width: 720px) and (max-width: 959px) {
        font-size: 13px;
        height: 36px;
        padding: 8px 4px 0 4px;
      }
    }
  `,
}
