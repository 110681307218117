import { bool, string } from "prop-types"
import { capitalize } from "lodash"
import {
  MY_REGISTRATIONS_SUMMARY_COUNT,
  useMyRegistrations,
  useMyRegistrationsTotalCount,
} from "source/registrations/hooks/useMyRegistrations"

import { Link } from "react-router-dom"
import { Loading } from "source/shared/components"
import Registration from "./Registration"

const RegistrationSummaryList = ({ timeframe, mt = false }) => {
  const { isLoading, data: registrations } = useMyRegistrations({ timeframe })
  const totalCount = useMyRegistrationsTotalCount({ timeframe })
  const paginatedLink =
    timeframe === "upcoming"
      ? "/registrations/profile_registrations/future"
      : `/registrations/profile_registrations/${timeframe}`

  return (
    <>
      <section>
        <h2 className={`h3 mb-2${mt ? " mt-4" : ""}`}>
          {capitalize(timeframe)}
        </h2>
        {isLoading ? (
          <Loading />
        ) : registrations.length > 0 ? (
          registrations.map((registration) => (
            <Registration key={registration.id} registration={registration} />
          ))
        ) : (
          <div>
            Looks like you don&apos;t have any registrations for {timeframe}{" "}
            events yet!
          </div>
        )}
      </section>
      {totalCount > MY_REGISTRATIONS_SUMMARY_COUNT && (
        <Link to={paginatedLink}>View all</Link>
      )}
    </>
  )
}

RegistrationSummaryList.propTypes = {
  timeframe: string.isRequired,
  mt: bool,
}

export default RegistrationSummaryList
