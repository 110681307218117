import { Suspense, useContext } from "react"
import PropTypes from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import { Link, useLocation } from "react-router-dom"
import MyGroupBreadcrumbs from "source/groups/my/groups/Breadcrumbs"
import { Loading } from "source/shared/components"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"

export default function TabView({ children, showActions = true }) {
  return (
    <>
      <MyGroupBreadcrumbs showActions={showActions} />
      <div className="d-f@md">
        <MyGroupNavigation />
        <div className="f-1" style={{ minWidth: 0 }}>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </div>
      </div>
    </>
  )
}
TabView.propTypes = {
  children: PropTypes.node.isRequired,
  showActions: PropTypes.bool,
}

function MyGroupNavigation() {
  const group = useContext(CurrentGroupContext)
  const { pathname: currentPath } = useLocation()
  const chatEnabled = group.abilities.conversations.can_read
  const messagingEnabled = !chatEnabled && group.abilities.forum_topics.can_read

  const links = [
    {
      title: "Chat",
      path: `${group.base_path}/conversations`,
      visible: chatEnabled,
    },
    {
      title: "Messaging",
      path: `${group.base_path}/messages`,
      visible: messagingEnabled,
    },
    {
      title: "Events",
      path: `${group.base_path}/events`,
      visible: true,
    },
    {
      title: "Resources",
      path: `${group.base_path}/resources`,
      visible: true,
    },
    {
      title: "Members",
      path: `${group.base_path}/members`,
      visible: true,
    },
  ].filter((l) => l.visible)

  const isPartiallyActiveLink = (link) => currentPath.startsWith(link.path)
  const menuTitle = links.find((l) => isPartiallyActiveLink(l))?.title || "Menu"

  return (
    <>
      <nav className="sub-navigation mr-4 d-n d-b@md">
        {links.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className={`sub-navigation__link ${
              isPartiallyActiveLink(link) ? "is-active-link" : ""
            }`}
          >
            {link.title}
          </Link>
        ))}
      </nav>
      <Menu>
        <MenuButton className="d-n@md mb-2 ta-l select">{menuTitle}</MenuButton>
        <MenuList className="dropdown__menu">
          {links.map((link) => (
            <MenuLink
              key={link.path}
              as={Link}
              to={link.path}
              className="dropdown__item"
            >
              {link.title}
            </MenuLink>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}
