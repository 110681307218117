import { css } from "@emotion/react"
import { useReducer } from "react"
import { useSession } from "source/shared/hooks/useSession"
import {
  DateSelector,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useApiRead } from "source/shared/SessionApiResource"
import { useAllSchools } from "../hooks/useAllSchools"
import {
  SchoolSelect,
  GradeSelect,
} from "source/myChurchCenter/Profile/FormElements/GradeAndSchool"
import { GenderSelect } from "source/myChurchCenter/Profile/GenderSelect"

const GENDER_OPTIONS_PATH = `/people/v2/genders`

const UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME"
const UPDATE_LAST_NAME = "UPDATE_LAST_NAME"
const UPDATE_GRADE = "UPDATE_GRADE"
const UPDATE_SCHOOL = "UPDATE_SCHOOL"
const UPDATE_GENDER = "UPDATE_GENDER"
const UPDATE_BIRTHDATE = "UPDATE_BIRTHDATE"
const UPDATE_MEDICAL_NOTES = "UPDATE_MEDICAL_NOTES"

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      }
    case UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      }
    case UPDATE_MEDICAL_NOTES:
      return {
        ...state,
        medicalNotes: action.payload,
      }
    case UPDATE_GRADE:
      return {
        ...state,
        grade: action.payload,
      }
    case UPDATE_GENDER:
      return {
        ...state,
        gender: action.payload,
      }
    case UPDATE_BIRTHDATE:
      return {
        ...state,
        birthdate: action.payload,
      }
    case UPDATE_SCHOOL:
      return {
        ...state,
        school: action.payload,
        grade: null,
      }
    default:
      return state
  }
}

const initialState = {
  firstName: "",
  lastName: "",
  grade: "",
  school: "",
  gender: "",
  birthdate: "",
  medicalNotes: "",
}

export function NewChildHouseholdMemberForm() {
  const {
    data: { id: currentPersonId },
  } = useSession(false)

  const navigate = useNavigate()
  const { householdId } = useParams()
  const schoolOptions = useAllSchools()
  const genderOptions = useApiRead(GENDER_OPTIONS_PATH)

  const {
    data: {
      attributes: { name: householdName },
    },
  } = useApiRead(
    `/people/v2/people/${currentPersonId}/households/${householdId}?fields[Household]=name`,
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const saveHouseholdMember = () => {
    const selectedGenderOption = genderOptions.data.find(
      (genderOpt) => genderOpt.attributes.value === state.gender,
    )

    const payload = {
      data: {
        type: "HouseholdMember",
        attributes: {
          first_name: state.firstName,
          last_name: state.lastName,
          gender_id: selectedGenderOption?.id || "",
          grade: state.grade,
          school_id: state.school.id,
          birthdate: state.birthdate,
          medical_notes: state.medicalNotes,
          child: true,
        },
      },
    }

    const url = `/people/v2/people/${currentPersonId}/households/${householdId}/household_members`
    sessionApiClient
      .post(url, payload)
      .then(() => navigate(`/households/${householdId}`))
  }

  const canSubmit = state.firstName !== "" && state.lastName !== ""

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        saveHouseholdMember()
      }}
    >
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl={`/households/${householdId}`}
          pageText={householdName}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage isActive pageText="Add a child" />
      </BreadcrumbNavigation>
      <div className="action-drawer mb-1 mt-2">
        <div className="action-drawer">
          <div className="d-f@sm mb-2 ai-fe">
            <div className="d-f fg-1" css={styles.wrapper}>
              <div className="mb-1 pr-2@sm fg-1" css={styles.names}>
                <p style={{ fontWeight: "500" }}>
                  Name <span style={{ color: "#c8211f" }}>&#x2a;</span>
                </p>
                <input
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_FIRST_NAME,
                      payload: e.target.value,
                    })
                  }}
                  placeholder="First name"
                  type="text"
                  value={state.firstName}
                />
              </div>
              <div className="mb-1 fg-1" css={styles.names}>
                <input
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_LAST_NAME,
                      payload: e.target.value,
                    })
                  }}
                  placeholder="Last name"
                  type="text"
                  value={state.lastName}
                />
              </div>
            </div>
          </div>

          <div className="d-f@sm f_1 mb-2">
            <div className="mb-1">
              <GenderSelect
                value={state.gender}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_GENDER,
                    payload: value,
                  })
                }}
              />
            </div>
          </div>

          <div className="d-f@sm f_1 mb-2 mt-2">
            <div className="mb-1">
              <DateSelector
                initialValue=""
                label={<div>Birthdate</div>}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_BIRTHDATE,
                    payload: value,
                  })
                }}
              />
            </div>
          </div>

          <SchoolSelect
            additionalOptions={schoolOptions}
            grade={null}
            onChange={(value) => {
              const selectedSchool = schoolOptions.find(
                (schoolOpt) => value === schoolOpt.id,
              )
              dispatch({
                type: UPDATE_SCHOOL,
                payload: selectedSchool,
              })
            }}
            value={state?.school?.id}
          />

          <div className="mt-2">
            <GradeSelect
              grade={
                state.grade
                  ? {
                      attributes: { grade: state.grade },
                      id: "Grade",
                      type: "Grade",
                    }
                  : null
              }
              onChange={(value) => {
                dispatch({
                  type: UPDATE_GRADE,
                  payload: value,
                })
              }}
              school={state.school || undefined}
              value={state.grade}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="medical_notes">Medical notes</label>
            <textarea
              id="medical_notes"
              className="text-input"
              placeholder="Please use a comma-separated list for brevity. Ex: Pollen, nuts, and dairy."
              onChange={(e) => {
                dispatch({
                  type: UPDATE_MEDICAL_NOTES,
                  payload: e.target.value,
                })
              }}
              value={state.medicalNotes}
            />
          </div>
        </div>
      </div>

      <div className="d-f mt-2" css={styles.button}>
        <button disabled={!canSubmit} type="submit" className="btn compact-btn">
          Add to household
        </button>
      </div>
    </form>
  )
}

const styles = {
  wrapper: css`
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  `,
  names: css`
    @media (max-width: 600px) {
      width: 100%;
    }
  `,
  button: css`
    flex-direction: row-reverse;

    @media (max-width: 600px) {
      justify-content: center;
    }
  `,
}
