import { ActionBar } from "source/shared/components"
import {
  useCurrentGroup,
  useMyApplicationForCurrentGroup,
} from "../hooks/useCurrentGroup"
import { useMyMembership } from "../hooks/useMemberships"
import { Contact } from "./Contact"
import { Join } from "./Join"
import { RequestToJoin } from "./RequestToJoin"

export function MembershipBar() {
  const { data: membership } = useMyMembership()
  const application = useMyApplicationForCurrentGroup()

  const isMember = !!membership
  const isApplicant = !!application

  if (isMember) return <MemberBar />
  if (isApplicant) return <ApplicantBar />

  return <NonMemberBar />
}

function MemberBar() {
  const {
    data: {
      attributes: { role },
    },
  } = useMyMembership()
  const {
    data: {
      attributes: { church_center_web_my_group_url },
    },
  } = useCurrentGroup()

  return (
    <ActionBar className="mb-1@sm mb-3@md">
      <ActionBar.Column>
        <ActionBar.Description>
          You are a {role} of this group.
        </ActionBar.Description>
      </ActionBar.Column>
      <ActionBar.Action>
        <a href={church_center_web_my_group_url} className="btn">
          View Group
        </a>
      </ActionBar.Action>
    </ActionBar>
  )
}

function ApplicantBar() {
  return (
    <ActionBar className="mb-1@sm mb-3@md">
      <ActionBar.Description>
        Your membership request is being reviewed.
      </ActionBar.Description>
    </ActionBar>
  )
}

function NonMemberBar() {
  const {
    data: {
      attributes: {
        can_contact,
        enrollment_open,
        enrollment_strategy,
        membership_availability,
      },
    },
  } = useCurrentGroup()

  const JoinComponent =
    enrollment_strategy === "request_to_join" ? RequestToJoin : Join

  return (
    <ActionBar className="mb-1@sm mb-3@md fd-c fd-r@xs">
      <ActionBar.Column>
        <ActionBar.Description>{membership_availability}</ActionBar.Description>
      </ActionBar.Column>
      <ActionBar.Action className="d-f g-2 fw-w jc-c jc-fs@xs">
        {can_contact && <Contact />}
        {enrollment_open && <JoinComponent />}
      </ActionBar.Action>
    </ActionBar>
  )
}
