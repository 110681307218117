import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { apiResource, arrayOf } from "source/shared/prop_types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { useApiRead } from "source/shared/SessionApiResource"
import { getRelationship } from "source/shared/getRelationship"
import { unlinkify } from "source/shared/linkify"
import { Avatar, Loading, Icon } from "source/shared/components"
import { Date } from "source/shared/DatetimeFmt"
import { CurrentGroupContext } from "source/groups/my/groups"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"
import LiveUpdates from "source/groups/messaging/LiveUpdates"
import TabView from "source/groups/my/groups/TabView"
import { colors } from "source/shared/colors"
import { Heading } from "@planningcenter/doxy-web"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

export default function MessagesIndex() {
  const { current_organization } = useContext(ServerRenderedProps)

  const joltEvents = [
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_topic.created",
    },
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_topic.updated",
    },
    {
      func: "remove",
      event: "groups.church_center.v2.events.forum_topic.destroyed",
    },
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_reply.created",
    },
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_reply.updated",
    },
    {
      func: "ingest", // refetch the topic with the next most recent reply
      event: "groups.church_center.v2.events.forum_reply.destroyed",
    },
  ]

  return (
    <TabView>
      <LiveUpdates
        dataContext={TopicsDataContext}
        events={joltEvents}
        subscribeTo={`church_center.groups.messaging.${current_organization.id}`}
        fetchSubscribeTokenURL={`/groups/v2/jolt_messaging_subscribe`}
      >
        <FetchInitialData />
        <MessagesList />
        <LoadMore />
      </LiveUpdates>
    </TabView>
  )
}

function MessagesList() {
  const { abilities } = useContext(CurrentGroupContext)
  let { data, included } = useContext(TopicsDataContext)

  return (
    <>
      {data.length > 0 ? (
        <div>
          {abilities.forum_topics.can_create && (
            <TabViewHeadingRow text="Messaging" ingoreWrap>
              <Link
                to="new"
                className="minor-btn secondary-btn btn"
                aria-label="New message"
              >
                <Icon
                  symbol="general#plus"
                  className="mr-4p"
                  style={{ fontSize: 9 }}
                  aria-hidden
                />
                New
              </Link>
            </TabViewHeadingRow>
          )}
          {data.map((topic) => (
            <MessagePreview key={topic.id} topic={topic} included={included} />
          ))}
        </div>
      ) : (
        <div>
          <EmptyMessagesList />
          <div className="ta-c">
            {abilities.forum_topics.can_create && (
              <Link to="new" className="compact-btn btn">
                New message
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

function LoadMore() {
  const [loading, setLoading] = useState(false)
  const { ingestMany, nextLink } = useContext(TopicsDataContext)

  if (!nextLink) return null
  if (loading) return <Loading />

  function loadMore() {
    setLoading(true)
    sessionApiClient
      .get(nextLink)
      .then((json) => ingestMany(json))
      .finally(() => setLoading(false))
  }

  return (
    <button className="minor-btn secondary-btn btn" onClick={loadMore}>
      Load older messages
    </button>
  )
}

function FetchInitialData() {
  let { groupId, ingestMany } = useContext(TopicsDataContext)
  let json = useApiRead(
    `/groups/v2/me/groups/${groupId}/forum_topics?filter=human_authors,live&include=author,most_recent_reply,most_recent_reply.author&per_page=50`,
  )

  useEffect(() => {
    ingestMany(json)
  }, [groupId])

  return null
}

function MessagePreview({ topic, included }) {
  let { topicAuthor, mostRecentReply, mostRecentReplyAuthor } =
    assemblePreviewData(topic, included)
  const { last_replied_at, title, unread, user_notification_preference } =
    topic.attributes

  return (
    <article className="mb-1">
      <Link
        to={`${topic.id}`}
        className="d-b p-1"
        css={{
          borderRadius: 4,
          transition: "background-color 0.2s",
          wordBreak: "break-word",
          "&:hover": { backgroundColor: "var(--color-tint8)" },
        }}
      >
        <div className="d-f">
          <div className="w-6 h-6 mr-2">
            <Avatar
              url={topicAuthor.attributes.avatar_url}
              alt={`profile image for ${topicAuthor.attributes.name}`}
            />
          </div>
          <div className="f-1">
            <div className="d-f ai-b jc-sb">
              <div className="f-1">
                <Heading level={2} size={4} text={title} />
              </div>
              <div className="ml-1 c-tint3 fs-5">
                <Date start={last_replied_at} style="relative-short" />
              </div>
            </div>
            <div className="d-f ai-b jc-sb">
              <div className="fs-4 c-tint3">
                {mostRecentReply && (
                  <>
                    {mostRecentReplyAuthor.attributes.name && (
                      <span>{mostRecentReplyAuthor.attributes.name}: </span>
                    )}
                    {unlinkify(mostRecentReply.attributes.message)}
                  </>
                )}
              </div>

              {user_notification_preference === "none" ? (
                <Icon
                  symbol="churchCenter#moon"
                  style={{
                    width: 12,
                    height: 12,
                    color: unread ? colors.topaz : colors.tint4,
                  }}
                  aria-hidden
                />
              ) : (
                <Icon
                  symbol="churchCenter#circle"
                  style={{
                    width: 12,
                    height: 12,
                    color: unread ? colors.topaz : "transparent",
                  }}
                  aria-hidden
                />
              )}
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}
MessagePreview.propTypes = {
  topic: apiResource.isRequired,
  included: arrayOf(apiResource).isRequired,
}

function EmptyMessagesList() {
  return (
    <div className="ta-c py-4 px-2 c-tint2">
      <div className="fs-1 mb-1">
        <Icon symbol="churchCenter#message-circle" />
      </div>
      No messages yet
    </div>
  )
}

function assemblePreviewData(topic, included) {
  let topicAuthor = getRelationship({ data: topic, included }, "author")
  let mostRecentReply = getRelationship(
    { data: topic, included },
    "most_recent_reply",
  )
  let mostRecentReplyAuthor = mostRecentReply
    ? getRelationship({ data: mostRecentReply, included }, "author")
    : null
  return { topicAuthor, mostRecentReply, mostRecentReplyAuthor }
}
