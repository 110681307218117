import { array, node, string, object } from "prop-types"
import { Fragment, useMemo } from "react"
import { Heading } from "@planningcenter/doxy-web"
import { sortBy } from "lodash/collection"

import { CalendarEventScroller } from "source/calendar/list/List"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import FeaturedEvents from "source/calendar/list/FeaturedEvents"
import ListDay from "source/calendar/list/ListDay"

EventsList.propTypes = {
  autoloading: object,
  events: object.isRequired,
  featuredEvents: array.isRequired,
}
export default function EventsList({
  autoloading = {},
  events,
  featuredEvents,
}) {
  const momentInTZ = useMomentInTimeZone()
  const today = momentInTZ().startOf("day")

  const sortedEvents = useMemo(() => {
    return sortBy(Object.entries(events), ([dayOfMonth, _]) => dayOfMonth)
  }, [events])

  const hasEvents = sortedEvents.length > 0
  const hasFeaturedEvents = featuredEvents && featuredEvents.length > 0
  const currentMonthEmpty =
    hasEvents &&
    momentInTZ(sortedEvents[0][0]).month() !== momentInTZ(today).month()

  return (
    <>
      {hasFeaturedEvents && (
        <div className="d-f fd-c g-1 mb-1">
          <Heading level={1} text={"Featured"} />
          <FeaturedEvents
            featuredEvents={featuredEvents}
            {...EventsList.configureAutoloading("featured", autoloading)}
          />
          <div className="mt-4">
            <Heading level={1} text={"Upcoming"} />
            <hr style={{ border: "solid 0.5px #E0E0E0" }} />
          </div>
        </div>
      )}

      <MonthHeader date={momentInTZ(today)} />
      {currentMonthEmpty && (
        <div className="action-drawer ta-c mb-2 c-tint2">
          No events scheduled
        </div>
      )}

      <AutoloadingScroller
        {...EventsList.configureAutoloading("events", autoloading)}
      >
        {sortedEvents.map(([dayOfMonth, eventsOnDay], ix) => {
          const [previousDay] = ix > 0 ? sortedEvents[ix - 1] : []
          const changedMonth =
            (previousDay &&
              momentInTZ(previousDay).month() !==
                momentInTZ(dayOfMonth).month()) ||
            (!previousDay && currentMonthEmpty)

          const date = momentInTZ(dayOfMonth)

          return (
            <Fragment key={dayOfMonth}>
              {previousDay && (
                <EmptyMonthsBetween {...{ previousDay, dayOfMonth }} />
              )}
              {changedMonth && <MonthHeader date={date} />}
              <ListDay day={date} events={eventsOnDay} />
            </Fragment>
          )
        })}
      </AutoloadingScroller>
    </>
  )
}
EventsList.configureAutoloading = function (kind, autoloading) {
  switch (kind) {
    case "events":
      return autoloading.events
        ? {
            hasMore: autoloading.events.hasMore,
            lastDateLoaded: autoloading.events.lastDateLoaded,
            loadingMore: autoloading.events.loading,
            loadMore: autoloading.events.loadMore,
          }
        : undefined
    case "featured":
      return autoloading.featured
        ? autoloading.featured
        : { hasMore: false, loading: false }
  }
}

AutoloadingScroller.propTypes = { children: node.isRequired }
function AutoloadingScroller({ children, ...props }) {
  return Object.keys(props).length > 0 ? (
    <CalendarEventScroller {...props}>{children}</CalendarEventScroller>
  ) : (
    children
  )
}

EmptyMonthsBetween.propTypes = { day: string, previousDay: string }
function EmptyMonthsBetween({ day, previousDay }) {
  const momentInTZ = useMomentInTimeZone()
  const emptyMonthsBetween = momentInTZ(day).diff(previousDay, "months")
  return (
    emptyMonthsBetween > 1 &&
    [...Array(emptyMonthsBetween - 1)].map((_, ix) => {
      const previousMonth = momentInTZ(previousDay).month()
      const month = momentInTZ().month(previousMonth + ix + 1)

      return (
        <Fragment key={month.toISOString()}>
          <MonthHeader date={month} />
          <div className="action-drawer ta-c mb-3 c-tint2">
            No events scheduled
          </div>
        </Fragment>
      )
    })
  )
}

MonthHeader.propTypes = { date: object }
function MonthHeader({ date }) {
  return (
    <div className="my-3" data-date={date.format("YYYY-MM")}>
      <Heading level={3} text={date.format("MMMM YYYY")} />
    </div>
  )
}
