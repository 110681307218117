import { createContext, useEffect, useContext, useReducer, useRef } from "react"
import { node } from "prop-types"
import QR from "qrcode"

const __churchCenterQRCode__internal_context_do_not_use = createContext()
const InternalContextProvider =
  __churchCenterQRCode__internal_context_do_not_use.Provider
const QRCodeDefaults = {
  qrCode: null,
  showQRCode: false,
  pageUsesQRCode: false,
}

function reducer(state = QRCodeDefaults, action) {
  switch (action.type) {
    case "setPageUsesQRCode":
      return { ...state, pageUsesQRCode: action.payload }
    case "setQRCode":
      return { ...state, qrCode: action.payload }
    case "setShowQRCode":
      return { ...state, showQRCode: !!action.payload }
    case "resetQRCodeState":
      return { ...QRCodeDefaults }
    default:
      return state
  }
}

export const QRCodeProvider = ({ children }) => {
  return (
    <InternalContextProvider value={useReducer(reducer, QRCodeDefaults)}>
      {children}
    </InternalContextProvider>
  )
}
QRCodeProvider.propTypes = {
  children: node.isRequired,
}

export const QRCodeLink = () => {
  const [{ showQRCode, qrCode, pageUsesQRCode }, dispatch] = useContext(
    __churchCenterQRCode__internal_context_do_not_use,
  )

  const toggleQRCode = () => {
    dispatch({
      type: "setShowQRCode",
      payload: !showQRCode,
    })
  }

  useEffect(() => {
    if (pageUsesQRCode && showQRCode && !qrCode) {
      const qrOptions = {
        errorCorrectionLevel: "H",
        margin: 0,
        width: 150,
        color: {
          dark: "#313437",
          light: "#FFF",
        },
      }

      QR.toDataURL(window.location.toString(), qrOptions, (error, url) => {
        if (error) {
          return
        }
        dispatch({
          type: "setQRCode",
          payload: url,
        })
      })
    }
  }, [qrCode, showQRCode, pageUsesQRCode, window.location.toString()])

  const handleShowQRClick = () => {
    toggleQRCode()
    setTimeout(() => {
      window.scrollTo(0, 10000000)
    }, 0)
  }

  return (
    pageUsesQRCode && (
      <ul className="site-footer__links o-3 d-n d-f@md">
        <li className="site-footer__link">
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              fontWeight: 400,
            }}
            className="fs-5 c-tint3"
            onClick={handleShowQRClick}
          >
            Shareable Code
          </button>
        </li>
      </ul>
    )
  )
}

export const QRCode = () => {
  const [{ showQRCode, qrCode, pageUsesQRCode }, _dispatch] = useContext(
    __churchCenterQRCode__internal_context_do_not_use,
  )

  const downloadQR = useRef()

  return pageUsesQRCode && showQRCode ? (
    <div className="container container--narrow">
      <div id="qrcode-wrapper" className="action-drawer d-f p-2 mt-4">
        <div className="mr-2">
          <img id="qrcode" alt="qrcode" src={qrCode} />
        </div>
        <div className="f-1">
          <p>
            Use this graphic in printed materials or on displayed screens.
            People can scan the code using their phone&apos;s built-in code
            reader (&quot;Camera&quot; on iOS, &quot;Google Assistant&quot; on
            Android) to be brought straight to this URL – without having to type
            it.
          </p>
          <p>
            <a
              ref={downloadQR}
              className="qrcode-download c-brand"
              href={qrCode}
              download={"qrcode.png"}
            >
              <span id="qrcode">Download QR Code</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div />
  )
}

export const useQRCode = (...dependencies) => {
  const [, dispatch] = useContext(
    __churchCenterQRCode__internal_context_do_not_use,
  )

  useEffect(() => {
    dispatch({
      type: "setPageUsesQRCode",
      payload: true,
    })

    return () => {
      dispatch({ type: "resetQRCodeState" })
    }
  }, dependencies)
}

export function IncludeQRCode() {
  useQRCode()
  return null
}
