import { useState, useEffect } from "react"
import { array, func, object } from "prop-types"
import { useParams } from "react-router-dom"
import { css } from "@emotion/react"
import SelectionsItems from "source/registrations/reservations/SelectionsItems"
import { Checkbox } from "source/registrations/CheckableInput"
import { useEvent } from "source/registrations/hooks/useEvent"

const SelectionsSeparateTimes = ({
  step,
  selections,
  attendeeTypes,
  onSelectionChange,
  onSelectionsReset,
}) => {
  const { eventId } = useParams()

  const {
    data: { eventTimes },
  } = useEvent(eventId)

  const [selectedTimes, setSelectedTimes] = useState([])

  useEffect(() => {
    const selected = selections
      .filter(({ count }) => count > 0)
      .map(({ signupTimeId }) => signupTimeId)

    if (selected.length > 0) return setSelectedTimes(selected)

    const availableEventTimes = eventTimes.filter(
      (et) => et.availableForRegistrations && !et.atMaximumCapacity,
    )

    if (availableEventTimes.length === 1) {
      setSelectedTimes([availableEventTimes[0].id])
    }
  }, [selections.length, step.isCurrent, eventTimes])

  const handleChange = ({ target: { value, checked } }) => {
    const eventTimeId = Number(value)

    if (checked) {
      setSelectedTimes([...selectedTimes, eventTimeId])
    } else {
      setSelectedTimes(selectedTimes.filter((st) => st !== eventTimeId))
      onSelectionsReset(eventTimeId)
    }
  }

  const waitlistAvailable = attendeeTypes.some((attendeeType) => {
    return attendeeType.waitlist
  })

  const eventTimesForRegisterable = eventTimes
    .filter((et) => et.availableForRegistrations)
    .sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt))

  const nestedActionDrawers = css`
    & .action-drawer {
      background-color: var(--color-tint8);
    }
  `

  const dateHeadingStyles = css`
    margin-top: 1.5rem;

    &:first-of-type {
      margin-top: 0;
    }
  `

  return (
    <div>
      {eventTimesForRegisterable.length === 0 && (
        <div>No dates or times available for registration</div>
      )}

      {eventTimesForRegisterable.map((eventTime) => (
        <div
          key={eventTime.id}
          className="mb-1"
          css={!step.isCurrent && dateHeadingStyles}
        >
          <div className={step.isCurrent ? "action-drawer mb-1" : ""}>
            {step.isCurrent && (
              <Checkbox
                id={`eventTime_${eventTime.id}`}
                name={`eventTime_${eventTime.id}`}
                label={`${eventTime.date} ${
                  eventTime.allDay ? "" : eventTime.time
                } ${eventTime.atMaximumCapacity ? "(Full)" : ""}`}
                value={eventTime.id}
                onChange={handleChange}
                disabled={eventTime.atMaximumCapacity && !waitlistAvailable}
                checked={selectedTimes.includes(eventTime.id)}
              />
            )}

            {!step.isCurrent &&
              step.isComplete &&
              selectedTimes.includes(eventTime.id) && (
                <div className="c-tint2 fw-500">
                  {eventTime.date} {eventTime.allDay ? "" : eventTime.time}
                </div>
              )}

            {selectedTimes.includes(eventTime.id) && (
              <div
                className={step.isCurrent ? "mt-2" : "mt-4p"}
                css={step.isCurrent && nestedActionDrawers}
              >
                <SelectionsItems
                  step={step}
                  selections={selections.filter(
                    ({ signupTimeId }) => signupTimeId === eventTime.id,
                  )}
                  attendeeTypes={attendeeTypes.filter(
                    ({ signupTimeId }) => signupTimeId === eventTime.id,
                  )}
                  onSelectionChange={onSelectionChange}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

SelectionsSeparateTimes.propTypes = {
  step: object,
  selections: array,
  attendeeTypes: array,
  onSelectionChange: func,
  onSelectionsReset: func,
}

export default SelectionsSeparateTimes
