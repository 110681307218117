import TabView from "source/groups/my/groups/TabView"
import TopicForm from "source/groups/messaging/Topic/TopicForm"
import { useSession } from "source/shared/hooks/useSession"

export default function MessagesNew() {
  useSession(true)

  return (
    <TabView showActions={false}>
      <TopicForm />
    </TabView>
  )
}
