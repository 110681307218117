import { useEffect, useState } from "react"
import PropTypes from "prop-types"

EmbeddedPlayer.propTypes = {
  episodeTime: PropTypes.object,
  videoEmbedCode: PropTypes.string.isRequired,
  joltChannel: PropTypes.object,
}

export default function EmbeddedPlayer({
  episodeTime,
  videoEmbedCode,
  joltChannel,
}) {
  const [started, setStarted] = useState(false)

  useEffect(() => {
    if (joltChannel && videoEmbedCode && !started) {
      setStarted(true)
      joltChannel.trigger(
        episodeTime ? "client.liveWatchStats" : "client.libraryWatchStats",
        JSON.stringify({
          playerState: "PLAYING",
          playerCurrentTime: 0,
        }),
      )
    }
  }, [episodeTime, joltChannel, videoEmbedCode, started])

  return (
    <div
      className="video-embed"
      dangerouslySetInnerHTML={{ __html: videoEmbedCode }}
    />
  )
}
