import { FC } from "react"
import { css, keyframes } from "@emotion/react"

interface BaseSkeletonProps {
  width?: number | string
  height?: number | string
  aspectRatio?: string
  borderRadius?: string
}

const BaseSkeleton: FC<BaseSkeletonProps> = ({
  width = "100%",
  height = "auto",
  aspectRatio = undefined,
  borderRadius = undefined,
}) => (
  <div css={[styles.skeleton, { width, height, aspectRatio, borderRadius }]} />
)

interface SkeletonProps {
  width?: number | string
  height?: number | string
  aspectRatio?: string
}

/**
 * Doxy skeleton
 */
export const Skeleton: FC<SkeletonProps> = ({
  width = undefined,
  height = undefined,
  aspectRatio = undefined,
}: SkeletonProps) => (
  <BaseSkeleton width={width} height={height} aspectRatio={aspectRatio} />
)

interface SkeletonCircle {
  /**
   * Diameter of the circle
   * */
  size?: number | string
}

export const Circle: FC<SkeletonCircle> = ({ size }: SkeletonCircle) => (
  <BaseSkeleton
    aspectRatio="1 / 1"
    borderRadius="100%"
    width={size}
    height={size}
  />
)

export const Paragraph: FC = () => (
  <div className="d-f fd-c g-1">
    <Skeleton width="100%" />
    <Skeleton width="80%" />
    <Skeleton width="100%" />
    <Skeleton width="90%" />
    <Skeleton width="50%" />
  </div>
)

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const styles = {
  skeleton: css`
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    background-color: var(--color-tint6);
    width: 100%;
    min-height: 16px;
    display: block;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0.5),
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0)
      );
      animation: ${shimmer} 2s infinite;
      content: "";
    }
    [data-color-scheme="dark"] &:after {
      background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 30%, 0),
        hsla(0, 0%, 30%, 0.2),
        hsla(0, 0%, 30%, 0.5),
        hsla(0, 0%, 30%, 0.2),
        hsla(0, 0%, 30%, 0)
      );
    }
  `,
}
