import { useContext, useEffect } from "react"
import { ReservationContext } from "source/registrations/reservations/ReservationContext"
import { loadStripe } from "@stripe/stripe-js/pure"

const useStripe = ({ stripeKey, stripeAccount, allowsOnlinePayments }) => {
  const { stripe, setStripe } = useContext(ReservationContext)

  useEffect(() => {
    const fetchStripe = async () => {
      const response = await loadStripe(stripeKey, { stripeAccount })
      setStripe(response)
    }

    if (allowsOnlinePayments && stripeKey && stripeAccount && !stripe)
      fetchStripe()
  }, [stripeKey, stripeAccount, allowsOnlinePayments, stripe, setStripe])

  return stripe
}

export default useStripe
