import { any, arrayOf, object, shape, bool, func, string } from "prop-types"
import { range, startCase } from "lodash"
import { Icon } from "source/shared/components"
import { GraduationYear } from "./GraduationYear"

const apiDataPropType = shape({
  attributes: object.isRequired,
  id: string.isRequired,
  type: string.isRequired,
})

GradeAndSchool.propTypes = {
  grade: any,
  isChild: any,
  school: any,
  schoolOptions: any,
  updateAttribute: any,
  updateAttributes: any,
  disableGrade: bool,
  disableGraduationYear: bool,
  graduationYear: any,
}

export function GradeAndSchool({
  grade,
  isChild,
  school,
  schoolOptions,
  updateAttribute,
  updateAttributes,
  disableGrade = false,
  disableGraduationYear = false,
  graduationYear = null,
}) {
  if (
    isChild ||
    !!school.attributes.school_id ||
    grade.attributes.grade !== null
  ) {
    return (
      <div className="d-f@sm f_1 mb-1">
        <GradeOrGraduationYear
          {...{
            disableGrade,
            disableGraduationYear,
            graduationYear,
            school,
            grade,
            updateAttribute,
          }}
        />
        <div className="mb-1 pl-2@sm">
          <SchoolSelect
            additionalOptions={schoolOptions}
            grade={grade?.attributes?.grade || null}
            graduationYear={graduationYear?.attributes?.graduation_year || null}
            onChange={(id) => {
              const defaultSchoolAttributes = {
                beginning_grade: null,
                ending_grade: null,
              }
              const newSchoolAttributes =
                schoolOptions.find((option) => option.id === id)?.attributes ||
                defaultSchoolAttributes

              updateAttributes(school, {
                ...newSchoolAttributes,
                school_id: id,
              })
            }}
            value={school?.attributes.school_id}
          />
        </div>
      </div>
    )
  } else return null
}

GradeOrGraduationYear.propTypes = {
  disableGrade: any,
  disableGraduationYear: any,
  grade: any,
  graduationYear: any,
  school: any,
  updateAttribute: func,
}

function GradeOrGraduationYear({
  disableGrade,
  disableGraduationYear,
  grade,
  graduationYear,
  school,
  updateAttribute,
}) {
  if (school?.attributes.school_id && !school?.attributes.ending_grade) {
    return (
      <div className="mb-1 pr-2@sm">
        <GraduationYear
          {...{ disableGraduationYear, graduationYear, updateAttribute }}
        />
      </div>
    )
  }

  return (
    <div className="mb-1 pr-2@sm">
      <GradeSelect
        disabled={disableGrade}
        grade={grade}
        onChange={(newGrade) => updateAttribute(grade, "grade", newGrade)}
        school={school}
        value={grade?.attributes?.grade || ""}
      />
    </div>
  )
}

GradeSelect.propTypes = {
  disabled: bool,
  grade: any,
  onChange: func.isRequired,
  school: apiDataPropType,
  value: string.isRequired,
}

export function GradeSelect({
  disabled = false,
  value,
  onChange,
  school = {
    attributes: { beginning_grade: null, ending_grade: null },
  },
  grade,
}) {
  const gradeMapping = {
    "-1": "Pre-Kindergarten",
    0: "Kindergarten",
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
    11: "11th",
    12: "12th",
  }

  const { beginning_grade = null, ending_grade = null } = school.attributes

  const PRE_KINDERGARTEN = -1
  const TWELTH_GRADE = 12
  const start =
    beginning_grade === null ? PRE_KINDERGARTEN : Number(beginning_grade)
  const exlusiveRangeEnd =
    ending_grade === null ? TWELTH_GRADE + 1 : Number(ending_grade) + 1

  const grades = range(start, exlusiveRangeEnd).map((grade) => {
    return (
      <option key={grade} value={grade}>
        {gradeMapping[grade]}
      </option>
    )
  })

  return (
    <>
      <div className="label">
        Grade
        {disabled && <Icon symbol="general#lock" className="fs-2 pl-1" />}
      </div>
      <select
        aria-label="grade"
        className="select"
        disabled={disabled}
        id="grade"
        name="grade"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        <option key="No value" value="">
          Select...
        </option>
        {gradeMapping[grade]}
        {grades}
      </select>
    </>
  )
}

SchoolSelect.propTypes = {
  additionalOptions: arrayOf(apiDataPropType),
  grade: string,
  graduationYear: string,
  onChange: func.isRequired,
  value: string.isRequired,
}

export function SchoolSelect({
  additionalOptions,
  grade,
  onChange,
  value,
  graduationYear = null,
}) {
  function buildOptionForSchool(option) {
    // Note: only an ending_range is required to create a range
    const { beginning_grade, ending_grade } = option.attributes

    const starting_grade = beginning_grade || -Infinity

    const gradeInRange =
      ending_grade &&
      Number(starting_grade) <= Number(grade) &&
      Number(ending_grade) >= Number(grade)

    const disableGradeSchool = grade !== null && !gradeInRange
    const disableNonGradeSchool = graduationYear && ending_grade

    const disableSchool =
      graduationYear === null ? disableGradeSchool : disableNonGradeSchool

    return (
      <option key={option.id} value={option.id} disabled={disableSchool}>
        {option.attributes.value}
      </option>
    )
  }

  const schoolGroups = groupSchoolsByType(additionalOptions)
  const schools = Object.keys(schoolGroups).map((type) => {
    const schoolGroup = schoolGroups[type]
    if (!schoolGroup.length) return null

    return (
      <optgroup key={type} label={startCase(type)}>
        {schoolGroup.map(buildOptionForSchool)}
      </optgroup>
    )
  })

  return (
    <>
      <label htmlFor="school">School</label>
      <select
        className="select"
        id="school"
        name="school"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option key="0" value="">
          Select...
        </option>
        {schools}
      </select>
    </>
  )
}

function groupSchoolsByType(schools) {
  const schoolGroups = {
    elementary: [],
    middle_school: [],
    high_school: [],
    college: [],
    other: [],
  }
  schools.forEach((option) => {
    const { school_types } = option.attributes
    school_types.forEach((type) => {
      if (!schoolGroups[type]) schoolGroups[type] = []
      schoolGroups[type].push(option)
    })
  })
  return schoolGroups
}
