import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"
import { Icon } from "source/shared/components"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { colors } from "source/shared/colors"

const propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subscriptionType: PropTypes.oneOf(["feed", "occurrence"]),
  iCalUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export function CalendarSubscriptionButton({
  links,
  subscriptionType,
  iCalUrl,
  children,
}) {
  return (
    <Menu>
      <MenuButton className="minor-btn secondary-btn btn">
        <div className="d-f jc-sb ai-c">
          {children}
          <span className="dropdown-trigger__icon fs-6 ml-4p">
            <Icon symbol="general#down-chevron" aria-hidden />
          </span>
        </div>
      </MenuButton>
      <MenuList className="dropdown__menu">
        {links.map((link) => (
          <MenuLink
            key={link.id}
            href={link.url}
            target="_blank"
            className="dropdown__item"
          >
            {link.title}
          </MenuLink>
        ))}
        <div
          className="mt-4p mb-4p"
          style={{
            borderTop: `1px solid ${colors.tint6}`,
          }}
        />
        {subscriptionType === "feed" && <CopyCalendarUrl url={iCalUrl} />}
        {subscriptionType === "occurrence" && (
          <DownloadCalendarFile url={iCalUrl} />
        )}
      </MenuList>
    </Menu>
  )
}
CalendarSubscriptionButton.propTypes = propTypes

function CopyCalendarUrl({ url }) {
  const [copied, setCopied] = useState(false)
  const handleCopy = () => setCopied(true)
  useEffect(() => {
    let delayedClearCopied
    if (copied) {
      delayedClearCopied = setTimeout(() => setCopied(false), 5000)
    }
    return () => clearTimeout(delayedClearCopied)
  }, [copied])

  return (
    <CopyToClipboard text={url} onCopy={handleCopy}>
      <div className="dropdown__item">
        {copied ? "Copied!" : "Copy the iCal link"}
      </div>
    </CopyToClipboard>
  )
}
CopyCalendarUrl.propTypes = { url: PropTypes.string.isRequired }

function DownloadCalendarFile({ url }) {
  return (
    <MenuLink href={url} target="_blank" className="dropdown__item">
      Download the iCal file
    </MenuLink>
  )
}
DownloadCalendarFile.propTypes = { url: PropTypes.string.isRequired }
