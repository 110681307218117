import { useContext } from "react"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"

export function useEnv() {
  const {
    configuration: { railsEnv },
  } = useContext(ServerRenderedProps)

  return railsEnv
}
