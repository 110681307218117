import { useRef, useState } from "react"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { sessionApiClient as API } from "@planningcenter/cc-api-client"
import spinner from "source/svg/spinner.svg"

const VARIANT_TEXT = {
  all: {
    title: "Delete All Blockouts?",
    body: "This will delete all dates for this recurrence.",
  },
  all_household: {
    title: "Delete All Blockouts for this Group?",
    body: "This will delete all of the group's dates for this recurrence.",
  },
  future: {
    title: "Delete All Future Blockouts?",
    body: "This will delete this one date and all future dates.",
  },
  future_household: {
    title: "Delete All Future Blockouts for this Group?",
    body: "This will delete this one date and all future dates for this group.",
  },
  one: {
    title: "Delete This Blockout?",
    body: "This will delete only this one date.",
  },
  one_household: {
    title: "Delete This Blockout Group?",
    body: "This will delete only this one date for this group.",
  },
}

export function DestroyModal({
  applyTo,
  applyToHousehold,
  blockoutId,
  blockoutDateId,
  onComplete,
  onCancel,
}) {
  const cancelRef = useRef(null)
  const [inFlight, setInFlight] = useState(false)

  const handleSubmit = () => {
    setInFlight(true)

    destroyBlockout()
      .then(onComplete)
      .catch(() => {
        setInFlight(false)
        alert("Something went wrong. Please try again.")
      })
  }

  const destroyBlockout = () =>
    API.post(`/services/v2/me/blockouts/${blockoutId}/delete`, {
      data: {
        attributes: {
          apply_to: applyTo,
          apply_to_group: applyToHousehold,
          blockout_date_id: blockoutDateId,
        },
      },
    })

  const variant = applyToHousehold ? `${applyTo}_household` : applyTo

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>{VARIANT_TEXT[variant].title}</AlertDialogLabel>
      <AlertDialogDescription>
        {VARIANT_TEXT[variant].body}
        <div className="d-f jc-fe mt-3">
          <button
            className="text-btn mr-2 btn "
            onClick={onCancel}
            ref={cancelRef}
          >
            Cancel
          </button>{" "}
          <button className="btn" onClick={handleSubmit}>
            {inFlight ? (
              <img
                src={spinner}
                alt="Deleting"
                style={{ height: "1em", width: "1em" }}
              />
            ) : (
              <div>Yes, delete!</div>
            )}
          </button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}
