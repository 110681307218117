import { object, func, arrayOf, number, string, shape, bool } from "prop-types"
import { useParams } from "react-router-dom"
import SingleAttendeeSelection from "source/registrations/reservations/SingleAttendeeSelection"
import MultiAttendeeSelection from "source/registrations/reservations/MultiAttendeeSelection"
import { useEvent } from "source/registrations/hooks/useEvent"

const SelectionsItems = ({
  step,
  selections,
  attendeeTypes,
  onSelectionChange,
  isSingleSelection,
}) => {
  const { eventId } = useParams()

  const {
    data: { registerForSeparateTimes, eventTimes },
  } = useEvent(eventId)

  const getStandardSelections = () =>
    selections
      .filter(({ waitlist }) => !waitlist)
      .filter((selection) => selection.count > 0)

  const getWaitlistSelections = () =>
    selections
      .filter(({ waitlist }) => waitlist)
      .filter((selection) => selection.count > 0)

  const selectionsToString = () => {
    const standardSelections = getStandardSelections().map(
      (selection) => `${selection.count} ${selection.name}`,
    )

    const waitlistSelections = getWaitlistSelections().map(
      (selection) => `${selection.count} ${selection.name}`,
    )

    return (
      <>
        {standardSelections.length > 0 && (
          <div className="truncate">{standardSelections.join(", ")}</div>
        )}

        {waitlistSelections.length > 0 && (
          <div className="truncate">
            {`On waitlist for ${waitlistSelections.join(", ")}`}
          </div>
        )}
      </>
    )
  }

  const eventTimeToString = () => {
    let selection = getStandardSelections()

    if (selection.length === 0) {
      selection = getWaitlistSelections()
    }

    const eventTime = eventTimes.find(
      (et) => et.id === selection[0].signupTimeId,
    )

    return `${eventTime.date} ${eventTime.allDay ? "" : eventTime.time}`
  }

  return (
    <div>
      {step.isCurrent && (
        <div className="d-f fd-c g-1">
          {isSingleSelection ? (
            <SingleAttendeeSelection
              selections={selections}
              onSelectionChange={onSelectionChange}
              attendeeTypes={attendeeTypes}
            />
          ) : (
            <MultiAttendeeSelection
              selections={selections}
              onSelectionChange={onSelectionChange}
              attendeeTypes={attendeeTypes}
              step={step}
            />
          )}
        </div>
      )}

      {!step.isCurrent && step.isComplete && (
        <>
          {isSingleSelection && registerForSeparateTimes && (
            <div className="c-tint2 fw-500">{eventTimeToString()}</div>
          )}
          <div className="c-tint2 mb-1 truncate">{selectionsToString()}</div>
        </>
      )}
    </div>
  )
}

SelectionsItems.propTypes = {
  step: object,
  selections: arrayOf(shape({ id: number, name: string, count: number })),
  onSelectionChange: func,
  isSingleSelection: bool,
  attendeeTypes: arrayOf(object),
}

export default SelectionsItems
