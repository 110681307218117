import { Image } from "@planningcenter/doxy-web"
import { css } from "@emotion/react"
import { string } from "prop-types"

export function HeroImage({ alt, src }) {
  return (
    <div css={styles.heroImageWrapper}>
      <Image rounded={[false, { md: true }]} {...{ alt, src }} />
    </div>
  )
}

HeroImage.propTypes = { alt: string, src: string }

const styles = {
  heroImageWrapper: css`
    margin: -1rem -1.5rem 1rem;

    @media (min-width: 720px) {
      margin: 0;
      order: 2;
    }
  `,
}
