import { array, bool, string } from "prop-types"
import { css } from "@emotion/react"
import { useParams } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import ReviewAttendees from "./ReviewAttendees"
import { useEvent } from "source/registrations/hooks/useEvent"

const dateHeadingStyles = css`
  margin-top: 1.5rem;

  &:first-of-type {
    margin-top: 0;
  }
`

const AttendeesBreakdown = ({
  attendees,
  addOns = [],
  isAddOnsEnabled = false,
  hasTotalDue = false,
  forWaitlist = false,
  totalDiscountString,
}) => {
  const { eventId } = useParams()

  const {
    data: {
      hasAttendeesModule,
      registerForSeparateTimes,
      eventTimes: eventTimesFromState,
    },
  } = useEvent(eventId)

  const attendeesByWaitlistType = attendees.filter(
    (a) => a.waitlist === forWaitlist,
  )

  const eventTimes = registerForSeparateTimes
    ? eventTimesFromState
    : [eventTimesFromState[0]]

  const eventTimeSelections = eventTimes.reduce((filtered, eventTime) => {
    const eventTimeAttendees = attendeesByWaitlistType.filter((attendee) =>
      attendee.eventTime
        ? attendee.eventTime.id === eventTime.id
        : attendee.eventTime === null,
    )

    if (eventTimeAttendees.length > 0) {
      filtered.push({
        ...eventTime,
        attendees: eventTimeAttendees,
      })
    }

    return filtered
  }, [])

  return (
    <>
      {forWaitlist && attendeesByWaitlistType.length > 0 && (
        <div className="mt-4 mb-1">
          <Heading
            level={3}
            size={4}
            text={
              hasAttendeesModule
                ? "These attendees will be placed on the waitlist:"
                : "You will be placed on the waitlist for:"
            }
          />
        </div>
      )}

      {eventTimeSelections.map((eventTime) => (
        <div key={eventTime.id} css={dateHeadingStyles}>
          {registerForSeparateTimes && (
            <div className="fw-500">
              {eventTime.date} {eventTime.allDay ? "" : eventTime.time}
            </div>
          )}

          <ReviewAttendees
            addOns={addOns}
            isAddOnsEnabled={isAddOnsEnabled}
            attendees={eventTime.attendees}
            hasNamedAttendees={hasAttendeesModule}
            hasTotalDue={hasTotalDue}
            forWaitlist={forWaitlist}
            totalDiscountString={totalDiscountString}
          />
        </div>
      ))}
    </>
  )
}

AttendeesBreakdown.propTypes = {
  attendees: array.isRequired,
  addOns: array,
  isAddOnsEnabled: bool,
  hasTotalDue: bool,
  forWaitlist: bool,
  totalDiscountString: string,
}

export default AttendeesBreakdown
