import { useState } from "react"
import PropTypes from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { get as dig } from "lodash"
import RsvpIcon from "source/groups/my/groups/events/RsvpIcon"
import { ActionBar, Icon } from "source/shared/components"

const AWAITING_RESPONSE = "awaiting_response"
const NOT_SENT = "not_sent"

export default function MyRsvpForm({
  rsvpResponseAttributes,
  eventApiPath,
  rsvp = null,
}) {
  const [response, setResponse] = useState(
    dig(rsvp, "attributes.response", AWAITING_RESPONSE),
  )

  const selectedResponseOption = rsvpResponseAttributes.find(
    (o) => o.id === response,
  )
  const permittedResponseOptions = rsvpResponseAttributes.filter(
    (o) => ![AWAITING_RESPONSE, NOT_SENT].includes(o.id),
  )

  const saveResponse = (value) => {
    sessionApiClient
      .post(`${eventApiPath}/rsvp`, {
        data: { attributes: { response: value } },
      })
      .then(({ data }) => {
        setResponse(data.attributes.response)
      })
      .catch(() => {
        alert("Oops, something went wrong. Please try again.")
      })
  }

  const { ccw_color, icon, labels } = selectedResponseOption.attributes
  const buttonText = response === AWAITING_RESPONSE ? "Respond" : "Update"

  return (
    <ActionBar className="mb-3@sm">
      <ActionBar.Column>
        <ActionBar.Title>RSVP</ActionBar.Title>
        <ActionBar.Description>
          <RsvpIcon iconColor={ccw_color} iconSymbol={icon} />
          {labels.second_person}
        </ActionBar.Description>
      </ActionBar.Column>
      <ActionBar.Action>
        <RsvpActions
          options={permittedResponseOptions}
          buttonText={buttonText}
          saveResponse={saveResponse}
        />
      </ActionBar.Action>
    </ActionBar>
  )
}
MyRsvpForm.propTypes = {
  rsvp: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.string,
      response: PropTypes.string,
    }),
    links: PropTypes.shape({
      self: PropTypes.string,
    }),
  }),
  rsvpResponseAttributes: PropTypes.array.isRequired,
  eventApiPath: PropTypes.string.isRequired,
}

function RsvpActions({ buttonText, options, saveResponse }) {
  return (
    <Menu>
      <MenuButton className="btn">
        {buttonText}
        <span className="fs-5 ml-1 dropdown-trigger__icon">
          <Icon symbol="general#down-chevron" />
        </span>
      </MenuButton>
      <MenuList className="dropdown__menu">
        {options.map((option) => (
          <MenuItem
            key={option.id}
            className="dropdown__item"
            onSelect={() => saveResponse(option.id)}
          >
            {option.attributes.labels.first_person}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
RsvpActions.propTypes = {
  buttonText: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  saveResponse: PropTypes.func.isRequired,
}
