import { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Button, Heading } from "@planningcenter/doxy-web"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { buildUrl } from "source/shared/buildUrl"
import { Loading } from "source/shared/components"
import { Pill } from "source/groups/my/groups/attendances/Pill"
import { useMemberAttendances } from "source/groups/my/groups/members/hooks/useMemberAttendances"

const startingEventCount = 20

export const MemberAttendances = ({ groupId, personId, joinedAt }) => {
  return (
    <section className="d-f fd-c g-1">
      <Heading level={2} size={3} text="Attendances" />
      <Suspense fallback={<Loading />}>
        <Attendances
          groupId={groupId}
          personId={personId}
          joinedAt={joinedAt}
        />
      </Suspense>
    </section>
  )
}

MemberAttendances.propTypes = {
  groupId: PropTypes.string,
  personId: PropTypes.string,
  joinedAt: PropTypes.string,
}

const Attendances = ({ groupId, personId, joinedAt }) => {
  const {
    showMore,
    showLess,
    canShowMore,
    canShowLess,
    visiblePairs,
    unloadedEventCount,
    loadingMore,
  } = useAttendances({ groupId, personId, joinedAt })

  if (visiblePairs.length === 0) {
    return <div>No attendances</div>
  }

  return (
    <div>
      <div className="d-f g-3 fw-w">
        {visiblePairs.map(({ attendance, event, loading }) => {
          return (
            <Attendance
              attendance={attendance}
              event={event}
              key={event.id}
              loading={loading}
            />
          )
        })}
        {loadingMore &&
          Array.from({ length: unloadedEventCount }).map((_, index) => (
            <LoadingAttendance key={index} />
          ))}
      </div>
      {canShowLess && (
        <div className="ta-c mt-2">
          <PaginateButton onClick={showLess} text="Show less" />
        </div>
      )}
      {canShowMore && (
        <div className="ta-c mt-2">
          <PaginateButton onClick={showMore} text="Show more" />
        </div>
      )}
    </div>
  )
}

Attendances.propTypes = {
  groupId: PropTypes.string,
  personId: PropTypes.string,
  joinedAt: PropTypes.string,
}

const Attendance = ({ attendance, event, loading }) => {
  return (
    <Pill
      state={attendanceState({
        recordingStatus: event.attributes.attendance_recording_status,
        attended: attendance?.attributes.attended,
        loading,
      })}
      label={loading ? "" : formatEventDate(event.attributes.starts_at)}
    />
  )
}

Attendance.propTypes = {
  attendance: PropTypes.object,
  event: PropTypes.object,
  loading: PropTypes.bool,
}

const LoadingAttendance = () => {
  return <Pill state="loading" label="" />
}

const PaginateButton = ({ onClick, text }) => {
  return <Button onClick={onClick} text={text} size="sm" variant="outline" />
}

PaginateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

const formatEventDate = (date) => {
  return moment(date).format("MM/DD")
}

const attendanceState = ({ recordingStatus, attended, loading }) => {
  if (loading) return "loading"
  if (recordingStatus === "no_recording") return "unrecorded_event"
  if (recordingStatus === "draft") return "unrecorded_event"
  if (recordingStatus === "processing") return "unrecorded_event"
  if (attended) return "attended"
  if (attended === false) return "missed"

  return "cancelled"
}

const useAttendances = ({ groupId, personId, joinedAt }) => {
  const [showingMore, setShowingMore] = useState(false)
  const filterParams = joinedAt
    ? { filter: "by_time", after: joinedAt }
    : { filter: "past" }

  const eventsUrl = buildUrl(`/groups/v2/me/groups/${groupId}/events`, {
    ...filterParams,
    order: "-starts_at",
    "fields[Event]": "starts_at,attendance_recording_status",
    per_page: startingEventCount,
  })

  const events = useInfiniteLoadingResource(eventsUrl)
  const eventIds = useMemo(
    () => events.records.map((event) => event.id),
    [events.records],
  )

  const showMore = useCallback(() => setShowingMore(true), [])
  const showLess = useCallback(() => setShowingMore(false), [])

  const unloadedEventCount = events.totalCount - events.records.length

  const attendances = useMemberAttendances({ groupId, personId, eventIds })

  const pairs = useMemo(
    () =>
      events.records.map((event) => ({
        attendance: attendances.data.find(
          (attendance) => event.id === attendance.relationships.event.data.id,
        ),
        event,
        loading: attendances.loadedEventIds.indexOf(event.id) < 0,
      })),
    [attendances.data, attendances.loadedEventIds, events.records],
  )

  useEffect(() => {
    if (showingMore && events.hasMore && !events.loadingMore) {
      events.loadMore()
    }
  }, [showingMore, events.hasMore, events.loadingMore, events.loadMore])

  const visiblePairs = showingMore ? pairs : pairs.slice(0, startingEventCount)
  const hasMoreUnloaded = events.hasMore
  const hasMoreLoaded = pairs.length > startingEventCount
  const canShowLess = showingMore
  const canShowMore = !showingMore && (hasMoreUnloaded || hasMoreLoaded)

  return {
    showMore,
    showLess,
    canShowMore,
    canShowLess,
    visiblePairs,
    unloadedEventCount,
    loadingMore: events.loadingMore,
  }
}
