import { transformResponse } from "@planningcenter/api-client"
import {
  getConfirmationLink,
  myRegistrationShape,
} from "source/registrations/hooks/useMyRegistrations"
import { useSession } from "source/shared/hooks/useSession"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"

import { Link } from "react-router-dom"
import EventImage from "./EventImage"
import EventImagePlaceholder from "./EventImagePlaceholder"
import Status from "./Status"

const Registration = ({ registration }) => {
  const { data: currentPerson } = transformResponse(useSession())
  const isRegistrant = currentPerson.id === registration.responsiblePerson?.id
  const confirmationLink = getConfirmationLink(registration)
  const detailedWaitlistEnabled = useOrganizationFlag("detailedWaitlist")

  return (
    <article key={registration.id} className="d-f mb-2">
      <div className="mr-2">
        <Link to={confirmationLink} className="d-b">
          {registration.event?.logoUrl ? (
            <EventImage event={registration.event} />
          ) : (
            <EventImagePlaceholder event={registration.event} />
          )}
        </Link>
      </div>
      <div className="f-1 overflow-text">
        <div className="lh-1.25 overflow-text">{registration.event.name}</div>
        {!isRegistrant && registration.responsiblePerson?.name && (
          <div className="fs-4 lh-1.5 c-tint2 overflow-text">
            Registration Contact: {registration.responsiblePerson.name}
          </div>
        )}
        {registration.participantNames.length > 0 && (
          <div className="fs-4 lh-1.5 c-tint2 overflow-text">
            Participants: {registration.participantNames.join(", ")}
          </div>
        )}
        {detailedWaitlistEnabled && registration.waitlistedNames.length > 0 && (
          <div className="fs-4 lh-1.5 c-tint2 overflow-text">
            Waitlisted: {registration.waitlistedNames.join(", ")}
          </div>
        )}
        {registration.event.eventTime && (
          <div className="fs-4 lh-1.5 c-tint2 overflow-text">
            {registration.event.eventTime}
          </div>
        )}
        <Status
          registration={registration}
          confirmationLink={confirmationLink}
        />
      </div>
    </article>
  )
}

Registration.propTypes = {
  registration: myRegistrationShape,
}

export default Registration
