import { arrayOf, shape, string } from "prop-types"
import { useContext } from "react"
import ReactDatePicker from "react-datepicker"
import { flatten } from "lodash"
import { WebBootContext } from "source/publishing/WebBoot"

function FormErrors({ errors = [] }) {
  if (!errors.length) return null

  const messages = flatten(errors.map(({ detail }) => detail))

  return (
    <ul className="mt-1">
      {messages.map((m) => (
        <li
          key={m}
          className="mt-1"
          style={{ color: "var(--warning-alert--text)" }}
        >
          {m}
        </li>
      ))}
    </ul>
  )
}
FormErrors.propTypes = {
  errors: arrayOf(
    shape({
      detail: string,
      source: shape({ parameter: string }),
    }),
  ),
}

function DateInput(props) {
  const {
    currentOrganization: {
      attributes: { datetime_fmt_configuration },
    },
  } = useContext(WebBootContext)

  return (
    <ReactDatePicker
      placeholder="Select a date"
      wrapperClassName="w-100%"
      showPopperArrow={false}
      dateFormat={
        datetime_fmt_configuration.dateFirst ? "d/MM/yyyy" : "M/d/yyyy"
      }
      {...props}
    />
  )
}

function TimeInput(props) {
  const {
    currentOrganization: {
      attributes: { datetime_fmt_configuration },
    },
  } = useContext(WebBootContext)

  return (
    <ReactDatePicker
      placeholder="Select a time"
      wrapperClassName="w-100%"
      showPopperArrow={false}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat={datetime_fmt_configuration.hour12 ? "h:mmaaa" : "HH:mm"}
      timeFormat={datetime_fmt_configuration.hour12 ? "h:mmaaa" : "HH:mm"}
      {...props}
    />
  )
}

const Form = {
  DateInput,
  TimeInput,
  FieldErrors: FormErrors,
}

export default Form
