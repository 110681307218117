import PropTypes from "prop-types"
import { cloneElement, Component } from "react"
const { shape, number, instanceOf, object, node } = PropTypes

const latLngProp = shape({ lat: number.isRequired, lng: number.isRequired })

export default class extends Component {
  static propTypes = {
    center: latLngProp.isRequired,
    radius: number.isRequired,
    map: instanceOf(google.maps.Map),
    infoWindow: node,
    options: object,
  }

  static defaultProps = {
    options: {},
  }

  state = { circle: null, isOpen: false }

  componentDidMount() {
    this.clickListener = null
    this.doImperativeWork()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.doImperativeWork(nextProps)
  }

  componentWillUnmount() {
    if (this.state.circle) {
      this.state.circle.setMap(null)
    }
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  // eslint-disable-next-line consistent-return
  doImperativeWork = (props = this.props) => {
    const { circle } = this.state

    if (!circle) {
      return this.createCircle().then(this.doImperativeWork)
    }

    circle.setOptions(this.getMapOptions(props))
    this.clickListener && this.clickListener.remove()
    this.clickListener = circle.addListener("click", this.handleClick)
  }

  createCircle = () => {
    const circle = new google.maps.Circle({ map: this.props.map })
    return new Promise((resolve) => this.setState({ circle }, resolve))
  }

  getMapOptions = (props = this.props) => {
    return {
      center: props.center,
      radius: props.radius,
      strokeColor: "#DA2427",
      strokeWeight: 1,
      fillColor: "#DA2427",
      ...props.options,
    }
  }

  render() {
    const { circle, isOpen } = this.state

    if (!circle || !this.props.infoWindow) return null

    return isOpen
      ? cloneElement(this.props.infoWindow, {
          anchor: circle,
          map: this.props.map,
          onClose: () => this.setState({ isOpen: false }),
        })
      : null
  }
}
