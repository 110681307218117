// import { useState, useEffect } from "react"
import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { DateSelector } from "source/shared/components"

const BirthdateField = ({
  id,
  person,
  existingPerson,
  onChange,
  isRequired,
  error,
  rowLayout,
}) => {
  return (
    <Field
      label="Birthdate"
      isRequired={isRequired}
      error={error}
      rowLayout={rowLayout}
    >
      <DateSelector
        name={`birthdate-${id}`}
        onChange={(value) => onChange("birthdate", value)}
        initialValue={person.birthdate}
        selectStyle={{ height: "32px" }}
        readOnly={!!existingPerson.birthdate}
      />
    </Field>
  )
}

BirthdateField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    birthdate: string,
  }),
  existingPerson: shape({
    birthdate: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
  rowLayout: bool,
}

export default BirthdateField
