import { Helmet } from "react-helmet-async"
import { Navigate, Route, Routes } from "react-router-dom"
import PlanDetails from "./pages/PlanDetails"
import PublicPlanPage from "./pages/PublicPlanPage"
import Blockout from "./pages/Blockout"
import EmailTeamLeaders from "./pages/EmailTeamLeaders"
import AvailableSignups from "./pages/AvailableSignups"

const parseQueryParamId = (queryParamName) => {
  const searchParams = new URLSearchParams(window.location.search)
  const value = searchParams.get(queryParamName)

  return value ? parseInt(value, 10) : undefined
}

const HandleSchedulingRequestEmails = () => {
  const planId = parseQueryParamId("plan_id")
  const serviceTypeId = parseQueryParamId("service_type_id")
  const planViewUrl = `/services/service_types/${serviceTypeId}/plans/${planId}`

  return (
    <Routes>
      <Route
        path="decline/*"
        element={<Navigate to={planViewUrl + "?response=decline"} replace />}
      />
      <Route
        path="accept/*"
        element={<Navigate to={planViewUrl + "?response=accept"} replace />}
      />
      <Route path="respond/*" element={<Navigate to={planViewUrl} replace />} />
      <Route path="view/*" element={<Navigate to={planViewUrl} replace />} />
      <Route
        path="pick_and_choose/*"
        element={<Navigate to="/services" replace />}
      />
    </Routes>
  )
}

export function Services() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/me" replace />} />
        <Route
          path="/service_types/:serviceTypeId/plans/:planId/public"
          element={<PublicPlanPage />}
        />
        <Route
          path="/service_types/:serviceTypeId/plans/after/today/public"
          element={<PublicPlanPage beforeAfter="after" />}
        />
        <Route
          path="/service_types/:serviceTypeId/plans/before/today/public"
          element={<PublicPlanPage beforeAfter="before" />}
        />
        <Route
          path="/service_types/:serviceTypeId/plans/:planId"
          element={<PlanDetails />}
        />
        <Route path="/blockouts/new" element={<Blockout />} />
        <Route
          path="/blockouts/:blockoutId/dates/:blockoutDateId"
          element={<Blockout />}
        />
        <Route path="/email_team_leaders" element={<EmailTeamLeaders />} />
        <Route
          path="/scheduling_request/*"
          element={<HandleSchedulingRequestEmails />}
        />
        <Route
          path="/available_signups/:availableSignupId"
          element={<AvailableSignups />}
        />
        <Route
          path="/blockout_notifications/some"
          element={<Navigate to="/services/blockouts/new" replace />}
        />
        <Route path="*" element={<Navigate to="/services" replace />} />
      </Routes>
      <Helmet title="My Services" />
    </>
  )
}
