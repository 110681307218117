import { buildUrl } from "source/shared/buildUrl"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"

export const useMembershipAttendances = ({
  groupId,
  filter,
  order = "attended_percentage",
  limit = 10,
}) => {
  const url = buildUrl(
    `/groups/v2/me/groups/${groupId}/membership_attendances`,
    {
      per_page: limit.toString(),
      ...(filterParams(filter) || {}),
      include: "person",
      order,
      "fields[Person]": "name,avatar_url",
    },
  )
  const { records, included, hasMore, loadingMore, loadMore } =
    useInfiniteLoadingResource(url)

  return {
    membershipAttendances: records,
    included,
    hasMore,
    loadingMore,
    loadMore,
  }
}

const filterParams = (filter) => {
  if (filter?.type === "date") return { filter: "by_time", after: filter.after }
  if (filter?.type === "event") {
    return { filter: "by_event_count", event_count: filter.count.toString() }
  }
}
