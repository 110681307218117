import { bool, func, number, object, string } from "prop-types"
import { useParams } from "react-router-dom"
import { useState } from "react"
import moment from "moment-timezone"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { AlertMessage } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { Avatar, Spinner, Icon } from "source/shared/components"
import { compact, flatten, isEmpty, pickBy, remove } from "lodash"
import { useSession } from "source/shared/hooks/useSession"
import { useLocalTimeToOrgTime } from "../hooks/useLocalTimeToOrgTime"
import { useCurrentOrganizationTimeZone } from "../hooks/useCurrentOrganizationTimeZone"
import FormHelpers from "./FormHelpers"
import SuccessfulSubmitMessage from "./SuccessfulSubmitMessage"
import { Heading, TextInput } from "@planningcenter/doxy-web"
import pluralize from "source/shared/pluralize"
import { getRelationship } from "source/shared/getRelationship"

import DateField from "./formFields/DateField"
import DropdownField from "./formFields/DropdownField"
import FieldLabel from "./FieldLabel"
import FileField from "./formFields/FileField"
import NumberField from "./formFields/NumberField"
import ParagraphField from "./formFields/ParagraphField"
import SectionHeading from "./formFields/SectionHeading"
import TextField from "./formFields/TextField"
import CheckboxesField from "./formFields/CheckboxesField"
import { useFormFields } from "../hooks/useFormFields"
import fieldHasConditionNotMet from "source/calendar/utils/fieldHasConditionNotMet"
import InfiniteScroll from "react-infinite-scroller"
import PrefilledData, { prefilledDataPropType } from "./PrefilledData"
import ResourceField from "./formFields/ResourceField"
import RoomField from "./formFields/RoomField"

export default function CalendarFormPage() {
  const { formId } = useParams()

  const ensureJson = (data = {}) => {
    try {
      return JSON.parse(data)
    } catch (e) {
      return {}
    }
  }

  const params = new URLSearchParams(window.location.search)
  const prefilledData = ensureJson(params.get("prefill"))

  useSession(true)

  const form = useApiRead(`/calendar/v2/forms/${formId}`)
  const { name, description, status } = form.data.attributes

  return status === "open" ? (
    <CalendarForm {...{ formId, prefilledData, name, description }} />
  ) : (
    <NotAcceptingSubmissions />
  )
}

CalendarForm.propTypes = {
  formId: string,
  prefilledData: prefilledDataPropType,
  name: string,
  description: string,
}

function CalendarForm({ formId, prefilledData = {}, name, description }) {
  const hasPrefilledData = !isEmpty(prefilledData)

  const initialFormValues = {
    ends_at: moment().startOf("hour").add(2, "hour").toISOString(true),
    event_name: "",
    notes: "",
    starts_at: moment().startOf("hour").add(1, "hour").toISOString(true),
  }
  const [attachments, setAttachments] = useState([])
  const [busy, setBusy] = useState(false)
  const [customFieldValues, setCustomFieldValues] = useState({})
  const [errors, setErrors] = useState({})
  const [formValues, setFormValues] = useState(initialFormValues)
  const [successfulSubmission, setSuccessfulSubmission] = useState()

  const {
    formFields = [],
    formFieldConditions,
    formFieldOptions,
    loadMore,
    hasMore,
  } = useFormFields({ formId })
  const shouldShowErrorAlert =
    errors.formSubmissionErrors?.length > 0 ||
    errors.customFieldErrors?.length > 0

  const formFieldsWithOptions = formFields.map((field) => {
    return {
      ...field,
      options: getRelationship(
        { data: field, included: formFieldOptions },
        "options",
      ),
    }
  })

  const charactersRemaining = 512 - formValues.notes.length
  const TOTAL_FILE_SIZE_LIMIT = 1024 * 1024 * 10 * 5

  const asOrgTime = useLocalTimeToOrgTime(true)

  const handleAddAttachment = (attachment) => {
    if (attachment) {
      const newAttachments = attachments.filter(
        (att) => att.form_field_id !== attachment.form_field_id,
      )
      setAttachments([...newAttachments, attachment])
      handleCustomFieldValueChanged("attachments", attachment.form_field_id)
    }
  }

  const handleRemoveAttachment = (formFieldId, fileName) => {
    const attachments = attachments.filter(
      (att) => att.form_field_id !== formFieldId,
    )

    const filesForFormField = attachmentsForField(formFieldId).filter(
      (att) => att.name !== fileName,
    )

    const newAttachments =
      filesForFormField.length > 0
        ? [
            ...attachments,
            { form_field_id: formFieldId, files: filesForFormField },
          ]
        : attachments

    setAttachments(newAttachments)

    if (filesForFormField.length === 0) {
      handleCustomFieldValueChanged("", formFieldId)
    }
  }

  const findHiddenFields = () => {
    const hiddenFields = {}
    formFields.forEach((field) => {
      const isHidden = fieldHasConditionNotMet(
        field,
        formFields,
        formFieldConditions,
        formFieldOptions,
        customFieldValues,
      )
      if (isHidden) {
        hiddenFields[field.id] = true
      }
    })
    return hiddenFields
  }

  const handleCustomFieldValueChanged = (value, id) => {
    let newValues = { ...customFieldValues, [id]: value }
    const hiddenFields = findHiddenFields()

    // Remove any hidden fields from the payload
    Object.keys(hiddenFields).forEach((key) => {
      if (hiddenFields[key]) {
        delete newValues[key]
      }
    })

    // Remove the field if its value is empty and not explicitly set to true
    if (isEmpty(newValues[id]) && newValues[id] !== true) {
      delete newValues[id]
    }

    setCustomFieldValues(newValues)
  }

  const handleChange = ({ name, value }) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }))
  }
  const handleChangeDateTimeRange = ({ starts_at, ends_at }) => {
    handleChange({ name: "starts_at", value: starts_at })
    handleChange({ name: "ends_at", value: ends_at })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setBusy(true)

    const uploads = attachments.map((attachment) =>
      uploadFileAttachment(attachment),
    )
    const uploadedAttachments = await Promise.all(uploads)

    const included = buildIncludedParam(
      trimmedValues(customFieldValues),
      uploadedAttachments,
    )

    const fields = ["event_name", "notes", "starts_at", "ends_at"]

    if (prefilledData?.location) fields.push("location")
    if (prefilledData?.recurrence) fields.push("event_recurrence")

    sessionApiClient
      .post(
        `/calendar/v2/forms/${formId}/submissions?include=form_submission_values&fields[FormSubmission]=${fields}`,
        {
          data: {
            attributes: {
              ...{
                ...formValues,
                ...prefilledData,
                // we want to treat these dates as if they were selected in the org's TZ,
                // regardless of the user's browser TZ.
                starts_at: hasPrefilledData
                  ? asOrgTime(prefilledData.starts_at)
                  : asOrgTime(formValues.starts_at),
                ends_at: hasPrefilledData
                  ? asOrgTime(prefilledData.ends_at)
                  : asOrgTime(formValues.ends_at),
              },
            },
          },
          included,
        },
      )
      .then((response) => {
        setBusy(false)
        setSuccessfulSubmission({
          formSubmission: response.data.attributes,
          groupedCustomFieldValues: response.meta.grouped_custom_field_values,
          included: response.included,
        })
      })
      .catch((response) => {
        setBusy(false)
        const friendlyErrors = humanizeResponseErrors(response?.errors)
        setErrors(friendlyErrors)
        window.scrollTo({ top: 0, behavior: "smooth" })
      })
  }

  const humanizeResponseErrors = (errors) => {
    const formSubmissionErrors = errors.filter(
      (error) => error.source.parameter !== "form_submission_values.value",
    )

    const customFieldErrors = remove(formSubmissionErrors, (error) =>
      error.source.parameter.includes("form_submission_values"),
    )

    const friendlyCustomFieldErrors = customFieldErrors.map((error) => {
      const customFields = error.meta.associated_resources

      const errors = customFields.map((field) => {
        const fieldId = field.relationships.form_field.data.id
        return field.errors.value
          ? {
              detail: field.errors.value,
              source: { parameter: fieldId },
            }
          : null
      })
      return flatten(errors)
    })

    return {
      formSubmissionErrors: formSubmissionErrors,
      customFieldErrors: flatten(friendlyCustomFieldErrors),
    }
  }

  const componentForType = (field) => {
    const {
      id,
      attributes: { field_type },
    } = field

    const hide = fieldHasConditionNotMet(
      field,
      formFields,
      formFieldConditions,
      formFieldOptions,
      customFieldValues,
    )

    if (hide) return null

    switch (field_type) {
      case "checkboxes":
        return (
          <>
            <CheckboxesField
              errors={getErrorsForCustomField(id)}
              onChange={handleCustomFieldValueChanged}
              {...{ field }}
            />
          </>
        )
      case "date":
        return (
          <DateField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "dropdown":
      case "event_template_dropdown":
        return (
          <DropdownField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "file":
        return (
          <FileField
            errors={getErrorsForCustomField(id)}
            maxTotalFileSize={TOTAL_FILE_SIZE_LIMIT}
            onAddFile={handleAddAttachment}
            onRemoveFile={handleRemoveAttachment}
            totalFileSizeExceedsLimit={totalFileSizeExceedsLimit()}
            {...{ field }}
          />
        )
      case "heading":
        return <SectionHeading key={field.id} {...{ field }} />
      case "number":
        return (
          <NumberField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "resource":
        return (
          <ResourceField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "room":
        return (
          <RoomField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "string":
        return (
          <TextField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      case "text":
        return (
          <ParagraphField
            errors={getErrorsForCustomField(id)}
            onChange={handleCustomFieldValueChanged}
            {...{ field }}
          />
        )
      default:
        console.error("field_type isn't handled:", field_type)
        return null
    }
  }

  const totalFileSizeExceedsLimit = () => {
    return (
      attachments
        .flatMap((attachment) => attachment.files)
        .reduce((acc, currentFile) => acc + currentFile.size, 0) >
      TOTAL_FILE_SIZE_LIMIT
    )
  }

  const getErrorsForFormSubmission = (parameter) => {
    return errors?.formSubmissionErrors?.filter(
      (error) => error.source.parameter === parameter,
    )
  }

  const getErrorsForCustomField = (fieldId) => {
    return errors?.customFieldErrors?.filter(
      (error) => error?.source?.parameter === fieldId,
    )
  }

  if (successfulSubmission)
    return (
      <SuccessfulSubmitMessage
        attachments={attachments}
        formId={formId}
        submission={successfulSubmission}
      />
    )

  return (
    <form onSubmit={handleSubmit}>
      {shouldShowErrorAlert && (
        <AlertMessage className="mb-2" type="warning">
          Uh oh! Looks like there were some problems. Please review the form for
          errors and try again.
        </AlertMessage>
      )}

      <Heading level={1} text={name} />
      <p style={{ whiteSpace: "pre-line" }}>{description}</p>

      <UserInfo />

      {hasPrefilledData ? (
        <>
          <PrefilledData {...{ prefilledData }} />
          <Description
            label="Notes"
            placeholder="Any additional information about this event"
            {...{ charactersRemaining, handleChange }}
          />
          <FormHelpers.FieldErrors
            errors={getErrorsForFormSubmission("event_description")}
          />
        </>
      ) : (
        <>
          <div className="mt-3">
            <FieldLabel required>Event Name</FieldLabel>
            <TextInput
              id="event_name"
              onChange={({ target }) => handleChange(target)}
              value={formValues.event_name}
            />
            <FormHelpers.FieldErrors
              errors={getErrorsForFormSubmission("event_name")}
            />
            <p />
          </div>

          <div className="mt-3">
            <DateTimeRange
              startsAt={formValues.starts_at}
              endsAt={formValues.ends_at}
              onChange={handleChangeDateTimeRange}
            />
          </div>

          <Description
            label="Event description"
            required
            {...{ charactersRemaining, handleChange }}
          />
          <FormHelpers.FieldErrors
            errors={getErrorsForFormSubmission("event_description")}
          />
        </>
      )}
      <div className="mt-3">
        <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
          {formFieldsWithOptions.map((field) => (
            <div className="mb-2" key={field.id}>
              {componentForType(field)}
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <div className="my-4 ta-c">
        <button
          type="submit"
          className="btn"
          aria-disabled={busy}
          disabled={busy}
        >
          {busy ? <Spinner /> : <>Submit</>}
        </button>
      </div>
    </form>
  )
}

function UserInfo() {
  const {
    meta: { authenticated },
    data: { attributes: userInfo },
  } = useSession(false)

  return authenticated ? (
    <NameBanner info="My information" {...{ userInfo }} />
  ) : (
    <NameBanner info="Submitting form for" {...{ userInfo }} />
  )
}

NameBanner.propTypes = { info: string.isRequired, userInfo: object.isRequired }
function NameBanner({ info, userInfo }) {
  const { avatar_url, email_address, name } = userInfo

  return (
    <div className="my-4">
      <div className="mb-1">
        <Heading level={2} size={4} text={info} />
      </div>
      <div className="d-f ai-c jc-sb action-drawer">
        <div className="d-f">
          <div className="mr-2">
            <div className="h-6 w-6">
              <Avatar alt={`Profile image of ${name}`} url={avatar_url} />
            </div>
          </div>
          <div className="d-f fd-c">
            <div>{name}</div>
            <div className="fs-13 c-tint2">{email_address}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

DateTimeRange.propTypes = { startsAt: string, endsAt: string, onChange: func }

function DateTimeRange({ startsAt, endsAt, onChange }) {
  const duration = moment(endsAt).diff(moment(startsAt))

  const handleStartsAtChange = (datetime) => {
    if (moment(datetime).isValid()) {
      const newEndsAt = moment(datetime).add(duration)

      setEventTime({
        starts_at: moment(datetime).toISOString(true),
        ends_at: moment(newEndsAt).toISOString(true),
      })
    } else if (!datetime) {
      setEventTime({
        starts_at: moment(startsAt).toISOString(true),
        ends_at: moment(endsAt).toISOString(true),
      })
    }
  }

  const handleEndsAtChange = (datetime) => {
    if (moment(datetime).isValid()) {
      if (moment(datetime).isSameOrBefore(moment(startsAt))) {
        const newStartsAt = moment(datetime).subtract(duration)

        setEventTime({
          starts_at: moment(newStartsAt).toISOString(true),
          ends_at: moment(datetime).toISOString(true),
        })
      } else {
        setEventTime({ ends_at: moment(datetime).toISOString(true) })
      }
    } else if (!datetime) {
      setEventTime({
        starts_at: moment(startsAt).toISOString(true),
        ends_at: moment(endsAt).toISOString(true),
      })
    }
  }

  const handleEndTimeChange = (datetime) => {
    handleEndsAtChange(setTimeOnDate(endsAt, datetime))
  }

  const handleStartTimeChange = (datetime) => {
    handleStartsAtChange(setTimeOnDate(startsAt, datetime))
  }

  const setTimeOnDate = (date, datetime) => {
    const hour = moment(datetime).hour()
    const minutes = moment(datetime).minutes()

    return moment(date).set("hour", hour).set("minute", minutes)
  }

  const setEventTime = (startsOrEndsAt) => {
    let payload = { starts_at: startsAt, ends_at: endsAt }
    payload = { ...payload, ...startsOrEndsAt }

    onChange(payload)
  }

  return (
    <>
      <div className="date-time-range">
        <div className="date-time-range__container">
          <div className="date-time-range__date date-time-range__start-date">
            <label className="label" htmlFor="event_starts_at_date">
              Start date
              <span className="c-ruby"> *</span>
            </label>
            <FormHelpers.DateInput
              id="event_starts_at_date"
              required
              minDate={moment().toDate()}
              selected={moment(startsAt).toDate()}
              onChange={(datetime) => handleStartsAtChange(datetime)}
            />
          </div>
          <div className="date-time-range__time date-time-range__start-time">
            <label className="label" htmlFor="event_starts_at_time">
              Start time (<TimeZoneAbbrev />) <span className="c-ruby"> *</span>
            </label>
            <div className="d-f ai-c">
              <FormHelpers.TimeInput
                id="event_starts_at_time"
                required
                selected={moment(startsAt).toDate()}
                onBlur={(event) =>
                  handleStartTimeChange(
                    moment(event.target.value, ["h:m a", "H:m"]),
                  )
                }
                onChange={(datetime, isTyping) => {
                  if (!isTyping) {
                    handleStartTimeChange(datetime)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="date-time-range__joinder">to</div>
        <div className="date-time-range__container">
          <div className="date-time-range__date date-time-range__end-date">
            <label className="label" htmlFor="event_ends_at">
              End date
              <span className="c-ruby"> *</span>
            </label>
            <FormHelpers.DateInput
              id="event_ends_at"
              required
              minDate={moment(startsAt).toDate()}
              selected={moment(endsAt).toDate()}
              onChange={(datetime) => handleEndsAtChange(datetime)}
            />
          </div>
          <div className="date-time-range__time date-time-range__end-time">
            <label className="label" htmlFor="event_ends_at_time">
              End time (<TimeZoneAbbrev />) <span className="c-ruby"> *</span>
            </label>
            <FormHelpers.TimeInput
              id="event_ends_at_time"
              required
              selected={moment(endsAt).toDate()}
              onBlur={(event) =>
                handleEndTimeChange(
                  moment(event.target.value, ["h:m a", "H:m"]),
                )
              }
              onChange={(datetime, isTyping) => {
                if (!isTyping) {
                  handleEndTimeChange(datetime)
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function Description({
  charactersRemaining,
  handleChange,
  label,
  placeholder = "",
  required = false,
}) {
  return (
    <>
      <div className="mt-3">
        <FieldLabel htmlFor="notes" required={required}>
          {label}
        </FieldLabel>
        <textarea
          id="notes"
          maxLength="512"
          name="notes"
          onChange={({ target }) => handleChange(target)}
          placeholder={placeholder}
          rows="5"
        />
      </div>
      <div className="mt-1 ta-r fs-13 c-tint2">
        {pluralize(charactersRemaining, "character")} remaining
      </div>
    </>
  )
}

Description.propTypes = {
  handleChange: func,
  charactersRemaining: number,
  label: string,
  placeholder: string,
  required: bool,
}

function TimeZoneAbbrev(props) {
  const orgTz = useCurrentOrganizationTimeZone()

  return <span {...props}>{moment.tz(orgTz).format("z")}</span>
}

function NotAcceptingSubmissions() {
  return (
    <div className="d-f ai-c fd-c">
      <Icon
        className="mb-2"
        style={{ fontSize: 32 }}
        symbol="general#x-circle"
      />
      <Heading level={2} text="This form is not accepting submissions" />
    </div>
  )
}

const attachmentsForField = (id) => {
  const attachements = this.state.attachments.find(
    (att) => att.form_field_id === id,
  )
  return attachements ? attachements["files"] : []
}

const buildIncludedParam = (values, uploadedAttachments) => {
  return flatten(
    compact(
      Object.keys(values).map((key) => {
        if (valueIsCheckboxes(values[key])) {
          return includedParamForCheckboxes(values[key], key)
        } else if (valueIsAttachment(values[key])) {
          const attachment = uploadedAttachments.find(
            (attachment) =>
              parseInt(attachment.form_field_id, 10) === parseInt(key, 10),
          )

          return {
            type: "FormSubmissionValue",
            attributes: {
              form_field_id: key,
              value: attachment.files,
            },
          }
        } else {
          // If "other" is blank, return null which will be compacted from the final array
          if (valueIsBlankDropdownOtherOption(values[key])) return null

          return {
            type: "FormSubmissionValue",
            attributes: {
              form_field_id: key,
              value: values[key],
            },
          }
        }
      }),
    ),
  )
}

const includedParamForCheckboxes = (value, key) => {
  let selected = value.selected.map((_k, i) => ({
    type: "FormSubmissionValue",
    attributes: {
      form_field_id: key,
      value: value.selected[i],
    },
  }))

  if (value["other"]) {
    selected = selected.concat([
      {
        type: "FormSubmissionValue",
        attributes: {
          form_field_id: key,
          value: { other: value["other"] },
        },
      },
    ])
  }
  return selected
}

const trimmedValues = (values) =>
  pickBy(values, (value) => !(typeof value === "string" && !value.trim()))

const uploadFileAttachment = async (attachment) => {
  const response = await Promise.all(
    attachment.files.map((file) => sessionApiClient.uploadFile(file)),
  )

  const files = response.map(({ data }) => ({
    fileID: data[0].id,
    fileName: data[0].attributes.name,
  }))

  return {
    form_field_id: attachment.form_field_id,
    files,
  }
}

const valueIsAttachment = (value) => value === "attachments"

const valueIsBlankDropdownOtherOption = (value) =>
  Object.keys(value).includes("other") && value.other.trim() === ""

const valueIsCheckboxes = (value) =>
  value.selected && Array.isArray(value.selected)
