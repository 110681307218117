import { css } from "@emotion/react"
import { colors } from "source/shared/colors"
import { Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { NarrowLayout } from "source/Layout"

export function Error403() {
  return (
    <NarrowLayout>
      <div className="ta-c">
        <div css={styles.iconCircleBackground}>
          <div css={styles.icon}>
            <Icon symbol="general#lock" aria-label="forbidden" />
          </div>
        </div>
        <Heading level={1} text="You don’t have access" />
      </div>
    </NarrowLayout>
  )
}

const styles = {
  iconCircleBackground: css`
    background-image: linear-gradient(
      135deg,
      ${colors.brandGradientStart} 0%,
      ${colors.brandGradientEnd} 100%
    );
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: center;
    width: 64px;

    &:before {
      background-color: white;
      border-radius: 100%;
      content: "";
      height: 58px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 58px;
    }
  `,
  icon: css`
    color: ${colors.brand};
    font-size: 32px;
    position: relative;
  `,
}
