import { useContext } from "react"
import { func } from "source/shared/prop_types"
import { Icon } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { useNavigate } from "react-router-dom"
import { CurrentGroupContext } from "source/groups/my/groups"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"

export default function TopicMenu({ toggleRepliesEnabled }) {
  const navigate = useNavigate()
  const { base_path } = useContext(CurrentGroupContext)
  const { del: removeLocalTopic, currentTopic } = useContext(TopicsDataContext)

  const { replies_enabled } = currentTopic.attributes
  const canEdit = currentTopic.attributes.abilities.can_update
  const canDelete = currentTopic.attributes.abilities.can_delete

  function handleEditTopic() {
    navigate(`${base_path}/messages/${currentTopic.id}/edit`)
  }

  function handleDelete() {
    if (confirm("Are you sure you want to remove this message?")) {
      sessionApiClient
        .del(currentTopic.links.self)
        .then(() => removeLocalTopic(currentTopic.id))
        .then(() => navigate(`${base_path}/messages`))
    }
  }

  return (
    <Menu>
      <MenuButton
        style={{ marginLeft: "auto" }}
        className="minor-btn secondary-btn btn fsh-0"
      >
        Options
        <span className="fs-6 pl-4p dropdown-trigger__icon">
          <Icon symbol="general#down-chevron" />
        </span>
      </MenuButton>
      <MenuList>
        {canEdit && <MenuItem onSelect={handleEditTopic}>Edit</MenuItem>}
        {canDelete && <MenuItem onSelect={handleDelete}>Delete</MenuItem>}
        {canEdit && (
          <MenuItem onSelect={toggleRepliesEnabled}>
            {replies_enabled ? "Disable" : "Enable"} replies
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

TopicMenu.propTypes = {
  toggleRepliesEnabled: func.isRequired,
}
