import { useState } from "react"
import { bool, func, oneOf, shape, string } from "prop-types"
import { Icon, Loading, AlertMessage } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Subheading } from "@planningcenter/doxy-web"

import { css } from "@emotion/react"

HouseholdAvatarSelect.propTypes = {
  household: shape({
    attributes: shape({
      name: string.isRequired,
      avatar_url: string.isRequired,
      using_default_avatar: bool.isRequired,
    }).isRequired,
  }).isRequired,
}

const DEFAULT_AVATAR_URL =
  "http://first.churchcenter.test/static/no-household-image.png"

export function HouseholdAvatarSelect({ household }) {
  const [avatarUrl, setAvatarUrl] = useState(household.attributes.avatar_url)
  const [usingDefaultAvatar, setUsingDefaultAvatar] = useState(
    household.attributes.using_default_avatar,
  )

  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState()
  const householdAvatarEdit = css`
    img {
      width: 220px;
      height: 220px;
      border-radius: 50%;
    }
  `
  function wrapRequestWithLocalStateHandling(request) {
    setUpdating(true)
    setError()
    return request()
      .then(
        ({
          data: {
            attributes: { avatar_url },
          },
        }) => {
          setAvatarUrl(avatar_url)
        },
      )
      .catch((e) => {
        setError(e)
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  function updateAvatar(avatarId) {
    return sessionApiClient.patch(`/people/v2/me/households/${household.id}`, {
      data: { attributes: { avatar: avatarId } },
    })
  }

  async function handleChange(e) {
    wrapRequestWithLocalStateHandling(() =>
      sessionApiClient
        .uploadFile(e.target.files[0])
        .then(({ data }) => updateAvatar(data[0].id)),
    ).then(() => setUsingDefaultAvatar(false))
  }

  function handleRemoveAvatar() {
    wrapRequestWithLocalStateHandling(() => updateAvatar(null)).then(() =>
      setUsingDefaultAvatar(true),
    )
  }

  return (
    <>
      <div className="mb-1">
        <Subheading level={2} text="Household photo" />
      </div>
      <div className="p-r d-f fd-cr ai-c my-3" css={householdAvatarEdit}>
        {updating ? (
          <div className="px-2 py-1 w-100%">
            <Loading message="Loading" />
          </div>
        ) : (
          <HouseholdAvatarFileInput
            hasCurrentAvatar={!usingDefaultAvatar}
            id={`household-avatar-upload-${household.id}`}
            onChange={handleChange}
            onRemove={handleRemoveAvatar}
          />
        )}

        <HouseholdAvatar url={avatarUrl} alt={household.attributes.name} />
      </div>
      {error && (
        <AlertMessage>
          Uh oh! Something went wrong.{" "}
          {error.errors.map((e) => e.detail).join(" ") ||
            "Please try saving again."}
        </AlertMessage>
      )}
    </>
  )
}

HouseholdAvatar.propTypes = {
  url: string,
  alt: string,
  size: oneOf(["thumbnail", "square", "large"]),
}
HouseholdAvatar.defaultProps = { size: "square" }
export function HouseholdAvatar({ url, alt, size }) {
  const src = buildHouseholdAvatarUrl({ url, size })

  return <img src={src} alt={alt} />
}

const AVATAR_SIZES = {
  thumbnail: "64x64",
  square: "244x244",
  large: "700x525",
}

function buildHouseholdAvatarUrl({ url, size }) {
  if (!url) return DEFAULT_AVATAR_URL

  const dimensionsKey = AVATAR_SIZES[size] || AVATAR_SIZES.square
  const resizeAndCropKey = "%23"

  return `${url}?g=${dimensionsKey}${resizeAndCropKey}`
}

HouseholdAvatarFileInput.propTypes = {
  hasCurrentAvatar: bool.isRequired,
  id: string.isRequired,
  onChange: func.isRequired,
  onRemove: func.isRequired,
}

function HouseholdAvatarFileInput({
  hasCurrentAvatar,
  id,
  onChange,
  onRemove,
}) {
  return (
    <>
      {hasCurrentAvatar ? (
        <div className="py-2 d-f jc-sb">
          <button
            className="destroy-btn minor-compact-btn btn"
            onClick={onRemove}
          >
            <span className="mr-4p fs-6" aria-hidden="true">
              <Icon symbol="general#x" />
            </span>
            Remove photo
          </button>
          <label htmlFor={id} className="minor-compact-btn btn ml-2">
            <span className="mr-4p fs-6" aria-hidden="true">
              <Icon symbol="general#pencil" />
            </span>
            Edit photo
          </label>
        </div>
      ) : (
        <div className="pt-1 d-f">
          <label htmlFor={id} className="btn secondary-btn compact-btn">
            <div style={{ fontSize: 9, marginRight: 4 }}>
              <Icon symbol="general#plus" aria-hidden />
            </div>
            Add household photo
          </label>
        </div>
      )}
      <input
        id={id}
        type="file"
        accept="image/gif, image/jpeg, image/png"
        onChange={onChange}
        style={{ display: "none" }}
      />
    </>
  )
}
