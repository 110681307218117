import GalleryEvent from "./GalleryEvent"
import { LimitedInfiniteScroll } from "source/shared/components"
import { func, bool, object, arrayOf } from "prop-types"

const FeaturedEvents = ({ featuredEvents, loadMore, hasMore, loading }) => {
  return (
    <>
      {featuredEvents.map((event) => (
        <GalleryEvent
          key={event.id}
          {...{
            event,
            layout: "row",
          }}
        />
      ))}
      <div className="d-f jc-c">
        <LimitedInfiniteScroll.LoadingIndicator
          loadingMore={loading}
          hasMore={hasMore}
          loadMoreButtonText={"Load More"}
          loadMore={loadMore}
        />
      </div>
    </>
  )
}

FeaturedEvents.propTypes = {
  featuredEvents: arrayOf(object),
  loadMore: func,
  hasMore: bool,
  loading: bool,
}

export default FeaturedEvents
