import queryString from "query-string"
import { useQueryParamsState } from "./useQueryParamsState"

export const OPEN_GROUPS_ENROLLMENT_STATUS = "open_signup,request_to_join"
export const ALL_GROUPS_ENROLLMENT_STATUS = "open_signup,request_to_join,closed"

export function useTranslatedQueryParamsState() {
  return useQueryParamsState(translateLegacyParams())
}

function translateLegacyParams() {
  const parsed = queryString.parse(window.location.search, {
    arrayFormat: "bracket",
    parseBooleans: true,
  })
  const defaultQuery = {
    "where[name]": "",
    filter: "enrollment",
    enrollment: OPEN_GROUPS_ENROLLMENT_STATUS,
    tag_id: "",
  }

  return Object.entries(parsed).reduce((translated, [param, value]) => {
    switch (param) {
      case "enrollment_status":
        return {
          ...translated,
          enrollment:
            value === "any"
              ? ALL_GROUPS_ENROLLMENT_STATUS
              : OPEN_GROUPS_ENROLLMENT_STATUS,
        }
      case "tags":
        return {
          ...translated,
          filter: "enrollment,tag",
          tag_id: value.join(","),
        }
      case "name":
        return {
          ...translated,
          ["where[name]"]: value,
        }
      default:
        return translated
    }
  }, defaultQuery)
}
