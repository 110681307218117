import { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import { useSession } from "source/shared/hooks/useSession"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  CurrentGroupContext,
  CurrentEventContext,
} from "source/groups/my/groups"
import { Avatar, Icon, Loading } from "source/shared/components"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import spinner from "source/svg/spinner.svg"
import { get as dig } from "lodash"
import { css } from "@emotion/react"
import { colors } from "source/shared/colors"
import RsvpIcon from "source/groups/my/groups/events/RsvpIcon"
import { Subheading } from "@planningcenter/doxy-web"

const table = css`
  border: 0;
  font-size: 0.875rem;
  width: 100%;
  td {
    background-color: ${colors.tint9};
    border-bottom: 8px solid ${colors.tint10};
    padding: 0.5rem;
  }
  td:first-child {
    border-radius: 4px 0 0 4px;
  }
  td:last-child {
    border-radius: 0 4px 4px 0;
  }
`

export default function RsvpsTable() {
  const { event } = useContext(CurrentEventContext)
  const {
    records: rsvps,
    hasMore,
    loadMore,
  } = useInfiniteLoadingResource(`${event.links.rsvps}?order=name`)
  const [globalEditing, setGlobalEditing] = useState(false)

  const { data: currentPerson } = useSession(true)

  function isMyRsvp(rsvp) {
    return dig(rsvp, "relationships.person.data.id") === currentPerson.id
  }

  if (!rsvps.length) return null
  if (rsvps.length === 1 && isMyRsvp(rsvps[0])) return null

  return (
    <div className="mb-3">
      <div className="d-f ai-c jc-sb mb-1">
        <Subheading level={2} text="Attendees" />
        <button
          className="minor-btn secondary-btn btn"
          aria-label="Edit RSVP responses for attendees"
          onClick={() => setGlobalEditing(!globalEditing)}
        >
          Edit
        </button>
      </div>

      <div className="info-alert alert p-1 mb-1">
        Editing a member&apos;s response will notify them
      </div>
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        loader={<Loading key="loading" />}
      >
        <table css={table} cellPadding="0" cellSpacing="0">
          <tbody>
            {rsvps.map((r) => (
              <RsvpRow key={r.id} rsvp={r} forceEditing={globalEditing} />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  )
}

function RsvpRow({ rsvp, forceEditing }) {
  const { data: currentPerson } = useSession(true)
  const [response, setResponse] = useState(rsvp.attributes.response)
  const [status, setStatus] = useState("")

  useEffect(() => {
    forceEditing ? setStatus("editing") : setStatus("")
  }, [forceEditing])

  if (dig(rsvp, "relationships.person.data.id") === currentPerson.id)
    return null

  return (
    <tr
      onClick={(e) => {
        if (e.target.tagName === "OPTION") return
        if (e.target.tagName === "SELECT") return
        if (status === "inFlight") return

        setStatus(`${status === "editing" ? "" : "editing"}`)
      }}
    >
      <td>
        <RsvpPerson
          avatarUrl={rsvp.attributes.avatar_url}
          name={rsvp.attributes.name}
        />
      </td>
      <td>
        {status === "editing" || status === "inFlight" ? (
          <RsvpEditor
            id={rsvp.id}
            link={rsvp.links.self}
            response={response}
            setResponse={setResponse}
            status={status}
            setStatus={setStatus}
            closeOnChange={!forceEditing}
          />
        ) : (
          <RsvpStatus response={response} />
        )}
      </td>
    </tr>
  )
}
RsvpRow.propTypes = {
  rsvp: PropTypes.object.isRequired,
  forceEditing: PropTypes.bool.isRequired,
}

function RsvpPerson({ avatarUrl, name }) {
  return (
    <div className="d-f ai-c">
      <div className="w-4 h-4 mr-1">
        <Avatar url={avatarUrl} alt={`profile image for ${name}`} />
      </div>
      <span>{name}</span>
    </div>
  )
}
RsvpPerson.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

function RsvpStatus({ response }) {
  const { rsvpResponseAttributes } = useContext(CurrentGroupContext)
  const rsvpStyles = rsvpResponseAttributes.find((opt) => opt.id === response)

  return (
    <div className="d-f ai-c" css={{ width: 210 }}>
      <RsvpIcon
        iconColor={rsvpStyles.attributes.ccw_color}
        iconSymbol={rsvpStyles.attributes.icon}
      />
      {rsvpStyles.attributes.labels.response}
    </div>
  )
}
RsvpStatus.propTypes = {
  response: PropTypes.string.isRequired,
}

function RsvpEditor({
  id,
  link,
  response,
  setResponse,
  status,
  setStatus,
  closeOnChange,
}) {
  const { rsvpResponseAttributes } = useContext(CurrentGroupContext)
  const [selectedResponse, setSelectedResponse] = useState(response)
  const handleChange = (val) => {
    if (response === val) return

    setSelectedResponse(val)
    setStatus("inFlight")

    sessionApiClient
      .patch(link, {
        data: { attributes: { response: val } },
      })
      .then((json) => {
        setResponse(json.data.attributes.response)
        closeOnChange ? setStatus("") : setStatus("editing")
      })
      .catch(() => {
        setSelectedResponse(response)
        setStatus("editing")
        alert("Oops, something went wrong. Please try again.")
      })
  }

  return (
    <div className="d-f">
      <label className="screen-reader-text" htmlFor={`rsvp_${id}`}>
        Change RSVP Response
      </label>
      <select
        id={id}
        disabled={status === "inFlight"}
        className="select w-a h-4"
        value={selectedResponse}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleChange(e.target.value)}
      >
        {rsvpResponseAttributes
          .filter((opt) => opt.id !== "not_sent")
          .map((opt) => (
            <option key={opt.id} value={opt.id}>
              {opt.attributes.labels.third_person}
            </option>
          ))}
      </select>
      <button
        className="btn minor-compact-btn ml-1"
        onClick={() => status !== "inFlight" && setStatus("")}
      >
        {status === "inFlight" ? (
          <img
            src={spinner}
            alt="Saving"
            style={{ height: "1em", width: "1em" }}
          />
        ) : (
          <Icon symbol="general#x" aria-label="Cancel RSVP response edit" />
        )}
      </button>
    </div>
  )
}
RsvpEditor.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  response: PropTypes.string.isRequired,
  setResponse: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  closeOnChange: PropTypes.bool.isRequired,
}
