import { useState } from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"
import { TextInput, Select } from "@planningcenter/doxy-web"

import CustomField from "./CustomField"

DropdownField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      required: bool.isRequired,
      settings: shape({
        other: bool,
      }),
    }),
    id: string.isRequired,
    options: arrayOf(
      shape({
        attributes: shape({
          label: string.isRequired,
          sequence: number.isRequired,
        }),
        id: string.isRequired,
      }),
    ),
  }),
  onChange: func.isRequired,
}

export default function DropdownField({ errors, field, onChange }) {
  const [selected, setSelected] = useState()
  const {
    attributes: { description, label, required, settings },
    id,
    options,
  } = field
  const inputId = `field_${id}_value`
  const sortedOptions = options.sort((a, b) => a.sequence - b.sequence)
  const hasOtherOption = settings && settings.other

  const handleChangeSelected = ({ target: { value } }) => {
    setSelected(value)
    onChange(value, id)
  }

  const handleOtherChange = ({ target: { value } }) => {
    onChange({ other: value }, id)
  }

  return (
    <CustomField {...{ description, errors, label, required }}>
      <Select
        id={inputId}
        onBlur={handleChangeSelected}
        onChange={handleChangeSelected}
        value={selected || ""}
      >
        <Select.Option
          disabled={required}
          label={required ? "Select an option" : ""}
          value=""
        />
        {sortedOptions.map(({ attributes: { label }, id: optionId }) => (
          <Select.Option
            key={`field_${id}_option_${optionId}`}
            label={label}
            value={optionId}
          />
        ))}
        {hasOtherOption && (
          <Select.Option key="other" label="Other" value="other" />
        )}
      </Select>
      {selected === "other" && (
        <div className="mt-2">
          <TextInput
            id={`field_${id}_other_value`}
            placeholder="Other"
            onChange={(e) => handleOtherChange(e)}
          />
        </div>
      )}
    </CustomField>
  )
}
