import { number, string, object, func, oneOfType, bool } from "prop-types"
import NameField from "./NameField"
import EmailField from "./EmailField"
import PhoneField from "./PhoneField"
import AddressField from "./AddressField"
import GenderField from "./GenderField"
import GradeField from "./GradeField"
import BirthdateField from "./BirthdateField"
import EmergencyContactField from "./EmergencyContactField"
import MedicalNotesField from "./MedicalNotesField"

const PersonInfoField = ({
  id,
  field,
  rowLayout = false,
  attendee = {},
  reservation = {},
  person = {},
  existingPerson = {},
  onChange = () => null,
  onCancel = () => null,
  onApplyEmergencyContactToAll = () => null,
  requirement = "hidden",
  error = null,
}) => {
  if (requirement === "hidden") return null

  const props = {
    id,
    attendee,
    reservation,
    person,
    existingPerson,
    field,
    rowLayout,
    onChange,
    onCancel,
    onApplyEmergencyContactToAll,
    error,
    isRequired: requirement === "required",
    isOptional: requirement === "optional",
  }

  const useGradeField = !person.id || (person.id && person.child)

  const Components = Object.freeze({
    name: <NameField {...props} />,
    emailAddress: <EmailField {...props} />,
    phoneNumber: <PhoneField {...props} />,
    address: <AddressField {...props} />,
    gender: <GenderField {...props} />,
    grade: useGradeField ? <GradeField {...props} /> : null,
    birthdate: <BirthdateField {...props} />,
    emergencyContact: <EmergencyContactField {...props} />,
    medical: <MedicalNotesField {...props} />,
  })

  return Components[field] || null
}

PersonInfoField.propTypes = {
  id: oneOfType([string, number]),
  field: string,
  rowLayout: bool,
  attendee: object,
  reservation: object,
  person: object,
  existingPerson: object,
  onChange: func,
  onCancel: func,
  onApplyEmergencyContactToAll: func,
  requirement: string,
  error: string,
}

export default PersonInfoField
