import { string } from "prop-types"

const Expired = ({ eventName }) => {
  return (
    <>
      <div
        className="status-bar action-drawer"
        css={{
          margin: "-1.5rem -1.5rem 1.5rem -1.5rem",
          "@media (min-width: 720px)": { margin: "-2rem -2rem 2rem -2rem" },
        }}
      >
        <div className="container container--wide d-f ai-c jc-sb">
          <div className="fs-4 c-tint2 pr-2 lh-1.5">
            <span className="fw-500">{eventName}</span>
          </div>

          <div>
            <div className="ta-r ta-r fs-5 c-tint2">Expired</div>
          </div>
        </div>
      </div>

      <div className="container container--narrow">
        <div className="error-alert alert">
          Your registration for {eventName} has expired.
        </div>
        <div className="ta-c mt-3">
          <a href="new" className="btn">
            Start a new registration
          </a>
        </div>
      </div>
    </>
  )
}

Expired.propTypes = {
  eventName: string.isRequired,
}

export default Expired
