// export { default } from "./MyRegistrations"

import MyRegistrationsComponent from "./MyRegistrations"
import PaginatedRegistrations from "./PaginatedRegistrations"

const MyRegistrations = Object.assign(MyRegistrationsComponent, {
  PaginatedRegistrations,
})

export default MyRegistrations
