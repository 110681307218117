import { get } from "lodash"
import { useContext } from "react"
import { WebBootContext } from "source/publishing/WebBoot"

export function useCurrentOrganizationTimeZone() {
  const { currentOrganization } = useContext(WebBootContext)

  return get(
    currentOrganization,
    "attributes.datetime_fmt_configuration.timeZone",
    "America/Los_Angeles",
  )
}
