import { useContext, useState } from "react"
import { capitalize, get, some } from "lodash"
import { datetime as formatDateTime } from "@churchcenter/datetime-fmt"
import { Heading } from "@planningcenter/doxy-web"
import { array, bool, func, object } from "prop-types"
import { Icon, AlertMessage } from "source/shared/components"
import { WebBootContext } from "source/publishing/WebBoot"

export default function SignupSheet({
  isSelected,
  onHandleSelectSignupSheet,
  signupSheet: {
    conflicts,
    display_times: displayTimes,
    id: signupSheetId,
    position_name: positionName,
    sort_index: sortIndex,
    title,
    team_name: teamName,
    times,
  },
}) {
  const [isShowingMultipleTimes, setIsShowingMultipleTimes] = useState(false)
  const showTitle = sortIndex === 0

  const handleToggleShowingMultipleTimes = () => {
    setIsShowingMultipleTimes(!isShowingMultipleTimes)
  }

  return (
    <>
      {showTitle && (
        <div className="mb-3">
          <Heading level={2} size={3} text={title} color="tint1" />
        </div>
      )}

      <div className="action-drawer mb-3">
        <div className="d-f ai-fs jc-sb fw-w">
          <div>
            <input
              id={signupSheetId}
              type="checkbox"
              className="checkbox"
              checked={isSelected}
              onChange={onHandleSelectSignupSheet}
            />

            <label
              htmlFor={signupSheetId}
              className="checkbox-label"
              css={{
                "&:before": { top: "2px !important" },
                "&:after": { top: "5px !important" },
              }}
            >
              <span>{positionName}</span>
              <div className="fs-4 pt-4p c-tint2">{`${teamName}${
                displayTimes ? ` - ${displayTimes}` : ""
              }`}</div>
            </label>
          </div>

          {times.length > 1 ? (
            <button
              onClick={handleToggleShowingMultipleTimes}
              className="minor-compact-btn btn"
              css={{ position: "relative", top: "5px" }}
            >
              View times
              <Icon
                symbol="general#down-chevron"
                aria-label="Expand times"
                className="ml-4p fs-4"
                css={{
                  transform: isShowingMultipleTimes ? "rotate(180deg)" : "none",
                  transition: "transform 0.1s",
                }}
              />
            </button>
          ) : (
            <div className="ta-r@sm w-100% w-a@sm mt-1 mt-0@sm pl-3 pl-0@sm">
              <TimeSection times={times} />
            </div>
          )}
        </div>

        <ConflictsSection conflicts={conflicts} />

        {isShowingMultipleTimes && (
          <div className="mt-1 pl-3">
            <TimeSection times={times} />
          </div>
        )}
      </div>
    </>
  )
}

function ConflictsSection({ conflicts }) {
  return some(conflicts) ? (
    <AlertMessage type="warning" className="p-1 mt-1 ml-3 mb-0">
      {conflicts.map((conflict, index) => (
        <div key={index} className="d-f ai-fs">
          <span className="mr-1 p-r t-2p c-citrine">
            <Icon symbol="general#exclamation-circle" />
          </span>
          <span>{conflict}</span>
        </div>
      ))}
    </AlertMessage>
  ) : null
}

function TimeSection({ times }) {
  const { currentOrganization } = useContext(WebBootContext)
  const dateTimeConfiguration = get(
    currentOrganization,
    "attributes.datetime_fmt_configuration",
  )

  return times.map((time, index) => {
    const { time_name: timeName, time_type: timeType } = time

    const dateString = `${formatDateTime(time.starts_at, {
      style: "relative",
      showTimeZone: false,
      ...dateTimeConfiguration,
    })}`

    return (
      <div className={`fs-4 ${times.length > 1 ? "mb-1" : ""}`} key={index}>
        <div>{dateString}</div>
        <div className="c-tint2">
          {timeName ? capitalize(timeName) : capitalize(timeType)}
        </div>
      </div>
    )
  })
}

ConflictsSection.propTypes = {
  conflicts: array,
}

SignupSheet.propTypes = {
  isSelected: bool,
  onHandleOpenInfoModal: func,
  onHandleSelectSignupSheet: func,
  signupSheet: object,
}
