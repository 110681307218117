import { Avatar } from "source/shared/components"
import { array, func, object } from "prop-types"
import { css } from "@emotion/react"

export default function TeamLeaderCheckbox({
  teamLeader,
  selectedTeamLeaderIds,
  onSelectTeamLeader,
}) {
  const { personId, personName, photoUrl } = teamLeader

  const checkboxLabel = css`
    &:before {
      top: 9px;
    }
    &:after {
      top: 12px;
    }
  `

  return (
    <div className="mb-1">
      <input
        id={personId}
        type="checkbox"
        className="checkbox"
        checked={selectedTeamLeaderIds.includes(personId)}
        onChange={() => onSelectTeamLeader(personId)}
      />

      <label
        htmlFor={personId}
        className="checkbox-label d-if ai-c"
        css={checkboxLabel}
      >
        <div className="w-4 h-4 mr-1">
          <Avatar url={photoUrl} />
        </div>
        <div>
          <span>{personName}</span>
        </div>
      </label>
    </div>
  )
}

TeamLeaderCheckbox.propTypes = {
  onSelectTeamLeader: func,
  selectedTeamLeaderIds: array,
  teamLeader: object,
}
