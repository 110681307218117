import _ from "lodash"
import moment from "moment-timezone"
import {
  date as formatDate,
  time as formatTime,
} from "@churchcenter/datetime-fmt"
import {
  useApiRead,
  useApiReadMultiple,
} from "source/shared/SessionApiResource"
import { CONFIRMED_STATUS } from "source/services/constants"
import { useCurrentOrganizationTimeZone } from "source/services/hooks/useCurrentOrganizationTimeZone"

const PLAN_FIELDS = ["planning_center_url", "rehearsable"]
const PLAN_NOTE_FIELDS = ["category_name", "content"]
const PLAN_TIME_FIELDS = ["ends_at", "name", "starts_at", "time_type"]
const SCHEDULE_FIELDS = [
  "can_accept_partial",
  "can_accept_partial_one_time",
  "dates",
  "scheduled_plan_times",
  "service_type_name",
  "short_dates",
  "status",
  "team",
  "team_name",
  "team_position_name",
  "times",
]

export function usePlanDetails({ serviceTypeId, planId }) {
  const { data, included } = useSchedule({ planId })
  const plan = usePlan({ serviceTypeId, planId })
  const teamIds = _.map(data, ({ relationships }) => relationships.team.data.id)
  const planNotes = usePlanNotes({ serviceTypeId, planId, teamIds })
  const planAttributes = _.get(plan, "data.attributes", {})
  const { dates, service_type_name, short_dates } = data[0].attributes
  const { rehearsable = false, planning_center_url } = planAttributes
  const scheduleInCurrentYear = dates.includes(new Date().getFullYear())
  const timeZone = useCurrentOrganizationTimeZone()

  return {
    planNotes: buildPlanNotes(planNotes),
    rehearsable,
    schedules: buildSchedules(data, included),
    serviceTypeName: service_type_name,
    groupedTimes: buildGroupedTimes(included, timeZone),
    title: scheduleInCurrentYear ? short_dates : dates,
    url: planning_center_url,
  }
}

function buildPlanNotes(teamPlanNotes) {
  return _(teamPlanNotes)
    .flatMap(({ data }) =>
      _.map(data, ({ id, attributes }) => ({
        categoryName: attributes.category_name,
        content: attributes.content,
        id,
      })),
    )
    .uniqBy("id")
    .groupBy("categoryName")
    .value()
}

function buildSchedules(data, included) {
  const schedules = data.map(({ id, attributes: s, relationships }) => {
    const timeIds = relationships.scheduled_plan_times.data.map((t) => t.id)

    return {
      id,
      splitTeam: s.can_accept_partial || s.can_accept_partial_one_time,
      status: s.status,
      timeIds,
      serviceTimes: buildServiceTimes(included, timeIds),
      displayName: s.team_position_name
        ? `${s.team_position_name} (${s.team_name})`
        : s.team_name,
    }
  })

  return _.sortBy(schedules, (s) => s.status === CONFIRMED_STATUS)
}

function buildServiceTimes(included, scheduledTimeIds) {
  const times = included
    .filter(
      (incl) =>
        incl.type === "PlanTime" &&
        incl.attributes.time_type === "service" &&
        scheduledTimeIds.includes(incl.id),
    )
    .map((t) => ({ id: t.id, starts_at: t.attributes.starts_at }))

  return _.sortBy(times, "starts_at")
}

function buildGroupedTimes(included, timeZone) {
  const times = included
    .filter((incl) => incl.type === "PlanTime")
    .map(({ attributes, id }) => {
      const { ends_at, name, starts_at, time_type } = attributes
      const time = formatTime(starts_at, ends_at, { showTimeZone: false })
      const displayName = `${time} ${name || _.capitalize(time_type)}`

      return { id, displayName, starts_at }
    })

  return _(times)
    .sortBy("starts_at")
    .groupBy((time) => {
      const startsAt = moment(time.starts_at).tz(timeZone)

      return formatDate(startsAt.startOf("day"), {
        year: startsAt.year() !== new Date().getFullYear(),
      })
    })
    .value()
}

function usePlan({ serviceTypeId, planId }) {
  return useApiRead(
    `/services/v2/service_types/${serviceTypeId}/plans/${planId}?fields[Plan]=${PLAN_FIELDS}`,
  )
}

function usePlanNotes({ serviceTypeId, planId, teamIds }) {
  return useApiReadMultiple(
    ..._.map(
      teamIds,
      (teamId) =>
        `/services/v2/service_types/${serviceTypeId}/plans/${planId}/notes?filter=team&team_id=${teamId}*fields[PlanNote]=${PLAN_NOTE_FIELDS}`,
    ),
  )
}

function useSchedule({ planId }) {
  return useApiRead(
    `/services/v2/me/schedules?per_page=100&where[plan_id]=${planId}&include=scheduled_plan_times&filter=future&fields[Schedule]=${SCHEDULE_FIELDS}&fields[PlanTime]=${PLAN_TIME_FIELDS}`,
  )
}
