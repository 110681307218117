import { useEffect } from "react"
import moment from "moment"
import { useApiRead } from "source/shared/SessionApiResource"
import {
  SET_ATTRIBUTES,
  SET_REPEATING_ATTRIBUTES,
} from "../reducers/eventFormReducer"

export const useMeetingScheduleDefaults = ({
  groupId,
  dispatch,
  skip = false,
}) => {
  const { data: defaultMeetingSchedule } = useApiRead(
    `/groups/v2/me/groups/${groupId}/meeting_schedule`,
  )

  useEffect(() => {
    if (!skip && defaultMeetingSchedule?.attributes) {
      const {
        attributes: {
          next_occurrence_starts_at,
          next_occurrence_ends_at,
          frequency,
        },
      } = defaultMeetingSchedule

      dispatch({
        type: SET_ATTRIBUTES,
        payload: {
          starts_at: moment(next_occurrence_starts_at).toISOString(true),
          ends_at: moment(next_occurrence_ends_at).toISOString(true),
        },
      })

      if (frequency?.name) {
        dispatch({
          type: SET_REPEATING_ATTRIBUTES,
          payload: {
            frequency_name: frequency?.name,
            last_occurrence_date: moment().add(3, "months").toISOString(true),
            week_count: frequency?.week_count?.map((c) => parseInt(c)),
          },
        })
      }
    }
  }, [defaultMeetingSchedule, skip])
}
