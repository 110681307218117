import { string } from "prop-types"
import { AlertMessage, Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

const Notification = ({ email }) => {
  return (
    <section>
      <AlertMessage type="success" className="pt-3 pb-2 mb-3">
        <div className="ta-c">
          <Icon
            symbol="general#check"
            style={{ color: "var(--color-emerald)" }}
            className="fs-1"
            aria-hidden
          />
          <div className="pt-4p mb-1">
            <Heading level={1} size={4} text="Thanks for registering!" />
          </div>
          <p>
            A confirmation has been sent to {email}.<br />
            Your registration details are below.
          </p>
        </div>
      </AlertMessage>
    </section>
  )
}

Notification.propTypes = {
  email: string,
}

export default Notification
