import { array, bool, string } from "prop-types"
import { uniqBy, countBy } from "lodash"
import ReviewAttendee from "./ReviewAttendee"

const ReviewAttendees = ({
  attendees: allAttendees,
  addOns,
  isAddOnsEnabled,
  hasNamedAttendees,
  hasTotalDue,
  forWaitlist = false,
  totalDiscountString,
}) => {
  if (allAttendees.length === 0) return null

  const attendeeCounts = hasNamedAttendees
    ? {}
    : countBy(allAttendees, "attendeeTypeId")

  const attendees = hasNamedAttendees
    ? allAttendees
    : uniqBy(
        allAttendees.sort((a, b) => b.id - a.id),
        "attendeeTypeId",
      )

  return (
    <>
      {attendees.map((attendee) => (
        <ReviewAttendee
          key={attendee.id}
          attendee={attendee}
          addOns={addOns}
          isAddOnsEnabled={isAddOnsEnabled}
          hasTotalDue={hasTotalDue}
          count={attendeeCounts[attendee.attendeeTypeId]}
          hasNamedAttendees={hasNamedAttendees}
          forWaitlist={forWaitlist}
          totalDiscountString={totalDiscountString}
        />
      ))}
    </>
  )
}

ReviewAttendees.propTypes = {
  attendees: array,
  addOns: array,
  isAddOnsEnabled: bool,
  hasNamedAttendees: bool,
  hasTotalDue: bool,
  forWaitlist: bool,
  totalDiscountString: string,
}

export default ReviewAttendees
