import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, number, string } from "prop-types"
import ScheduleItem from "source/myChurchCenter/Schedule/ScheduleItem"
import { Icon } from "source/shared/components"

Blockout.propTypes = {
  blockout_id: number,
  blockout_date_id: number,
  reason: string,
  is_all_day: bool,
  starts_at: string,
  ends_at: string,
  day: number,
  total_days: number,
}

const renderBlockoutLinkTitleWithIcon = (title) => {
  return (
    <div className="d-if">
      <Icon
        symbol="general#thick-x"
        className="mr-4p mt-4p c-ruby p-r t-1p fs-4"
      />
      <div>{title}</div>
    </div>
  )
}

export default function Blockout({
  is_all_day,
  blockout_id,
  blockout_date_id,
  day,
  total_days,
  reason,
  starts_at,
  ends_at,
}) {
  const isLastDay = total_days > 1 && day === total_days

  const title = is_all_day
    ? "Blockout (all day)"
    : isLastDay
      ? `Blockout (until ${formatTime(ends_at, { showTimeZone: false })})`
      : `${formatTime(starts_at, ends_at, { showTimeZone: false })} Blockout`

  const scheduleItemContext = { text: reason }

  return (
    <ScheduleItem
      linkTitle={renderBlockoutLinkTitleWithIcon(title)}
      linkUrl={`/services/blockouts/${blockout_id}/dates/${blockout_date_id}`}
      linkState={{ prevPath: location.pathname, prevPathName: "Me" }}
      itemContext={scheduleItemContext}
    />
  )
}
