import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { useNavigate, useParams } from "react-router-dom"
import { useSession } from "source/shared/hooks/useSession"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import { EditContactAndInfo } from "source/myChurchCenter/Profile/EditContactAndInfo"
import { ProfileAvatar } from "source/myChurchCenter/Profile/ProfileAvatar"
import { Heading } from "@planningcenter/doxy-web"
import { useAllSchools } from "../hooks/useAllSchools"

const MARITAL_STATUS_OPTIONS_PATH = "/people/v2/marital_statuses"

export function HouseholdMemberEdit() {
  const navigate = useNavigate()
  const { householdId, householdMemberId } = useParams()
  const {
    data: { id: currentPersonId },
  } = useSession(true)

  const household = useApiRead(
    `/people/v2/people/${currentPersonId}/households/${householdId}?include=people,household_members`,
  )

  const householdMembersPersonId = household.included.find(
    (record) => record.id === householdMemberId,
  ).relationships.person.data.id

  const profilePath = `/people/v2/people/${householdMembersPersonId}?include=emails,phone_numbers,addresses.country,marital_status,school&fields[Country]=address_fields,name`
  const profile = useApiRead(profilePath)

  const maritalStatusOptions = useApiRead(MARITAL_STATUS_OPTIONS_PATH)
  const schoolOptions = useAllSchools()

  const name = profile.data.attributes.name
  const avatar =
    profile.data.attributes.avatar_url ||
    profile.data.attributes.demographic_avatar_url

  const reloadProfile = useCacheHydrate(SessionApiResource, profilePath)

  const canEditContactInfo =
    profile.data.attributes.contact_info_editable_by_household ||
    currentPersonId === profile.data.id

  function onDiscard() {
    navigate(`/households/${householdId}`)
  }

  function onSave() {
    navigate(`/households/${householdId}`)
  }

  const includedRecords = profile.included
  const personalAttributesRecords = [
    "Anniversary",
    "Birthdate",
    "Gender",
    "Grade",
    "Graduation_Year",
    "Medical_Notes",
  ].map((recordType) => {
    return {
      id: recordType,
      attributes: {
        [recordType.toLowerCase()]:
          profile.data.attributes[recordType.toLowerCase()],
      },
      type: recordType,
      links: { self: profile.data.links.self },
    }
  })

  const addressCountries = profile.included.filter(
    (record) => record.type === "Country",
  )

  // NOTE: We need a default state for MaritalStatus in the event we don't have one
  // in the `included` fields.
  if (includedRecords.every((record) => record.type !== "MaritalStatus")) {
    const defaultMaritalStatus = {
      type: "MaritalStatus",
      id: "",
      attributes: {
        marital_status_id: "",
      },
      links: {
        self: "",
      },
    }

    includedRecords.push(defaultMaritalStatus)
  }

  if (includedRecords.every((record) => record.type !== "SchoolOption")) {
    const defaultSchool = {
      type: "SchoolOption",
      id: "",
      attributes: {
        school_id: "",
      },
      links: {
        self: "",
      },
    }

    includedRecords.push(defaultSchool)
  }

  function mapRecordsFieldsForPatchingToPersonRelationship(records) {
    return records.map((record) => {
      switch (record.type) {
        case "MaritalStatus":
          record.links.self = profile.data.links.self
          record.attributes.marital_status_id = record.id
          record.deleteValueAttributeForPatch = true
          break
        case "SchoolOption":
          record.links.self = profile.data.links.self
          record.attributes.school_id = record.id
          record.deleteValueAttributeForPatch = true
          break
      }

      return record
    })
  }

  const records = personalAttributesRecords.concat(
    mapRecordsFieldsForPatchingToPersonRelationship(includedRecords),
  )

  return (
    <>
      <div className="d-f ai-c mb-2">
        <ProfileAvatar
          avatar={avatar}
          name={name}
          personPath={profilePath}
          reloadProfile={reloadProfile}
        />
        <Heading level={1} size={2} text={name} />
      </div>
      <EditContactAndInfo
        currentPersonId={currentPersonId}
        canEditContactInfo={canEditContactInfo}
        editableOptions={{
          maritalStatusOptions: maritalStatusOptions.data,
          schoolOptions: schoolOptions,
        }}
        isChild={profile.data.attributes.child}
        onDiscard={onDiscard}
        onSave={onSave}
        path="/contact-and-info/edit"
        personId={profile.data.id}
        records={records}
        addressCountries={addressCountries}
      />
    </>
  )
}
