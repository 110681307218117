import { array, object, bool } from "prop-types"

const ReviewAddOnSelection = ({ addOns, addOnSelection, hasTotalDue }) => {
  const {
    addOnId,
    addOnVariationId,
    type,
    quantity,
    costEachString,
    costEachCents,
    costTotalString,
  } = addOnSelection

  const useAddOnVariation = type === "AddOnVariation"

  const addOn = addOns.find((addOn) => addOn.id === addOnId)
  const addOnVariation = addOn.addOnVariations.find(
    (v) => v.id === addOnVariationId,
  )

  const addOnName = useAddOnVariation
    ? `${addOn.name}: ${addOnVariation.name}`
    : addOn.name

  return (
    <div className="d-f ai-c jc-sb mb-4p c-tint2">
      <div>
        {addOnName}
        {quantity > 1 && costEachCents === 0 && ` (${quantity})`}
        {quantity > 1 &&
          costEachCents > 0 &&
          ` (${quantity} x ${costEachString})`}
      </div>
      {hasTotalDue && <div className="pl-1">{costTotalString}</div>}
    </div>
  )
}

ReviewAddOnSelection.propTypes = {
  addOnSelection: object,
  addOns: array,
  hasTotalDue: bool,
}

export default ReviewAddOnSelection
