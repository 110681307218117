import { MonthViewOverlayStyles } from "./MonthViewStyles"
import { CalendarEventScroller } from "./List"
import { WideLayout } from "source/Layout"
import { bool, array, func, string } from "prop-types"
import moment from "moment"
import GalleryEvent from "./GalleryEvent"
import { CardGrid } from "../../shared/components"

const GalleryView = ({
  events,
  loadingEvents,
  hasMore,
  loadMore,
  lastDateLoaded,
}) => {
  events.forEach((it) => {
    it.eventDay = moment(it.eventDate).format("YYYY-MM-DD")
  })

  return (
    <WideLayout>
      <MonthViewOverlayStyles />
      <CalendarEventScroller
        loadingMore={loadingEvents}
        {...{ hasMore, loadMore, lastDateLoaded }}
      >
        <div className="mb-2">
          <CardGrid>
            {events.map((event) => (
              <GalleryEvent
                key={event.id}
                {...{
                  event,
                  layout: "column",
                }}
              />
            ))}
          </CardGrid>
        </div>
      </CalendarEventScroller>
    </WideLayout>
  )
}

GalleryView.propTypes = {
  events: array.isRequired,
  loadingEvents: bool,
  hasMore: bool,
  loadMore: func,
  lastDateLoaded: string,
}

export default GalleryView
