export const reactionData = [
  {
    Component: () => <>❤️</>,
    name: "Love",
    type: "heart",
  },
  {
    Component: () => <>🙏</>,
    name: "Pray",
    type: "pray",
  },
  {
    Component: () => <>😂</>,
    name: "Laughing",
    type: "laughing",
  },
  {
    Component: () => <>👍</>,
    name: "Thumbs up",
    type: "+1",
  },
  {
    Component: () => <>👎</>,
    name: "Thumbs down",
    type: "-1",
  },
]
