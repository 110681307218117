import { TextInput } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"

type GraduationYearType = {
  disableGraduationYear: boolean
  graduationYear: {
    attributes: {
      graduation_year: number | null
    }
  }
  updateAttribute: (record: any, attribute: any, value: any) => void
}

export function GraduationYear({
  disableGraduationYear,
  graduationYear,
  updateAttribute,
}: GraduationYearType) {
  const {
    attributes: { graduation_year },
  } = graduationYear
  return (
    <>
      <div className="label">
        Graduation Year
        {disableGraduationYear && (
          <Icon symbol="general#lock" className="fs-2 pl-1" />
        )}
      </div>
      <TextInput
        disabled={disableGraduationYear}
        id="graduation_year"
        onChange={(e) =>
          updateAttribute(graduationYear, "graduation_year", e.target.value)
        }
        value={graduation_year ? graduation_year.toString() : ""}
      />
    </>
  )
}
