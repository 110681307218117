import { css, Global } from "@emotion/react"
import { fontSize } from "@planningcenter/system"
import { colors } from "source/shared/colors"

const cmvBorderColor = colors.tint7
const cmvAllDayColor = colors.tint7
const cmvOffRangeColor = colors.tint9
const cmvBorderRadius = "4px"

export const MonthViewOverlayStyles = () => {
  return (
    <Global
      styles={css`
        .rbc-overlay {
          border: none !important;
          border-radius: 4px;

          .rbc-overlay-header {
            border-bottom: none !important;
            font-size: ${fontSize(1)}px;
            font-weight: 600;
            padding-top: 7px !important;
            text-align: center;
            text-transform: uppercase;
          }

          .rbc-event {
            background: none;
            color: ${colors.tint2};
            line-height: 14px;

            .rbc-event-content {
              font-size: ${fontSize(1)}px;
              font-weight: 600;
            }
          }

          .rbc-event.rbc-event-allday {
            background: ${cmvAllDayColor};
            line-height: 18px;
            padding: 0 5px;
          }

          .rbc-event.rbc-selected:not(.rbc-event-allday),
          .rbc-day-slot .rbc-selected.rbc-background-event {
            background: none;
          }
        }
      `}
    />
  )
}

const params = new URLSearchParams(window.location.search)
const embedded = params.get("embed") == "true"
const filterBarEnabled = params.get("allowFiltering") == "true"
// as of Feb 23. 2023 the filter bar is 48px tall + 16px top padding + 16px bottom padding + 32px bottom margin = 112px
// we want the month view to take up all the vertical space - the height of the conditionally visible filter bar.
// without this conditional styling, the last week of month is cut off in month view
const embeddedHeight = filterBarEnabled ? "calc(100vh - 112px)" : "100vh"

export const MonthViewStyles = css`
  height: ${embedded ? embeddedHeight : "600px"};

  .rbc-toolbar {
    margin-bottom: 1.5rem;

    .rbc-toolbar-label {
      order: 1;
      text-align: left;
      color: ${colors.tint0};
      font-size: 18px;
      font-weight: 600;
      line-height: 1.333;
      padding: 0;
    }

    .rbc-btn-group {
      order: 2;
      display: flex;

      // today
      button:nth-child(1) {
        order: 2;
        background-color: ${colors.tint10};
        color: ${colors.brand};
        height: 24px;
        border-radius: 30px;
        outline: none;
        border: 1px solid ${colors.brand};
        padding: 0 12px;
        margin: 0 2px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: ${fontSize(1)}px;
        font-weight: 600;

        &:active {
          box-shadow: none;
        }
      }

      // prev
      button:nth-child(2) {
        order: 1;
      }

      // next
      button:nth-child(3) {
        order: 3;
      }

      // prev, next
      button:nth-child(2),
      button:nth-child(3) {
        border: none;
        color: ${colors.brand};
        font-size: ${fontSize(2)}px;
        line-height: 24px;
        padding: 0 4px;

        &:hover,
        &:active,
        &:focus {
          background: none;
          box-shadow: none;
        }

        .symbol {
          vertical-align: middle;
        }
      }
    }
  }

  .rbc-month-view {
    border: none;
    border-bottom: 1px solid ${cmvBorderColor};
    border-radius: ${cmvBorderRadius};

    > .rbc-month-row {
      border-top: 1px solid ${cmvBorderColor};
      border-radius: ${cmvBorderRadius} ${cmvBorderRadius} 0 0;

      > .rbc-day-bg:first-child {
        border-radius: ${cmvBorderRadius} 0 0 0;
      }

      > .rbc-day-bg:last-child {
        border-radius: 0 ${cmvBorderRadius} 0 0;
      }
    }

    > .rbc-month-row ~ .rbc-month-row:not(:last-child) {
      border-radius: 0;

      > .rbc-day-bg:first-child {
        border-radius: 0;
      }

      > .rbc-day-bg:last-child {
        border-radius: 0;
      }
    }
  }

  .rbc-month-header {
    .rbc-header {
      border: none;
      font-size: ${fontSize(2)}px;
      font-weight: 600;
      height: 28px;
    }
  }

  .rbc-month-row,
  .rbc-day-bg {
    border-color: ${cmvBorderColor} !important;
  }

  .rbc-month-row {
    border-left: 1px solid ${cmvBorderColor};
    border-right: 1px solid ${cmvBorderColor};

    &:last-child {
      border-radius: 0 0 ${cmvBorderRadius} ${cmvBorderRadius};

      > .rbc-day-bg:first-child {
        border-radius: 0 0 0 ${cmvBorderRadius};
      }

      > .rbc-day-bg:last-child {
        border-radius: 0 0 ${cmvBorderRadius} 0;
      }
    }
  }

  .rbc-date-cell {
    text-align: center;
    color: ${colors.tint3};

    .rbc-button-link {
      font-size: ${fontSize(1)}px;
      cursor: default;
    }
  }

  .rbc-off-range-bg {
    background: ${cmvOffRangeColor};
  }

  .rbc-off-range .rbc-button-link {
    opacity: 0.65;
  }

  .rbc-now .rbc-button-link {
    background: ${colors.brand};
    color: ${colors.tint10};
    border-radius: 50%;
    width: 22px;
    line-height: 22px;
  }

  .rbc-today {
    background: none;

    &.rbc-off-range-bg {
      background: ${cmvOffRangeColor};
    }
  }

  // day date container
  .rbc-row-content > .rbc-row {
    height: 28px;
  }

  .rbc-row-content > .rbc-row ~ .rbc-row {
    height: auto;
  }

  .rbc-event {
    background: none;
    color: ${colors.tint2};
    line-height: 14px;

    .rbc-event-content {
      font-size: ${fontSize(1)}px;
      font-weight: 600;
    }
  }

  .rbc-event.rbc-event-allday {
    background: ${cmvAllDayColor};
    line-height: 18px;
    padding: 0 5px;
  }

  .rbc-event.rbc-selected:not(.rbc-event-allday),
  .rbc-day-slot .rbc-selected.rbc-background-event {
    background: none;
  }

  .rbc-show-more {
    color: ${colors.tint2};
    font-size: ${fontSize(1)}px;
    font-weight: 600;
    padding-left: 5px;

    &:hover,
    &:focus,
    &:active {
      color: ${colors.brand};
    }
  }

  .rbc-row-segment .rbc-event-content {
    text-overflow: initial;
  }
`
