import { useApiRead } from "source/shared/SessionApiResource"

const TEAM_LEADER_FIELDS = ["person"]
const PERSON_FIELDS = ["first_name", "last_name", "photo_url"]

export function useCurrentPersonAssignedTeamLeaders() {
  const { data, included } = useApiRead(
    `/services/v2/me/assigned_team_leaders?per_page=100&filter=not_archived,not_deleted&include=people&order=first_name,last_name&fields[TeamLeader]=${TEAM_LEADER_FIELDS}&fields[Person]=${PERSON_FIELDS}`,
  )

  const teamLeaders = data?.map((teamLeader) => {
    const personId = teamLeader.relationships.person.data.id
    const person = included.find(
      (incl) => incl.type === "Person" && incl.id === personId,
    )

    return {
      ...teamLeader.attributes,
      personId: person.id,
      personName: getFullName(person),
      photoUrl: person.attributes.photo_url,
    }
  })

  const uniqueTeamLeaders = teamLeaders?.filter(
    (teamLeader, index, teamLeaders) =>
      teamLeaders.findIndex((item) => item.personId === teamLeader.personId) ===
      index,
  )

  if (uniqueTeamLeaders && uniqueTeamLeaders.length > 0) {
    uniqueTeamLeaders[0].selected = true
  }

  return uniqueTeamLeaders ? uniqueTeamLeaders : []
}

function getFullName(person) {
  return [person.attributes.first_name, person.attributes.last_name]
    .filter((name) => name)
    .join(" ")
}
