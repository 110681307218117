import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  createResource,
  useCacheRead,
} from "source/shared/react-cache-but-its-a-context"

export const SessionApiResource = createResource(
  (pathname, options = {}) =>
    sessionApiClient.get(pathname, {}, options).catch((json) => json),
  (pathname, options = {}) =>
    `SessionApiResource-${pathname}-${JSON.stringify({ options })}`,
)

export function useApiRead(pathname, options = {}) {
  return useCacheRead(SessionApiResource, pathname, options)
}

export function useApiReadEntireCollection(pageOne, options = {}) {
  return useCacheRead(EntireCollectionResource, pageOne, options)
}

export async function getEntireCollection(pageOne, options = {}) {
  let nextLink = pageOne
  let responses = []
  while (nextLink) {
    let json = await sessionApiClient.get(nextLink, {}, options)
    responses.push(json)
    nextLink = json.links?.next
  }
  return responses
}

const EntireCollectionResource = createResource(
  getEntireCollection,
  (pageOne, _options = {}) => `EntireCollectionResource-${pageOne}`,
)

const ParallelApiResource = createResource(
  (...pathnames) =>
    Promise.all(
      pathnames.map((pathname) => sessionApiClient.get(pathname, {}, {})),
    ),
  (...pathnames) => pathnames.join("__AND__"),
)

export function useApiReadMultiple(...pathnames) {
  return useCacheRead(ParallelApiResource, ...pathnames)
}

export const SessionPostApiResource = createResource(
  (pathname, params, options = {}) =>
    sessionApiClient.post(pathname, params, options).catch((json) => json),
  (pathname, params, options = {}) =>
    `SessionApiResource-POST-${pathname}-${JSON.stringify({
      params,
    })}-${JSON.stringify({ options })}`,
)

export function useApiPost(pathname, params = undefined, options = {}) {
  return useCacheRead(SessionPostApiResource, pathname, params, options)
}
