import "url-search-params-polyfill"
import "whatwg-fetch"

import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"
import * as Bugsnag from "source/Bugsnag"
import { StrictMode, useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { func, object } from "prop-types"
import Rails from "@rails/ujs"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { AlertMessage } from "source/shared/components"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { Layout } from "source/Layout"
import svg4everybody from "@planningcenter/icons/js/svg4everybody.js"
import { MainRouter } from "source/MainRouter"
import { SetOrganizationTimeZone } from "source/SetOrganizationTimeZone"
import { WebBoot } from "source/publishing/WebBoot"
import "@planningcenter/components/css/components.css"
import { useChurchCenterPageAnalytics } from "source/shared/hooks/useChurchCenterPageAnalytics"
import { useDiscardAnchorTagJavascript } from "source/shared/hooks/useDiscardAnchorTagJavascript"
import { useEnv } from "source/shared/hooks/useEnv"
import { startDatadogRealUserMonitoring } from "source/shared/datadogRum"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Router } from "source/shared/routingUtils"

import "stream-chat-react/dist/css/v2/index.css"
import "stylesheets/stream-chat-react-overrides.css"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

svg4everybody()
momentDurationFormatSetup(moment)

App.propTypes = {
  bugsnag_configuration: object.isRequired,
}
App.render = (props, mountNode) => {
  createRoot(mountNode).render(
    <StrictMode>
      <App {...props} />
    </StrictMode>,
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey }) => {
        const [path, queryParams = {}] = queryKey
        return sessionApiClient.get(path, queryParams).catch((error) => error)
      },
      throwOnError: (error) => ["500"].includes(error.status),
      staleTime: 60 * 1000,
      gcTime: 60 * 5 * 1000,
    },
  },
})

// NOTE: Every product team needs to sign off on a change to this version.
//
// In most cases we'll want to use per-request configuration, not this configuration.
// If we find we're doing that a lot, we can explore tooling to generate derivative api clients with overridden configuration.
sessionApiClient.configure({ version: "2020-06-16" })

Object.assign(window, { sessionApiClient })

function App(props) {
  useDiscardAnchorTagJavascript()

  useEffect(() => {
    // https://github.com/rails/rails/blob/db30dd6fe71341ac132bb1e850bd4df2596a2803/actionview/app/assets/javascripts/rails-ujs.esm.js#L624
    if (!window._rails_loaded) Rails.start()
  }, [])
  const [key, setKey] = useState(0)

  function onRestart() {
    if (key === 0) {
      setKey(1)
    } else {
      window.location.reload()
    }
  }

  return (
    <Bugsnag.Root {...props.bugsnag_configuration}>
      <Bugsnag.ErrorBoundary
        key={key}
        fallback={<MainError onRestart={onRestart} />}
      >
        <Router>
          <ServerRenderedProps.Provider value={props}>
            <QueryClientProvider client={queryClient}>
              <WebBoot>
                <SetOrganizationTimeZone>
                  <Main />
                </SetOrganizationTimeZone>
              </WebBoot>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </ServerRenderedProps.Provider>
        </Router>
      </Bugsnag.ErrorBoundary>
    </Bugsnag.Root>
  )
}

MainError.propTypes = {
  onRestart: func.isRequired,
}
function MainError({ onRestart }) {
  return (
    <div className="mx-8 my-4 ta-c">
      <AlertMessage>
        Something unexpected happened. Please try again.
      </AlertMessage>
      <button className="secondary-btn btn mb-3 mt-3" onClick={onRestart}>
        Start over
      </button>
    </div>
  )
}

function Main() {
  const env = useEnv()

  useEffect(() => {
    if (env) startDatadogRealUserMonitoring(env)
  }, [env])

  const pageAnalyticsFlipperEnabled = useFlipperFeatureEnabled(
    "ROLLOUT_page_analytics",
  )
  useChurchCenterPageAnalytics(pageAnalyticsFlipperEnabled)

  return (
    <Layout>
      <MainRouter />
    </Layout>
  )
}

function mountReactRoot() {
  const reactRoot = document.getElementById("react-root")
  const props = JSON.parse(reactRoot.dataset.reactProps)
  App.render(props, reactRoot)
}

window.addEventListener("DOMContentLoaded", mountReactRoot)
