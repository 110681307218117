import { useApiRead } from "source/shared/SessionApiResource"
import { _ } from "lodash"
import moment from "moment-timezone"

export function usePublicPlanDetails({ planId, serviceTypeId, beforeAfter }) {
  let url = ""
  if (planId) {
    url = `/services/v2/public_plans/${planId}`
  } else {
    url = `/services/v2/public_plans?service_type_id=${serviceTypeId}&${beforeAfter}=true`
  }

  const {
    dates,
    errors,
    public_items,
    series_art,
    service_type_name,
    times,
    title,
  } = usePublicPlan({
    url,
  })

  return {
    errors: errors,
    plan: {
      dateHeading: (times ?? []).length <= 1 ? dates : "",
      seriesArt: series_art,
      serviceTypeName: service_type_name,
      showItemTimes: public_items?.some((item) => item.time !== null),
      times: formatTimes(times),
      title: title,
      planItems: public_items,
    },
  }
}

function usePublicPlan({ url }) {
  const response = useApiRead(url)
  return response.errors ? { errors: true } : response.data.attributes
}

function formatTimes(times) {
  const groupedByDate = _.groupBy(times, (time) =>
    moment(time, "YYYY/MM/DD HH:mm:ss Z").utc().format("YYYY-MM-DD"),
  )

  const formattedData = _.map(groupedByDate, (services, date) => {
    const times = _.map(services, (service) => {
      const minutes = moment(service, "YYYY/MM/DD HH:mm:ss Z").utc().minutes()
      if (minutes === 0) {
        return moment(service, "YYYY/MM/DD HH:mm:ss Z").utc().format("h a")
      } else {
        return moment(service, "YYYY/MM/DD HH:mm:ss Z").utc().format("h:mm a")
      }
    })

    return {
      date: `${moment(date).utc().format("MMM D, YYYY")} - ${times.join(", ")}`,
    }
  })

  return formattedData
}
