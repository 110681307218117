import { createContext, useState, useContext, useEffect } from "react"
import { bool, node, string, object } from "prop-types"
import { Link } from "react-router-dom"
import { Icon } from "source/shared/components"

const BreadcrumbNavigationContext = createContext()

export const BreadcrumbNavigationProvider = ({ children }) => {
  return (
    <BreadcrumbNavigationContext.Provider value={useState(null)}>
      {children}
    </BreadcrumbNavigationContext.Provider>
  )
}

BreadcrumbNavigationProvider.propTypes = {
  children: node.isRequired,
}

export const BreadcrumbNavigationPortal = () => {
  const [crumbs, _setCrumbs] = useContext(BreadcrumbNavigationContext)

  return (
    crumbs && (
      <nav aria-label="Breadcrumb" className="breadcrumbs">
        <div className="breadcrumbs__layout">{crumbs}</div>
      </nav>
    )
  )
}

export const BreadcrumbNavigation = ({ children }) => {
  const [_crumbs, setCrumbs] = useContext(BreadcrumbNavigationContext)

  useEffect(() => {
    setCrumbs(children)
    return () => setCrumbs(null)
  }, [children])

  return <></>
}

BreadcrumbNavigation.propTypes = {
  children: node.isRequired,
}

export function BreadcrumbPage({ linkUrl, linkState, pageText, isActive }) {
  let className, ariaCurrent

  if (isActive) {
    className = "breadcrumbs__page breadcrumbs__current-page"
    ariaCurrent = "location"
  } else {
    className = "breadcrumbs__page"
    ariaCurrent = "false"
  }

  return (
    <>
      {linkUrl ? (
        <Link
          to={linkUrl}
          state={linkState}
          className={className}
          aria-current={ariaCurrent}
          aria-label={pageText}
        >
          <span className="text">{pageText}</span>
          <span className="icon">
            <Icon symbol="churchCenter#three-dots" />
          </span>
        </Link>
      ) : (
        <div className={className} aria-current={ariaCurrent}>
          {pageText}
        </div>
      )}
    </>
  )
}

BreadcrumbNavigationWithMe.propTypes = {
  children: node.isRequired,
}
export function BreadcrumbNavigationWithMe({ children }) {
  return (
    <BreadcrumbNavigation>
      <BreadcrumbPage linkUrl="/me" pageText="Me" />
      <BreadcrumbDividerIcon />
      {children}
    </BreadcrumbNavigation>
  )
}

BreadcrumbPage.propTypes = {
  linkUrl: string,
  linkState: object,
  pageText: string,
  isActive: bool,
}

export function BreadcrumbContent({ isActive, children }) {
  let className, ariaCurrent

  if (isActive) {
    className = "breadcrumbs__page breadcrumbs__current-page"
    ariaCurrent = "location"
  } else {
    className = "breadcrumbs__page"
    ariaCurrent = "false"
  }

  return (
    <div className={className} aria-current={ariaCurrent}>
      {children}
    </div>
  )
}

BreadcrumbContent.propTypes = {
  isActive: bool,
  children: node,
}

export function BreadcrumbDividerIcon() {
  return (
    <span className="breadcrumbs__divider-icon">
      <Icon symbol="general#right-chevron" aria-hidden />
    </span>
  )
}
