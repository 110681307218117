import { time } from "@churchcenter/datetime-fmt"
import { bool, number, string, shape } from "prop-types"
import ScheduleItem from "source/myChurchCenter/Schedule/ScheduleItem"

UpcomingGroupsEvent.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  starts_at: string.isRequired,
  ends_at: string.isRequired,
  group_slug: string.isRequired,
  group_name: string.isRequired,
  rsvp_response: shape({
    icon: string.isRequired,
    ccw_color: string.isRequired,
    label: string.isRequired,
  }).isRequired,
  is_all_day: bool.isRequired,
  day: number.isRequired,
  total_days: number.isRequired,
}

function activityTitle(name, startsAt, endsAt, isAllDay, day, totalDays) {
  const formattedStart = time(startsAt, { showTimeZone: false })
  const formattedEnd = time(endsAt, { showTimeZone: false })
  const formattedRange = time(startsAt, endsAt, { showTimeZone: false })
  const isMultiDay = totalDays > 1
  const isFirstDay = isMultiDay && day === 1
  const isLastDay = isMultiDay && day === totalDays

  let title
  if (isAllDay) {
    title = name + " (all day)"
  } else if (isFirstDay) {
    title = `${formattedStart} ${name}`
  } else if (isLastDay) {
    title = `${name} (until ${formattedEnd})`
  } else {
    title = `${formattedRange} ${name}`
  }

  return title
}
export default function UpcomingGroupsEvent({
  id,
  name,
  starts_at,
  ends_at,
  group_slug,
  group_name,
  rsvp_response,
  is_all_day,
  day,
  total_days,
}) {
  const myGroupEventPath = `/my/groups/${group_slug}/events/${id}`

  const groupEventItemContext = {
    icon: rsvp_response.icon,
    iconClass: `mr-4p fs-4 c-${rsvp_response.ccw_color}`,
    text: rsvp_response.label,
  }

  return (
    <ScheduleItem
      linkTitle={activityTitle(
        name,
        starts_at,
        ends_at,
        is_all_day,
        day,
        total_days,
      )}
      linkUrl={myGroupEventPath}
      itemDescription={group_name}
      itemContext={groupEventItemContext}
    />
  )
}
