import { func, string, bool, node, oneOfType } from "prop-types"
import classNames from "classnames"

const Field = ({ rowLayout = false, label, isRequired, children, error }) => {
  return (
    <div>
      <div className={classNames("d-f", rowLayout ? "ai-c g-2" : "fd-c g-4p")}>
        <label
          className={classNames(
            "fs-4 pb-0 fw-600",
            isRequired ? "required-field" : "",
            error ? "c-ruby" : "c-tint2",
          )}
          css={{ textWrap: "nowrap", minWidth: rowLayout ? "80px" : "unset" }}
        >
          {label}
        </label>

        {children}
      </div>
      {error && <div className="c-ruby">{error}</div>}
    </div>
  )
}

Field.propTypes = {
  rowLayout: bool,
  label: string,
  value: oneOfType([string, node]),
  persistedValue: string,
  isEditable: bool,
  isRequired: bool,
  isOptional: bool,
  onEdit: func,
  onSave: func,
  onCancel: func,
  children: node,
  error: string,
  isSmallScreen: bool,
}

export default Field
