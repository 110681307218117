import { object, func, bool } from "prop-types"
import { Elements, ExpressCheckoutElement } from "@stripe/react-stripe-js"
import { CenterLabelBorder } from "source/shared/components"
import { useEnv } from "source/shared/hooks/useEnv"

const ExpressCheckout = ({
  stripe,
  paymentIntent,
  loading,
  onExpressCheckoutReady,
}) => {
  const env = useEnv()

  // TODO: Implement handleExpressCheckoutClick
  const handleExpressCheckoutClick = () => {}

  // TODO: Implement handleExpressCheckoutConfirm
  const handleExpressCheckoutConfirm = () => {}

  return (
    <Elements
      stripe={stripe}
      options={{
        amount: paymentIntent?.amount,
        mode: "payment",
        currency: "usd",
        paymentMethodTypes: env === "development" ? ["amazon_pay"] : [],
      }}
    >
      <CenterLabelBorder label="Quick Pay">
        {loading && <ExpressCheckoutLoading />}
        <ExpressCheckoutElement
          onClick={handleExpressCheckoutClick}
          onConfirm={handleExpressCheckoutConfirm}
          onReady={onExpressCheckoutReady}
        />
      </CenterLabelBorder>
    </Elements>
  )
}

ExpressCheckout.propTypes = {
  stripe: object,
  loading: bool,
  paymentIntent: object,
  onExpressCheckoutReady: func,
}

export default ExpressCheckout

const ExpressCheckoutLoading = () => {
  const loadingStyles = {
    flexGrow: 1,
    height: "44px",
    borderRadius: "4px",
    backgroundColor: "var(--color-tint9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    padding: "1rem",
  }

  return (
    <div className="d-f g-2">
      {[1, 2, 3].map((i) => (
        <div key={i} css={loadingStyles}>
          <div
            className="loading-placeholder"
            css={{ height: "1rem", width: "5rem" }}
          ></div>
        </div>
      ))}
    </div>
  )
}
