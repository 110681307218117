function toParam(record) {
  if (record.hasOwnProperty("id")) return record.id
  else return record
}

export function channelCollectionPath() {
  return `/channels`
}

export function channelPath(channel) {
  return `/channels/${toParam(channel)}`
}

export function episodePath(episode) {
  return `/episodes/${toParam(episode)}`
}
