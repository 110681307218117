import { useContext } from "react"
import PropTypes from "prop-types"
import { date } from "@churchcenter/datetime-fmt"
import moment from "moment"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import { Icon } from "source/shared/components"
import { EventsListContext } from "../contexts/EventsListContext"

const propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      starts_at: PropTypes.string.isRequired,
      ends_at: PropTypes.string.isRequired,
      location_type_preference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  rsvp: PropTypes.shape({
    attributes: PropTypes.shape({
      response: PropTypes.string,
    }),
  }),
  isRepeating: PropTypes.bool,
}

export function EventDetails({ event, location, rsvp, isRepeating = false }) {
  const { getEventUrl } = useContext(EventsListContext)
  const {
    attributes: {
      name,
      starts_at,
      ends_at,
      canceled_at,
      location_type_preference,
    },
  } = event

  const abbreviatedDatetime = date(starts_at, { style: "abbreviated" })
  const shortMonthName = abbreviatedDatetime.split(" ")[0]
  const dayNumber = abbreviatedDatetime.split(" ")[1]
  const eventIsCanceled = canceled_at && moment(canceled_at).isBefore(moment())
  const eventIsUpcoming = moment(starts_at).isSameOrAfter(moment())
  const locationVisible = location && location_type_preference === "physical"
  const rsvpStatusVisible = eventIsUpcoming && rsvp && !eventIsCanceled
  const url = getEventUrl(event)

  return (
    <article
      className={`d-f f-1 ${
        locationVisible && rsvpStatusVisible ? "ai-fs" : "ai-c"
      }`}
    >
      <div className="mr-2">
        <a href={url}>
          <div className="w-8 h-8 ta-c event-cal">
            <div className="event-cal__month">{shortMonthName}</div>
            <div className="event-cal__day">{dayNumber}</div>
          </div>
        </a>
      </div>

      <div className="f-1">
        <a href={url} className="fw-500">
          <div className="lh-1.5 mb-2p">
            {name}
            {isRepeating && (
              <span className="d-ib ml-4p c-tint3">
                <Icon symbol="general#calendar-repeat" />
              </span>
            )}
            {eventIsCanceled && (
              <div className="badge danger-badge ml-1">Canceled</div>
            )}
          </div>
        </a>
        <div className="fs-13 lh-1.5 c-tint2">
          <EventDatetimeDisplay startsAt={starts_at} endsAt={ends_at} />
          {locationVisible && <div>{location.attributes.name}</div>}
          {rsvpStatusVisible && (
            <RSVPStatus responseKey={rsvp.attributes.response} />
          )}
        </div>
      </div>
    </article>
  )
}
EventDetails.propTypes = propTypes

function RSVPStatus({ responseKey }) {
  const response = {
    awaiting_response: {
      classes: "c-marigold t-2p p-r",
      icon: "general#question-circle",
      label: "You have not responded",
    },
    yes: {
      classes: "c-emerald t-2p p-r",
      icon: "general#check",
      label: "You are going!",
    },
    no: {
      classes: "c-ruby fs-6 t-3p p-r",
      icon: "general#x",
      label: "You are not going",
    },
    maybe: {
      classes: "c-marigold t-2p p-r",
      icon: "general#question-circle",
      label: "You are a maybe",
    },
    not_sent: {
      classes: "c-marigold t-2p p-r",
      icon: "general#question-circle",
      label: "You have not responded",
    },
  }[responseKey]

  return (
    <div>
      <Icon symbol={response.icon} className={response.classes} />{" "}
      {response.label}
    </div>
  )
}
RSVPStatus.propTypes = {
  responseKey: PropTypes.oneOf([
    "awaiting_response",
    "yes",
    "no",
    "maybe",
    "not_sent",
  ]).isRequired,
}
