import { useState } from "react"
import { arrayOf, bool, func, shape, string } from "prop-types"

import CustomField from "./CustomField"

ParagraphField.propTypes = {
  errors: arrayOf(
    shape({
      detail: arrayOf(string),
      source: {
        parameter: string,
      },
    }),
  ),
  field: shape({
    attributes: shape({
      description: string,
      label: string.isRequired,
      placeholder: string,
      required: bool.isRequired,
    }),
    id: string.isRequired,
  }),
  onChange: func.isRequired,
}

export default function ParagraphField({ errors, field, onChange }) {
  const [value, setValue] = useState("")
  const {
    attributes: { description, label, placeholder, required },
    id,
  } = field
  const inputId = `field_${id}_value`

  const handleChange = ({ target: { value } }) => {
    setValue(value)
    onChange(value, id)
  }

  return (
    <CustomField {...{ description, errors, label, required }}>
      <textarea
        rows="5"
        id={inputId}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </CustomField>
  )
}
