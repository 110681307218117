import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { get as dig } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import { EmailRecipients, useMaxRecipientCount } from "./EmailRecipients"
import { Confirmation } from "./Confirmation"
import { Button, TextInput } from "@planningcenter/doxy-web"
import { AlertMessage } from "source/shared/components"

const IDLE = "idle"
const IN_FLIGHT = "inFlight"
const ERROR = "error"
const SUCCESS = "success"

export default function EmailNew() {
  const group = useContext(CurrentGroupContext)
  const [subject, setSubject] = useState()
  const [body, setBody] = useState()
  const [peopleIds, setPeopleIds] = useState([])
  const [status, setStatus] = useState(IDLE)
  const [errors, setErrors] = useState()

  const { groupMembershipsCountOverMax, maxRecipientCount } =
    useMaxRecipientCount()

  const location = useLocation()
  const prevPath =
    location.state?.prevPath || location.pathname.replace("/email/new", "")
  const navigate = useNavigate()

  const canSendEmails = dig(group, "abilities.email_messages.can_create", false)
  const isSubmitDisabled =
    !subject ||
    !body ||
    status === IN_FLIGHT ||
    (groupMembershipsCountOverMax && peopleIds.length === 0) ||
    (groupMembershipsCountOverMax && peopleIds.length > maxRecipientCount)

  if (!canSendEmails) {
    navigate(prevPath)
  }

  function handleSubmit(e) {
    e.preventDefault()

    setStatus(IN_FLIGHT)
    setErrors()

    sessionApiClient
      .post(`/groups/v2/me/groups/${group.id}/email`, {
        data: {
          attributes: { subject, body },
          relationships: {
            people: {
              data: peopleIds.map((p) => ({ type: "Person", id: p })),
            },
          },
        },
      })
      .then(() => {
        setStatus(SUCCESS)
      })
      .catch((e) => {
        setStatus(ERROR)

        if (e.errors[0].status === "429") {
          setErrors(
            "We're almost done sending your last email. Please try again in a moment.",
          )
        } else {
          setErrors(e.errors[0].detail)
        }
      })
  }

  if (status === SUCCESS) {
    return <Confirmation individuals={peopleIds.length > 0} />
  }

  return (
    <TabView>
      {errors && (
        <AlertMessage icon="general#exclamation-triangle">
          {errors}
        </AlertMessage>
      )}
      <EmailRecipients peopleIds={peopleIds} setPeopleIds={setPeopleIds} />
      <form className="w-100%" onSubmit={handleSubmit}>
        <div className="mb-2">
          <TextInput
            id="subject"
            label="Subject"
            onChange={(e) => setSubject(e.target.value)}
            size="md"
            required
          />
        </div>
        <div className="mb-2">
          <label className="label" htmlFor="body">
            Body
            <span className="c-ruby"> *</span>
          </label>
          <textarea
            id="body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows={12}
            className="w-100%"
          />
        </div>
        <div className="d-f jc-fe ai-c mt-3">
          <button
            className="c-tint3 text-btn pr-2"
            onClick={() => navigate(prevPath)}
            type="button"
          >
            Cancel
          </button>
          <Button
            size="lg"
            text="Send email"
            theme="primary"
            type="submit"
            variant="filled"
            isLoading={status === IN_FLIGHT}
            disabled={isSubmitDisabled}
          />
        </div>
      </form>
    </TabView>
  )
}

export function emailNewPath() {
  const { base_path } = useContext(CurrentGroupContext)

  return `${base_path}/members/email/new`
}
