import { difference, find, isEmpty, reduce, some } from "lodash"
import { useState } from "react"
import { array, func, object, string } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import spinner from "source/svg/spinner.svg"
import { useNavigate } from "react-router-dom"

export default function SubmitResponseButton({
  referrerPath,
  responseData,
  schedules,
  setReasonModalProps,
  setShowReasonModal,
  toggleableScheduleIds,
}) {
  const navigate = useNavigate()

  const [inFlight, setInFlight] = useState(false)

  const allDeclined = schedules.every(
    (schedule) =>
      schedule.serviceTimes.length ===
      responseData[schedule.id].declinedTimeIds.length,
  )

  const hasAnyDeclines = some(
    responseData,
    (schedule) => schedule.declinedTimeIds.length > 0,
  )

  const handleSubmitResponse = (declineReason) => {
    setInFlight(true)
    Promise.all(
      schedules
        .filter((schedule) => toggleableScheduleIds.includes(schedule.id))
        .map((schedule) => {
          if (someTimesAccepted(schedule)) {
            return sessionApiClient.post(
              `/services/v2/me/unscoped_schedules/${schedule.id}/accept`,
              payload(schedule, declineReason, responseData[schedule.id]),
            )
          } else {
            return sessionApiClient.post(
              `/services/v2/me/unscoped_schedules/${schedule.id}/decline`,
              { data: { attributes: { reason: declineReason } } },
            )
          }
        }),
    ).then(() => navigate(referrerPath))
  }

  const numberOrDeclinedTimesOrPositions = reduce(
    responseData,
    (accu, { declinedTimeIds }, scheduleId) => {
      const schedule = find(schedules, { id: scheduleId })

      if (schedule.splitTeam) {
        accu += declinedTimeIds.length
      } else if (some(declinedTimeIds)) {
        accu += 1
      }
      return accu
    },
    0,
  )

  const someTimesAccepted = (schedule) => {
    const declinedTimeIds = responseData[schedule.id].declinedTimeIds
    if (isEmpty(declinedTimeIds)) return true

    const serviceTimeIds = schedule.serviceTimes.map(({ id }) => id)
    return !!difference(serviceTimeIds, declinedTimeIds).length
  }

  const payload = (schedule, declineReason, { declinedTimeIds }) => {
    if (schedule.splitTeam) {
      return {
        data: {
          type: "ScheduleAccept",
          attributes: { reason: declineReason },
          relationships: {
            declined_plan_times: {
              data: declinedTimeIds.map((id) => ({ type: "PlanTime", id })),
            },
          },
        },
      }
    } else {
      return {
        data: {
          attributes: {},
        },
      }
    }
  }

  const showSubmitResponseButton = toggleableScheduleIds.length >= 1

  const handleSubmitDeclines = () => {
    setReasonModalProps({
      allDeclined,
      declinedCount: numberOrDeclinedTimesOrPositions,
      handleSubmit: handleSubmitResponse,
    })
    setShowReasonModal(true)
  }

  return (
    showSubmitResponseButton && (
      <button
        className="btn mt-1"
        onClick={() =>
          hasAnyDeclines ? handleSubmitDeclines() : handleSubmitResponse()
        }
      >
        {inFlight ? (
          <img
            src={spinner}
            alt="Saving"
            style={{ height: "1em", width: "1em" }}
          />
        ) : (
          <div>Submit response</div>
        )}
      </button>
    )
  )
}

SubmitResponseButton.propTypes = {
  declineReason: string,
  referrerPath: string,
  responseData: object,
  schedules: array,
  setReasonModalProps: func,
  setShowReasonModal: func,
  toggleableScheduleIds: array,
}
