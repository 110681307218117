import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"

export function useUpcomingEvents() {
  return useEvents({ filter: "upcoming,public", order: "starts_at" })
}

export function usePastEvents() {
  return useEvents({ filter: "past,public", order: "-starts_at" })
}

function useEvents(params) {
  const { groupTypeSlug, groupSlug } = useParams()
  const query = new URLSearchParams({
    ...params,
    per_page: 3,
    include: "location,my_rsvp",
  })

  return useApiRead(
    `/groups/v2/group_types/${groupTypeSlug}/groups/${groupSlug}/events?${query.toString()}`,
  )
}
