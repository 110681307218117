import { useRef, useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "source/shared/components"
import { FormErrors } from "./FormErrors"
import { formatFileSize } from "../utils/formatFileSize"

import { ADD_FILE, REMOVE_FILE } from "../reducers/useResourceFormReducer"

FileResource.propTypes = {
  dispatch: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
}
export function FileResource({ dispatch, meta }) {
  const handleAddAttachment = (attachment) => {
    dispatch({
      type: ADD_FILE,
      payload: { file: attachment },
    })
  }

  const handleRemoveAttachment = () => {
    dispatch({ type: REMOVE_FILE })
  }

  return (
    <FileField
      id="new_resource"
      onAddFile={handleAddAttachment}
      onRemoveFile={handleRemoveAttachment}
      fileAllowList={meta.file_allowlist}
      maxFileSizeInMegabytes={meta.max_file_size_in_megabytes}
    />
  )
}

FileField.propTypes = {
  id: PropTypes.string.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  fileAllowList: PropTypes.object.isRequired,
  maxFileSizeInMegabytes: PropTypes.number.isRequired,
}
function FileField({
  id,
  onAddFile,
  onRemoveFile,
  fileAllowList,
  maxFileSizeInMegabytes,
}) {
  const [selectedFile, setSelectedFile] = useState()
  const [uploadErrors, setUploadErrors] = useState([])

  const fileInputRef = useRef()
  const maxNumberOfFiles = 1
  const initialBtnMessage = `Choose file…`
  const updateBtnMessage = initialBtnMessage
  const buttonText = selectedFile ? updateBtnMessage : initialBtnMessage
  const noSelectionMsg = `No file currently selected`
  const selectionMsg = `1 file currently selected`
  const selectedFilesMessage = selectedFile ? selectionMsg : noSelectionMsg

  const atFileCapacity = () => !!selectedFile

  const handleFileChange = () => {
    const files = Object.values(fileInputRef.current.files).filter(
      (file) => file.name !== selectedFile?.name,
    )

    if (files.length === 0 || atFileCapacity()) {
      return
    }

    if (files.find((file) => isOversized(file))) {
      setUploadErrors([
        {
          source: {
            parameter: "attachment",
          },
          detail: `size cannot exceed ${maxFileSizeInMegabytes} MB.`,
        },
      ])
      return
    }

    if (files.find((file) => isUnsupportedExtension(file))) {
      setUploadErrors([
        {
          source: {
            parameter: "attachment",
          },
          detail: "type is not supported.",
        },
      ])
      return
    }

    if (files.length > 2) {
      setUploadErrors([
        {
          source: {
            parameter: "resource",
          },
          detail: `can only include ${maxNumberOfFiles} attachment${
            files.length > 1 ? "s" : ""
          }.`,
        },
      ])
      return
    }

    setUploadErrors([])

    const newSelectedFile = files[0]

    setSelectedFile(newSelectedFile)

    onAddFile(newSelectedFile)
  }

  const handleFileInputClick = () => fileInputRef.current?.click()

  const handleFileRemove = () => {
    setSelectedFile()
    onRemoveFile()
  }

  const isOversized = (file) => file.size > 1024 * 1024 * maxFileSizeInMegabytes

  const isUnsupportedExtension = (file) => {
    const extension = file.name.split(".").pop().toLowerCase()

    return !fileAllowList.includes(extension)
  }

  const isDisabled = atFileCapacity()

  return (
    <>
      <FormErrors
        message="Uh oh! We couldn&rsquo;t upload your file. Please correct these errors and
        try again."
        errors={uploadErrors}
      />
      {!selectedFile ? (
        <>
          <div className="d-f fw-w">
            <div className="mr-2 mb-1">
              <button
                type="button"
                className={`btn minor-btn secondary-btn ${
                  isDisabled ? "disabled-btn" : null
                }`}
                style={{ minWidth: "7rem" }}
                htmlFor={`field${id}`}
                onClick={handleFileInputClick}
                disabled={isDisabled}
              >
                {buttonText}
              </button>
              <input
                className="screen-reader-text"
                disabled={isDisabled}
                id={`field${id}`}
                multiple={false}
                onChange={() => handleFileChange()}
                ref={fileInputRef}
                required={true}
                tabIndex={-1}
                type="file"
              />
            </div>
            <div className="fs-4 pt-2p c-tint2">
              <p className="mb-0">{selectedFilesMessage}</p>
            </div>
          </div>
        </>
      ) : (
        <div className="d-f ai-c fs-4 pr-2 mb-1" key={selectedFile.name}>
          <div
            className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
            style={{
              width: 28,
              height: 28,
              flex: "0 0 28px",
              border: "1px solid",
            }}
          >
            {["jpg", "jpeg", "gif", "png"].includes(
              selectedFile.name.split(".").pop().toLowerCase(),
            ) ? (
              <img
                alt={selectedFile.name}
                width={28}
                height={28}
                style={{ objectFit: "cover" }}
                src={window.URL.createObjectURL(selectedFile)}
              />
            ) : (
              <div className="fs-5 c-tint3 mt-2p">
                <Icon symbol="general#outlined-generic-file" />
              </div>
            )}
          </div>
          <div className="d-f fd-c overflow-text lh-1.25">
            <span className="overflow-text">{selectedFile.name}</span>
            <span className="fs-5 c-tint3">
              {formatFileSize(selectedFile.size, 1)}
            </span>
          </div>
          <button
            type="button"
            className="btn minor-compact-btn destroy-btn ml-1 mb-2"
            style={{ marginBottom: 14 }}
            title={`Remove ${selectedFile.name}`}
            aria-label={`Remove ${selectedFile.name}`}
            onClick={() => handleFileRemove()}
          >
            <Icon symbol="general#x" title="Remove" />
          </button>
        </div>
      )}
    </>
  )
}
