import { string } from "prop-types"

export function TakeAttendanceButton({ myEventUrl, status }) {
  return status && ["no_recording", "draft"].includes(status) ? (
    <a
      href={`${myEventUrl}/attendance`}
      className="minor-btn secondary-btn btn"
    >
      Take attendance
    </a>
  ) : null
}
TakeAttendanceButton.propTypes = {
  myEventUrl: string.isRequired,
  status: string,
}
