import { Routes, Route } from "react-router-dom"
import { HardRefresh } from "source/shared/routingUtils"
import { WideLayout } from "source/Layout"
import EncryptedRsvpsEdit from "source/groups/screens/encrypted_rsvps/Edit"
import EncryptedRsvpsShow from "source/groups/screens/encrypted_rsvps/Show"
import EventsIndex from "source/groups/screens/events/Index"
import EventsShow from "source/groups/screens/events/Show"
import GroupTypesIndex from "source/groups/screens/group_types/Index"
import GroupTypesShow from "source/groups/screens/group_types/Show"
import GroupsShow from "source/groups/screens/groups/Show"
import JoinCreate from "source/groups/screens/joins/Create"
import ProfileShow from "source/groups/screens/profiles/Show"

export function Groups() {
  return (
    <WideLayout>
      <Routes>
        <Route path="*" element={<HardRefresh />} />

        <Route path="/" element={<GroupTypesIndex />} />
        <Route path="/:groupTypeSlug" element={<GroupTypesShow />} />
        <Route path="/:groupTypeSlug/:groupSlug" element={<GroupsShow />} />
        <Route
          path="/encrypted_rsvps/:token"
          element={<EncryptedRsvpsEdit />}
        />
        <Route
          path="/encrypted_rsvps/result"
          element={<EncryptedRsvpsShow />}
        />
        <Route path="/join/:token" element={<JoinCreate />} />
        <Route path="/profile" element={<ProfileShow />} />
        <Route
          path="/groups/:groupSlug/events/:filter"
          element={<EventsIndex />}
        />
        <Route path="/events/:eventId" element={<EventsShow />} />
      </Routes>
    </WideLayout>
  )
}
