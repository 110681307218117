export function parseHTML(message) {
  const parser = new DOMParser()
  const { firstChild, children: bodyContent } = parser.parseFromString(
    message,
    "text/html",
  ).body

  const bodyNodes = Array.from(bodyContent)

  const isPlainTextBody = bodyNodes.length === 0
  if (isPlainTextBody) {
    return {
      messageFragments: [[message]],
      dataUUID: undefined,
    }
  }

  const messageFragments = bodyNodes.map(({ childNodes }) => {
    const children = Array.from(childNodes)
    return children.map(({ href, textContent }) => (href || textContent).trim())
  })

  return {
    messageFragments,
    dataUUID: firstChild.dataset.uuid,
  }
}

export function convertToExpandedLinks(messageFragments, expanded_links) {
  const { first, middle, last } = arrayParts(messageFragments)
  const leadingLink = findLink(expanded_links, first) || { url: "" }
  const trailingLink = findLink(expanded_links, last) || { url: "" }
  const messageString = [
    leadingLink.url.length > 0 ? "" : first,
    ...middle,
    trailingLink.url.length > 0 ? "" : last,
  ]
    .join(" ")
    .trim()

  return {
    leadingLink,
    messageString,
    trailingLink,
  }
}

export function arrayParts([first, ...rest]) {
  const lastIdx = rest.length - 1
  return {
    first: first,
    middle: rest.slice(0, lastIdx),
    last: rest[lastIdx],
  }
}

function findLink(expanded_links, maybeURL) {
  if (!expanded_links) return undefined
  if (!maybeURL) return undefined

  const expandedLink =
    expanded_links[maybeURL] ||
    expanded_links[maybeURL.slice(0, maybeURL.length - 1)] ||
    expanded_links[`${maybeURL}/`]
  if (expandedLink) {
    return { ...expandedLink, url: maybeURL }
  } else {
    return undefined
  }
}
