import { useReducer } from "react"
import { getRelationship } from "source/shared/getRelationship"

export function usePersonAndHouseholdDirectoryFieldData({
  person,
  households,
}) {
  const personDirectoryFieldData = serializeDirectoryFieldData(person)
  const householdDirectoryFieldData =
    serializeHouseholdDirectoryFieldData(households)
  const initialDirectoryFieldData = [
    ...personDirectoryFieldData,
    ...householdDirectoryFieldData,
  ]

  const [{ directoryFieldData, isCurrentlyInDirectory }, dispatch] = useReducer(
    manageDirectoryFieldDataReducer,
    {
      isCurrentlyInDirectory: !!initialDirectoryFieldData.length,
      directoryFieldData: initialDirectoryFieldData,
    },
  )

  return {
    directoryFieldData,
    dispatch,
    isCurrentlyInDirectory,
  }
}

function manageDirectoryFieldDataReducer(
  { isCurrentlyInDirectory, directoryFieldData },
  action,
) {
  const newState = buildNewDirectoryFieldDataState(directoryFieldData, action)
  if (action.persisted) {
    return {
      isCurrentlyInDirectory: !!newState.length,
      directoryFieldData: newState,
    }
  } else {
    return { isCurrentlyInDirectory, directoryFieldData: newState }
  }
}

function buildNewDirectoryFieldDataState(state, action) {
  switch (action.type) {
    case "append":
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload]
      } else {
        return [...state, action.payload]
      }
    case "set":
      return action.payload
    case "remove":
      return state.filter(
        ({ fieldType, displayableId, personId }) =>
          !(
            fieldType === action.payload.fieldType &&
            displayableId === action.payload.displayableId &&
            personId === action.payload.personId
          ),
      )
    case "removeAllForPerson":
      return state.filter(
        ({ personId }) => personId !== action.payload.personId,
      )
    default:
      throw new Error("Unrecognized action in manageDirectoryFieldDataReducer")
  }
}

function serializeDirectoryFieldData(person) {
  const directoryFieldData = getRelationship(person, "directory_field_data")
  return directoryFieldData.map(
    ({
      attributes: { field_type },
      relationships: {
        displayable: { data: displayableData } = {},
        person: { data: { id: personId } = {} } = {},
      } = {},
    }) => ({
      fieldType: field_type,
      displayableId: (displayableData && displayableData.id) || undefined,
      personId,
    }),
  )
}

function serializeHouseholdDirectoryFieldData({ data, included }) {
  return data.reduce((acc, household) => {
    const people = getRelationship({ data: household, included }, "people")
    return people.reduce(
      (innerAcc, person) => [
        ...innerAcc,
        ...serializeDirectoryFieldData({ data: person, included }),
      ],
      acc,
    )
  }, [])
}
