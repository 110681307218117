import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react"
import { shape, string, object, number } from "prop-types"
import { useRegistration } from "source/registrations/hooks/useRegistration"
import { useUncacheMyRegistrations } from "source/registrations/hooks/useMyRegistrations"
import { useMutation } from "source/registrations/utils/queryCache"
import { api } from "source/registrations/api/client"

import BirthdateField from "./PersonalInformation/BirthdateField"
import GenderField from "./PersonalInformation/GenderField"
import GradeField from "./PersonalInformation/GradeField"
import EmergencyContactField from "./PersonalInformation/EmergencyContactField"
import EmailAddressField from "./PersonalInformation/EmailAddressField"
import PhoneNumberField from "./PersonalInformation/PhoneNumberField"
import AddressField from "./PersonalInformation/AddressField"
import MedicalNotesField from "./PersonalInformation/MedicalNotesField"
import { Heading } from "@planningcenter/doxy-web"

const NamedAttendeePersonalInformation = forwardRef(
  ({ attendeeSummary, attendeeSelectionEdit }, ref) => {
    const {
      grade: personGrade,
      registrationId,
      id: attendeeId,
    } = attendeeSummary
    const { data: registration } = useRegistration(registrationId)
    const attendee = registration.attendees.find((attendee) => {
      return attendee.id == attendeeId
    })
    const { potentialEmergencyContacts = [], allowedAccountCenterIds = [] } =
      registration
    const {
      attendeeType: {
        collectionOptions: {
          grade: gradeOption,
          emergencyContact: emergencyContactOption,
          birthdate: birthdateOption,
          address: addressOption,
          email: emailOption,
          phone: phoneOption,
          medical: medicalOption,
          gender: genderOption,
        },
      },
      emergencyContact = {},
      person: {
        birthdate: personBirthdate,
        id: accountCenterId,
        gender: personGender,
        emailAddress: personEmailAddress,
        phoneNumber: personPhoneNumber,
        medicalNotes: personMedicalNotes,
        address: personAddress = {},
      } = {},
    } = attendee
    const { isEditing, onSave } = attendeeSelectionEdit

    const [selectedBirthdate, setSelectedBirthdate] = useState(personBirthdate)
    const [selectedGender, setSelectedGender] = useState(personGender)
    const [selectedGrade, setSelectedGrade] = useState(personGrade)
    const [selectedEmergencyContact, setSelectedEmergencyContact] = useState({
      id:
        !emergencyContact.phoneNumber ||
        emergencyContact.phoneNumber.length == 0
          ? null
          : emergencyContact.id,
      name: emergencyContact.name,
      phoneNumber: emergencyContact.phoneNumber,
    })
    const [selectedEmailAddress, setSelectedEmailAddress] =
      useState(personEmailAddress)
    const [selectedPhoneNumber, setSelectedPhoneNumber] =
      useState(personPhoneNumber)
    const [selectedAddress, setSelectedAddress] = useState(personAddress || {})
    const [selectedMedicalNotes, setSelectedMedicalNotes] =
      useState(personMedicalNotes)

    const currentPersonCanEditAttendeePerson =
      allowedAccountCenterIds.includes(accountCenterId)

    const saved = useRef(false)

    const birthdateChanged = useMemo(() => {
      return !(selectedBirthdate == personBirthdate)
    }, [selectedBirthdate, personBirthdate])

    const genderChanged = useMemo(() => {
      return !(selectedGender == personGender)
    }, [selectedGender, personGender])

    const gradeChanged = useMemo(() => {
      return !(selectedGrade == personGrade)
    }, [selectedGrade, personGrade])

    const emergencyContactChanged = useMemo(() => {
      return !(
        selectedEmergencyContact.name == emergencyContact.name &&
        selectedEmergencyContact.phoneNumber == emergencyContact.phoneNumber
      )
    }, [selectedEmergencyContact, emergencyContact])

    const emailAddressChanged = useMemo(() => {
      return !(selectedEmailAddress == personEmailAddress)
    }, [selectedEmailAddress, personEmailAddress])

    const phoneNumberChanged = useMemo(() => {
      return !(selectedPhoneNumber == personPhoneNumber)
    }, [selectedPhoneNumber, personPhoneNumber])

    const addressChanged = useMemo(() => {
      return !(
        selectedAddress?.street == personAddress?.street &&
        selectedAddress?.city == personAddress?.city &&
        selectedAddress?.state == personAddress?.state &&
        selectedAddress?.zip == personAddress?.zip
      )
    }, [selectedAddress, personAddress])

    const medicalNotesChanged = useMemo(() => {
      return !(selectedMedicalNotes == personMedicalNotes)
    }, [selectedMedicalNotes, personMedicalNotes])

    const somethingChanged = useMemo(() => {
      return (
        birthdateChanged ||
        genderChanged ||
        gradeChanged ||
        emergencyContactChanged ||
        emailAddressChanged ||
        phoneNumberChanged ||
        addressChanged ||
        medicalNotesChanged
      )
    }, [
      birthdateChanged,
      genderChanged,
      gradeChanged,
      emergencyContactChanged,
      emailAddressChanged,
      phoneNumberChanged,
      addressChanged,
      medicalNotesChanged,
    ])

    const uncacheMyRegistrations = useUncacheMyRegistrations()

    const registrationMutation = useMutation({
      mutationFn: async (params) => {
        const { errors, data } = await api.patch(
          `/registrations/v2/registrations/${registrationId}`,
          params,
        )
        return errors ? Promise.reject(errors[0]) : data
      },
    })

    const saveChanges = useCallback(() => {
      registrationMutation.mutate(
        {
          data: {
            attributes: {},
          },
          included: [
            {
              type: "AttendeePerson",
              attributes: {
                attendeeId: attendeeId,
                personAttributes: {
                  accountCenterId: attendee.personId,
                  ...(gradeChanged && { grade: selectedGrade }),
                  ...(genderChanged && { gender: selectedGender }),
                  ...(birthdateChanged && { birthdate: selectedBirthdate }),
                  ...(medicalNotesChanged && {
                    medicalNotes: selectedMedicalNotes,
                  }),
                  ...(emailAddressChanged && {
                    emailAddressesAttributes: [
                      {
                        location: "Home",
                        primary: true,
                        address: selectedEmailAddress,
                      },
                    ],
                  }),
                  ...(phoneNumberChanged && {
                    phoneNumbersAttributes: [
                      {
                        location: "Home",
                        primary: true,
                        number: selectedPhoneNumber,
                      },
                    ],
                  }),
                  ...(addressChanged && {
                    addressesAttributes: [
                      {
                        location: "Home",
                        primary: true,
                        street: selectedAddress.street,
                        city: selectedAddress.city,
                        state: selectedAddress.state,
                        zip: selectedAddress.zip,
                      },
                    ],
                  }),
                },
                ...(emergencyContactChanged && {
                  emergencyContactAttributes: {
                    name: selectedEmergencyContact.name,
                    phoneNumber: selectedEmergencyContact.phoneNumber,
                  },
                }),
              },
            },
          ],
        },
        {
          onSuccess: (_data, _variables, _context) => {
            saved.current = true
            onSave({ personalInformation: true })
            uncacheMyRegistrations()
          },
          onError: (_error, _params, _context) => {
            saved.current = true
            onSave({ personalInformation: false })
          },
        },
      )
    }, [
      registrationMutation,
      attendee,
      onSave,
      selectedGrade,
      selectedEmergencyContact,
      birthdateChanged,
      emergencyContactChanged,
      gradeChanged,
      attendeeId,
      genderChanged,
      selectedBirthdate,
      selectedGender,
      emailAddressChanged,
      selectedEmailAddress,
      medicalNotesChanged,
      selectedMedicalNotes,
      addressChanged,
      selectedAddress,
      phoneNumberChanged,
      selectedPhoneNumber,
      uncacheMyRegistrations,
    ])

    const resetGrade = useCallback(
      () => setSelectedGrade(personGrade),
      [personGrade],
    )

    const resetEmergencyContact = useCallback(
      () =>
        setSelectedEmergencyContact({
          id: emergencyContact.id,
          name: emergencyContact.name,
          phoneNumber: emergencyContact.phoneNumber,
        }),
      [emergencyContact],
    )

    const handleReset = useCallback(() => {
      resetEmergencyContact()
      resetGrade()
      setSelectedBirthdate(personBirthdate)
      setSelectedAddress(personAddress)
      setSelectedEmailAddress(personEmailAddress)
      setSelectedGender(personGender)
      setSelectedMedicalNotes(personMedicalNotes)
      setSelectedPhoneNumber(personPhoneNumber)
    }, [
      resetEmergencyContact,
      resetGrade,
      personBirthdate,
      setSelectedPhoneNumber,
      personPhoneNumber,
      setSelectedBirthdate,
      setSelectedAddress,
      personAddress,
      setSelectedEmailAddress,
      personEmailAddress,
      setSelectedGender,
      personGender,
      setSelectedMedicalNotes,
      personMedicalNotes,
    ])

    useEffect(() => {
      if (somethingChanged && !isEditing) {
        handleReset()
      }
    }, [somethingChanged, isEditing, handleReset])

    useEffect(() => {
      if (isEditing) {
        if (
          attendeeSelectionEdit.changes.personalInformation != somethingChanged
        ) {
          attendeeSelectionEdit.onChange({
            personalInformation: somethingChanged,
          })
        }
      }
    }, [attendeeSelectionEdit, isEditing, somethingChanged])

    const handleSaveClick = useCallback(() => {
      if (somethingChanged) {
        saveChanges()
      }
    }, [somethingChanged, saveChanges])

    useImperativeHandle(ref, () => {
      return {
        save() {
          handleSaveClick()
        },
      }
    }, [handleSaveClick])

    const birthdateFieldProps = useMemo(() => {
      return {
        canEdit: !personBirthdate && currentPersonCanEditAttendeePerson,
        show: birthdateOption !== "hidden",
        isRequired: birthdateOption == "required",
        value: selectedBirthdate,
        hasPersistedValue: !!personBirthdate,
        onChange: (value) => {
          setSelectedBirthdate(value)
        },
      }
    }, [
      birthdateOption,
      currentPersonCanEditAttendeePerson,
      selectedBirthdate,
      personBirthdate,
    ])

    const emailFieldProps = useMemo(() => {
      return {
        canEdit: !personEmailAddress && currentPersonCanEditAttendeePerson,
        show: emailOption !== "hidden",
        isRequired: emailOption == "required",
        value: selectedEmailAddress,
        hasPersistedValue: !!personEmailAddress,
        onChange: (value) => {
          setSelectedEmailAddress(value)
        },
      }
    }, [
      personEmailAddress,
      currentPersonCanEditAttendeePerson,
      selectedEmailAddress,
      emailOption,
    ])

    const genderFieldProps = useMemo(() => {
      return {
        canEdit: !personGender && currentPersonCanEditAttendeePerson,
        show: genderOption !== "hidden",
        isRequired: genderOption == "required",
        value: selectedGender,
        hasPersistedValue: personGender && personGender.length > 0,
        onChange: (value) => {
          setSelectedGender(value)
        },
      }
    }, [
      personGender,
      currentPersonCanEditAttendeePerson,
      selectedGender,
      genderOption,
    ])

    const gradeFieldProps = useMemo(() => {
      return {
        canEdit:
          !personGrade &&
          personGrade != 0 &&
          currentPersonCanEditAttendeePerson,
        show: gradeOption !== "hidden",
        isRequired: gradeOption == "required",
        value: selectedGrade,
        hasPersistedValue: !!personGrade || personGrade == 0,
        onChange: (value) => {
          setSelectedGrade(value)
        },
      }
    }, [
      personGrade,
      currentPersonCanEditAttendeePerson,
      selectedGrade,
      gradeOption,
    ])

    const emergencyContactFieldProps = useMemo(() => {
      return {
        canEdit: true,
        show: emergencyContactOption !== "hidden",
        isRequired: emergencyContactOption == "required",
        value: selectedEmergencyContact,
        hasPersistedValue:
          emergencyContact.phoneNumber &&
          emergencyContact.phoneNumber.length > 0,
        onChange: (contact) => {
          setSelectedEmergencyContact(contact)
        },
      }
    }, [selectedEmergencyContact, emergencyContactOption, emergencyContact])

    const phoneNumberFieldProps = useMemo(() => {
      return {
        canEdit: !personPhoneNumber && currentPersonCanEditAttendeePerson,
        show: phoneOption !== "hidden",
        isRequired: phoneOption == "required",
        value: selectedPhoneNumber,
        hasPersistedValue: personPhoneNumber && personPhoneNumber.length > 0,
        onChange: (value) => {
          setSelectedPhoneNumber(value)
        },
      }
    }, [
      personPhoneNumber,
      currentPersonCanEditAttendeePerson,
      phoneOption,
      selectedPhoneNumber,
    ])

    const addressFieldProps = useMemo(() => {
      const hasExistingAddress = () => {
        return (
          personAddress &&
          (personAddress.street.length > 0 ||
            personAddress.city.length > 0 ||
            personAddress.state.length > 0 ||
            personAddress.zip.length > 0)
        )
      }

      return {
        canEdit: !hasExistingAddress() && currentPersonCanEditAttendeePerson,
        show: addressOption !== "hidden",
        isRequired: addressOption == "required",
        value: selectedAddress,
        hasPersistedValue: hasExistingAddress(),
        onChange: (value) => {
          setSelectedAddress(value)
        },
      }
    }, [
      personAddress,
      addressOption,
      currentPersonCanEditAttendeePerson,
      selectedAddress,
    ])

    const medicalFieldProps = useMemo(() => {
      return {
        canEdit: !personMedicalNotes && currentPersonCanEditAttendeePerson,
        show: medicalOption !== "hidden",
        isRequired: medicalOption == "required",
        value: selectedMedicalNotes,
        hasPersistedValue: !!personMedicalNotes,
        onChange: (value) => {
          setSelectedMedicalNotes(value)
        },
      }
    }, [
      personMedicalNotes,
      currentPersonCanEditAttendeePerson,
      medicalOption,
      selectedMedicalNotes,
    ])

    const possibleFields = {
      birthdate: birthdateOption !== "hidden",
      gender: genderOption !== "hidden",
      grade: gradeOption !== "hidden",
      emergencyContact: emergencyContactOption !== "hidden",
      email: emailOption !== "hidden",
      phone: phoneOption !== "hidden",
      address: addressOption !== "hidden",
      medicl: medicalOption !== "hidden",
    }

    const anyCollectedFields = Object.values(possibleFields).some(
      (field) => field,
    )

    const Birthdate = useMemo(() => {
      return (
        <BirthdateField
          attendee={attendee}
          isEditing={isEditing}
          {...birthdateFieldProps}
        />
      )
    }, [attendee, isEditing, birthdateFieldProps])

    const Email = useMemo(() => {
      return (
        <EmailAddressField
          attendee={attendee}
          isEditing={isEditing}
          {...emailFieldProps}
        />
      )
    }, [attendee, isEditing, emailFieldProps])

    const Gender = useMemo(() => {
      return (
        <GenderField
          attendee={attendee}
          isEditing={isEditing}
          {...genderFieldProps}
        />
      )
    }, [attendee, isEditing, genderFieldProps])

    const Grade = useMemo(() => {
      return (
        <GradeField
          attendee={attendee}
          isEditing={isEditing}
          {...gradeFieldProps}
        />
      )
    }, [attendee, isEditing, gradeFieldProps])

    const EmergencyContact = useMemo(() => {
      return (
        <EmergencyContactField
          attendee={attendee}
          potentialEmergencyContacts={potentialEmergencyContacts}
          isEditing={isEditing}
          {...emergencyContactFieldProps}
        />
      )
    }, [
      attendee,
      isEditing,
      emergencyContactFieldProps,
      potentialEmergencyContacts,
    ])

    const Phone = useMemo(() => {
      return (
        <PhoneNumberField
          attendee={attendee}
          isEditing={isEditing}
          {...phoneNumberFieldProps}
        />
      )
    }, [attendee, isEditing, phoneNumberFieldProps])

    const Address = useMemo(() => {
      return (
        <AddressField
          attendee={attendee}
          isEditing={isEditing}
          {...addressFieldProps}
        />
      )
    }, [attendee, isEditing, addressFieldProps])

    const Medical = useMemo(() => {
      return (
        <MedicalNotesField
          attendee={attendee}
          isEditing={isEditing}
          {...medicalFieldProps}
        />
      )
    }, [attendee, isEditing, medicalFieldProps])

    return (
      anyCollectedFields && (
        <div className="mt-3 mb-1">
          <Heading level={3} size={4} text="Profile Information"></Heading>
          <div>{possibleFields.birthdate && Birthdate}</div>
          <div>{possibleFields.gender && Gender}</div>
          <div>{possibleFields.grade && Grade}</div>
          <div>{possibleFields.emergencyContact && EmergencyContact}</div>
          <div>{possibleFields.email && Email}</div>
          <div>{possibleFields.phone && Phone}</div>
          <div>{possibleFields.address && Address}</div>
          <div>{possibleFields.medical && Medical}</div>
        </div>
      )
    )
  },
)

NamedAttendeePersonalInformation.propTypes = {
  attendeeSummary: shape({
    registrationId: number,
    id: number,
  }),
  attendee: shape({
    attendeeType: shape({
      collectionOptions: shape({
        grade: string,
        emergencyContact: string,
      }),
    }),
  }),
  attendeeSelectionEdit: object,
}

NamedAttendeePersonalInformation.displayName =
  "NamedAttendeePersonalInformation"

export default NamedAttendeePersonalInformation
