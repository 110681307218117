import _ from "lodash"
import { array, bool, func } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Heading } from "@planningcenter/doxy-web"
import ScheduleRequest from "./ScheduleRequest"
import SignupSheet from "./SignupSheet"
import PendingGroupsEvent from "./PendingGroupsEvent"
import ServicesOptionsDropdown from "source/myChurchCenter/ServicesOptionsDropdown"

const PENDING_COMPONENTS = {
  ScheduleRequest: ScheduleRequest,
  SignupSheet: SignupSheet,
  PendingGroupsEvent: PendingGroupsEvent,
}

function renderPendingItem(item, refreshSchedule) {
  const Component = PENDING_COMPONENTS[item.type]

  return Component ? (
    <Component key={item.id} refreshSchedule={refreshSchedule} {...item} />
  ) : null
}

PendingSection.propTypes = {
  pendingItems: array,
  refreshSchedule: func,
  showOptionsDropdown: bool,
  hasServicesAbilities: bool,
}

export default function PendingSection({
  pendingItems,
  refreshSchedule,
  showOptionsDropdown,
  hasServicesAbilities,
}) {
  if (_.isEmpty(pendingItems)) return null

  const scheduleRequests = _.filter(pendingItems, {
    type: "ScheduleRequest",
  })
  const schedulesToAccept = _.flatMap(scheduleRequests, "schedules")
  const showAcceptAllSchedules = _.some(schedulesToAccept)

  const handleAcceptAllScheduleRequests = () => {
    Promise.all(
      schedulesToAccept.map((schedule) =>
        sessionApiClient.post(
          `/services/v2/me/unscoped_schedules/${schedule.id}/accept`,
          {},
        ),
      ),
    )
      .then(refreshSchedule)
      .catch(() => {
        alert("Oops, something went wrong. Please try again.")
      })
  }

  return (
    <div className="mb-4">
      <div className="d-f ai-c jc-sb mb-1">
        <Heading level={2} text="Requests" />
        {showOptionsDropdown && (
          <ServicesOptionsDropdown
            handleAcceptAllScheduleRequests={handleAcceptAllScheduleRequests}
            hasServicesAbilities={hasServicesAbilities}
            showAcceptAllSchedules={showAcceptAllSchedules}
          />
        )}
      </div>
      <div
        className="d-g"
        css={{
          gridTemplateColumns: "repeat(auto-fill,minmax(240px,1fr))",
          gridGap: "16px",
        }}
      >
        {pendingItems.map((item) => renderPendingItem(item, refreshSchedule))}
      </div>
    </div>
  )
}
