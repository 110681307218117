import { Suspense, useEffect, useRef, useState } from "react"
import { bool, node, string } from "prop-types"
import { Link, Navigate, useLocation } from "react-router-dom"
import { Loading } from "source/shared/components"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import { useApiRead } from "source/shared/SessionApiResource"
import BlankState from "source/groups/BlankState"
import { BreadcrumbNav } from "./components/BreadcrumbNav"
import { GroupList } from "./components/GroupList"
import { Filters } from "./components/Filters"
import { FiltersContextProvider } from "./contexts/FiltersContext"
import { MapContextProvider } from "./contexts/MapContext"
import { useGroupType } from "./hooks/useGroupType"
import { Grid } from "@planningcenter/doxy-web"
import { css } from "@emotion/react"
import { simplifyGroupTypeDescription } from "source/groups/utils/simplifyGroupTypeDescription"

const CHARACTER_LIMIT = 300

export default function Entry() {
  useQRCode()

  return (
    <Suspense fallback={<Loading />}>
      <GroupType />
    </Suspense>
  )
}

function GroupType() {
  const { data, errors } = useGroupType()
  const { name, description, description_as_plain_text } = data.attributes

  const {
    meta: { total_count: totalGroupTypeCount },
  } = useApiRead("/groups/v2/group_types?per_page=0")

  if (errors) {
    return (
      <BlankState icon="churchCenter#groups-o">
        <p className="mb-2">This group type is currently unavailable</p>
        <Link to="/groups" className="mr-1 minor-btn secondary-btn btn">
          View all groups
        </Link>
      </BlankState>
    )
  }

  return (
    <RedirectIfHistoricalSlug>
      <OpenGraphTags title={name} description={description} />
      {totalGroupTypeCount > 1 && <BreadcrumbNav />}
      {description && (
        <Description plainText={description_as_plain_text}>
          {description}
        </Description>
      )}
      <FiltersContextProvider>
        <MapContextProvider>
          <Grid columns={1} gap={3}>
            <Filters />
            <Suspense fallback={<Loading />}>
              <GroupList />
            </Suspense>
          </Grid>
        </MapContextProvider>
      </FiltersContextProvider>
    </RedirectIfHistoricalSlug>
  )
}

function RedirectIfHistoricalSlug({ children }) {
  const {
    data: {
      attributes: { church_center_web_url },
    },
  } = useGroupType()
  const { pathname: actualPath } = useLocation()
  const expectedPath = new URL(church_center_web_url).pathname

  return expectedPath === actualPath ? (
    children
  ) : (
    <Navigate to={expectedPath} replace />
  )
}
RedirectIfHistoricalSlug.propTypes = {
  children: node,
}

function Description({ children, plainText }) {
  const simpleDescription = simplifyGroupTypeDescription(plainText)
  const descriptionLength = simpleDescription?.length
  const expandable = descriptionLength > CHARACTER_LIMIT

  {
    /* description is sanitized on the vertex */
  }
  return (
    <section className="mb-3">
      <ExpandingDescription expandable={expandable}>
        <div
          id="styles-for-group-type-descriptions"
          data-preformatted-content="heading-scale:legacy-church-center"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </ExpandingDescription>
    </section>
  )
}
Description.propTypes = {
  children: node,
  plainText: string,
}

function ExpandingDescription({ children, expandable }) {
  const contentRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [maxHeight, setMaxHeight] = useState("12rem")

  const styles = useStyles(maxHeight, expanded)
  const buttonLabel = expanded ? "Read less" : "Read more"

  useEffect(() => {
    if (expanded) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`)
    } else {
      setMaxHeight("12rem")
    }
  }, [expanded])

  const handleExpanded = () => setExpanded((prevExpanded) => !prevExpanded)

  if (!expandable) {
    return children
  }

  return (
    <>
      <div ref={contentRef} className="p-r o-h" css={styles.wrapper}>
        {children}
        <span className="p-a b-0 l-0 r-0" css={styles.gradientFooter} />
      </div>
      <button
        onClick={handleExpanded}
        className="btn md-btn naked-btn p-0 mt-4p"
      >
        {buttonLabel}
      </button>
    </>
  )
}
ExpandingDescription.propTypes = {
  children: node,
  expandable: bool,
}

const useStyles = (maxHeight, expanded) => {
  return {
    wrapper: css`
      max-height: ${maxHeight};
      transition: max-height 0.5s ease;
    `,
    gradientFooter: css`
      background: linear-gradient(180deg, transparent, white);
      height: 3rem;
      display: ${expanded ? "none" : "block"};
    `,
  }
}
