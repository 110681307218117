import { useContext, useEffect, useCallback, useState } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { JoltContext } from "source/shared/contexts/JoltContext"
import { arrayOf, exact, node, string } from "source/shared/prop_types"

export default function LiveUpdates({
  children,
  dataContext,
  events,
  subscribeTo,
  fetchSubscribeTokenURL,
}) {
  const { jolt } = useContext(JoltContext)
  const { ingest, del, react } = useContext(dataContext)
  const [channel, setChannel] = useState(null)
  const funcs = {
    ingest: useCallback(
      (message) => ingest(JSON.parse(message.data)),
      [ingest],
    ),
    remove: useCallback(
      (message) => del(JSON.parse(message.data).data.id),
      [del],
    ),
    react: useCallback((reaction) => react(JSON.parse(reaction.data)), [react]),
  }
  const fetchSubscribeTokenFn = useCallback(
    async (channel, connection_id) => {
      const response = await sessionApiClient.post(fetchSubscribeTokenURL, {
        data: {
          attributes: {
            channel,
            connection_id,
          },
        },
      })
      return response.data.id
    },
    [fetchSubscribeTokenURL],
  )

  useEffect(() => {
    if (jolt) {
      setChannel(jolt.subscribe(subscribeTo, { fetchSubscribeTokenFn }))
      return () => jolt.unsubscribe(subscribeTo)
    }
  }, [subscribeTo])

  useEffect(() => {
    if (channel) {
      let event = `jolt.subscriptionStats`
      return channel.bind(event, (message) =>
        console.log(
          `TODO: There are currently ${
            JSON.parse(message.data).subscribers
          } subscribed chatters on this channel.`,
        ),
      )
    }
  }, [channel])

  events.forEach(({ event, func }) => {
    useEffect(() => {
      if (channel) return channel.bind(event, funcs[func])
    }, [channel])
  })

  return children
}

LiveUpdates.propTypes = {
  children: node.isRequired,
  events: arrayOf(
    exact({
      event: string.isRequired,
      func: string.isRequired,
    }),
  ).isRequired,
  fetchSubscribeTokenURL: string.isRequired,
  subscribeTo: string.isRequired,
}
