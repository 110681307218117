import { api } from "source/registrations/api/client"
import {
  useSuspenseQuery,
  useMutation,
  splitQueryParams,
  queryFn,
  useQueryClient,
} from "source/registrations/utils/queryCache"
import { useUncacheMyRegistrations } from "./useMyRegistrations"

export const usePaymentIntent = (registrationId, options = {}) => {
  const path = `/registrations/v2/registrations/${registrationId}/payment_intent`
  const { params, queryOptions } = splitQueryParams(options)

  return useSuspenseQuery({
    queryFn,
    queryKey: [path, params],
    ...queryOptions,
  })
}

export const usePaymentIntentMutation = (registrationId, paymentIntent) => {
  const request = paymentIntent?.id ? api.patch : api.post
  const path = `/registrations/v2/registrations/${registrationId}/payment_intent`

  const queryClient = useQueryClient()
  const uncacheMyRegistrations = useUncacheMyRegistrations()

  return useMutation({
    mutationFn: async (params) => {
      const { errors, data } = await request(path, {
        type: "PaymentIntent",
        data: { attributes: params },
      })
      return errors ? Promise.reject(errors[0]) : data
    },
    onSuccess: (data, _params, _context) => {
      queryClient.setQueryData([path, {}], () => data)
      uncacheMyRegistrations()
    },
  })
}

export const useInvalidatePaymentIntent = (registrationId, options = {}) => {
  const queryClient = useQueryClient()
  const path = `/registrations/v2/registrations/${registrationId}/payment_intent`
  const { params } = splitQueryParams(options)

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: [path, params],
    })
  }
  return invalidate
}
