import { node } from "prop-types"
import { useApiRead } from "source/shared/SessionApiResource"
import { Heading } from "@planningcenter/doxy-web"
import { Card, CardGrid } from "source/shared/components"

export function MyGroupsHeader() {
  const GROUP_LIMIT = 3
  const {
    data: myGroups,
    meta: { total_count },
  } = useApiRead(
    `/groups/v2/me/groups?per_page=${GROUP_LIMIT}&fields[Group]=name,church_center_web_my_group_url,header_image`,
  )

  return (
    total_count > 0 && (
      <Container>
        <CardGrid>
          {myGroups.map((group) => (
            <Card
              key={group.id}
              link={group.attributes.church_center_web_my_group_url}
              heading={group.attributes.name}
              poster={group.attributes.header_image.medium}
              posterAltText={`Logo for ${group.attributes.name}`}
            />
          ))}
        </CardGrid>
        {total_count > GROUP_LIMIT && <SeeAllGroups />}
      </Container>
    )
  )
}

function SeeAllGroups() {
  return (
    <div className="fs-4 mt-1">
      <a href="/groups/profile#groups_collection">See all of my groups</a>
    </div>
  )
}

function Container({ children }) {
  return (
    <div className="group-types-collection mb-4">
      <div className="mb-2">
        <Heading level={2} text="My groups" />
      </div>
      {children}
    </div>
  )
}
Container.propTypes = { children: node }
