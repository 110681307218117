import { Component } from "react"
import { LocationMap } from "source/shared/components"
import { Subheading } from "@planningcenter/doxy-web"

export default class EventLocation extends Component {
  getLocationName = () => {
    let {
      location: { locationType, name },
    } = this.props

    if (locationType === "online") {
      if (name) {
        name = `${name} (Online)`
      } else {
        name = "Online"
      }
    }

    return name
  }

  render() {
    if (!this.props.location) return null

    const { registeredForEvent, location, isAnnouncementOnlyEvent } = this.props
    const { locationType, url } = location

    return (
      <div className="mb-3 mb-4@md">
        <div className="mb-1 d-f ai-c jc-sb fw-w">
          <Subheading level={2} text="Location" />
          {locationType === "online" &&
            url &&
            (registeredForEvent || isAnnouncementOnlyEvent) && (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="minor-btn secondary-btn btn"
              >
                Attend Online
              </a>
            )}
        </div>
        <div className="fs-4">{this.getLocationName()}</div>
        {locationType !== "online" && <LocationMap location={location} />}
      </div>
    )
  }
}
