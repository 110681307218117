import { useState, useEffect, useMemo } from "react"
import { css } from "@emotion/react"
import { string } from "prop-types"
import { isEmpty, padStart } from "lodash"
import {
  getDateDiff,
  hasMoreThanXMinutesLeft,
} from "source/registrations/utils/dates"

const CountdownTimer = ({ expiresAt }) => {
  const [dateDiff, setDateDiff] = useState({})
  const date = new Date()
  const expiresDate = useMemo(() => new Date(expiresAt), [expiresAt])
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    if (expired) return

    const timer = setInterval(() => {
      setDateDiff(getDateDiff(date, expiresDate))
    }, 1000)
    return () => clearInterval(timer)
  }, [dateDiff, expired, date, expiresDate])

  useEffect(() => {
    setExpired(date > expiresDate)
  }, [date, expiresDate])

  const timeRemaining = useMemo(
    () =>
      isEmpty(dateDiff)
        ? "--:--"
        : `${padStart(dateDiff.minutes, 2, "0")}:${padStart(
            dateDiff.seconds,
            2,
            "0",
          )}`,
    [dateDiff],
  )

  const styles = css`
    background: ${timeRemaining < "01:01"
      ? "var(--color-ruby)"
      : "var(--color-topaz)"};
    border-radius: 25px;
    color: var(--color-tint10);
    display: ${isEmpty(dateDiff) ? "none" : "block"};
    padding: 4px 8px;
    font-size: 12px;
    width: ${expired ? "auto" : "52px"};
    text-align: center;
  `

  return hasMoreThanXMinutesLeft(15, expiresDate) ? null : (
    <div css={styles}>{expired ? "Registration expired" : timeRemaining}</div>
  )
}

CountdownTimer.propTypes = {
  expiresAt: string,
}

export default CountdownTimer
