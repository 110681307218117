import { Link } from "react-router-dom"
import { any, arrayOf, shape, string } from "prop-types"
import { colors } from "source/shared/colors"
import { Icon } from "source/shared/components"

MyProfileLinks.propTypes = {
  links: arrayOf(
    shape({
      to: string.isRequired,
      children: any.isRequired,
      icon: string.isRequired,
    }),
  ),
}
export function MyProfileLinks({ links }) {
  const styles = {
    link: {
      alignItems: "center",
      backgroundColor: colors.tint9,
      borderRadius: 4,
      border: 0,
      color: colors.tint1,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 8,
      padding: "12px 16px",
      width: "100%",
      transition: "all 0.2s",

      "& .chevron": {
        color: colors.tint4,
      },

      "&:hover": {
        backgroundColor: colors.tint8,
        cursor: "pointer",
        "& .chevron": { color: colors.tint3 },
      },
    },
    appIcon: {
      color: colors.tint3,
    },
  }

  const symbolFor = ({ icon }) =>
    icon.includes("#") ? icon : `churchCenter#${icon}-o`

  return (
    <div className="mb-4">
      {links.map((link) => (
        <Link key={link.to} {...link} css={styles.link}>
          <div className="fw-400 d-f ai-c">
            <div className="mr-2 fs-1 d-f ai-c" css={styles.appIcon}>
              <Icon symbol={symbolFor(link)} aria-hidden />
            </div>
            <span>{link.children}</span>
          </div>
          <div className="chevron">
            <Icon symbol="general#right-chevron" aria-hidden />
          </div>
        </Link>
      ))}
    </div>
  )
}
