import { useContext, Fragment, Suspense, useState } from "react"
import { Helmet } from "react-helmet-async"
import NoLogoPlaceholder from "./noLogoPlaceholder"
import { useApiRead } from "source/shared/SessionApiResource"
import { Loading, Icon, Card, CardGrid } from "source/shared/components"
import { arrayOf, string, func, shape } from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import {
  createResource,
  useCacheRead,
} from "source/shared/react-cache-but-its-a-context"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useQRCode } from "source/shared/QRCode"
import { useCustomNavigationLabel } from "source/publishing/WebBoot"
import { SuccessMessage } from "./SuccessMessage"

export const UnknownFilterEvents = () => {
  const { filter, filterId } = useParams()
  let campusId, categoryId

  if (filter) {
    if (filter === "campus") {
      campusId = filterId
    } else {
      categoryId = filterId
    }
  }

  return <Events campusId={campusId} categoryId={categoryId} />
}

export const FilteredEvents = () => {
  const { campusId, categoryId } = useParams()

  return <Events campusId={campusId} categoryId={categoryId} />
}

const EventFilterOptionsResource = createResource(
  () => {
    return Promise.all([
      sessionApiClient.get(
        "/registrations/v2/categories?order=name&per_page=100",
        {},
        {
          version: "2020-06-16",
        },
      ),
      sessionApiClient.get(
        "/registrations/v2/campuses?order=name&per_page=100",
        {},
        {
          version: "2020-06-16",
        },
      ),
    ])
      .then(([{ data: categories }, { data: campuses }]) => ({
        categories,
        campuses,
      }))
      .catch((_error) => ({ categories: [], campuses: [] }))
  },
  () => "EventFilterOptionsResource",
)

const Events = ({ campusId, categoryId }) => {
  const { categories, campuses } = useCacheRead(EventFilterOptionsResource)
  useQRCode()

  let selectedCampus, selectedCategory

  if (campusId || categoryId) {
    selectedCampus =
      campusId &&
      campuses.find((campus) => {
        return campus.id === campusId
      })
    selectedCategory =
      categoryId &&
      categories.find((category) => {
        return category.id === categoryId
      })
  }

  const filter = [
    "order=starts_at",
    "filter=unarchived,published",
    "fields[Event]=name,featured,logo_url,event_time,starts_at,ends_at,registration_state",
    "per_page=100",
  ]
  if (selectedCampus) {
    filter.push(
      `where[campus]=${encodeURIComponent(selectedCampus.attributes.name)}`,
    )
  }

  if (selectedCategory) {
    filter.push(
      `where[category]=${encodeURIComponent(selectedCategory.attributes.name)}`,
    )
  }

  const urlParams = new URLSearchParams(window?.location?.search)
  const unsub = urlParams.get("unsub")

  return (
    <Fragment>
      <MetaInformationForDocumentHeader />
      <FilterBar
        campuses={campuses}
        categories={categories}
        campus={selectedCampus}
        category={selectedCategory}
      />
      {unsub && (
        <SuccessMessage>
          You have been removed from future notifications for this signup.
        </SuccessMessage>
      )}
      <Suspense fallback={<Loading />}>
        <EventList filter={filter} />
      </Suspense>
    </Fragment>
  )
}

Events.propTypes = {
  campusId: string,
  categoryId: string,
}

const EventList = ({ filter = [] }) => {
  const path = ["/registrations/v2/events", filter.join("&")]
    .filter(Boolean)
    .join("?")

  const { data: events } = useApiRead(path, { version: "2020-06-16" })

  return events.length > 0 ? (
    <CardGrid>
      {events.map((event, index) => {
        return <EventItem event={event} key={index} />
      })}
    </CardGrid>
  ) : (
    <NoEventsPlaceholder />
  )
}
const MetaInformationForDocumentHeader = () => {
  const {
    layout: {
      organization_name: organizationName,
      organization_avatar_url: avatarUrl,
    },
  } = useContext(ServerRenderedProps)
  const title = useCustomNavigationLabel("Events")

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content="Events" />
      <meta
        property="og:description"
        content={`Events at ${organizationName}`}
      />
      <meta property="og:image" content={avatarUrl} />
    </Helmet>
  )
}

EventList.propTypes = {
  filter: arrayOf(string),
}

const FilterBar = ({ campuses, categories, campus, category }) => {
  const navigate = useNavigate()

  const defaultCampusFilter = "All Campuses"
  const defaultCategoryFilter = "All Categories"
  const selectedCampus = campus ? campus.attributes.name : defaultCampusFilter
  const selectedCategory = category
    ? category.attributes.name
    : defaultCategoryFilter

  const handleCampusChange = (e) => {
    handleFilterChange({ campus: e, category: selectedCategory })
  }

  const handleCategoryChange = (e) => {
    handleFilterChange({ campus: selectedCampus, category: e })
  }

  const handleFilterChange = ({ campus, category }) => {
    let path = "/registrations/events"

    if (campus && campus != defaultCampusFilter) {
      path += `/campus/${
        campuses.find((c) => {
          return c.attributes.name === campus
        }).id
      }`
    }

    if (category && category != defaultCategoryFilter) {
      path += `/category/${
        categories.find((c) => {
          return c.attributes.name === category
        }).id
      }`
    }

    navigate(path)
  }

  return (
    <div className="action-drawer mb-2 mb-4@md d-f@sm ai-fs">
      <div className="d-b d-f@sm fw-w mb-1 mb-0@sm mr-1@sm">
        {campuses.length > 1 && (
          <Filter
            items={campuses.map((campus) => {
              return campus.attributes.name
            })}
            selected={selectedCampus}
            selectedChanged={handleCampusChange}
            defaultFilter={defaultCampusFilter}
          />
        )}
      </div>
      <div className="d-b d-f@sm fw-w">
        {categories.length > 0 && (
          <Filter
            items={categories.map((category) => {
              return category.attributes.name
            })}
            selected={selectedCategory}
            selectedChanged={handleCategoryChange}
            defaultFilter={defaultCategoryFilter}
          />
        )}
      </div>
    </div>
  )
}

const campusShape = shape({
  id: string,
  attributes: shape({
    name: string,
  }),
})

const categoryShape = shape({
  id: string,
  attributes: shape({
    name: string,
  }),
})

FilterBar.propTypes = {
  campuses: arrayOf(campusShape),
  categories: arrayOf(categoryShape),
  campus: campusShape,
  category: categoryShape,
}

const Filter = ({
  items,
  defaultFilter,
  selected,
  selectedChanged = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const onSelect = (clicked) => {
    if (clicked !== selected) {
      selectedChanged(clicked)
    }
    setIsOpen(false)
  }

  return (
    <div className={`dropdown ${isOpen ? "is-open" : ""}`}>
      <button className="select ta-l" onClick={toggle}>
        <span>{selected}</span>
        <span className="caret c-primary" />
      </button>
      <ul className="dropdown__menu">
        <li className="dropdown__item p-0">
          <button
            style={{
              display: "block",
              color: "#424242",
              lineHeight: "1.25",
              padding: "0.5rem 0.75rem",
              backgroundColor: "transparent",
              border: "none",
              fontWeight: 400,
              textAlign: "left",
              width: "100%",
            }}
            onClick={() => {
              onSelect(defaultFilter)
            }}
          >
            {defaultFilter}
          </button>
        </li>
        {items.map((item, index) => {
          return (
            <li key={index} className="dropdown__item p-0">
              <button
                style={{
                  display: "block",
                  color: "#424242",
                  lineHeight: "1.25",
                  padding: "0.5rem 0.75rem",
                  backgroundColor: "transparent",
                  border: "none",
                  fontWeight: 400,
                  textAlign: "left",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  onSelect(item)
                }}
              >
                {item}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Filter.propTypes = {
  items: arrayOf(string.isRequired),
  defaultFilter: string.isRequired,
  selected: string,
  selectedChanged: func,
}

export const NoEventsPlaceholder = () => (
  <div className="ta-c c-tint2 fs-2">
    <Icon symbol="churchCenter#registrations-o" className="mb-1" aria-hidden />
    <div>Looks like there aren&apos;t any events available</div>
  </div>
)

export const EventItem = ({ event }) => {
  const {
    attributes: {
      logo_url: logoURL,
      name,
      event_time: eventTimeSummary,
      featured,
    },
  } = event

  return (
    <Card
      link={{
        as: Link,
        to: `/registrations/events/${event.id}`,
        state: { event: event },
      }}
      heading={name}
      description={eventTimeSummary}
      poster={
        logoURL ? (
          <img loading="lazy" src={logoURL} alt={`${name} logo`} />
        ) : (
          <NoLogoPlaceholder event={event} name={name} />
        )
      }
      badges={[featured && { theme: "featured", label: "Featured" }]}
    />
  )
}

EventItem.propTypes = {
  event: shape({
    attributes: shape({
      logo_url: string,
      name: string.isRequired,
      event_time: string,
      event_price_with_range: string,
      registration_state: string.isRequired,
    }),
  }).isRequired,
}

export default Events
