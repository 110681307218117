export function simplifyGroupTypeDescription(description: string = "") {
  if (!description) return ""

  return description
    .replace(/[\n\r]+/g, " ") // Remove newline characters
    .replace(/\(\s*https?:\/\/[^\s]+\s*\)/g, "") // Remove URLs within parentheses
    .replace(/\s+([,.!?;:])/g, "$1") // Remove spaces before punctuation
    .replace(/\s+/g, " ") // Convert multiple spaces to a single space
    .trim() // Trim leading and trailing spaces
}
