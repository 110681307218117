import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  createResource,
  useCacheRead,
} from "source/shared/react-cache-but-its-a-context"
import { useEnv } from "source/shared/hooks/useEnv"

const organization = createResource(
  () =>
    sessionApiClient
      .get("/registrations/v2")
      .then((json) => json.data.attributes)
      .catch((_error) => []),
  () => "registrations.root",
)

const EventFeaturesResource = createResource(
  ({ eventId }) =>
    sessionApiClient
      .get(
        `/registrations/v2/events/${eventId}/features?fields[Feature]=name,state,enabled`,
      )
      .then((json) => json.data)
      .catch((_error) => []),
  ({ eventId }) => `registrations.events.${eventId}`,
)

const useRegistrationsFlag = (name) => {
  return useCacheRead(organization).product_features[name] || false
}

const useRegistrationsFeature = (name, eventId) => {
  const railsEnv = useEnv()

  const feature = useCacheRead(EventFeaturesResource, { eventId }).find(
    (feature) => {
      return feature.attributes.name === `${name}_${railsEnv}`
    },
  )

  return feature?.attributes?.enabled || false
}

const useRegistrationsProxyPaths = () => {
  return useCacheRead(organization)
    .proxy_transition.filter(({ redirect_to_proxy }) => redirect_to_proxy)
    .map(({ path }) =>
      path === "/registrations" ? "/" : path.replace(/\/registrations/, ""),
    )
}

const useRegistrationsSetting = (name) => {
  const { settings: { [name]: setting } = {} } = useCacheRead(organization)
  return setting
}

export {
  useRegistrationsFlag,
  useRegistrationsProxyPaths,
  useRegistrationsSetting,
  useRegistrationsFeature,
}
