import { isChurchCenterApp } from "source/Layout"

export function shouldOpenInChurchCenterModal(url: string) {
  let resourceUrl

  // we're using a try/catch block to account for relative URLs ("/episode/1") that will cause new URL to throw an error
  try {
    resourceUrl = new URL(url)

    const isCCA = isChurchCenterApp()
    return (
      !isCCA &&
      validateHostnameIsValid(resourceUrl.hostname) &&
      validatePathnameIsValid(resourceUrl.pathname)
    )
  } catch (e) {
    console.warn(e)

    return false
  }
}

function validateHostnameIsValid(hostname: string) {
  if (hostname.match(/\.churchcenteronline\.com$/)) return true
  if (hostname.match(/\.churchcenter\.com$/)) return true
  if (hostname.match(/\.church\.center$/)) return true
  if (hostname.match(/\.churchcenter\.test$/)) return true
  throw (
    "ChurchCenterModal: hostname '" +
    hostname +
    "' is not supported. Use 'https://[yoursubdomain].churchcenteronline.com'"
  )
}

function validatePathnameIsValid(pathname: string) {
  const parts = pathname.slice(1).split("/")
  const app = parts[0]
  if (app === "giving" || (app === "people" && parts[1] === "forms")) {
    return true
  }
  throw "ChurchCenterModal: the app '" + app + "' is not supported yet."
}

export function urlForResource(resource: {
  attributes: { kind: string; url: string }
}) {
  const KINDS_TO_APPEND_SOURCE_CCA = ["giving_fund", "people_form"]
  if (KINDS_TO_APPEND_SOURCE_CCA.indexOf(resource.attributes.kind) > -1) {
    // We want no header and no footer, and `?source=cca` is the way to signal that.
    return `${resource.attributes.url}?source=cca`
  }
  return resource.attributes.url
}
