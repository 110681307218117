export default function pluralize(count, singularWord, includeCount = true) {
  const plural = count !== 1
  const irregularInflections = { person: "people" }
  let pluralWord

  if (singularWord in irregularInflections) {
    pluralWord = irregularInflections[singularWord]
  } else {
    pluralWord = `${singularWord}s`
  }

  return [
    includeCount ? `${count} ` : "",
    plural ? pluralWord : singularWord,
  ].join("")
}
