import { useEffect } from "react"

export default function useNotifyNativeLoaded() {
  useEffect(() => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "loaded" }))
  }, [])
  return null
}

export function NotifyNativeLoaded() {
  useNotifyNativeLoaded()
  return null
}
