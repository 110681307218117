import moment from "moment-timezone"
import { useSession } from "source/shared/hooks/useSession"
import { useApiRead } from "source/shared/SessionApiResource"
import { useBlockoutIsSharedWithAnyHouseholdMembers } from "source/services/hooks/useBlockoutIsSharedWithAnyHouseholdMembers"
import { useCurrentOrganizationTimeZone } from "source/services/hooks/useCurrentOrganizationTimeZone"
import { useCurrentPersonLinkedAccounts } from "source/services/hooks/useCurrentPersonLinkedAccounts"
import { useManageableHouseholdMemberIds } from "source/services/hooks/useManageableHouseholdMemberIds"
import { Blockout } from "source/services/classes/Blockout"

const BLOCKOUT_FIELDS = [
  "group_identifier",
  "reason",
  "share",
  "repeat_frequency",
  "repeat_interval",
  "repeat_period",
  "repeat_until",
  "person",
  "organization_name",
]

const BLOCKOUT_DATE_FIELDS = ["starts_at_utc", "ends_at_utc"]

export function useBlockout({ blockoutId, blockoutDateId }) {
  const blockout = blockoutId
    ? useExistingBlockout({ blockoutId, blockoutDateId })
    : useNewBlockout()

  blockout.hasLinkedAccounts = !!useCurrentPersonLinkedAccounts().length
  blockout.manageableHouseholdMemberIds =
    useManageableHouseholdMemberIds(blockout)
  blockout.isSharedWithHouseholdMembers =
    useBlockoutIsSharedWithAnyHouseholdMembers(blockout)

  return blockout
}

function useNewBlockout() {
  const { data: currentPerson } = useSession()
  const timeZone = useCurrentOrganizationTimeZone()
  const starts_at = moment().tz(timeZone).startOf("day").toISOString()
  const ends_at = moment().tz(timeZone).endOf("day").toISOString()

  return new Blockout({
    starts_at,
    ends_at,
    reason: "",
    isOwner: true,
    isNew: true,
    repeat_frequency: "no_repeat",
    repeat_period: null,
    repeat_interval: null,
    repeat_until: null,
    personId: currentPerson.id,
    timeZone,
  })
}

function useExistingBlockout({ blockoutId, blockoutDateId }) {
  const { data: blockout } = useApiRead(
    `/services/v2/me/blockouts/${blockoutId}?fields[Blockout]=${BLOCKOUT_FIELDS}`,
  )

  const { data: blockoutDate } = useApiRead(
    `/services/v2/me/blockouts/${blockoutId}/blockout_dates/${blockoutDateId}?fields[BlockoutDate]=${BLOCKOUT_DATE_FIELDS}`,
  )

  const { data: currentPerson } = useSession()
  const personId = blockout.relationships.person.data.id
  const isOwner = personId === currentPerson.id

  return new Blockout({
    ...blockout.attributes,
    id: blockout.id,
    blockoutDateId: blockoutDate.id,
    starts_at: blockoutDate.attributes.starts_at_utc,
    ends_at: blockoutDate.attributes.ends_at_utc,
    personId,
    isNew: false,
    isOwner,
    timeZone: useCurrentOrganizationTimeZone(),
  })
}

export const DEFAULT_RECURRENCE = {
  frequency: "every_1",
  period: "weekly",
  interval: null,
  until: null,
}

export const NULL_RECURRENCE = {
  frequency: "no_repeat",
  period: null,
  interval: null,
  until: null,
}
