import { useState } from "react"
import { object, node, array } from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import ExpressCheckout from "./ExpressCheckout"
import {
  Icon,
  Accordion,
  CenterLabelBorder,
  SavedPaymentMethods,
} from "source/shared/components"

const cardHeaderStyles = {
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
}

const CustomPaymentMethods = ({
  stripe,
  paymentIntent,
  stripeForm,
  savedPaymentMethods,
}) => {
  const [expressPaymentMethods, setExpressPaymentMethods] = useState(null)
  const [expressCheckoutLoading, setExpressCheckoutLoading] = useState(true)

  const onExpressCheckoutReady = ({ availablePaymentMethods }) => {
    if (
      availablePaymentMethods &&
      Object.values(availablePaymentMethods).some((val) => val === true)
    ) {
      setExpressPaymentMethods(availablePaymentMethods)
    }
    setExpressCheckoutLoading(false)
  }

  // TODO:: Implement onPaymentMethodChange
  const onPaymentMethodChange = (paymentMethod) => {
    paymentMethod
  }

  const paymentFormOptions = {
    clientSecret: paymentIntent?.clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        borderRadius: "4px",
      },
      rules: {
        ".Input": {
          boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
          border: "1px solid rgb(224 224 224)",
          padding: "12px",
        },
        ".Input--invalid": {
          border: "1px solid rgb(224 224 224)",
          boxShadow: "inset 0 1px 2px rgb(250, 250, 250)",
        },
        ".Label": {
          fontWeight: 500,
          marginBottom: "8px",
          fontSize: "14px",
          color: "#3d3d3d",
        },
        ".Error": {
          marginTop: "8px",
          color: "rgb(239, 80, 78)",
          fontSize: "16px",
        },
      },
    },
  }

  const savedPaymentsConfig = {
    name: "savedPayments",
    heading: (
      <div css={cardHeaderStyles}>
        <Icon symbol="giving#payment-methods" />
        <div>Saved Payment Methods</div>
      </div>
    ),
    content: (
      <SavedPaymentMethods
        paymentMethods={savedPaymentMethods}
        onPaymentMethodChange={onPaymentMethodChange}
      />
    ),
  }

  const newCardConfig = {
    name: "newCard",
    heading: (
      <div css={cardHeaderStyles}>
        <Icon symbol="general#outlined-credit-card" />
        <div>New Card</div>
      </div>
    ),
    content: (
      <Elements stripe={stripe} options={paymentFormOptions}>
        {stripeForm}
      </Elements>
    ),
  }

  const buildAccordionPanels = () => {
    const panels = [newCardConfig]
    if (savedPaymentMethods?.length) panels.unshift(savedPaymentsConfig)
    return panels
  }

  return (
    <>
      {(expressCheckoutLoading || expressPaymentMethods) && (
        <>
          <ExpressCheckout
            stripe={stripe}
            paymentIntent={paymentIntent}
            loading={expressCheckoutLoading}
            onExpressCheckoutReady={onExpressCheckoutReady}
          />
          <CenterLabelBorder className="my-3" label="or" />
        </>
      )}
      <Accordion panels={buildAccordionPanels()} />
    </>
  )
}

CustomPaymentMethods.propTypes = {
  stripe: object.isRequired,
  paymentIntent: object.isRequired,
  stripeForm: node.isRequired,
  savedPaymentMethods: array.isRequired,
}

export default CustomPaymentMethods
