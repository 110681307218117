import { bool, string, number, func, oneOfType } from "prop-types"

const CheckableInput = ({
  type,
  name,
  id,
  value,
  onChange,
  checked,
  label,
  labelClassName,
  inputClassName,
  disabled,
}) => (
  <>
    <input
      type={type}
      className={inputClassName}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
    <label htmlFor={id} className={labelClassName}>
      {label}
    </label>
  </>
)

CheckableInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  id: string.isRequired,
  value: oneOfType([number, string]),
  onChange: func,
  checked: bool,
  label: string,
  labelClassName: string,
  inputClassName: string,
  disabled: bool,
}

const Checkbox = (props) => (
  <CheckableInput
    type="checkbox"
    inputClassName="checkbox"
    labelClassName="checkbox-label"
    {...props}
  />
)

const Radio = (props) => (
  <CheckableInput
    type="radio"
    inputClassName="radio"
    labelClassName="radio-label"
    {...props}
  />
)

export { Checkbox, Radio, CheckableInput as default }
