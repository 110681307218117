export default function constrainDimensions({ height, width }, constraint) {
  if (!height || !width) {
    return { height: constraint, width: constraint }
  } else if (height > width) {
    return {
      height: constraint,
      width: Math.floor((width / height) * constraint),
    }
  } else {
    return {
      width: constraint,
      height: Math.floor((height / width) * constraint),
    }
  }
}
