import { useApiReadEntireCollection } from "source/shared/SessionApiResource"

export const useFetchEntireAttendeeTypeCollection = (eventId) => {
  try {
    return useApiReadEntireCollection(
      `/registrations/v2/events/${eventId}/attendee_types?order=position&per_page=100`,
    ).reduce((acc, page) => {
      acc.push(...page.data)
      return acc
    }, [])
  } catch (error) {
    return []
  }
}
