import { useContext } from "react"
import { object } from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import { get as dig, pick } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { Icon, AlertMessage } from "source/shared/components"
import { DateInput } from "./form_helpers/DateInput"
import { TimeInput } from "./form_helpers/TimeInput"
import { FormErrors } from "./form_helpers/FormErrors"
import { updateDateInISODatetime } from "./utils/date"
import {
  useEventFormReducer,
  SET_ATTRIBUTES,
  SET_REPEATING_ATTRIBUTES,
  SET_INCLUDE_REPEATING,
  SET_BUSY,
  SET_ERRORS,
} from "./reducers/eventFormReducer"
import { RepeatingDateFields } from "./form_helpers/RepeatingDateField"
import { useMeetingScheduleDefaults } from "./utils/useMeetingScheduleDefaults"
import { Select } from "@planningcenter/doxy-web"

EventForm.propTypes = {
  currentEvent: object,
}
export default function EventForm({ currentEvent }) {
  const navigate = useNavigate()
  const [state, dispatch] = useEventFormReducer(currentEvent)
  const group = useContext(CurrentGroupContext)
  const { data: availableGroupLocations = [] } = useApiRead(
    `/groups/v2/me/groups/${group.id}/locations`,
  )

  useMeetingScheduleDefaults({
    groupId: group.id,
    dispatch,
    skip: !!currentEvent,
  })

  const handleStartsAtChange = (datetime) => {
    setEventTime({ starts_at: moment(datetime).toISOString(true) })
  }

  const handleEndsAtChange = (datetime) => {
    setEventTime({ ends_at: moment(datetime).toISOString(true) })
  }

  const setEventTime = (startsOrEndsAt) => {
    let payload = pick(state.attributes, ["starts_at", "ends_at"])
    payload = { ...payload, ...startsOrEndsAt }

    if (moment(payload.ends_at).isBefore(payload.starts_at, "day")) {
      payload.ends_at = updateDateInISODatetime(
        payload.ends_at,
        payload.starts_at,
      )
    }

    dispatch({ type: SET_ATTRIBUTES, payload })
  }

  const handleLocationTypePrefChange = (e) => {
    const location_type_preference = e.target.value
    dispatch({ type: SET_ATTRIBUTES, payload: { location_type_preference } })
  }

  const handlePhysicalLocationChange = (e) => {
    const location_id = e.target.value
    dispatch({ type: SET_ATTRIBUTES, payload: { location_id } })
  }

  const handleReminderOffsetChange = (e) => {
    const selectedOffset = Number(e.target.value)
    const automated_reminder_desired_at_offset =
      selectedOffset > 0 ? selectedOffset : undefined
    dispatch({
      type: SET_ATTRIBUTES,
      payload: { automated_reminder_desired_at_offset },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch({ type: SET_BUSY, payload: true })

    const baseApiPath = `/groups/v2/me/groups/${group.id}/events`
    const { attributes, repeatingAttributes, includeRepeating } = state
    const payload = {
      data: {
        attributes: {
          ...attributes,
          ...(includeRepeating ? { repeating: repeatingAttributes } : {}),
        },
      },
    }
    const eventId = dig(currentEvent, "event.id")
    const patchOrPost = eventId
      ? sessionApiClient.patch(`${baseApiPath}/${eventId}`, payload)
      : sessionApiClient.post(baseApiPath, payload)

    patchOrPost
      .then((response) => {
        dispatch({ type: SET_BUSY, payload: false })
        if (currentEvent) currentEvent.refetchEvent()
        navigate(`${group.base_path}/events/${response.data.id}`)
      })
      .catch((errors) => {
        dispatch({ type: SET_BUSY, payload: false })
        dispatch({ type: SET_ERRORS, payload: errors })
        window.scrollTo({ top: 0, behavior: "smooth" })
      })
  }

  const handleCancel = (e) => {
    e.preventDefault()
    const confirmText = "Are you sure you'd like to cancel?"
    if (window.confirm(confirmText)) {
      navigate(`${group.base_path}/events`)
    }
  }

  const editingRepeatingEvent = currentEvent && currentEvent.repeating_event
  const eventIsPast = moment(state.attributes.starts_at).isBefore(moment())
  const persistedEventIsInThePast =
    moment(dig(currentEvent, "event.attributes.ends_at")).isBefore(moment()) ||
    false
  const eventEndIsBeforeStart = moment(state.attributes.ends_at).isBefore(
    moment(state.attributes.starts_at),
  )
  const physicalLocationPreference =
    state.attributes.location_type_preference === "physical"
  const virtualLocationPreference =
    state.attributes.location_type_preference === "virtual"
  const reminderOffsetIsPast =
    state.attributes.automated_reminder_desired_at_offset &&
    moment(state.attributes.starts_at)
      .subtract(
        state.attributes.automated_reminder_desired_at_offset,
        "seconds",
      )
      .isSameOrBefore(moment())
  let submitButtonText = currentEvent ? "Update event" : "Create event"
  if (reminderOffsetIsPast && !eventIsPast)
    submitButtonText += " & send reminders"

  return (
    <form onSubmit={handleSubmit}>
      <FormErrors errors={state.errors} />
      <fieldset disabled={state.isBusy}>
        {editingRepeatingEvent && (
          <div className="mb-3">
            <div className="d-f ai-c">
              <input
                type="radio"
                id="event_update_this_occurrence_only"
                className="radio"
                checked={state.includeRepeating === false}
                onChange={() =>
                  dispatch({ type: SET_INCLUDE_REPEATING, payload: false })
                }
              />
              <label
                className="radio-label"
                htmlFor="event_update_this_occurrence_only"
                css={{ cursor: "pointer", fontWeight: 400 }}
              >
                Only this occurrence
              </label>
            </div>
            <div className="d-f ai-c mt-2">
              <input
                type="radio"
                id="event_update_all_occurrences"
                className="radio"
                checked={state.includeRepeating === true}
                onChange={() =>
                  dispatch({ type: SET_INCLUDE_REPEATING, payload: true })
                }
              />
              <label
                className="radio-label"
                htmlFor="event_update_all_occurrences"
                css={{ cursor: "pointer", fontWeight: 400 }}
              >
                All future occurrences
              </label>
            </div>
          </div>
        )}
        <div>
          <label className="label" htmlFor="event_name">
            Event name
            <span className="c-ruby"> *</span>
          </label>
          <input
            type="text"
            id="event_name"
            className="w-100%"
            required
            value={state.attributes.name}
            onChange={(e) =>
              dispatch({
                type: SET_ATTRIBUTES,
                payload: { name: e.target.value },
              })
            }
          />
        </div>
        <div className="mt-2">
          <div className="date-time-range">
            <div className="date-time-range__container">
              <div className="date-time-range__date date-time-range__start-date">
                <label className="label" htmlFor="event_starts_at_date">
                  Start date
                  <span className="c-ruby"> *</span>
                </label>
                <DateInput
                  disabled={persistedEventIsInThePast}
                  id="event_starts_at_date"
                  required
                  minDate={moment().toDate()}
                  selected={moment(state.attributes.starts_at).toDate()}
                  onChange={handleStartsAtChange}
                />
              </div>
              <div className="date-time-range__time date-time-range__start-time">
                <label className="label" htmlFor="event_starts_at_time">
                  Start time
                  <span className="c-ruby"> *</span>
                </label>
                <TimeInput
                  disabled={persistedEventIsInThePast}
                  id="event_starts_at_time"
                  required
                  selected={moment(state.attributes.starts_at).toDate()}
                  onChange={handleStartsAtChange}
                />
              </div>
            </div>
            <div className="date-time-range__joinder">to</div>
            <div className="date-time-range__container">
              <div className="date-time-range__date date-time-range__end-date">
                <label className="label" htmlFor="event_ends_at">
                  End date
                  <span className="c-ruby"> *</span>
                </label>
                <DateInput
                  disabled={persistedEventIsInThePast}
                  id="event_ends_at"
                  required
                  minDate={moment(state.attributes.starts_at).toDate()}
                  selected={moment(state.attributes.ends_at).toDate()}
                  onChange={handleEndsAtChange}
                />
              </div>
              <div className="date-time-range__time date-time-range__end-time">
                <label className="label" htmlFor="event_ends_at_time">
                  End time
                  <span className="c-ruby"> *</span>
                </label>
                <TimeInput
                  disabled={persistedEventIsInThePast}
                  id="event_ends_at_time"
                  required
                  selected={moment(state.attributes.ends_at).toDate()}
                  onChange={handleEndsAtChange}
                />
              </div>
            </div>
          </div>
          {persistedEventIsInThePast && (
            <AlertMessage
              icon="general#exclamation-triangle"
              type="warning"
              className="mt-1"
            >
              The date and time for past events cannot be edited, but you can
              still edit all other fields.{" "}
              <Link to={`${group.base_path}/events/new`} target="_blank">
                Create a new event
              </Link>{" "}
              to make further changes.
            </AlertMessage>
          )}
          {eventEndIsBeforeStart && (
            <AlertMessage className="mt-1">
              End time must be after start time
            </AlertMessage>
          )}
        </div>
        {state.includeRepeating && (
          <div className="mt-2">
            <RepeatingDateFields
              startDate={state.attributes.starts_at}
              attributes={state.repeatingAttributes}
              onChange={(attrs) =>
                dispatch({ type: SET_REPEATING_ATTRIBUTES, payload: attrs })
              }
            />
            {editingRepeatingEvent && eventIsPast && (
              <div className="d-f ai-fs fs-4 c-tint3 mt-1">
                <span className="d-f ai-c c-marigold mt-4p mr-4p">
                  <Icon symbol="general#exclamation-triangle" />
                </span>
                <span>
                  Editing this schedule may delete future occurrences and their
                  attendance
                </span>
              </div>
            )}
          </div>
        )}
        <div className="mt-2">
          <label className="label" htmlFor="event_description">
            Description
          </label>
          <textarea
            id="event_description"
            className="w-100%"
            rows={4}
            value={state.attributes.description}
            onChange={(e) =>
              dispatch({
                type: SET_ATTRIBUTES,
                payload: { description: e.target.value },
              })
            }
          />
        </div>
        <div className="mt-2">
          <span className="label">Location</span>
          <div className="d-f ai-c">
            <input
              type="radio"
              id="event_physical_location_type_preference"
              value="physical"
              className="radio"
              checked={physicalLocationPreference}
              onChange={handleLocationTypePrefChange}
            />
            <label
              className="radio-label"
              htmlFor="event_physical_location_type_preference"
            >
              Physical address
            </label>
          </div>
          {physicalLocationPreference && (
            <div className="pl-3 mt-1">
              <Select
                id="event_location"
                key={state.attributes.location_id}
                onBlur={handlePhysicalLocationChange}
                onChange={handlePhysicalLocationChange}
                value={state.attributes.location_id}
              >
                <Select.Option label="(no location)" value="" />
                {availableGroupLocations.map((location) => (
                  <Select.Option
                    key={location.id}
                    label={location.attributes.name}
                    value={location.id}
                  />
                ))}
              </Select>
              <div className="fs-4 c-tint3 mt-1">
                <span>
                  Add or edit locations in{" "}
                  <a
                    href={group.planning_center_settings_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    group settings
                  </a>
                </span>
              </div>
            </div>
          )}
          <div className="d-f ai-c mt-2">
            <input
              type="radio"
              id="event_virtual_location_type_preference"
              value="virtual"
              className="radio"
              checked={virtualLocationPreference}
              onChange={handleLocationTypePrefChange}
            />
            <label
              className="radio-label"
              htmlFor="event_virtual_location_type_preference"
            >
              Virtual (link)
            </label>
          </div>
          {virtualLocationPreference && (
            <div className="pl-3 mt-1">
              <input
                type="url"
                placeholder="Enter URL"
                value={state.attributes.virtual_location_url}
                onChange={(e) =>
                  dispatch({
                    type: SET_ATTRIBUTES,
                    payload: { virtual_location_url: e.target.value },
                  })
                }
              />
            </div>
          )}
        </div>
        <div className="mt-2">
          <label
            className="label"
            htmlFor="event_automated_reminder_desired_at_offset"
          >
            Send reminder emails
          </label>
          {eventIsPast ? (
            <span>
              Reminder emails cannot be sent because the event has already
              happened.
            </span>
          ) : (
            <>
              <Select
                id="event_automated_reminder_desired_at_offset"
                key={state.attributes.automated_reminder_desired_at_offset}
                onBlur={handleReminderOffsetChange}
                onChange={handleReminderOffsetChange}
                value={state.attributes.automated_reminder_desired_at_offset}
              >
                <Select.Option label="Do not send reminders" value="" />
                {group.automated_event_reminder_defaults.options_for_select.map(
                  ({ label, value }) => (
                    <Select.Option key={value} label={label} value={value} />
                  ),
                )}
              </Select>
              {reminderOffsetIsPast && (
                <div className="d-f ai-fs fs-4 warning-alert alert p-1 mt-1">
                  <span className="d-f ai-c c-marigold mt-2p mr-4p">
                    <Icon symbol="general#exclamation-triangle" />
                  </span>
                  <span>Saving this event will send reminders immediately</span>
                </div>
              )}
            </>
          )}
        </div>
      </fieldset>
      <div className="d-f fw-w ai-c jc-c jc-fe@md mb-2 mt-4">
        <button className="compact-btn btn text-btn m-1" onClick={handleCancel}>
          Go back
        </button>
        <button type="submit" className="btn">
          {submitButtonText}
        </button>
      </div>
    </form>
  )
}
