import { useEffect, useState } from "react"
import { node } from "prop-types"
import { Route, Routes } from "react-router-dom"
import { StreamChat } from "stream-chat"
import { Chat } from "stream-chat-react"
import { useApiRead } from "source/shared/SessionApiResource"
import { Loading } from "source/shared/components"
import TabView from "source/groups/my/groups/TabView"
import { ConversationsIndex } from "source/groups/my/groups/conversations/index"
import { ConversationsShow } from "source/groups/my/groups/conversations/show"
import { ConversationsNew } from "source/groups/my/groups/conversations/new"
import { ConversationsEdit } from "source/groups/my/groups/conversations/edit"

export default function ConversationsRouter() {
  return (
    <TabView>
      <StreamChatProvider>
        <Routes>
          <Route path="/" element={<ConversationsIndex />} />
          <Route path="/:conversationId" element={<ConversationsShow />} />
          <Route path="/:conversationId/edit" element={<ConversationsEdit />} />
          <Route path="/new" element={<ConversationsNew />} />
        </Routes>
      </StreamChatProvider>
    </TabView>
  )
}

StreamChatProvider.propTypes = {
  children: node.isRequired,
}

export function StreamChatProvider({ children }) {
  const credentials = useMyStreamCredentials()
  const client = useStreamClient(credentials)

  if (!client) return <Loading />

  return <Chat client={client}>{children}</Chat>
}

function useMyStreamCredentials() {
  const { data: user } = useApiRead(`/chat/v2/me/stream_user`, {
    headers: { "X-PCO-Groups-Chat": 1 },
  })
  const apiKey = user.attributes.api_key
  const token = user.attributes.token
  const userId = user.id

  return { apiKey, token, userId }
}

function useStreamClient({ apiKey, userId, token }) {
  const [streamClient, setStreamClient] = useState(null)

  useEffect(() => {
    const client = new StreamChat(apiKey)

    // Under some circumstances, a "connectUser" operation might be interrupted
    // (fast user switching, react strict-mode in dev). With this flag, we control
    // whether a "disconnectUser" operation has been requested before we
    // provide a new StreamChat instance to the consumers of this hook.
    let didUserConnectInterrupt = false

    const connectUser = client.connectUser({ id: userId }, token).then(() => {
      if (!didUserConnectInterrupt) {
        setStreamClient(client)
      }
    })

    return () => {
      didUserConnectInterrupt = true
      // there might be a pending "connectUser" operation, wait for it to finish
      // before executing the "disconnectUser" in order to prevent race-conditions.
      connectUser.then(() => {
        setStreamClient(null)
        client.disconnectUser()
      })
    }
  }, [apiKey, token, userId])

  return streamClient
}
