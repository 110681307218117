import { useRef, useState } from "react"
import { bool, func, number } from "prop-types"
import spinner from "source/svg/spinner.svg"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"

const ReasonModal = ({
  allDeclined,
  declinedCount,
  handleClose,
  handleSubmit,
}) => {
  const [declineReason, setDeclineReason] = useState("")
  const [inFlight, setInFlight] = useState(false)
  const cancelRef = useRef(null)

  const handleClickSubmit = () => {
    setInFlight(true)
    handleSubmit(declineReason)
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>Decline</AlertDialogLabel>
      <AlertDialogDescription>
        {"Let us know if you have a specific reason you can't serve."}
        <textarea
          onChange={({ target: { value } }) => setDeclineReason(value)}
          className="mt-3"
        />
        <div className="d-f jc-fe mt-3">
          <button
            className="text-btn mr-2 btn "
            onClick={handleClose}
            ref={cancelRef}
          >
            Cancel
          </button>{" "}
          <button className="destroy-btn btn" onClick={handleClickSubmit}>
            {inFlight ? (
              <img
                src={spinner}
                alt="Saving"
                style={{ height: "1em", width: "1em" }}
              />
            ) : (
              <div>{allDeclined ? "Decline" : `Decline ${declinedCount}`}</div>
            )}
          </button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

ReasonModal.propTypes = {
  allDeclined: bool,
  declinedCount: number,
  handleClose: func,
  handleSubmit: func,
}

export default ReasonModal
