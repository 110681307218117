import { useContext } from "react"
import { get as dig } from "lodash"
import { WebBootContext } from "./WebBoot"

export function usePublishingFeature(name) {
  const state = useContext(WebBootContext)
  return !!dig(state, `features.${name}`)
}

export function FeatureEnabled({ name, fallback, children }) {
  const enabled = usePublishingFeature(name)
  return enabled ? children : fallback
}
