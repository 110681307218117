import * as React from "react"
import { cloneElement, createContext, useContext, useMemo } from "react"
import { node } from "prop-types"
import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"

export function start(configuration) {
  const client = bugsnag(configuration)
  client.use(bugsnagReact, React)
}

const __bugsnagClient = createContext()

Root.propTypes = {
  children: node.isRequired,
}
export function Root({ children, ...configuration }) {
  const { Provider } = __bugsnagClient
  const client = useMemo(() => {
    const c = bugsnag(configuration)
    c.use(bugsnagReact, React)
    return c
  }, [configuration])

  return <Provider value={client}>{children}</Provider>
}

export function ErrorBoundary({ fallback, ...props }) {
  const client = useContext(__bugsnagClient)
  const BugsnagErrorBoundary = client.getPlugin("react")
  function RenderFallback({ error, info }) {
    return cloneElement(fallback, { error, info })
  }
  return <BugsnagErrorBoundary FallbackComponent={RenderFallback} {...props} />
}
