import { useApiPost } from "source/shared/SessionApiResource"
import GroupTopic from "./GroupTopic"
import { func, object } from "prop-types"
import { Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

SelectedGroup.propTypes = {
  group: object,
  episode: object,
  episodeTime: object,
  setGroupId: func,
}
export function SelectedGroup({ group, episode, episodeTime, setGroupId }) {
  const token = useApiPost(
    `/publishing/v2/episodes/${episode.id}/episode_times/${
      episodeTime.id
    }/generate_topic_token`,
  ).data.id
  const topic = useApiPost(
    `/groups/v2/me/groups/${group.id}/topic_for_episode`,
    { data: { attributes: { token } } },
  ).data
  return <GroupTopic group={group} topic={topic} setGroupId={setGroupId} />
}

export function MessagingNotEnabled({ setGroupId, group }) {
  return (
    <div className="f-1 d-f jc-c ai-c ta-c" style={{ padding: "12px" }}>
      <div
        className="p-2 d-f fd-c g-1"
        style={{
          maxWidth: "280px",
          background: "var(--color-tint8)",
          borderRadius: "5px",
        }}
      >
        <Heading
          level={4}
          size={5}
          text={`Group Messaging is not enabled for the ${
            group.attributes.name
          } group.`}
        />
        <button
          className="btn minor-compact-btn p-2"
          onClick={() => setGroupId(null)}
        >
          <Icon symbol="churchCenter#groups" className="mr-1" />
          Switch To Another Group
        </button>
      </div>
    </div>
  )
}
