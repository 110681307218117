import { Fragment, useState } from "react"
import { string, bool, func, node } from "prop-types"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList,
} from "@reach/menu-button"
import { Icon } from "source/shared/components"
import { colors } from "source/shared/colors"
import spinner from "source/svg/spinner.svg"

import { sessionApiClient } from "@planningcenter/cc-api-client"

BookmarkEvent.propTypes = {
  additionalDatesBookmarked: bool,
  bookmarked: bool,
  className: string,
  eventId: string,
  multiDayEvent: bool,
  recurring: bool,
}
function BookmarkEvent({
  additionalDatesBookmarked: initialAdditionalDatesBookmarkedValue,
  bookmarked: initialBookmarkedValue,
  className,
  eventId,
  multiDayEvent,
  recurring,
}) {
  const [bookmarked, setBookmarked] = useState(initialBookmarkedValue)
  const [additionalDatesBookmarked, setAdditionalDatesBookmarked] = useState(
    initialAdditionalDatesBookmarkedValue,
  )
  const [inProgress, setInProgress] = useState(false)

  const createBookmark = (target) => {
    setInProgress(true)
    return sessionApiClient
      .post(
        `/calendar/v2/events/${eventId}/bookmark`,
        { data: { type: "EventBookmark", attributes: { target } } },
        { version: "2021-06-23" },
      )
      .then(() => {
        setInProgress(false)
        setBookmarked(true)
        if (target == "all") setAdditionalDatesBookmarked(true)
      })
  }

  const removeBookmark = (target) => {
    setInProgress(true)
    return sessionApiClient
      .post(
        `/calendar/v2/events/${eventId}/unbookmark`,
        { data: { type: "EventBookmark", attributes: { target } } },
        { version: "2021-06-23" },
      )
      .then(() => {
        setInProgress(false)
        setBookmarked(false)
        if (target == "all") setAdditionalDatesBookmarked(false)
      })
  }

  const toggleBookmarkOne = () =>
    bookmarked ? removeBookmark("current") : createBookmark("current")
  const toggleBookmarkAll = () =>
    bookmarked ? removeBookmark("all") : createBookmark("all")

  const getLabel = () => {
    if (bookmarked) {
      return (
        <Fragment>
          <Icon symbol="general#check" className="fs-6" aria-hidden />
          <span className="pl-4p">Bookmarked</span>
        </Fragment>
      )
    } else {
      return "Add bookmark"
    }
  }

  return (
    <div {...{ className }}>
      {bookmarked || recurring ? (
        <BookmarkDropdown {...{ bookmarked, getLabel, inProgress, recurring }}>
          <BookmarkMenuItem onSelect={toggleBookmarkOne}>
            {bookmarked ? "Remove" : "Bookmark"} this event
          </BookmarkMenuItem>

          {bookmarked && recurring && additionalDatesBookmarked && (
            <BookmarkMenuItem onSelect={toggleBookmarkAll}>
              Remove all events
            </BookmarkMenuItem>
          )}

          {!bookmarked && recurring && (
            <BookmarkMenuItem onSelect={toggleBookmarkAll}>
              Bookmark all events
            </BookmarkMenuItem>
          )}

          {bookmarked && (
            <BookmarkMenuLink to="/schedule">View my schedule</BookmarkMenuLink>
          )}
        </BookmarkDropdown>
      ) : (
        <BookmarkOneButton
          {...{ bookmarked, inProgress }}
          onClick={toggleBookmarkOne}
          position="relative"
        >
          <span style={{ opacity: inProgress ? 0 : 1 }}>{getLabel()}</span>
          {inProgress && <InProgress>Adding...</InProgress>}
        </BookmarkOneButton>
      )}
    </div>
  )
}

BookmarkOneButton.propTypes = {
  bookmarked: bool,
  children: node,
  inProgress: bool,
  onClick: func,
}
function BookmarkOneButton({ children, onClick }) {
  return (
    <button
      className="btn secondary-btn minor-btn"
      onClick={onClick}
      style={{ position: "relative" }}
    >
      {children}
    </button>
  )
}

BookmarkDropdown.propTypes = {
  bookmarked: bool,
  children: node,
  inProgress: bool,
  getLabel: func,
  recurring: bool,
}
function BookmarkDropdown({
  bookmarked,
  children,
  inProgress,
  getLabel,
  recurring,
}) {
  return (
    <Menu>
      <MenuButton
        className="btn secondary-btn minor-btn"
        style={{ position: "relative" }}
      >
        <span className="d-f ai-c" style={{ opacity: inProgress ? 0 : 1 }}>
          {getLabel()}
          <span className="fs-6 pl-1 dropdown-trigger__icon">
            <Icon symbol="general#down-chevron" aria-hidden />
          </span>
        </span>
        {inProgress && (
          <InProgress>{bookmarked ? "Removing" : "Adding"}...</InProgress>
        )}
      </MenuButton>

      {!inProgress && (
        <MenuList
          className="dropdown__menu pb-1 mt-1"
          css={css`
            box-shadow: 0px 4px 12px rgb(0 0 0 / 12%);
          `}
        >
          {recurring && (
            <div
              className="px-2 py-1 c-tint1 d-f ai-c mb-1"
              style={{ borderBottom: `1px solid ${colors.tint6}` }}
            >
              <Icon
                className="c-brand mr-1"
                symbol="general#calendar-repeat"
                aria-hidden
              />
              <span className="c-tint2">This is a recurring event</span>
            </div>
          )}
          {children}
        </MenuList>
      )}
    </Menu>
  )
}

BookmarkMenuItem.propTypes = { children: node, onSelect: func }
function BookmarkMenuItem({ children, onSelect }) {
  return (
    <MenuItem
      {...{ onSelect }}
      className="dropdown__item px-2"
      style={{ paddingTop: 6, paddingBottom: 6 }}
    >
      {children}
    </MenuItem>
  )
}

BookmarkMenuLink.propTypes = { children: node, to: string }
function BookmarkMenuLink({ children, to }) {
  return (
    <MenuLink
      as={Link}
      {...{ to }}
      className="dropdown__item px-2"
      style={{ paddingTop: 6, paddingBottom: 6 }}
    >
      {children}
    </MenuLink>
  )
}

InProgress.propTypes = { children: string }
function InProgress({ children }) {
  return (
    <span style={{ position: "absolute" }} className="d-f ai-c">
      <InlineSpinner />
      <span style={{ marginLeft: 4 }}>{children}</span>
    </span>
  )
}

const InlineSpinner = () => (
  <img
    alt="Loading..."
    src={spinner}
    style={{
      opacity: 0.7,
      height: "1em",
      width: "1em",
    }}
  />
)

export default BookmarkEvent
