import { css } from "@emotion/react"
import PropTypes from "prop-types"
import { size } from "@planningcenter/system"
import { useNavigate } from "react-router-dom"

function handleClickWithReactRouterNavigationIfPossible(event, navigate) {
  const { href, target } = event.target
  if (href && href.startsWith(window.origin) && target !== "_blank") {
    event.preventDefault()
    navigate(href.replace(window.origin, ""))
  }
}

RoutingAwareDangerousHTML.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

export function RoutingAwareDangerousHTML({ html, styles, className }) {
  const navigate = useNavigate()

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      css={styles || defaultStyles.htmlContent}
      className={className}
      data-preformatted-content="heading-scale:legacy-church-center"
      onClick={(event) =>
        handleClickWithReactRouterNavigationIfPossible(event, navigate)
      }
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const space = (increment) => `${size(increment)}px`

const defaultStyles = {
  htmlContent: css`
    > *:not([data-section-header]) {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
    }

    [data-block] {
      margin-bottom: ${space(3)};
    }

    [data-text] {
      > div,
      > p {
        margin-bottom: 20px;
      }

      div:not(:has(div, p, iframe, h1, h2, h3, h4, h5, h6)) {
        margin-bottom: 20px;
      }
    }

    hr {
      height: 1px;
      border: none;
      background-color: var(--color-tint5);
      margin-bottom: 24px;
      display: block;
    }

    iframe {
      border: none;
      margin: 4px 0 24px;
    }

    iframe[src*="boxcast.tv"],
    iframe[src*="vimeo.com"],
    iframe[src*="youtube.com"] {
      margin: 0 auto 24px;
      max-width: 640px;
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    pre {
      white-space: pre-wrap;
    }

    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-word;

      &:hover {
        text-decoration: underline;
      }
    }

    .attachment {
      margin: 0;

      .attachment__caption {
        text-align: center;
        padding-top: 8px;
        font-weight: 400;
        color: var(--color-tint2);
        font-size: 14px;
        font-style: italic;
      }

      a:hover .attachment__caption {
        text-decoration: none;
      }
    }

    .attachment-gallery {
      figure + figure,
      action-text-attachment ~ action-text-attachment .attachment {
        margin-top: 20px;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      --image--border-radius: 0;
    }

    [data-image] {
      margin-bottom: 24px;
    }

    [data-text~="text-align:center"] {
      text-align: center;

      ol,
      ul {
        list-style-position: inside;
      }
    }

    [data-text~="text-align:right"] {
      text-align: right;

      ol,
      ul {
        list-style-position: inside;
      }
    }

    [data-section-header] {
      display: flex;
      margin-bottom: 24px;
      margin-left: -1.5em;
      margin-right: -1.5em;

      + [data-section-header] {
        margin-top: -24px;
      }

      + [data-section-header~="height:auto"] {
        margin-top: 0;
      }

      &:first-child {
        margin-top: -1.5em;
      }

      @media screen and (min-width: 720px) {
        margin-left: -2em;
        margin-right: -2em;

        &:first-child {
          margin-top: -2em;
        }
      }
    }

    [data-section-header~="height:regular"] {
      height: 300px;
    }

    [data-section-header~="height:tall"] {
      height: 500px;
    }

    [data-section-header--img] {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: ${space(3)};
      margin-bottom: 0;
      background-image: var(--background-image-url);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    [data-section-header--img~="false"] {
      padding: 0 ${space(3)};
    }

    [data-section-header--callout] {
      max-width: 900px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > * {
        max-width: 450px;
        width: 100%;
      }
    }

    [data-section-header--callout-button-wrap] {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    [data-section-header--callout~="position:left"] {
      align-items: flex-start;
    }

    [data-section-header--callout~="position:right"] {
      align-items: flex-start;

      @media screen and (min-width: 720px) {
        align-items: flex-end;
      }
    }

    [data-section-header--callout~="position:center"] {
      [data-section-header--callout-text] {
        text-align: center;
      }
      [data-section-header--callout-button-wrap] {
        align-items: center;
      }
    }

    [data-section-header--callout~="position:right"] {
      [data-section-header--callout-text] {
        text-align: right;
      }
      [data-section-header--callout-button-wrap] {
        align-items: flex-end;
      }
    }

    [data-section-header--callout-text],
    [data-section-header--callout-button-wrap] {
      max-width: 450px;
    }

    [data-section-header--callout-text] {
      background: transparent;
      border: 0;
      margin: 0;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      overflow: hidden;
      resize: none;

      @media screen and (min-width: 600px) {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
      }

      @media screen and (min-width: 960px) {
        font-size: 42px;
        line-height: 56px;
      }
    }

    [data-section-header--callout-text]
      + [data-section-header--callout-button-wrap] {
      margin-top: 16px;
    }

    [data-section-header--img="true"]
      [data-section-header--callout-text~="color:light"] {
      color: #fff;
    }

    [data-section-header--img="true"]
      [data-section-header--callout-text~="color:dark"] {
      color: #000;
      font-weight: 700;

      @media screen and (min-width: 600px) {
        font-weight: 600;
      }
    }

    [data-section-header--img="false"] [data-section-header--callout-text] {
      [data-color-scheme="light"] & {
        font-weight: 700;

        @media screen and (min-width: 600px) {
          font-weight: 600;
        }
      }
    }

    a[data-section-header--callout-button] {
      text-decoration: none;
    }

    [data-button-wrap],
    [data-giving] {
      display: flex;
      justify-content: center;
    }

    [data-giving] {
      gap: 16px;
      flex-wrap: wrap;
      > * {
        flex: 0 1 7ch;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }

      h2 {
        flex-basis: 100%;
        font-size: 30px;
        margin-bottom: -4px;
      }

      &[data-alignment="center"] {
        justify-content: center;

        h2 {
          text-align: center;
        }
      }

      &[data-alignment="left"] {
        justify-content: flex-start;

        h2 {
          text-align: left;
        }
      }

      &[data-alignment="right"] {
        justify-content: flex-end;

        h2 {
          text-align: right;
        }
      }
    }

    [data-button-wrap="position:center"] {
      justify-content: center;
    }

    [data-button-wrap="position:left"] {
      justify-content: flex-start;
    }

    [data-button-wrap="position:right"] {
      justify-content: flex-end;
    }

    [data-button] {
      border: none;
      padding: 0;
      background-color: transparent;

      a:hover {
        text-decoration: none;
      }
    }

    [data-contact],
    [data-social] {
      max-width: 640px;
      height: 48px;
      margin: 0 auto 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
    }

    [data-contact--item],
    [data-social--item] {
      display: flex;
      align-items: center;
      color: var(--color-tint3);
      text-decoration: none;
      font-weight: 400;
      gap: 8px;
    }

    [data-contact--item] .symbol {
      color: var(--color-tint3);
    }

    [data-social--item] .symbol {
      height: 2em;
      width: 2em;
    }

    [data-location--map] {
      height: auto;
      width: 100%;
      margin-bottom: 16px;
    }

    [data-location--meta] {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    [data-location--address] {
      line-height: 20px;
    }

    [data-location--address~="location-type:lat_long"] {
      white-space: pre-line;
    }

    [data-event-schedule] {
      max-width: 640px;
      margin: 0 auto 24px;
    }

    [data-event-schedule--dayname] {
      margin: 16px 0 6px;
      padding: 0;
      color: var(--heading-color);
      font-weight: 600;
    }

    [data-event-schedule--list] {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    [data-event-schedule--list] li {
      display: flex;
      padding: 4px 0;
      color: var(--color-tint-1);
    }

    [data-event-schedule--start-time] {
      flex: 1;
      max-width: 100px;
      padding-right: 10px;
    }

    [data-event-schedule--name] {
      flex: 2;
      flex-grow: 1;
      padding-left: 10px;
    }

    [data-grid] {
      display: grid;
      grid-template-rows: auto;
      gap: 16px;
    }

    [data-grid-columns-mobile="3"] {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    [data-grid-columns-mobile="2"] {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    [data-grid-columns-mobile="1"] {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 600px) {
      [data-grid-columns-desktop="3"] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      [data-grid-columns-desktop="2"] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    [data-grid-image-constrain="true"] img {
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }

    [data-grid-image-scale-up="true"],
    [data-grid-image-scale-up="true"] img {
      width: 100%;
    }

    [data-grid-item] {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;
    }

    [data-grid-item] :is(h2, p) {
      margin: 0;
    }

    [data-grid-item] h2 {
      font-size: 21px;
      line-height: 1.2;
      margin: -4px 0;
      width: 100%;
      overflow-wrap: break-word;
    }

    [data-grid-item] p {
      margin: -2px 0;
      width: 100%;
      overflow-wrap: break-word;
    }

    [data-grid-alignment="left"] [data-grid-item] {
      align-items: flex-start;
    }

    [data-grid-alignment="center"] [data-grid-item] {
      align-items: center;
      > :is(h2, p) {
        text-align: center;
      }
    }

    [data-grid-alignment="right"] [data-grid-item] {
      align-items: flex-end;
      > :is(h2, p) {
        text-align: right;
      }
    }
  `,
}
