import { useCallback, useEffect } from "react"

export default function useReactNativeBridgeComponent({ component, onReply }) {
  const supported = window.nativeBridge?.supportedComponents.includes(component)

  const postMessage = useCallback(
    (event, data) => {
      if (!supported) return
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          component,
          data,
          event,
          type: "bridgeComponent",
        }),
      )
    },
    [component, supported],
  )

  const handleReplyMessage = useCallback(
    (event) => {
      if (event.component === component) {
        onReply({ data: event.data, event: event.event })
      }
    },
    [component, onReply],
  )

  useEffect(() => {
    if (supported)
      window.nativeBridge?.eventTarget.addEventListener(handleReplyMessage)
    return () =>
      supported &&
      window.nativeBridge?.eventTarget.removeEventListener(handleReplyMessage)
  }, [handleReplyMessage, supported])

  return { postMessage, supported }
}
