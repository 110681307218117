import { useCallback, useState } from "react"
import { useDebounce } from "use-debounce"

function isBlankOrTwoOrMoreChars(str) {
  return str.length !== 1
}

export function useValidSearchTerm(
  initialSearchTerm = "",
  { isValid = isBlankOrTwoOrMoreChars } = {},
) {
  const [searchTerm, _setSearchTerm] = useState(initialSearchTerm)
  const [validSearchTerm, setValidSearchTerm] = useState(initialSearchTerm)
  const setSearchTerm = useCallback((searchTerm) => {
    _setSearchTerm(searchTerm)
    if (isValid) {
      setValidSearchTerm(searchTerm)
    }
  }, [])
  const [debouncedValidSearchTerm] = useDebounce(validSearchTerm, 300)
  return { setSearchTerm, searchTerm, debouncedValidSearchTerm }
}
