import { array, bool, func, string } from "prop-types"
import { questionShape } from "source/registrations/propTypes"
import { isStringBlank } from "source/registrations/utils/helpers"
import { TextInput } from "@planningcenter/doxy-web"
import classNames from "classnames"

const QuestionInput = ({
  fieldId,
  question,
  onChange,
  answers = [],
  removeContainer,
}) => {
  const handleChange = ({ target: { value } }) =>
    onChange(question.id, isStringBlank(value) ? [] : [value])

  return (
    <div className={classNames("mb-1", { "action-drawer": !removeContainer })}>
      <label htmlFor={fieldId} className={`${question.description && "pb-0"}`}>
        {question.question}
        {(question.required || question.publiclyRequired) && (
          <span className="c-ruby required-symbol"> *</span>
        )}
      </label>
      {question.description && (
        <p className="fs-4 c-tint2 mb-1">{question.description}</p>
      )}

      {question.kind === "text" && (
        <TextInput
          id={fieldId}
          value={answers[0] || ""}
          onChange={handleChange}
        />
      )}

      {question.kind === "paragraph" && (
        <textarea
          id={fieldId}
          value={answers[0] || ""}
          onChange={handleChange}
        />
      )}
    </div>
  )
}

QuestionInput.propTypes = {
  question: questionShape.isRequired,
  onChange: func.isRequired,
  fieldId: string.isRequired,
  answers: array,
  removeContainer: bool,
}

export default QuestionInput
