import { Suspense } from "react"
import { shape, string } from "prop-types"
import { Menu, MenuButton, MenuList, MenuLink } from "@reach/menu-button"
import {
  BreadcrumbNavigation,
  BreadcrumbContent,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { useAllGroupTypes } from "source/groups/hooks/useGroupTypes"
import { useGroupType } from "../hooks/useGroupType"

export function BreadcrumbNav() {
  const { data: groupType } = useGroupType()

  return (
    <BreadcrumbNavigation>
      <BreadcrumbPage linkUrl="/groups" pageText="All groups" />
      <BreadcrumbDividerIcon />
      <BreadcrumbContent isActive>
        <Suspense
          fallback={<span className="pl-1">{groupType.attributes.name}</span>}
        >
          <GroupTypesMenu groupType={groupType} />
        </Suspense>
      </BreadcrumbContent>
    </BreadcrumbNavigation>
  )
}

function GroupTypesMenu({ groupType }) {
  const groupTypes = useAllGroupTypes()
  const otherGroupTypes = groupTypes.filter((gt) => gt.id !== groupType.id)

  return (
    <Menu>
      <MenuButton
        className="select breadcrumbs__btn"
        style={{ marginLeft: "auto" }}
      >
        {groupType.attributes.name}
      </MenuButton>
      <MenuList>
        {otherGroupTypes.map((gt) => (
          <MenuLink key={gt.id} href={gt.attributes.church_center_web_url}>
            {gt.attributes.name}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  )
}
GroupTypesMenu.propTypes = {
  groupType: shape({
    id: string.isRequired,
    attributes: shape({
      name: string.isRequired,
    }).isRequired,
  }).isRequired,
}
