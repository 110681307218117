import { shape, string } from "prop-types"
import { Card } from "source/shared/components"
import { capitalize } from "lodash"

Group.propTypes = {
  group: shape({
    attributes: shape({
      name: string.isRequired,
      church_center_web_url: string.isRequired,
      header_image: shape({ medium: string.isRequired }).isRequired,
      enrollment_status: string.isRequired,
      schedule: string,
    }).isRequired,
  }),
}

export function Group({ group }) {
  return (
    <Card
      link={group.attributes.church_center_web_url}
      poster={group.attributes.header_image.medium}
      posterAltText={`Logo for ${group.attributes.name}`}
      heading={group.attributes.name}
      description={group.attributes.schedule}
      badges={[
        {
          label: capitalize(group.attributes.enrollment_status),
          theme:
            group.attributes.enrollment_status === "open"
              ? "success"
              : "default",
        },
      ]}
    />
  )
}
