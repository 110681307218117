import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { Select } from "source/shared/components"

const GRADES = [
  "Pre-K",
  "K",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
]

const GradeField = ({
  id,
  attendee,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
}) => {
  const label = attendee.attendeeType.gradeLabel || "Grade"

  const { grade } = person
  const { grade: existingPersonGrade } = existingPerson

  const options = [
    { label: `Select ${label.toLowerCase()}`, value: "" },
    ...GRADES.map((grade, index) => ({
      label: grade,
      value: index - 1,
    })),
  ]

  const gradeToString = (gradeNumber) => {
    if (gradeNumber === null || gradeNumber === undefined) return ""
    return GRADES[Number(gradeNumber) + 1]
  }

  return (
    <Field
      label={label}
      value={gradeToString(grade)}
      persistedValue={gradeToString(existingPersonGrade)}
      onCancel={() => onCancel("grade")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
    >
      <Select
        name={`grade-${id}`}
        onChange={({ target: { value } }) => onChange("grade", value)}
        value={grade == 0 || grade ? grade : ""}
        readOnly={!!existingPersonGrade || existingPersonGrade === 0}
        style={{ height: "32px" }}
        options={options}
      />
    </Field>
  )
}

GradeField.propTypes = {
  id: oneOfType([string, number]),
  attendee: shape({
    attendeeType: shape({
      gradeLabel: string,
    }),
  }),
  person: shape({
    grade: oneOfType([string, number]),
  }),
  existingPerson: shape({
    grade: oneOfType([string, number]),
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
}

export default GradeField
