import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { TextInput } from "@planningcenter/doxy-web"

const AddressField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
}) => {
  const { address } = person
  const { address: existingPersonAddress } = existingPerson

  const handleChange = (field, value) =>
    onChange("address", { ...address, [field]: value })

  const addressToString = (personAddress) =>
    [
      ...(personAddress?.street ? [personAddress.street] : []),
      ...(personAddress?.city ? [personAddress.city] : []),
      ...(personAddress?.state ? [personAddress.state] : []),
      ...(personAddress?.zip ? [personAddress.zip] : []),
    ].join(", ")

  return (
    <Field
      label="Address"
      value={addressToString(address)}
      persistedValue={addressToString(existingPersonAddress)}
      onCancel={() => onCancel("address")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
    >
      <div>
        <TextInput
          id={`street-${id}`}
          value={address?.street || ""}
          readOnly={!!existingPersonAddress}
          onChange={({ target: { value } }) => handleChange("street", value)}
          size="sm"
          placeholder="Street address"
        />
        <div className="mt-1 d-f ai-c g-1">
          <div css={{ width: 0, flexGrow: 3 }}>
            <TextInput
              id={`city-${id}`}
              value={address?.city || ""}
              readOnly={!!existingPersonAddress}
              onChange={({ target: { value } }) => handleChange("city", value)}
              size="sm"
              placeholder="City"
            />
          </div>
          <div css={{ width: 0, flexGrow: 1 }}>
            <TextInput
              id={`state-${id}`}
              value={address?.state || ""}
              readOnly={!!existingPersonAddress}
              onChange={({ target: { value } }) => handleChange("state", value)}
              size="sm"
              placeholder="State"
            />
          </div>
          <div css={{ width: 0, flexGrow: 2 }}>
            <TextInput
              id={`zip-${id}`}
              value={address?.zip || ""}
              readOnly={!!existingPersonAddress}
              onChange={({ target: { value } }) => handleChange("zip", value)}
              size="sm"
              placeholder="Zip"
            />
          </div>
        </div>
      </div>
    </Field>
  )
}

AddressField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    address: shape({
      street: oneOfType([string, number]),
      city: oneOfType([string, number]),
      state: oneOfType([string, number]),
      zip: oneOfType([string, number]),
    }),
  }),
  existingPerson: shape({
    address: shape({
      street: oneOfType([string, number]),
      city: oneOfType([string, number]),
      state: oneOfType([string, number]),
      zip: oneOfType([string, number]),
    }),
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
}

export default AddressField
