import { Fragment } from "react"
import { string, bool, node, shape, number, array } from "prop-types"
import { css } from "@emotion/react"
import moment from "moment"
import { useLocation, useParams } from "react-router-dom"
import { size } from "@planningcenter/system"
import { useSession } from "source/shared/hooks/useSession"
import { WideLayout } from "source/Layout"
import { colors } from "source/shared/colors"
import { useApiRead } from "source/shared/SessionApiResource"
import {
  LocationMap,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  HeroImage,
  Error404,
  RoutingAwareDangerousHTML,
} from "source/shared/components"
import { useQRCode } from "source/shared/QRCode"
import { sortBy } from "lodash/collection"
import { Grid, Heading, Subheading } from "@planningcenter/doxy-web"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

import calendarEventDetails from "source/calendar/utils/calendarEventDetails"
import {
  Date,
  Datetime,
  Time,
  SmallVisualizedDate,
} from "source/shared/DatetimeFmt"

import BookmarkEvent from "./BookmarkEvent"

function stringifyFields(fields) {
  return Object.entries(fields)
    .map(([key, value]) => {
      return `fields[${key}]=${value.join(",")}`
    })
    .join("&")
}

function stringifyIncludes(includes) {
  return `include=${includes.join(",")}`
}

const include = [
  "location",
  "event_registration_url",
  "event_times",
  "event_source",
  "category_tags",
  "campus_tags",
]

const fields = {
  Event: [
    "additional_dates_bookmarked",
    "all_day_event",
    "bookmarked",
    "description",
    "ends_at",
    "event_registration_url",
    "event_source",
    "image_url",
    "location",
    "multi_day_event",
    "name",
    "open_graph_description",
    "open_graph_image_url",
    "recurrence_description",
    "recurring",
    "registration_url",
    "starts_at",
  ],
  EventSource: [
    "source_description",
    "source_link_text",
    "source_type",
    "source_url",
  ],
  Location: [
    "display_on_church_center",
    "full_formatted_address",
    "latitude",
    "longitude",
    "name",
    "type",
    "url",
  ],
  RegistrationUrl: ["announcement_only", "name", "registrations_event", "url"],
}

const params = [stringifyIncludes(include), stringifyFields(fields)].join("&")

const useEvent = (eventId, params) => {
  const shouldFetch = Boolean(eventId)
  return useApiRead(
    shouldFetch ? `/calendar/v2/events/${eventId}?${params}` : null,
    {
      version: "2021-06-23",
    },
  )
}

export default function CalendarEvent() {
  useQRCode()
  const routerLocation = useLocation()

  const { eventId } = useParams()

  const event = useEvent(eventId, params)

  if (!eventId) return null

  if (event.errors?.some((error) => error.status === "404")) {
    return <Error404 />
  }

  const {
    additionalDatesBookmarked,
    allDayEvent,
    description,
    bookmarked,
    endsAt,
    eventSource,
    imageUrl,
    location,
    multiDayEvent,
    name,
    openGraph,
    recurrenceDescription,
    recurring,
    registrationUrl,
    startsAt,
  } = calendarEventDetails(event)

  const eventTimes = event.included.filter((inc) => inc.type === "EventTime")
  const categories = sortBy(
    event.included.filter((inc) => inc.type === "CategoryTag"),
    (c) => c.attributes.name.toLowerCase(),
  )
  const campuses = sortBy(
    event.included.filter((inc) => inc.type === "CampusTag"),
    (c) => c.attributes.name.toLowerCase(),
  )

  const eventDate = routerLocation.state && routerLocation.state.eventDate

  return (
    <>
      <OpenGraphTags
        title={name}
        description={openGraph.description}
        imageUrl={openGraph.imageUrl}
      />
      <WideLayout>
        <div>
          <BreadcrumbNavigation>
            <BreadcrumbPage
              linkUrl="/calendar"
              linkState={{ eventId, eventDate }}
              pageText="All events"
            />
            <BreadcrumbDividerIcon />
            <BreadcrumbPage pageText={name} isActive />
          </BreadcrumbNavigation>
          <EventDetails
            {...{ additionalDatesBookmarked, bookmarked, multiDayEvent }}
            allDayEvent={allDayEvent}
            campuses={campuses}
            categories={categories}
            endsAt={endsAt}
            eventId={eventId}
            eventTitle={name}
            eventRecurrence={recurrenceDescription}
            eventSignup={registrationUrl}
            eventDescription={description}
            eventLocation={location}
            eventSchedule={eventTimes}
            eventSource={eventSource}
            imageUrl={imageUrl}
            isConnectedToRegistrationsSignup={
              registrationUrl?.registrationsEvent
            }
            recurring={recurring}
            startsAt={startsAt}
          />
        </div>
      </WideLayout>
    </>
  )
}

EventDate.propTypes = {
  allDayEvent: bool,
  endsAt: string,
  multiDayEvent: bool,
  startsAt: string,
}
export function EventDate({ allDayEvent, endsAt, multiDayEvent, startsAt }) {
  const inCurrentYear = moment().year() === moment(endsAt).year()
  const dateStyle = multiDayEvent ? "standard" : "long"

  return allDayEvent ? (
    <>
      <Date
        start={startsAt}
        end={endsAt}
        style={dateStyle}
        year={!inCurrentYear}
      />{" "}
      (all day)
    </>
  ) : (
    <Datetime
      start={startsAt}
      end={endsAt}
      style={dateStyle}
      year={!inCurrentYear}
    />
  )
}

function CampusTags({ campuses }) {
  return campuses.map((campus, idx) => {
    return (
      <span key={idx} className="badge mr-4p">
        {campus.attributes.name}
      </span>
    )
  })
}

function CategoryTags({ categories }) {
  return categories.map((category, idx) => {
    return (
      <span key={idx} className="badge mr-4p">
        {category.attributes.name}
      </span>
    )
  })
}

function EventDetails({
  additionalDatesBookmarked,
  allDayEvent,
  bookmarked,
  campuses,
  categories,
  endsAt,
  eventTitle,
  eventDescription,
  eventId,
  eventLocation,
  eventRecurrence,
  eventSchedule,
  eventSignup,
  eventSource,
  imageUrl,
  isConnectedToRegistrationsSignup,
  multiDayEvent,
  recurring,
  startsAt,
}) {
  const {
    meta: { authenticated },
  } = useSession(false)
  const shouldUseNewSignupBadgeLogic = useFlipperFeatureEnabled(
    "ROLLOUT_calendar_new_signups_logic",
  )

  const fromFeed = !!eventSource
  const bookmarkable =
    authenticated && !fromFeed && !isConnectedToRegistrationsSignup

  const shouldShowEventSourceBanner = eventSource && !eventSignup
  const legacyLogic = eventSignup && !eventSignup.announcementOnly
  const newLogic = eventSignup?.canRegister
  const signupsAvailable = shouldUseNewSignupBadgeLogic ? newLogic : legacyLogic

  return (
    <div className="makeRoomForTheActionBarOnMobile">
      <div className="mb-3">
        <Grid columns={[1, { md: imageUrl ? 2 : 1 }]} gap={[0, { md: 4 }]}>
          {imageUrl && <HeroImage alt={`${eventTitle} logo`} src={imageUrl} />}
          <div className="ai-t d-f">
            {!imageUrl && (
              <div className="mr-2">
                <SmallVisualizedDate start={startsAt} />
              </div>
            )}
            <div className="d-f fd-c jc-c">
              <div className="mb-4p">
                <Heading level={1} text={eventTitle} />
              </div>
              {campuses.length > 0 && (
                <div className="mb-2p">
                  <CampusTags {...{ campuses }} />
                </div>
              )}
              {recurring && <div>{eventRecurrence}</div>}
              <EventDate
                {...{ allDayEvent, endsAt, multiDayEvent, startsAt }}
              />
              {bookmarkable && (
                <BookmarkEvent
                  {...{
                    additionalDatesBookmarked,
                    bookmarked,
                    eventId,
                    multiDayEvent,
                    recurring,
                  }}
                  className="as-fs mt-2"
                />
              )}
            </div>
          </div>
        </Grid>
      </div>
      {shouldShowEventSourceBanner && (
        <EventSourceBanner eventSource={eventSource} />
      )}
      {signupsAvailable && (
        <div className={`mb-3@sm px-2 action-drawer d-f ai-c jc-sb action-bar`}>
          <div className={`c-tint2 fs-13 f-1 mr-2 lh-1.5`}>
            Signups are available for this event
          </div>
          <a href={eventSignup.url} className="btn">
            Sign up
          </a>
        </div>
      )}

      <div className="d-f@md">
        <div className="w-33%@md o-1@md">
          <EventSchedule {...{ eventSchedule, allDayEvent, eventTitle }} />
          {categories.length > 0 && (
            <div className="mb-3">
              <div className="mb-1">
                <Subheading level={2} text="Categories" />
              </div>
              <CategoryTags {...{ categories }} />
            </div>
          )}
          {eventLocation && <EventLocation eventLocation={eventLocation} />}
        </div>
        {eventDescription && (
          <div className="mb-3 f-1 pr-4@md makeRoomForTheActionBarOnMobile">
            <div className="mb-1">
              <Heading level={2} size={3} text="Details" />
            </div>
            <RoutingAwareDangerousHTML
              html={eventDescription}
              styles={{ wordWrap: "anywhere" }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

EventDetails.propTypes = {
  additionalDatesBookmarked: bool,
  allDayEvent: bool,
  bookmarked: bool,
  campuses: array,
  categories: array,
  children: node,
  endsAt: string,
  eventTitle: string,
  eventDescription: string,
  eventId: string,
  eventLocation: shape(eventLocationProps),
  eventRecurrence: string,
  eventSchedule: array,
  eventSignup: shape({
    announcementOnly: bool,
    canRegister: bool,
    id: string,
    registrationsEvent: bool,
    url: string,
  }),
  eventSource: shape({
    id: number,
    description: string,
    linkText: string,
    url: string,
  }),
  imageUrl: string,
  isConnectedToRegistrationsSignup: bool,
  multiDayEvent: bool,
  recurring: bool,
  startsAt: string,
}

EventSchedule.propTypes = {
  eventTitle: string,
  eventSchedule: array,
}
function EventSchedule({ eventSchedule, eventTitle }) {
  const styles = {
    scheduleGrid: css`
      display: grid;
      grid-template-columns: fit-content(20%) 3fr;
      row-gap: 4px;
    `,
    scheduleItemTime: css`
      color: ${colors.tint2};
      padding-right: ${size(2)}px;
      white-space: nowrap;
    `,
  }

  const scheduleItems = eventSchedule.map((schedule) => ({
    id: schedule.id,
    startsAt: schedule.attributes.starts_at,
    endsAt: schedule.attributes.ends_at,
    name: schedule.attributes.name,
  }))

  if (scheduleItems.length === 1 && scheduleItems[0].name === eventTitle) {
    return null
  }

  return (
    <div className="mb-3">
      <div className="mb-1">
        <Subheading level={2} text="Schedule" />
      </div>
      <div className="unstyled" css={styles.scheduleGrid}>
        {scheduleItems.map((item) => (
          <Fragment key={item.id}>
            <span css={styles.scheduleItemTime}>
              <Time start={item.startsAt} end={item.endsAt} />
            </span>
            <span>{item.name}</span>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

EventLocation.propTypes = { eventLocation: shape(eventLocationProps) }
function EventLocation({ eventLocation }) {
  const { name, type, url } = eventLocation

  const locationIsDigital = type === "Virtual"

  return (
    <div className="mb-3">
      <div className="mb-1">
        <Subheading level={2} text="Location" />
      </div>
      {locationIsDigital ? (
        <VirtualLocation {...{ name, url }} />
      ) : (
        <PhysicalLocation {...{ eventLocation }} />
      )}
    </div>
  )
}

VirtualLocation.propTypes = { name: string, url: string }
function VirtualLocation({ name, url }) {
  return url ? <a href={url}>{name}</a> : name
}

PhysicalLocation.propTypes = {
  eventLocation: shape(eventLocationProps),
}
function PhysicalLocation({ eventLocation }) {
  const { displayOnChurchCenter, name, formattedAddress } = eventLocation

  return (
    <>
      <div>{name}</div>
      {formattedAddress && (
        <p style={{ whiteSpace: "pre-line" }}>{formattedAddress}</p>
      )}
      {displayOnChurchCenter && <LocationMap location={eventLocation} />}
    </>
  )
}

var eventLocationProps = {
  displayOnChurchCenter: bool,
  name: string,
  url: string,
  formattedAddress: string,
  latitude: number,
  longitude: number,
  type: string,
}

EventSourceBanner.propTypes = {
  eventSource: shape({
    description: string,
    linkText: string,
    url: string,
  }),
}
function EventSourceBanner({ eventSource }) {
  const { url, linkText, description } = eventSource
  return (
    <div className={`mb-3@sm px-2 action-drawer d-f ai-c jc-sb action-bar`}>
      <div className={`c-tint2 fs-13 f-1 mr-2 lh-1.5`}>{description}</div>
      {url && (
        <a href={url} className="btn">
          {linkText}
        </a>
      )}
    </div>
  )
}
