import { Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { HardRefresh } from "source/shared/routingUtils"
import { useSession } from "source/shared/hooks/useSession"
import MessageShow from "./MessageShow"

export function Messages() {
  useSession(true)

  return (
    <>
      <Routes>
        <Route path="/:id" element={<MessageShow />} />
      </Routes>
      <Helmet title="Messages" />
    </>
  )
}

export function People() {
  return <HardRefresh />
}
