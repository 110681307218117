import { useContext } from "react"
import { Grid } from "@planningcenter/doxy-web"
import { arrayOf, node, object, shape, string } from "prop-types"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { FiltersContext } from "../contexts/FiltersContext"
import { useAllTags } from "../hooks/useAllTags"

const TagProp = shape({
  id: string.isRequired,
  attributes: shape({
    name: string.isRequired,
  }),
})

export function TagFilter() {
  const tagGroups = useTagGroups()
  const hasTagFilters = tagGroups.length > 0

  if (!hasTagFilters) return null

  return (
    <TagSelectGrid>
      {tagGroups.map(([tagGroup, tags]) =>
        tagGroup.attributes.multiple_options_enabled ? (
          <TagGroupMultiSelect
            name={tagGroup.attributes.name}
            tags={tags}
            key={tagGroup.id}
          />
        ) : (
          <TagGroupSingleSelect
            name={tagGroup.attributes.name}
            tags={tags}
            key={tagGroup.id}
          />
        ),
      )}
    </TagSelectGrid>
  )
}

function TagSelectGrid({ children }) {
  return (
    <Grid columns={[1, { xs: 2, md: 3, lg: 4 }]} gap={2}>
      {children}
    </Grid>
  )
}
TagSelectGrid.propTypes = { children: node }

const TagGroupSelectPropTypes = {
  name: string.isRequired,
  tags: arrayOf(TagProp).isRequired,
}

function useTitle(tagGroupName, tags) {
  const { tagIds } = useContext(FiltersContext)

  const selectedTags = tags.filter((tag) =>
    tagIds.some((tagId) => tag.id === tagId),
  )

  switch (selectedTags.length) {
    case 0:
      return tagGroupName
    case 1:
      return `${tagGroupName}: ${selectedTags[0].attributes.name}`
    default:
      return `${tagGroupName}: ${selectedTags.length} selected`
  }
}

function MenuTitle({ name, tags }) {
  const title = useTitle(name, tags)

  return <MenuButton className="select truncate ta-l">{title}</MenuButton>
}
MenuTitle.propTypes = TagGroupSelectPropTypes

function TagGroupSingleSelect({ name, tags }) {
  return (
    <Menu>
      <MenuTitle name={name} tags={tags} />
      <ScrollableMenuList>
        <AnyItem tags={tags} />
        {tags.map((tag) => (
          <SingleSelectTagItem key={tag.id} tag={tag} tags={tags} />
        ))}
      </ScrollableMenuList>
    </Menu>
  )
}
TagGroupSingleSelect.propTypes = TagGroupSelectPropTypes

function TagGroupMultiSelect({ name, tags }) {
  return (
    <Menu>
      <MenuTitle name={name} tags={tags} />
      <ScrollableMenuList>
        {tags.map((tag) => (
          <MultiSelectTagItem key={tag.id} tag={tag} />
        ))}
      </ScrollableMenuList>
    </Menu>
  )
}
TagGroupMultiSelect.propTypes = TagGroupSelectPropTypes

function AnyItem({ tags }) {
  const { setTagIds } = useContext(FiltersContext)

  return (
    <MenuItem
      className="stripped-btn w-100% ta-l"
      onSelect={() =>
        setTagIds((prevTagIds) =>
          prevTagIds.filter((tagId) => !tags.some((tag) => tagId === tag.id)),
        )
      }
    >
      (any)
    </MenuItem>
  )
}
AnyItem.propTypes = {
  tags: arrayOf(TagProp).isRequired,
}

function SingleSelectTagItem({ tag, tags }) {
  const { setTagIds } = useContext(FiltersContext)
  const inOtherGroup = (tagId) => !tags.some((ot) => ot.id === tagId)

  return (
    <MenuItem
      className="stripped-btn w-100% ta-l"
      onSelect={() =>
        setTagIds((prevTagIds) => [...prevTagIds.filter(inOtherGroup), tag.id])
      }
    >
      {tag.attributes.name}
    </MenuItem>
  )
}
SingleSelectTagItem.propTypes = {
  tag: TagProp.isRequired,
  tags: arrayOf(TagProp).isRequired,
}

function MultiSelectTagItem({ tag }) {
  const { tagIds, setTagIds } = useContext(FiltersContext)
  const isSelected = tagIds.some((id) => tag.id === id)
  const inputId = `tag_select_${tag.id}`

  return (
    <MenuItem
      className="dropdown__item"
      onSelect={() =>
        setTagIds((prevTagIds) =>
          isSelected
            ? prevTagIds.filter((id) => id !== tag.id)
            : [...prevTagIds, tag.id],
        )
      }
    >
      <input
        id={inputId}
        type="checkbox"
        className="checkbox"
        checked={isSelected}
      />
      <label
        htmlFor={inputId}
        className="checkbox-label"
        style={{ verticalAlign: "baseline" }}
      >
        {tag.attributes.name}
      </label>
    </MenuItem>
  )
}
MultiSelectTagItem.propTypes = {
  tag: TagProp.isRequired,
}

function ScrollableMenuList({ style = {}, ...rest }) {
  const scrollableStyle = {
    ...style,
    maxHeight: "15.5rem",
    maxWidth: "13rem",
    overflow: "auto",
  }
  return <MenuList {...rest} style={scrollableStyle} />
}
ScrollableMenuList.propTypes = {
  style: object,
}

function useTagGroups() {
  const { data: tags, included: tagGroups } = useAllTags()

  return tagGroups.reduce(
    (acc, tagGroup) => [
      ...acc,
      [
        tagGroup,
        tags.filter(
          (tag) => tag.relationships.tag_group.data.id === tagGroup.id,
        ),
      ],
    ],
    [],
  )
}
