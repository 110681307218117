import { useMemo } from "react"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { time as formatTime } from "@churchcenter/datetime-fmt"
import { bool, string, arrayOf, number } from "prop-types"

Attendee.propTypes = {
  ends_at: string,
  all_day: bool,
  registration_completion_status: string,
  registration_id: number,
  selection_name: string,
  selections: arrayOf({
    selection_name: string,
  }),
  signup_name: string,
  starts_at: string,
  day: number,
  total_days: number,
}

export default function Attendee({
  ends_at,
  all_day,
  registration_completion_status,
  registration_id,
  selections,
  signup_name,
  starts_at,
  day,
  total_days,
}) {
  const isLastDay = total_days > 1 && day === total_days
  const isFirstDay = total_days > 1 && day === 1

  const activityRecordTitle = useMemo(() => {
    if (all_day) {
      return `${signup_name} (all day)`
    }
    if (isFirstDay) {
      return `${formatTime(starts_at, { showTimeZone: false })} ${signup_name}`
    }
    if (isLastDay) {
      return `${signup_name} (until ${formatTime(ends_at, {
        showTimeZone: false,
      })})`
    }
    return `${formatTime(starts_at, ends_at, {
      showTimeZone: false,
    })} ${signup_name}`
  }, [signup_name, all_day, isFirstDay, isLastDay, ends_at, starts_at])

  const registrationStatusStyle = css`
    background-color: var(--color-citrine);
    border-radius: 100%;
    display: block;
    height: 10px;
    width: 10px;
    margin-right: 8px;
    position: relative;
    top: 1px;
    left: 2px;
  `

  const completionStatus = registration_completion_status === "incomplete" && (
    <span css={registrationStatusStyle} />
  )

  return (
    <Link
      to={`/registrations/registrations/${registration_id}`}
      className="mb-2"
    >
      <div className="c-brand fw-500">{activityRecordTitle}</div>
      <>
        {selections.map((selection) => {
          return (
            <div key={selection.selection_name} className="d-f ai-c fs-4">
              {completionStatus}
              <div className="c-tint2">{selection.selection_name}</div>
            </div>
          )
        })}
      </>
    </Link>
  )
}
