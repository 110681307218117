import { useState, useEffect } from "react"
import { array, object, func, number } from "prop-types"
import { useParams } from "react-router-dom"
import AttendeeTypeString from "source/registrations/reservations/AttendeeTypeString"
import AttendeesPersonForm from "source/registrations/reservations/AttendeesPersonForm"
import {
  restrictionsToString,
  isSelectionDisabled,
} from "source/registrations/restrictions"
import { useEvent } from "source/registrations/hooks/useEvent"

const AttendeesPersonSelect = ({
  attendee,
  people,
  session,
  step,
  onPersonChange,
  onPersonFieldChange,
  onEmergencyContactChange,
  onApplyEmergencyContactToAll,
  households,
  maxHouseholdSize,
  attendeeErrors = [],
  reservation,
}) => {
  const { eventId } = useParams()

  const {
    data: { canRegisterHouseholdMembers, canRegisterOutsideMembers },
  } = useEvent(eventId)

  const [isNewPerson, setIsNewPerson] = useState(false)

  useEffect(() => {
    if (isNewPerson && attendee?.person?.id) {
      setIsNewPerson(false)
    }
  }, [attendee, isNewPerson])

  const handleAttendeePersonChange = ({ target: { value } }) => {
    setIsNewPerson(value === "new" ? true : false)
    onPersonChange(value, attendee)
  }

  const handleChange = (attendee, field, value, useContactForAllAttendees) => {
    field === "emergencyContact"
      ? onEmergencyContactChange(attendee, value, useContactForAllAttendees)
      : onPersonFieldChange(attendee, field, value)
  }
  const peopleRestrictions = people.reduce((obj, person) => {
    obj[person.id] = {
      ...isSelectionDisabled(attendee.attendeeType, person, attendee.waitlist),
    }
    return obj
  }, {})

  const peopleOptions = [
    { label: "Select a person", value: "" },
    ...people.map((person) => ({
      label: `${person.name} ${peopleRestrictions[person.id]?.disabledReason}`,
      value: person.id,
      disabled: peopleRestrictions[person.id]?.isDisabled ?? false,
    })),
  ]

  if (
    !reservation.responsiblePerson.child &&
    (canRegisterHouseholdMembers || canRegisterOutsideMembers)
  ) {
    peopleOptions.push({
      label:
        canRegisterHouseholdMembers && !canRegisterOutsideMembers
          ? "Add a new household member"
          : "Add a new person",
      value: "new",
    })
  }

  const errors = attendeeErrors.filter((error) => error.id === attendee.id)

  return (
    <div className={`${step.isCurrent ? "mb-2" : "c-tint2"}`}>
      {step.isCurrent && (
        <div className="mb-1">
          <label className="label" htmlFor={`attendee-${attendee.id}`}>
            <AttendeeTypeString attendee={attendee} />
            <div className="fw-400 fs-4 c-tint2">
              {restrictionsToString(attendee.attendeeType)}
            </div>
          </label>

          <AttendeesPersonForm
            attendee={attendee}
            people={people}
            isNewPerson={isNewPerson}
            onChange={handleChange}
            onApplyEmergencyContactToAll={onApplyEmergencyContactToAll}
            households={households}
            session={session}
            maxHouseholdSize={maxHouseholdSize}
            onAttendeePersonChange={handleAttendeePersonChange}
            peopleOptions={peopleOptions}
            errors={errors}
            reservation={reservation}
          />
        </div>
      )}

      {!step.isCurrent && step.isComplete && (
        <div className="mr-1">
          <AttendeeTypeString attendee={attendee} isTruncated />
        </div>
      )}
    </div>
  )
}

AttendeesPersonSelect.propTypes = {
  attendee: object,
  people: array,
  session: object,
  step: object,
  onPersonChange: func,
  onPersonFieldChange: func,
  onEmergencyContactChange: func,
  onApplyEmergencyContactToAll: func,
  households: array,
  attendeeErrors: array,
  maxHouseholdSize: number,
  reservation: object,
}

export default AttendeesPersonSelect
