import classNames from "classnames"
import { node, string } from "prop-types"

SuccessMessage.propTypes = {
  children: node,
  className: string,
}

SuccessMessage.defaultProps = {
  className: "",
}

export function SuccessMessage(props) {
  const { children, ...rest } = props

  const className = classNames("success-alert alert", props.className)

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  )
}
