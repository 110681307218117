import {
  useSuspenseQuery,
  splitQueryParams,
  queryFn,
  useQueryClient,
} from "source/registrations/utils/queryCache"

const REGISTRATION_INCLUDES = [
  "responsible_person",
  "event",
  "attendees",
  "attendees.attendee_type",
  "attendees.emergency_contact",
  "attendee_types.signup_times",
  "attendees.answers",
  "answers",
  "event.questions",
  "event.contact",
  "event.event_times",
  "jolt_channel",
  "payment_intent",
  "attendees.person",
  "people",
]

export const REGISTRATION_FIELDS = {
  Registration: [
    "event",
    "responsible_person",
    "created_at",
    "attendees",
    "questions",
    "cancel_registration_url",
    "canceled_on",
    "has_cost",
    "jolt_channel",
    "payment_intent",
    "minimum_due",
    "minimum_due_string",
    "attendee_summaries",
    "finalized",
    "required_forms",
    "total_due",
    "use_stripe_webhooks",
    "is_detailed",
    "edit_registration_url",
    "payment_intent",
    "minimum_due",
    "minimum_due_string",
    "answers",
    "people",
    "potential_emergency_contacts",
    "allowed_account_center_ids",
    "missing_required_questions",
  ],
  EmergencyContact: ["name", "phone_number"],
  Event: [
    "public_url",
    "questions",
    "contact",
    "event_times",
    "starts_at",
    "name",
    "endsAt",
    "new_registration_url",
    "register_for_separate_times",
    "registration_type",
    "has_addons_module",
    "has_attendees_module",
    "has_attendee_questions_module",
    "has_payments_module",
    "has_registrant_questions_module",
    "allow_credit_card_payments",
    "allow_minimum_deposits",
    "currency",
    "allow_registrants_to_cancel",
  ],
  Question: [
    "required",
    "description",
    "kind",
    "question",
    "choices",
    "allow_multiple",
    "question_group",
  ],
  Answer: ["question", "answers", "is_modified"],
  EventTime: ["id", "date", "time"],
  Attendee: [
    "attendee_type",
    "person",
    "waitlist",
    "emergency_contact",
    "emergency_contact_name",
    "emergency_contact_phone_number",
    "answers",
  ],
  AttendeeType: [
    "signup_time",
    "name",
    "collection_options",
    "grade_label",
    "grade_options",
    "attendee_type_capacity_to_string",
    "at_maximum_capacity",
    "price_cents",
  ],
  Person: [
    "email_address",
    "name",
    "avatar_url",
    "grade",
    "medical_notes",
    "phone_number",
    "birthdate",
    "address",
    "gender",
  ],
  PaymentIntent: ["amount", "status", "amount_formatted", "client_secret"],
  JoltChannel: ["id"],
}

export const useRegistration = (registrationId, options = {}) => {
  const path = `/registrations/v2/registrations/${registrationId}`
  const { params, queryOptions } = splitQueryParams(options)

  const response = useSuspenseQuery({
    queryFn,
    queryKey: [
      path,
      {
        include: REGISTRATION_INCLUDES,
        fields: REGISTRATION_FIELDS,
        ...params,
      },
    ],
    ...queryOptions,
  })

  const invalidate = useInvalidateRegistration(registrationId, {
    retry: false,
  })

  return {
    ...response,
    data: {
      ...response.data,
      invalidate: invalidate,
    },
  }
}

export const useInvalidateRegistration = (registrationId, options = {}) => {
  const queryClient = useQueryClient()
  const path = `/registrations/v2/registrations/${registrationId}`
  const { params } = splitQueryParams(options)

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: [path, params],
    })
  }
  return invalidate
}
