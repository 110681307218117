// Much of this is taken from https://github.com/jquense/react-big-calendar/blob/master/src/localizers/moment.js

import moment from "moment"

function eq(a, b, unit) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit)
  return dtA.isSame(dtB, datePart)
}

function defineComparators(a, b, unit) {
  const datePart = fixUnit(unit)
  const dtA = datePart ? moment(a).startOf(datePart) : moment(a)
  const dtB = datePart ? moment(b).startOf(datePart) : moment(b)
  return [dtA, dtB, datePart]
}

function fixUnit(unit) {
  let datePart = unit ? unit.toLowerCase() : unit
  if (datePart === "FullYear") {
    datePart = "year"
  } else if (!datePart) {
    datePart = undefined
  }
  return datePart
}

function add(date, adder, unit) {
  const datePart = fixUnit(unit)
  return moment(date).add(adder, datePart).toDate()
}

function startOf(date = null, unit) {
  const datePart = fixUnit(unit)
  if (datePart) {
    return moment(date).startOf(datePart).toDate()
  }
  return moment(date).toDate()
}

function ceil(date, unit) {
  const datePart = fixUnit(unit)
  const floor = startOf(date, datePart)

  return eq(floor, date) ? floor : add(floor, 1, datePart)
}

function diff(a, b, unit = "day") {
  const datePart = fixUnit(unit)

  const dtA = moment(a)
  const dtB = moment(b)
  return dtB.diff(dtA, datePart)
}

function sortEvents({
  evtA: { start: aStart, end: aEnd, allDay: aAllDay },
  evtB: { start: bStart, end: bEnd, allDay: bAllDay },
}) {
  const startSort = +startOf(aStart, "day") - +startOf(bStart, "day")

  const durA = diff(aStart, ceil(aEnd, "day"), "day")

  const durB = diff(bStart, ceil(bEnd, "day"), "day")

  return (
    startSort || // sort by start Day first
    !!bAllDay - !!aAllDay || // then allDay single day events
    +aStart - +bStart || // then sort by start time
    Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
    +aEnd - +bEnd // then sort by end time
  )
}

export default sortEvents
