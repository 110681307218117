import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { elementType, func, node, object, shape, string } from "prop-types"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"

const ModalContext = createContext()

export function Modal({ children, onOpenFocusRef }) {
  const hasFocusRefOverride = Boolean(onOpenFocusRef)

  const defaultFocusRef = useRef()
  const focusRef = hasFocusRefOverride ? onOpenFocusRef : defaultFocusRef

  const [showDialog, setShowDialog] = useState(false)

  return (
    <ModalContext.Provider
      value={{ showDialog, setShowDialog, focusRef, hasFocusRefOverride }}
    >
      {children}
    </ModalContext.Provider>
  )
}
Modal.propTypes = {
  children: node,
  onOpenFocusRef: shape({ current: object }),
}

Modal.Trigger = ModalTrigger
Modal.Show = ModalShow
Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter
Modal.Close = ModalClose

export function useCloseModal() {
  const { setShowDialog } = useContext(ModalContext)

  return useCallback(() => setShowDialog(false), [setShowDialog])
}

export function useOpenModal() {
  const { setShowDialog } = useContext(ModalContext)

  return useCallback(() => setShowDialog(true), [setShowDialog])
}

function ModalTrigger({
  as: Comp = "button",
  className = "btn",
  style = {},
  dataAttributes = {},
  children,
}) {
  const { setShowDialog } = useContext(ModalContext)
  return (
    <Comp
      className={className}
      style={style}
      onClick={() => setShowDialog(true)}
      {...dataAttributes}
    >
      {children}
    </Comp>
  )
}
ModalTrigger.propTypes = {
  as: elementType,
  className: string,
  children: node,
  dataAttributes: object,
  style: object,
}

function ModalShow({ children, onOpen, onClose = () => {} }) {
  const { focusRef, showDialog, setShowDialog } = useContext(ModalContext)

  useEffect(() => {
    if (showDialog) {
      onOpen && onOpen()
    }
  }, [showDialog, onOpen])

  const handleDismiss = () => {
    setShowDialog(false)
    onClose()
  }

  return (
    showDialog && (
      <AlertDialog leastDestructiveRef={focusRef} onDismiss={handleDismiss}>
        {children}
      </AlertDialog>
    )
  )
}
ModalShow.propTypes = { children: node, onOpen: func, onClose: func }

function ModalHeader({ children }) {
  return <AlertDialogLabel className="mb-1">{children}</AlertDialogLabel>
}
ModalHeader.propTypes = { children: node }

function ModalBody({ children }) {
  return <AlertDialogDescription>{children}</AlertDialogDescription>
}
ModalBody.propTypes = { children: node }

function ModalFooter({ children }) {
  return <div className="d-f jc-fe mt-3">{children}</div>
}
ModalFooter.propTypes = { children: node }

function ModalClose({
  as: Comp = "button",
  className = "btn text-btn mr-2",
  children,
  onClose = () => {},
}) {
  const { hasFocusRefOverride, focusRef, setShowDialog } =
    useContext(ModalContext)

  const handleClose = () => {
    setShowDialog(false)
    onClose()
  }

  const refAttribute = hasFocusRefOverride ? null : { ref: focusRef }

  return (
    <Comp className={className} onClick={handleClose} {...refAttribute}>
      {children}
    </Comp>
  )
}
ModalClose.propTypes = {
  as: elementType,
  className: string,
  children: node,
  onClose: func,
}
