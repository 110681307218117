import { useState, useEffect } from "react"
import { object, func, array } from "prop-types"
import { Select } from "source/shared/components"
import { TextInput } from "@planningcenter/doxy-web"
import { formatEmergencyContact } from "source/registrations/reservations/personInfo/emergencyContact"
import Field from "./Field"

const EmergencyContactField = ({
  attendee,
  onChange,
  potentialEmergencyContacts = [],
  value,
  ...rest
}) => {
  const { id: attendeeId, error } = attendee

  const [showNewFields, setShowNewFields] = useState(false)

  useEffect(() => {
    if (value.id && value.id !== "new" && !value.name) {
      setShowNewFields(false)
    }
  }, [value])

  const emergencyContacts = potentialEmergencyContacts.map((ec) => ({
    ...formatEmergencyContact(ec.name, ec.phoneNumber),
    ...(ec.id && { id: ec.id }),
  }))

  const handleEmergencyContactSelectChange = (value) => {
    if (value == "new") {
      setShowNewFields(true)
      onChange({ id: "new", name: "", phoneNumber: "" })
    } else if (value == "") {
      onChange({ id: null, name: null, phoneNumber: null })
      setShowNewFields(false)
    } else {
      setShowNewFields(false)
      const selected = emergencyContacts.find((ec) => ec.id == value)
      onChange(selected)
    }
  }

  const handleEmergencyContactNameChange = (input) =>
    onChange({
      id: value.id,
      name: input,
      phoneNumber: value.phoneNumber,
    })

  const handleEmergencyContactPhoneNumberChange = (input) =>
    onChange({
      id: value.id,
      name: value.name,
      phoneNumber: input,
    })

  return (
    <Field
      label={"Emergency Contact"}
      value={value.name}
      error={error}
      {...rest}
    >
      <>
        <Select
          name={`emergencyContact-${attendeeId}`}
          value={value.id || ""}
          options={[
            { label: `Select emergency contact`, value: "" },
            ...emergencyContacts.map((ec) => ({
              label: ec.name,
              value: ec.id,
            })),
            { label: "Someone else", value: "new" },
          ]}
          onChange={({ target: { value } }) =>
            handleEmergencyContactSelectChange(value)
          }
          style={{ height: "32px" }}
        />
        {showNewFields && (
          <>
            <div className={"mt-1"}>
              <TextInput
                placeholder="Name"
                size="sm"
                onChange={({ target: { value } }) =>
                  handleEmergencyContactNameChange(value)
                }
                value={value.name || ""}
              />
            </div>
            <div className={"mt-1"}>
              <TextInput
                placeholder="PhoneNumber"
                size="sm"
                onChange={({ target: { value } }) =>
                  handleEmergencyContactPhoneNumberChange(value)
                }
                value={value.phoneNumber || ""}
              />
            </div>
          </>
        )}
      </>
    </Field>
  )
}

EmergencyContactField.propTypes = {
  attendee: object,
  onChange: func,
  value: object,
  potentialEmergencyContacts: array,
}

export default EmergencyContactField
