import { node, oneOf } from "prop-types"
import { Link, useParams } from "react-router-dom"
import { LocationDetails } from "source/groups/components/LocationDetails"
import { EventsList } from "source/groups/components/EventsList"
import { usePastEvents, useUpcomingEvents } from "../hooks/useEvents"
import {
  useCurrentGroup,
  useLocationForCurrentGroup,
} from "../hooks/useCurrentGroup"
import { useFirstPageOfLeaders } from "../hooks/useMemberships"
import { Heading, Subheading } from "@planningcenter/doxy-web"

export function About() {
  const { data: group } = useCurrentGroup()
  const hasSchedule = !!group.attributes.schedule
  const shouldDisplayLeaders = group.attributes.list_leader_names_publicly
  const hasDescription = !!group.attributes.description

  return (
    <AboutWrapper>
      <ScheduleLeadersAndLocationWrapper>
        {hasSchedule && <Schedule />}
        {shouldDisplayLeaders && <LeadersIfAny />}
        <Location />
      </ScheduleLeadersAndLocationWrapper>
      <DescriptionAndEventsWrapper>
        {hasDescription && <Description />}
        <EventsWrapper>
          <UpcomingEvents />
          <PastEvents />
        </EventsWrapper>
      </DescriptionAndEventsWrapper>
    </AboutWrapper>
  )
}

function AboutWrapper({ children }) {
  return <div className="d-f@md mt-2 mt-0@sm">{children}</div>
}
AboutWrapper.propTypes = { children: node }

function ScheduleLeadersAndLocationWrapper({ children }) {
  return <div className="w-33%@md o-1@md fs-4 pt-4p">{children}</div>
}
ScheduleLeadersAndLocationWrapper.propTypes = { children: node }

function Schedule() {
  const { data: group } = useCurrentGroup()

  return (
    <div className="mb-3 mb-4@md pt-2p">
      <Subheading level={2} text="Schedule" />
      <div className="mt-1">{group.attributes.schedule}</div>
    </div>
  )
}

function LeadersIfAny() {
  const { data: leaders } = useFirstPageOfLeaders()
  const leadersHeader = leaders.length === 1 ? "Leader" : "Leaders"

  return (
    leaders.length > 0 && (
      <div className="mb-3">
        <Subheading level={2} text={leadersHeader} />
        <div className="mt-1">
          {leaders.map((leader) => (
            <p key={leader.id} className="fs-4 mb-0 c-tint1">
              {leader.attributes.first_name}
            </p>
          ))}
        </div>
      </div>
    )
  )
}

function Location() {
  const { data: group } = useCurrentGroup()
  const location = useLocationForCurrentGroup()

  return (
    <LocationDetails
      preference={group.attributes.location_type_preference}
      virtual={{ url: group.attributes.virtual_location_url }}
      physical={location?.attributes}
    />
  )
}

function DescriptionAndEventsWrapper({ children }) {
  return <div className="f-1 pr-4@md o-h">{children}</div>
}
DescriptionAndEventsWrapper.propTypes = { children: node }

function Description() {
  const {
    data: {
      attributes: { name, description },
    },
  } = useCurrentGroup()

  return (
    <>
      <Heading level={2} size={3} text={`About ${name}`} />
      <div
        className="mt-2 mb-3 mb-4@md"
        data-preformatted-content="heading-scale:legacy-church-center"
      >
        {/* description is sanitized on the vertex, so we can live dangerously*/}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </>
  )
}

function UpcomingEvents() {
  const {
    data: events = [],
    included,
    meta: { total_count: eventsCount },
  } = useUpcomingEvents()
  const getEventUrl = (event) => event.attributes.church_center_web_url

  return (
    <EventsList events={events} included={included} getEventUrl={getEventUrl}>
      <EventsList.Content>
        <EventsList.Header>Upcoming events</EventsList.Header>
        <EventsList.List />
      </EventsList.Content>
      {eventsCount > 3 && <ViewAll filter="upcoming" />}
    </EventsList>
  )
}

function PastEvents() {
  const {
    data: events = [],
    included,
    meta: { total_count: eventsCount },
  } = usePastEvents()
  const getEventUrl = (event) => event.attributes.church_center_web_url

  return (
    <EventsList events={events} included={included} getEventUrl={getEventUrl}>
      <EventsList.Content>
        <EventsList.Header>Past events</EventsList.Header>
        <EventsList.List />
      </EventsList.Content>
      {eventsCount > 3 && <ViewAll filter="past" />}
    </EventsList>
  )
}

function EventsWrapper({ children }) {
  return (
    <div className="mb-3" style={{ minHeight: "200px" }}>
      {children}
    </div>
  )
}
EventsWrapper.propTypes = { children: node }

function ViewAll({ filter }) {
  const { groupSlug } = useParams()

  return (
    <div className="mt-2 mb-1">
      <Link
        to={`/groups/groups/${groupSlug}/events/${filter}`}
        className="minor-btn secondary-btn btn"
      >
        View All
      </Link>
    </div>
  )
}
ViewAll.propTypes = {
  filter: oneOf(["upcoming", "past"]).isRequired,
}
