import { string } from "prop-types"
import { Icon } from "source/shared/components"
import { Link } from "react-router-dom"

const Error404 = ({
  message = null,
  linkText = null,
  linkTo = "/registrations/signups",
}) => {
  return (
    <div className="ta-c c-tint2 p-4">
      <div style={{ fontSize: 32 }}>
        <Icon symbol="churchCenter#registrations-o" aria-hidden />
      </div>
      <p className="mb-2">
        {message || "This signup is currently unavailable"}
      </p>
      {linkTo && (
        <Link to={linkTo} className="mr-1 minor-btn secondary-btn btn">
          {linkText || "View all signups"}
        </Link>
      )}
    </div>
  )
}

Error404.propTypes = {
  message: string,
  linkText: string,
  linkTo: string,
}

export default Error404
