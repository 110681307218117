import { useEffect } from "react"
import {
  useQueryClient,
  useQuery,
  useMutation,
  useInfiniteQuery,
  useSuspenseQuery,
  useSuspenseInfiniteQuery,
} from "@tanstack/react-query"
import { api } from "source/registrations/api/client"

export {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  useSuspenseQuery,
  useSuspenseInfiniteQuery,
}

/**
 * Sets up a simple hook to prefetch a query, this uses the same pattern
 * as the useQuery hook, but does not return any data.
 * @param {Object} queryParams - The react-query settings Object.
 */
export const usePrefetchQuery = (queryParams) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.prefetchQuery(queryParams)
  }, [queryClient, queryParams])
}

/**
 * The default query function for react-query, this is a simple wrapper around
 * the api client.  ReactQuery will automatically handle caching and retries.
 * @param {Object} queryParams - The react-query settings object.
 * @returns {Object} - The query response object.
 */
export const queryFn = async ({ queryKey, includeMeta = false }) => {
  const [path, options = {}] = queryKey
  const response = await api.get(path, options)

  const data = includeMeta ? response : response.data
  return response.errors ? Promise.reject(response.errors[0]) : data
}

/**
 * A simple react-query helper that wraps our api client and "walks" our
 * paginated links to return an array of all paginated results.
 * @param {Object} queryParams - The react-query settings object.
 * @returns {Object|Array} - A combined list of the query response objects.
 */
export const queryFnAll = async ({ queryKey, includeMeta = false }) => {
  const [path, options = {}] = queryKey

  let nextLink = path
  let collection = []
  let meta = {}
  let error = null

  while (nextLink) {
    let response = await api.get(nextLink, { per_page: 100, ...options })

    if (response.errors) {
      error = response.errors[0]
      break
    }

    collection = [...collection, ...response.data]
    meta = response.meta
    nextLink = response.links?.next
  }

  const data = includeMeta ? { data: collection, meta } : collection
  return error ? Promise.reject(error) : data
}

/**
 * A simple react-query helper that wraps our api client. It is designed as a
 * variation of queryFn to be used with the useInfiniteQuery hook.
 * @param {Object} queryParams - The react-query settings object.
 * @returns {Object|Array} - A combined list of the query response objects.
 */
export const queryFnInfinite = async ({ queryKey, pageParam: offset = 0 }) => {
  const [path, options = {}] = queryKey

  const response = await api.get(path, { ...options, offset })
  return response.errors ? Promise.reject(response.errors[0]) : response
}

/**
 * Splits the query params from the react-query settings object.  This is
 * useful for passing the query params to the api client and the query options
 * to react-query.
 * @param {Object} options - The react-query params / settings object.
 * @returns {Object} - An object containing separated query params and query settings.
 */
export const splitQueryParams = (options) => {
  const paramKeys = [
    "filter",
    "include",
    "fields",
    "order",
    "per_page",
    "offset",
    "where",
  ]

  let params = {}
  let queryOptions = {}

  Object.entries(options).forEach(([key, value]) => {
    if (paramKeys.includes(key)) {
      params[key] = value
    } else {
      queryOptions[key] = value
    }
  })

  return { params, queryOptions }
}
