import { useContext, useState } from "react"
import { func, object } from "prop-types"
import { Link, Navigate, useParams } from "react-router-dom"
import { Heading } from "@planningcenter/doxy-web"
import { Helmet } from "react-helmet-async"
import { usePersonFinder } from "source/directory/people/usePersonFinder"
import { NarrowLayout } from "source/Layout"
import {
  Icon,
  Avatar,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { HouseholdAvatar } from "source/directory/HouseholdAvatar"
import {
  EmailFields,
  AddressFields,
  PhoneNumberFields,
  PersonalInfoFields,
} from "source/directory/people/Fields"
import { getRelationship } from "source/shared/getRelationship"
import { css } from "@emotion/react"
import { colors } from "source/shared/colors"
import { Error404 } from "source/shared/components/Error404"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { LAST_VIEW_STORAGE_KEY } from "source/directory/index"
import { WebBootContext } from "source/publishing/WebBoot"
import { ContactDirectoryMemberModal } from "./ContactDirectoryMemberModal"
import { Modal } from "source/shared/components/Modal"

export function PeopleShow() {
  const { id } = useParams()
  const person = usePersonFinder(id)
  const [successfullySentMessage, setSuccessfullySentMessage] = useState("")
  const {
    abilities: {
      people: {
        directory_privacy_mode_enabled: organizationDirectoryPrivacyModeEnabled,
      },
    },
  } = useContext(WebBootContext)
  const [directoryLastView, _setDirectoryLastView] = useLocalStorage(
    LAST_VIEW_STORAGE_KEY,
    "people",
  )
  if (person.errors) {
    if (person.errors.some((error) => error.status === "404")) {
      return <Error404 />
    }

    return <Navigate to="/directory/people" replace /> // We'll go back to directory if person won't load
  }
  const canEditPerson = person.meta.can_edit
  const individualAvatar = css`
    width: 152px;
    height: 152px;

    @media (max-width: 720px) {
      width: 80px;
      height: 80px;
    }
  `
  const {
    data: {
      attributes: {
        directory_privacy_mode_enabled: individualDirectoryPrivacyModeEnabled,
        directory_private_contact_mode: directoryPrivateContactMode,
      },
    },
  } = person

  const directoryPrivacyEnabled =
    organizationDirectoryPrivacyModeEnabled ||
    individualDirectoryPrivacyModeEnabled

  const contactAllowed = directoryPrivateContactMode === "enabled"

  const showDirectoryContactButton = directoryPrivacyEnabled && contactAllowed

  return (
    <NarrowLayout>
      <Helmet title={person.data.attributes.name} />
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/directory/${directoryLastView}`}
          pageText="Directory"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText={person.data.attributes.name} isActive />
        {canEditPerson && (
          <div className="d-n d-b@sm ml-a">
            <Link
              className="ml-a btn secondary-btn minor-btn"
              to="/directory/edit"
            >
              Edit shared details
            </Link>
          </div>
        )}
      </BreadcrumbNavigation>

      {canEditPerson && (
        <div className="d-f jc-fe mb-1 d-n@sm">
          <Link
            className="ml-a btn secondary-btn minor-btn"
            to="/directory/edit"
          >
            Edit shared details
          </Link>
        </div>
      )}

      {successfullySentMessage && (
        <p role="status" className="alert success-alert br-8p fs-3 mb-4">
          {successfullySentMessage}
        </p>
      )}

      <div className="d-f fd-c fd-r@md g-1 g-4@md">
        <div className="d-f fd-c ai-c">
          <div className="avatar mt-2 mt-0@md" css={individualAvatar}>
            <Avatar
              url={person.data.attributes.avatar_url}
              alt={`profile image for ${name}`}
            />
          </div>
        </div>

        <div className="d-f fd-c mb-4">
          <div className="ta-c ta-l@md">
            <Heading level={1} text={person.data.attributes.name} />
          </div>
          {!directoryPrivacyEnabled && (
            <>
              <EmailFields emails={getRelationship(person, "emails")} />
              <PhoneNumberFields
                phoneNumbers={getRelationship(person, "phone_numbers")}
              />
            </>
          )}
          <AddressFields addresses={getRelationship(person, "addresses")} />
          <PersonalInfoFields
            birthday={person.data.attributes.birthday}
            anniversary={person.data.attributes.anniversary}
          />
          {showDirectoryContactButton && (
            <div
              className="mt-2"
              onClick={() => setSuccessfullySentMessage("")}
              aria-hidden={true}
            >
              <ContactDirectoryMember
                recipient={person}
                onSuccess={() => {
                  setSuccessfullySentMessage(
                    "Your message was successfully sent. Any replies will be sent to your primary email address.",
                  )
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="d-f fd-c ai-c jc-c my-1">
        {getRelationship(person, "directory_households").map((household) => {
          const directoryProfileLink = css`
            &:hover {
              background-color: ${colors.tint8};
            }
          `
          return (
            <Link
              key={household.id}
              to={`/directory/households/${household.id}`}
              className="p-2 mb-1 d-f g-2@sm fd-c fd-r@sm ai-c jc-sb w-100% action-drawer"
              css={directoryProfileLink}
            >
              <div
                className="w-8 h-8"
                style={{
                  borderRadius: "32px",
                  overflow: "hidden",
                }}
              >
                <HouseholdAvatar
                  url={household.attributes.avatar_url}
                  alt={household.attributes.name}
                  size="thumbnail"
                />
              </div>
              <div className="d-f fd-c f-1">
                <div className="d-f ai-c jc-sb mb-2p f-1">
                  <div className="mt-1 mt-0@sm ta-c ta-l@sm w-100% w-a@sm">
                    <Heading
                      level={3}
                      color="tint1"
                      text={household.attributes.name}
                    />
                  </div>
                </div>

                <div className="c-tint2 fs-4 ta-c ta-l@sm pr-2@sm">
                  {getRelationship(
                    { data: household, included: person.included },
                    "directory_people",
                  )
                    .map(
                      (household_member) =>
                        household_member.attributes.first_name,
                    )
                    .join(", ")}
                </div>
              </div>
              <span className="ml-1 c-tint3 d-n d-f@sm f-0">
                <Icon symbol="general#right-chevron" aria-hidden />
              </span>
            </Link>
          )
        })}
      </div>
    </NarrowLayout>
  )
}
ContactDirectoryMember.propTypes = {
  recipient: object,
  onSuccess: func,
}
function ContactDirectoryMember({ recipient, onSuccess }) {
  return (
    <Modal>
      <Modal.Trigger
        className="btn md-btn outline-btn"
        dataAttributes={{ "data-pendo": "privacy-mode-contact-me" }}
      >
        Contact {recipient.data.attributes.first_name}
      </Modal.Trigger>
      <ContactDirectoryMemberModal
        recipient={recipient}
        onSuccess={onSuccess}
      />
    </Modal>
  )
}
