import { Suspense, useContext } from "react"
import { Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { CurrentGroupContext } from "source/groups/my/groups"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import TabView from "source/groups/my/groups/TabView"
import { Loading, Avatar, Icon } from "source/shared/components"
import { Date } from "source/shared/DatetimeFmt"
import BlankState from "source/groups/BlankState"
import { Heading } from "@planningcenter/doxy-web"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

export function pendingGroupApplicationsUrl(group_id) {
  return `/groups/v2/me/groups/${group_id}/applications?filter=pending&include=person`
}

export default function ApplicationsIndex() {
  return (
    <TabView>
      <TabViewHeadingRow text="Pending requests" />
      <Suspense fallback={<Loading />}>
        <PendingGroupApplicationsList />
      </Suspense>
    </TabView>
  )
}

function PendingGroupApplicationsList() {
  const group = useContext(CurrentGroupContext)
  const { records, included, hasMore, loadMore } = useInfiniteLoadingResource(
    pendingGroupApplicationsUrl(group.id),
  )

  const applications = records
    .map((application) => {
      const relationship = application.relationships.person
      const person = included.find(
        ({ type, id }) =>
          relationship?.data?.type === type && relationship?.data?.id === id,
      )
      return { ...application, person }
    })
    .filter((application) => application.person)

  if (applications.length === 0) {
    return (
      <BlankState icon="churchCenter#people">
        There are no pending membership requests.
      </BlankState>
    )
  }

  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<Loading key="loading" />}
    >
      {applications.map((application) => {
        const { avatar_url, name } = application.person.attributes
        const { applied_at } = application.attributes

        return (
          <Link key={application.id} to={application.id}>
            <article className="d-f fd-c fd-r@xs ai-c mb-2 action-drawer">
              <div className="w-8 h-8 mr-0 mr-2@xs mb-1 mb-0@xs">
                <Avatar url={avatar_url} />
              </div>
              <div className="f-1 fd-c ai-fs ta-c ta-l@xs">
                <div className="d-f jc-sb ai-c fd-c fd-r@xs mb-4p mb-0@xs">
                  <Heading level={3} size={4} text={name} />
                </div>

                <p className="fs-4 lh-1.5 c-tint2 d-b w-fc@xs mb-0">
                  Received <Date start={applied_at} />
                </p>
              </div>
              <div className="d-n d-b@xs">
                <Icon symbol="general#right-chevron" className="fs-4 c-tint3" />
              </div>
            </article>
          </Link>
        )
      })}
    </InfiniteScroll>
  )
}
