import { api } from "source/registrations/api/client"
import {
  useQuery,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
  splitQueryParams,
  queryFn,
} from "source/registrations/utils/queryCache"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"

export const RESERVATION_INCLUDES = [
  "add_on_selections",
  "attendee_people.emergency_contact",
  "attendee_type_selections",
  "attendees",
  "attendees.answers",
  "answers",
  "discount_selections",
  "jolt_channel",
  "payment_intent",
  "people",
  "responsible_person",
]

export const RESERVATION_FIELDS = {
  AddOnSelection: [
    "add_on_variation",
    "add_on",
    "add_onable_type",
    "attendee",
    "cost_each_cents",
    "cost_each_string",
    "cost_total_string",
    "quantity",
  ],
  Answer: ["answers", "question"],
  Attendee: [
    "answers",
    "attendee_type",
    "base_cost_cents",
    "base_cost_string",
    "effective_discount_selections",
    "person",
    "total_cost_cents",
    "total_cost_minus_discounts_cents",
    "total_cost_minus_discounts_string",
    "total_cost_string",
    "waitlist",
  ],
  AttendeePerson: ["attendee", "emergency_contact", "person"],
  AttendeeTypeSelection: [
    "attendee_type",
    "available_count",
    "count",
    "is_waitlist_only",
    "name",
    "position",
    "price_cents",
    "price",
    "signup_time",
    "status",
    "waitlist_available",
    "waitlist",
  ],
  DiscountSelection: ["kind"],
  EmergencyContact: ["name", "phone_number"],
  PaymentIntent: ["amount", "client_secret"],
  Person: [
    "address",
    "avatar_url",
    "birthdate",
    "child",
    "email_address",
    "emergency_contact",
    "existing_registration_url",
    "first_name",
    "gender",
    "grade",
    "last_name",
    "medical_notes",
    "name",
    "phone_number",
  ],
  Reservation: [
    "add_on_selections",
    "additional_info_optional",
    "answers",
    "attendee_people",
    "attendee_type_selections",
    "attendees",
    "completable",
    "discount_selections",
    "event",
    "expired",
    "expires_at",
    "guest_session_param",
    "jolt_channel",
    "minimum_due_string",
    "minimum_due",
    "payment_intent",
    "people",
    "registration_id",
    "registration",
    "responsible_person",
    "total_cost_string",
    "total_cost",
    "total_discount_string",
    "total_discount",
    "total_due_string",
    "total_due",
    "use_stripe_webhooks",
  ],
  JoltChannel: ["id"],
}

const setQueryKey = (eventId, reservationUuid, options = {}) => {
  const { params, queryOptions } = splitQueryParams(options)

  const queryKey = [
    `/registrations/v2/events/${eventId}/reservations/${reservationUuid}`,
    {
      fields: RESERVATION_FIELDS,
      include: RESERVATION_INCLUDES,
      ...params,
    },
  ]

  return { queryKey, queryOptions }
}

export const useReservation = (eventId, reservationUuid, options = {}) => {
  const { queryKey, queryOptions } = setQueryKey(
    eventId,
    reservationUuid,
    options,
  )

  return useSuspenseQuery({
    queryFn,
    queryKey,
    refetchOnWindowFocus: false,
    ...queryOptions,
  })
}

const mutationFn = async (params, reservation) => {
  const { queryKey } = setQueryKey(reservation.eventId, reservation.id)

  const { errors, data } = await api.patch(queryKey[0], {
    ...params,
    include: RESERVATION_INCLUDES,
    fields: {
      ...RESERVATION_FIELDS,
      PaymentIntent: reservation?.paymentIntent?.clientSecret
        ? "amount"
        : RESERVATION_FIELDS.PaymentIntent,
    },
  })

  return errors ? Promise.reject(errors[0]) : data
}

export const useReservationMutation = (eventId, reservationUuid) => {
  const { queryKey } = setQueryKey(eventId, reservationUuid)
  const queryClient = useQueryClient()
  const existingReservation = useReservation(eventId, reservationUuid)

  return useMutation({
    mutationFn: (params) => mutationFn(params, existingReservation?.data),
    onMutate: (_params) => existingReservation?.data,
    onSuccess: (data, _params, _context) => {
      queryClient.setQueryData(queryKey, (old) => ({
        ...old,
        ...data,
        paymentIntent: {
          ...old.paymentIntent,
          ...data.paymentIntent,
        },
      }))
    },
    onError: (error, _params, _context) => {
      if (error?.code === "expired_reservation" || error?.status === "404") {
        queryClient.setQueryData(queryKey, (old) => ({ ...old, expired: true }))
      }
    },
  })
}

export const useCreateReservation = (eventId, params) => {
  const detailedWaitlistNotifyEnabled = useOrganizationFlag(
    "detailedWaitlistNotify",
  )
  const path = `/registrations/v2/events/${eventId}/reservations`
  const data = { attributes: {} }
  if (detailedWaitlistNotifyEnabled && params.waitlist) {
    data.attributes.waitlist_reservation_allowed_uuid = params.waitlist
  }
  const queryKey = [`${path}/new`, data]

  const createReservationFn = async () => {
    const response = await api.post(path, {
      type: "Reservation",
      data,
    })
    return response.errors ? Promise.reject(response.errors[0]) : response.data
  }

  return useQuery({
    queryFn: createReservationFn,
    queryKey,
    refetchOnWindowFocus: false,
    cacheTime: 60 * 1000,
    retry: false,
  })
}
