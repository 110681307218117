import {
  useApiRead,
  useApiReadEntireCollection,
} from "source/shared/SessionApiResource"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"

const GROUP_TYPES_URL = "/groups/v2/group_types?order=position"

export function useAllGroupTypes() {
  const responses = useApiReadEntireCollection(GROUP_TYPES_URL)

  return responses.reduce((acc, { data }) => {
    return [...acc, ...data]
  }, [])
}

export function useInfiniteLoadingGroupTypes() {
  return useInfiniteLoadingResource(GROUP_TYPES_URL)
}

export function useFirstPageOfGroupTypes() {
  return useApiRead(GROUP_TYPES_URL)
}
