import { useContext, useEffect, useRef, useState } from "react"
import { RepliesDataContext } from "./RepliesDataContext"
import { elementType, func, number, shape, string } from "prop-types"
import useScrollInfo from "react-element-scroll-hook"
import useQueryString from "source/shared/hooks/useQueryString"
import LoadMore from "./LoadMore"
import { Heading } from "@planningcenter/doxy-web"

Replies.propTypes = {
  RepliesChunk: elementType.isRequired,
  leaderLabel: string,
  repliesFilter: shape({ func }),
}
export default function Replies({
  RepliesChunk,
  leaderLabel = "Leader",
  repliesFilter = { func: (_reply) => true },
}) {
  const [params] = useQueryString()
  const initiallyAtBottom = params.scroll === "top" ? false : true
  const [atBottom, setAtBottom] = useState(initiallyAtBottom)
  const {
    replies,
    authors,
    leaderIds,
    totalCount,
    injected: { group, topic },
  } = useContext(RepliesDataContext)
  const scrollIntoViewRef = useRef(null)
  const [scrollInfo, scrollInfoRef] = useScrollInfo()
  const { human_authored_replies, replies_enabled } = topic.attributes
  const repliesCount = totalCount || human_authored_replies.count

  useEffect(() => {
    if (atBottom) scrollIntoViewRef.current.scrollIntoView()
  }, [replies.length])

  useEffect(() => {
    if (scrollInfo?.y?.total && scrollInfo?.y?.value) {
      const newAtBottom = scrollInfo.y.total - scrollInfo.y.value < 100
      if (newAtBottom != atBottom) {
        setAtBottom(newAtBottom)
      }
    }
  }, [atBottom, scrollInfo?.y?.total, scrollInfo?.y?.value])

  return (
    <div className="messaging__reply-scroller" ref={scrollInfoRef}>
      <div className="messaging__reply-wrapper">
        <div>
          <LoadMore groupId={group.id} topicId={topic.id} />
          {replies_enabled ? (
            <ReplyCount>{repliesCount}</ReplyCount>
          ) : (
            <Heading level={3} text="Replies are turned off" />
          )}
        </div>
        {replies
          .filter(repliesFilter.func)
          .reduce(chunkByAuthor, [])
          .map((chunk) => {
            const author_id = authorId(chunk[0])
            const author = authors[author_id]
            const isLeader = leaderIds.includes(author.id)
            return (
              <RepliesChunk
                key={chunk[0].id}
                chunk={chunk}
                author={authors[author_id]}
                leaderIds={leaderIds}
                leaderLabel={isLeader ? leaderLabel : null}
              />
            )
          })}
      </div>
      <div ref={scrollIntoViewRef} />
    </div>
  )
}

function ReplyCount({ children: count }) {
  switch (count) {
    case 0:
      return <Heading level={3} text="No replies" />
    case 1:
      return <Heading level={3} text="1 reply" />
    default:
      return <Heading level={3} text={`${count} replies`} />
  }
}

ReplyCount.propTypes = {
  children: number.isRequired,
}

function chunkByAuthor(acc, reply, idx, src) {
  const prevReply = src[idx - 1]
  if (!prevReply) return [...acc, [reply]]

  const sameAuthor = authorId(reply) === authorId(prevReply)
  if (!sameAuthor) return [...acc, [reply]]

  const accInit = acc.slice(0, acc.length - 1)
  const accLast = acc[acc.length - 1]
  return [...accInit, [...accLast, reply]]
}

function authorId(obj) {
  return obj.relationships.author.data.id
}
