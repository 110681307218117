import { useApiRead } from "source/shared/SessionApiResource"

const MANAGEABLE_VALUES = ["granted", "implicit"]
const HOUSEHOLD_MEMBER_FIELDS = ["schedule_managee_permission"]

export function useManageableHouseholdMemberIds({ personId, isOwner }) {
  if (!isOwner) return []

  return useApiRead(
    `/services/v2/people/${personId}/household_members?fields[HouseholdMember]=${HOUSEHOLD_MEMBER_FIELDS}`,
  )
    .data.filter(
      (member) =>
        member.id !== personId &&
        MANAGEABLE_VALUES.includes(
          member.attributes.schedule_managee_permission,
        ),
    )
    .map((member) => member.id)
}
