import { useState, createContext } from "react"
import { node, object, shape } from "prop-types"

export const ReservationContext = createContext(null)

export const ReservationContextProvider = ({ children }) => {
  const [emergencyContacts, setEmergencyContacts] = useState([])
  const [stripe, setStripe] = useState(null)
  const [paymentIntent, setPaymentIntent] = useState({
    data: null,
    error: null,
    loading: true,
    patch: null,
  })

  return (
    <ReservationContext.Provider
      value={{
        stripe,
        setStripe,
        paymentIntent,
        setPaymentIntent,
        emergencyContacts,
        setEmergencyContacts,
      }}
    >
      {children}
    </ReservationContext.Provider>
  )
}

ReservationContextProvider.propTypes = {
  children: node.isRequired,
  initialState: shape({
    event: object.isRequired,
  }),
}
