import { get } from "lodash"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { func, node, number } from "prop-types"

import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { Icon } from "source/shared/components"

const parseQueryParamId = (queryParamName) => {
  const searchParams = new URLSearchParams(window.location.search)
  const value = searchParams.get(queryParamName)

  return value ? parseInt(value.replace(/^AC/, ""), 10) : undefined
}

const replaceUrl = (location, navigate) => {
  navigate(location.pathname, { replace: true })
}

function TimedAlert({ children, onClear = () => {}, showForMilliseconds }) {
  useEffect(() => {
    if (onClear && showForMilliseconds) {
      const timeoutFn = setTimeout(() => onClear(), showForMilliseconds)
      return () => clearTimeout(timeoutFn)
    }
  }, [onClear, showForMilliseconds])

  return children
}

TimedAlert.propTypes = {
  children: node,
  onClear: func,
  showForMilliseconds: number,
}

function TimedErrorAlert({ message, ...props }) {
  return (
    <TimedAlert {...props}>
      <div className="alert error-alert d-f ai-c">
        <span className="mr-1 p-r t-2p c-ruby">
          <Icon symbol="general#exclamation-triangle" />
        </span>
        <span className="ml-1">{message}</span>
      </div>
    </TimedAlert>
  )
}

TimedErrorAlert.propTypes = {
  message: node,
}

function TimedSuccessAlert({ message, ...props }) {
  return (
    <TimedAlert {...props}>
      <div className="alert success-alert d-f ai-c">
        <span className="mr-1 p-r t-2p c-emerald">
          <Icon symbol="general#outlined-circle-check" />
        </span>
        <span className="ml-1">{message}</span>
      </div>
    </TimedAlert>
  )
}

TimedSuccessAlert.propTypes = {
  message: node,
}

export default function RespondsToBlockoutNotification() {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [scheduler, setScheduler] = useState({})

  const location = useLocation()
  const navigate = useNavigate()
  const {
    data: { id: currentPersonId },
  } = useSession(false)

  useEffect(() => {
    const teamMemberId = parseQueryParamId("team_member_id")
    const schedulerId = parseQueryParamId("scheduler_id")

    replaceUrl(location, navigate)

    if (!(teamMemberId && schedulerId)) return
    if (parseInt(currentPersonId, 10) !== teamMemberId) {
      setShowError(true)
      return
    }

    sessionApiClient
      .post(`/services/v2/me/respond_no_blockouts`, {
        data: { attributes: { scheduler_id: schedulerId } },
      })
      .then(({ data }) => {
        setShowSuccess(true)
        setScheduler(get(data, "[0].attributes", {}))
      })
      .catch(() => setShowError(true))
  }, [])

  if (showSuccess) {
    return (
      <TimedSuccessAlert
        onClear={() => setShowSuccess(false)}
        showForMilliseconds={5000}
        message={`Thanks for letting ${
          scheduler.full_name || "your leader"
        } know!`}
      />
    )
  } else if (showError) {
    return (
      <TimedErrorAlert
        onClear={() => setShowError(false)}
        showForMilliseconds={5000}
        message="Something went wrong when attempting to notify your leader. Please try again, or contact support."
      />
    )
  } else {
    return null
  }
}
