import { string, shape, func, bool, number, oneOfType } from "prop-types"
import Field from "./Field"
import { Select } from "source/shared/components"
import { usePeopleGenderOptions } from "source/registrations/hooks/usePeopleGenders"

const GenderField = ({
  id,
  person,
  existingPerson,
  onChange,
  onCancel,
  isRequired,
  isOptional,
  error,
}) => {
  const genderOptions = usePeopleGenderOptions()

  const { gender } = person

  return (
    <Field
      label="Gender"
      value={gender}
      persistedValue={existingPerson.gender}
      onCancel={() => onCancel("gender")}
      isRequired={isRequired}
      isOptional={isOptional}
      error={error}
    >
      <Select
        name={`gender-${id}`}
        onChange={({ target: { value } }) => onChange("gender", value)}
        style={{ height: "32px" }}
        readOnly={!!existingPerson.gender}
        disabled={genderOptions.isLoading}
        options={genderOptions.data || []}
        value={gender || ""}
      />
    </Field>
  )
}

GenderField.propTypes = {
  id: oneOfType([string, number]),
  person: shape({
    gender: string,
  }),
  existingPerson: shape({
    gender: string,
  }),
  onChange: func,
  onCancel: func,
  isRequired: bool,
  isOptional: bool,
  error: string,
}

export default GenderField
