import { css } from "@emotion/react"
import { node } from "source/shared/prop_types"

export function AuthorName({ children, badge }) {
  return (
    <h4>
      <span className="fs-5">
        {children ? (
          <>
            <span css={styles.author}>{children}</span>
            {badge && (
              <span
                className="ml-4p xs-badge badge fw-400"
                css={styles.authorBadge}
              >
                {badge}
              </span>
            )}
          </>
        ) : (
          "\u00A0"
        )}
      </span>
    </h4>
  )
}

AuthorName.propTypes = {
  children: node,
  badge: node,
}

const styles = {
  author: css`
    color: var(--color-tint2);
  `,
  authorBadge: css`
    background-color: var(--color-tint2);
    color: var(--color-tint10);
  `,
}
