import { Fragment } from "react"
import { arrayOf, object, bool, shape, string } from "prop-types"
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"
import { useSession } from "source/shared/hooks/useSession"
import {
  Icon,
  BreadcrumbNavigationWithMe,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { Date as DateFmt } from "source/shared/DatetimeFmt"
import { EditContactAndInfo } from "./EditContactAndInfo"
import { ProfileAvatar } from "./ProfileAvatar"
import { Heading } from "@planningcenter/doxy-web"

import { useAllSchools } from "./hooks/useAllSchools"

const ME_PATH =
  "/people/v2/me?include=emails,phone_numbers,addresses.country,marital_status,school&fields[Country]=address_fields,name"

const MARITAL_STATUS_OPTIONS_PATH = "/people/v2/marital_statuses"

export function ContactAndInfo() {
  const navigate = useNavigate()
  // TODO useNarrowLayout()
  const {
    data: { id: currentPersonId },
  } = useSession(true)
  const profile = useApiRead(ME_PATH)
  const maritalStatusOptions = useApiRead(MARITAL_STATUS_OPTIONS_PATH)
  const schoolOptions = useAllSchools()

  const reloadProfile = useCacheHydrate(SessionApiResource, ME_PATH)
  const name = profile.data.attributes.name
  const avatar =
    profile.data.attributes.avatar_url ||
    profile.data.attributes.demographic_avatar_url
  const includedRecords = profile.included

  const canEditContactInfo =
    profile.data.attributes.contact_info_editable_by_household ||
    currentPersonId === profile.data.id

  const personalAttributesRecords = [
    "Anniversary",
    "Birthdate",
    "Gender",
    "Grade",
    "Graduation_Year",
    "Medical_Notes",
  ].map((recordType) => {
    return {
      id: recordType,
      attributes: {
        [recordType.toLowerCase()]:
          profile.data.attributes[recordType.toLowerCase()],
      },
      type: recordType,
      links: { self: profile.data.links.self },
    }
  })

  const addressCountries = profile.included.filter(
    (record) => record.type === "Country",
  )

  // NOTE: We need a default state for MaritalStatus in the event we don't have one
  // in the `included` fields.
  if (includedRecords.every((record) => record.type !== "MaritalStatus")) {
    const defaultMaritalStatus = {
      type: "MaritalStatus",
      id: "",
      attributes: {
        marital_status_id: "",
      },
      links: {
        self: "",
      },
    }

    includedRecords.push(defaultMaritalStatus)
  }

  if (includedRecords.every((record) => record.type !== "SchoolOption")) {
    const defaultSchool = {
      type: "SchoolOption",
      id: "",
      attributes: {
        school_id: "",
      },
      links: {
        self: "",
      },
    }

    includedRecords.push(defaultSchool)
  }

  function mapRecordsFieldsForPatchingToPersonRelationship(records) {
    return records.map((record) => {
      switch (record.type) {
        case "MaritalStatus":
          record.links.self = profile.data.links.self
          record.attributes.marital_status_id = record.id
          record.deleteValueAttributeForPatch = true
          break
        case "SchoolOption":
          record.links.self = profile.data.links.self
          record.attributes.school_id = record.id
          record.deleteValueAttributeForPatch = true
          break
      }

      return record
    })
  }

  const records = personalAttributesRecords.concat(
    mapRecordsFieldsForPatchingToPersonRelationship(includedRecords),
  )

  function allowlist(pathname) {
    const allowed = [
      "/giving",
      "/groups",
      "/registrations",
      "/profile",
      "/me/profile",
    ]
    if (allowed.some((a) => pathname.startsWith(a))) return pathname
    else return "/me/profile"
  }
  const params = new URLSearchParams(window.location.search)
  let returnPath = params.has("return")
    ? allowlist(params.get("return"))
    : "/me/profile/contact-and-info"

  function onDiscard() {
    navigate(returnPath)
  }

  function onSave() {
    reloadProfile()
    navigate(returnPath)
  }

  return (
    <Fragment>
      <BreadcrumbNavigationWithMe>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Contact &amp; profile information" isActive />
      </BreadcrumbNavigationWithMe>
      <div className="d-f ai-c mb-2">
        <ProfileAvatar
          avatar={avatar}
          name={name}
          personPath={ME_PATH}
          reloadProfile={reloadProfile}
        />
        <Heading level={1} size={2} text={name} />
      </div>
      <Routes>
        <Route
          path="edit"
          element={
            <EditContactAndInfo
              canEditContactInfo={canEditContactInfo}
              editableOptions={{
                maritalStatusOptions: maritalStatusOptions.data,
                schoolOptions: schoolOptions,
              }}
              isChild={profile.data.attributes.child}
              onDiscard={onDiscard}
              onSave={onSave}
              personId={profile.data.id}
              records={records}
              addressCountries={addressCountries}
            />
          }
        />
        <Route
          path="/"
          element={
            <DisplayContactAndInfo
              records={records}
              isChild={profile.data.attributes.child}
            />
          }
        />
        <Route path="*" element={<Navigate to="/me/profile" replace />} />
      </Routes>
    </Fragment>
  )
}

const apiDataPropType = shape({
  attributes: object.isRequired,
  id: string.isRequired,
  type: string.isRequired,
})

DisplayContactAndInfo.propTypes = {
  isChild: bool.isRequired,
  records: arrayOf(apiDataPropType).isRequired,
}

function typeFilter(type) {
  return (record) => record.type === type
}

function DisplayContactAndInfo({ isChild, records }) {
  const emails = records.filter(typeFilter("Email"))
  const phoneNumbers = records.filter(typeFilter("PhoneNumber"))
  const addresses = records.filter(typeFilter("Address"))

  const anniversary = records.find(typeFilter("Anniversary"))?.attributes
    ?.anniversary
  const formattedAnniversary = anniversary
    ? DateFmt({ start: anniversary, year: !anniversary.includes("1885-") })
    : null

  const birthdate = records.find(typeFilter("Birthdate"))

  const medicalNote =
    records.find(typeFilter("Medical_Notes"))?.attributes?.medical_notes ||
    "No notes"
  const school =
    records.find(typeFilter("SchoolOption"))?.attributes?.value || ""

  const maritalStatus =
    records.find(typeFilter("MaritalStatus"))?.attributes?.value || "No status"

  const gender = records.find(typeFilter("Gender"))?.attributes?.gender || ""

  const grade = records.find(typeFilter("Grade")).attributes.grade
  const graduationYear = records.find(typeFilter("Graduation_Year")).attributes
    .graduation_year

  let formattedGrade = null
  switch (true) {
    case grade === 0:
      formattedGrade = "Kindergarten"
      break
    case grade === -1:
      formattedGrade = "Pre-Kindergarten"
      break
    case grade === 1:
      formattedGrade = "1st"
      break
    case grade === 2:
      formattedGrade = "2nd"
      break
    case grade === 3:
      formattedGrade = "3rd"
      break
    case grade >= 4 && grade <= 12:
      formattedGrade = `${grade}th`
      break
    default:
      formattedGrade = ""
  }

  function GradeAndSchool() {
    if (isChild || school || grade !== null) {
      return (
        <div className="d-f@sm f_1 mb-1">
          <Grade />
          <School />
        </div>
      )
    } else return null
  }

  function Grade() {
    return (
      <div>
        <dt className="c-tint2 fs-4">
          {grade === null && graduationYear ? <>Graduation Year</> : <>Grade</>}
          <Icon symbol="general#lock" className="fs-2 pl-1" />
        </dt>
        <dd className="mb-1">
          {grade === null && graduationYear ? graduationYear : formattedGrade}
        </dd>
      </div>
    )
  }

  function School() {
    return (
      <div>
        <dt className="c-tint2 fs-4">School</dt>
        <dd>{school}</dd>
      </div>
    )
  }

  return (
    <>
      <div className="mb-4">
        <div className="d-f ai-c jc-sb mb-2">
          <Heading level={2} size={3} text="Contact information" />
          <Link
            to="edit"
            className="minor-btn secondary-btn btn"
            style={{ zIndex: 0 }}
          >
            Edit
          </Link>
        </div>
        {emails.length > 0 && (
          <div className="action-drawer mb-1">
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-1">
                <Icon symbol="general#outlined-envelope" />
              </span>
              <Heading level={3} size={4} text="Email" />
            </div>
            {emails.map((email) => (
              <dl className="d-f ai-c" key={email.id}>
                <div className="pt-1">
                  <div className="c-tint2 fs-4">
                    {email.attributes.location}
                  </div>
                  <div>
                    {email.attributes.address}{" "}
                    {email.attributes.primary && (
                      <span className="badge ml-1">Primary</span>
                    )}
                  </div>
                </div>
              </dl>
            ))}
          </div>
        )}
        {phoneNumbers.length > 0 && (
          <div className="action-drawer mb-1">
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-1">
                <Icon symbol="general#mobile-phone" />
              </span>
              <Heading level={3} size={4} text="Phone" />
            </div>
            {phoneNumbers.map((phoneNumber) => (
              <div className="d-f ai-c" key={phoneNumber.id}>
                <div className="pt-1">
                  <div className="c-tint2 fs-4">
                    {phoneNumber.attributes.location}
                  </div>
                  <div>
                    {phoneNumber.attributes.number}{" "}
                    {phoneNumber.attributes.primary && (
                      <span className="badge ml-1">Primary</span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {addresses.length > 0 && (
          <div className="action-drawer">
            <div className="d-f ai-c">
              <span className="c-tint3 p-r t-1p mr-1">
                <Icon symbol="general#outlined-location-pin" />
              </span>
              <Heading level={3} size={4} text="Address" />
            </div>
            {addresses.map((address) => (
              <div className="d-f ai-c" key={address.id}>
                <div className="pt-1">
                  <div className="c-tint2 fs-4">
                    {address.attributes.location}
                  </div>
                  <div>
                    {address.attributes.street}
                    <br />
                    {address.attributes.city}, {address.attributes.state}{" "}
                    {address.attributes.zip}
                    {address.attributes?.country_name && (
                      <>
                        <br />
                        {address.attributes.country_name}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="d-f ai-c jc-sb mb-2">
        <Heading level={2} size={3} text="Personal information" />
        <Link
          to="edit"
          className="minor-btn secondary-btn btn"
          style={{ zIndex: 0 }}
        >
          Edit
        </Link>
      </div>
      <dl className="action-drawer mb-4">
        <div className="d-f@sm f_1 mb-1">
          <div>
            <dt className="c-tint2 fs-4">Gender</dt>
            <dd className="mb-1">{gender}</dd>
          </div>
          <div>
            <dt className="c-tint2 fs-4">
              Birthdate
              {birthdate.attributes.birthdate && (
                <Icon symbol="general#lock" className="fs-2 pl-1" />
              )}
            </dt>
            <dd>
              {birthdate.attributes.birthdate
                ? DateFmt({
                    start: birthdate.attributes.birthdate,
                    year: true,
                  })
                : "No Birthdate"}
            </dd>
          </div>
        </div>
        <GradeAndSchool />
        {!isChild && (
          <div className="d-f@sm f_1 mb-1">
            <div>
              <dt className="c-tint2 fs-4">Marital status</dt>
              <dd className="mb-1">{maritalStatus}</dd>
            </div>
            {maritalStatus === "Married" && (
              <div>
                <dt className="c-tint2 fs-4">Anniversary</dt>
                <dd>{formattedAnniversary}</dd>
              </div>
            )}
          </div>
        )}
        <dt className="c-tint2 fs-4">Medical notes</dt>
        <dd>{medicalNote}</dd>
      </dl>
    </>
  )
}
