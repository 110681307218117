import PropTypes from "prop-types"
import { Children, cloneElement, Component } from "react"
import _ from "lodash"
const { shape, number, object, instanceOf, node, bool } = PropTypes

const DEFAULT_ZOOM = 15
const latLngProp = shape({ lat: number.isRequired, lng: number.isRequired })

export default class extends Component {
  static propTypes = {
    center: latLngProp.isRequired,
    children: node,
    viewport: instanceOf(google.maps.LatLngBounds),
    options: shape({
      mapTypeControl: bool,
      streetViewControl: bool,
      defaultZoom: number,
    }),
    eventListeners: object,
    style: object,
  }

  static defaultProps = {
    style: {
      width: "100%",
      height: "300px",
    },
    options: {},
    eventListeners: {},
  }

  state = {
    map: null,
  }

  componentDidMount() {
    this.mapEventListeners = []
    this.doImperativeWork()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.doImperativeWork(nextProps)
  }

  // eslint-disable-next-line consistent-return
  doImperativeWork = (props = this.props) => {
    const { map } = this.state
    const { viewport, eventListeners } = props

    if (!map) {
      return this.createMap().then(this.doImperativeWork)
    }

    map.setOptions(this.getMapOptions(props))
    this.showViewport(viewport)
    this.mapEventListeners.map((listener) => listener.remove())
    this.mapEventListeners = _.map(eventListeners, (func, name) =>
      map.addListener(name, func),
    )
  }

  createMap = () => {
    const map = new google.maps.Map(this.refs.map, this.getMapOptions())

    return new Promise((resolve) => this.setState({ map }, resolve))
  }

  showViewport = (viewport) => {
    const { map } = this.state

    if (!viewport) return

    // 1 degree =~ 111km
    // 1 mile =~ 1.6km
    // 1 mile =~ 0.0144144144 degrees
    // 1/2 mile =~ 0.007207207 degrees
    const halfMile = 0.007207207

    const span = viewport.toSpan()
    if (span.lng() > halfMile || span.lat() > halfMile) {
      map.fitBounds(viewport, 0)
    } else {
      map.setCenter(viewport.getCenter())
      map.setZoom(DEFAULT_ZOOM)
    }
  }

  getMapOptions = (props = this.props) => {
    const options = {
      scrollwheel: false,
      draggable: true,
      ...props.options,
      center: props.center,
    }

    if (!options.hasOwnProperty("zoom") && !this.state.map) {
      options.zoom = props.options.defaultZoom || DEFAULT_ZOOM
    }

    return options
  }

  render() {
    return (
      <div>
        <div style={this.props.style} ref="map">
          {this.state.map &&
            Children.map(this.props.children, (child) => {
              return (
                child &&
                cloneElement(child, {
                  ...child.props,
                  map: this.state.map,
                })
              )
            })}
        </div>
      </div>
    )
  }
}
