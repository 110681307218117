type CheckboxProps = {
  checked: boolean
  id: string
  label: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Checkbox({
  checked,
  id,
  label,
  onChange,
  value,
  ...props
}: CheckboxProps) {
  return (
    <div>
      <input
        className="checkbox"
        checked={checked}
        id={id}
        onChange={onChange}
        type="checkbox"
        value={value}
        {...props}
      />
      <label htmlFor={id} className="checkbox-label mb-4p">
        {label}
      </label>
    </div>
  )
}
