import { useRef, useEffect } from "react"

export function useFocus(autoFocus = false) {
  const ref = useRef(null)
  useEffect(() => {
    autoFocus && focus()
  }, [])

  function focus() {
    ref.current.focus()
  }

  return [ref, focus]
}
