import { object, func, bool, string } from "prop-types"
import { questionShape } from "source/registrations/propTypes"
import QuestionHeader from "./QuestionHeader"
import QuestionInput from "./QuestionInput"
import QuestionDropdown from "./QuestionDropdown"
import QuestionCheckbox from "./QuestionCheckbox"
import QuestionText from "./QuestionText"

const Question = ({
  question,
  onChange,
  answers = {},
  isEditing = false,
  fieldId = null,
  removeContainer = false,
}) => {
  const questionAnswers = answers[question.id]?.answers || []

  const questionFieldId = fieldId || `question_${question.id}`

  const allowMultiple = question.allowMultiple || question.multipleSelect

  if (!isEditing) {
    return (
      <QuestionText
        question={question}
        answers={questionAnswers}
        removeContainer={removeContainer}
      />
    )
  } else if (question.kind === "section_header") {
    return (
      <QuestionHeader question={question} removeContainer={removeContainer} />
    )
  } else if (question.kind === "text" || question.kind === "paragraph") {
    return (
      <QuestionInput
        fieldId={questionFieldId}
        question={question}
        answers={questionAnswers}
        onChange={onChange}
        removeContainer={removeContainer}
      />
    )
  } else if (
    (question.kind === "dropdown" || question.kind === "yesno") &&
    !allowMultiple
  ) {
    return (
      <QuestionDropdown
        fieldId={questionFieldId}
        question={question}
        answers={questionAnswers}
        onChange={onChange}
        removeContainer={removeContainer}
      />
    )
  } else if (
    question.kind === "singlecheckbox" ||
    (question.kind === "dropdown" && allowMultiple)
  ) {
    return (
      <QuestionCheckbox
        fieldId={questionFieldId}
        question={question}
        answers={questionAnswers}
        onChange={onChange}
        removeContainer={removeContainer}
      />
    )
  }
}

Question.propTypes = {
  question: questionShape.isRequired,
  onChange: func.isRequired,
  answers: object,
  isEditing: bool,
  removeContainer: bool,
  fieldId: string,
}

export default Question
