import { useCallback, useEffect, useState } from "react"
import { uniqBy } from "lodash"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useApiRead } from "source/shared/SessionApiResource"

export function useInfiniteLoadingResource(url) {
  const preloadedRecords = useApiRead(url)
  const [records, setRecords] = useState(preloadedRecords.data)
  const [included, setIncluded] = useState(preloadedRecords.included)
  const [links, setLinks] = useState(preloadedRecords.links)
  const [totalCount, setTotalCount] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const hasMore = !!links.next
  useEffect(() => {
    setRecords(preloadedRecords.data)
    setIncluded(preloadedRecords.included)
    setLinks(preloadedRecords.links)
    setTotalCount(preloadedRecords.meta.total_count)
  }, [preloadedRecords])

  const loadMore = useCallback(() => {
    if (loadingMore || !hasMore) return

    setLoadingMore(true)
    return sessionApiClient
      .get(links.next)
      .then((json) => {
        setLinks(json.links)
        setIncluded((included) =>
          uniqBy([...included, ...json.included], (i) => i.type + i.id),
        )
        setRecords([...records, ...json.data])
      })
      .finally(() => {
        setLoadingMore(false)
      })
  }, [loadingMore, links, records, url, hasMore])

  return { records, included, hasMore, loadMore, loadingMore, totalCount }
}
