import { useEffect, useState, useRef } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import {
  Modal,
  useCloseModal,
  useOpenModal,
} from "source/shared/components/Modal"
import {
  useCurrentGroup,
  useReloadMyApplications,
} from "../hooks/useCurrentGroup"
import { MyInformation } from "./MyInformation"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { object, shape } from "prop-types"

export function RequestToJoin() {
  const [_, setRequestToJoinClicked] = useLocalStorage(
    "RequestToJoinClicked",
    false,
  )
  const {
    data: {
      attributes: { name },
    },
  } = useCurrentGroup()

  const inputFocusRef = useRef()

  return (
    <Modal onOpenFocusRef={inputFocusRef}>
      <OpenIfPreviouslyRequestedToJoin />
      <Modal.Trigger>Request to join</Modal.Trigger>
      <Modal.Show
        onOpen={() => setRequestToJoinClicked(true)}
        onClose={() => setRequestToJoinClicked(false)}
      >
        <Modal.Header>Request to join {name}</Modal.Header>
        <Modal.Body>
          <MyInformation />
          <RequestToJoinForm inputFocusRef={inputFocusRef} />
        </Modal.Body>
      </Modal.Show>
    </Modal>
  )
}

function OpenIfPreviouslyRequestedToJoin() {
  const [requestToJoinClicked, _] = useLocalStorage(
    "RequestToJoinClicked",
    false,
  )

  const { data: currentPerson } = useSession()

  const openModal = useOpenModal()

  useEffect(() => {
    if (requestToJoinClicked && currentPerson.id) openModal()
  }, [currentPerson.id, openModal, requestToJoinClicked])
}

function RequestToJoinForm({ inputFocusRef }) {
  const [_, setRequestToJoinClicked] = useLocalStorage(
    "RequestToJoinClicked",
    false,
  )

  useSession(true)
  const closeModal = useCloseModal()
  const reloadMyApplications = useReloadMyApplications()

  const {
    data: {
      links: { enroll: enrollUrl },
    },
  } = useCurrentGroup()

  const [message, setMessage] = useState()

  const saveForm = (e) => {
    e.preventDefault()

    const payload = { data: { attributes: { message } } }
    sessionApiClient
      .post(enrollUrl, payload)
      .then(() => {
        reloadMyApplications()
        closeModal()
        setRequestToJoinClicked(false)
      })
      .catch(() => alert("Uh oh, something went wrong. Please try again."))
  }

  return (
    <form onSubmit={saveForm}>
      <label className="label" htmlFor="message">
        Personal message (optional)
      </label>
      <p className="fs-4 mt-4p c-tint2">
        If you would like to send a message to the leader of the group, you can
        do so here. Otherwise, select &quot;Send request&quot; to continue.
      </p>
      <textarea
        id="message"
        className="w-100%"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        ref={inputFocusRef}
      />
      <Modal.Footer>
        <Modal.Close onClose={() => setRequestToJoinClicked(false)}>
          Cancel
        </Modal.Close>
        <button type="submit" className="btn">
          Send request
        </button>
      </Modal.Footer>
    </form>
  )
}

RequestToJoinForm.propTypes = {
  inputFocusRef: shape({ current: object }),
}
