import { Helmet } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"
import { useSession } from "source/shared/hooks/useSession"
import NotificationList from "./NotificationList"
import NotificationShow from "./NotificationShow"

export function Notifications() {
  useSession(true)

  return (
    <>
      <Routes>
        <Route path="/" element={<NotificationList />} />
        <Route path="/:id" element={<NotificationShow />} />
      </Routes>
      <Helmet title="Notifications" />
    </>
  )
}
