import { Suspense, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { get as dig } from "lodash"
import { Heading } from "@planningcenter/doxy-web"
import { Loading } from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { CurrentGroupContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import { Form } from "./components/Form"

export default function ResourcesEdit() {
  return (
    <TabView>
      <Suspense fallback={<Loading />}>
        <Loaded />
      </Suspense>
    </TabView>
  )
}

function Loaded() {
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const group = useContext(CurrentGroupContext)
  const response = useApiRead(
    `/groups/v2/me/groups/${group.id}/resources/${resourceId}`,
  )

  const canManageResource = dig(
    response.data.attributes,
    "abilities.can_manage",
    false,
  )
  if (!canManageResource) {
    navigate(`${group.base_path}/resources`)
  }

  return (
    <>
      <div className="mt-1 mb-3">
        <Heading level={1} size={3} text="Edit resource" />
      </div>
      <Form existingResource={response.data}>
        <Form.Name />
        <Form.Description />
        <Form.EditResourceType meta={response.meta} />
        <Form.Visibility />
      </Form>
    </>
  )
}
