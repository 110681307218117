import { useEffect } from "react"

function isProhibitedProtocol(href) {
  const prohibited = ["javascript:", "data:"]
  return prohibited.some((protocol) => href.startsWith(protocol))
}

function discardAnchorTagJavascript(event) {
  if (
    event.target.tagName === "A" &&
    isProhibitedProtocol(event.target.href || "")
  )
    event.preventDefault()
}

export function useDiscardAnchorTagJavascript() {
  useEffect(() => {
    window.addEventListener("click", discardAnchorTagJavascript)
    return () => window.removeEventListener("click", discardAnchorTagJavascript)
  }, [])
}
