import { Suspense, useContext, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  ChannelList,
  useChatContext as useStreamChatContext,
} from "stream-chat-react"
import { useApiReadEntireCollection } from "source/shared/SessionApiResource"
import { Icon } from "source/shared/components"
import { CurrentGroupContext } from "source/groups/my/groups"
import { TabViewHeadingRow } from "source/groups/my/groups/TabViewHeadingRow"

export function ConversationsIndex() {
  return (
    <>
      <TabViewHeadingRow text="Conversations" ingoreWrap>
        <Link
          to="new"
          className="minor-btn secondary-btn btn"
          aria-label="New conversation"
        >
          <Icon
            symbol="general#plus"
            className="mr-4p"
            style={{ fontSize: 9 }}
            aria-hidden
          />
          New
        </Link>
      </TabViewHeadingRow>
      <Suspense>
        <ConversationsList />
      </Suspense>
    </>
  )
}

function ConversationsList() {
  const graphId = useGraphId()
  const { client } = useStreamChatContext()
  const conversations = useMyGroupConversations()

  useActiveChannelNavigation(conversations)

  // Updating these filters might require changes in `channelEventHandler`
  const filters = {
    $or: [
      { last_message_at: { $exists: true } },
      { created_by_id: client.userID },
    ],
    members: { $in: [client.userID] },
    pco_groups: { $contains: graphId },
  }

  // If the above `filters` change we may need to update this logic as well.
  const channelEventHandler = async (setChannels, event) => {
    const eventChannel = event.channel
    const isGroupChannel = !!eventChannel?.pco_groups?.includes(graphId)

    if (!eventChannel?.id || !isGroupChannel) return

    // Ensures empty conversations added by a different user are still hidden across event changes.
    const channelHasMessages = Boolean(eventChannel.last_message_at)

    if (!channelHasMessages) return

    try {
      const newChannel = client.channel(eventChannel.type, eventChannel.id)
      await newChannel.watch()
      setChannels((channels) => [newChannel, ...channels])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ChannelList
      filters={filters}
      setActiveChannelOnMount={false}
      onMessageNew={channelEventHandler}
      onAddedToChannel={channelEventHandler}
    />
  )
}

function useGraphId() {
  const group = useContext(CurrentGroupContext)
  const sourceAppName = "Groups"
  const sourceType = "Group"
  const sourceId = group.id
  const graphId = [sourceAppName, sourceType, sourceId].join("-")

  return graphId
}

function useMyGroupConversations() {
  const graphId = useGraphId()

  const responses = useApiReadEntireCollection(
    `/chat/v2/me/conversations?filter=group&group=${graphId}&per_page=100`,
  )

  return useMemo(
    () => responses.reduce((acc, { data }) => [...acc, ...data], []),
    [responses],
  )
}

function useActiveChannelNavigation(conversations) {
  const group = useContext(CurrentGroupContext)
  const navigate = useNavigate()
  const { channel: activeChannel, setActiveChannel } = useStreamChatContext()
  const activeConversation =
    activeChannel &&
    conversations.find(
      (c) => c.relationships.stream_channel.data.id === activeChannel.id,
    )

  useEffect(() => {
    const resetActiveChannelBeforeLeaving = () => setActiveChannel(undefined)
    if (activeConversation)
      navigate(`${group.base_path}/conversations/${activeConversation.id}`)

    return resetActiveChannelBeforeLeaving
  }, [setActiveChannel, activeConversation, navigate, group.base_path])

  return null
}
