import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { Modal, useCloseModal } from "source/shared/components/Modal"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import { useReloadMyMembership } from "../hooks/useMemberships"
import { MyInformation } from "./MyInformation"

export function Join() {
  const {
    data: {
      attributes: { name },
    },
  } = useCurrentGroup()

  return (
    <Modal>
      <Modal.Trigger>Join</Modal.Trigger>
      <Modal.Show>
        <Modal.Header>Join {name}</Modal.Header>
        <Modal.Body>
          <MyInformation />
          <JoinForm />
        </Modal.Body>
      </Modal.Show>
    </Modal>
  )
}

function JoinForm() {
  useSession(true)
  const closeModal = useCloseModal()
  const reloadMyMembership = useReloadMyMembership()

  const {
    data: {
      links: { enroll: enrollUrl },
    },
  } = useCurrentGroup()

  const saveForm = (e) => {
    e.preventDefault()

    sessionApiClient
      .post(enrollUrl, {})
      .then(() => {
        reloadMyMembership()
        closeModal()
      })
      .catch(() => alert("Uh oh, something went wrong. Please try again."))
  }

  return (
    <form onSubmit={saveForm}>
      <Modal.Footer>
        <Modal.Close>Cancel</Modal.Close>
        <button type="submit" className="btn">
          Join group
        </button>
      </Modal.Footer>
    </form>
  )
}
