import { useContext } from "react"
import { Helmet } from "react-helmet-async"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"

export function TitleTemplate() {
  const {
    layout: { organization_name },
  } = useContext(ServerRenderedProps)

  return (
    <Helmet
      titleTemplate={`%s - ${organization_name}`}
      defaultTitle={organization_name}
    />
  )
}
