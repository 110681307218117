import { array, bool, func, string } from "prop-types"
import { questionShape } from "source/registrations/propTypes"
import { isStringBlank } from "source/registrations/utils/helpers"
import { Select } from "source/shared/components"
import classNames from "classnames"

const QuestionDropdown = ({
  fieldId,
  question,
  onChange,
  answers = [],
  removeContainer,
}) => {
  const handleChange = ({ target: { value } }) =>
    onChange(question.id, isStringBlank(value) ? [] : [value])

  const options =
    question.kind === "yesno"
      ? [
          { value: "", label: "Select" },
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ]
      : [
          { value: "", label: "Select" },
          ...question.choices.map((choice) => ({
            value: choice,
            label: choice,
          })),
        ]

  return (
    <div className={classNames("mb-1", { "action-drawer": !removeContainer })}>
      <label
        htmlFor={`${fieldId}_dropdown`}
        className={`${question.description && "pb-0"}`}
      >
        {question.question}
        {(question.required || question.publiclyRequired) && (
          <span className="c-ruby required-symbol"> *</span>
        )}
      </label>
      {question.description && (
        <p className="fs-4 c-tint2 mb-1">{question.description}</p>
      )}
      <Select
        id={`${fieldId}_dropdown`}
        name={`${fieldId}_dropdown`}
        onChange={handleChange}
        value={answers[0] || ""}
        options={options}
        className="select w-100%"
      />
    </div>
  )
}

QuestionDropdown.propTypes = {
  question: questionShape.isRequired,
  onChange: func.isRequired,
  fieldId: string.isRequired,
  answers: array,
  removeContainer: bool,
}

export default QuestionDropdown
