import { array, bool, func, node, object, string } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"
import { Avatar } from "source/shared/components"
import {
  Displayables,
  PersonDisplayable,
} from "source/directory/edit/Displayables"
import { getRelationship } from "source/shared/getRelationship"

import { css } from "@emotion/react"
import { Date } from "source/shared/DatetimeFmt"
import { DirectoryContactSettings } from "./DirectoryContactSettings"

IndividualOptIn.propTypes = {
  directoryFields: object.isRequired,
  directoryFieldData: array.isRequired,
  directoryPrivacySettings: array.isRequired,
  householdId: string,
  person: object.isRequired,
  onUpdate: func.isRequired,
  onPrivacySettingsUpdate: func.isRequired,
}
IndividualOptIn.defaultProps = { showFooter: true }

export function IndividualOptIn({
  directoryFields,
  directoryFieldData,
  directoryPrivacySettings,
  householdId,
  person,
  onUpdate,
  onPrivacySettingsUpdate,
}) {
  return (
    <>
      <IndividualOptInWrapper>
        <IndividualOptInBody
          person={person}
          directoryFields={directoryFields}
          directoryFieldData={directoryFieldData}
          directoryPrivacySettings={directoryPrivacySettings}
          householdId={householdId}
          onUpdate={onUpdate}
          onPrivacySettingsUpdate={onPrivacySettingsUpdate}
        />
      </IndividualOptInWrapper>
    </>
  )
}

IndividualOptInBody.propTypes = {
  directoryFields: object.isRequired,
  directoryFieldData: array.isRequired,
  directoryPrivacySettings: array.isRequired,
  disabled: bool,
  householdId: string,
  person: object.isRequired,
  onUpdate: func.isRequired,
  onPrivacySettingsUpdate: func.isRequired,
}

export function IndividualOptInBody({
  directoryFields,
  directoryFieldData,
  directoryPrivacySettings,
  disabled,
  householdId,
  person,
  onUpdate,
  onPrivacySettingsUpdate,
}) {
  function canDisplayFieldType(type) {
    return directoryFields.data.some(
      (directoryField) => directoryField.attributes.field_type === type,
    )
  }

  const personDirectorySettings =
    directoryPrivacySettings.find((pref) => pref.personId === person.data.id) ||
    {}

  const allowedContactFieldTypes = directoryFields.data
    .filter((field) =>
      ["email", "phone_number"].includes(field.attributes.field_type),
    )
    .map((field) => field.attributes.field_type.replace(/_/, " "))

  const nestedCheckboxes = css`
    margin-left: 32px;
    margin-top: 4px;
    @media (min-width: 720px) {
      margin-left: 112px;
      margin-top: -8px;
    }
  `
  return (
    <>
      <div className="mt-4p">
        <div css={nestedCheckboxes}>
          {canDisplayFieldType("avatar") && (
            <PersonDisplayable
              directoryFieldData={directoryFieldData}
              disabled={disabled}
              householdId={householdId}
              person={person}
              onUpdate={onUpdate}
              fieldTypeName="avatar"
            >
              Include profile photo
            </PersonDisplayable>
          )}
          {canDisplayFieldType("birthday") &&
            !!person.data.attributes.birthday && (
              <PersonDisplayable
                directoryFieldData={directoryFieldData}
                disabled={disabled}
                householdId={householdId}
                person={person}
                onUpdate={onUpdate}
                fieldTypeName="birthday"
              >
                Include birthday {"("}
                <Date
                  start={person.data.attributes.birthday}
                  style="standard"
                />
                {")"}
              </PersonDisplayable>
            )}
          {canDisplayFieldType("anniversary") &&
            !!person.data.attributes.anniversary && (
              <PersonDisplayable
                directoryFieldData={directoryFieldData}
                disabled={disabled}
                householdId={householdId}
                person={person}
                onUpdate={onUpdate}
                fieldTypeName="anniversary"
              >
                Include anniversary {"("}
                <Date
                  start={person.data.attributes.anniversary}
                  style="standard"
                />
                {")"}
              </PersonDisplayable>
            )}
          {canDisplayFieldType("address") && (
            <Displayables
              fieldType="address"
              directoryFieldData={directoryFieldData}
              disabled={disabled}
              displayables={getRelationship(person, "addresses")}
              householdId={householdId}
              person={person}
              onUpdate={onUpdate}
            >
              {(displayable) => (
                <>
                  {displayable.attributes.street}
                  <br />
                  {displayable.attributes.city}, {displayable.attributes.state}{" "}
                  {displayable.attributes.zip}
                  {!!displayable.attributes.country_name && (
                    <>
                      <br />
                      {displayable.attributes.country_name}
                    </>
                  )}
                </>
              )}
            </Displayables>
          )}
          <DirectoryContactSettings
            person={person}
            directoryPrivacySettings={personDirectorySettings}
            onPrivacySettingsUpdate={onPrivacySettingsUpdate}
            disabled={disabled}
            allowedContactFieldTypes={allowedContactFieldTypes}
            contactFields={[
              canDisplayFieldType("phone_number") && (
                <Displayables
                  fieldType="phone_number"
                  directoryFieldData={directoryFieldData}
                  disabled={
                    disabled ||
                    personDirectorySettings.directoryPrivacyModeEnabled
                  }
                  displayables={getRelationship(person, "phone_numbers")}
                  householdId={householdId}
                  person={person}
                  onUpdate={onUpdate}
                  key={"shared_contact_field_phone_number"}
                >
                  {(displayable) => displayable.attributes.number}
                </Displayables>
              ),
              canDisplayFieldType("email") && (
                <Displayables
                  fieldType="email"
                  directoryFieldData={directoryFieldData}
                  disabled={
                    disabled ||
                    personDirectorySettings.directoryPrivacyModeEnabled
                  }
                  displayables={getRelationship(person, "emails")}
                  householdId={householdId}
                  person={person}
                  onUpdate={onUpdate}
                  key={"shared_contact_field_email"}
                >
                  {(displayable) => displayable.attributes.address}
                </Displayables>
              ),
            ].filter((field) => field)}
          />
        </div>
      </div>
    </>
  )
}

IndividualOptInHeader.propTypes = {
  person: object.isRequired,
}

export function IndividualOptInHeader({ person }) {
  const avatarSize = css`
    flex: 1 0 40px;
    margin-top: -16px;
  `
  return (
    <div className="d-f p-r">
      <div className="avatar ml-1 mr-2 w-8 h-8 o-h" css={avatarSize}>
        <Avatar
          url={person.data.attributes.demographic_avatar_url}
          alt={`profile image for ${person.data.attributes.name}`}
        />
      </div>
      <div className="mt-4p mb-2">
        <Heading level={2} size={3} text={person.data.attributes.name} />
      </div>
    </div>
  )
}

IndividualOptInWrapper.propTypes = { children: node }

export function IndividualOptInWrapper({ children }) {
  return <div className="mb-2 pt-2 w-100%">{children}</div>
}

SelectableIndividualOptIn.propTypes = {
  directoryFieldData: array.isRequired,
  householdId: string,
  person: object.isRequired,
  onUpdate: func.isRequired,
  onPrivacySettingsUpdate: func.isRequired,
}

export function SelectableIndividualOptIn(props) {
  const {
    directoryFieldData,
    householdId,
    person,
    onUpdate,
    onPrivacySettingsUpdate,
  } = props
  const checked = directoryFieldData.some(
    ({ fieldType: directoryFieldDatumType, personId }) =>
      directoryFieldDatumType === "person_name" && person.data.id === personId,
  )

  return (
    <IndividualOptInWrapper>
      <div className="d-f p-r">
        <input
          type="checkbox"
          id={`checkbox-household-${householdId}-person-${person.data.id}`}
          className="large-checkbox checkbox directory-checkbox"
          checked={checked}
          onChange={(e) => {
            const checked = e.target.checked
            onUpdate({
              type: checked ? "append" : "removeAllForPerson",
              payload: { fieldType: "person_name", personId: person.data.id },
            })
            if (!checked) {
              onPrivacySettingsUpdate({
                type: "resetForPerson",
                payload: { personId: person.data.id },
              })
            }
          }}
        />
        <label
          htmlFor={`checkbox-household-${householdId}-person-${person.data.id}`}
          className="large-checkbox-label checkbox-label directory-checkbox-label p-s"
        >
          <IndividualOptInHeader {...props} />
        </label>
      </div>
      <div className="d-f p-r">
        <IndividualOptInBody {...props} disabled={!checked} />
      </div>
    </IndividualOptInWrapper>
  )
}
