import { useState, useEffect } from "react"
import { reservationStepPropTypes } from "source/registrations/propTypes"
import { keyBy } from "lodash"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import QuestionCompletion from "source/registrations/questions/QuestionCompletion"
import Question from "source/registrations/questions/Question"

const RegistrantQuestions = ({
  reservation,
  registrantQuestions,
  error: reservationError,
  onReservationSave,
  isProcessing,
  onIsProcessing,
  onEdit,
  onCancel,
  step,
  className,
}) => {
  const [answers, setAnswers] = useState({})
  const [error, setError] = useState(null)

  const requiredQuestions = registrantQuestions.filter(
    (question) => question.required,
  )

  useEffect(() => {
    const questionAnswers = reservation.answers.map((answer) => {
      return {
        id: answer.id,
        answers: answer.answers,
        questionId: answer.questionId,
      }
    })

    setAnswers(keyBy(questionAnswers, "questionId"))
  }, [reservation])

  const isValid = () =>
    requiredQuestions.every(
      (question) => answers[question.id]?.answers?.length > 0,
    )

  const handleSave = async () => {
    if (!isValid()) {
      return setError("Required Questions can not be blank.")
    }

    onIsProcessing(true)
    setError(null)

    const included = Object.values(answers).map((answer) => ({
      type: "Answer",
      attributes: {
        ...answer,
        ...(answer.id && answer.answers.length == 0 && { _destroy: true }),
      },
    }))

    await onReservationSave({ data: { attributes: {} }, included })
    onIsProcessing(false)
  }

  const handleChange = (questionId, answer) => {
    setAnswers({
      ...answers,
      [questionId]: {
        ...answers[questionId],
        answers: answer,
        questionId,
      },
    })
  }

  const questionsError = error || reservationError

  return (
    <SectionLayout
      heading="Registration questions"
      className={className}
      step={step}
      isButtonShowing={step.isEditable}
      onButtonClick={onEdit}
      error={questionsError}
      isNextDisabled={isProcessing || !isValid()}
      onSave={handleSave}
      onCancel={onCancel}
      expiresAt={reservation.expiresAt}
      isProcessing={isProcessing}
    >
      {!step.isCurrent && step.isComplete && (
        <QuestionCompletion
          requiredQuestions={requiredQuestions}
          questions={registrantQuestions}
          answers={answers}
        />
      )}

      {step.isCurrent &&
        registrantQuestions.map((question) => (
          <Question
            key={question.id}
            question={question}
            answers={answers}
            onChange={handleChange}
            isEditing
          />
        ))}
    </SectionLayout>
  )
}

RegistrantQuestions.propTypes = reservationStepPropTypes

export default RegistrantQuestions
