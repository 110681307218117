import { ReactNode } from "react"

import FieldLabel from "../FieldLabel"
import FormHelpers from "../FormHelpers"

type CustomFieldProps = {
  children: ReactNode
  description?: string
  errors?: {
    detail: string[]
    source: {
      parameter: string
    }
  }[]
  label: string
  required: boolean
}

const CustomField = ({
  children,
  description,
  errors = [],
  label,
  required,
}: CustomFieldProps) => {
  return (
    <>
      <FieldLabel text={label} {...{ required }}>
        {label}
      </FieldLabel>
      {description ? (
        <p style={{ whiteSpace: "pre-line" }}>{description}</p>
      ) : null}
      {children}
      <FormHelpers.FieldErrors {...{ errors }} />
    </>
  )
}

export default CustomField
