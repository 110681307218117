import { api } from "source/registrations/api/client"

export const postPeopleGuest = (personAttributes) => {
  const path = `/people/v2/guests`

  return api.post(path, {
    data: {
      type: "Guest",
      attributes: personAttributes,
    },
  })
}
