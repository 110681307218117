import { elementType, node, object, string } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { Link as ReactRouterLink } from "react-router-dom"

ActionBar.propTypes = {
  as: elementType,
  children: node.isRequired,
  className: string,
  style: object,
}
export function ActionBar({
  as: Comp = "div",
  className = "",
  children,
  style,
  ...rest
}) {
  return (
    <Comp
      className={`d-f ai-c jc-sb px-2 action-drawer action-bar ${className}`}
      style={{ gap: "1rem", ...style }}
      {...rest}
    >
      {children}
    </Comp>
  )
}

Pane.propTypes = {
  children: node.isRequired,
  className: string,
}
function Pane({ className = "", children, ...rest }) {
  return (
    <ActionBar
      className={`p-s fd-c fd-r@xs ta-c ta-l@xs ${className}`}
      {...rest}
    >
      {children}
    </ActionBar>
  )
}
ActionBar.Pane = Pane

Link.propTypes = {
  children: node.isRequired,
  className: string,
  to: string.isRequired,
}
function Link({ className = "", children, to = "", ...rest }) {
  return (
    <ActionBar
      as={ReactRouterLink}
      to={to}
      className={`fd-r ta-l mb-0 ${className}`}
      {...rest}
    >
      <ActionBar.Column>{children}</ActionBar.Column>
      <ActionBar.Action>
        <span className="c-tint3 fs-4">
          <Icon symbol="general#right-chevron" aria-hidden />
        </span>
      </ActionBar.Action>
    </ActionBar>
  )
}
ActionBar.Link = Link

Column.propTypes = {
  children: node.isRequired,
  className: string,
}
function Column({ children, className = "" }) {
  return <div className={`d-f fd-c ai-fs fg-1 ${className}`}>{children}</div>
}
ActionBar.Column = Column

Row.propTypes = {
  children: node.isRequired,
  className: string,
}
function Row({ children, className = "" }) {
  return <div className={`d-f fd-c fd-r@xs fg-1 ${className}`}>{children}</div>
}
ActionBar.Row = Row

Title.propTypes = {
  children: node.isRequired,
}
function Title({ children, ...restProps }) {
  return (
    <Heading level={2} size={4} color="tint1" text={children} {...restProps} />
  )
}
ActionBar.Title = Title

Description.propTypes = {
  children: node.isRequired,
}
function Description({ children }) {
  return <p className="fs-4 m-0 c-tint1">{children}</p>
}
ActionBar.Description = Description

Action.propTypes = {
  children: node.isRequired,
  className: string,
}
function Action({ children, className = "" }) {
  return <div className={className}>{children}</div>
}
ActionBar.Action = Action
