import { Heading } from "@planningcenter/doxy-web"
import { EventLink } from "./EventListing"
import { bool, number, string, oneOfType, oneOf, shape } from "prop-types"
import { Card } from "../../shared/components"

import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
const GalleryEvent = ({ layout, event }) => {
  const {
    id,
    eventDay,
    imageUrl,
    fallbackImageUrl,
    name,
    formattedEndsAt,
    formattedStartsAt,
    multiDayEvent,
    recurring,
    schedule,
    campus,
    featured,
    registrationUrl,
  } = event

  const shouldUseNewSignupBadgeLogic = useFlipperFeatureEnabled(
    "ROLLOUT_calendar_new_signups_logic",
  )
  const legacyLogic = registrationUrl
  const newLogic = registrationUrl?.canRegister
  const canRegister = shouldUseNewSignupBadgeLogic ? newLogic : legacyLogic

  return (
    <Card
      layout={layout}
      link={{ as: EventLink, eventDay, eventId: id }}
      poster={imageUrl || fallbackImageUrl}
      heading={name}
      description={
        <>
          <>{formattedStartsAt}</>
          {multiDayEvent && formattedEndsAt !== formattedStartsAt && (
            <> - {formattedEndsAt}</>
          )}
          {recurring && <span className="c-tint2"> | Recurring</span>}
          {schedule}
        </>
      }
      footnote={
        campus && <Heading color="tint3" level={2} size={6} text={campus} />
      }
      badges={[
        featured && { label: "Featured", theme: "featured" },
        canRegister && { label: "Signups available" },
      ]}
    />
  )
}
GalleryEvent.propTypes = {
  event: shape({
    id: oneOfType([string, number]).isRequired,
    eventDay: string.isRequired,
    imageUrl: string,
    fallbackImageUrl: string,
    name: string.isRequired,
    formattedEndsAt: string.isRequired,
    formattedStartsAt: string.isRequired,
    multiDayEvent: bool.isRequired,
    recurring: bool.isRequired,
    schedule: string,
    campus: string.isRequired,
    featured: bool.isRequired,
    registrationUrl: string,
  }),
  layout: oneOf(["row", "column"]),
}

export default GalleryEvent
