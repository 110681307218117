import PropTypes from "prop-types"
import { Icon } from "source/shared/components"

export default function BlankState({ children, icon }) {
  return (
    <div className="ta-c c-tint2 p-4">
      <div style={{ fontSize: 32 }}>
        <Icon symbol={icon} aria-hidden />
      </div>
      {children}
    </div>
  )
}

BlankState.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
