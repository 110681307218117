import { useState, useCallback } from "react"
import queryString from "query-string"

export default function useQueryString(defaults = {}) {
  const [parsedParams, setParams] = useState(() => ({
    ...defaults,
    ...queryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true,
    }),
  }))

  const setQueryString = useCallback(
    (params) => {
      const qs = queryString.stringify(params)
      const location = [window.location.pathname, qs].filter((i) => i).join("?")

      setParams(params)
      window.history.replaceState(params, new Date().getTime(), location)
    },
    [setParams],
  )

  return [parsedParams, setQueryString]
}
