import { useSession } from "source/shared/hooks/useSession"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  Icon,
} from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { Navigate, useNavigate } from "react-router-dom"

export function HouseholdsNew() {
  const navigate = useNavigate()

  const {
    data: {
      id,
      attributes: { last_name },
    },
  } = useSession(false)

  const {
    meta: { total_count: numberOfHouseholds },
  } = useApiRead(`/people/v2/people/${id}/households?per_page=0`)

  if (numberOfHouseholds > 0) {
    return <Navigate to="/households" replace />
  }

  const createHouseholdAndRedirectToNewHouseholdMemberFormFor = (
    childOrAdult,
  ) => {
    const pathname = `/people/v2/people/${id}/households`
    return sessionApiClient
      .post(pathname, {
        data: { attributes: { name: `${last_name} household` } },
      })
      .then((resp) =>
        navigate(
          `/households/${resp.data.id}/household-members/new?type=${childOrAdult}`,
        ),
      )
      .catch(() =>
        alert(
          `There was an error trying to create a household. Please try again.`,
        ),
      )
  }

  return (
    <div>
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="New household" isActive />
      </BreadcrumbNavigation>
      <div className="d-f ai-c fd-c">
        <div style={{ fontSize: 40 }}>
          <Icon symbol="churchCenter#people" aria-hidden />
        </div>
        <p style={{ fontWeight: 400 }}>Add people to your household</p>
        <div className="d-f g-2">
          <button
            onClick={() =>
              createHouseholdAndRedirectToNewHouseholdMemberFormFor("adult")
            }
            className="btn compact-btn secondary-btn"
          >
            Add adult
          </button>
          <button
            onClick={() =>
              createHouseholdAndRedirectToNewHouseholdMemberFormFor("child")
            }
            className="btn compact-btn secondary-btn"
          >
            Add child
          </button>
        </div>
      </div>
    </div>
  )
}
