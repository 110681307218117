import { useLocation } from "react-router-dom"
import { AlertMessage, Icon } from "source/shared/components"

export function ApplicantAlerts() {
  const {
    state: { type, message },
  } = useLocation()

  if (type === "error") {
    return (
      <AlertMessage type="error" icon="general#exclamation-triangle">
        <p className="mb-0">{message}</p>
      </AlertMessage>
    )
  } else {
    return (
      <div className="alert success-alert d-f ai-c g-1">
        <span className="p-r t-2p c-emerald">
          <Icon symbol="general#outlined-circle-check" />
        </span>
        <p className="mb-0">{message}</p>
      </div>
    )
  }
}
