import { useState, useEffect } from "react"
import { string } from "prop-types"
import { Icon } from "source/shared/components"
import { CopyToClipboard } from "react-copy-to-clipboard"

export default function LinkToJoin({ url }) {
  const [linkClicked, setLinkClicked] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLinkClicked(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [linkClicked])

  return linkClicked ? (
    <div className="d-f ai-c btn minor-compact-btn mt-1">
      <Icon className="c-emerald" symbol="general#check-circle" aria-hidden />
      <p className="pl-1 m-0">URL copied to clipboard!</p>
    </div>
  ) : (
    <CopyToClipboard text={url} onCopy={() => setLinkClicked(true)}>
      <button
        className="btn minor-compact-btn mt-1"
        style={{ color: "var(--color-brand)" }}
      >
        <Icon
          symbol="general#link"
          style={{ transform: "rotate(315deg)", marginRight: "-0.2rem" }}
          aria-hidden
        />
        <p className="pl-1 m-0">Copy URL</p>
      </button>
    </CopyToClipboard>
  )
}

LinkToJoin.propTypes = { url: string.isRequired }
