import { bool, node, object, oneOfType, string } from "prop-types"
import { Link } from "react-router-dom"
import moment from "moment"

import { css } from "@emotion/react"
import { size } from "@planningcenter/system"
import { colors } from "source/shared/colors"
import { Heading } from "@planningcenter/doxy-web"
import { useEmbedded } from "source/calendar/hooks/useEmbedded"

EventListing.propTypes = {
  eventId: string,
  eventDate: object,
  eventTitle: string,
  eventLocation: string,
  eventRecurrence: string,
  eventTime: oneOfType([string, node]),
  signupsAvailable: bool,
}

export default function EventListing({
  eventId,
  eventDate,
  eventTitle,
  eventLocation,
  eventRecurrence,
  eventTime,
  signupsAvailable,
}) {
  const eventDay = moment(eventDate).format("YYYY-MM-DD")
  const recurs = eventRecurrence && eventRecurrence != "Does not repeat"

  return (
    <li
      css={css`
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 4px;
        width: 100%;
      `}
      data-eventid={eventId}
      data-date={eventDay}
    >
      <EventLink eventDay={eventDay} eventId={eventId}>
        <Heading color="brand" level={1} size={3} text={eventTitle} />
      </EventLink>

      <div>
        {eventTime}
        {recurs && (
          <span
            css={css`
              color: ${colors.tint2};
            `}
          >
            {" "}
            | {eventRecurrence}
          </span>
        )}
      </div>

      {eventLocation && (
        <Heading
          color="tint2"
          isUppercase
          level={2}
          size={6}
          text={eventLocation}
          weight={600}
        />
      )}

      {signupsAvailable && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div className="badge">Signups available</div>
        </div>
      )}
    </li>
  )
}

EventLink.propTypes = {
  eventDay: string,
  eventId: string,
  children: node,
}
export function EventLink({ eventId, eventDay, children, ...rest }) {
  const { embedded } = useEmbedded()
  const eventUrl = `calendar/event/${eventId}`
  return embedded ? (
    <a href={eventUrl} {...(embedded && { target: "_blank" })} {...rest}>
      {children}
    </a>
  ) : (
    <Link
      to={`/calendar/event/${eventId}`}
      state={{ eventDate: eventDay }}
      {...rest}
    >
      {children}
    </Link>
  )
}
