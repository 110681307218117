/* eslint-disable jsx-a11y/no-onchange */
import {
  node,
  bool,
  string,
  array,
  func,
  shape,
  oneOfType,
  number,
} from "prop-types"
import classNames from "classnames"

const Option = ({ label = "", value = "", disabled = false }) => (
  <option value={value} disabled={disabled}>
    {label}
  </option>
)

Option.propTypes = {
  label: string,
  value: oneOfType([string, number]),
  disabled: bool,
}

const OptGroup = ({ label, children }) => (
  <optgroup label={label}>{children}</optgroup>
)

OptGroup.propTypes = {
  children: node,
  label: string,
}

const Options = ({ options }) =>
  options.map((option) => (
    <Option
      key={option.value}
      value={option.value}
      label={option.label}
      disabled={option.disabled}
    />
  ))

const Select = ({
  id,
  name,
  onChange,
  options,
  value,
  ariaLabel = null,
  className = "select",
  disabled = false,
  style = {},
  children = null,
  readOnly,
}) => {
  const selectId = id || name

  return (
    <select
      className={classNames(className, { readOnly: readOnly })}
      disabled={disabled}
      style={style}
      name={name}
      id={selectId}
      value={value}
      onChange={onChange}
      aria-label={ariaLabel}
    >
      {children || <Options options={options} />}
    </select>
  )
}

Select.propTypes = {
  ariaLabel: string,
  className: string,
  disabled: bool,
  id: string,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array,
  style: shape({}),
  value: oneOfType([string, number]).isRequired,
  children: node,
  readOnly: bool,
}

Select.Option = Option
Select.OptGroup = OptGroup
Select.Options = Options

export { Select }
