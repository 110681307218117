import { object } from "prop-types"

const AttendeesPersonRegistered = ({ attendee }) => {
  const { person: attendeePerson, waitlist: isWaitlisted } = attendee

  const registrationDetail = attendeePerson?.existingRegistrationDetails?.find(
    (registration) => registration.isWaitlisted === isWaitlisted,
  )
  const description = isWaitlisted
    ? "is already on the waitlist"
    : "has already registered"

  return registrationDetail ? (
    <div className="warning-alert alert fs-4 mb-1 p-1">
      {attendeePerson.name} {description}.
      <a
        href={registrationDetail.url}
        className="pl-1"
        style={{
          color: "var(--color-tint1)",
          textDecoration: "underline",
        }}
      >
        View registration
      </a>
    </div>
  ) : null
}

AttendeesPersonRegistered.propTypes = {
  attendee: object,
}

export default AttendeesPersonRegistered
