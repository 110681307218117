import { isDate } from "lodash"
import moment from "moment"

export const getDateDiff = (previousDate, futureDate) => {
  const date1 = isDate(previousDate) ? previousDate : new Date(previousDate)
  const date2 = isDate(futureDate) ? futureDate : new Date(futureDate)
  const diff = new Date(date2.getTime() - date1.getTime())

  return {
    years: diff.getUTCFullYear() - 1970,
    months: diff.getUTCMonth(),
    days: diff.getUTCDate() - 1,
    hours: diff.getUTCHours(),
    minutes: diff.getUTCMinutes(),
    seconds: diff.getUTCSeconds(),
  }
}

export const calculateAge = (
  birthdate,
  evaluateAt = null,
  formatInYears = true,
) => {
  if (!birthdate) {
    return null
  }

  return moment(evaluateAt).diff(
    moment(birthdate),
    formatInYears ? "years" : "months",
  )
}

export const hasMoreThanXMinutesLeft = (x, targetDate) => {
  const currentTime = new Date()
  const targetTime = new Date(targetDate)

  // Calculate the difference in minutes 60s * 1000ms per second
  const differenceInMinutes =
    (targetTime.getTime() - currentTime.getTime()) / (60 * 1000)

  return differenceInMinutes > x
}
