import { useApiRead } from "source/shared/SessionApiResource"
import { buildUrl } from "source/shared/buildUrl"

export const useEvents = ({
  groupId,
  order = "-starts_at",
  filter = "past",
  limit = 8,
}) => {
  const url = buildUrl(`/groups/v2/me/groups/${groupId}/events`, {
    order,
    filter,
    "fields[Event]": "starts_at,attendance_recording_status",
    per_page: limit.toString(),
  })
  const response = useApiRead(url)

  return response.data || StableArray
}

const StableArray = []
