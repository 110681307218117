import { useContext } from "react"
import PropTypes from "prop-types"
import { CurrentGroupContext } from "source/groups/my/groups"
import { Link } from "react-router-dom"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import { Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

const propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      starts_at: PropTypes.string.isRequired,
      ends_at: PropTypes.string.isRequired,
      location_type_preference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  isRepeating: PropTypes.bool,
}

export default function EventSecondaryHeader({
  event,
  location,
  isRepeating = false,
}) {
  const group = useContext(CurrentGroupContext)
  const {
    id,
    attributes: { name, starts_at, ends_at, location_type_preference },
  } = event
  const url = `${group.base_path}/events/${id}`

  return (
    <article className="d-f f-1 ai-c">
      <div className="f-1">
        <Link to={url} className="fw-500">
          <div className="lh-1.5 mb-2p">
            <Heading level={1} size={2} text={name} />
            {isRepeating && (
              <span className="d-ib ml-4p c-tint3">
                <Icon symbol="general#calendar-repeat" />
              </span>
            )}
          </div>
        </Link>
        <div className="fs-13 lh-1.5 c-tint2">
          <EventDatetimeDisplay startsAt={starts_at} endsAt={ends_at} />
          {location && location_type_preference === "physical" && (
            <div>{location.attributes.name}</div>
          )}
        </div>
      </div>
    </article>
  )
}
EventSecondaryHeader.propTypes = propTypes
