import { func, oneOf } from "prop-types"
import { css } from "@emotion/react"
import { Icon } from "source/shared/components"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"

export const emojis = {
  love: "❤️",
  pray: "🙏",
  laugh: "😆",
  yes: "👍",
  no: "👎",
}

ReactionPicker.propTypes = {
  value: oneOf(Object.keys(emojis)),
  onSelect: func.isRequired,
  onClear: func.isRequired,
}

export function ReactionPicker({ value, onSelect, onClear }) {
  return (
    <Menu>
      <MenuButton css={styles.addReactionButton} aria-label="Add reaction">
        <Icon symbol="churchCenter#add-reaction" css={styles.addReactionIcon} />
      </MenuButton>
      <MenuList className="dropdown__menu" css={styles.picker}>
        {Object.entries(emojis).map(([name, emoji]) => {
          const isSelected = name === value
          return (
            <MenuItem
              key={name}
              css={[styles.reaction, isSelected && styles.reactionSelected]}
              onSelect={() => (isSelected ? onClear() : onSelect(name))}
            >
              {emoji}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

const styles = {
  addReactionButton: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--color-tint8);
    padding: 4px 0.75rem;
    border: 1px solid var(--color-tint7);
    border-radius: 50px;
    &:hover {
      background: var(--color-tint6);
    }
  `,
  addReactionIcon: css`
    fill: var(--color-brand);
    font-size: 14px;
  `,
  picker: css`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    padding: 4px;
  `,
  reaction: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 40px;
    font-size: 1.25rem;
    padding: 0;
    border-radius: 16px / 15px !important;
    color: var(--color-tint1);
    &[data-reach-menu-item][data-selected] {
      background: var(--color-tint10);
    }
    &[data-reach-menu-item][data-selected]:hover {
      background: var(--color-tint8);
      border: 1px solid var(--color-tint5);
    }
  `,
  reactionSelected: css`
    background: var(--color-tint8);
    border: 1px solid var(--color-tint5);
  `,
}
