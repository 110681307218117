import { useContext, useState } from "react"
import _ from "lodash"
import { Helmet } from "react-helmet-async"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSession } from "source/shared/hooks/useSession"
import { NarrowLayout } from "source/Layout"
import PendingSection from "./PendingSection"
import UpcomingSection from "./UpcomingSection"
import useMyChuchCenter from "./hooks/useMyChurchCenter"
import { WebBootContext } from "source/publishing/WebBoot"
import { RespondsToBlockoutNotification } from "source/services/shared"

export function Schedule() {
  useSession(true)

  const { abilities: { services: servicesAbilities } = {} } =
    useContext(WebBootContext)

  const initialState = useMyChuchCenter()
  const [{ activityDates, pendingItems }, setScheduleData] =
    useState(initialState)

  const refreshSchedule = () =>
    sessionApiClient
      .get("/publishing/v2/me/my_church_center")
      .then((resp) => {
        const { upcoming: activityDates, pending: pendingItems } =
          resp.data.attributes

        setScheduleData({ activityDates, pendingItems })
      })
      .catch((json) => json)

  const hasServicesAbilities = _.some(servicesAbilities)
  const hasPendingItems = _.some(pendingItems)

  return (
    <NarrowLayout>
      <Helmet title="Schedule" />
      <RespondsToBlockoutNotification />
      <PendingSection
        pendingItems={pendingItems}
        refreshSchedule={refreshSchedule}
        showOptionsDropdown={hasPendingItems}
        hasServicesAbilities={hasServicesAbilities}
      />
      <UpcomingSection
        activityDates={activityDates}
        showOptionsDropdown={!hasPendingItems}
        hasServicesAbilities={hasServicesAbilities}
      />
    </NarrowLayout>
  )
}
