import { array, bool, func, object } from "prop-types"
import { Subheading } from "@planningcenter/doxy-web"
import ScheduleItem from "./ScheduleItem"

export default function ScheduleItems({
  handleAcceptToggle,
  handleDeclineToggle,
  hasAnyPending,
  responseData,
  schedules,
  setToggleableScheduleIds,
  toggleableScheduleIds,
}) {
  return (
    <div className="f-1 d-f fd-c g-1">
      <Subheading level={2} text="My Positions" />
      <div className="gc-tint9 p-2 br-4p f-1">
        {schedules.map((schedule) => {
          const scheduleItemProps = {
            handleAcceptToggle,
            handleDeclineToggle,
            schedule,
            scheduleResponseData: responseData[schedule.id],
            setToggleableScheduleIds,
            toggleableScheduleIds,
          }

          return schedule.splitTeam ? (
            schedule.serviceTimes.map((st) => (
              <ScheduleItem key={st.id} time={st} {...scheduleItemProps} />
            ))
          ) : (
            <ScheduleItem key={schedule.id} {...scheduleItemProps} />
          )
        })}
        {toggleableScheduleIds.length === 0 && (
          <p className="fs-4 c-tint3 mb-1">
            {hasAnyPending
              ? "You have not responded."
              : "This position is confirmed."}
          </p>
        )}
      </div>
    </div>
  )
}

ScheduleItems.propTypes = {
  handleAcceptToggle: func,
  handleDeclineToggle: func,
  hasAnyPending: bool,
  responseData: object,
  schedules: array,
  setToggleableScheduleIds: func,
  toggleableScheduleIds: array,
}
