import { useState, useEffect, useMemo, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { uniqBy, orderBy, groupBy, castArray } from "lodash"
import NotificationListItem from "./NotificationListItem"
import InfiniteScroll from "react-infinite-scroller"
import { useSession } from "source/shared/hooks/useSession"
import { JoltContext } from "source/shared/contexts/JoltContext"
import { Icon } from "source/shared/components"
import { Heading } from "@planningcenter/doxy-web"

const PER_PAGE = 100

function fixMessageBusGeneratingIncorrectOrigin(url) {
  return url
    .replace("pco.test", "churchcenter.test")
    .replace("planningcenteronline.com", "churchcenter.com")
    .replace("-staging", ".staging")
}

export default function NotificationList() {
  const { data: currentPerson } = useSession()
  const [loadUrl, setLoadUrl] = useState(
    `/notifications/v2/me/notifications?include=native_payloads&per_page=${PER_PAGE}`,
  )
  const [ignoreUpdatesForRollUpKeys, setIgnoreUpdatesForRollUpKeys] = useState(
    [],
  )
  const [nextUrl, setNextUrl] = useState()
  const [initialLoading, setInitialLoading] = useState(true)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (!loadUrl) return
    sessionApiClient
      .get(loadUrl)
      .then((payload) => {
        processPayload(payload)
        setNotifications((notifications) =>
          orderBy(
            uniqBy([...notifications, ...payload.data], (n) => n.id),
            ["attributes.created_at"],
            ["desc"],
          ),
        )
        setNextUrl(payload.links.next)
        if (initialLoading) setInitialLoading(false)
      })
      .catch((e) => console.log("ERRROR:", e))
  }, [loadUrl])

  const handleMarkRollupKeyRead = (rollup_key) => {
    setIgnoreUpdatesForRollUpKeys((keys) => keys.concat(rollup_key))
    let payload = payloadForRollupKey(rollup_key)

    return sessionApiClient
      .post(`/notifications/v2/me/bulk_update`, payload)
      .then(() => {
        setNotifications((notifications) =>
          notifications.map((n) =>
            n.attributes.rollup_key === rollup_key
              ? optimisticUpdateRead(n)
              : n,
          ),
        )
      })
  }

  const handleExecuteMarkAllAsRead = () => {
    let payload = payloadForPerson(currentPerson)

    setNotifications((notifications) =>
      notifications.map((n) =>
        !n.attributes.read_at ? optimisticUpdateRead(n) : n,
      ),
    )
    return sessionApiClient
      .post(`/notifications/v2/me/bulk_update`, payload)
      .then(() => {
        setIgnoreUpdatesForRollUpKeys([])
      })
  }

  const handleMarkAllAsRead = () => {
    if (confirm("Are you sure you want to mark all notifications as read?")) {
      handleExecuteMarkAllAsRead()
    }
  }

  const handleMarkRead = (notification) => {
    setNotifications((notifications) =>
      notifications.map((n) =>
        n.id === notification.id ? optimisticUpdateRead(n) : n,
      ),
    )
    return sessionApiClient.post(
      `${fixMessageBusGeneratingIncorrectOrigin(
        notification.links.self,
      )}/mark_read`,
    )
  }

  const handleMarkUnread = (notification) => {
    setNotifications((notifications) =>
      notifications.map((n) =>
        n.id === notification.id ? optimisticUpdateUnread(n) : n,
      ),
    )
    return sessionApiClient.post(
      `${fixMessageBusGeneratingIncorrectOrigin(
        notification.links.self,
      )}/mark_unread`,
    )
  }

  const handleToggleReadStatus = useCallback(
    (notifications) => {
      const { read_at, rollup_key } = notifications[0].attributes
      if (read_at) {
        return handleMarkUnread(notifications[0])
      } else if (rollup_key) {
        return handleMarkRollupKeyRead(rollup_key)
      } else {
        return handleMarkRead(notifications[0])
      }
    },
    [handleMarkUnread, handleMarkRollupKeyRead],
  )

  const notificationsChannel = useContext(JoltContext)?.notificationsChannel
  const notificationUpdated = useCallback((e) => {
    let payload = JSON.parse(e.data)
    if (ignoreUpdatesForRollUpKeys.includes(payload.data.attributes.rollup_key))
      return

    processPayload(payload)
    setNotifications((notifications) =>
      notifications.map((n) =>
        n.id === payload.data[0].id ? payload.data[0] : n,
      ),
    )
  }, [])
  const notificationCreated = useCallback((e) => {
    let payload = JSON.parse(e.data)
    processPayload(payload)
    setNotifications((notifications) =>
      orderBy(
        [...notifications, ...payload.data],
        ["attributes.created_at"],
        ["desc"],
      ),
    )
  }, [])
  const notificationDestroyed = useCallback((e) => {
    let payload = JSON.parse(e.data)
    setNotifications((notifications) =>
      notifications.filter((n) => n.id !== payload.data.id),
    )
  }, [])

  useEffect(() => {
    const kindBase = "notifications.v2.events.notification"
    if (notificationsChannel) {
      notificationsChannel.bind(`${kindBase}.updated`, notificationUpdated)
      notificationsChannel.bind(`${kindBase}.created`, notificationCreated)
      notificationsChannel.bind(`${kindBase}.destroyed`, notificationDestroyed)
    }
    return () => {
      if (notificationsChannel) {
        notificationsChannel.unbind(`${kindBase}.updated`, notificationUpdated)
        notificationsChannel.unbind(`${kindBase}.created`, notificationCreated)
        notificationsChannel.unbind(
          `${kindBase}.destroyed`,
          notificationDestroyed,
        )
      }
    }
  }, [notificationsChannel])

  const unread = useMemo(
    () =>
      groupBy(
        notifications.filter((n) => !n.attributes.read_at),
        (n) => n.attributes.rollup_key || n.id,
      ),
    [notifications],
  )
  const unreadKeys = Object.keys(unread)
  const read = useMemo(
    () =>
      groupBy(
        notifications.filter(
          (n) =>
            n.attributes.read_at &&
            !unreadKeys.includes(n.attributes.rollup_key),
        ),
        (n) => n.attributes.rollup_key || n.id,
      ),
    [notifications],
  )

  if (initialLoading) return <div>Loading</div>

  return (
    <InfiniteScroll
      loadMore={() => setLoadUrl(nextUrl)}
      hasMore={nextUrl}
      loader={
        <div className="loader" key={0}>
          Loading ...
        </div>
      }
    >
      <Heading level={1} text="Notifications" />

      <div className="mt-4">
        {Object.values(unread).length > 0 ? (
          <Unread
            notifications={Object.values(unread)}
            handleToggleReadStatus={handleToggleReadStatus}
            handleMarkAllAsRead={handleMarkAllAsRead}
          />
        ) : (
          <NoUnread />
        )}
        {Object.values(read).length > 0 && (
          <Read
            notifications={Object.values(read)}
            handleToggleReadStatus={handleToggleReadStatus}
          />
        )}
      </div>
    </InfiniteScroll>
  )
}

Unread.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
    .isRequired,
  handleToggleReadStatus: PropTypes.func.isRequired,
  handleMarkAllAsRead: PropTypes.func.isRequired,
}

function Unread({
  notifications,
  handleToggleReadStatus,
  handleMarkAllAsRead,
}) {
  return (
    <>
      <div className="d-f ai-c jc-sb mb-4">
        <Heading level={2} text="New" />
        <button className="minor-btn btn" onClick={handleMarkAllAsRead}>
          Mark all as read
        </button>
      </div>
      <Notifications
        notifications={notifications}
        handleToggleReadStatus={handleToggleReadStatus}
      />
    </>
  )
}

function NoUnread() {
  return (
    <div className="ta-c mt-8 mb-8">
      <div className="ta-c mb-1 fs-21">
        <Icon symbol="general#magic-wand" aria-label="Inbox zero" />
      </div>
      <Heading level={2} text="You're all caught up!" />
    </div>
  )
}

Read.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
    .isRequired,
  handleToggleReadStatus: PropTypes.func.isRequired,
}

function Read({ notifications, handleToggleReadStatus }) {
  return (
    <div className="d-b pt-4">
      <Heading level={2} text="Previous" />
      <div className="d-b mt-2">
        <Notifications
          notifications={notifications}
          handleToggleReadStatus={handleToggleReadStatus}
        />
      </div>
    </div>
  )
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
    .isRequired,
  handleToggleReadStatus: PropTypes.func.isRequired,
}

function Notifications({ notifications, handleToggleReadStatus }) {
  return (
    <ul className="unstyled">
      {notifications.map((n) => (
        <li key={n[0].id}>
          <NotificationListItem
            notifications={n}
            handleToggleReadStatus={handleToggleReadStatus}
          />
        </li>
      ))}
    </ul>
  )
}

const processPayload = (payload) => {
  payload.data = castArray(payload.data)
  payload.data.forEach((notification) => {
    if (!notification.payload)
      notification.payload = extractRelationship(
        payload,
        notification.relationships.native_payloads,
      )[0]
  })
}

function extractRelationship(payload, relationship) {
  relationship = relationship?.data || relationship
  if (!relationship) return null

  if (Array.isArray(relationship)) {
    return relationship.map((object) => extractRelationship(payload, object))
  }
  return payload.included.find(
    (object) =>
      object.type === relationship.type && object.id === relationship.id,
  )
}

const payloadForRollupKey = (rollup_key) => {
  return {
    data: {
      type: "BulkUpdate",
      attributes: {},
      relationships: {
        rollup_keys: {
          data: {
            source: "/included/rollup_key/*",
          },
        },
      },
    },
    included: [
      {
        type: "rollup_key",
        id: rollup_key,
        attributes: { action: "read" },
      },
    ],
  }
}

const payloadForPerson = (currentPerson) => {
  return {
    data: {
      type: "BulkUpdate",
      attributes: {},
      relationships: {
        rollup_keys: {
          data: {
            source: "/included/person/*",
          },
        },
      },
    },
    included: [
      {
        type: "person",
        id: `${currentPerson.id}`,
        attributes: { action: "read" },
      },
    ],
  }
}

function optimisticUpdateRead(record) {
  return {
    ...record,
    attributes: { ...record.attributes, read_at: new Date() },
  }
}

function optimisticUpdateUnread(record) {
  return {
    ...record,
    attributes: { ...record.attributes, read_at: null },
  }
}
