import { object, bool, node } from "prop-types"

const AttendeeTypeString = ({ attendee, isTruncated = false, children }) => {
  const { attendeeType } = attendee

  return (
    <div>
      <div className="d-f ai-c jc-sb">
        <div className={isTruncated ? "truncate" : "wb-bw"}>
          {attendeeType.name}
          {attendee?.person?.name && `: ${attendee.person.name}`}
        </div>
        {children && <div>{children}</div>}
      </div>
    </div>
  )
}

AttendeeTypeString.propTypes = {
  attendee: object,
  isTruncated: bool,
  children: node,
  displaySingleLine: bool,
}

export default AttendeeTypeString
