import useQueryString from "source/shared/hooks/useQueryString"
import { NewAdultHouseholdMemberForm } from "./NewAdultHouseholdMemberForm"
import { NewChildHouseholdMemberForm } from "./NewChildHouseholdMemberForm"

export function HouseholdMemberNew() {
  const [queryStrings] = useQueryString()

  return queryStrings.type === "adult" ? (
    <NewAdultHouseholdMemberForm />
  ) : (
    <NewChildHouseholdMemberForm />
  )
}
