import { css } from "@emotion/react"
import { Heading } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { colors } from "source/shared/colors"
import { useQRCode } from "source/shared/QRCode"

export function CheckIns() {
  useQRCode()

  return (
    <div className="ta-c">
      <div css={styles.iconCircleBackground}>
        <div css={styles.icon}>
          <Icon symbol="general#check" aria-hidden />
        </div>
      </div>
      <div className="d-f fd-c g-1 mb-7">
        <Heading level={1} color="tint1" text="Get ready to check in" />
        <Heading
          level={2}
          size={3}
          color="tint1"
          text="using the Church Center app!"
        />
      </div>
      <div className="d-f jc-c">
        <a
          className="mr-1 mr-2@sm"
          href="https://itunes.apple.com/us/app/my-church-center/id1357742931?mt=8&ls=1&ign-mpt=uo%3D4"
          style={{ width: 161 }}
        >
          <img
            src="/assets/app-store-badge1.png"
            alt="Download on Apple App Store"
          />
        </a>
        <a
          className="ml-1 ml-2@sm"
          href="https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter"
          style={{ width: 180 }}
        >
          <img
            src="/assets/google-play-badge.png"
            alt="Download on Google Play Store"
          />
        </a>
      </div>
    </div>
  )
}

const styles = {
  iconCircleBackground: css`
    background-image: linear-gradient(
      135deg,
      ${colors.brandGradientStart} 0%,
      ${colors.brandGradientEnd} 100%
    );
    border-radius: 100%;
    display: inline-block;
    height: 64px;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: center;
    width: 64px;

    &:before {
      background-color: white;
      border-radius: 100%;
      content: "";
      height: 58px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 58px;
    }
  `,
  icon: css`
    color: ${colors.brand};
    font-size: 32px;
    position: relative;
    top: 12px;
  `,
}
