import { useSession } from "source/shared/hooks/useSession"
import { RepliesDataContext, RepliesDataProvider } from "./RepliesDataContext"
import RepliesInitialDataLoader from "./RepliesInitialDataLoader"
import LiveUpdates from "source/groups/messaging/LiveUpdates"
import { node, object } from "prop-types"

export const BOT_AUTHOR_ID = "bot"

Topic.propTypes = {
  group: object.isRequired,
  topic: object.isRequired,
  children: node.isRequired,
}

export default function Topic({ group, topic, children }) {
  let jsonApiShape = useSession(false)
  if (jsonApiShape.data.type != "VerifiedInSessionPerson")
    return <div>Not logged in</div>

  const fetchSubscribeTokenURL = `/groups/v2/me/groups/${
    group.id
  }/forum_topics/${topic.id}/jolt_subscribe`
  const joltEvents = [
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_reply.created",
    },
    {
      func: "ingest",
      event: "groups.church_center.v2.events.forum_reply.updated",
    },
    {
      func: "remove",
      event: "groups.church_center.v2.events.forum_reply.destroyed",
    },
    {
      func: "react",
      event: "groups.church_center.v2.events.forum_reply_reaction.created",
    },
    {
      func: "react",
      event: "groups.church_center.v2.events.forum_reply_reaction.updated",
    },
    {
      func: "react",
      event: "groups.church_center.v2.events.forum_reply_reaction.destroyed",
    },
  ]

  return (
    <RepliesDataProvider group={group} topic={topic}>
      <LiveUpdates
        dataContext={RepliesDataContext}
        subscribeTo={`church_center.groups.messaging.topics.${topic.id}`}
        fetchSubscribeTokenURL={fetchSubscribeTokenURL}
        events={joltEvents}
      >
        <RepliesInitialDataLoader />
        {children}
      </LiveUpdates>
    </RepliesDataProvider>
  )
}
