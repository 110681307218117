import { object } from "prop-types"

const Header = ({ registration }) => {
  return (
    <header
      className="mb-3"
      css={{
        borderBottom: "1px solid var(--color-tint5)",
      }}
    >
      <h2 className="mb-1">
        Thanks! Your registration for&nbsp;
        <a href={registration.event.publicUrl}>{registration.event.name}</a>
        &nbsp; has been submitted.
      </h2>

      <p>
        A confirmation has been sent to{" "}
        {registration.responsiblePerson.emailAddress}. Your registration details
        are below.
      </p>
    </header>
  )
}

Header.propTypes = {
  registration: object,
}

export default Header
