import { useState } from "react"

import Checkbox from "../shared/Checkbox"
import ResourceQuantity from "./ResourceQuantity"
import type { FormField, ResourceFieldValue } from "../types"

type ResourceCheckboxesFieldProps = {
  id: FormField["id"]
  onChange: (value: { selected?: ResourceFieldValue[] }, id: string) => void
  options: FormField["options"]
}

export default function ResourceCheckboxesField({
  id,
  onChange,
  options,
}: ResourceCheckboxesFieldProps) {
  const [selected, setSelected] = useState<ResourceFieldValue[]>([])

  const handleQuantityChange = (newValue: string, optionId: string): void => {
    const newSelected = selected.map((value) => {
      if (value.optionId === optionId) {
        return { ...value, quantity: newValue }
      }
      return value
    })

    setSelected(newSelected)
    onChange({ selected: newSelected }, id)
  }

  const handleCheckboxChange = ({
    target: { value: optionId, checked },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const values = checked
      ? [...selected, { optionId, quantity: "1" }]
      : selected.filter((value) => value.optionId !== optionId)

    setSelected(values)

    if (values.length > 0) {
      onChange({ selected: values }, id)
    } else {
      onChange({}, id)
    }
  }

  const resourceIsSelected = (optionId: string): boolean =>
    selected.some((value) => value.optionId === optionId)

  const getQuantityForResource = (optionId: string): string => {
    const resource = selected.find((value) => value.optionId === optionId)
    return resource ? resource.quantity : "1"
  }

  return options.map((option) => (
    <div key={option.id}>
      <Checkbox
        label={option.attributes.label}
        checked={resourceIsSelected(option.id)}
        id={`checkbox_${option.id}`}
        value={option.id}
        onChange={handleCheckboxChange}
      />
      {resourceIsSelected(option.id) && (
        <div className="m-1 ml-3">
          <ResourceQuantity
            id={option.id}
            maxQuantity={option.optionable.attributes.quantity}
            onChange={(e) => handleQuantityChange(e.target.value, option.id)}
            quantity={getQuantityForResource(option.id)}
          />
        </div>
      )}
    </div>
  ))
}
