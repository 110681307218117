import { shape, bool } from "prop-types"
import { Icon } from "source/shared/components"

const StepStatusIcon = ({ step }) => {
  const symbol =
    step.isCurrent || !step.isComplete
      ? "churchCenter#circle"
      : "general#check-circle"

  const modifier = step.isCurrent
    ? "reservation-step__status-icon--editing"
    : step.isComplete
      ? "reservation-step__status-icon--completed"
      : ""

  const ariaLabel = step.isCurrent
    ? "Current step status icon"
    : step.isComplete
      ? "Completed step status icon"
      : "Unvisited step staus icon"

  return (
    <Icon
      symbol={symbol}
      aria-label={ariaLabel}
      className={`reservation-step__status-icon ${modifier}`}
      aria-hidden
    />
  )
}

StepStatusIcon.propTypes = {
  step: shape({
    isCurrent: bool,
    isComplete: bool,
  }),
}

export default StepStatusIcon
