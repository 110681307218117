import { useEffect, useState } from "react"
import { bool, string } from "prop-types"

Loading.propTypes = { message: string }
Loading.defaultProps = { message: "Loading" }

export function Loading({ message }) {
  const [step, setStep] = useState(0)
  useEffect(() => {
    const timeout = setTimeout(() => setStep((step + 1) % 4), 350)
    return () => {
      clearTimeout(timeout)
    }
  }, [step])

  Dot.propTypes = { visible: bool.isRequired }
  function Dot({ visible }) {
    const visibility = visible ? "visible" : "hidden"
    return <span style={{ visibility }}>.</span>
  }

  return (
    <div className="d-f ai-c c-tint4 loading-wrapper">
      <span className="d-f f-1 jc-c">
        {message}
        <Dot visible={step > 0} />
        <Dot visible={step > 1} />
        <Dot visible={step > 2} />
      </span>
    </div>
  )
}

export function LoadingFullScreen() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh)",
        justifyContent: "center",
      }}
    >
      <Loading />
    </div>
  )
}
