import { Fragment } from "react"
import { arrayOf, shape, string, bool } from "prop-types"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { NarrowLayout } from "source/Layout"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
  Icon,
} from "source/shared/components"
import { useSession } from "source/shared/hooks/useSession"
import { useApiRead } from "source/shared/SessionApiResource"
import { sortAdults, sortChildren } from "./Household"
import { colors } from "source/shared/colors"
import { Heading } from "@planningcenter/doxy-web"

const ME_PATH =
  "/people/v2/me?include=emails,phone_numbers,addresses,marital_status,school"

export function HouseholdsIndex() {
  useSession(true)
  const profile = useApiRead(ME_PATH)

  const householdsAndMembers = useApiRead(
    `/people/v2/people/${profile.data.id}/households?include=people`,
  )

  const households = householdsAndMembers.data.map((household) => {
    const {
      id,
      attributes: { name },
    } = household

    const members = household.relationships.people.data
      .map((householdMember) =>
        householdsAndMembers.included.find(
          (includedMember) => includedMember.id === householdMember.id,
        ),
      )
      .map((householdMember) => {
        const { birthday, child, demographic_avatar_url, name, status } =
          householdMember.attributes

        return {
          birthday,
          child,
          demographic_avatar_url,
          personId: householdMember.id,
          name,
          status,
        }
      })

    const currentUser = members.find(
      (member) => member.personId === profile.data.id,
    )
    const adults = members.filter(
      (member) => !member.child && member.personId !== profile.data.id,
    )
    const children = members.filter(
      (member) => member.child && member.personId !== profile.data.id,
    )

    return {
      id,
      householdMembers: [
        currentUser,
        ...sortAdults(adults),
        ...sortChildren(children),
      ],
      householdName: name,
    }
  })

  return (
    <NarrowLayout>
      <Helmet title="Households" />
      <BreadcrumbNavigation>
        <BreadcrumbPage linkUrl="/me/profile" pageText="Profile" />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Households" isActive />
      </BreadcrumbNavigation>
      <MyHouseholds households={households} />
    </NarrowLayout>
  )
}

const householdMemberDataType = shape({
  birthday: string,
  child: bool.isRequired,
  demographic_avatar_url: string.isRequired,
  name: string.isRequired,
  personId: string.isRequired,
  status: string.isRequired,
})

const householdDataPropType = shape({
  id: string.isRequired,
  householdMembers: arrayOf(householdMemberDataType).isRequired,
  householdName: string.isRequired,
})

MyHouseholds.propTypes = {
  households: arrayOf(householdDataPropType).isRequired,
}

function MyHouseholds({ households }) {
  if (households.length === 0) return <NoHouseholds />

  const styles = {
    link: {
      alignItems: "center",
      color: colors.tint1,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      transition: "all 0.2s",

      "& .chevron": {
        color: colors.tint4,
      },

      "&:hover": {
        backgroundColor: colors.tint8,
        cursor: "pointer",
        "& .chevron": { color: colors.tint3 },
      },
    },
    appIcon: {
      color: colors.tint3,
    },
  }

  return (
    <>
      {households.map(({ id, householdMembers, householdName }) => {
        return (
          <Fragment key={`${householdName}-${id}`}>
            <div className="d-b mb-2">
              <Heading
                level={2}
                size={3}
                text={households.length === 1 ? "Household" : householdName}
              />
            </div>
            <a
              className="action-drawer d-b mb-4"
              css={styles.link}
              href={`/households/${id}`}
            >
              <div className="d-f">
                {householdMembers.map((member) => {
                  return (
                    <div
                      className="d-f f-1"
                      key={`${member.name}-${member.personId}`}
                    >
                      <div className="w-5 h-5 mr-1">
                        <img
                          alt="Household member"
                          className="circle"
                          loading="lazy"
                          src={`${member.demographic_avatar_url}?g=80x80%23`}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="chevron">
                <Icon symbol="general#right-chevron" />
              </div>
            </a>
          </Fragment>
        )
      })}
    </>
  )
}

function NoHouseholds() {
  return (
    <div className="action-drawer mb-1">
      Households you are a part of will appear here for easy access
      <br />
      <Link to="/me/profile">
        <button className="md-btn secondary-btn btn mt-2">
          Back to Profile
        </button>
      </Link>
    </div>
  )
}
