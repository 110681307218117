import { useState } from "react"
import { func, string } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import spinner from "source/svg/spinner.svg"
import { Modal, useCloseModal } from "source/shared/components/Modal"

export function LeaveGroupModalContent({
  groupId,
  groupName,
  afterSubmit = () => {},
}) {
  const closeModal = useCloseModal()
  const [inFlight, setInFlight] = useState(false)

  const confirmText = `Are you sure you would like to leave ${groupName}?`
  const errorText = `
    Uh oh, something went wrong. We could not remove your membership.
    Please try again, or contact a church administrator.
  `
  const handleSubmit = () => {
    setInFlight(true)
    sessionApiClient
      .del(`/groups/v2/me/groups/${groupId}/my_membership`)
      .then(() => {
        afterSubmit()
      })
      .catch(() => {
        alert(errorText)
      })
      .finally(() => {
        closeModal()
        setInFlight(false)
      })
  }

  return (
    <Modal.Show>
      <Modal.Header>Leave {groupName}</Modal.Header>
      <Modal.Body>
        {confirmText}
        <Modal.Footer>
          <Modal.Close>Cancel</Modal.Close>
          <button className="btn" onClick={handleSubmit}>
            {inFlight ? (
              <img
                src={spinner}
                alt="Deleting"
                style={{ height: "1em", width: "1em" }}
              />
            ) : (
              <div>Yes, leave group</div>
            )}
          </button>
        </Modal.Footer>
      </Modal.Body>
    </Modal.Show>
  )
}
LeaveGroupModalContent.propTypes = {
  groupId: string.isRequired,
  groupName: string.isRequired,
  afterSubmit: func,
}
