import { useContext } from "react"
import { Navigate, useParams } from "react-router-dom"
import { NarrowLayout } from "source/Layout"
import { useSession } from "source/shared/hooks/useSession"
import { useApiRead } from "source/shared/SessionApiResource"
import { colors } from "source/shared/colors"
import {
  Icon,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { WebBootContext } from "source/publishing/WebBoot"
import { Heading } from "@planningcenter/doxy-web"

const styles = {
  link: {
    alignItems: "center",
    color: colors.tint1,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    width: "100%",
    transition: "all 0.2s",

    "& .chevron": {
      color: colors.tint4,
    },

    "&:hover": {
      backgroundColor: colors.tint8,
      cursor: "pointer",
      "& .chevron": { color: colors.tint3 },
    },
  },
}

export function sortChildren(children) {
  const byBirthday = (a, b) =>
    (a.birthday > b.birthday) - (a.birthday < b.birthday)

  const sortedActiveChildren = children
    .filter((child) => child.status === "active")
    .sort(byBirthday)
  const sortedInactiveChildren = children
    .filter((child) => child.status === "inactive")
    .sort(byBirthday)

  return [...sortedActiveChildren, ...sortedInactiveChildren]
}

export function sortAdults(adults) {
  const byName = (a, b) => (a.name > b.name) - (a.name < b.name)

  const sortedActiveAdults = adults
    .filter((adult) => adult.status === "active")
    .sort(byName)

  const sortedInactiveAdults = adults
    .filter((adult) => adult.status === "inactive")
    .sort(byName)

  return [...sortedActiveAdults, ...sortedInactiveAdults]
}

export function Household() {
  const { householdId } = useParams()
  const {
    data: { id },
  } = useSession(true)

  const {
    layout: { organization_contact_email },
  } = useContext(ServerRenderedProps)

  const {
    abilities: {
      people: {
        add_or_remove_household_members: addOrRemoveHouseholdMembersEnabled,
      },
    },
  } = useContext(WebBootContext)

  const household = useApiRead(
    `/people/v2/people/${id}/households/${householdId}?include=people,household_members`,
  )

  if (household.errors?.length > 0) return <Navigate to="/" replace />

  const includedPersonRecords = household.included.filter(
    (record) => record.type === "Person",
  )

  const includedHouseholdRecords = household.included.filter(
    (record) => record.type === "HouseholdMember",
  )

  const defaultBirthday = new Date().toISOString().split("T")[0]

  const normalizedHouseholdMembers = includedPersonRecords.map((person) => {
    const associatedHouseholdRecord = includedHouseholdRecords.find(
      (member) => member.relationships.person.data.id === person.id,
    )

    const { birthday, child, demographic_avatar_url, name, status } =
      person.attributes

    return {
      birthday: birthday ? birthday : defaultBirthday,
      child,
      demographic_avatar_url,
      id: associatedHouseholdRecord.id,
      name,
      personId: person.id,
      status,
    }
  })

  const currentUser = normalizedHouseholdMembers.find(
    (member) => member.personId === id,
  )
  const adults = normalizedHouseholdMembers.filter(
    (member) => !member.child && member.personId !== id,
  )
  const children = normalizedHouseholdMembers.filter(
    (member) => member.child && member.personId !== id,
  )

  const sortedNormalizedHouseholdMembers = [
    currentUser,
    ...sortAdults(adults),
    ...sortChildren(children),
  ]

  return (
    <NarrowLayout>
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText={household.data.attributes.name} isActive />
      </BreadcrumbNavigation>
      <div>
        <div className="screen-reader-text">
          <Heading level={1} text={household.data.attributes.name} />
        </div>
        <div>
          {!!addOrRemoveHouseholdMembersEnabled && (
            <div className="mb-2 d-f jc-fe">
              <a
                className="btn secondary-btn compact-btn mr-1"
                href={`/households/${householdId}/household-members/new?type=adult`}
              >
                Add adult
              </a>
              <a
                className="btn secondary-btn compact-btn mr-1"
                href={`/households/${householdId}/household-members/new?type=child`}
              >
                Add child
              </a>
            </div>
          )}
          {sortedNormalizedHouseholdMembers.map((member) => (
            <a
              key={member.id}
              href={
                member.personId === id
                  ? `/me/profile/contact-and-info/edit`
                  : `/households/${householdId}/household-members/${member.id}/contact-and-info/edit`
              }
              className="action-drawer"
              css={styles.link}
            >
              <div className="d-f ai-c f-1">
                <div className="w-5 h-5 mr-1">
                  <img
                    alt="Household member"
                    className="circle"
                    loading="lazy"
                    src={`${member.demographic_avatar_url}?g=96x96%23`}
                  />
                </div>

                <div>
                  <div>{member.name}</div>
                  {member.personId === id && <div className="c-tint2">You</div>}
                </div>
              </div>
              <div className="chevron">
                <Icon symbol="general#right-chevron" />
              </div>
            </a>
          ))}
        </div>
        {!addOrRemoveHouseholdMembersEnabled && (
          <div className="ta-c c-tint3">
            Want to add or remove a household member?
            <div>
              <a href={`mailto:${organization_contact_email}`}>Contact us</a>
            </div>
          </div>
        )}
      </div>
    </NarrowLayout>
  )
}
