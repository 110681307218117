import { createContext, useContext, useReducer, useState } from "react"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useNavigate } from "react-router-dom"
import {
  apiResource,
  arrayOf,
  bool,
  node,
  object,
  shape,
  string,
} from "source/shared/prop_types"
import spinner from "source/svg/spinner.svg"
import linkify from "linkifyjs/string"
import { unlinkify } from "source/shared/linkify"
import { Icon } from "source/shared/components"
import {
  RepliesDataContext,
  prepareMessageForSubmitting,
} from "source/groups/messaging/Topic/RepliesDataContext"
import { CurrentGroupContext } from "source/groups/my/groups"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import {
  parseHTML,
  convertToExpandedLinks,
  arrayParts,
} from "source/groups/my/groups/messages/utils"
import { sanitize } from "source/groups/messaging/utils"
import "./expanded-links.css"
import { Heading } from "@planningcenter/doxy-web"

const ReplyContext = createContext()

export function Reply({
  children,
  reply,
  author,
  canEdit,
  canDelete,
  myReply,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const showControls = canEdit || canDelete

  const replyClassName = `messaging__reply d-f ai-c ${myReply && "my-reply"}`

  if (!reply) return null

  return (
    <ReplyContext.Provider
      value={{
        reply,
        author,
        myReply,
        canEdit,
        canDelete,
        isEditing,
        setIsEditing,
      }}
    >
      <div className={replyClassName}>
        <div
          className={[
            "action-drawer p-r py-1",
            Boolean(reply.pending) && "reply-bubble--pending",
            isEditing ? "py-2" : "reply-bubble",
            myReply && "o-1",
          ]
            .filter(Boolean)
            .join(" ")
            .trim()}
          style={{ width: isEditing ? "75%" : "auto" }}
        >
          {children}
        </div>
        {showControls && <Controls />}
      </div>
    </ReplyContext.Provider>
  )
}

Reply.propTypes = {
  reply: apiResource,
  author: apiResource.isRequired,
  children: node.isRequired,
  canEdit: bool.isRequired,
  canDelete: bool.isRequired,
  myReply: bool.isRequired,
}

Reply.Message = Message

function Message() {
  const { reply, isEditing } = useContext(ReplyContext)
  const { message, content_updated_at, expanded_links } = reply.attributes
  const plainMessage = unlinkify(message).trim()
  if (plainMessage.length < 1) return null

  if (isEditing) return <Editing />
  const { messageFragments, dataUUID } = parseHTML(message)
  const { first, middle, last = [] } = arrayParts(messageFragments)

  return (
    <div
      data-uuid={dataUUID}
      className={reply.pending ? "reply-message-pending" : "reply-message"}
    >
      {reply.pending && reply.hasFiles && (
        <img
          className="m-3"
          src={spinner}
          style={{
            opacity: 0.5,
            height: "20px",
            width: "20px",
          }}
          alt="Loading"
        />
      )}
      <LeadingLink messageFragments={first} expanded_links={expanded_links} />
      {middle
        .map((fragments) => fragments.join(" "))
        .map((content, idx) => (
          <p
            className="middle-fragment"
            key={idx}
            dangerouslySetInnerHTML={{ __html: sanitize(linkify(content)) }}
          />
        ))}
      <TrailingLink messageFragments={last} expanded_links={expanded_links} />
      {content_updated_at && (
        <em style={{ fontSize: "0.7em", opacity: 0.8 }}>(edited)</em>
      )}
    </div>
  )
}

function LeadingLink({ messageFragments, expanded_links = {} }) {
  const { leadingLink, messageString, trailingLink } = convertToExpandedLinks(
    messageFragments,
    expanded_links,
  )
  const paragraphContent =
    leadingLink.url.length > 0
      ? [messageString, trailingLink.url].join(" ")
      : [leadingLink.url, messageString, trailingLink.url].join(" ")
  return (
    <>
      {leadingLink.url.length > 0 && <ExpandedLink {...leadingLink} />}
      {paragraphContent.trim().length > 0 && (
        <p
          style={{ margin: 0 }}
          dangerouslySetInnerHTML={{
            __html: sanitize(linkify(paragraphContent)),
          }}
        />
      )}
    </>
  )
}

LeadingLink.propTypes = {
  expanded_links: object,
  messageFragments: arrayOf(string).isRequired,
}

function TrailingLink({ messageFragments, expanded_links = {} }) {
  const { leadingLink, messageString, trailingLink } = convertToExpandedLinks(
    messageFragments,
    expanded_links,
  )
  const paragraphContent =
    trailingLink.url.length > 0
      ? [leadingLink.url, messageString].join(" ")
      : [leadingLink.url, messageString, trailingLink.url].join(" ")
  return (
    <>
      {paragraphContent.trim().length > 0 && (
        <p
          className="trailing-fragment"
          dangerouslySetInnerHTML={{
            __html: sanitize(linkify(paragraphContent)),
          }}
        />
      )}
      {trailingLink.url.length > 0 && <ExpandedLink {...trailingLink} />}
    </>
  )
}

TrailingLink.propTypes = {
  expanded_links: object,
  messageFragments: arrayOf(string).isRequired,
}

function initialEditingState(message) {
  return {
    message,
    inFlight: false,
    errors: [],
  }
}

const SET_MESSAGE = "SET_MESSAGE"
const SET_IN_FLIGHT = "SET_IN_FLIGHT"
const SET_ERRORS = "SET_ERRORS"

function editingReducer(state, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      }
    case SET_IN_FLIGHT:
      return {
        ...state,
        inFlight: action.payload,
      }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      throw new Error(`Unrecognized action: ${action.type}`)
  }
}

function Editing() {
  const { ingest } = useContext(RepliesDataContext)
  const { reply, setIsEditing } = useContext(ReplyContext)
  const { message: persistedMessage } = reply.attributes
  const origMessage = unlinkify(persistedMessage).trim()
  const [{ message, inFlight, errors }, dispatch] = useReducer(
    editingReducer,
    initialEditingState(origMessage),
  )

  function handleSubmit(e) {
    e.preventDefault()

    if (message === origMessage) {
      setIsEditing(false)
      return
    }

    const payload = {
      data: {
        attributes: {
          message: prepareMessageForSubmitting(message),
        },
      },
    }

    dispatch({ type: SET_ERRORS, payload: [] })
    dispatch({ type: SET_IN_FLIGHT, payload: true })

    sessionApiClient
      .patch(`${reply.links.self}?include=author`, payload)
      .then((json) => ingest(json))
      .then(() => setIsEditing(false))
      .catch(({ errors }) => dispatch({ type: SET_ERRORS, payload: errors }))
      .finally(() => dispatch({ type: SET_IN_FLIGHT, payload: false }))
  }

  function cancelEditing(e) {
    e.preventDefault()

    if (message === origMessage) {
      setIsEditing(false)
      return
    }

    if (confirm("Are you sure you want to cancel?")) {
      setIsEditing(false)
    }
  }

  return (
    <>
      {inFlight && (
        <img
          src={spinner}
          style={{
            backgroundColor: "var(--color-tint10)",
            display: "block",
            height: "20px",
            left: -32,
            marginTop: -10,
            opacity: 1,
            position: "absolute",
            top: "50%",
            width: "20px",
            zIndex: 1,
          }}
          alt="Loading"
        />
      )}
      <form onSubmit={handleSubmit} className="d-f fd-c">
        <textarea
          value={message}
          onChange={(e) =>
            dispatch({ type: SET_MESSAGE, payload: e.target.value })
          }
          disabled={inFlight}
          className="messaging__edit-reply-input"
        />
        <div className="pt-1 ml-a">
          <button
            type="button"
            disabled={inFlight}
            onClick={cancelEditing}
            className="link-btn fs-13"
            aria-label="Cancel editing reply"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={inFlight}
            className="primary-btn minor-btn btn ml-2"
            aria-label="Submit edited reply"
          >
            Save changes
          </button>
        </div>
      </form>
      <Errors>{errors}</Errors>
    </>
  )
}

function ExpandedLink(props) {
  const { title, image, description, url } = props
  const header = title || url
  const detail = header === url ? description : description || url

  return (
    <ExpandedLinkContainer url={url}>
      {image && (
        <img
          src={image}
          className="expanded-link__image"
          alt={description || ""}
        />
      )}
      <div>
        <div className="expanded-link__title">
          <Heading level={1} size={3} text={header} />
        </div>
        <div className="d-g expanded-link__content">
          <div className="fs-4 truncate">{detail}</div>
        </div>
      </div>
    </ExpandedLinkContainer>
  )
}

ExpandedLink.propTypes = {
  title: string,
  image: string,
  description: string,
  url: string.isRequired,
}

function ExpandedLinkContainer({ children, url }) {
  return (
    <a
      href={url}
      target="_blank"
      className="expanded-link__container"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

ExpandedLinkContainer.propTypes = {
  children: node.isRequired,
  url: string.isRequired,
}

function Controls() {
  const navigate = useNavigate()
  const { del } = useContext(RepliesDataContext)
  const { reply, setIsEditing, canEdit, canDelete } = useContext(ReplyContext)
  const { base_path } = useContext(CurrentGroupContext)
  const replyPath = `${base_path}/messages/${
    reply.relationships.topic.data.id
  }/replies/${reply.id}`

  function handleDelete() {
    if (confirm("Are you sure you want to remove this message?")) {
      del(reply.id)
      sessionApiClient.del(reply.links.self)
    }
  }

  return (
    <div className="reply-controls mx-2">
      <Menu>
        <MenuButton className="text-btn minor-compact-btn btn">
          <Icon symbol="churchCenter#three-dots" />
        </MenuButton>
        <MenuList className="dropdown__menu">
          <MenuItem
            className="dropdown__item"
            onSelect={() => navigate(replyPath)}
          >
            View Reactions
          </MenuItem>
          {canEdit && (
            <MenuItem
              className="dropdown__item"
              onSelect={() => setIsEditing(true)}
            >
              Edit
            </MenuItem>
          )}
          {canDelete && (
            <MenuItem className="dropdown__item" onSelect={handleDelete}>
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </div>
  )
}

function Errors({ children }) {
  return children.map(({ detail }) => (
    <div key={detail} className="reply-error d-f ai-c jc-c">
      {detail}
    </div>
  ))
}

Errors.propTypes = {
  children: arrayOf(shape({ detail: string })),
}
