import { map } from "lodash"
import { object } from "prop-types"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import classNames from "classnames"

export default function ScheduleTimes({ groupedTimes }) {
  return (
    <div className="f-1 d-f fd-c g-1">
      <Subheading level={2} size={5} text="My Times" />
      <div className="gc-tint9 p-2 br-4p f-1">
        {map(groupedTimes, (times, heading) => (
          <div
            key={heading}
            className={classNames({
              "mb-2": heading !== Object.keys(groupedTimes).pop(),
            })}
          >
            <div className="mb-4p">
              <Heading level={3} size={4} text={heading} />
            </div>
            {map(times, ({ id, displayName }) => (
              <div className="mb-4p" key={id}>
                {displayName}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

ScheduleTimes.propTypes = {
  times: object,
}
