export const colors = {
  topaz: "var(--color-topaz)",
  turquoise: "var(--color-turquoise)",
  emerald: "var(--color-emerald)",
  ruby: "var(--color-ruby)",
  marigold: "var(--color-marigold)",
  tint0: "var(--color-tint0)",
  tint1: "var(--color-tint1)",
  tint2: "var(--color-tint2)",
  tint3: "var(--color-tint3)",
  tint4: "var(--color-tint4)",
  tint5: "var(--color-tint5)",
  tint6: "var(--color-tint6)",
  tint7: "var(--color-tint7)",
  tint8: "var(--color-tint8)",
  tint9: "var(--color-tint9)",
  tint10: "var(--color-tint10)",
  brand: "var(--color-brand)",
  brandGradientStart: "var(--color-brand-gradient-start)",
  brandGradientEnd: "var(--color-brand-gradient-end)",
}
