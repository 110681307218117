import { css } from "@emotion/react"
import { useState } from "react"
import { func, string } from "prop-types"
import { Icon } from "source/shared/components"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import spinner from "source/svg/spinner.svg"

ProfileAvatar.propTypes = {
  avatar: string.isRequired,
  name: string,
  personPath: string.isRequired,
  reloadProfile: func.isRequired,
}

export function ProfileAvatar({ avatar, name, reloadProfile, personPath }) {
  const [editing, setEditing] = useState(false)
  const [updating, setUpdating] = useState(false)

  const uploadAvatar = async (newAvatar) => {
    try {
      setEditing(false)
      setUpdating(true)
      const { data } = await sessionApiClient.uploadFile(newAvatar)
      await updatePersonAvatar(data[0].id)
      reloadProfile()
      setUpdating(false)
    } catch {
      setUpdating(false)
      alert("Something went wrong. Please try again.")
    }
  }

  const removeAvatar = async () => {
    try {
      setEditing(false)
      setUpdating(true)
      await updatePersonAvatar(null)
      reloadProfile()
      setUpdating(false)
    } catch {
      alert("Something went wrong. Please try again.")
    }
  }

  const updatePersonAvatar = (avatar) => {
    return sessionApiClient.patch(personPath, {
      data: { attributes: { avatar: avatar } },
    })
  }

  return (
    <>
      <div className="p-r mr-2 w-8 h-8 o-h">
        {updating ? (
          <div
            className="d-f ai-c jc-c w-8 h-8 circle"
            style={{
              backgroundColor: "var(--color-tint5)",
              border: "2px solid var(--color-tint5)",
            }}
          >
            <img
              src={spinner}
              className="w-50% h-50%"
              css={{ opacity: 0.5 }}
              alt="Loading"
            />
          </div>
        ) : (
          <Menu isExpanded={editing}>
            <MenuButton
              className="gc-tint10"
              css={css`
                border: 2px solid var(--color-tint5);
                width: 64px;
                height: 64px;
                border-radius: 32px;
                outline: none;
                padding: 0;
              `}
              onClick={() => setEditing(!editing)}
            >
              <img
                className="circle d-b"
                src={`${avatar}?g=128x128%23`}
                alt={`Avatar for ${name}`}
              />
              <div
                className="d-f ai-c jc-c p-a b-0 r-0 w-3 h-3 c-tint1"
                css={css`
                  background-color: var(--color-tint6);
                  border-radius: 12px;
                `}
              >
                <Icon
                  symbol="general#pencil"
                  className="d-b"
                  style={{ width: 12, height: 12 }}
                />
              </div>
            </MenuButton>
            <MenuList className="dropdown__menu">
              <MenuItem>
                <label
                  className="fs-4"
                  css={{ cursor: "pointer" }}
                  htmlFor="avatarUpload"
                  style={{
                    margin: "-0.25rem -0.75rem",
                    padding: "0.25rem 0.75rem",
                  }}
                >
                  Upload photo
                </label>
              </MenuItem>
              <MenuItem onSelect={removeAvatar}>
                <span className="c-ruby fs-4">Remove</span>
              </MenuItem>
            </MenuList>
            <input
              id="avatarUpload"
              css={css`
                border: 0px;
                clip: rect(0px, 0px, 0px, 0px);
                height: 1px;
                overflow: hidden;
                padding: 0px;
                white-space: nowrap;
                width: 1px;
                position: absolute !important;
              `}
              onChange={({ target: { files } }) => uploadAvatar(files[0])}
              type="file"
              accept="image/*"
            />
          </Menu>
        )}
      </div>
    </>
  )
}
