import { useContext } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { HardRefresh } from "source/shared/routingUtils"
import { ServerRenderedProps } from "source/shared/contexts/ServerRenderedProps"
import { WideLayout } from "source/Layout"
import Event from "source/registrations/event"
import Events, {
  UnknownFilterEvents,
  FilteredEvents,
} from "source/registrations/events"
import MyRegistrations from "source/registrations/myRegistrations"
import Registration from "source/registrations/registrations"
import Reservations from "source/registrations/reservations"
import {
  useOrganizationSetting,
  useOrganizationProxyPaths,
} from "source/registrations/hooks/useOrganization"

export function Registrations() {
  const { paths } = useContext(ServerRenderedProps)
  const proxiedPaths = useOrganizationProxyPaths()
  const canUseChurchCenterWeb = useOrganizationSetting("churchCenterWeb")
  const canUseChurchCenterWebRegistrations = useOrganizationSetting(
    "churchCenterWebRegistrations",
  )

  if (!canUseChurchCenterWeb) {
    window.location = paths.missing_organization
  }

  if (!canUseChurchCenterWebRegistrations) {
    return <Navigate to="/home" replace />
  }

  return (
    <WideLayout>
      <Routes>
        {proxiedPaths?.map((path) =>
          path === "/profile" ? null : (
            <Route key={path} path={path} element={<HardRefresh />} />
          ),
        )}
        <Route path="/profile" element={<MyRegistrations />} />
        <Route
          path="/profile_registrations/future"
          element={
            <MyRegistrations.PaginatedRegistrations timeframe="upcoming" />
          }
        />
        <Route
          path="/profile_registrations/ongoing"
          element={
            <MyRegistrations.PaginatedRegistrations timeframe="ongoing" />
          }
        />
        <Route
          path="/profile_registrations/past"
          element={<MyRegistrations.PaginatedRegistrations timeframe="past" />}
        />

        <Route
          path="/signups/:filter/:filterId"
          element={<UnknownFilterEvents />}
        />
        <Route
          path="/signups/campus/:campusId/category/:categoryId"
          element={<FilteredEvents />}
        />
        <Route path="/signups/:eventId" element={<Event />} />
        <Route path="/signups" element={<Events />} />

        <Route
          path="/events/:filter/:filterId"
          element={<UnknownFilterEvents />}
        />
        <Route
          path="/events/campus/:campusId/category/:categoryId"
          element={<FilteredEvents />}
        />
        <Route path="/events/:eventId" element={<Event />} />
        <Route path="/events" element={<Events />} />

        <Route path="*" element={<Events />} />

        <Route
          path="/:eventSignupPath/:eventId/reservations/*"
          element={<Reservations />}
        />
        <Route
          path="/registrations/:registrationId/*"
          element={<Registration />}
        />
      </Routes>
    </WideLayout>
  )
}
