import { map } from "lodash"
import { object } from "prop-types"

export default function SchedulePlanNotes({ planNotes }) {
  return (
    <div className="mb-3">
      <h2 className="h5 tt-u mb-2">My Notes</h2>
      {map(Object.keys(planNotes), (noteCategory) => (
        <div key={noteCategory} className="mb-2">
          <h3 className="h4 mb-4p">{noteCategory}</h3>
          {map(planNotes[noteCategory], (note) => (
            <div className="mb-4p">{note.content}</div>
          ))}
        </div>
      ))}
    </div>
  )
}

SchedulePlanNotes.propTypes = {
  planNotes: object,
}
