import { useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "source/shared/components"

SubscriberCounter.propTypes = {
  subscribersCount: PropTypes.number,
}
export default function SubscriberCounter({ subscribersCount }) {
  return subscribersCount ? (
    <div
      className="badge"
      style={{
        position: "absolute",
        top: "8px",
        right: "8px",
        zIndex: "1",
        padding: "4px 8px",
        background: "rgba(33,33,33, 0.4)",
        color: "white",
      }}
    >
      <Icon symbol="general#show-eye" className="c-tint10 mr-1 p-r t-2p" />
      {subscribersCount}
    </div>
  ) : (
    <div />
  )
}
