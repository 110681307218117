import { useContext } from "react"
import { WindowDimensionsCtx } from "../contexts/WindowDimensionsProvider"

export const BREAKPOINTS = {
  XXL: 1600,
  XL: 1200,
  LG: 960,
  MD: 720,
  SM: 600,
  XS: 480,
}
export const useWindowDimensions = () => useContext(WindowDimensionsCtx)
