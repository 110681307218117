import { Route, Routes } from "react-router-dom"
import { Error404 } from "source/shared/components"
import { Household } from "./Household"
import { HouseholdsIndex } from "./HouseholdsIndex"
import { HouseholdsNew } from "./HouseholdsNew"
import { HouseholdMemberEdit } from "./HouseholdMemberEdit"
import { HouseholdMemberNew } from "./HouseholdMemberNew"

export function Households() {
  return (
    <Routes>
      <Route path="/" element={<HouseholdsIndex />} />
      <Route path="/new" element={<HouseholdsNew />} />
      <Route path="/:householdId" element={<Household />} />
      <Route
        path="/:householdId/household-members/:householdMemberId/contact-and-info/edit"
        element={<HouseholdMemberEdit />}
      />
      <Route
        path="/:householdId/household-members/new"
        element={<HouseholdMemberNew />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
