import { func, shape, string, node } from "prop-types"
import { Heading } from "@planningcenter/doxy-web"
import NameField from "./NameField"
import PersonInfoField from "./PersonInfoField"
import {
  useWindowDimensions,
  BREAKPOINTS,
} from "source/shared/hooks/useWindowDimensions"
import classNames from "classnames"

const AVAILABLE_FIELDS = ["name", "emailAddress", "phoneNumber", "address"]

const PersonInfoForm = ({
  options,
  heading,
  onChange,
  onCancel,
  person = null,
  existingPerson = null,
  subheading = null,
}) => {
  const { width: screenWidth } = useWindowDimensions()
  const isSmAndDown = screenWidth <= BREAKPOINTS.SM

  if (!person) return null

  const availableFields = AVAILABLE_FIELDS.filter(
    (field) => !(isSmAndDown && field === "name"),
  )

  return (
    <div className="mt-3">
      <Heading level={2} size={4} text={heading} />
      {subheading && <p className="c-tint2 mt-4p">{subheading}</p>}

      <div
        className={classNames("d-f g-2 action-drawer", { "fd-c": isSmAndDown })}
      >
        <div className="d-f g-2 fsh-0">
          <img
            src={`${existingPerson.avatarUrl}?g=200x200`}
            className="w-6 h-6 circle d-b"
            alt={` Profile of ${person.name}`}
          />
          {isSmAndDown && (
            <NameField
              id="responsiblePerson"
              person={person}
              existingPerson={existingPerson}
              onChange={onChange}
              onCancel={onCancel}
              isRequired={options["name"] === "required"}
              isOptional={options["name"] === "optional"}
            />
          )}
        </div>
        <div className="w-100% d-f fd-c g-1">
          {availableFields.map((field) => (
            <PersonInfoField
              key={field}
              id="responsiblePerson"
              person={person}
              rowLayout
              existingPerson={existingPerson}
              field={field}
              onChange={onChange}
              onCancel={onCancel}
              requirement={options[field]}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

PersonInfoForm.propTypes = {
  options: shape({
    name: string,
    emailAddress: string,
    phoneNumber: string,
    address: string,
  }).isRequired,
  heading: string.isRequired,
  person: shape({
    name: string,
    avatarUrl: string,
  }),
  existingPerson: shape({}),
  subheading: node,
  onChange: func,
  onCancel: func,
}

export default PersonInfoForm
