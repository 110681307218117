import { css } from "@emotion/react"
import { useReducer } from "react"
import { useSession } from "source/shared/hooks/useSession"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useNavigate, useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { GenderSelect } from "source/myChurchCenter/Profile/GenderSelect"
import { LocationSelect } from "source/myChurchCenter/Profile/FormElements/LocationSelect"
import {
  DateSelector,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"

const MARITAL_STATUS_OPTIONS_PATH = "/people/v2/marital_statuses"
const GENDER_OPTIONS_PATH = `/people/v2/genders`

const UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME"
const UPDATE_LAST_NAME = "UPDATE_LAST_NAME"
const UPDATE_EMAIL_ADDRESS = "UPDATE_EMAIL_ADDRESS"
const UPDATE_PHONE_LOCATION = "UPDATE_PHONE_LOCATION"
const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER"
const UPDATE_MARITAL_STATUS = "UPDATE_MARITAL_STATUS"
const UPDATE_GENDER = "UPDATE_GENDER"
const UPDATE_BIRTHDATE = "UPDATE_BIRTHDATE"
const UPDATE_ANNIVERSARY = "UPDATE_ANNIVERSARY"

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      }
    case UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      }
    case UPDATE_EMAIL_ADDRESS:
      return {
        ...state,
        email: { ...state.email, address: action.payload },
      }
    case UPDATE_PHONE_LOCATION:
      return {
        ...state,
        phone: { ...state.phone, location: action.payload },
      }
    case UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phone: { ...state.phone, number: action.payload },
      }
    case UPDATE_MARITAL_STATUS:
      return {
        ...state,
        maritalStatus: action.payload,
      }
    case UPDATE_GENDER:
      return {
        ...state,
        gender: action.payload,
      }
    case UPDATE_BIRTHDATE:
      return {
        ...state,
        birthdate: action.payload,
      }
    case UPDATE_ANNIVERSARY:
      return {
        ...state,
        anniversary: action.payload,
      }
    default:
      return state
  }
}

const initialState = {
  firstName: "",
  lastName: "",
  email: { address: "", location: "Home" },
  phone: { number: "", location: "Mobile" },
  gender: "",
  maritalStatus: "",
  birthdate: "",
  anniversary: "",
}

export function NewAdultHouseholdMemberForm() {
  const {
    data: { id: currentPersonId },
  } = useSession()

  const navigate = useNavigate()
  const { householdId } = useParams()
  const maritalStatusOptions = useApiRead(MARITAL_STATUS_OPTIONS_PATH)
  const genderOptions = useApiRead(GENDER_OPTIONS_PATH)

  const {
    data: {
      attributes: { name: householdName },
    },
  } = useApiRead(
    `/people/v2/people/${currentPersonId}/households/${householdId}?fields[Household]=name`,
  )

  const [state, dispatch] = useReducer(reducer, initialState)

  const saveHouseholdMember = () => {
    const selectedGenderOption = genderOptions.data.find(
      (genderOpt) => genderOpt.attributes.value === state.gender,
    )

    const payload = {
      data: {
        type: "HouseholdMember",
        attributes: {
          first_name: state.firstName,
          last_name: state.lastName,
          gender_id: selectedGenderOption?.id || "",
          marital_status_id: state.maritalStatus,
          anniversary: state.anniversary,
          birthdate: state.birthdate,
        },
      },
    }

    const url = `/people/v2/people/${currentPersonId}/households/${householdId}/household_members`
    sessionApiClient.post(url, payload).then((resp) => {
      const newPersonId = resp.data.relationships.person.data.id
      const emailPayload = {
        data: {
          type: "Email",
          attributes: {
            address: state.email.address,
            location: state.email.location,
          },
        },
      }

      const phonePayload = {
        data: {
          type: "PhoneNumber",
          attributes: {
            number: state.phone.number,
            location: state.phone.location,
          },
        },
      }

      const emailUrl = `/people/v2/people/${newPersonId}/emails`
      const phoneUrl = `/people/v2/people/${newPersonId}/phone_numbers`

      const recordsToCreate = state.phone.number
        ? [
            sessionApiClient.post(emailUrl, emailPayload),
            sessionApiClient.post(phoneUrl, phonePayload),
          ]
        : [sessionApiClient.post(emailUrl, emailPayload)]

      Promise.all(recordsToCreate).then(() => {
        navigate(`/households/${householdId}`)
      })
    })
  }

  const canSubmit =
    state.firstName !== "" &&
    state.lastName !== "" &&
    state.email.address !== ""

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        saveHouseholdMember()
      }}
    >
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl="/me/profile"
          pageText="My profile & settings"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage
          linkUrl={`/households/${householdId}`}
          pageText={householdName}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Add an adult" isActive />
      </BreadcrumbNavigation>
      <div className="action-drawer mb-1 mt-2">
        <div className="action-drawer">
          <div className="d-f@sm mb-2 ai-fe">
            <div className="d-f fg-1" css={styles.wrapper}>
              <div className="mb-1 pr-2@sm fg-1" css={styles.names}>
                <p style={{ fontWeight: "500" }}>
                  Name <span style={{ color: "#c8211f" }}>&#x2a;</span>
                </p>
                <input
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_FIRST_NAME,
                      payload: e.target.value,
                    })
                  }}
                  placeholder="First name"
                  type="text"
                  value={state.firstName}
                />
              </div>
              <div className="mb-1 fg-1" css={styles.names}>
                <input
                  onChange={(e) => {
                    dispatch({
                      type: UPDATE_LAST_NAME,
                      payload: e.target.value,
                    })
                  }}
                  placeholder="Last name"
                  type="text"
                  value={state.lastName}
                />
              </div>
            </div>
          </div>

          <div className="d-f@sm f_1">
            <div className="mb-1">
              <p style={{ fontWeight: "500" }}>
                Email address <span style={{ color: "#c8211f" }}>&#x2a;</span>
              </p>
              <div styles={{ paddingTop: "16px" }}>
                <div className="d-f@sm ai-c">
                  <div className="f-1">
                    <label htmlFor="id">
                      <span className="screen-reader-text">Email</span>
                      <input
                        id="email"
                        disabled={false}
                        inputMode="email"
                        onChange={(e) => {
                          dispatch({
                            type: UPDATE_EMAIL_ADDRESS,
                            payload: e.target.value,
                          })
                        }}
                        pattern="^\S+@\S+\.\S+$"
                        placeholder="name@email.com"
                        type="text"
                        value={state.email.address}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-f@sm f_1">
            <div className="mb-1">
              <p style={{ fontWeight: "500" }}>Phone number</p>
              <div styles={{ paddingTop: "16px" }}>
                <div className="d-f@sm ai-c g-1">
                  <LocationSelect
                    id="phone_number_location"
                    value={state.phone.location}
                    onChange={(value) => {
                      dispatch({
                        type: UPDATE_PHONE_LOCATION,
                        payload: value,
                      })
                    }}
                    additionalOptions={["Mobile"]}
                  />
                  <div className="f-1 pb-1">
                    <input
                      id="phone_number"
                      inputMode="number"
                      onChange={(e) => {
                        dispatch({
                          type: UPDATE_PHONE_NUMBER,
                          payload: e.target.value,
                        })
                      }}
                      placeholder="888-555-1212"
                      type="text"
                      value={state.phone.number}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-f@sm f_1 mb-2">
            <div className="mb-1">
              <GenderSelect
                value={state.gender}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_GENDER,
                    payload: value,
                  })
                }}
              />
            </div>
          </div>

          <p style={{ fontWeight: "500" }}>Marital status</p>
          <div className="d-f@sm f_1">
            <div className="mb-1">
              <select
                className="select"
                id="marital_status"
                name="marital_status"
                onChange={(e) => {
                  dispatch({
                    type: UPDATE_MARITAL_STATUS,
                    payload: e.target.value,
                  })
                }}
                value={state.maritalStatus}
              >
                <option value="">Select...</option>
                {maritalStatusOptions.data.map(
                  ({ id, attributes: { value } }) => (
                    <option key={`marital-status-option-${value}`} value={id}>
                      {value}
                    </option>
                  ),
                )}
              </select>
            </div>
          </div>

          <div className="d-f@sm f_1 mb-2 mt-2">
            <div className="mb-1 pr-2@sm">
              <DateSelector
                initialValue=""
                label={<div>Birthdate</div>}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_BIRTHDATE,
                    payload: value,
                  })
                }}
              />
            </div>
            <div className="mb-1">
              <DateSelector
                initialValue=""
                label={<div>Anniversary</div>}
                onChange={(value) => {
                  dispatch({
                    type: UPDATE_ANNIVERSARY,
                    payload: value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-f mt-2" css={styles.button}>
        <button disabled={!canSubmit} type="submit" className="btn compact-btn">
          Add to household
        </button>
      </div>
    </form>
  )
}

const styles = {
  wrapper: css`
    flex-direction: row;
    align-items: flex-end;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  `,
  names: css`
    @media (max-width: 600px) {
      width: 100%;
    }
  `,
  button: css`
    flex-direction: row-reverse;

    @media (max-width: 600px) {
      justify-content: center;
    }
  `,
}
