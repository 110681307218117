import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { func, number } from "prop-types"
import { Icon } from "source/shared/components"
import { useDirectoryStatus } from "source/directory/hooks/useDirectoryStatus"

import { css } from "@emotion/react"
import { colors } from "source/shared/colors"

export function DirectoryAlerts() {
  const directoryStatus = useDirectoryStatus()
  const location = useLocation()
  const navigate = useNavigate()
  const justRemovedProfile = /status=removed_profile/.test(location.search)

  const showNotSharedAlert = directoryStatus !== "participant"

  return justRemovedProfile ? (
    <RemovedInfoAlert
      onClear={() => navigate(location.pathname, { replace: true })}
      showForMilliseconds={5000}
    />
  ) : showNotSharedAlert ? (
    <NotSharedAlert />
  ) : null
}

function NotSharedAlert() {
  return (
    <div className="alert info-alert d-f ai-c">
      <span className="mr-1 p-r t-2p c-topaz">
        <Icon symbol="general#hide-eye" aria-hidden />
      </span>
      <p className="unstyled">
        You haven&apos;t shared your information yet.{" "}
        <Link to="../edit" className="c-topaz">
          Share it now.
        </Link>
      </p>
    </div>
  )
}

RemovedInfoAlert.propTypes = {
  onClear: func,
  showForMilliseconds: number,
}
RemovedInfoAlert.defaultProps = { onClear: () => {} }

function RemovedInfoAlert({ onClear, showForMilliseconds }) {
  useEffect(() => {
    if (onClear && showForMilliseconds) {
      const timeoutFn = setTimeout(() => onClear(), showForMilliseconds)
      return () => clearTimeout(timeoutFn)
    }
  }, [onClear, showForMilliseconds])

  return (
    <div className="alert success-alert d-f ai-c">
      <span className="mr-1 p-r t-2p c-emerald">
        <Icon symbol="general#outlined-circle-check" />
      </span>
      <p className="unstyled">
        You have successfully removed your information from the directory.
      </p>
    </div>
  )
}
