import { useEffect } from "react"
import { object, func, arrayOf, number, string, shape } from "prop-types"
import { Subheading } from "@planningcenter/doxy-web"
import SelectionsItem from "source/registrations/reservations/SelectionsItem"

const MultiAttendeeSelection = ({
  selections,
  attendeeTypes,
  onSelectionChange,
  step,
}) => {
  useEffect(() => {
    const selected = selections.filter(({ count }) => count > 0)
    if (selected.length > 0) return

    const availableSelections = selections.filter(
      ({ waitlist, status }) =>
        (!status && !waitlist) || (status === "Waitlist available" && waitlist),
    )

    if (availableSelections.length === 1 && !availableSelections[0].waitlist) {
      onSelectionChange({
        id: availableSelections[0].id,
        count: 1,
      })
    }
  }, [selections.length])

  const visibleWaitlistSelections = selections
    .filter(({ waitlist }) => !!waitlist)
    .filter((selection) => {
      const standardSelection = selections.find(
        ({ id }) => id === Number(`${selection.attendeeTypeId}0`),
      )
      return (
        standardSelection.count >= standardSelection.availableCount ||
        selection.isWaitlistOnly
      )
    })

  return (
    <>
      {selections
        .filter(({ waitlist }) => !waitlist)
        .map((selection) => (
          <SelectionsItem
            key={selection.id}
            selection={selection}
            onSelectionChange={onSelectionChange}
            isCurrent={step.isCurrent}
            attendeeType={attendeeTypes.find(
              (at) => at.id === selection.attendeeTypeId,
            )}
          />
        ))}

      {visibleWaitlistSelections.length > 0 && (
        <div className="mt-2">
          <Subheading level={3} text="Waitlist Available" />
        </div>
      )}

      {visibleWaitlistSelections.map((selection) => (
        <SelectionsItem
          key={selection.id}
          selection={selection}
          onSelectionChange={onSelectionChange}
          isCurrent={step.isCurrent}
          attendeeType={attendeeTypes.find(
            (at) => at.id === selection.attendeeTypeId,
          )}
        />
      ))}
    </>
  )
}

MultiAttendeeSelection.propTypes = {
  step: object,
  selections: arrayOf(shape({ id: number, name: string, count: number })),
  onSelectionChange: func,
  attendeeTypes: arrayOf(object),
}

export default MultiAttendeeSelection
