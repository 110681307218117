import { useRef, useState } from "react"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { func } from "prop-types"
import spinner from "source/svg/spinner.svg"

export default function ConfirmModal({ onClose, onHandleSubmit }) {
  const cancelRef = useRef(null)
  const [inFlight, setInFlight] = useState(false)

  const handleSubmit = () => {
    setInFlight(true)
    onHandleSubmit()
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef}>
      <AlertDialogLabel>Sign up?</AlertDialogLabel>
      <AlertDialogDescription>
        <p>This will sign you up for the selected positions.</p>
        <div className="d-f jc-fe mt-3">
          <button className="text-btn mr-2 " onClick={onClose} ref={cancelRef}>
            Cancel
          </button>
          <button className="btn" onClick={handleSubmit}>
            {inFlight ? (
              <img
                src={spinner}
                alt="Saving"
                style={{ height: "1em", width: "1em" }}
              />
            ) : (
              <div>Sign up</div>
            )}
          </button>
        </div>
      </AlertDialogDescription>
    </AlertDialog>
  )
}

ConfirmModal.propTypes = {
  onClose: func,
  onHandleSubmit: func,
}
