import { Component } from "react"
import moment from "moment-timezone"
import ReactDatePicker from "react-datepicker"
import { arrayOf, bool, func, instanceOf, shape, string } from "prop-types"
import { NULL_RECURRENCE } from "source/services/hooks/useBlockout"
import { ordinalize } from "source/shared/number"

const DEFAULT_INTERVAL = "exact_day_of_month"
const FREQUENCY_OPTIONS = [
  { text: "None", value: "no_repeat" },
  { text: "Every", value: "every_1" },
  { text: "Every two", value: "every_2" },
  { text: "Every three", value: "every_3" },
  { text: "Every four", value: "every_4" },
  { text: "Every five", value: "every_5" },
  { text: "Every six", value: "every_6" },
  { text: "Every seven", value: "every_7" },
  { text: "Every eight", value: "every_8" },
]

function RepeatSelect({ value, options, onChange, disabled, ...restProps }) {
  const handleChange = (event) => onChange(event.target.value)

  return (
    <select
      className="select"
      value={value}
      aria-label={`recurrence_${value}`}
      onChange={handleChange}
      onBlur={handleChange}
      disabled={disabled}
      {...restProps}
    >
      {options.map(({ text, value }) => (
        <option key={text} value={value}>
          {text}
        </option>
      ))}
    </select>
  )
}
RepeatSelect.propTypes = {
  value: string,
  options: arrayOf(
    shape({
      text: string,
      value: string,
    }),
  ),
  onChange: func,
  disabled: bool,
}

export class RecurrenceSelectGroup extends Component {
  constructor(props) {
    super(props)
    this.state = { hasExpiration: !!props.recurrence.until }
  }

  static propTypes = {
    recurrence: shape({
      frequency: string,
    }),
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
    onChange: func,
    disabled: bool,
  }

  handleSimpleSelectChange = (field) => {
    return (value) => {
      let attributes

      if (value === "no_repeat") {
        attributes = NULL_RECURRENCE
      } else if (this.props.recurrence.frequency === "no_repeat") {
        attributes = { [field]: value, period: "weekly" }
      } else {
        attributes = { [field]: value }
      }

      this.props.onChange(attributes)
    }
  }

  handlePeriodChange = (value) => {
    // interval only applies when period = "monthly"
    const interval = value === "monthly" ? DEFAULT_INTERVAL : null

    this.props.onChange({ period: value, interval })
  }

  handleHasExpirationChange = () => {
    const hadExpiration = this.state.hasExpiration
    const defaultUntil = this.props.endDate

    this.setState({ hasExpiration: !this.state.hasExpiration }, () =>
      hadExpiration
        ? this.props.onChange({ until: null })
        : this.props.onChange({ until: defaultUntil }),
    )
  }

  getSpecificDayIntervalOption() {
    const { startDate, endDate } = this.props
    const startDateText = ordinalize(startDate.getDate())
    const endDateText = ordinalize(endDate.getDate())
    let text

    const endsAtMoment = moment.utc(this.endsAt)

    if (endsAtMoment.isSame(endsAtMoment, "day")) {
      text = `on the ${startDateText} day`
    } else {
      text = `from the ${startDateText} to the ${endDateText}`
    }

    return { value: DEFAULT_INTERVAL, text }
  }

  getPeriodOptions = () => {
    const { recurrence } = this.props
    const s = recurrence.frequency === "every_1" ? "" : "s"
    const dayText = `day${s}`
    const weekText = `week${s}`
    const monthText = `month${s}`
    const yearText = `year${s}`

    return [
      { value: "daily", text: dayText },
      { value: "weekly", text: weekText },
      { value: "monthly", text: monthText },
      { value: "yearly", text: yearText },
    ]
  }

  getIntervalOptions() {
    const { startDate } = this.props
    let startMonth = moment(startDate)
    let currMonth = moment(startDate)
    let currWeek = Math.ceil(currMonth.date() / 7)
    let startWeekday = startMonth.format("dddd")
    let options = [this.getSpecificDayIntervalOption()]

    if (currWeek < 5) {
      options.push({
        value: `week_of_month_${currWeek}`,
        text: `on the ${ordinalize(currWeek)} ${startWeekday}`,
      })
    }

    if (currMonth.add(1, "week").month() !== startMonth.month()) {
      options.push({
        value: `week_of_month_last`,
        text: `on the last ${startWeekday}`,
      })
    }

    return options
  }

  render() {
    const { disabled, recurrence, endDate } = this.props
    const { hasExpiration } = this.state
    const isRecurring = recurrence.frequency !== "no_repeat"

    return (
      <div className="d-f fw-w">
        <div className="mr-1 mb-1">
          <RepeatSelect
            disabled={disabled}
            value={recurrence.frequency}
            options={FREQUENCY_OPTIONS}
            onChange={this.handleSimpleSelectChange("frequency")}
          />
        </div>

        {isRecurring && (
          <>
            <div className="pr-1 mb-1">
              <RepeatSelect
                disabled={disabled}
                value={recurrence.period}
                options={this.getPeriodOptions()}
                onChange={this.handlePeriodChange}
              />
            </div>
            {recurrence.period === "monthly" ? (
              <div className="pr-1 mb-1">
                <RepeatSelect
                  disabled={disabled}
                  value={recurrence.interval}
                  options={this.getIntervalOptions()}
                  onChange={this.handleSimpleSelectChange("interval")}
                />
              </div>
            ) : null}
            <div className="pr-1 mb-1">
              <RepeatSelect
                disabled={disabled}
                value={hasExpiration}
                options={[
                  { text: "forever", value: false },
                  { text: "until", value: true },
                ]}
                onChange={this.handleHasExpirationChange}
              />
            </div>
            {hasExpiration ? (
              <div>
                <ReactDatePicker
                  disabled={disabled}
                  wrapperClassName=""
                  showPopperArrow={false}
                  selected={recurrence.until}
                  onChange={this.handleSimpleSelectChange("until")}
                  minDate={endDate}
                  dateFormat="M/d/yyyy"
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    )
  }
}
