import { string, node } from "prop-types"

CenterLabelBorder.propTypes = {
  label: string,
  className: string,
  children: node,
}

export function CenterLabelBorder({ label, className, children }) {
  const styles = {
    container: {
      boxShadow: "inset 0 1px 2px var(--color-tint9)",
      borderStyle: "solid",
      borderColor: "var(--color-tint5)",
      borderWidth: children ? "1px" : "1px 0 0 0",
      padding: children ? "1rem" : "0",
      borderRadius: children ? "4px" : "0",
      position: "relative",
    },
    labelContainer: {
      position: "absolute",
      height: "24px",
      top: "-14px",
      left: "0",
      right: "0",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
    },
    label: {
      backgroundColor: "white",
      padding: "0.25rem 0.5rem",
      color: "var(--color-tint3)",
    },
  }

  return (
    <div className={className} css={styles.container}>
      <div css={styles.labelContainer}>
        <div css={styles.label}>{label}</div>
      </div>
      {children}
    </div>
  )
}
