import {
  node,
  bool,
  array,
  shape,
  string,
  number,
  func,
  object,
  arrayOf,
} from "prop-types"

export const questionShape = shape({
  id: number,
  type: string,
  questionGroup: string,
  kind: string,
  position: number,
  allowMultiple: bool,
  question: string,
  required: bool,
  description: string,
  choices: array,
})

export const reservationStepPropTypes = {
  session: object,
  organization: object,
  reservation: object,
  attendeeTypes: array,
  addOns: array,
  registrantQuestions: arrayOf(questionShape),
  households: array,
  maxHouseholdSize: number,
  people: array,
  attendees: array,
  isProcessing: bool.isRequired,
  isAddOnsEnabled: bool,
  onIsProcessing: func.isRequired,
  onCancel: func,
  onEdit: func,
  onReservationSave: func.isRequired,
  onPaymentComplete: func,
  completeRegistrationId: string,
  error: node,
  step: shape({
    name: string,
    next: string,
    prev: string,
    isCurrent: bool,
    isComplete: bool,
    isIncomplete: bool,
    isUnconfirmed: bool,
    isEditable: bool,
  }).isRequired,
  className: string.isRequired,
}
