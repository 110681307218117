import { Link } from "react-router-dom"
import { array, bool, func } from "prop-types"

Footer.propTypes = {
  directoryFieldData: array,
  directoryPrivacySettings: array,
  disabled: bool,
  isCurrentlyInDirectory: bool,
  onClick: func,
}

export function Footer({
  directoryFieldData,
  directoryPrivacySettings,
  disabled,
  isCurrentlyInDirectory,
  onClick,
}) {
  if (isCurrentlyInDirectory) {
    return (
      <div className="d-f jc-sb my-2">
        <button
          className="btn secondary-btn destroy-btn"
          type="submit"
          disabled={disabled}
          onClick={() =>
            onClick(
              [],
              directoryPrivacySettings.map((pref) => ({
                ...pref,
                directoryPrivacyModeEnabled: false,
                directoryPrivateContactMode: "disabled",
              })),
            )
          }
        >
          Remove all
        </button>
        <button
          className="btn secondary-btn"
          type="submit"
          disabled={disabled}
          onClick={() => onClick(directoryFieldData, directoryPrivacySettings)}
        >
          Update profile
        </button>
      </div>
    )
  } else {
    return (
      <div className="d-f fd-c ai-c my-3">
        <button
          className="btn mb-2"
          type="submit"
          disabled={disabled}
          onClick={() => onClick(directoryFieldData, directoryPrivacySettings)}
        >
          Join directory
        </button>
        <Link to="/directory" className="c-tint3 text-btn">
          Ask me later
        </Link>
      </div>
    )
  }
}
