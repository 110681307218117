import PropTypes from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { CardGrid, Icon, Skeleton } from "source/shared/components"
import { useMomentInTimeZone } from "source/calendar/hooks/useMomentInTimeZone"
import { useQuery } from "@tanstack/react-query"
import { NoEventsPlaceholder } from "source/registrations/events"
import qs from "query-string"
import { EventItem } from "source/registrations/events"

const MAX_EVENTS_TO_RENDER = 6

function useEventsQuery(params) {
  return useQuery({
    queryKey: [params],
    queryFn: useEventsQuery.queryFn,
    select: useEventsQuery.selector,
    retry: false,
  })
}
useEventsQuery.queryFn = function ({ queryKey }) {
  const search = qs.stringify(queryKey[0], { arrayFormat: "comma" })
  return sessionApiClient.get(`/registrations/v2/events?${search}`)
}
useEventsQuery.selector = function (data) {
  const { data: events, meta } = data

  return {
    events,
    meta: { hasMore: !!meta.next },
  }
}

function buildParams(block, momentInTZ) {
  let search = {
    order: "starts_at",
    "fields[Event]":
      "name,featured,logo_url,event_time,starts_at,ends_at,registration_state",
    per_page: MAX_EVENTS_TO_RENDER,
    utm_campaign: "pco_content_block",
    utm_medium: "web",
    utm_source: "church_center",
  }

  if (block.attributes.event_times_scope === "all") {
    search.filter = "unarchived,published"
  } else {
    search.filter = `unarchived,published,${block.attributes.event_times_scope}`
  }

  if (block.attributes.campus_tag_id) {
    search["where[campus]"] = block.attributes.campus_tag_id
  }

  if (block.attributes.category_tag_id) {
    search["where[category]"] = block.attributes.category_tag_id
  }

  if (block.attributes.only_featured) {
    search["where[featured]"] = 1
  }

  switch (block.attributes.limit_by) {
    case "count": {
      search.per_page = block.attributes.limit_count
      break
    }

    case "days": {
      const endDate = momentInTZ()
        .endOf("day")
        .add(block.attributes.limit_days, "days")
      const startDate = momentInTZ().endOf("hour")

      search["where[starts_before]"] = endDate.toISOString()
      search["where[ends_after]"] = startDate.toISOString()
      break
    }
  }

  return search
}

Registrations.propTypes = {
  block: PropTypes.object.isRequired,
}
export function Registrations(block) {
  const styles = {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "640px",
    marginBottom: "24px",
    gap: "12px",
  }

  return (
    <div style={styles} className="page-block">
      {block.attributes.headline_enabled && (
        <h2
          style={{
            fontSize: 30,
            textAlign: block.attributes.headline_alignment,
          }}
        >
          {block.attributes.headline}
        </h2>
      )}

      <GridView block={block} />
    </div>
  )
}

GridView.propTypes = {
  block: PropTypes.object.isRequired,
}
function GridView({ block }) {
  const momentInTZ = useMomentInTimeZone()
  const params = buildParams(block, momentInTZ)

  const { data, isSuccess, isError } = useEventsQuery(params)
  const {
    events,
    meta: { hasMore },
  } = data || { events: [], meta: { hasMore: false } }

  if (isError) {
    return (
      <div className="ta-c c-tint2 fs-2">
        <Icon symbol="general#exclamation-triangle" className="mb-1" />
        <div>Error loading events</div>
      </div>
    )
  }

  if (!isSuccess) {
    return (
      <CardGrid
        columns={block.attributes.columns_desktop}
        columnsMobile={block.attributes.columns_mobile}
      >
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
        <Skeleton aspectRatio="308 / 242" />
      </CardGrid>
    )
  }

  if (events.length === 0) {
    return (
      <div className="mt-1">
        <NoEventsPlaceholder />
      </div>
    )
  }

  if (block.attributes.only_featured) {
    events.forEach((event) => {
      event.attributes.featured = false
    })
  }

  return (
    <>
      {events.length > 1 ? (
        <CardGrid>
          {events.map((event) => (
            <EventItem key={event.id} event={event} />
          ))}
        </CardGrid>
      ) : (
        events.map((event) => (
          <div
            key={event.id}
            style={{
              alignSelf: block.attributes.headline_alignment
                .replace("left", "flex-start")
                .replace("right", "flex-end"),
              maxWidth: "320px",
            }}
          >
            <EventItem event={event} />
          </div>
        ))
      )}

      {hasMore && (
        <div style={{ textAlign: block.attributes.headline_alignment }}>
          <LinkToViewMore block={block} />
        </div>
      )}
    </>
  )
}

LinkToViewMore.propTypes = {
  block: PropTypes.object.isRequired,
}
function LinkToViewMore({ block }) {
  const { campus_tag_id, category_tag_id } = block.attributes
  const path = [
    "/registrations/events",
    campus_tag_id && `campus/${campus_tag_id}`,
    category_tag_id && `category/${category_tag_id}`,
  ].filter(Boolean)

  return <a href={path.join("/")}>View all signups</a>
}
