import { useContext } from "react"
import { useParams } from "react-router-dom"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { FiltersContext } from "../contexts/FiltersContext"

export function useInfiniteLoadingGroups() {
  const { params } = useContext(FiltersContext)
  const { groupTypeSlug } = useParams()

  const urlQuery = new URLSearchParams(params)
  urlQuery.append("per_page", 42)
  urlQuery.append("include", "location")

  return useInfiniteLoadingResource(
    `/groups/v2/group_types/${groupTypeSlug}/groups?${urlQuery.toString()}`,
  )
}
