import { useEffect, useState } from "react"
import { pick, omitBy, keys, isEmpty } from "lodash"
import queryString from "query-string"

export function useQueryParamsState(defaultQuery) {
  let initialQuery = { ...defaultQuery, ...parseQueryParams() }

  initialQuery = filterParams(pick(initialQuery, keys(defaultQuery)))

  const [query, setQuery] = useState(initialQuery)
  const updateQuery = (updateFn) =>
    setQuery((prev) => filterParams(updateFn(prev)))

  useEffect(() => {
    updateQueryParams(query)
  }, [query])

  return [query, updateQuery]
}

function filterParams(params) {
  return omitBy(params, isEmpty)
}

function parseQueryParams(string = window.location.search) {
  return queryString.parse(string, {
    arrayFormat: "bracket",
    parseBooleans: true,
  })
}

function updateQueryParams(params) {
  const query = queryString.stringify(params, {
    arrayFormat: "bracket",
    skipNull: true,
  })
  const location = [window.location.pathname, query].filter((i) => i).join("?")
  window.history.replaceState(null, "", location)
}
