import { useState, useRef, useEffect } from "react"
import { string } from "prop-types"
import QRCode from "qrcode"

import { AlertMessage } from "source/shared/components"

QRCodeWithImage.propTypes = {
  imageSrc: string.isRequired,
  url: string.isRequired,
}

export default function QRCodeWithImage({ imageSrc, url }) {
  const [hasError, setHasError] = useState(false)
  const canvasRef = useRef()

  useEffect(() => {
    let canvas = canvasRef.current

    if (canvas) {
      const imageDimensions = { width: 36, height: 36 }
      let context = canvas.getContext("2d")
      let image = new Image()
      image.src = imageSrc

      image.onload = function () {
        context.drawImage(
          image,
          styles.QRCode.width / 2 - imageDimensions.width / 2,
          styles.QRCode.width / 2 - imageDimensions.height / 2,
          imageDimensions.width,
          imageDimensions.height,
        )
      }

      QRCode.toCanvas(canvas, url, styles.QRCode, function (error) {
        if (error) setHasError(true)
      })
    }
  })

  return hasError ? (
    <AlertMessage>
      We tried to create your QR Code, but something went wrong. Please try
      again later.
    </AlertMessage>
  ) : (
    <canvas id="canvas" ref={canvasRef} />
  )
}

const styles = {
  QRCode: {
    width: 216,
  },
}
