import { isArray, isObject, lowerCase, snakeCase, camelCase } from "lodash"

export const isStringEqual = (string1, string2) =>
  lowerCase(string1) === lowerCase(string2)

export const isStringBlank = (value) =>
  value === null || value === undefined || value.toString().trim().length === 0

export const stringToCase = (string, toSnake = false) =>
  toSnake ? snakeCase(string) : camelCase(string)

export const convertKeyCase = (object, { toSnake = false } = {}) => {
  if (isArray(object)) {
    return object.map((v) => convertKeyCase(v, { toSnake }))
  } else if (!isObject(object)) {
    return object
  }

  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      [stringToCase(key, toSnake)]: convertKeyCase(object[key], { toSnake }),
    }
  }, {})
}
