import { useCallback, useReducer, createContext } from "react"
import { node, oneOfType, arrayOf } from "prop-types"
import { uniq, uniqWith } from "lodash/array"
import { groupBy, map, merge, sortBy } from "lodash"
export const CalendarDataContext = createContext()

export const CalendarDataProvider = ({ children }) => {
  const [state, dispatch] = useCalendarReducer()

  const addEvents = useCallback(
    (data) => dispatch({ type: "addData", payload: data }),
    [dispatch],
  )

  const addRequestedUrl = useCallback(
    (url) => dispatch({ type: "addRequestedUrl", payload: url }),
    [dispatch],
  )

  const resetRequestedUrls = useCallback(
    (data) => dispatch({ type: "resetRequestedUrls", payload: data }),
    [dispatch],
  )

  const resetEvents = useCallback(
    (data) => dispatch({ type: "resetEvents", payload: data }),
    [dispatch],
  )

  return (
    <CalendarDataContext.Provider
      value={{
        data: state,
        addEvents,
        addRequestedUrl,
        resetRequestedUrls,
        resetEvents,
      }}
    >
      {children}
    </CalendarDataContext.Provider>
  )
}

export function useCalendarReducer(
  initialCalendarState = {
    events: [],
    included: [],
    links: {},
    lastDateLoaded: {},
    requestedUrls: [],
  },
) {
  function reducer(state = initialCalendarState, action) {
    switch (action.type) {
      case "addData": {
        /*
         Events fetched for month view don't include all fields needed by list view.
         We merge event records by ID so that existing events fetched for month view
         can be enriched with List view event data when the user navigates to list view.
        */

        const allEvents = [...state.events, ...action.payload.data]
        const eventsById = groupBy(allEvents, "id")
        const mergedEvents = map(eventsById, (eventsForId) => {
          if (eventsForId.length > 1) {
            // merge events with the same id into a single event.
            merge(eventsForId[0], eventsForId[1])
          }
          return eventsForId[0]
        })
        const events = sortBy(mergedEvents, (e) => e.attributes.starts_at) // unsorted events cause rendering issues in month view
        const included = uniqWith(
          [...state.included, ...action.payload.included],
          (one, two) => one.type === two.type && one.id === two.id,
        )
        const links = {
          ...state.links,
          [action.payload.linkKey]: action.payload.links,
        }

        const startsAt =
          action.payload.data[action.payload.data?.length - 1]?.attributes
            ?.starts_at
        const lastDateLoaded = {
          ...state.lastDateLoaded,
          [action.payload.linkKey]: startsAt,
        }

        return { ...state, events, links, included, lastDateLoaded }
      }
      case "addRequestedUrl":
        return {
          ...state,
          requestedUrls: uniq([...state.requestedUrls, action.payload]),
        }
      case "resetRequestedUrls": {
        return {
          ...state,
          requestedUrls: [],
        }
      }
      case "resetEvents": {
        return {
          ...state,
          events: [],
        }
      }
      default:
        return state
    }
  }

  return useReducer(reducer, initialCalendarState)
}

CalendarDataProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}
