import PropTypes from "prop-types"
import {
  Menu,
  MenuButton as ReachMenuButton,
  MenuList as ReachMenuList,
  MenuItem,
} from "@reach/menu-button"
import { maxEventCount } from "./config"
import { ActionBar } from "source/shared/components"

export const AttendanceReportFilterBar = ({
  filter,
  setFilter,
  order,
  setOrder,
}) => {
  return (
    <ActionBar.Pane className="ai-s">
      <ActionBar.Row className="g-2">
        <Menu>
          <MenuButton>{filter.title}</MenuButton>
          <MenuList>
            {filterOptions.map((option, index) => (
              <MenuItem
                key={option.value}
                onSelect={() => setFilter(filterOptions[index])}
              >
                {option.title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton>{order.title}</MenuButton>
          <MenuList>
            {orderOptions.map((option, index) => (
              <MenuItem
                key={option.value}
                onSelect={() => setOrder(orderOptions[index])}
              >
                {option.title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </ActionBar.Row>
    </ActionBar.Pane>
  )
}

AttendanceReportFilterBar.propTypes = {
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  order: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setOrder: PropTypes.func.isRequired,
}

function MenuButton({ ...restProps }) {
  return <ReachMenuButton className="ta-l select w-a" {...restProps} />
}

function MenuList({ ...restProps }) {
  return (
    <ReachMenuList
      className="dropdown__menu"
      style={{ "--dropdown-menu--font-size": "1rem" }}
      {...restProps}
    />
  )
}

export const filterOptions = [
  { title: `Last ${maxEventCount} events`, value: "latest_events" },
  { title: "Last month", value: "last_1_month" },
  { title: "Last 3 months", value: "last_3_months" },
  { title: "Last 6 Months", value: "last_6_months" },
  { title: "All events", value: "all" },
]

export const orderOptions = [
  { title: "Activity (Recently missed)", value: "attended_percentage" },
  { title: "First name", value: "first_name" },
  { title: "Last name", value: "last_name" },
]
