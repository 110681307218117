import {
  useApiRead,
  SessionApiResource,
} from "source/shared/SessionApiResource"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"

export const DIRECTORY_STATUS_PATH =
  "/people/v2/me?fields[Person]=directory_status"

function getDirectoryStatus(user) {
  if (!user.errors) {
    return user.data.attributes.directory_status
  }
}

export function useDirectoryStatus() {
  const user = useApiRead(DIRECTORY_STATUS_PATH)
  return getDirectoryStatus(user)
}

export function useReloadDirectoryStatus() {
  return useCacheHydrate(SessionApiResource, DIRECTORY_STATUS_PATH)
}
