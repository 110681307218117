import { Navigate, Route, Routes, useParams } from "react-router-dom"
import { FullscreenLayout } from "source/Layout"
import Expired from "source/registrations/reservations/Expired"
import New from "source/registrations/reservations/New"
import Reservation from "source/registrations/reservations/Reservation"
import Error404 from "source/registrations/Error404"
import { useEventFeature } from "source/registrations/hooks/useEventFeature"
import { useSession } from "source/shared/hooks/useSession"
import { useEvent } from "source/registrations/hooks/useEvent"
import { transformResponse } from "@planningcenter/api-client"
import { ReservationContextProvider } from "source/registrations/reservations/ReservationContext"

const Reservations = () => {
  const { eventId } = useParams()
  const event = useEvent(eventId)
  const isUpdatedFlow = useEventFeature("unified_detailed_signups_ccw", eventId)
  const currentPerson = transformResponse(useSession())

  if (event.isError) return <Error404 />

  const {
    data: { name, registrationType, guestMode },
  } = event

  const session = {
    ...currentPerson.data,
    isGuest: !currentPerson.meta.authenticated,
    isAuthenticated: currentPerson.meta.authenticated,
  }

  if (registrationType === "detailed" && !isUpdatedFlow) {
    return (
      <Navigate
        to={`/registrations/events/${eventId}/registrations/new`}
        replace
      />
    )
  }

  if (guestMode === "disabled" && session.isGuest) {
    return <Navigate to={`/login?return=${window.location.href}`} replace />
  }

  return (
    <FullscreenLayout>
      <Routes>
        <Route path="/expired" element={<Expired eventName={name} />} />
        <Route path="/new" element={<New />} />
        <Route
          path="/:reservationUuid"
          element={
            <ReservationContextProvider>
              <Reservation session={session} />
            </ReservationContextProvider>
          }
        />
      </Routes>
    </FullscreenLayout>
  )
}

export default Reservations
