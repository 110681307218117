import {
  SessionApiResource,
  useApiRead,
} from "source/shared/SessionApiResource"
import { useInfiniteLoadingResource } from "source/shared/hooks/useInfiniteLoadingResource"
import { useCacheHydrate } from "source/shared/react-cache-but-its-a-context"

const MY_GROUPS_URL = "/groups/v2/me/groups"

export function useFirstPageOfMyGroups() {
  return useApiRead(MY_GROUPS_URL)
}

export function useInfiniteLoadingMyGroups() {
  return useInfiniteLoadingResource(MY_GROUPS_URL)
}

export function useReloadMyGroups() {
  return useCacheHydrate(SessionApiResource, MY_GROUPS_URL)
}
