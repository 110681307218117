import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useEffect, useMemo, useState } from "react"
import { difference, uniq } from "lodash"
import { buildUrl } from "source/shared/buildUrl"

export const useMemberAttendances = ({ groupId, personId, eventIds }) => {
  const [loadedEventIds, setLoadedEventIds] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const neededEventIds = useMemo(
    () => difference(eventIds, loadedEventIds),
    [eventIds, loadedEventIds],
  )

  const needsMoreData = neededEventIds.length > 0
  const readyToLoadMoreData = !loading && needsMoreData

  useEffect(() => {
    if (readyToLoadMoreData) {
      setLoading(true)
      const url = buildUrl(`/groups/v2/me/groups/${groupId}/attendances`, {
        filter: "completed,by_event,by_person",
        event_ids: neededEventIds.join(","),
        person_ids: personId,
        per_page: neededEventIds.length.toString(),
      })

      sessionApiClient
        .get(url, {})
        .then((json) => {
          setData([...data, ...(json.data || [])])
        })
        .finally(() => {
          setLoadedEventIds(uniq([...loadedEventIds, ...neededEventIds]))
          setLoading(false)
        })
    }
  }, [
    readyToLoadMoreData,
    neededEventIds,
    data,
    loadedEventIds,
    groupId,
    personId,
  ])

  return { data, loading, loadedEventIds }
}
