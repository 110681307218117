import { useState, useEffect } from "react"
import { keyBy } from "lodash"
import {
  getAnswers,
  postAnswer,
  patchAnswer,
  destroyAnswer,
} from "source/registrations/api/form_answers"
import { filteredAnswers } from "./useAnswers"

const setRequestType = (answer) => {
  let answerRequest = postAnswer

  if (answer.id) {
    answerRequest = answer.answers.length === 0 ? destroyAnswer : patchAnswer
  }

  return answerRequest
}

const formatAttributes = ({
  answer,
  questions,
  registerableId,
  eventId,
  attendeeId,
}) => {
  const question = questions.find(
    (question) => question.id === answer.questionId,
  )

  return {
    registerableId,
    attendeeId,
    eventId,
    answers: answer.answers,
    kind: question.kind,
    multipleSelect: question.allowMultiple,
    choices: question.choices,
    questionId: question.id,
    ...(answer.id && { id: answer.id }),
  }
}

export const useAnswers = ({
  questions,
  answers: initialAnswers,
  registerableId,
  eventId,
  attendeeId,
}) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [registerableAnswers, setRegisterableAnswers] = useState([])
  const [answers, setAnswers] = useState({})

  useEffect(() => {
    setRegisterableAnswers(initialAnswers)
    updateAnswers(initialAnswers)
  }, [initialAnswers])

  const handleIsValid = () => {
    const requiredQuestions = questions.filter((question) => question.required)

    return requiredQuestions.every(
      (question) => answers[question.id]?.answers?.length > 0,
    )
  }

  const handleSave = async () => {
    if (!handleIsValid()) {
      const validationError = "Required Questions can not be blank."
      setError(validationError)
      return { errors: validationError }
    }

    setIsLoading(true)
    setError(null)

    const response = await Promise.all(
      Object.values(answers)
        .filter((answer) => answer.isModified)
        .map((answer) => {
          const answerRequest = setRequestType(answer)

          const answerAttributes = formatAttributes({
            answer,
            questions,
            registerableId,
            eventId,
            attendeeId,
          })

          return answerRequest(answerAttributes)
        }),
    )

    const responseErrors = response.filter((res) => res.errors)

    if (responseErrors.length > 0) {
      setError(responseErrors[0].errors[0].detail)
      setIsLoading(false)
      return responseErrors[0]
    } else {
      const newAnswers = await getAnswers({ registerableId, attendeeId })
      setRegisterableAnswers(newAnswers.data)
      updateAnswers(newAnswers.data)
      setIsLoading(false)
      return newAnswers
    }
  }

  const handleChange = (questionId, answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = {
        ...prevAnswers,
        [questionId]: {
          ...prevAnswers[questionId],
          answers: answer,
          questionId,
          isModified: true,
        },
      }
      return filteredAnswers(newAnswers)
    })
  }

  const handleReset = () => {
    error && setError(null)
    updateAnswers(registerableAnswers)
  }

  const updateAnswers = (newAnswers) => {
    let questionAnswers = newAnswers ? newAnswers : []
    questionAnswers = newAnswers.map((answer) => {
      return {
        id: answer.id,
        answers: answer.answers,
        questionId: answer.questionId,
        isModified: false,
      }
    })

    setAnswers(keyBy(questionAnswers, "questionId"))
  }

  return {
    error,
    questions,
    answers,
    handleIsValid,
    isLoading,
    handleSave,
    handleChange,
    handleReset,
  }
}
