import { ordinalize } from "source/shared/number"
import { daysInMonth } from "./date"

const NEVER = "never"
const WEEKLY = "weekly"
const BIWEEKLY = "biweekly"
const DEPRECATED_MONTHLY = "monthly"
const MONTHLY_ON_WEEKDAY = "monthly_on_weekday"
const MONTHLY_ON_DAY = "monthly_on_day"
const YEARLY = "yearly"

export const frequencies = {
  NEVER,
  WEEKLY,
  BIWEEKLY,
  DEPRECATED_MONTHLY,
  MONTHLY_ON_WEEKDAY,
  MONTHLY_ON_DAY,
  YEARLY,
}

/**
 * For a given date, returns the available repeating frequencies
 *
 * @param {Object} Args
 * @param {Date} Args.date
 * @param {boolean} Args.includeNever
 * @returns {{value: string, label: string}[]}
 */
export const frequencyForDate = ({ date, includeNever }) => {
  const monthDay = ordinalize(date.getDate())
  const weekdayName = date.toLocaleDateString(undefined, { weekday: "long" })

  return [
    ...(includeNever ? [{ value: NEVER, label: "Never" }] : []),
    { value: WEEKLY, label: "Weekly" },
    { value: BIWEEKLY, label: "Every other week" },
    {
      value: MONTHLY_ON_WEEKDAY,
      label: `Monthly (on selected ${weekdayName}s)`,
    },
    { value: MONTHLY_ON_DAY, label: `Monthly (on the ${monthDay})` },
    { value: YEARLY, label: "Yearly" },
  ]
}

const ordinalWeekNumber = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  "-1": "Last",
}

const defaultSequences = [
  ["1"],
  ["2"],
  ["3"],
  ["1", "3"],
  ["4"],
  ["2", "4"],
  ["5"],
]

/**
 *
 * @param {string[][]} newSequence Follows the same pattern as defaultSequence
 * @returns boolean
 */
const isUniqueSequence = (newSequence) =>
  !defaultSequences.some((seq) => seq.toString() === newSequence.toString())

/**
 *
 * @param {Date} date
 * @param {string} givenSequence Optional
 * @returns {{value: string, label: string}[]}
 */
export const weeklyOptions = (date, givenSequence) => {
  const dayOfMonth = date.getDate()
  const currentWeek = (Math.floor((dayOfMonth - 1) / 7) + 1).toString()
  const weekdayName = date.toLocaleDateString(undefined, { weekday: "long" })
  const isLastWeekOfMonth = daysInMonth(date) - dayOfMonth < 7
  const givenSequenceArray = givenSequence?.split(",")

  function includesCurrentWeek(...weeks) {
    return weeks.some((week) => week === currentWeek)
  }

  let sequences = [
    ...defaultSequences,
    ...(givenSequenceArray && isUniqueSequence(givenSequenceArray)
      ? [givenSequenceArray]
      : []),
  ].filter((seq) => includesCurrentWeek(...seq))

  if (isLastWeekOfMonth) {
    sequences = [...sequences, ["-1"]]
  }

  let output = sequences.map((seq) => ({
    value: seq.join(","),
    label: seq
      .map((s) => ordinalWeekNumber[s])
      .join(" & ")
      .concat(` ${weekdayName}`),
  }))

  return output
}

/**
 * We are deprecating the "monthly" frequency and it is now split out
 * into two separate options. This can be removed once we stop sending
 * "monthly" as an option from the groups church-center api.
 *
 * @param {string} frequencyName
 * @param {string} weekCount
 * @returns string
 */
export const resolveDeprecatedFrequency = (frequencyName, weekCount) => {
  if (frequencyName === DEPRECATED_MONTHLY) {
    if (weekCount?.length) return MONTHLY_ON_WEEKDAY
    return MONTHLY_ON_DAY
  }

  return frequencyName
}
