import { array, func, object } from "prop-types"
import { filter, sortBy } from "lodash"
import { useParams } from "react-router-dom"
import AddOnsFormFields from "./AddOnsFormFields"
import { useEvent } from "source/registrations/hooks/useEvent"

const AddOnsForm = ({ addOns, step, attendee, onChange }) => {
  const { eventId } = useParams()

  const {
    data: { currency },
  } = useEvent(eventId)

  const sortedAddOns = sortBy(addOns, "position")

  const addOnsForAttendeeType = filter(sortedAddOns, (addOn) =>
    attendee.attendeeType.availableAddOns.includes(addOn.id),
  )

  return (
    <div className={`${step.isCurrent ? "mb-3" : "c-tint2"}`}>
      {step.isCurrent && (
        <>
          {attendee.waitlist && (
            <div className="mb-1 fs-4">
              Additional options are not available for waitlisted attendees
            </div>
          )}

          {!attendee.waitlist && addOnsForAttendeeType.length > 0
            ? addOnsForAttendeeType.map((addOn) => (
                <div key={addOn.id} className="mt-1">
                  <AddOnsFormFields
                    attendee={attendee}
                    addOn={addOn}
                    onAddOnChange={onChange}
                    currencySymbol={currency}
                  />
                </div>
              ))
            : null}
        </>
      )}
    </div>
  )
}

AddOnsForm.propTypes = {
  addOns: array,
  step: object,
  attendee: object,
  onChange: func,
}

export default AddOnsForm
