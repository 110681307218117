import { createContext, useState, useEffect, ReactNode } from "react"
import { useDebouncedCallback } from "source/shared/hooks/useDebounce"

type WindowDimensions = {
  breakpoint?: number | null
  height: number
  width: number
}

export const WindowDimensionsCtx = createContext<WindowDimensions>(
  {} as WindowDimensions,
)

const WindowDimensionsProvider = ({ children }: { children: ReactNode }) => {
  const [dimensions, setDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: null,
  })
  const handleResize = useDebouncedCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
    document.documentElement.style.setProperty(
      "--window-inner-height",
      window.innerHeight + "px",
    )
  }, 200)

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize, { passive: true })
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}

export default WindowDimensionsProvider
