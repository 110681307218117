import { arrayOf, number, object, shape, string } from "prop-types"
import DOMPurify from "dompurify"
import { Icon } from "source/shared/components"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import { useSession } from "source/shared/hooks/useSession"
import { Heading } from "@planningcenter/doxy-web"
import { find } from "lodash"

SuccessfulSubmitMessage.propTypes = {
  attachments: arrayOf(
    shape({
      form_field_id: string,
      files: arrayOf(
        shape({
          lastModified: number,
          name: string,
          size: number,
          type: string,
        }),
      ),
    }),
  ),
  formId: string,
  submission: object,
}
function SuccessfulSubmitMessage({ attachments, formId, submission }) {
  const {
    data: { attributes: userInfo },
  } = useSession(false)

  const {
    ends_at,
    event_name,
    event_recurrence = null,
    location = null,
    notes,
    starts_at,
  } = submission.formSubmission
  const groupedCustomFieldValues = submission.groupedCustomFieldValues

  return (
    <>
      <div className="alert success-alert">
        <div className="d-f">
          <span className="mr-1 c-emerald fs-0 lh-1.333 pt-2p">
            <Icon symbol="general#check-circle" />
          </span>
          <div className="d-f fd-c">
            <div className="mb-1">
              <Heading
                level={1}
                size={2}
                text="Thanks! Your request has been submitted."
              />
            </div>
            <p className="mb-0">Your submission details are below.</p>
          </div>
        </div>
      </div>

      <Section>
        <div>{userInfo.name}</div>
        <div>{userInfo.email_address}</div>
      </Section>

      <Section>
        <Heading level={2} size={3} text="Submission details" />
        <div className="mt-1 mb-2">
          <div className="c-tint2">Event name</div>
          {event_name}
        </div>
        <div className="mt-1 mb-2">
          <div className="c-tint2">Date & time</div>
          <EventDatetimeDisplay startsAt={starts_at} endsAt={ends_at} />
        </div>
        {location && (
          <div className="mt-1 mb-2">
            <div className="c-tint2">Location</div>
            {location}
          </div>
        )}
        {event_recurrence && (
          <div className="mt-1 mb-2">
            <div className="c-tint2">Recurrence</div>
            {event_recurrence}
          </div>
        )}
        {notes && (
          <div className="mt-1" style={{ wordWrap: "break-word" }}>
            <div className="c-tint2">Notes</div>
            <Notes>{notes}</Notes>
          </div>
        )}
        <div className="mt-1 mb-2">
          {groupedCustomFieldValues.map((customFieldValue) => {
            return (
              <div
                key={customFieldValue.id}
                className="mt-1"
                style={{ wordWrap: "break-word" }}
              >
                <div className="c-tint2">{customFieldValue.label}</div>
                <FieldSubmissionValue
                  submission={customFieldValue}
                  {...{ attachments }}
                />
              </div>
            )
          })}
        </div>
      </Section>

      <a href={`/calendar/forms/${formId}`}>Submit another event request</a>
    </>
  )
}

FileSubmissionValue.propTypes = {
  attachments: arrayOf(
    shape({
      form_field_id: string,
      files: arrayOf(
        shape({
          lastModified: number,
          name: string,
          size: number,
          type: string,
        }),
      ),
    }),
  ),
  fieldId: number,
  fileSubmission: shape({ filename: string, url: string }),
}
function FileSubmissionValue({ attachments, fieldId, fileSubmission }) {
  const attachmentsForField = find(attachments, {
    form_field_id: fieldId.toString(),
  })

  if (!attachmentsForField) {
    return null
  }

  const attachmentForSubmission = find(attachmentsForField.files, {
    name: fileSubmission.filename,
  })

  const { filename } = fileSubmission
  const extension = filename.split(".").pop()
  const url = window.URL.createObjectURL(attachmentForSubmission)

  return (
    <div className="my-1" key={url}>
      <a
        className="d-f ai-c"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
          style={{
            width: 28,
            height: 28,
            flex: "0 0 28px",
            border: "1px solid",
          }}
        >
          {["jpg", "jpeg", "gif", "png"].includes(extension.toLowerCase()) ? (
            <img
              alt={filename}
              width={28}
              height={28}
              style={{ objectFit: "cover" }}
              src={url}
            />
          ) : (
            <div className="fs-4 c-tint3 mt-2p">
              <Icon symbol="general#outlined-generic-file" />
            </div>
          )}
        </div>
        <span className="overflow-text">{filename}</span>
        <span className="ml-1 fs-4 mt-4p">
          <Icon symbol="general#new-window" />
        </span>
      </a>
    </div>
  )
}

FieldSubmissionValue.propTypes = {
  attachments: arrayOf(
    shape({
      form_field_id: string,
      files: arrayOf(
        shape({
          lastModified: number,
          name: string,
          size: number,
          type: string,
        }),
      ),
    }),
  ),
  submission: shape({
    id: number,
    label: string,
    sequence: number,
    values: arrayOf(string),
  }),
}
function FieldSubmissionValue({ attachments, submission }) {
  return submission.values.map((value) => {
    if (typeof value === "object") {
      return (
        <FileSubmissionValue
          key={submission.id}
          fieldId={submission.id}
          fileSubmission={value}
          {...{ attachments }}
        />
      )
    } else {
      return <div key={value}>{value}</div>
    }
  })
}

Notes.propTypes = { children: string }
function Notes({ children }) {
  const withLineBreaks = DOMPurify.sanitize(children, { ALLOWED_TAGS: ["br"] })

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: withLineBreaks.replace(/\n/g, "<br />"),
      }}
    />
  )
}

function Section(props) {
  return (
    <section
      css={{ borderBottom: "1px solid var(--color-tint5)" }}
      className="py-2 mb-2"
      {...props}
    />
  )
}

export default SuccessfulSubmitMessage
