import { useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { groupBy } from "lodash"
import { node } from "source/shared/prop_types"
import {
  CurrentGroupContext,
  CurrentEventContext,
} from "source/groups/my/groups"
import { date } from "@churchcenter/datetime-fmt"
import TabView from "source/groups/my/groups/TabView"
import { Heading } from "@planningcenter/doxy-web"

export default function EventsCancel() {
  const { base_path, event, refetchEvent } = useContext(CurrentEventContext)
  const { base_path: group_base_path } = useContext(CurrentGroupContext)
  const navigate = useNavigate()
  const [notifyMembers, setNotifyMembers] = useState(false)
  const [message, setMessage] = useState("")
  const [inFlight, setInFlight] = useState(false)
  const [errors, setErrors] = useState({})
  const messageRef = useRef()

  const { starts_at, name } = event.attributes

  const [shortMonthName, dayNumber] = date(starts_at, {
    style: "abbreviated",
  }).split(" ")

  useEffect(() => {
    if (notifyMembers) {
      messageRef.current.focus()
    }
  }, [notifyMembers])

  function handleSubmit(e) {
    e.preventDefault()

    setInFlight(true)

    const attributes = notifyMembers ? { message } : {}
    sessionApiClient
      .post(`${event.links.self}/cancel`, { data: { attributes } })
      .then(() => refetchEvent())
      .then(() => setInFlight(false))
      .then(() => navigate(`${group_base_path}/events`))
      .catch(({ errors }) => setErrors(groupBy(errors, "source.parameter")))
  }

  return (
    <TabView showActions={false}>
      <div>
        <header className="d-f mb-4 pt-1 ai-c">
          <div className="mr-2 event-detail__cal">
            <div className="ta-c event-cal">
              <div className="event-cal__month">{shortMonthName}</div>
              <div className="event-cal__day">{dayNumber}</div>
            </div>
          </div>
          <Heading level={1} size={2} text={name} />
        </header>

        <form className="w-100%" onSubmit={handleSubmit}>
          <div className="action-drawer mb-1">
            <div className="mb-2">
              Are you sure you want to cancel this event?
            </div>
            <div>
              <input
                type="checkbox"
                id="notifyMembers"
                className="checkbox"
                checked={notifyMembers}
                onChange={() => setNotifyMembers(!notifyMembers)}
              />
              <label htmlFor="notifyMembers" className="checkbox-label">
                Notify members
              </label>
            </div>

            {notifyMembers && (
              <div className="mt-1">
                <textarea
                  className="w-100%"
                  placeholder="Message"
                  ref={messageRef}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows={12}
                />
                {errors.message &&
                  errors.message.map((e) => (
                    <ValidationError key={e.detail}>{e.detail}</ValidationError>
                  ))}
              </div>
            )}
          </div>
          <div className="d-f jc-fe ai-c">
            <div>
              <Link to={base_path} className="btn compact-btn text-btn">
                Go back
              </Link>
              <input
                type="submit"
                className="ml-2 compact-btn btn"
                disabled={inFlight}
                value={notifyMembers ? "Yes, cancel & send" : "Yes, cancel"}
              />
            </div>
          </div>
        </form>
      </div>
    </TabView>
  )
}

function ValidationError({ children }) {
  return <div className="c-ruby">{children}</div>
}

ValidationError.propTypes = {
  children: node.isRequired,
}
