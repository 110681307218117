import { useApiRead } from "source/shared/SessionApiResource"

export default function useMyChuchCenter() {
  const {
    data: {
      attributes: { upcoming: activityDates, pending: pendingItems } = {},
    } = {},
  } = useApiRead("/publishing/v2/me/my_church_center")

  return { activityDates, pendingItems }
}
