import { groupBy } from "lodash"
import { array, object } from "prop-types"
import { Link, Navigate, useParams } from "react-router-dom"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import { Helmet } from "react-helmet-async"
import { useHouseholdFinder } from "source/directory/households/useHouseholdFinder"
import { WideLayout } from "source/Layout"
import {
  Avatar,
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import {
  EmailFields,
  AddressFields,
  PhoneNumberFields,
  PersonalInfoFields,
} from "source/directory/people/Fields"
import { getRelationship } from "source/shared/getRelationship"
import { HouseholdAvatar } from "source/directory/HouseholdAvatar"
import { useLocalStorage } from "source/shared/hooks/useLocalStorage"
import { LAST_VIEW_STORAGE_KEY } from "source/directory/index"

import { css } from "@emotion/react"

export function HouseholdsShow() {
  const { id } = useParams()
  const household = useHouseholdFinder(id)
  const [directoryLastView, _setDirectoryLastView] = useLocalStorage(
    LAST_VIEW_STORAGE_KEY,
    "people",
  )
  if (household.errors) {
    return <Navigate to="/directory" replace /> // We'll go back to directory if household won't load
  }

  const canEditHousehold = household.meta.can_edit

  const people = getRelationship(household, "directory_people")
  const { adults, children } = groupBy(people, (person) =>
    person.attributes.child ? "children" : "adults",
  )

  const householdAvatar = css`
    img {
      border-radius: 4px;
    }
  `
  const memberGrid = css`
    @media (min-width: 720px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(144px, 434px));
      grid-gap: 32px;
    }
  `

  return (
    <WideLayout>
      <Helmet title={household.data.attributes.name} />
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/directory/${directoryLastView}`}
          pageText="Directory"
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText={household.data.attributes.name} isActive />
        {canEditHousehold && (
          <div className="d-n d-b@sm ml-a">
            <Link
              className="ml-a btn secondary-btn minor-btn"
              to="/directory/edit"
            >
              Edit shared details
            </Link>
          </div>
        )}
      </BreadcrumbNavigation>

      {!household.data.attributes.using_default_avatar && (
        <div className="mb-2 mr-2 w-100%" css={householdAvatar}>
          <HouseholdAvatar
            url={household.data.attributes.avatar_url}
            alt={household.data.attributes.name}
            size="large"
          />
        </div>
      )}
      <div className="mb-3">
        <Heading level={1} text={household.data.attributes.name} />
      </div>
      <div css={memberGrid}>
        {adults && (
          <div>
            <div className="mb-2">
              <Subheading level={2} text="Adults" />
            </div>
            <div>
              {adults.map((person) => (
                <Person
                  key={person.id}
                  person={person}
                  included={household.included}
                />
              ))}
            </div>
          </div>
        )}

        {children && (
          <div>
            <div className="mb-2">
              <Subheading level={2} text="Children" />
            </div>
            <div>
              {children.map((person) => (
                <Person
                  key={person.id}
                  person={person}
                  included={household.included}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </WideLayout>
  )
}

Person.propTypes = {
  person: object.isRequired,
  included: array.isRequired,
}

function Person({ person, included }) {
  const {
    attributes: { avatar_url, name, birthday, anniversary },
    id,
  } = person

  return (
    <div className="mb-4" style={{ border: "0" }} key={id}>
      <div className="d-f">
        <div className="mr-2">
          <Link to={`/directory/people/${id}`} className="avatar w-8 h-8 d-f">
            <Avatar url={avatar_url} alt={`profile image for ${name}`} />
          </Link>
        </div>
        <div className="o-h">
          <Link to={`/directory/people/${id}`} className="d-f ai-c f-1">
            <div>
              <Heading level={3} text={name} />
              <span className="c-turquoise fs-4">View profile</span>
            </div>
          </Link>
          <div className="fs-4">
            <EmailFields
              emails={getRelationship({ data: person, included }, "emails")}
            />
            <PhoneNumberFields
              phoneNumbers={getRelationship(
                { data: person, included },
                "phone_numbers",
              )}
            />
            <AddressFields
              addresses={getRelationship(
                { data: person, included },
                "addresses",
              )}
            />
            <PersonalInfoFields birthday={birthday} anniversary={anniversary} />
          </div>
        </div>
      </div>
    </div>
  )
}
