import { Suspense, useContext, useState, useRef } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog"
import { string } from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { useSession } from "source/shared/hooks/useSession"
import { CurrentEventContext } from "source/groups/my/groups"
import TabView from "source/groups/my/groups/TabView"
import EventSecondaryHeader from "source/groups/my/groups/events/EventSecondaryHeader"
import { EventNoteForm } from "source/groups/my/groups/events/EventNoteForm"
import { Spinner } from "source/shared/components"

const NOPE = "NOPE"
const REJECTING = "REJECTING"
const APPROVING = "APPROVING"

export default function NotesEdit() {
  const { noteId } = useParams()
  const currentEvent = useContext(CurrentEventContext)
  const { event, location: eventLocation } = currentEvent
  const url = `${
    event.links.notes
  }/${noteId}?include=owner&fields[Owner]=first_name`

  return (
    <TabView showActions={false}>
      <div>
        <EventSecondaryHeader event={event} location={eventLocation} />
        <Suspense fallback={null}>
          <Fetch url={url} />
        </Suspense>
      </div>
    </TabView>
  )
}

function Fetch({ url }) {
  const { data: currentPerson } = useSession(true)
  const { base_path: eventPath } = useContext(CurrentEventContext)
  const routerLocation = useLocation()
  const navigate = useNavigate()
  const { data, included, errors } = useApiRead(url)
  const notFound = errors?.some((e) => e.status === "404")
  const [showDialog, setShowDialog] = useState(false)
  const [inFlight, setInFlight] = useState(NOPE)
  const cancelRef = useRef()
  const requestInProgress = inFlight === APPROVING || inFlight === REJECTING
  const { returnTo = eventPath } = routerLocation.state || {}

  if (notFound) return null

  const owner = data?.relationships?.owner?.data
  const author = included.find(
    ({ type, id }) => owner?.type === type && owner?.id === id,
  )
  const title =
    author.id === currentPerson.id
      ? "My note"
      : `${author.attributes.first_name}\u2019s note`

  if (!data.attributes.abilities.can_update)
    return <Navigate to={eventPath} replace />

  const errorDeleteText = `
    Uh oh, something went wrong. We could not remove your note.
    Please try again, or contact a church administrator.
  `

  function openModal(e) {
    e.preventDefault()
    setShowDialog(true)
  }

  function closeModal(e) {
    e.preventDefault()
    setShowDialog(false)
  }

  function confirmDelete(e) {
    e.preventDefault()
    setInFlight(REJECTING)
    sessionApiClient
      .del(data.links.self)
      .then(() => navigate(returnTo))
      .catch(() => alert(errorDeleteText))
  }

  return (
    <>
      <EventNoteForm
        destroy={openModal}
        initialBody={data.attributes.body}
        returnTo={returnTo}
        save={(payload) => sessionApiClient.patch(data.links.self, payload)}
        textareaLabel={title}
      />
      {showDialog && (
        <AlertDialog leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ width: "calc(100% - 50px)" }}>
            Delete note
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>Are you sure you want to delete this note?</p>
            <div className="d-f jc-fe mt-3">
              <button
                className="btn compact-btn text-btn mr-1"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className={
                  inFlight === REJECTING
                    ? "btn compact-btn destroy-outline-btn--loading"
                    : "btn compact-btn destroy-btn"
                }
                disabled={requestInProgress}
                onClick={confirmDelete}
              >
                <Spinner
                  alt="Rejecting"
                  opacity={inFlight === REJECTING ? 1 : 0}
                />
                <span style={{ opacity: inFlight === REJECTING ? 0 : 1 }}>
                  Yes, delete
                </span>
              </button>
            </div>
          </AlertDialogDescription>
        </AlertDialog>
      )}
    </>
  )
}
Fetch.propTypes = {
  url: string.isRequired,
}
