import { shape, string } from "prop-types"
import { Link } from "react-router-dom"

const NoCapacityError = ({ error }) => {
  const confirmationLink = `/registrations/registrations/${error.detail}/confirmation`

  return (
    <div className="f-1 container container--wide">
      <h1 className="ta-c mb-2">Whoa, this signup sure is popular!</h1>
      <p className="ta-c">
        <strong>Unfortunately, the event is at capacity again.</strong>
      </p>
      <p>
        Don&rsquo;t worry, you&rsquo;re still on the waitlist and will be
        notified if spots become available again.
      </p>
      <p>
        <strong>No Longer Interested?</strong> If you&rsquo;ve changed your
        mind, you can{" "}
        <Link to={confirmationLink}>remove attendees from the waitlist</Link> so
        you don&rsquo;t receive additional notifications.
      </p>
      <p>
        Thank you for your interest and understanding. We hope to accommodate
        everyone eager to participate!
      </p>
    </div>
  )
}

NoCapacityError.propTypes = {
  error: shape({
    detail: string,
  }),
}

export default NoCapacityError
