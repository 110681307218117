import { shape, bool, string, number } from "prop-types"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"

const Attendee = ({ attendeeSummary }) => {
  const canUseSimplePayments = useOrganizationFlag("simplePayments")

  let selectionDetails = [attendeeSummary.count]

  if (canUseSimplePayments && !attendeeSummary.free) {
    selectionDetails.push(` x ${attendeeSummary.baseCost}`)
  }

  return (
    <div>
      <div
        className={`d-f ai-c jc-sb fw-500 mt-2 mb-4p ${
          attendeeSummary.canceled ? "c-tint3" : ""
        }`}
      >
        <div>{attendeeSummary.selectionName}</div>
        <div>{selectionDetails}</div>
      </div>

      {!attendeeSummary.free && (
        <div className="d-f ai-c jc-sb mb-4p c-tint2">
          <div>Base cost</div>
          <div>{attendeeSummary.baseCost}</div>
        </div>
      )}

      {attendeeSummary.discounts.length > 0 && (
        <>
          <div className="d-f ai-c jc-sb mb-4p c-tint2">
            <div className="fw-500" style={{ color: "initial" }}>
              Discounts
            </div>
            <div>-{attendeeSummary.discountsTotal}</div>
          </div>

          {attendeeSummary.discounts.map((discount) => (
            <div key={discount.name} className="d-f ai-c jc-sb mb-4p c-tint2">
              <div>{discount.name}</div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

Attendee.propTypes = {
  attendeeSummary: shape({
    canceled: bool,
    selectionName: string,
    count: number,
  }),
}

export default Attendee
