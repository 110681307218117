import { api } from "source/registrations/api/client"

export const getAnswers = ({ registerableId, ...options }) => {
  const path = `/registrations/v2/registrations/${registerableId}/answers`
  return api.get(path, options)
}

export const postAnswer = ({ registerableId, ...attributes }) => {
  const path = `/registrations/v2/registrations/${registerableId}/answers`
  return api.post(path, { data: { attributes } })
}

export const patchAnswer = ({
  registerableId,
  id: answerId,
  ...attributes
}) => {
  const path = `/registrations/v2/registrations/${registerableId}/answers/${answerId}`
  return api.patch(path, { data: { attributes } })
}

export const destroyAnswer = ({ registerableId, id: answerId }) => {
  const path = `/registrations/v2/registrations/${registerableId}/answers/${answerId}`
  return api.destroy(path)
}
