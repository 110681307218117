import React, { useEffect } from "react"
import {
  BrowserRouter,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom"
import { Loading } from "source/shared/components"

export function HardRefresh() {
  useEffect(() => {
    !__TEST__ && window.location.replace(location.href)
  })
  return <Loading />
}

export function redirectTo(uri) {
  throw new RedirectRequest(uri)
}

export function Router({ children }) {
  return (
    <BrowserRouter>
      <RedirectRequestErrorBoundary>{children}</RedirectRequestErrorBoundary>
    </BrowserRouter>
  )
}

export function Unproxy() {
  const location = useLocation()
  const params = useParams()
  const pathname = `/${params["*"]}${location.search}`
  return <Navigate to={pathname} replace />
}

function isRedirect(object) {
  return object instanceof RedirectRequest
}

function RedirectRequest(uri) {
  this.uri = uri
}

function RedirectRequestErrorBoundary({ children }) {
  const location = useLocation()

  return (
    <RedirectRequestErrorBoundary.Component location={location}>
      {children}
    </RedirectRequestErrorBoundary.Component>
  )
}
RedirectRequestErrorBoundary.Component = class extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const self = RedirectRequestErrorBoundary.Component // react unbinds this (ref: https://github.com/facebook/react/issues/12612#issuecomment-381332338)

    if (props.location !== state.previous_location) {
      return self.resetState(props)
    }

    return null
  }

  static initialState(props) {
    return {
      isRedirect: false,
      uri: undefined,
      previous_location: props.location,
    }
  }

  static resetState(props) {
    return this.initialState(props)
  }

  constructor(props) {
    super(props)
    this.state = this.constructor.initialState(props)
  }

  componentDidCatch(error, _info) {
    if (!isRedirect(error)) {
      throw error
    }

    this.setState((prev) => ({ ...prev, isRedirect: true, uri: error.uri }))
  }

  render() {
    if (this.state.isRedirect) {
      return <Navigate to={this.state.uri} replace />
    }

    return this.props.children
  }
}
