import { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CurrentGroupContext } from "source/groups/my/groups"
import { TopicsDataContext } from "source/groups/messaging/TopicsDataContext"
import { string } from "source/shared/prop_types"
import { useApiRead } from "source/shared/SessionApiResource"
import { useSession } from "source/shared/hooks/useSession"
import TabView from "source/groups/my/groups/TabView"
import TopicForm from "source/groups/messaging/Topic/TopicForm"

export default function MessagesEdit() {
  const { topicId } = useParams()
  useSession(true)
  const { group, setCurrentTopicId } = useContext(TopicsDataContext)

  useEffect(() => {
    setCurrentTopicId(topicId)
    return () => setCurrentTopicId(undefined)
  }, [topicId])

  const topicUrl = `/groups/v2/me/groups/${
    group.id
  }/forum_topics/${topicId}?include=author,most_recent_reply,most_recent_reply.author`

  return (
    <TabView showActions={false}>
      <EditForm fetchTopicUrl={topicUrl} />
    </TabView>
  )
}

function EditForm({ fetchTopicUrl }) {
  const navigate = useNavigate()
  const { base_path } = useContext(CurrentGroupContext)
  const { currentTopic: topic, ingest } = useContext(TopicsDataContext)
  const topicJson = useApiRead(fetchTopicUrl)

  useEffect(() => {
    ingest(topicJson)
  }, [topicJson])

  if (!topic) return null
  if (!topic.attributes.abilities.can_update)
    navigate(`${base_path}/messages/${topic.id}`)

  return (
    <TopicForm
      topicId={topic.id}
      title={topic.attributes.title}
      message={topic.attributes.message}
      repliesEnabled={topic.attributes.replies_enabled}
    />
  )
}

EditForm.propTypes = {
  fetchTopicUrl: string,
}
