import { useEffect, useState } from "react"

export function usePlatformUiHeight() {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(sumPlatformUiHeight())

    window.addEventListener("resize", sumPlatformUiHeight)

    return () => {
      window.removeEventListener("resize", sumPlatformUiHeight)
    }
  }, [])

  return height
}

function sumPlatformUiHeight() {
  const pageContent = document.querySelector("#main_page_content")

  const pageContentPadding =
    parseInt(getComputedStyle(pageContent).paddingTop) +
    parseInt(getComputedStyle(pageContent).paddingBottom)

  const pageContentHeightMinusPadding =
    pageContent.clientHeight - pageContentPadding

  const heightSum = document.body.scrollHeight - pageContentHeightMinusPadding

  return heightSum
}
