import { useState, useEffect } from "react"
import { object, func, arrayOf, number, string, shape } from "prop-types"
import { useParams } from "react-router-dom"
import { lowerCase, sortBy } from "lodash"
import { Select } from "source/shared/components"
import { restrictionsToString } from "source/registrations/restrictions"
import { useEvent } from "source/registrations/hooks/useEvent"

const SingleAttendeeSelection = ({
  selections,
  attendeeTypes,
  onSelectionChange,
}) => {
  const { eventId } = useParams()

  const {
    data: { registerForSeparateTimes, eventTimes },
  } = useEvent(eventId)

  const [eventTimeId, setEventTimeId] = useState("")
  const [selectionId, setSelectionId] = useState("")

  const availableSelectionsFor = (signupTimeId) => {
    let availableSelections = selections.filter(
      ({ waitlist, status }) =>
        (!status && !waitlist) || (status === "Waitlist available" && waitlist),
    )

    if (registerForSeparateTimes) {
      availableSelections = availableSelections.filter(
        (as) => as.signupTimeId === signupTimeId,
      )
    }

    return availableSelections
  }

  useEffect(() => {
    const selected = selections.find((s) => s.count > 0)

    if (selected) {
      setEventTimeId(selected.signupTimeId || "")
      setSelectionId(selected.id)
      return
    }

    const availableEventTimes = eventTimes.filter(
      (et) => et.availableForRegistrations && !et.atMaximumCapacity,
    )

    let signupTimeId = null

    if (registerForSeparateTimes && availableEventTimes.length === 1) {
      signupTimeId = availableEventTimes[0].id
      setEventTimeId(signupTimeId)
    }

    const availableSelections = availableSelectionsFor(signupTimeId)

    if (availableSelections.length === 1 && !availableSelections[0].waitlist) {
      handleSelectionChange({ target: { value: availableSelections[0].id } })
    }
  }, [selections.length])

  const handleSelectionChange = ({ target: { value } }) => {
    setSelectionId(Number(value))
    onSelectionChange({ id: Number(value), count: 1 })
  }

  const handleEventTimeChange = ({ target: { value } }) => {
    setEventTimeId(Number(value))

    const availableSelections = availableSelectionsFor(Number(value))

    if (availableSelections.length === 1) {
      handleSelectionChange({ target: { value: availableSelections[0].id } })
    } else if (selectionId) {
      onSelectionChange({ id: selectionId, count: 0 })
      setSelectionId("")
    }
  }

  const eventTimesForRegisterable = registerForSeparateTimes
    ? eventTimes.filter((et) => et.availableForRegistrations)
    : []

  const eventTimeOptions = eventTimesForRegisterable.map((eventTime) => {
    const atMaxCapacity =
      eventTime.atMaximumCapacity ||
      selections
        .filter((s) => s.signupTimeId === eventTime.id)
        .every((s) => s.availableCount === 0 && !s.waitlistAvailable)

    return {
      label: `${eventTime.date} ${eventTime.allDay ? "" : eventTime.time} ${
        atMaxCapacity ? "(Full)" : ""
      }`,
      value: eventTime.id,
      disabled: atMaxCapacity,
    }
  })

  const groupSelectionOptions = sortBy(selections, "position").reduce(
    (groupSelections, selection) => {
      const status = lowerCase(selection.status)
      const isWaitlistAvailable = status === "waitlist available"
      const isFullOrSoldOut = status === "full" || status === "sold out"

      const waitlistAndNotAvailable = !isWaitlistAvailable && selection.waitlist
      const notWaitlistAndAvailable = isWaitlistAvailable && !selection.waitlist

      if (
        (selection.signupTimeId && selection.signupTimeId !== eventTimeId) ||
        notWaitlistAndAvailable ||
        waitlistAndNotAvailable
      )
        return groupSelections

      let groupOrder = 1
      if (isWaitlistAvailable) groupOrder = 2
      if (isFullOrSoldOut) groupOrder = 3

      const restrictions = restrictionsToString(
        attendeeTypes.find((at) => at.id === selection.attendeeTypeId),
      )

      const restrictionsText = restrictions ? ` - ${restrictions}` : ""
      const priceText = selection.priceCents > 0 ? ` (${selection.price})` : ""

      const option = {
        label: `${selection.name} ${restrictionsText} ${priceText}`,
        value: selection.id,
        disabled: isFullOrSoldOut,
      }

      const selectionOption = selection.status
        ? {
            label: selection.status,
            groupOrder,
            options: [
              ...(groupSelections[selection.status]?.options || []),
              option,
            ],
          }
        : {
            groupOrder,
            ...option,
          }

      return {
        ...groupSelections,
        [selectionOption.label]: selectionOption,
      }
    },
    {},
  )

  const optionsForSelection = sortBy(
    Object.values(groupSelectionOptions),
    "groupOrder",
  )

  return (
    <>
      {registerForSeparateTimes && (
        <Select
          name="event-times"
          onChange={handleEventTimeChange}
          value={eventTimeId.toString()}
          options={[
            { label: "Select a date/time", value: "", disabled: true },
            ...eventTimeOptions,
          ]}
        />
      )}

      {(!registerForSeparateTimes || eventTimeId) && (
        <Select
          name="attendee-selection"
          className="select mt-1"
          onChange={handleSelectionChange}
          value={selectionId.toString()}
        >
          <Select.Option disabled />
          {optionsForSelection.map((option) => {
            return option.options ? (
              <Select.OptGroup key={option.label} label={option.label}>
                <Select.Options options={option.options} />
              </Select.OptGroup>
            ) : (
              <Select.Option
                key={option.value}
                label={option.label}
                value={option.value}
                disabled={option.disabled}
              />
            )
          })}
        </Select>
      )}
    </>
  )
}

SingleAttendeeSelection.propTypes = {
  selections: arrayOf(shape({ id: number, name: string, count: number })),
  onSelectionChange: func,
  attendeeTypes: arrayOf(object),
}

export default SingleAttendeeSelection
